import {connect} from "react-redux";
import AccountsPage from "../components/AccountsPage";
import {init, addAccount, updateAccount, removeAccount} from "../../../actions/AccountsActions";
import {getExchangesListAction} from "../../../actions/MarketsActions";

export default connect((state) => ({
  accounts: state.accounts.accounts.data,
  accountsLoading: state.accounts.accounts.loading,
  user: state.user.user.data,
  exchanges: state.markets.exchanges,
}), {
  init,
  addAccount,
  updateAccount,
  removeAccount,
  getExchangesListAction,
})(AccountsPage);
