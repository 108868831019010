import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {curry} from "lodash";
import {isModalOpen} from "../../selectors/markets";
import {toggleModal as toggleModalAction} from "../../actions/MarketsActions";
import styles from './WidgetScanner.module.scss';
import {useState} from "react";
import WidgetScannerSettingsModalTypes from "./WidgetScannerSettingsModalTypes";
import {getActiveWidget} from "../../selectors/wigdet";
import {getWidgetSettings} from "../../selectors/wigdetScanner";
import {doUpdateState} from "../../actions/UserAction";
import {updateSettings} from "../../actions/WidgetScanner";

const tabs = {
  "Create": {
    settingsKey: "patterns",
    component: WidgetScannerSettingsModalTypes
  },
};
export default () => {
  const activeWidget = useSelector(getActiveWidget);
  const [tab, setTab] = useState(Object.keys(tabs)[0]);
  const isOpen = useSelector(curry(isModalOpen)('widgetScannerSettingsModal'));
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(toggleModalAction("widgetScannerSettingsModal"));
  const settings = useSelector(state => getWidgetSettings(activeWidget.i, state));
  const [settingsPatterns, setSettingsPatterns] = useState(settings);
  const handleChangeSettings = name => data => setSettingsPatterns({
    ...settingsPatterns,
    [name]: data
  });
  const handleSaveChanges=()=>{
    dispatch(updateSettings(activeWidget.i, settingsPatterns));
    dispatch(doUpdateState());
    dispatch(toggleModalAction("widgetScannerSettingsModal"));
  };
  return (<Modal
    className={styles.WidgetScannerSettingsModal}
    autoFocus={false}
    isOpen={isOpen}
    toggle={() => toggleModal("widgetScannerSettingsModal")}
  >
    <ModalHeader toggle={() => toggleModal("widgetScannerSettingsModal")}>
      Scanner <span>& Settings</span>
    </ModalHeader>

    <ModalBody>
      <WidgetScannerSettingsModalTypes onChange={handleChangeSettings(tabs[tab].settingsKey)} settings={settings} />
    </ModalBody>
    <div className={styles.bottomButtons}>
      <button
        label="Reset"
        className={styles.ResetButton}
      >
        Reset
      </button>
      <button
        className={styles.SaveButton}
        type="button"
        onClick={handleSaveChanges}
      >
        Save
      </button>
      <button
        className={styles.CancelButton}
        type="button"
        onClick={() => toggleModal("widgetScannerSettingsModal")}
      >
        Cancel
      </button>
    </div>
  </Modal>);
};
