export const EXCHANGES = [
  {
    label: "AAX",
    value: "aax",
    image: <img alt="AAX" src="/img/exchanges_gray/aax.png" />
  },
  {
    label: "AscendEX",
    value: "ascendex",
    image: <img alt="ascendex" src="/img/exchanges_gray/ascendex.png" />
  },
  {
    label: "Bequant",
    value: "bequant",
    image: <img alt="Bequant" src="/img/exchanges_gray/bequant.png" />
  },
  {
    label: "Bibox",
    value: "bibox",
    image: <img alt="Bibox" src="/img/exchanges_gray/bibox.png" />
  },
  {
    label: "BigONE",
    value: "bigone",
    image: <img alt="BigONE" src="/img/exchanges_gray/bigone.png" />
  },
  {
    label: "Binance",
    value: "binance",
    image: <img alt="Binance COIN-M" src="/img/exchanges_gray/binance.png" />
  },
  {
    label: "Binance COIN-M",
    value: "binancecoinm",
    image: <img alt="Binance COIN-M" src="/img/exchanges_gray/binancecoinm.png" />
  },
  {
    label: "Binance US",
    value: "binanceus",
    image: <img alt="Binance US" src="/img/exchanges_gray/binanceus.png" />
  },
  {
    label: "Binance USDⓈ-M",
    value: "binanceusdm",
    image: <img alt="Binance USDⓈ-M" src="/img/exchanges_gray/binanceusdm.png" />
  },
  {
    label: "Bit2C",
    value: "bit2c",
    image: <img alt="Bit2C" src="/img/exchanges_gray/bit2c.png" />
  },
  {
    label: "bitbank",
    value: "bitbank",
    image: <img alt="bitbank" src="/img/exchanges_gray/bitbank.png" />
  },
  {
    label: "Bitbns",
    value: "bitbns",
    image: <img alt="Bitbns" src="/img/exchanges_gray/bitbns.png" />
  },
  {
    label: "FMFW.io",
    value: "bitcoincom",
    image: <img alt="FMFW.io" src="/img/exchanges_gray/bitcoincom.png" />
  },
  {
    label: "Bitfinex",
    value: "bitfinex",
    image: <img alt="Bitfinex" src="/img/exchanges_gray/bitfinex.png" />
  },
  {
    label: "Bitfinex",
    value: "bitfinex2",
    image: <img alt="Bitfinex" src="/img/exchanges_gray/bitfinex2.png" />
  },
  {
    label: "bitFlyer",
    value: "bitflyer",
    image: <img alt="bitFlyer" src="/img/exchanges_gray/bitflyer.png" />
  },
  {
    label: "Bitforex",
    value: "bitforex",
    image: <img alt="Bitforex" src="/img/exchanges_gray/bitforex.png" />
  },
  {
    label: "Bitget",
    value: "bitget",
    image: <img alt="Bitget" src="/img/exchanges_gray/bitget.png" />
  },
  {
    label: "Bithumb",
    value: "bithumb",
    image: <img alt="Bithumb" src="/img/exchanges_gray/bithumb.png" />
  },
  {
    label: "BitMart",
    value: "bitmart",
    image: <img alt="BitMart" src="/img/exchanges_gray/bitmart.png" />
  },
  {
    label: "BitMEX",
    value: "bitmex",
    image: <img alt="BitMEX" src="/img/exchanges_gray/bitmex.png" />
  },
  {
    label: "BitoPro",
    value: "bitopro",
    image: <img alt="BitoPro" src="/img/exchanges_gray/bitopro.png" />
  },
  {
    label: "Bitpanda Pro",
    value: "bitpanda",
    image: <img alt="Bitpanda Pro" src="/img/exchanges_gray/bitpanda.png" />
  },
  {
    label: "Bitrue",
    value: "bitrue",
    image: <img alt="Bitrue" src="/img/exchanges_gray/bitrue.png" />
  },
  {
    label: "Bitso",
    value: "bitso",
    image: <img alt="Bitso" src="/img/exchanges_gray/bitso.png" />
  },
  {
    label: "Bitstamp",
    value: "bitstamp",
    image: <img alt="Bitstamp" src="/img/exchanges_gray/bitstamp.png" />
  },
  {
    label: "Bitstamp",
    value: "bitstamp1",
    image: <img alt="Bitstamp" src="/img/exchanges_gray/bitstamp1.png" />
  },
  {
    label: "Bittrex",
    value: "bittrex",
    image: <img alt="Bittrex" src="/img/exchanges_gray/bittrex.png" />
  },
  {
    label: "Bitvavo",
    value: "bitvavo",
    image: <img alt="Bitvavo" src="/img/exchanges_gray/bitvavo.png" />
  },
  {
    label: "BKEX",
    value: "bkex",
    image: <img alt="BKEX" src="/img/exchanges_gray/bkex.png" />
  },
  {
    label: "BL3P",
    value: "bl3p",
    image: <img alt="BL3P" src="/img/exchanges_gray/bl3p.png" />
  },
  {
    label: "Blockchain.com",
    value: "blockchaincom",
    image: <img alt="Blockchain.com" src="/img/exchanges_gray/blockchaincom.png" />
  },
  {
    label: "BTC-Alpha",
    value: "btcalpha",
    image: <img alt="BTC-Alpha" src="/img/exchanges_gray/btcalpha.png" />
  },
  {
    label: "BtcBox",
    value: "btcbox",
    image: <img alt="BtcBox" src="/img/exchanges_gray/btcbox.png" />
  },
  {
    label: "BTC Markets",
    value: "btcmarkets",
    image: <img alt="BTC Markets" src="/img/exchanges_gray/btcmarkets.png" />
  },
  {
    label: "BTC Trade UA",
    value: "btctradeua",
    image: <img alt="BTC Trade UA" src="/img/exchanges_gray/btctradeua.png" />
  },
  {
    label: "BTCTurk",
    value: "btcturk",
    image: <img alt="BTCTurk" src="/img/exchanges_gray/btcturk.png" />
  },
  {
    label: "Buda",
    value: "buda",
    image: <img alt="Buda" src="/img/exchanges_gray/buda.png" />
  },
  {
    label: "BW",
    value: "bw",
    image: <img alt="BW" src="/img/exchanges_gray/bw.png" />
  },
  {
    label: "Bybit",
    value: "bybit",
    image: <img alt="Bybit" src="/img/exchanges_gray/bybit.png" />
  },
  {
    label: "ByteTrade",
    value: "bytetrade",
    image: <img alt="ByteTrade" src="/img/exchanges_gray/bytetrade.png" />
  },
  {
    label: "CDAX",
    value: "cdax",
    image: <img alt="CDAX" src="/img/exchanges_gray/cdax.png" />
  },
  {
    label: "CEX.IO",
    value: "cex",
    image: <img alt="CEX.IO" src="/img/exchanges_gray/cex.png" />
  },
  {
    label: "Coinbase",
    value: "coinbase",
    image: <img alt="Coinbase" src="/img/exchanges_gray/coinbase.png" />
  },
  {
    label: "Coinbase Prime",
    value: "coinbaseprime",
    image: <img alt="Coinbase Prime" src="/img/exchanges_gray/coinbaseprime.png" />
  },
  {
    label: "Coinbase Pro",
    value: "coinbasepro",
    image: <img alt="Coinbase Pro" src="/img/exchanges_gray/coinbasepro.png" />
  },
  {
    label: "coincheck",
    value: "coincheck",
    image: <img alt="coincheck" src="/img/exchanges_gray/coincheck.png" />
  },
  {
    label: "CoinEx",
    value: "coinex",
    image: <img alt="CoinEx" src="/img/exchanges_gray/coinex.png" />
  },
  {
    label: "CoinFalcon",
    value: "coinfalcon",
    image: <img alt="CoinFalcon" src="/img/exchanges_gray/coinfalcon.png" />
  },
  {
    label: "CoinMate",
    value: "coinmate",
    image: <img alt="CoinMate" src="/img/exchanges_gray/coinmate.png" />
  },
  {
    label: "CoinOne",
    value: "coinone",
    image: <img alt="CoinOne" src="/img/exchanges_gray/coinone.png" />
  },
  {
    label: "CoinSpot",
    value: "coinspot",
    image: <img alt="CoinSpot" src="/img/exchanges_gray/coinspot.png" />
  },
  {
    label: "CREX24",
    value: "crex24",
    image: <img alt="CREX24" src="/img/exchanges_gray/crex24.png" />
  },
  {
    label: "Crypto.com",
    value: "cryptocom",
    image: <img alt="Crypto.com" src="/img/exchanges_gray/cryptocom.png" />
  },
  {
    label: "Currency.com",
    value: "currencycom",
    image: <img alt="Currency.com" src="/img/exchanges_gray/currencycom.png" />
  },
  {
    label: "Delta Exchange",
    value: "delta",
    image: <img alt="Delta Exchange" src="/img/exchanges_gray/delta.png" />
  },
  {
    label: "Deribit",
    value: "deribit",
    image: <img alt="Deribit" src="/img/exchanges_gray/deribit.png" />
  },
  {
    label: "DigiFinex",
    value: "digifinex",
    image: <img alt="DigiFinex" src="/img/exchanges_gray/digifinex.png" />
  },
  {
    label: "EQONEX",
    value: "eqonex",
    image: <img alt="EQONEX" src="/img/exchanges_gray/eqonex.png" />
  },
  {
    label: "EXMO",
    value: "exmo",
    image: <img alt="EXMO" src="/img/exchanges_gray/exmo.png" />
  },
  {
    label: "flowBTC",
    value: "flowbtc",
    image: <img alt="flowBTC" src="/img/exchanges_gray/flowbtc.png" />
  },
  {
    label: "FMFW.io",
    value: "fmfwio",
    image: <img alt="FMFW.io" src="/img/exchanges_gray/fmfwio.png" />
  },
  {
    label: "FTX",
    value: "ftx",
    image: <img alt="FTX" src="/img/exchanges_gray/ftx.png" />
  },
  {
    label: "FTX US",
    value: "ftxus",
    image: <img alt="FTX US" src="/img/exchanges_gray/ftxus.png" />
  },
  {
    label: "Gate.io",
    value: "gateio",
    image: <img alt="Gate.io" src="/img/exchanges_gray/gateio.png" />
  },
  {
    label: "HitBTC",
    value: "hitbtc",
    image: <img alt="HitBTC" src="/img/exchanges_gray/hitbtc.png" />
  },
  {
    label: "HitBTC",
    value: "hitbtc3",
    image: <img alt="HitBTC" src="/img/exchanges_gray/hitbtc3.png" />
  },
  {
    label: "HollaEx",
    value: "hollaex",
    image: <img alt="HollaEx" src="/img/exchanges_gray/hollaex.png" />
  },
  {
    label: "Huobi",
    value: "huobi",
    image: <img alt="Huobi" src="/img/exchanges_gray/huobi.png" />
  },
  {
    label: "Huobi Japan",
    value: "huobijp",
    image: <img alt="Huobi Japan" src="/img/exchanges_gray/huobijp.png" />
  },
  {
    label: "IDEX",
    value: "idex",
    image: <img alt="IDEX" src="/img/exchanges_gray/idex.png" />
  },
  {
    label: "Independent Reserve",
    value: "independentreserve",
    image: <img alt="Independent Reserve" src="/img/exchanges_gray/independentreserve.png" />
  },
  {
    label: "INDODAX",
    value: "indodax",
    image: <img alt="INDODAX" src="/img/exchanges_gray/indodax.png" />
  },
  {
    label: "itBit",
    value: "itbit",
    image: <img alt="itBit" src="/img/exchanges_gray/itbit.png" />
  },
  {
    label: "Kraken",
    value: "kraken",
    image: <img alt="Kraken" src="/img/exchanges_gray/kraken.png" />
  },
  {
    label: "KuCoin",
    value: "kucoin",
    image: <img alt="KuCoin" src="/img/exchanges_gray/kucoin.png" />
  },
  {
    label: "KuCoin Futures",
    value: "kucoinfutures",
    image: <img alt="KuCoin Futures" src="/img/exchanges_gray/kucoinfutures.png" />
  },
  {
    label: "Kuna",
    value: "kuna",
    image: <img alt="Kuna" src="/img/exchanges_gray/kuna.png" />
  },
  {
    label: "Latoken",
    value: "latoken",
    image: <img alt="Latoken" src="/img/exchanges_gray/latoken.png" />
  },
  {
    label: "LBank",
    value: "lbank",
    image: <img alt="LBank" src="/img/exchanges_gray/lbank.png" />
  },
  {
    label: "LBank",
    value: "lbank2",
    image: <img alt="LBank" src="/img/exchanges_gray/lbank2.png" />
  },
  {
    label: "Liquid",
    value: "liquid",
    image: <img alt="Liquid" src="/img/exchanges_gray/liquid.png" />
  },
  {
    label: "luno",
    value: "luno",
    image: <img alt="luno" src="/img/exchanges_gray/luno.png" />
  },
  {
    label: "Lykke",
    value: "lykke",
    image: <img alt="Lykke" src="/img/exchanges_gray/lykke.png" />
  },
  {
    label: "Mercado Bitcoin",
    value: "mercado",
    image: <img alt="Mercado Bitcoin" src="/img/exchanges_gray/mercado.png" />
  },
  {
    label: "MEXC Global",
    value: "mexc",
    image: <img alt="MEXC Global" src="/img/exchanges_gray/mexc.png" />
  },
  {
    label: "MEXC Global",
    value: "mexc3",
    image: <img alt="MEXC Global" src="/img/exchanges_gray/mexc3.png" />
  },
  {
    label: "NDAX",
    value: "ndax",
    image: <img alt="NDAX" src="/img/exchanges_gray/ndax.png" />
  },
  {
    label: "NovaDAX",
    value: "novadax",
    image: <img alt="NovaDAX" src="/img/exchanges_gray/novadax.png" />
  },
  {
    label: "OceanEx",
    value: "oceanex",
    image: <img alt="OceanEx" src="/img/exchanges_gray/oceanex.png" />
  },
  {
    label: "OKCoin",
    value: "okcoin",
    image: <img alt="OKCoin" src="/img/exchanges_gray/okcoin.png" />
  },
  {
    label: "OKX",
    value: "okx",
    image: <img alt="OKX" src="/img/exchanges_gray/okx.png" />
  },
  {
    label: "Paymium",
    value: "paymium",
    image: <img alt="Paymium" src="/img/exchanges_gray/paymium.png" />
  },
  {
    label: "Phemex",
    value: "phemex",
    image: <img alt="Phemex" src="/img/exchanges_gray/phemex.png" />
  },
  {
    label: "Poloniex",
    value: "poloniex",
    image: <img alt="Poloniex" src="/img/exchanges_gray/poloniex.png" />
  },
  {
    label: "ProBit",
    value: "probit",
    image: <img alt="ProBit" src="/img/exchanges_gray/probit.png" />
  },
  {
    label: "qTrade",
    value: "qtrade",
    image: <img alt="qTrade" src="/img/exchanges_gray/qtrade.png" />
  },
  {
    label: "Ripio",
    value: "ripio",
    image: <img alt="Ripio" src="/img/exchanges_gray/ripio.png" />
  },
  {
    label: "STEX",
    value: "stex",
    image: <img alt="STEX" src="/img/exchanges_gray/stex.png" />
  },
  {
    label: "TheRockTrading",
    value: "therock",
    image: <img alt="TheRockTrading" src="/img/exchanges_gray/therock.png" />
  },
  {
    label: "TideBit",
    value: "tidebit",
    image: <img alt="TideBit" src="/img/exchanges_gray/tidebit.png" />
  },
  {
    label: "Tidex",
    value: "tidex",
    image: <img alt="Tidex" src="/img/exchanges_gray/tidex.png" />
  },
  {
    label: "TimeX",
    value: "timex",
    image: <img alt="TimeX" src="/img/exchanges_gray/timex.png" />
  },
  {
    label: "Upbit",
    value: "upbit",
    image: <img alt="Upbit" src="/img/exchanges_gray/upbit.png" />
  },
  {
    label: "VCC Exchange",
    value: "vcc",
    image: <img alt="VCC Exchange" src="/img/exchanges_gray/vcc.png" />
  },
  {
    label: "Waves.Exchange",
    value: "wavesexchange",
    image: <img alt="Waves.Exchange" src="/img/exchanges_gray/wavesexchange.png" />
  },
  {
    label: "WazirX",
    value: "wazirx",
    image: <img alt="WazirX" src="/img/exchanges_gray/wazirx.png" />
  },
  {
    label: "WhiteBit",
    value: "whitebit",
    image: <img alt="WhiteBit" src="/img/exchanges_gray/whitebit.png" />
  },
  {
    label: "WOO X",
    value: "woo",
    image: <img alt="WOO X" src="/img/exchanges_gray/woo.png" />
  },
  {
    label: "Xena Exchange",
    value: "xena",
    image: <img alt="Xena Exchange" src="/img/exchanges_gray/xena.png" />
  },
  {
    label: "YoBit",
    value: "yobit",
    image: <img alt="YoBit" src="/img/exchanges_gray/yobit.png" />
  },
  {
    label: "Zaif",
    value: "zaif",
    image: <img alt="Zaif" src="/img/exchanges_gray/zaif.png" />
  },
  {
    label: "ZB",
    value: "zb",
    image: <img alt="ZB" src="/img/exchanges_gray/zb.png" />
  },
  {
    label: "Zipmex",
    value: "zipmex",
    image: <img alt="Zipmex" src="/img/exchanges_gray/zipmex.png" />
  },
  {
    label: "Zonda",
    value: "zonda",
    image: <img alt="Zonda" src="/img/exchanges_gray/zonda.png" />
  },
];
