import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import numeral from "numeral";
import moment from "moment";
import Select from "../../../../components/Select";
import Collapse from "../../../../components/Collapse";

import styles from "../SettingsPage.scss";
import FontAwesome from "../../../../components/FontAwesome";

import CoinpaymentsLogo from "../../../../assets/coinpayments.png";
import {PRICES} from "../../constants/SubscriptionConstants";
import {getHostName} from "../../../../utils/apiUtils";

class SubscriptionPage extends Component {
  state = {
    period: PRICES[0],
    count: 1,
  };

  handlePeriodChange = (event, field) => {
    if (field === "period") {
      const label = event.target.value;
      const period = PRICES.find((item) => item.label === label);
      this.setState({
        period: period || PRICES[0],
        count: period ? (this.state.count > period.maxNumber ? 1 : this.state.count) : 1,
      });
    } else if (field === "count") {
      this.setState({
        count: +event.target.value
      });
    }
  };

  drawSubscription = (item) => {
    const period = item.type.split(" ");
    const expireDate = moment(item.createdDate).add(period[0], period[1]);
    const diff = moment().diff(expireDate, "seconds");
    const isActive = diff <= 0;

    return (
      <tr key={item._id}>
        <td>
          <FontAwesome
            wrapperClassName={classNames(styles.Icon, {
              [styles.Active]: isActive,
            })}
            prefix={isActive ? "fas" : "far"}
            name={isActive ? "check" : "ban"}
          />
          <span className={classNames({[styles.Active]: isActive})}>
            {isActive ? "Active" : "Expired"}
          </span>
        </td>
        <td>{moment(item.createdDate).format("D-MM-YYYY H:mm:ss")}</td>
        <td>{moment(expireDate).format("D-MM-YYYY H:mm:ss")}</td>
        <td>{moment(expireDate).toNow(true)}</td>
        <td>${item.total} / {item.type}(s)</td>
      </tr>
    );
  };

  drawStatus = (status) => {
    const label = status === "admin" ? "Admin" : "No subscription";
    return (
      <tr>
        <td>
          <FontAwesome
            wrapperClassName={classNames(styles.Icon, {
              [styles.Active]: status === "admin",
            })}
            prefix={status === "admin" ? "fas" : "far"}
            name={status === "admin" ? "check" : "ban"}
          />
          <span className={classNames({[styles.Active]: status === "admin"})}>
            {label}
          </span>
        </td>
        <td>&mdash;</td>
        <td>&mdash;</td>
        <td>&mdash;</td>
        <td>No plan</td>
      </tr>
    );
  };

  drawBillingSettings = () => {
    const {period, count} = this.state;
    const {user} = this.props;

    return (
      <div className={classNames("row", styles.Row)}>
        <div
          className={classNames({
            "col-sm-8": !user.isPaid,
            "col-sm-12": user.isPaid,
          })}
        >
          {user && (
            <table className={styles.Table}>
              <thead>
                <tr>
                  <th>Subscription</th>
                  <th>Start date</th>
                  <th>Expiry date</th>
                  <th>Time remaining</th>
                  <th>Plan</th>
                </tr>
              </thead>
              <tbody>
                {user.isAdmin && this.drawStatus("admin")}
                {!user.isAdmin && user.subscriptions?.map(this.drawSubscription)}
                {!user.isAdmin && user.subscriptions?.length === 0 && this.drawStatus("free")}
              </tbody>
            </table>
          )}
        </div>
        {!user.isPaid && (
          <div className="col-sm-4">
            <img
              alt="Coinpayments"
              className={styles.Logo}
              src={CoinpaymentsLogo}
            />
            <div className={styles.CheckoutBlock}>
              <div className={styles.Item}>
                <div>Subscription</div>
                <div>
                  <div style={{
                    width: '40px',
                    float: 'left'}
                  }>
                    <Select
                      onChange={(e) => this.handlePeriodChange(e, "count")}
                      value={count}
                    >
                      {[...Array(period.maxNumber).keys()].map((num) => (
                        <option
                          key={num + 1}
                          value={num + 1}
                        >
                          {num + 1}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div style={{
                    width: '75px',
                    float: 'left',
                    marginLeft: '5px'}
                  }>
                    <Select
                      onChange={(e) => this.handlePeriodChange(e, "period")}
                      value={period.label}
                      style={{
                        width: '75px',
                        float: 'left',
                        marginLeft: '5px',
                      }}
                    >
                      {PRICES.map((item) => (
                        <option
                          key={item.label}
                          value={item.label}
                        >
                          {item.label + "(s)"}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className={styles.Item}>
                <div>Subtotal</div>
                <div>{numeral(period.total * count).format("$0,0.00")}</div>
              </div>
              <div className={styles.Item}>
                <div>Tax</div>
                <div>{numeral(period.tax * count).format("$0,0.00")}</div>
              </div>
              <div className={styles.Item}>
                <div>Total</div>
                <div>{numeral(period.total * (count + period.tax)).format("$0,0.00")}</div>
              </div>
            </div>
            <form action="https://www.coinpayments.net/index.php" method="post">
              <input type="hidden" name="cmd" value="_pay_simple"/>
              <input type="hidden" name="reset" value="1"/>
              <input type="hidden" name="merchant" value="0a329c2e396be391c59d5a0ec7faff54"/>
              <input type="hidden" name="item_name" value={`${count} ${period.label}`}/>
              <input type="hidden" name="item_desc" value={`Aurlix subscription - ${count} ${period.label}(s)`}/>
              <input type="hidden" name="currency" value="USD"/>
              <input type="hidden" name="amountf" value={period.total * count}/>
              <input type="hidden" name="taxf" value={period.tax * count}/>
              <input type="hidden" name="want_shipping" value="0"/>
              <input type="hidden" name="email" value={user.email}/>
              <input type="hidden" name="custom" value={user.id}/>
              <input
                type="hidden"
                name="success_url"
                value={`${getHostName()}/settings/subscription`}
              />
              <input
                type="hidden"
                name="cancel_url"
                value={`${getHostName()}/settings/subscription`}
              />
              <button className={styles.SaveBtn} type="submit">
                Checkout
              </button>
            </form>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className={styles.Wrapper}>
        <Collapse
          label="Billing"
          description="Edit subscription plan"
          expanded
        >
          {this.drawBillingSettings()}
        </Collapse>
      </div>
    );
  }
}

SubscriptionPage.propTypes = {
  user: PropTypes.object,
};

SubscriptionPage.defaultProps = {
  user: {},
};

export default SubscriptionPage;
