import {api} from "../utils/apiUtils";

const getBalanceList = () => api.get("balance/list");
const getAccountBalanceList = (account) => api.get(`balance/list/${account}`);
const getBalanceHistoryList = (period) => api.get("balance/history", {
  period,
});

export {
  getBalanceList,
  getBalanceHistoryList,
  getAccountBalanceList,
};
