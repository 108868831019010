export default [
  {url: "https://cointelegraph.com/feed"},
  {url: "https://www.coindesk.com/feed"},
  {url: "https://news.bitcoin.com/feed"},
  {url: "https://www.ccn.com/feed"},
  {url: "https://bitcoinmagazine.com/feed"},
  {url: "https://www.newsbtc.com/feed"},
  {url: "http://bitcoinist.com/feed"},
  {url: "https://ethereumworldnews.com/feed"},
  {url: "http://www.cryptoninjas.net/feed"},
  {url: "https://cryptobriefing.com/feed"},
  {url: "https://cryptodaily.co.uk/feed"},
  {url: "https://bravenewcoin.com/news/rss"},
  {url: "https://nulltx.com/feed"},
  {url: "https://btcmanager.com/feed"},
  {url: "https://usethebitcoin.com/feed"},
  {url: "https://www.coinspeaker.com/feed"},
  {url: "https://coinjournal.net/feed"},
  {url: "https://cryptopotato.com/feed"},
  {url: "https://zycrypto.com/feed"},
  {url: "https://www.livebitcoinnews.com/feed"},
  {url: "https://bitcoingarden.org/feed"},
  {url: "https://bitcoinprbuzz.com/feed"},
  {url: "https://tokeneconomy.co/feed"},
  {url: "https://thebitcoinnews.com/feed"},
  {url: "https://insidebitcoins.com/feed"},
  {url: "http://www.coinnewsasia.com/feed"},
  {url: "https://bitcoinchaser.com/feed"},
  {url: "https://www.mycryptopedia.com/feed"},
  {url: "https://bitrazzi.com/feed"},
  {url: "http://cryptocoremedia.com/feed"},
  {url: "http://exploringtheblock.com/feed"},
  {url: "https://bitcoinwarrior.net/feed"},
  {url: "https://thewalletgenius.com/feed"},
  {url: "https://coinreport.net/feed"},
  {url: "https://forklog.media/feed"},
  {url: "https://bitcoinfoundation.org/feed"},
  {url: "https://coinscage.com/feed"},
  {url: "https://coinpress.io/feed"},
  {url: "http://www.cryptoanalyst.co/feed"},
  {url: "https://bitcoinsinireland.com/feed"},
  {url: "https://dappdaily.com/feed"},
  {url: "http://cryptoconverts.com/feed"},
  {url: "http://btcworldnews.com/feed"},
  {url: "http://themerkle.com/feed"},
  {url: "https://altcointoday.com/feed"},
  {url: "https://cryptocurrencynews.com/feed"},
  {url: "https://cryptoclarified.com/feed"},
  {url: "https://www.crypto-news.net/feed"},
];
