import React from "react";
import PropTypes from "prop-types";

import "./MarketName.scss";

const MarketName = ({name, handleOnClick, reversed}) => {
  let result;
  switch (reversed) {
    case true:
      const reversedNames = name.split("/");
      if (reversedNames.length > 1) {
        result = (
          <div
            className="market-name"
            onClick={handleOnClick}
            tabIndex={0}
            role="button"
          >
            <span className="ticker">
              {reversedNames[0]}
            </span>
            <span className="base">
              / {reversedNames[1]}
            </span>
            <div className="tickerLong">Crypto</div>
          </div>
        );
      }
      break;
    case false:
      const names = name.split("-");
      if (names.length > 1) {
        result = (
          <div
            className="market-name"
            onClick={handleOnClick}
            tabIndex={0}
            role="button"
          >
            <span className="ticker">
              {names[1]}
            </span>
            <span className="base">
              / {names[0]}
            </span>
          </div>
        );
      }
      break;
  }
  return result ? result : (
    <div
      className="market-name"
      onClick={handleOnClick}
      tabIndex={0}
      role="button"
    >
      {name}
    </div>
  );
};

MarketName.propTypes = {
  handleOnClick: PropTypes.func,
  name: PropTypes.string,
  reversed: PropTypes.bool,
};

MarketName.defaultProps = {
  handleOnClick: () => false,
  name: "",
  reversed: false,
};

export default MarketName;
