import {connect} from "react-redux";
import SettingsPage from "../components/SettingsPage";
import {doCheckToken, getTmpSecret, editUserAction} from "../../../actions/UserAction";
import {subsribeAction} from "../../../actions/SettingsActions";

export default connect((state) => ({
  tmpSecretImg: state.user.tmpSecretImg,
  tmpSecretKey: state.user.tmpSecretKey,
  tokenValid: state.user.tokenValid,
  user: state.user.user.data,
}), {
  doCheckToken,
  getTmpSecret,
  subsribeAction,
  editUserAction,
})(SettingsPage);
