import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {uid} from "uid";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import FontAwesome from "../../../components/FontAwesome";
import {indicators} from "./ChartWidget/Indicator/settings";

import styles from "./MarketsModal.scss";
const {createNewIndicator} = require('./ChartWidget/Indicator/parser');

class WidgetSettingsModal extends Component {
  constructor() {
    super();

    this.input = null;
    this.state = {
      filter: "all",
      search: "",
    };
  }

  addNewIndicator = (indicator) => {
    const {updateWidget, widget, indicatorTemplates} = this.props;
    if (indicator.editable && indicator.options.code) {
      const indicatorIdx = widget.indicators.length;
      const newIndicator = createNewIndicator(indicator, indicatorIdx);
      console.log(indicator, indicatorIdx);
      const type = indicator.id;
      const templates = indicatorTemplates && indicatorTemplates[type];
      const autoSavedSetting = templates && templates['AutoSave'];
      if (autoSavedSetting){
        updateWidget({
          ...widget,
          indicators: [
            ...widget.indicators, {
              ...newIndicator,
              uid: uid(10),
              updatedDrawingStyles: autoSavedSetting.styles,
              options: autoSavedSetting.options
            }]
        });
      } else {
        updateWidget({
          ...widget,
          indicators: [
            ...widget.indicators, {
              ...newIndicator,
              uid: uid(10)
            }]
        });
      }
    } else {
      updateWidget({
        ...widget,
        indicators: [
          ...widget.indicators, {
            ...indicator,
            uid: uid(10),
          }]
      });
      // if (indicator.type === "interactive") {
      //   toggleModal("widgetSettings");
      // }
    }
  };

  handleSearchInput = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  filterIndicators = (indicator) => {
    const {filter, search} = this.state;
    let passFilter = true;

    if (filter === "indicator" || filter === "interactive") {
      passFilter = indicator.type === filter;
    }
    return passFilter && (indicator.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
      indicator.fullName.toLowerCase().indexOf(search.toLowerCase()) >= 0);
  };

  checkIsDisabled = (indicator) => {
    return false;
    // const {widget} = this.props;
    // if (!widget) {
    //   return false;
    // }
    // const indicatorExists = widget.indicators && widget.indicators.find((item) => item.id === indicator.id);
    // const separatedIndicatorsCount = widget.indicators && widget.indicators.filter((item) => item.separated).length;

    // return !indicator.active || (indicator.separated && separatedIndicatorsCount === 4) ||
    //   (indicator.type !== "interactive" && indicatorExists);
  };
  compare = ( a, b ) => {
    if (a.type == b.type){
      if (a.name > b.name){
        return 1;
      } else {
        return -1;
      }
    }
    return a.type > b.type ? 1 : -1;
  }
  render() {
    const {isOpen, toggleModal} = this.props;
    indicators.sort(this.compare);
    return (
      <Modal
        autoFocus={false}
        onEnter={() => setTimeout(() => {
          this.input && this.input.focus();
        }, 500)}
        isOpen={isOpen}
        toggle={() => toggleModal("widgetSettings")}
        className={styles.MarketsModal}
        size="lg"
      >
        <ModalHeader
          className={styles.Header}
          toggle={() => toggleModal("widgetSettings")}
        >
          Indicator Panel
          <div className={styles.test1}>
            <FontAwesome
              prefix="fas"
              name="search"
            />
            <div className={styles.Search}>
              <input
                type="text"
                autoFocus
                ref={(input) => {
                  this.input = input;
                }}
                placeholder="Search..."
                onChange={this.handleSearchInput}
                value={this.state.search}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <ul className="favourites">
            <button
              className="gold"
              onClick={this.handleToggleFavourites}
            >
              <FontAwesome
                prefix="fas"
                name="star"
                className={styles.star}
              /> Favourites</button>
            <button
              className="white"
            >
              TREND
            </button>
            <button
              className="white"
            >
              VOLUME
            </button>
            <button
              className="white"
            >
              OSCILLATORS
            </button>
            <button
              className="white"
            >
              ADAPTIVE
            </button>
            <button
              className="white"
            >
              VOLATILITY
            </button>
            <button
              className="white"
            >
              ALL
            </button>
          </ul>
          <div
            className={styles.MarketsList}
          >
            {indicators
                .filter((indicator) => this.filterIndicators(indicator))
                .map((indicator) => (
                  <div
                    className={classNames(styles.MarketItem, {[styles.Disabled]: this.checkIsDisabled(indicator)})}
                    key={indicator.id}
                    onClick={() => !this.checkIsDisabled(indicator) && this.addNewIndicator(indicator)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className={styles.iconIndicator}>
                      <img alt="Indicator" src={`/img/icons/${indicator.category}.svg`} />
                    </div>
                    <div className={styles.Name1}>
                      <span className="ticker">
                        {indicator.name}
                      </span>
                      <span className="base">
                        / {indicator.category}
                        <FontAwesome
                          className={styles.star1}
                          prefix="far"
                          name="star"
                        />
                      </span>
                      <div className="tickerLong">{indicator.fullName}</div>

                    </div>
                    <div
                      className={styles.indicatorType}>Built in / {indicator.type.trim().replace(/^\w/, (c) => c.toUpperCase())}
                    </div>

                    <div className={styles.tooltip}>
                      Description <FontAwesome
                        className="star"
                        prefix="far"
                        name="info-circle"
                      />
                      <span className={styles.tooltiptext}>
                        <div className={styles.titletip}>
                          {indicator.fullName}
                        </div>
                        {indicator.long}
                        <div className={styles.example}>
                          <span>Example:</span>
                          <img
                            alt={indicator.fullName}
                            onError={(img) => img.target.style.display = 'none'}
                            className={styles.indicatorimage}
                            src={`/img/images/${indicator.name}.png`}
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                ))}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

WidgetSettingsModal.propTypes = {
  indicatorTemplates: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
};

WidgetSettingsModal.defaultProps = {
  isOpen: false,
  toggleModal: () => false,
  widget: {},
};

export default WidgetSettingsModal;
