/* eslint react/jsx-handler-names: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FontAwesome from "../../../components/FontAwesome";
import {Nav, NavItem, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";

import styles from "./BottomTabs.scss";

class BottomTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      worksheetsOpened: false,
    };
  }

  toggle = (type) => {
    this.setState({
      [type]: !this.state[type],
    });
  };

  render() {
    const {openTradeWidget, addWorksheet, currentWorksheet, user, toggleModal, history} = this.props;
    const widgetsLength = currentWorksheet && currentWorksheet.widgets ? currentWorksheet.widgets.length : 0;
    let actions = [];
    if (user != null) {
      actions = [
        {
          id: "add-widget",
          name: "Add Panels & Widgets",
          disabled: !user.isPaid,
          action: () => {
            if (user.isPaid || (!user.isPaid && widgetsLength <= 5)) {
              toggleModal("addNewPanel");
            }
          },
        },
      ];
    }
    return (
      <div className={styles.BottomTabs}>
        <Nav className="app-workspaces-tabs">
          <NavItem>
            <ButtonDropdown
              isOpen={this.state.worksheetsOpened}
              toggle={() => this.toggle("worksheetsOpened")}
              direction="up"
            >
              <DropdownToggle className={styles.WorksheetButton} color="dark">
                <div className={styles.WorksheetButtonText}>

                  {currentWorksheet.name}
                </div>

                <FontAwesome
                  name={this.state.worksheetsOpened ? "chevron-down" : "chevron-up"}
                  style={{
                    float: "right",
                  }}
                />

              </DropdownToggle>
              <DropdownMenu className={styles.WorksheetMenu}>
                {this.props.worksheets.map((worksheet) => (
                  <DropdownItem
                    key={worksheet.id}
                    className={classNames({active: worksheet.id === currentWorksheet.id})}
                    onClick={() => {
                      history.push(`/markets/workspaces/${worksheet.id}`);
                      this.toggle("worksheetsOpened");
                    }}
                  >
                    <div className={styles.worksheetIcon}>{worksheet.name}</div>
                  </DropdownItem>
                ))}
                <DropdownItem divider/>
                <DropdownItem
                  onClick={() => user.isPaid && addWorksheet()}
                >
                  <FontAwesome
                    name="plus"
                    className={styles.plusSVG}
                    prefix="far"
                  /> New Worksheet
                </DropdownItem>
                <DropdownItem
                  onClick={() => toggleModal("worksheetSettings")}
                >
                  <FontAwesome
                    name="cog"
                    className={styles.plusSVG}
                    prefix="fas"
                  /> Edit Settings
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </NavItem>
          <NavItem>
            <div className="btn-group">
              <button
                className="btn btn-dark"
                onClick={openTradeWidget}
              >
                Trading Panel
              </button>
            </div>
          </NavItem>
          <NavItem>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={() => this.toggle("dropdownOpen")}
              direction="up"
            >
              <DropdownToggle color="dark">
                <FontAwesome
                  name='bars'
                />
              </DropdownToggle>
              <DropdownMenu>
                {actions.map((action) => (
                  <DropdownItem
                    key={action.id}
                    disabled={action.disabled}
                    onClick={action.action}
                    className="btn-group"
                  >
                    <FontAwesome
                      name="plus"
                      className={styles.plusSVG}
                      prefix="far"
                    /> {action.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

BottomTabs.propTypes = {
  addWidget: PropTypes.func,
  addWorksheet: PropTypes.func.isRequired,
  currentWorksheet: PropTypes.object,
  openTradeWidget: PropTypes.func,
  removeWorksheet: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  worksheets: PropTypes.array,
  toggleModal: PropTypes.func.isRequired,
};

BottomTabs.defaultProps = {
  addWidget: () => false,
  currentWorksheet: {},
  openTradeWidget: () => false,
  removeWorksheet: () => false,
  user: {},
  worksheets: [],
};

export default BottomTabs;
