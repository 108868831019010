import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./UserStatus.scss";

const UserStatus = ({className, isOnline}) => (
  <div
    className={classNames(styles.Status, className, {
      [styles.Online]: isOnline,
      [styles.Offline]: !isOnline,
    })}
  />
);

UserStatus.propTypes = {
  className: PropTypes.string,
  isOnline: PropTypes.bool,
};

UserStatus.defaultProps = {
  className: "",
  isOnline: false,
};

export default UserStatus;
