import {
  GET_BALANCE,
  GET_BALANCE_ERROR,
  GET_BALANCE_SUCCESS,
  GET_ACCOUNT_BALANCE,
  GET_ACCOUNT__BALANCE_SUCCESS,
  GET_ACCOUNT__BALANCE_ERROR,
} from "../const";

const initialState = {
  balance: {
    loading: false,
    data: [],
    error: null,
  },
  accountBalance: {
    loading: false,
    data: {},
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE:
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: true,
          error: null,
        },
      };
    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        balance: {
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case GET_BALANCE_ERROR:
      return {
        ...state,
        balance: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    case GET_ACCOUNT_BALANCE:
      return {
        ...state,
        accountBalance: {
          ...state.accountBalance,
          loading: true,
          error: null,
        },
      };
    case GET_ACCOUNT__BALANCE_SUCCESS:
      return {
        ...state,
        accountBalance: {
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case GET_ACCOUNT__BALANCE_ERROR:
      return {
        ...state,
        accountBalance: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
