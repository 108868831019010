import {connect} from "react-redux";
import {doLogin, doGoogleLogin, getUserName} from "../../../actions/UserAction";
import LoginPage from "../components/LoginPage";

export default connect(null, {
  doLogin,
  doGoogleLogin,
  getUserName,

}
)(LoginPage);
