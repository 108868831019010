const t0 = new Date;
const t1 = new Date;

export default function newInterval(floori, offseti, count, field) {
  function interval(date) {
    return floori(date = new Date(+date)), date;
  }

  interval.floor = interval;

  interval.ceil = function(date) {
    return floori(date = new Date(date - 1)), offseti(date, 1), floori(date), date;
  };

  interval.round = function(date) {
    const d0 = interval(date);
    const d1 = interval.ceil(date);
    return date - d0 < d1 - date ? d0 : d1;
  };

  interval.offset = function(date, step) {
    return offseti(date = new Date(+date), step == null ? 1 : Math.floor(step)), date;
  };

  interval.range = function(start, stop, step, duration) {
    const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const range = []; let previous;
    start = interval.ceil(start);
    step = step == null ? 1 : Math.floor(step);
    if (!(start < stop) || !(step > 0)) return range; // also handles Invalid Date
    do {
      previous = new Date(+start);
      const preStart = new Date(start);
      offseti(start, step);
      if (duration == 2592000000 || duration == 7776000000){
        const preMonth = preStart.getUTCMonth();
        if (preMonth == 0){
          range.push([preStart, preStart.getUTCFullYear()]);
        } else {
          range.push([preStart, shortMonths[preStart.getUTCMonth()]]);
        }
      } else if (duration >= 86400000 && duration <= 604800000){
        const nextStart = new Date(start);
        const preMonth = preStart.getUTCMonth();
        const nextMonth = nextStart.getUTCMonth();
        if ((preMonth < nextMonth || preMonth == 11 && nextMonth == 0) && nextStart.getUTCDate() > 1){
          nextStart.setUTCDate(1);
          const diffDays = parseInt((nextStart - preStart) / (1000 * 60 * 60 * 24));
          if (diffDays > 2){
            range.push([preStart, preStart.getUTCDate()]);
          }
          if (nextMonth == 0){
            range.push([nextStart, nextStart.getUTCFullYear()]);
          } else {
            range.push([nextStart, shortMonths[nextStart.getUTCMonth()]]);
          }
        } else if (preStart.getUTCDate() != 2){
          range.push([preStart, preStart.getUTCDate()]);
        }
      } else if (duration <=43200000 && duration >=3600000 ){
        let hour = preStart.getUTCHours();
        if (hour == 0){
          range.push([preStart, preStart.getUTCDate()]);
        } else {
          if (hour < 10){
            hour = '0' + hour;
          }
          range.push([preStart, hour +":00"]);
        }
      } else if (duration <=1800000 && duration >=60000 ){
        let hour = preStart.getUTCHours();
        let min = preStart.getUTCMinutes();
        if (hour == 0 && min == 0){
          range.push([preStart, preStart.getUTCDate()]);
        } else {
          if (hour < 10){
            hour = '0' + hour;
          }
          if (min < 10){
            min = '0' + min;
          }
          range.push([preStart, hour+':'+min]);
        }
      } else {
        range.push([preStart, preStart.getUTCDate()]);
      }
      // }
      floori(start);
    } while (previous < start && start < stop);
    return range;
  };

  interval.localRange = function(start, stop, step, duration) {
    const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const range = []; let previous;
    start = interval.ceil(start);
    step = step == null ? 1 : Math.floor(step);
    if (!(start < stop) || !(step > 0)) return range; // also handles Invalid Date
    do {
      previous = new Date(+start);
      const preStart = new Date(start);
      offseti(start, step);
      if (duration === 2592000000 || duration === 7776000000){
        const preMonth = preStart.getMonth();
        if (preMonth === 0){
          range.push([preStart, preStart.getFullYear()]);
        } else {
          range.push([preStart, shortMonths[preStart.getMonth()]]);
        }
      } else if (duration >= 86400000 && duration <= 604800000){
        const nextStart = new Date(start);
        const preMonth = preStart.getMonth();
        const nextMonth = nextStart.getMonth();
        if ((preMonth < nextMonth || preMonth === 11 && nextMonth === 0) && nextStart.getDate() > 1){
          nextStart.setDate(1);
          const diffDays = parseInt((nextStart - preStart) / (1000 * 60 * 60 * 24));
          if (diffDays > 2){
            range.push([preStart, preStart.getDate()]);
          }
          if (nextMonth === 0){
            range.push([nextStart, nextStart.getFullYear()]);
          } else {
            range.push([nextStart, shortMonths[nextStart.getMonth()]]);
          }
        } else if (preStart.getDate() !== 2){
          range.push([preStart, preStart.getDate()]);
        }
      } else if (duration <=43200000 && duration >=3600000 ){
        let hour = preStart.getHours();
        if (hour === 0){
          range.push([preStart, preStart.getDate()]);
        } else {
          if (hour < 10){
            hour = '0' + hour;
          }
          range.push([preStart, hour + ":00"] );
        }
      } else if (duration <=1800000 && duration >=60000 ){
        let hour = preStart.getHours();
        let min = preStart.getMinutes();
        if ((hour === 0 && min === 0)){
          range.push([preStart, preStart.getDate()]);
        } else {
          if (hour < 10){
            hour = '0' + hour;
          }
          if (min < 10){
            min = '0' + min;
          }
          range.push([preStart, hour+':'+min]);
        }
      } else {
        range.push([preStart, preStart.getDate()]);
      }
      // }
      floori(start);
    } while (previous < start && start < stop);
    return range;
  };

  interval.filter = function(test) {
    return newInterval(function(date) {
      if (date >= date) while (floori(date), !test(date)) date.setTime(date - 1);
    }, function(date, step) {
      if (date >= date) {
        if (step < 0) {
          while (++step <= 0) {
            while (offseti(date, -1), !test(date)) {} // eslint-disable-line no-empty
          }
        } else {
          while (--step >= 0) {
            while (offseti(date, +1), !test(date)) {} // eslint-disable-line no-empty
          }
        }
      }
    });
  };

  if (count) {
    interval.count = function(start, end) {
      t0.setTime(+start), t1.setTime(+end);
      floori(t0), floori(t1);
      return Math.floor(count(t0, t1));
    };

    interval.every = function(step, duration) {
      step = Math.floor(step);
      return !isFinite(step) || !(step > 0) ? null :
          !(step > 1) ? {interval, duration} :
          {interval: interval.filter(field ?
              function(d) {
                return field(d) % step === 0;
              } :
              function(d) {
                return interval.count(0, d) % step === 0;
              }), duration};
    };
  }

  return interval;
}
