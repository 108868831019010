import {connect} from "react-redux";
import FeedFilterModal from "../components/FeedWidget/FeedFilterModal";
import {addFilter, removeFilter, updateFilter} from "../../../actions/FeedActions";
import {toggleModal} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      return {
        filters: state.feed.filters,
        isOpen: state.markets.modals.feedFilter,
      };
    }, {
      toggleModal,
      addFilter,
      removeFilter,
      updateFilter,
    },
)(FeedFilterModal);
