import {connect} from "react-redux";
import ChartSettingsModal from "../components/ChartSettingsModal";
import {toggleModal, updateWidget} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;

      return {
        isOpen: state.markets.modals.chartSettings,
        widget: activeWidget,
      };
    }, {
      toggleModal,
      updateWidget,
    },
)(ChartSettingsModal);
