
// Calculate human-readable price scale matching prace range
export function humanScalePrice(priceRange, priceScaleCnt) {
  const ranges = [];
  for (let i = 1; i < 18; i++) {
    const powerOfTen = Math.pow(10, i);
    ranges.push(powerOfTen / 4 / 10000000000);
    ranges.push(powerOfTen / 2 / 10000000000);
    ranges.push(powerOfTen / 10000000000);
  }
  const scaleValue = priceRange / priceScaleCnt;
  let humanScaleDiff = 100000000;
  let humanScale = 0;
  for (const range of ranges) {
    const diff = Math.abs(scaleValue - range);
    if (humanScaleDiff > diff) {
      humanScaleDiff = diff;
      humanScale = range;
    }
  }
  return humanScale;
}

export function formatPrice(price) {
  if (price == null) {
    return '';
  }
  const unit = '';
  return price.toFixed(8) + unit;
}

export function dateToTimeScale(quote, localeData) {
  const day = quote.date.getDate();
  new Date().get;
  if (day == 1){
    return localeData.monthNames[quote.date.getMonth()];
  } else {
    return day;
  }
}

// Point inside box check
export function inside(point, box) {
  return (
    point[1] > box[1] && point[1] < (box[1] + box[3]) &&
    point[0] > box[0] && point[0] < (box[0] + box[2])
  );
}

export function relativeFontSize(width, maxSize) {
  const size = width / 27;
  return Math.max(22, Math.min(maxSize, size));
}
