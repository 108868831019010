import {v4} from "uuid";

export default ({ts1, ts2, price1, price2, base, quote})=> ( {
  id: v4(),
  type: 'trendline',
  points: 2,
  ts1,
  ts2,
  price1,
  price2,
  settings: {},
  exchange: 'binance',
  market: `${base}-${quote}`
});
