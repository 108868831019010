/* eslint max-len: 0 */
import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import FontAwesome from "../../../components/FontAwesome";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import WidgetCoinFilter from "../../../components/WidgetCoinFilter/WidgetCoinFilter";
import Loader from "../../../components/Loader";
import FlagIconFactory from "react-flag-icon-css";
const FlagIcon = FlagIconFactory(React, { useCssModules: false });
import styles from "./MarketsModal.scss";
import {getMarketExchanges} from "../../../api/MarketsApi";

const MARKET_ITEM_ROW_HEIGHT=50;
class MarketsModal extends Component {
  constructor(props) {
    super(props);

    this.input = null;
    this.timeout = null;
    this.state = {
      search: "",
      exchange: props.widget && props.widget.exchange,
      exchangesOpened: false,
      base: "BTC",
      firstShowedElement: 0,
      list: [],
      isLoading: true
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!this.state.exchange && this.props.widget?.exchange) {
      this.setState({
        exchange: this.props.widget.exchange
      });
      if (this.props.isOpen){
        await this.loadData();
      }
    }
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.getExchangesListAction();
      await this.loadData();
      if (this.props.widget && this.props.widget.exchange) {
        this.setState({exchange: this.props.widget.exchange});
      }
      // setTimeout(() => {
      //   this.props.getMarketsListAction({exchange: this.state.exchange !== "all" ? this.state.exchange : undefined});
      // }, 200);
    } else if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        search: "",
        exchangesOpened: false,
      });
    }
  }

  async loadData(){
    const {data} = await getMarketExchanges(this.state.exchange);
    this.setState({
      list: data,
      isLoading: false
    });
  }

  updateMarket = (market) => {
    const {toggleModal, updateWidget, widget} = this.props;
    debugger;
    updateWidget({
      ...widget,
      exchange: this.state.exchange,
      name: `${market.quote}-${market.base}`,
      marketId: market.id
    });
    toggleModal("markets");
  };

  handleSearchInput = (event) => {
    clearTimeout(this.timeout);
    this.setState({
      search: event.target.value,
    });
    this.timeout = setTimeout(() => {
      // this.props.getMarketsListAction({
      //   exchange: this.state.exchange !== "all" ? this.state.exchange : undefined,
      //   symbol: this.state.search.toUpperCase(),
      // });
    }, 500);
  };

  handleChangeExchange = (exchange) => {
    getMarketExchanges(exchange).then(({data})=>{
      this.setState({
        list: data,
        isLoading: false,

      });
    });
    this.setState({
      isLoading: true,
      exchange: exchange,
      exchangesOpened: false,
    });
  };

  showExchangeName = (item) => {
    const {exchanges} = this.props;
    const exist = exchanges.find((exchange) => this.state.exchange === exchange.id);
    return exist ? exist.name : item;
  };

  filterExchange = (exchange) => {
    const {widget} = this.props;
    switch (widget.type) {
      case "trade":
        return exchange.hasOrder;
      case "markets":
        return exchange.hasPublicAPI && exchange.hasFetchMarkets;
      case "chart":
        return exchange.hasPublicAPI && exchange.hasFetchOHLCV;
      case "price":
        return exchange.hasPublicAPI && exchange.hasFetchTicker;
      default:
        return exchange.hasPublicAPI;
    }
  };

  replaceCodes = (code) => {
    return code.replace("uk", "gb");
  };

  getFileNameForMarket = ({base}) => {
    return `${base}`;
  }

  drawExchange = (exchange) => {
    return (
      <div
        className={styles.MarketItem}
        key={exchange.id}
        onClick={(event) => {
          event.preventDefault();
          this.handleChangeExchange(exchange.id);
        }}
        role="button"
        tabIndex="0"
      >
        <div className={styles.Logo1}>
          <img
            alt=""
            height={18}
            src={`/img/exchanges/${exchange.id}.png`}
          />
        </div>
        <div
          className={styles.Name}>
          <span
            className="ticker"
          >
            {exchange.name}
          </span>
          <span
            className="base"
          >/ CEX
          </span>
          <div className="tickerLong">
            Crypto
          </div>
        </div>
        <div className={styles.exchangeGroup}>
          <div className={styles.Icon}>
            {exchange.countries && <FlagIcon code={this.replaceCodes(exchange.countries[0].toLowerCase())}/>}
          </div>
          <div className={styles.Exchange}>
            {exchange.countries ? exchange.countries[0] : "N/A"}
          </div>
        </div>
      </div>
    );
  };

  handleScroll({scrollTop, innerHeight}){
    clearTimeout(this.debounce);
    this.debounce=setTimeout(()=>{
      this.setState({firstShowedElement: Math.floor((scrollTop/MARKET_ITEM_ROW_HEIGHT))});
    }, 10);
  }

  render() {
    const {isOpen, toggleModal, exchanges} = this.props;
    const {exchangesOpened, base, firstShowedElement} = this.state;
    const currentExchange = exchanges.find((exchange) => exchange.id === this.state.exchange);
    let sortedData=this.state.list;
    if (base==='ALTS'){
      sortedData=sortedData
          .filter(({quote})=>quote!==`BTC`)
          .filter(({quote})=>quote!==`USDT`)
          .filter(({quote})=>quote!==`USDC`)
          .filter(({quote})=>quote!==`BIDR`)
          .filter(({quote})=>quote!==`AUD`)
          .filter(({quote})=>quote!==`EUR`)
          .filter(({quote})=>quote!==`GBP`)
          .filter(({quote})=>quote!==`USD`);
    } else if (base){
      sortedData=sortedData
          .filter(({quote})=>quote===base);
    }
    const paginatedSortedData=sortedData.filter(({symbol})=>symbol?.includes(this.state.search.toUpperCase()));

    return (
      <Modal
        autoFocus={false}
        onEnter={() => setTimeout(() => {
          this.input && this.input.focus();
        }, 500)}
        isOpen={isOpen}
        toggle={() => toggleModal("markets")}
        className={styles.MarketsModal}
        size="lg"
      >
        <ModalHeader
          className={styles.Header}
          toggle={() => toggleModal("markets")}
        >
          Symbols <span>& Pairs</span>
          <div className={styles.test1}>
            <FontAwesome
              prefix="far"
              name="search"
            />
            <div className={styles.Search}>
              <input
                ref={(input) => {
                  this.input = input;
                }}
                type="text"
                placeholder="Filter Ticker..."
                autoFocus
                onChange={this.handleSearchInput}
                value={this.state.search}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody className={styles.Blocks}>
          <div className={styles.groupExchange}>
            <WidgetCoinFilter/>
            <div className={styles.cex}> Exchange:
              <button
                className={styles.exchange1}
                onClick={(event) => {
                  event.preventDefault();
                  this.setState({exchangesOpened: !exchangesOpened});
                }}
              >
                {!currentExchange || currentExchange === "All" ? "SELECT" : currentExchange.name.toUpperCase()}

              </button>
            </div>
          </div>
          <div>
            {exchangesOpened && (
              <Fragment>
                <div
                  className={styles.MarketsList}
                >
                  {exchanges.filter(this.filterExchange).map(this.drawExchange)}
                </div>
              </Fragment>
            )}
            {!exchangesOpened && (
              <div
                className={styles.MarketsList}
              >
                <div style={{height: MARKET_ITEM_ROW_HEIGHT*firstShowedElement}} />

                {this.state.isLoading ? (
                  <Loader/>
                ) : (paginatedSortedData.length === 0 ? (
                  <div className={styles.MarketItem1}>
                    Nothing found. Please try again with some different keywords.
                  </div>
                ) : (<>
                  <div style={{height: (MARKET_ITEM_ROW_HEIGHT*(sortedData.length-firstShowedElement))}}>{paginatedSortedData.map((item) => (
                    <div
                      className={`${item.symbol}-${item.exchange}-${item.base} ${styles.MarketItem}`}
                      key={`${item.symbol}-${item.exchange}-${item.created}`}
                      onClick={() => this.updateMarket(item)}
                      role="button"
                      tabIndex="0"
                    >
                      <div className={styles.ticker}>
                        {item.symbol.split("/")[0]}
                      </div>
                      <div>
                        <span className={styles.longname}>
                          {item.longName}Longname <span className="base">/ {item.symbol.split("/")[1]}</span>
                        </span>
                        <div className={styles.base}>
                          Store of Value
                        </div>
                      </div>

                      <div className={styles.exchangeGroup}>
                        <div>
                          <div className={styles.base2}>
                            Crypto
                          </div>
                          <div className={styles.Exchange}>
                            <img
                              className={styles.Icon}
                              alt=""
                              height={18}
                              src={`/img/exchanges/${this.state.exchange}.png`}
                            /> {this.showExchangeName(item.exchange)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}</div></>))}
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

MarketsModal.propTypes = {
  exchanges: PropTypes.array,
  getExchangesListAction: PropTypes.func,
  getMarketsListAction: PropTypes.func,
  isOpen: PropTypes.bool,
  markets: PropTypes.array,
  marketsLoading: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
};

MarketsModal.defaultProps = {
  exchanges: [],
  getExchangesListAction: () => false,
  getMarketsListAction: () => false,
  isOpen: false,
  markets: [],
  marketsLoading: false,
  toggleModal: () => false,
  widget: undefined,
};

export default MarketsModal;
