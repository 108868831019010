/* eslint react/jsx-handler-names: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader, NavLink, Nav, NavItem, TabContent, TabPane} from "reactstrap";
import FontAwesome from "../../../components/FontAwesome";
import styles from "./ProfileSettingsModal.scss";
import classNames from "classnames";
import Switch from 'rc-switch';
import ReactTooltip from 'react-tooltip';

function onChange(value, event) {
  // eslint-disable-next-line no-console
  console.log(`switch checked: ${value}`, event);
}

class ProfileSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      src: props.user.avatar,
      userName: props.user.username,
      email: props.user.email,
      token: "",
      password: "",
      confirmPassword: "",
      notificationEnabled: (props.user.settings && props.user.settings.desktopNotify) || false,
      disabled: false,
    };
  }

  componentDidMount() {
    this.props.getTmpSecret();
  }

  componentDidUpdate(prevProps) {
  }

  toggle = (Switch) => {
    const { disabled } = this.state;
    this.setState({
      disabled: !disabled,
    });
  };

  toggle = (tab) => {
    this.state.activeTab !== tab && this.setState({
      activeTab: tab,
    });
  };

  handleSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
        }),
      false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleSaveEmail = () => {
    this.props.editUserAction({
      email: this.state.email,
    });
  };

  handleSaveAccountSettings = () => {
    debugger;
    this.props.editUserAction({
      avatar: this.state.src,
      username: this.state.userName,
    });
  };

  handleSavePassword = () => {
    const {password, confirmPassword} = this.state;
    if (password === confirmPassword) {
      this.props.editUserAction({
        password: password,
      });
    }
  };

  handleSaveSecret = () => {
    const {editUserAction, tmpSecretKey} = this.props;
    editUserAction({
      secret: {
        token: this.state.token,
        secret: tmpSecretKey,
      },
    });
  };

  handleSaveNotification = () => {
    this.setState({
      notificationEnabled: !this.state.notificationEnabled,
    }, () => {
      this.props.editUserAction({
        notification: this.state.notificationEnabled,
      });
    });
  };

  closeModal = () => {
    this.setState({
      src: this.props.user.avatar,
      userName: this.props.user.username,
    });
    this.props.toggleModal("profileSettings");
  };

  render() {
    const {isOpen} = this.props;
    const {userName} = this.state;
    const { activeTab } = this.state;
    const { disabled } = this.state;

    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={this.closeModal}
        className={classNames(styles.ProfileSettingsModal, styles.moveable)}
        contentClassName="custom-modal-style"
        size="lg"
      >
        <ModalHeader
          className={styles.Header}
          toggle={this.closeModal}
        >
          Chat Settings
        </ModalHeader>
        <div className={styles.LeftPanel}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({active: this.state.activeTab === "1"})}
                onClick={() => this.toggle("1")}
              >
                <FontAwesome
                  className={styles.Icon}
                  prefix="fas"
                  name="user"
                /> Profile
              </NavLink>
              <NavItem>
                <NavLink
                  className={classNames({active: this.state.activeTab === "2"})}
                  onClick={() => this.toggle("2")}
                >
                  <FontAwesome
                    className={styles.Icon}
                    prefix="fas"
                    name="comment-alt"
                  /> Notifications
                </NavLink>
                <NavLink
                  className={classNames({active: this.state.activeTab === "3"})}
                  onClick={() => this.toggle("3")}
                >
                  <FontAwesome
                    className={styles.Icon}
                    prefix="fas"
                    name="user-shield"
                  /> Moderation
                </NavLink>
                <div className="social">
                  <a data-tip data-for='twitterTooltip'>
                    <NavLink
                      className="socialIcons"
                      href="https://twitter.com/officialaurlix"
                      target="_blank">
                      <FontAwesome
                        className={styles.Icon}
                        prefix="fab"
                        name="twitter-square"
                        width="14"
                        height="14"
                      />
                    </NavLink>
                  </a>
                  <ReactTooltip
                    id='twitterTooltip'
                    offset="{'top': -7}"
                    arrowColor='#30333a'
                    className={styles.buttonTooltip}
                    effect='solid'
                  >
                    <span>Follow us on Twitter.</span>
                  </ReactTooltip>
                  <a data-tip data-for='facebookTooltip'>
                    <NavLink
                      className="socialIcons"
                      href="https://www.facebook.com/aurlix"
                      target="_blank">
                      <FontAwesome
                        className={styles.Icon}
                        prefix="fab"
                        name="facebook-square"
                        width="14"
                        height="14"
                      />
                    </NavLink>
                  </a>
                  <ReactTooltip
                    id='facebookTooltip'
                    offset="{'top': -7}"
                    arrowColor='#30333a'
                    className={styles.buttonTooltip}
                    effect='solid'
                  >
                    <span>Follow us on Facebook.</span>
                  </ReactTooltip>
                  <a data-tip data-for='youtubeTooltip'>
                    <NavLink
                      className="socialIcons"
                      href="https://www.youtube.com/channel/UCJ2E3A834yMJLMRF2E7254Q"
                      target="_blank">
                      <FontAwesome
                        className={styles.Icon}
                        prefix="fab"
                        name="youtube-square"
                        width="14"
                        height="14"
                      />
                    </NavLink>
                  </a>
                  <ReactTooltip
                    id='youtubeTooltip'
                    offset="{'top': -7}"
                    arrowColor='#30333a'
                    className={styles.buttonTooltip}
                    effect='solid'
                  >
                    <span>Follow us on Youtube.</span>
                  </ReactTooltip>
                </div>
              </NavItem>
            </NavItem>
          </Nav>
          <ModalBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="wrapper">
                  <div className={styles.Container}>
                    <div className={classNames(styles.Col, styles.Left)}>
                      <div className={styles.InputGroup}>
                        <span className={styles.title}>Profile photo <a data-tip data-for='profileInfo'>
                          <FontAwesome
                            className={styles.infoTooltip}
                            name="info-circle"
                            prefix="fal"
                          />
                        </a>
                        <ReactTooltip
                            id='profileInfo'
                            offset="{'top': -7}"
                            arrowColor='#30333a'
                            className={styles.buttonTooltip}
                            effect='solid'
                        >
                            <span>Images must be .png or .jpg format.</span>
                          </ReactTooltip></span>
                        <label
                          className={styles.FileButton}
                          htmlFor="avatar-input"
                        >
                          {this.state.src ? (
                            <img src={this.state.src} alt=""/>
                          ) : (
                            <div className={styles.DefaultAvatar}>
                              {userName ? userName[0].toUpperCase() : ""}
                            </div>
                          )}
                          <div className={styles.Change}>
                            <FontAwesome
                              wrapperClassName={styles.Icon}
                              prefix="fas"
                              name="camera"
                            />
                            Change
                          </div>
                        </label>
                        <input
                          id="avatar-input"
                          type="file"
                          onChange={this.handleSelectFile}
                        />
                        <span className={styles.title}>Change Username</span>
                        <input
                          placeholder="New username..."
                          onChange={(event) => this.handleChange("userName", event.target.value)}
                        />
                        <p>Your current username is {userName}. To change it enter a new username.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                {this.state.activeTab === "2" && (
                  <div className="wrapper">
                    <div className={styles.Container}>
                      <div className={classNames(styles.Col, styles.Left)}>
                        <div className={styles.InputGroup}>
                          <span className={styles.title}>Chat Notifications</span>
                          <div>
                            <p>Enable / disable chat notifications for messages.
                              <Switch
                                defaultChecked
                                onChange={onChange}
                                disabled={disabled}
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </TabPane>
              <TabPane tabId="3">
                {this.state.activeTab === "3" && (
                  <div className="wrapper">
                    <div className={styles.Container}>
                      <div className={classNames(styles.Col, styles.Left)}>
                        <div className={styles.InputGroup}>
                          <span className={styles.title}>Blocked users</span>
                          <div>
                            <p>Remove blocked users here.</p>
                            <table>
                              <thead>
                                <tr className={styles.tabletr}>
                                  <th className={styles.tablethName}>
                                    Name
                                    <span className="fa-layers">
                                      <FontAwesome
                                        name="chevron-up"
                                        dataFaTransform="up-6"
                                      />
                                      <FontAwesome
                                        name="chevron-down"
                                        dataFaTransform="down-6"
                                      />
                                    </span>
                                  </th>
                                  <th className={styles.tablethDate}>
                                    Date added
                                    <span className="fa-layers">
                                      <FontAwesome
                                        name="chevron-up"
                                        dataFaTransform="up-6"
                                      />
                                      <FontAwesome
                                        name="chevron-down"
                                        dataFaTransform="down-6"
                                      />
                                    </span>
                                  </th>
                                  <th className={styles.tablethAction}>
                                    Action
                                    <span className="fa-layers">
                                      <FontAwesome
                                        name="chevron-up"
                                        dataFaTransform="up-6"
                                      />
                                      <FontAwesome
                                        name="chevron-down"
                                        dataFaTransform="down-6"
                                      />
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className={styles.tbodyScanner}>
                                <tr className={styles.tabletr}>
                                  <td className={styles.tabletd}>
                                    John Doe
                                  </td>
                                  <td className={styles.tabletd}>
                                    14/08/2021
                                  </td>
                                  <td className={styles.tabletd}>
                                    <button className={styles.tableButtonUnblock}>
                                      unblock
                                    </button>
                                  </td>
                                </tr>
                                <tr className={styles.tabletr}>
                                  <td className={styles.tabletd}>
                                    Jane Doe
                                  </td>
                                  <td className={styles.tabletd}>
                                    03/12/2022
                                  </td>
                                  <td className={styles.tabletd}>
                                    <button className={styles.tableButtonUnblock}>
                                      unblock
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </TabPane>
            </TabContent>
            <div className="buttons">
              <button
                className={styles.SaveButton}
                onClick={this.handleSaveAccountSettings}
              >
                Save
              </button>
              <button
                className={styles.CancelButton}
                onClick={this.closeModal}
                type="button"
              >
                Cancel
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }
}

ProfileSettingsModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  editUserAction: PropTypes.func,
  user: PropTypes.object,
  getTmpSecret: PropTypes.func,
  tmpSecretImg: PropTypes.string,
  tmpSecretKey: PropTypes.string,
};

ProfileSettingsModal.defaultProps = {
  data: undefined,
  isOpen: false,
  toggleModal: () => false,
  editUserAction: () => false,
  user: undefined,
  getTmpSecret: () => false,
  tmpSecretImg: undefined,
  tmpSecretKey: undefined,
};

export default ProfileSettingsModal;
