import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import numeral from "numeral";
import styles from "./OrderSettings.scss";
import SelectBox from "../../../../components/SelectBox/SelectBox";
import SelectBoxItem from "../../../../components/SelectBox/SelectBoxItem";
import ReactTooltip from 'react-tooltip';
import {  FormGroup, Label} from "reactstrap";
import FloatValue from "../../../../components/FloatValue";
import FontAwesome from "../../../../components/FontAwesome";
import './rc-slider.scss';
import {OrderTypes} from './OrderTypes.js';
import TimeInForce from './TimeInForce.js';
import Select from 'react-select';
function onChange(value, event) {
  // eslint-disable-next-line no-console
  console.log(`switch checked: ${value}`, event);
}

const tradingTypes=[
  {label: <span>Simple</span>, value: "Simple"},
  {label: <span>Command Line</span>, value: "Command"},
  {label: <span>Advanced</span>, value: "Advanced"},
];


const actionTypes = [
  {label: "Buy", value: "BUY"},
  {label: "Sell", value: "SELL"},
];

const orderTypes = [
  {name: "LMT", value: ""},
  {name: "SNP", value: "SNIPER"},
];

const orderDurations = [
  {name: "GTC", value: "GTC"},
];

const limitPrices = {
  last: 0,
  ask: 0,
  bid: 0,
};

const initialTempOrder = {
  quantity: 0,
  limitPrice: 0,
  amountToBuy: 0,
};

class OrderSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountInput: null,
      value: 0,
      selectedOptions: "limitPrice",
      account: props.accounts && props.accounts[0] ? props.accounts[0].name : "",
      accountExchange: props.accounts && props.accounts[0] && props.accounts[0].exchange,
      actionType: actionTypes[0].value,
      orderType: orderTypes[0].value,
      orderDuration: orderDurations[0].value,
      dropdownOpen: false,
      accountsDropdownOpen: false,
      ...initialTempOrder,
    };
  }

  componentDidMount() {
    const {marketSummary, fetchAccountBalance, widget} = this.props;
    if (widget.price) {
      this.handleChange("limitPrice", widget.price);
    } else if (marketSummary.last) {
      this.handleChange("limitPrice", marketSummary.last.toFixed(8));
    }
    if (widget.orderType) {
      this.handleChange("actionType", widget.orderType === "buy" ? "BUY" : "SELL");
    }
    widget.name && widget.exchange && fetchAccountBalance(this.state.account);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      accounts, widget, marketSummary, fetchAccountBalance, addTempOrder, updateTempOrder, tempOrder, orders,
      removeTempOrder,
    } = this.props;

    if (marketSummary.symbol !== prevProps.marketSummary.symbol) {
      if ((!prevProps.marketSummary.symbol && !this.state.limitPrice) || prevProps.marketSummary.symbol) {
        marketSummary.last && this.handleChange("limitPrice", marketSummary.last.toFixed(8));
      }
    }

    if (prevProps.widget && (prevProps.widget.name !== widget.name || prevProps.widget.exchange !== widget.exchange)) {
      const account = accounts && accounts[0] ? accounts[0].name : "";
      this.setState({
        ...this.state,
        ...initialTempOrder,
        account: account,
      });
    }
    if (prevState.account !== this.state.account) {
      widget.name && widget.exchange && fetchAccountBalance(this.state.account);
    }

    let tempShouldUpdate = false;
    const orderData = {
      name: widget.name,
      exchange: widget.exchange,
      yValue: this.state.limitPrice,
      amount: this.state.quantity,
      type: this.state.actionType,
    };

    Object.keys(this.state).forEach((key) => {
      if (prevState[key] !== this.state[key]) {
        tempShouldUpdate = true;
      }
    });

    if (tempShouldUpdate && orderData.amount != 0) {
      if (tempOrder.id) {
        updateTempOrder({
          ...tempOrder,
          ...orderData,
        });
      } else {
        addTempOrder(orderData);
      }
    }

    if (tempOrder.yValue && prevProps.tempOrder.yValue !== tempOrder.yValue) {
      this.handleChange("limitPrice", tempOrder.yValue);
    }

    if (prevProps.tempOrder.id && !tempOrder.id) {
      this.setState({
        ...initialTempOrder,
        limitPrice: marketSummary.last.toFixed(8),
      });
    }

    if (tempOrder.id && orders.length > prevProps.orders.length) {
      removeTempOrder(tempOrder.id);
    }
  }

  calculateQuantity = () => {
    const {actionType, amountToBuy, limitPrice} = this.state;
    if (limitPrice) {
      const value = actionType === "BUY" ? amountToBuy * limitPrice : (limitPrice !== 0 ? amountToBuy / limitPrice : 0);

      this.setState({
        quantity: numeral(value).format("0.[00000000]"),
      });
    }
  };

  calculateAmountToBuy = () => {
    const {actionType, quantity, limitPrice} = this.state;
    if (limitPrice) {
      const value = actionType === "BUY" ? (limitPrice !== 0 ? quantity / limitPrice : 0) : quantity * limitPrice;

      this.setState({
        amountToBuy: numeral(value).format("0.[00000000]"),
      });
    }
  };

  handleChange = (field, value) => {
    const numberFields = ["quantity", "limitPrice", "amountToBuy"];
    const isNumber = numberFields.indexOf(field) >= 0;
    if (value < 0 && numberFields.indexOf(field) >= 0) {
      value = Math.abs(value);
    }

    value = value.toString();
    if (!isNumber || value === "" || (isNumber && /^[0-9.]+$/.test(value) && (value.match(/\./g) || []).length <= 1)) {
      this.setState({
        [field]: value,
      });
    }

    if (value !== undefined) {
      setTimeout(() => (field === "quantity" || field === "limitPrice") ?
        this.calculateAmountToBuy() : this.calculateQuantity(), 200);
    }
  };

  handleValueBlur = (field, value) => {
    if (value === "") {
      this.setState({
        [field]: 0,
      });
    }
  };

  setPriceLimit = (price) => {
    this.setState({
      limitPrice: price || 0,
    });
    setTimeout(() => this.calculateAmountToBuy(), 200);
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  calculateBalance = () => {
    const {accountBalance, marketSummary} = this.props;
    const {actionType} = this.state;

    let balance = 0;
    const currencies = marketSummary.symbol && marketSummary.symbol.split("/");
    const currency = actionType === "BUY" ? (currencies ? currencies[1] : "BTC") : (currencies ? currencies[0] : "BTC");
    if (accountBalance.balance) {
      const symbol = accountBalance.balance.find((item) => item.symbol === currency);
      balance = symbol ? symbol.free : balance;
    }
    return balance;
  };

  getInlineInputOffset = (value) => ({
    left: 16 + (value !== undefined ? (Math.round(value.toString().length * 7.5 * 100) / 100) : 0),
  });
  getInlineInputOffsetPrice = (value) => ({
    left: 13 + (value !== undefined ? (Math.round(value.toString().length * 7.7 * 100) / 100) : 0),
  });

  handleCreateOrder = async () => {
    const {createOrderAction, widget} = this.props;
    const {account, actionType, orderType, limitPrice, amountToBuy, quantity} = this.state;
    await createOrderAction(widget.name, account, {
      actionType: actionType,
      orderType: orderType,
      limitPrice: limitPrice,
      amountToBuy: actionType === "BUY" ? amountToBuy - amountToBuy * 0.0025 : quantity - quantity * 0.0025,
    });
  };

  onSliderChange = (value) => {
    this.setState({ value });
  };
  setActivityKey = () => {
    this.setState({
      activeKey: ['2'],
    });
  };

  toggle = () => {
    const { accordion } = this.state;
    this.setState({
      accordion: !accordion,
    });
  };

  handleCollapsibleChange = (e: any) => {
    const values = [undefined, 'header', 'disabled'];
    this.setState({
      collapsible: values[e.target.value],
    });
  };
  onChange = (activeKey: string) => {
    this.setState({
      activeKey,
    });
  };
  expandIcon({ isActive }) {
    return (
      <FontAwesome
        className="InlineInputPriceCaret"
        prefix="far"
        name="angle-down"
      />
    );
  }
  setValue = (index) => {
    this.setState({
      value: index,
    });
  };
  handleChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  }

  render() {
    const {accounts, toggleModal, marketSummary} = this.props;
    const {account, accountInput, accountExchange,
      actionType, orderType, orderDuration, quantity, dropdownOpen,
      limitPrice, activeKey, amountToBuy, selectedOptions} = this.state;
    const currencies = marketSummary.symbol && marketSummary.symbol.split("/");
    const currency = actionType === "BUY" ?
      (currencies ? currencies[1] : "BTC") : (currencies ? currencies[0] : "BTC");
    const toCurrency = actionType === "BUY" ?
      (currencies ? currencies[0] : "BTC") : (currencies ? currencies[1] : "BTC");
    const accountBalance = this.calculateBalance();
    const currentAccount = accounts.find((item) => item.name === account);
    const marks = {
      0: <div className={styles.dots}>0%</div>,
      100: <div className={styles.dots} style={{marginRight: '20px' }}>100%</div>,
    };
    const isBackgroundRed = true;
    return (
      <div className="OrderSettings">
        <div className={styles.tradingOptionsDiv}>
          <div className={styles.tradingOptions}>
            <div className={styles.greyDarkHeader}>Type</div>
            <div className={styles.greyLightHeader}>
              <Select
                options={tradingTypes}
                defaultValue={{label: <span>Simple</span>}}
                className="tradingTypeDropdown"
                classNamePrefix="react-select"
                onChange={this.handleChange}
                //menuIsOpen
              />
            </div>
          </div>
          <div className={styles.textHeader}>Current account details:</div>
          <div style={{borderColor: "#333740", marginLeft: 0}} className="rowtest">
            <div className={styles.greyDarkHeader}>Token/Pair
            </div>
            <div
              className={styles.outlineHeader}
              onClick={() => toggleModal("markets")}
            >
              <span className="coinName">{toCurrency}</span>
              <span className="coinName"> / {currency}</span>
            </div>
            <a data-tip data-for='tokenInfo'>
              <FontAwesome
                className={styles.infoTooltip}
                name="info-circle"
                prefix="fal"
              />
            </a>
            <ReactTooltip
              id='tokenInfo'
              offset="{'top': -7}"
              arrowColor='#30333a'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Click on the pair <br />to search more symbols</span>
            </ReactTooltip>
          </div>
          <div style={{borderColor: "#333740", marginLeft: 0}} className="rowtest">
            <div className={styles.greyDarkHeader}>
              Account
            </div>
            <div className={styles.outlineHeader}>
              <SelectBox
                className={styles.chevron}
                value="Select"
                style={{width: 100}}
              >
                {accounts
                    .filter(acc =>
                      acc.name.toLowerCase().includes((accountInput || '').toLowerCase()) ||
              acc.exchange.toLowerCase().includes((accountInput || '').toLowerCase())
                    )
                    .map((type) => (
                      <SelectBoxItem
                        key={name}
                        className={styles.patternRow}
                      >
                        {type.name}
                      </SelectBoxItem>
                    ))}
              </SelectBox>
            </div>
            <a data-tip data-for='accountInfo'>
              <FontAwesome
                className={styles.infoTooltip}
                name="info-circle"
                prefix="fal"
              />
            </a>
            <ReactTooltip
              id='accountInfo'
              offset="{'top': -7}"
              arrowColor='#30333a'
              clickable="true"
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>To add more exchange accounts <br /><a className={styles.accountInfoLink}>click here</a></span>
            </ReactTooltip>
            <div className="currentPrice">
              <span>Available:</span> <img
                alt=""
                className={styles.Icon}
                height={14}
                src={`/img/icons/${currency.toUpperCase()}.svg?`+
                `exchange=${account.exchange}`
                }
              /> <FloatValue value={accountBalance}/>
            </div>
          </div>
          <div className={styles.dateSeperator}>Primary Order</div>
          <div className="rowtest">
            <div className={styles.greyDarkHeader}>Side
            </div>
            <div
              style={{
                backgroundColor: isBackgroundRed ? '#3c9265' : 'blue',
              }}
              className={styles.outlineHeader}
            >
              <Select
                options={actionTypes}
                defaultValue={{label: <span>Buy</span>}}
                className="tradingSideDropdown"
                classNamePrefix="react-select"
                onChange={this.handleChange}
                //menuIsOpen
              />
            </div>
          </div>
          <div className="rowtest">
            <div className={styles.greyDarkHeader}>Type
            </div>
            <div
              className={styles.outlineHeader}
            >
              <Select
                options={OrderTypes}
                defaultValue={{label: <span><b>LMT</b> Limit</span>, value: "LMT"}}
                className="tradingOrderTypes"
                classNamePrefix="react-select"
                isSearchable
                onChange={this.handleChange}
              //menuIsOpen
              />
            </div>
          </div>
          <div className="rowtest">
            {selectedOptions.order}
          </div>
        </div>

        {/*  <div className="rowLong">
            <Label />
            <div className="currentPrice">
              <Label>Last Price:</Label> <img
                alt=""
                className={styles.Icon}
                height={14}
                src={`/img/icons/${currency.toUpperCase()}.svg?`+
                `exchange=${account.exchange}`
                }
              /> <FloatValue value={marketSummary.last}/>
            </div>
          </div>*/}


        {/*<FormGroup row>


          <div>
            {selectedOptions.order}
          </div>
          {/*<div className="rowtest">
            <Label><img
              alt=""
              className={styles.Icon}
              height={14}
              src={`/img/icons/${currency.toUpperCase()}.svg?`+
                `exchange=${account.exchange}`
              }
            /> {currency} Total</Label>
            <div className="InlineInput">
              <Input
                type="text"
                placeholder="0.0"
                onBlur={(event) => this.handleValueBlur("quantity", event.target.value)}
                onChange={(event) => this.handleChange("quantity", event.target.value)}
                value={parseFloat(quantity)}
              />
            </div>
          </div>

          <div className="rowtest">
            <Label><img
              alt=""
              className={styles.Icon}
              height={14}
              src={`/img/icons/${currency.toUpperCase()}.svg?`+
                `exchange=${account.exchange}`
              }
            /> {currency}  Price</Label>
            <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
              <div>
                <DropdownToggle>
                  <FontAwesome
                    className="InlineInputPriceCaret"
                    prefix="far"
                    name="angle-down"
                  />
                </DropdownToggle>
              </div>
              <div className="InlineInputPrice">
                <input
                  type="text"
                  value={marketSummary.last}
                  onBlur={(event) => this.handleValueBlur("limitPrice", event.target.value)}
                  onChange={(event) => this.handleChange("limitPrice", event.target.value)}
                />
              </div>
              <DropdownMenu>
                {Object.keys(limitPrices).map((key) => (
                  <DropdownItem
                    key={key}
                    onClick={() =>
                      this.setPriceLimit(marketSummary[key] ? marketSummary[key].toFixed(8) : limitPrices[key])
                    }
                  >
                    {key}: <FloatValue value={marketSummary[key] ? marketSummary[key] : limitPrices[key]}/>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </div>

          <div className="rowtest">
            <Label><img
              alt=""
              className={styles.Icon}
              height={14}
              src={`/img/symbols/${toCurrency.toUpperCase()}.png?`+
            `exchange=${account.exchange}`
              }
            /> {toCurrency} Total</Label>
            <div className="InlineInput">
              <input
                type="text"
                placeholder="0.0"
                onBlur={(event) => this.handleValueBlur("amountToBuy", event.target.value)}
                onChange={(event) => this.handleChange("amountToBuy", event.target.value)}
                value={parseFloat(amountToBuy)}
              />
              <div
                className={classNames("Quantity", {"Blue": actionType === "BUY"})}
                style={this.getInlineInputOffset(amountToBuy)}
              />
            </div>
          </div>*/}
        {/*</FormGroup>*/}

        <FormGroup row>
          {/*<div className="rowDivider" />*/}
          <div className={styles.ButtonBottom}>
            <div
              className="OrderType"
              style={{marginLeft: 'auto', marginBottom: 10}}
            >
              <Label>Time-in-Force</Label>
              <Select
                options={TimeInForce}
                defaultValue={{label: <span><b>GTC</b>Good ‘Til Canceled</span>, value: "GTC"}}
                className="OrderTypes"
                classNamePrefix="react-select"
                isSearchable
                //menuIsOpen
              />

            </div>

            <button
              className={classNames(styles.AddOrder, {
                [styles.Buy]: actionType === "BUY",
                [styles.Sell]: actionType === "SELL",
              })}
              onClick={this.handleCreateOrder}
            >Submit
            </button>

          </div>
        </FormGroup>
      </div>
    );
  }
}

OrderSettings.propTypes = {
  accountBalance: PropTypes.object,
  accounts: PropTypes.array,
  addTempOrder: PropTypes.func,
  toggleModal: PropTypes.func,
  createOrderAction: PropTypes.func,
  fetchAccountBalance: PropTypes.func,
  marketSummary: PropTypes.object,
  orders: PropTypes.array,
  removeTempOrder: PropTypes.func,
  tempOrder: PropTypes.object,
  updateTempOrder: PropTypes.func,
  widget: PropTypes.object,
};

OrderSettings.defaultProps = {
  accountBalance: {},
  accounts: [],
  toggleModal: () => false,
  addTempOrder: () => false,
  createOrderAction: () => false,
  fetchAccountBalance: () => false,
  marketSummary: {},
  orders: [],
  removeTempOrder: () => false,
  tempOrder: {},
  updateTempOrder: () => false,
  widget: {},
};

export default OrderSettings;
