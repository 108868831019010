import {connect} from "react-redux";
import OrdersPage from "../components/HistoryPage";
import {fetchOrderHistory} from "../../../actions/OrdersActions";
import {fetchAccounts} from "../../../actions/AccountsActions";

export default connect((state) => ({
  accounts: state.accounts.accounts.data,
  orderHistory: state.orders.orderHistory.data,
  ordersHistoryLoading: state.orders.orderHistory.loading,
}), {
  fetchOrderHistory,
  fetchAccounts,
})(OrdersPage);
