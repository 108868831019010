import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import Worksheet from "./Worksheet";
import WidgetSettingsModal from "../containers/WidgetSettingsModalContainer";
import MarketsModal from "../containers/MarketsModalContainer";
import AddNewPanelModal from "../containers/AddNewPanelContainer";
import WorksheetSettingsModal from "../containers/WorksheetSettingsModalContainer";
import ChartSettingsModal from "../containers/ChartSettingsModalContainer";
import IndicatorSettingsModal from "../containers/IndicatorSettingsModalContainer";
import DrawingToolSettingsModal from "../containers/DrawingToolSettingsModalContainer";
import ExchangesModal from "../containers/ExchangesModalContainer";
import FeedFilterModal from "../containers/FeedFilterModalContainer";
import DomChartSettingsModal from "../containers/DomChartSettingsModalContainer";
import TokenFinderModal from "../containers/TokenFinderModalContainer";
import "./MarketsPage.scss";
import WidgetScannerSettingsModal from "../../../components/WidgetScanner/WidgetScannerSettingsModal";
import WidgetFilterModal from "../../../components/WidgetCoinFilter/WidgetFilterModal";

class MarketsPage extends PureComponent {
  constructor() {
    super();

    this.interval = null;
    this.state = {
      width: document.body.clientWidth,
    };
  }

  componentDidMount() {
    const {match: {params}, user, history, changeWorksheet} = this.props;
    if (!params.workspace || user.worksheets.indexOf(params.workspace) < 0) {
      history.push(`/markets/workspaces/${user.worksheets[0]}`);
    } else {
      changeWorksheet(params.workspace);
      this.initWorksheets();
    }
  }

  componentDidUpdate(prevProps) {
    const {match: {params}, changeWorksheet} = this.props;
    if (params.workspace !== prevProps.match.params.workspace) {
      changeWorksheet(params.workspace);
      this.clearWorksheets();
      this.initWorksheets();
    }
  }

  componentWillUnmount() {
    this.clearWorksheets();
  }

  initWorksheets = () => {
    const {getUserWorksheets, fetchOrderHistory,  fetchOrdersList,
      getUserDrawingTemplates, getUserIndicatorTemplates} = this.props;

    getUserWorksheets();
    getUserDrawingTemplates();
    getUserIndicatorTemplates();
    window.addEventListener("resize", this.onResizeWindow);

    this.interval = setInterval(() => {
      if (this.props.currentWorksheet && this.props.currentWorksheet.widgets) {
        if (this.props.currentWorksheet.widgets.find((widget) => ["chart", "trade"].indexOf(widget.type) >= 0)) {
          fetchOrderHistory();
          fetchOrdersList();
        }

        // if (this.props.currentWorksheet.widgets.find((widget) => ["dom", "domChart"].indexOf(widget.type) >= 0)) {
        //   getMarketDom();
        // }
      }
    }, 5000);
  };

  clearWorksheets = () => {
    clearInterval(this.interval);
    this.props.clearTempOrders();
    window.removeEventListener("resize", this.onResizeWindow);
  };

  onResizeWindow = () => {
    this.setState({
      width: document.body.clientWidth,
    });
  };

  getPageTitle = () => {
    const {activeWidget} = this.props;
    if (!activeWidget || activeWidget && !activeWidget.name) {
      return "aurlix";
    }
    const splittedMarket = activeWidget.name.split("-");
    const name = `${splittedMarket[1]}/${splittedMarket[0]}`;
    let price = 0;
    switch (activeWidget.type) {
      case "chart":
        price = activeWidget.data.length > 0 ? activeWidget.data[activeWidget.data.length - 1].close : 0;
        break;
      case "price":
        price = activeWidget.data.market ? activeWidget.data.market.last : 0;
        break;
      case "trade":
        price = activeWidget.marketSummary ? activeWidget.marketSummary.last : 0;
        break;
    }
    return price ? `${name}: ${Number(price).toFixed(8)}` : name;
  };

  render() {
    return (
      <PerfectScrollbar options={{suppressScrollX: true}}>
        <Worksheet
          {...this.props}
          width={this.state.width}
        />
        <WidgetSettingsModal/>
        <MarketsModal/>
        <AddNewPanelModal/>
        <WorksheetSettingsModal/>
        <ChartSettingsModal/>
        <IndicatorSettingsModal/>
        <DrawingToolSettingsModal/>
        <ExchangesModal/>
        <FeedFilterModal/>
        <DomChartSettingsModal/>
        <TokenFinderModal/>
        {this.props.activeWidget?.active && <WidgetScannerSettingsModal/>}
        {this.props.activeWidget?.active && <WidgetFilterModal/>}
      </PerfectScrollbar>
    );
  }
}

MarketsPage.propTypes = {
  activeWidget: PropTypes.object,
  changeWorksheet: PropTypes.func,
  clearTempOrders: PropTypes.func,
  currentWorksheet: PropTypes.object,
  fetchOrderHistory: PropTypes.func,
  fetchOrdersList: PropTypes.func,
  getMarketDom: PropTypes.func,
  getMarketSummaries: PropTypes.func,
  getUserDrawingTemplates: PropTypes.func.isRequired,
  getUserIndicatorTemplates: PropTypes.func.isRequired,
  getUserWorksheets: PropTypes.func,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  worksheets: PropTypes.array,
};

MarketsPage.defaultProps = {
  activeWidget: {},
  changeWorksheet: () => false,
  clearTempOrders: () => false,
  currentWorksheet: {},
  fetchOrderHistory: () => false,
  fetchOrdersList: () => false,
  getMarketSummaries: () => false,
  getMarketDom: () => false,
  getUserWorksheets: () => false,
  user: {},
  worksheets: [],
};

export default MarketsPage;
