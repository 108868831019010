// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1yo3xryxukq9-jgrj7CQp_{width:15px;height:15px;border-radius:3px;padding:2px;border:1px solid #444}._1C2P2qYBc83Mh3WwEH3pVb{display:flex;align-items:center}", "",{"version":3,"sources":["webpack://src/components/ColorPicker/ColorPicker.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,qBAAA,CAGF,yBACE,YAAA,CACA,kBAAA","sourcesContent":[".example {\n  width: 15px;\n  height: 15px;\n  border-radius: 3px;\n  padding: 2px;\n  border: 1px solid #444;\n  //margin-right: 5px\n}\n.colorbutton {\n  display: flex;\n  align-items: center;\n}\n.dropdown{\n //margin: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"example": "_1yo3xryxukq9-jgrj7CQp_",
	"colorbutton": "_1C2P2qYBc83Mh3WwEH3pVb"
};
export default ___CSS_LOADER_EXPORT___;
