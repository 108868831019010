import {connect} from "react-redux";
import Chat from "../components/Chat";

import {
  getChannelsAction, setChannelAction, getUsersAction, getMessagesAction, createChannelAction, sendMessageAction,
  deleteChannelAction, leaveChannelAction, uploadFileAction, deleteMessageAction, updateMessageAction,
  setMessageForEditing, notifyIsTyping, fetchUrlAction,
} from "../../../actions/ChatActions";
import {toggleModal} from "../../../actions/MarketsActions";

const getUserNames = (members = [], users = []) => {
  return members.map((member) => {
    const user = users.find((item) => item._id === member);
    return {
      id: member,
      online: user ? !!user.online : false,
      username: user ? user.username : "Removed user",
    };
  });
};

const getDirectTitle = (members = [], userId) => {
  const targetUser = members.filter((item) => item.id !== userId);
  return targetUser && targetUser[0] ? targetUser[0].username : "Removed user";
};

const checkIsUserOnline = (members = [], userId) => {
  const targetUser = members.filter((item) => item.id !== userId);
  return targetUser && targetUser[0] ? targetUser[0].online : false;
};

const getMessageUser = (messageUser, users = []) => {
  const user = users.find((item) => item._id === messageUser);
  return user ? user.username : "Removed user";
};

const getMessageAvatar = (messageUser, users = []) => {
  const user = users.find((item) => item._id === messageUser);
  return user ? user.avatar : null;
};

export default connect(
    (state) => {
      const users = state.chat.users;
      const user = state.user.user.data;

      const channels = state.chat.channels.map((channel) => {
        const members = getUserNames(channel.members, users);
        return {
          ...channel,
          title: channel.type === "direct" ? getDirectTitle(members, user.id) : channel.title,
          online: channel.type === "direct" ? checkIsUserOnline(members, user.id) : true,
          members: members,
        };
      });

      const channel = state.chat.channel;
      const channelObject = channels.find((item) => item._id === channel);

      return {
        channelObject: channelObject,
        channel: channel,
        channels: channels,
        editingMessage: state.chat.editingMessage,
        hasNewItems: state.chat.hasNewItems,
        messages: state.chat.messages.map((message) => ({
          ...message,
          user: {
            id: message.user,
            name: getMessageUser(message.user, users),
            avatar: getMessageAvatar(message.user, users),
          },
        })).sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate)),
        totalMessages: state.chat.totalMessages,
        urlsData: state.chat.urlsData,
        user: user,
        users: users.filter((item) => item._id !== user.id),
        userTyping: state.chat.userTyping.map((item) => getMessageUser(item, users)),
        userStatusChanged: state.chat.userStatusChanged,
      };
    }, {
      getChannelsAction,
      setChannelAction,
      getUsersAction,
      leaveChannelAction,
      getMessagesAction,
      sendMessageAction,
      createChannelAction,
      deleteChannelAction,
      toggleModal,
      uploadFileAction,
      fetchUrlAction,
      deleteMessageAction,
      updateMessageAction,
      setMessageForEditing,
      notifyIsTyping,
    },
)(Chat);
