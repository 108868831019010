import React, {Component} from "react";
import PropTypes from "prop-types";
import {getHostName} from "../../../utils/apiUtils";
import Loader from "../../../components/Loader";

import styles from "./ImagePage.scss";

class ImagePage extends Component {
  componentDidMount() {
    const {routeParams: {id}} = this.props;
    this.props.getImageAction(id);
  }

  render() {
    const {imagePath, loading} = this.props;
    return (
      <div className={styles.ImagePage}>
        {loading || !imagePath ? (
          <Loader/>
        ) : (
          <div>
            <img
              className={styles.Image}
              src={`${getHostName()}/${imagePath}`}
              alt="Chart screenshot"
            />
            <a
              className={styles.Link}
              href={`${getHostName()}/${imagePath.replace("images/", "download/")}`}
              target="_blank"
            >
              Download image
            </a>
          </div>
        )}
      </div>
    );
  }
}

ImagePage.propTypes = {
  getImageAction: PropTypes.func.isRequired,
  imagePath: PropTypes.string,
  loading: PropTypes.bool,
  routeParams: PropTypes.object.isRequired,
};

ImagePage.defaultProps = {
  imagePath: undefined,
  loading: false,
};

export default ImagePage;
