// import interval from "./interval";
// import {durationWeek} from "./duration";

// function utcWeekday(i) {
//   return interval(function(date) {
//     console.log('week floori', i, date);
//     if (date.getUTCDate() > 28 || date.getUTCDate() < 6){
//       let preDate = date.getUTCDate();
//       date.setUTCDate(1);
//       if (preDate > 28){
//         date.setUTCMonth(date.getUTCMonth() + 1);
//       }
//     } else {
//       let newDate = date.getUTCDate() - (date.getUTCDay() + 7 - i) % 7;
//       if (newDate > 5){
//         date.setUTCDate(newDate);
//       }
//     }
//     date.setUTCHours(0, 0, 0, 0);
//   }, function(date, step) {
//     console.log('week offseti', date);
//     // let temp = new Date(date);
//     // let oldDate = temp.getUTCDate();
//     // let newDate = new Date(temp.setUTCDate(temp.getUTCDate() + step * 7)).getUTCDate();
//     // if (newDate < oldDate){
//     //   // means new month, set month day
//     //   date.setUTCDate(1);
//     //   date.setUTCHours(0, 0, 0, 0);
//     //   date.setUTCMonth(date.getUTCMonth() + 1);
//     // } else {
//       date.setUTCDate(date.getUTCDate() + step * 7);
//     // }
//   }, function(start, end) {
//     return (end - start) / durationWeek;
//   });
// }

// export var utcSunday = utcWeekday(0);
// export var utcMonday = utcWeekday(1);
// export var utcTuesday = utcWeekday(2);
// export var utcWednesday = utcWeekday(3);
// export var utcThursday = utcWeekday(4);
// export var utcFriday = utcWeekday(5);
// export var utcSaturday = utcWeekday(6);

// export var utcSundays = utcSunday.range;
// export var utcMondays = utcMonday.range;
// export var utcTuesdays = utcTuesday.range;
// export var utcWednesdays = utcWednesday.range;
// export var utcThursdays = utcThursday.range;
// export var utcFridays = utcFriday.range;
// export var utcSaturdays = utcSaturday.range;


import interval from "./interval";
import {durationWeek} from "./duration";

function utcWeekday(i) {
  return interval(function(date) {
    date.setUTCDate(date.getUTCDate() - (date.getUTCDay() + 7 - i) % 7);
    date.setUTCHours(0, 0, 0, 0);
    return new Date();
  }, function(date, step) {
    date.setUTCDate(date.getUTCDate() + step * 7);
  }, function(start, end) {
    return (end - start) / durationWeek;
  });
}

export const utcSunday = utcWeekday(0);
export const utcMonday = utcWeekday(1);
export const utcTuesday = utcWeekday(2);
export const utcWednesday = utcWeekday(3);
export const utcThursday = utcWeekday(4);
export const utcFriday = utcWeekday(5);
export const utcSaturday = utcWeekday(6);

export const utcSundays = utcSunday.range;
export const utcMondays = utcMonday.range;
export const utcTuesdays = utcTuesday.range;
export const utcWednesdays = utcWednesday.range;
export const utcThursdays = utcThursday.range;
export const utcFridays = utcFriday.range;
export const utcSaturdays = utcSaturday.range;
