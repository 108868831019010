/* eslint react/no-danger: 0 */
/* eslint react/no-did-mount-set-state: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import {Emoji} from "emoji-mart";
import {ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Badge} from "reactstrap";
import LogoWhite from "../../../assets/logo_white.svg";
import InputBlock from "./InputBlock";
import FontAwesome from "../../../components/FontAwesome";
import {getHostName} from "../../../utils/apiUtils";
import ReactTooltip from 'react-tooltip';
import styles from "./MessageItem.scss";

import {EMOJI_SET, EMAIL_REGEXP, EMOJI_REGEXP, LINK_REGEXP, FILE_REGEXP} from "../../../constants/ChatConstants";

class MessageItem extends Component {
  constructor() {
    super();

    this.interval = null;
    this.state = {
      countdown: false,
      visible: true,
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    const {message: {burnTimeout, createdDate}} = this.props;
    if (!burnTimeout) {
      return false;
    }
    const period = burnTimeout.split(" ");
    const burnDate = moment(createdDate).add(period[0], period[1]);
    const now = moment();
    const diff = burnDate.diff(now, "seconds");

    if (diff > 0) {
      this.setState({
        countdown: diff,
      });
      this.interval = setInterval(() => {
        const {countdown} = this.state;
        if (countdown > 0) {
          this.setState({
            countdown: countdown - 1,
          });
        } else {
          clearInterval(this.interval);
          this.setState({
            visible: false,
          });
        }
      }, 1000);
    } else {
      this.setState({
        visible: false,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleToggleDropdown = (state) => {
    this.setState({
      dropdownOpen: state === undefined ? !this.state.dropdownOpen : state,
    });
  };

  parseMessage = () => {
    const {message} = this.props;
    const withEmogies = message.body.replace(EMOJI_REGEXP, (a, b, c) => {
      return Emoji({
        html: true,
        set: EMOJI_SET,
        emoji: c,
        size: 25,
        sheetSize: 32,
        backgroundImageFn: (set, sheetSize) => `https://unpkg.com/emoji-datasource-${set}@5.0.1/img/${set}/sheets-256/${sheetSize}.png`,
      });
    });
    const withLinks = withEmogies.replace(LINK_REGEXP, (a, b, c) => {
      return `<a href=${c} target="_blank">${c}</a>`;
    });
    const withEmails = withLinks.replace(EMAIL_REGEXP, (a, b, c) => {
      return `<a href="mailto:${c}" target="_blank">${c}</a>`;
    });
    const withFiles = withEmails.replace(FILE_REGEXP, () => "");
    return withFiles;
  };

  drawImage = (path) => (
    <img
      className={styles.Image}
      src={path}
      key={path}
      alt=""
    />
  );

  drawUrl = (url) => {
    const {urlsData} = this.props;
    const urlData = urlsData.find((item) => item.url === url);
    return urlData && (urlData.image || urlData.title || urlData.description) && (
      <a
        className={styles.Url}
        href={url}
        key={url}
        target="_blank"
      >
        {urlData.image && (<img src={urlData.image} alt={urlData.title}/>)}
        <p className={styles.Title}>
          {urlData.ogTitle || urlData.title}
        </p>
        <p className={styles.Description}>
          {urlData.ogDescription || urlData.description}
        </p>
      </a>
    );
  };

  drawFile = (path) => {
    const pathParts = path.split("|");
    const url = pathParts[0];
    const name = pathParts[1];
    const size = numeral(pathParts[2]).format("0b");
    const isImage = (/\.(gif|jpg|jpeg|tiff|png)$/i).test(url);

    return (
      <div className={styles.File} key={path}>
        <span>Uploaded file:</span>
        <a
          href={url}
          target="_blank"
        >
          {name} <span>({size})</span>
          <FontAwesome
            name="download"
            prefix="fas"
          />
        </a>
        {isImage && this.drawImage(url)}
      </div>
    );
  };

  getMessageImages = () => {
    const {message} = this.props;
    const images = [];

    const links = message.body.match(LINK_REGEXP);
    if (links && links.length > 0) {
      links.forEach((link) => {
        const path = link.replace(LINK_REGEXP, (a, b, c) => c);
        const isImage = (/\.(gif|jpg|jpeg|tiff|png)$/i).test(path);
        if (isImage) {
          images.push(path);
        }
      });
    }

    return images;
  };

  getUrls = () => {
    const {message, fetchUrlAction, urlsData} = this.props;
    const urls = [];
    const links = message.body.match(LINK_REGEXP);

    if (links && links.length > 0) {
      links.forEach((link) => {
        const path = link.replace(LINK_REGEXP, (a, b, c) => c);
        const isImage = (/\.(gif|jpg|jpeg|tiff|png)$/i).test(path);
        if (!isImage) {
          if (!urlsData.find((item) => item.url === path)) {
            fetchUrlAction(path);
          }
          urls.push(path);
        }
      });
    }

    return urls;
  };

  getFiles = () => {
    const {message} = this.props;
    const filesList = [];

    const files = message.body.match(FILE_REGEXP);
    if (files && files.length > 0) {
      files.forEach((link) => {
        const path = link.replace(FILE_REGEXP, (a, b, c) => c);
        filesList.push(`${getHostName()}/${path}`);
      });
    }
    return filesList;
  };

  calculateDiff = () => {
    const {countdown} = this.state;
    const now = moment();
    let burnDate = moment().add(countdown, "seconds");
    const diff = [];

    const days = burnDate.diff(now, "days");
    if (days > 0) {
      burnDate = burnDate.add(-1 * days, "days");
      diff.push(`${days}d`);
    }
    const hours = burnDate.diff(now, "hours");
    if (hours > 0) {
      burnDate = burnDate.add(-1 * hours, "hours");
      diff.push(`${hours}h`);
    }
    const minutes = burnDate.diff(now, "minutes");
    if (minutes > 0) {
      burnDate = burnDate.add(-1 * minutes, "minutes");
      diff.push(`${minutes}m`);
    }
    const seconds = burnDate.diff(now, "seconds");
    if (seconds > 0) {
      diff.push(`${seconds}s`);
    }

    if (diff.length === 0) {
      diff.push("0s");
    }

    return diff.join(" ");
  };

  render() {
    const {message, editingMessage, isUnique, isAuthor, deleteMessageAction, setMessageForEditing} = this.props;
    const {countdown, visible, dropdownOpen} = this.state;
    const messageHasFile = message.body.match(FILE_REGEXP);
    const images = this.getMessageImages();
    const urls = this.getUrls();
    const files = this.getFiles();
    const parsedMessage = this.parseMessage();

    return visible && (
      <div
        className={classNames(styles.Message, {
          [styles.NotUnique]: !isUnique,
        })}
        id={`message-${message._id}`}
      >
        {isUnique && (
          <div
            className={classNames(styles.MessageUserImage, {
              [styles.NoImage]: !message.user.avatar,
            })}
          >
            {message.user.avatar ? (
              <img alt="" src={message.user.avatar}/>
            ) : <img src={LogoWhite} alt="aurlix"/>}
          </div>
        )}
        <div
          className={styles.MessageBody}
          onMouseLeave={() => this.handleToggleDropdown(false)}
        >
          {isUnique && (
            <div className={styles.MessageAuthor}>
              <span>{message.user.name}</span>:&nbsp; {moment(message.createdDate).format("h:mma")}
            </div>
          )}
          {!editingMessage ? (
            <React.Fragment>
              {/* eslint-disable */}

              {parsedMessage && parsedMessage.length > 0 && (
                <div
                  className={styles.MessageText}
                  dangerouslySetInnerHTML={{__html: parsedMessage}}
                />
              )}
              {message.burnTimeout && countdown > 0 && (
                <Badge className={styles.Badge} color="danger">
                  Burn in {this.calculateDiff()}
                </Badge>
              )}
              {message.burnTimeout && countdown === 0 && (
                <Badge className={styles.Badge} color="danger">
                  Burning message
                </Badge>
              )}
              {message.updatedDate && (
                <span className={styles.Edited}>
                  (edited)
                </span>
              )}
              {images.map(this.drawImage)}
              {urls.map(this.drawUrl)}
              {files.map(this.drawFile)}
            </React.Fragment>
          ) : (
            <InputBlock
              {...this.props}
            />
          )}
          {isAuthor && (
            <ButtonDropdown
              dropup
              className={styles.Dropdown}
              isOpen={dropdownOpen}
              toggle={() => this.handleToggleDropdown()}
            >
              {/* eslint-enable */}
              <a data-tip data-for='moreActionsButton'>
                <DropdownToggle
                  className={styles.Button}
                  type="button"
                  onClick={() => this.handleToggleDropdown()}
                >
                  <FontAwesome className={styles.temp} name="ellipsis-h" prefix="fas"/>
                </DropdownToggle>
              </a>
              <ReactTooltip
                id='moreActionsButton'
                offset="{'top': -18}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>More actions</span>
              </ReactTooltip>
              <DropdownMenu className={styles.OptionsDropdown}>
                {messageHasFile === null && !message.burnTimeout && (
                  <DropdownItem
                    className={styles.dropItem}
                    onClick={() => setMessageForEditing(message)}
                  >
                    <div className={styles.dropdownTitle}>Message Settings</div>
                    <FontAwesome
                      className={styles.dropItemsvg}
                      name="edit"
                      prefix="fas"
                    /> Edit message
                  </DropdownItem>
                )}
                <DropdownItem
                  className={styles.dropItem}
                  onClick={() => deleteMessageAction(message._id)}
                >
                  <FontAwesome
                    className={styles.dropItemsvg}
                    name="trash-alt"
                    prefix="fas"
                  /> Delete message
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          )}
        </div>
      </div>
    );
  }
}

MessageItem.propTypes = {
  deleteMessageAction: PropTypes.func,
  editingMessage: PropTypes.object,
  fetchUrlAction: PropTypes.func,
  isAuthor: PropTypes.bool,
  isUnique: PropTypes.bool,
  message: PropTypes.object,
  setMessageForEditing: PropTypes.func,
  urlsData: PropTypes.array,
};

MessageItem.defaultProps = {
  deleteMessageAction: () => false,
  editingMessage: undefined,
  fetchUrlAction: () => false,
  isAuthor: false,
  isUnique: false,
  message: undefined,
  setMessageForEditing: () => false,
  urlsData: [],
};

export default MessageItem;
