import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {sortBy} from "lodash";
import FontAwesome from "../../../../components/FontAwesome";

import OrderType from "../../../../components/OrderType";
import MarketName from "../../../../components/MarketName";
import FloatValue from "../../../../components/FloatValue";
import Button from "../../../../components/Button";

import styles from "./Orders.scss";

const FIELDS = ["Market", "Type", "Price", "Total"];

class Orders extends PureComponent {
  constructor() {
    super();

    this.state = {
      sortKey: FIELDS[0],
      sortType: "asc",
    };
  }

  onSortClick = (key, type) => this.setState({sortingKey: key, sortingType: type});

  sortData = (items) => {
    const {sortingKey, sortingType} = this.state;
    const sortedData = sortingKey ? sortBy(items, (item) => {
      switch (sortingKey) {
        case "Total":
          return item.Limit * item.Quantity;
        case "Type":
          return item.OrderType;
        case "Price":
          return item.Limit;
        case "Market":
        default:
          return item.Exchange;
      }
    }) : items;

    return sortingType === "desc" ? sortedData.reverse() : sortedData;
  };

  handleSort = (key) => {
    const {sortKey, sortType} = this.state;
    let newSortKey;
    let newSortType;

    if (!sortKey || sortKey !== key) {
      newSortKey = key;
      newSortType = "asc";
    } else if (sortKey && sortType === "asc") {
      newSortKey = sortKey;
      newSortType = "desc";
    } else if (sortKey && sortType === "desc") {
      newSortKey = null;
      newSortType = "asc";
    }
    this.setState({
      sortKey: newSortKey,
      sortType: newSortType,
    });
    this.onSortClick(newSortKey, newSortType);
  };

  render() {
    const {orders, activeWidget, cancelOrderAction} = this.props;
    const {sortKey, sortType} = this.state;

    return (
      <div className={styles.Orders}>
        <table>
          <thead>
            <tr>
              {FIELDS.map((field) => (
                <th
                  key={field}
                  onClick={() => this.handleSort(field)}
                >
                  {sortKey === field && sortType === "asc" && (
                    <FontAwesome name="chevron-down"/>
                  )}
                  {sortKey === field && sortType === "desc" && (
                    <FontAwesome name="chevron-up"/>
                  )}
                  {` ${field}`}
                </th>
              ))}
              <th/>
            </tr>
          </thead>
          <tbody>
            {this.sortData(orders).filter((item) => activeWidget ? item.Exchange === activeWidget.name : true)
                .map((item) => (
                  <tr key={item.OrderUuid}>
                    <td>
                      <MarketName name={item.symbol} reversed/>
                    </td>
                    <td>
                      <OrderType type={item.type}/>
                    </td>
                    <td>
                      <FloatValue value={item.price}/>
                    </td>
                    <td>
                      <FloatValue value={item.price * item.amount}/>
                    </td>
                    <td>
                      <Button
                        className={styles.Cancel}
                        label={<FontAwesome name="times"/>}
                        onClick={() => cancelOrderAction(item.accountName, item)}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    );
  }
}

Orders.propTypes = {
  activeWidget: PropTypes.object,
  cancelOrderAction: PropTypes.func,
  orders: PropTypes.array,
};

Orders.defaultProps = {
  activeWidget: undefined,
  cancelOrderAction: () => false,
  orders: [],
};

export default Orders;
