import {connect} from "react-redux";
import PriceWidget from "../components/PriceWidget/PriceWidget";

import {getChartData, getPriceData, setWidgetActive, unsubscribePriceData} from "../../../actions/MarketsActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null;

      return {
        price: widget && widget.data && widget.data.market,
        widget: widget,
      };
    }, {
      getChartData,
      getPriceData,
      setWidgetActive,
      unsubscribePriceData
    },
)(PriceWidget);
