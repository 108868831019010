import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import React, {Fragment, useState} from "react";
import FontAwesome from "../FontAwesome";
import PropTypes from "prop-types";
import styles from './SelectBox.module.scss';
import SelectBoxItem from "./SelectBoxItem";
import PerfectScrollbar from "react-perfect-scrollbar";

const SelectBox = ({options, value, onChange, children}) => {
  if (!value && options[0]) {
    value = options[0];
  }
  const [isOpen, setIsOpen] = useState(false);
  return (<Dropdown
    isOpen={isOpen}
    toggle={() => setIsOpen(!isOpen)}
    className={styles.SelectBox}
  >
    <DropdownToggle>
      <div>
        <Fragment>
          {<span>{value}</span>}
        </Fragment>
        <FontAwesome
          name="chevron-down"
          prefix="fas"
        />
      </div>
    </DropdownToggle>

    <DropdownMenu className={styles.dropdownMenu}>
      <PerfectScrollbar
        options={{suppressScrollX: true}}
        className={styles.dropdownMenuList}
      >
        {
          children ?? options.map((item) => (
            <SelectBoxItem
              key={item}
              onClick={() => onChange(item)}
            >
              {item}
            </SelectBoxItem>))
        }
      </PerfectScrollbar>
    </DropdownMenu>

  </Dropdown>);
};
SelectBox.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.node,
  children: PropTypes.node,
  onChange: PropTypes.func
};
export default SelectBox;
