import {uid} from "uid";
import {UPDATE_FILTERS, GET_FEED} from "../const";
import {getMessages} from "../api/FeedApi";

let timeout = null;

const getFeedAction = (date, isNew) => async (dispatch, getState) => {
  const state = getState();
  const filters = state.feed.filters;

  const reqDate = new Date(date);
  const data = await getMessages({
    filters: JSON.stringify(filters),
    date: reqDate.toISOString(),
    isNew: isNew,
  });

  dispatch({
    type: GET_FEED,
    data: data.data,
    isNew: data.isNew,
  });
};

const checkNewFeeds = (date) => (dispatch, getState, {emit}) => {
  const state = getState();
  const filters = state.feed.filters;

  emit("getNewFeed", {
    date,
    filters,
  });
};

const filterChanged = () => (dispatch) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => dispatch(getFeedAction(null, true)), 500);
};

const addFilter = (data) => (dispatch, getState) => {
  const state = getState();

  const filter = {
    ...data,
    uid: uid(10),
  };

  dispatch({
    type: UPDATE_FILTERS,
    filters: [
      ...state.feed.filters,
      filter,
    ],
  });

  dispatch(filterChanged());
};

const removeFilter = (filter) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: UPDATE_FILTERS,
    filters: state.feed.filters.filter((item) => item.uid !== filter.uid),
  });

  dispatch(filterChanged());
};

const updateFilter = (filter) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: UPDATE_FILTERS,
    filters: state.feed.filters.map((item) => item.uid === filter.uid ? filter : item),
  });

  dispatch(filterChanged());
};

const subscribeSentimentData = (widgetId) => (dispatch, getState, {emit}) => {
  const state = getState();
  const {currentWorksheet, worksheets, markets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const widgets = currentWorksheetData.widgets;
  const widget = widgets.find((widget) => widget.i === widgetId);
  const market = widget && widget.name && widget.name.split("-")[1];
  const fullMarket = markets.find((item) => item.MarketCurrency === market);

  market && emit("subscribeNewsSentiment", {
    widget: widgetId,
    market: market,
    fullMarket: fullMarket ? fullMarket.MarketCurrencyLong : market,
    period: widget.period,
  });
};

const unSubscribeSentimentData = (widgetId) => (dispatch, getState, {emit}) => {
  const state = getState();
  const {currentWorksheet, worksheets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const widgets = currentWorksheetData.widgets;
  const widget = widgets.find((widget) => widget.i === widgetId);
  const market = widget && widget.name && widget.name.split("-")[1];

  market && emit("unSubscribeNewsSentiment", {
    widget: widgetId,
    market: market,
    period: widget.period,
  });
};

export {
  getFeedAction,
  checkNewFeeds,
  addFilter,
  removeFilter,
  updateFilter,
  subscribeSentimentData,
  unSubscribeSentimentData,
};
