import {DropdownItem} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import styles from './SelectBox.module.scss';
const SelectBoxItem = ({className, ...props}) => {
  return (<DropdownItem
    className={styles.DropdownItem+" "+className}
    {...props}
  />);
};
SelectBoxItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
export default SelectBoxItem;
