import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./FontAwesome.scss";
import "../assets/fontawesome-pro/js/all";

class FontAwesome extends Component {
  componentDidMount() {
    // window.FontAwesome.config = {
    //   dataAutoReplaceSvg: true,
    //   observeMutations: true,
    // };
    window.FontAwesome.config.autoReplaceSvg = "nest";
  }

  render() {
    const {className, name, prefix, handleClick, wrapperClassName, style, dataFaTransform} = this.props;
    return (
      <div
        className={classNames(wrapperClassName, styles.IconWrapper)}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        style={style}
      >
        <i
          className={classNames(className, prefix, `fa-${name}`)}
          data-fa-transform={dataFaTransform}
        />
      </div>
    );
  }
}

FontAwesome.propTypes = {
  className: PropTypes.string,
  dataFaTransform: PropTypes.string,
  handleClick: PropTypes.func,
  name: PropTypes.string,
  prefix: PropTypes.string,
  style: PropTypes.object,
  wrapperClassName: PropTypes.string,
};

FontAwesome.defaultProps = {
  className: "",
  dataFaTransform: "",
  handleClick: () => false,
  name: "",
  prefix: "fas",
  style: {},
  wrapperClassName: "",
};

export default FontAwesome;
