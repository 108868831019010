import React, { Component, Fragment } from 'react';
import { MDBDataTable } from 'mdbreact';
import Web3 from 'web3';
import ReactTooltip from 'react-tooltip';
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import './swaptable.css';
import styles from "./tokenFinder.scss";
import { ethRPC, ERC20ABI, ROUTERABI, ethAddress, uniswapRouterAddress, uniswapFactoryAddress, etherscanAPIKey, FactoryABI, ethUsdtAddress } from '../config';
import { FiCheckCircle, FiXCircle, FiClock, FiHelpCircle } from 'react-icons/fi';
import { BiGasPump } from "react-icons/bi";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// In a node environment
const web3 = new Web3(ethRPC);
const factoryContract = new web3.eth.Contract(FactoryABI, uniswapFactoryAddress);
const wethContract = new web3.eth.Contract(ERC20ABI, ethAddress);
const routerContract = new web3.eth.Contract(ROUTERABI, uniswapRouterAddress);
const internationalNumberFormat = new Intl.NumberFormat('en-US');
const pageBusy = true;

console.log(pageBusy);

class Uniswap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isBotRuning: false,
      tableDatas: [],
      prevToken: '',
      checkhash: '0',
      pageBusy: true,
      scanningBlockNumber: 0,
    };
  }

  async componentWillMount() {
    await this.initialListing(1000);

    const blocknumber = await web3.eth.getBlockNumber();
    this.setState({
      scanningBlockNumber: blocknumber
    });

    setInterval(() => {
      this.realTimeScanning();
    }, 30000);

    setInterval(() => {
      this.realTimeDataUpdate();
    }, 60000);

    setTimeout(() => {
      setInterval(() => {
        this.realTimeTimerUpdate();
      }, 2000);
    }, 10000);
  }

  async initialListing(number) {
    console.log("initial token scanning!");
    const blocknumber = await web3.eth.getBlockNumber() - number;
    const eventarray = await factoryContract.getPastEvents('PairCreated', {
      fromBlock: blocknumber,
      toBlock: 'latest'
    });
    console.log(eventarray);

    let tokenAddress;
    let hash;
    let pairAddress;

    for (let index = eventarray.length - 1; index > - 1; index--) {
      eventarray[index].returnValues[0] === ethAddress ? tokenAddress = eventarray[index].returnValues[1] : tokenAddress = eventarray[index].returnValues[0];
      if (tokenAddress === ethUsdtAddress) {
        return;
      }

      hash = eventarray[index].transactionHash;
      pairAddress = eventarray[index].returnValues[2];

      const tableData = {
        id: index,
        tokenName: '',
        tokenTitle: '',
        releaseDate: '',
        owner: '',
        Distokeninfo: '',
        tokenAddress: tokenAddress,
        hash: hash,
        DisreleaseDate: '',
        verifyStatus: '',
        DisverifyStatus: '',
        honeyPotStatus: '',
        mintStatus: '',
        DismintStatus: '',
        taxStatus: '',
        renounceStatus: '',
        liquidityStatus: '',
        liquidityAmount: '',
        supply: '',
        decimals: '',
        traded: '',
        txCount: '',
        pairAddress: pairAddress,
        DistokenAddress: '',
        Dishash: '',
        honeyPotStatusDis: '',
        DisOwner: '',
        flag: 'false'
      };
      const tableDatas = this.state.tableDatas;
      tableDatas.push(tableData);
      this.setState({
        tableDatas: tableDatas
      });
      this.getData(tokenAddress, hash, index, pairAddress, false);
      this.getTimer(hash, index);
    }
  }

  async realTimeScanning() {
    console.log("real time token scanning");
    let tokenAddress;
    let hash;
    let pairAddress;

    const eventarray = await factoryContract.getPastEvents('PairCreated', {
      fromBlock: this.state.scanningBlockNumber + 1,
      toBlock: 'latest'
    });

    const blocknumber = await web3.eth.getBlockNumber();
    this.setState({
      scanningBlockNumber: blocknumber
    });

    if (eventarray.length === 0) {
      console.log("new token scanning result: nothing");
      return;
    } else {
      console.log("new token scanning result: ", eventarray.length);
      hash = eventarray[0].transactionHash;
      if (hash === this.state.tableDatas[0].hash) {
      } else {
        eventarray[0].returnValues[0] === ethAddress ? tokenAddress = eventarray[0].returnValues[1] : tokenAddress = eventarray[0].returnValues[0];
        if (tokenAddress === ethUsdtAddress) {
          return;
        }
        hash = eventarray[0].transactionHash;
        pairAddress = eventarray[0].returnValues[2];
        const tokenContract = new web3.eth.Contract(ERC20ABI, tokenAddress);
        const tokenName = await tokenContract.methods.symbol().call();
        NotificationManager.success("New token " + tokenName + " is added To Uninswap Liquidity \n");
        document.querySelector('tbody>tr:first-of-type').classList.add('new');
        setTimeout(() => {
          document.querySelector('tbody>tr:first-of-type').classList.remove("new");
        }, 30000);
        const tableData = {
          id: this.state.tableDatas.length,
          tokenName: '',
          tokenTitle: '',
          releaseDate: '',
          owner: '',
          tokenAddress: tokenAddress,
          hash: hash,
          verifyStatus: '',
          honeyPotStatus: '',
          mintStatus: '',
          taxStatus: '',
          renounceStatus: '',
          liquidityStatus: '',
          liquidityAmount: '',
          supply: '',
          decimals: '',
          traded: '',
          txCount: '',
          pairAddress: pairAddress,
          Distokeninfo: '',
          DisverifyStatus: '',
          DismintStatus: '',
          DisreleaseDate: '',
          DistokenAddress: '',
          Dishash: '',
          DisOwner: '',
          honeyPotStatusDis: '',
          flag: 'false'
        };

        const tableDatas = this.state.tableDatas;
        tableDatas.unshift(tableData);
        this.setState({
          tableDatas: tableDatas
        });
        this.getData(tokenAddress, hash, this.state.tableDatas.length - 1, pairAddress);
        this.getTimer(hash, this.state.tableDatas.length - 1);
      }
    }
  }

  async realTimeDataUpdate() {
    if (this.state.pageBusy === false) {
      return;
    }
    console.log("data update!");
    for (let i = 0; i < this.state.tableDatas.length; i++) {
      this.getData(this.state.tableDatas[i].tokenAddress, this.state.tableDatas[i].hash, this.state.tableDatas[i].id, this.state.tableDatas[i].pairAddress, true);
      console.log(i);
    }
  }

  async realTimeTimerUpdate() {
    if (pageBusy === false) {
      return;
    }
    for (let i = 0; i < this.state.tableDatas.length; i++) {
      this.getTimer(this.state.tableDatas[i].hash, this.state.tableDatas[i].id);
    }
  }

  async getData(tokenAddress, hash, id, pairAddress, isUpdate) {
    try {
      let tokenName;
      let tokenTitle;
      let verifyStatus;
      let honeyPotStatus;
      let mintStatus;
      let buyTax;
      let sellTax;
      let buyGas;
      let sellGas;
      let renounceStatus;
      let liquidityAmount;
      let owner;
      let supply;
      let decimals;
      let traded;
      let txCount;
      // let releaseDate
      let tableDatas;
      let ethPrice;

      const tokenContract = new web3.eth.Contract(ERC20ABI, tokenAddress);

      tokenName = await tokenContract.methods.symbol().call();
      tokenTitle = await tokenContract.methods.name().call();

      tableDatas = this.state.tableDatas;
      tableDatas[this.state.tableDatas.length - id - 1].tokenName = tokenName;
      tableDatas[this.state.tableDatas.length - id - 1].tokenTitle = tokenTitle;
      tableDatas[this.state.tableDatas.length - id - 1].tokeninfo = <div><a className={styles.cleanlink} target="_blank" href={"https://twitter.com/search?q=%24" + tokenName + "&f=live"} >{tokenName}</a></div>;
      tableDatas[this.state.tableDatas.length - id - 1].tokenpair = <div className={styles.tokenName}>{tokenTitle} /</div>;
      tableDatas[this.state.tableDatas.length - id - 1].tokenAddressDis = <a target="_blank" href={"https://dexscreener.com/ethereum/" + tokenAddress}><b>{tokenAddress.slice(0, 5)}...{tokenAddress.slice(tokenAddress.length - 3, tokenAddress.length)}</b></a>;
      tableDatas[this.state.tableDatas.length - id - 1].tokenAddressSniff = <a target="_blank" href={"https://tokensniffer.com/token/eth/" + tokenAddress}><b>{tokenAddress.slice(0, 5)}...{tokenAddress.slice(tokenAddress.length - 3, tokenAddress.length)}</b></a>;
      tableDatas[this.state.tableDatas.length - id - 1].hashDis = <a href={"https://etherscan.io/tx/" + hash}><b>{hash.slice(0, 5)}...{hash.slice(hash.length - 3, hash.length)}</b></a>;
      this.setState({
        tabledatas: tableDatas
      });
      // renounce check      ============================================================

      try {
        try {
          owner = await tokenContract.methods.owner().call();
        } catch (err) {
          owner = "";
        }
        if (owner === '0x0000000000000000000000000000000000000000') {
          renounceStatus = 'false';
        } else if (owner === '') {
          renounceStatus = 'unknown';
        } else {
          renounceStatus = 'true';
        }
      } catch (err) {
        renounceStatus = false;
      }
      tableDatas = this.state.tableDatas;
      if (renounceStatus === 'true') {
        tableDatas[this.state.tableDatas.length - id - 1].renounceStatus =
        (<Fragment>
          <span className={styles.success}>
            <a data-tip data-for='contractrenounced'>
              <FiCheckCircle className={styles.icon}/>
              <span className={styles.text}>Renounced </span>
            </a>
          </span>
          <ReactTooltip
            id='contractrenounced'
            place="top"
            className={styles.buttonTooltip}
            effect='solid'
          >
            <div className={styles.tooltipHeader}>Contract Analysis</div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.success}><FiCheckCircle styles={{fontSize: 16}} className={styles.icon}/></span> Contract is Renounced</a></div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> functionality and ownership is renounced.</span></a></div>
          </ReactTooltip>
        </Fragment>)
        ;
      } else if (renounceStatus === 'false') {
        tableDatas[this.state.tableDatas.length - id - 1].renounceStatus =
        (<Fragment>
          <span className={styles.danger}>
            <a data-tip data-for='contractnotrenounced'>
              <FiXCircle className={styles.icon}/>
              <span className={styles.text}>Not renounced </span>
              {/*<span className={styles.dangerIcon}>1</span>*/}
            </a>
          </span>
          <ReactTooltip
            id='contractnotrenounced'
            place="top"
            className={styles.buttonTooltip}
            effect='solid'
          >
            <div className={styles.tooltipHeader}>Contract Analysis</div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.danger}><FiXCircle styles={{fontSize: 16}} className={styles.icon}/></span> Contract is not Renounced</a></div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> functionality and ownership is not renounced.</span></a></div>
          </ReactTooltip>
        </Fragment>);
      } else {
        tableDatas[this.state.tableDatas.length - id - 1].renounceStatus =
        (<Fragment>
          <span className={styles.unknown}>
            <a data-tip data-for='contractunknown'>
              <FiHelpCircle className={styles.icon}/>
              <span className={styles.text}>Unknown </span>
              {/*<span className={styles.dangerIcon}>1</span>*/}
            </a>
          </span>
          <ReactTooltip
            id='contractunknown'
            place="top"
            className={styles.buttonTooltip}
            effect='solid'
          >
            <div className={styles.tooltipHeader}>Contract Analysis</div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.unknown}><FiHelpCircle styles={{fontSize: 16}} className={styles.icon}/></span> Contract is not Unknown</a></div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> functionality and ownership is unknown.</span></a></div>
          </ReactTooltip>
        </Fragment>);
      }


      if (isUpdate && owner !== tableDatas[this.state.tableDatas.length - id - 1].owner) {
        document.querySelector('tbody>tr:nth-of-type(' + (this.state.tableDatas.length - id) + ')>td:nth-of-type(5)').classList.add('new');
        document.querySelector('tbody>tr:nth-of-type(' + (this.state.tableDatas.length - id) + ')>td:nth-of-type(10)').classList.add('new');
        setTimeout(() => {
          document.querySelector('tbody>tr:nth-of-type(' + (this.state.tableDatas.length - id) + ')>td:nth-of-type(5)').classList.remove("new");
          document.querySelector('tbody>tr:nth-of-type(' + (this.state.tableDatas.length - id) + ')>td:nth-of-type(10)').classList.remove("new");
        }, 30000);
      }

      tableDatas[this.state.tableDatas.length - id - 1].owner = owner;
      owner === '' ? tableDatas[this.state.tableDatas.length - id - 1].DisOwner = <p className='text-warning'> Unknown </p> : tableDatas[this.state.tableDatas.length - id - 1].DisOwner = <a href={"https://etherscan.io/address/" + owner}><b>{owner.slice(0, 6)}...{owner.slice(owner.length - 3, owner.length)}</b></a>;

      this.setState({
        tabledatas: tableDatas
      });

      // total supply check
      try {
        function addTrailingZeros(number, numZeros){
          const numberOfZeros = "0".repeat(numZeros);
          return (number + numberOfZeros);
        }
        supply = await tokenContract.methods.totalSupply().call();
        decimals = await tokenContract.methods.decimals().call();
        supply = supply / addTrailingZeros(1, (decimals));
      } catch (err) {
        supply = " can't catch ";
      }
      const formatCash = n => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
      };
      function separateComma(val) {
        // remove sign if negative
        let sign = 1;
        if (val < 0) {
          sign = -1;
          val = -val;
        }
        // trim the number decimal point if it exists
        const num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
        const len = num.toString().length;
        let result = '';
        let count = 1;

        for (let i = len - 1; i >= 0; i--) {
          result = num.toString()[i] + result;
          if (count % 3 === 0 && count !== 0 && i !== 0) {
            result = ',' + result;
          }
          count++;
        }

        // add number after decimal point
        if (val.toString().includes('.')) {
          result = result + '.' + val.toString().split('.')[1];
        }
        // return result with - sign if negative
        return sign < 0 ? '-' + result : result;
      }

      tableDatas = this.state.tableDatas;
      tableDatas[this.state.tableDatas.length - id - 1].supply = <div>{separateComma((supply))}</div>;
      this.setState({
        tabledatas: tableDatas
      });


      // verify check,  mint check ===============================================================
      try {
        if (this.state.tableDatas[this.state.tableDatas.length - id - 1].verifyStatus === true) {
          verifyStatus = true;
          mintStatus = this.state.tableDatas[this.state.tableDatas.length - id - 1].mintStatus;
        } else {
          const bscURL = 'https://api.etherscan.com/api?module=contract&action=getsourcecode&address=' + tokenAddress + '&apikey=' + etherscanAPIKey;
          await fetch(bscURL)
              .then(response => response.json())
              .then(
                  async (response) => {
                    try {
                      if (response['result']['0']['ABI'] === "Contract source code not verified") {
                        verifyStatus = false;
                        mintStatus = "unknown";
                      } else {
                        verifyStatus = true;
                        try {
                          if (response['result']['0']['SourceCode'].includes('mint') || response['result']['0']['SourceCode'].includes('Mint')) {
                            mintStatus = "Non-Mintable";
                          } else {
                            mintStatus = "Mintable";
                          }
                        } catch (err) {
                          mintStatus = "Non-mintable";
                        }
                      }
                    } catch (err) {
                      verifyStatus = false;
                    }
                  });
        }
      } catch (err) {
        verifyStatus = false;
        mintStatus = "Non-M";
      }

      tableDatas = this.state.tableDatas;
      tableDatas[this.state.tableDatas.length - id - 1].mintStatus = mintStatus;
      tableDatas[this.state.tableDatas.length - id - 1].verifyStatus = verifyStatus;
      if (mintStatus === 'unknown') {
        tableDatas[this.state.tableDatas.length - id - 1].mintStatusDis =
        (<Fragment>
          <span className={styles.unknown}>
            <a data-tip data-for='mintStatusDisUnknown'>
              <FiHelpCircle className={styles.icon}/>
              <span className={styles.text}>Unknown </span>
              {/*<span className={styles.unknownIcon}>1</span>*/}
            </a>
          </span>
          <ReactTooltip
            id='mintStatusDisUnknown'
            place="top"
            className={styles.buttonTooltip}
            effect='solid'
          >
            <div className={styles.tooltipHeader}>Contract Analysis</div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.unknown}><FiHelpCircle styles={{fontSize: 16}} className={styles.icon}/></span> Unknown if token is mintable </a></div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> contract functionality could mint new tokens.</span></a></div>
          </ReactTooltip>
        </Fragment>)
        ;
      } else if (mintStatus === 'Non-mintable') {
        tableDatas[this.state.tableDatas.length - id - 1].mintStatusDis =
        (<Fragment>
          <span className={styles.success}>
            <a data-tip data-for='mintStatusDisSuccess'>
              <FiCheckCircle className={styles.icon}/>
              <span className={styles.text}>Non-Mintable </span>
            </a>
          </span>
          <ReactTooltip
            id='mintStatusDisSuccess'
            place="top"
            className={styles.buttonTooltip}
            effect='solid'
          >
            <div className={styles.tooltipHeader}>Contract Analysis</div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.success}><FiCheckCircle styles={{fontSize: 16}} className={styles.icon}/></span> Token is not mintable </a></div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> contract functionality cannot mint new tokens.</span></a></div>


          </ReactTooltip>
        </Fragment>)
        ;
      } else {
        tableDatas[this.state.tableDatas.length - id - 1].mintStatusDis =
        (<Fragment>
          <span className={styles.danger}>
            <a data-tip data-for='mintStatusDis'>
              <FiXCircle className={styles.icon}/>
              <span className={styles.text}>Mintable </span>
              {/*<span className={styles.dangerIcon}>1</span>*/}
            </a>
          </span>
          <ReactTooltip
            id='mintStatusDis'
            place="top"
            className={styles.buttonTooltip}
            effect='solid'
          >
            <div className={styles.tooltipHeader}>Contract Analysis</div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.danger}><FiXCircle styles={{fontSize: 16}} className={styles.icon}/></span> Token is mintable </a></div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> contract functionality can mint new tokens.</span></a></div>


          </ReactTooltip>
        </Fragment>)
        ;
      }
      verifyStatus ? tableDatas[this.state.tableDatas.length - id - 1].verifyStatusDis =
      (<Fragment>
        <span className={styles.success}>
          <a data-tip data-for='contractverified'>
            <FiCheckCircle className={styles.icon}/>
            <span className={styles.text}>Verified </span>
          </a>
        </span>
        <ReactTooltip
          id='contractverified'
          place="top"
          className={styles.buttonTooltip}
          effect='solid'
        >
          <div className={styles.tooltipHeader}>Contract Analysis</div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.success}><FiCheckCircle styles={{fontSize: 16}} className={styles.icon}/></span> Contract is Verified </a></div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> source code is verified on Etherscan.</span></a></div>
        </ReactTooltip>
      </Fragment>) : tableDatas[this.state.tableDatas.length - id - 1].verifyStatusDis =
        (<Fragment>
          <span className={styles.danger}>
            <a data-tip data-for='contractunverified'>
              <FiXCircle className={styles.icon}/>
              <span className={styles.text}>Unverified </span>
              {/*<span className={styles.dangerIcon}>1</span>*/}
            </a>
          </span>
          <ReactTooltip
            id='contractunverified'
            place="top"
            className={styles.buttonTooltip}
            effect='solid'
          >
            <div className={styles.tooltipHeader}>Contract Analysis</div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.danger}><FiXCircle styles={{fontSize: 16}} className={styles.icon}/></span> Contract is Unverified </a></div>
            <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> source code is not verified on Etherscan.</span></a></div>
          </ReactTooltip>
        </Fragment>);
      this.setState({
        tabledatas: tableDatas
      });


      // honeypot check ================================================================
      try {
        const honeypot_url = 'https://api.honeypot.is/legacy/aws/isHoneypot?token=' + tokenAddress + '&chain=eth';
        await fetch(honeypot_url)
            .then(response => response.json())
            .then(
                async (response) => {
                  console.log(response);
                  honeyPotStatus = !response.IsHoneypot;
                  buyTax = response.BuyTax;
                  sellTax = response.SellTax;
                  buyGas = response.BuyGas;
                  sellGas = response.SellGas;
                });
      } catch (err) {
        honeyPotStatus = false;
      }

      if (isUpdate && tableDatas[this.state.tableDatas.length - id - 1].honeyPotStatus !== honeyPotStatus) {
        document.querySelector('tbody>tr:nth-of-type(' + (this.state.tableDatas.length - id) + ')>td:nth-of-type(8)').classList.add('new');
        setTimeout(() => {
          document.querySelector('tbody>tr:nth-of-type(' + (this.state.tableDatas.length - id) + ')>td:nth-of-type(8)').classList.remove("new");
        }, 30000);
      }
      tableDatas = this.state.tableDatas;
      tableDatas[this.state.tableDatas.length - id - 1].honeyPotStatus = honeyPotStatus;
      honeyPotStatus ? tableDatas[this.state.tableDatas.length - id - 1].honeyPotStatusDis =
      (<Fragment>
        <span className={styles.success}>
          <a data-tip data-for='honeyPotStatusDisSuccess'>
            <FiCheckCircle className={styles.icon}/>
            <span className={styles.text}>Okay </span>
          </a>
        </span>
        <ReactTooltip
          id='honeyPotStatusDisSuccess'
          place="top"
          className={styles.buttonTooltip}
          effect='solid'
        >
          <div className={styles.tooltipHeader}>Swap Analysis</div>
          <div><a data-tip data-for='honeyPotStatusDisSuccess'><span className={styles.success}><FiCheckCircle styles={{fontSize: 16}} className={styles.icon}/></span> Token currently is sellable</a></div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> appears to be sellable. Gas fees: <span className={styles.gasicon}><BiGasPump className={styles.gasicon}/></span> <b>{separateComma(buyGas)}</b> (approx)</span></a></div>
          <div><a data-tip data-for='honeyPotStatusDisSuccess'><span className={styles.success}><FiCheckCircle className={styles.icon}/></span> Token currently is buyable</a></div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> appears to be sellable. Gas fees: <span className={styles.gasicon}><BiGasPump className={styles.gasicon}/></span><b>{separateComma(sellGas)}</b> (approx)</span></a></div>
        </ReactTooltip>
      </Fragment>) : tableDatas[this.state.tableDatas.length - id - 1].honeyPotStatusDis =
      (<Fragment>

        <span className={styles.danger}>
          <a data-tip data-for='honeyPotStatusDis'>
            <FiXCircle className={styles.icon}/>
            <span className={styles.text}>HoneyPot</span>
            {/*<span className={styles.dangerIcon}>2</span>*/}
          </a>
        </span>

        <ReactTooltip
          id='honeyPotStatusDis'
          place="top"
          className={styles.buttonTooltip}
          effect='solid'
        >
          <div className={styles.tooltipHeader}>Swap Analysis</div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.danger}><FiXCircle styles={{fontSize: 16}} className={styles.icon}/></span> Token is currently not sellable </a></div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> appears to be unsellable (ignore for presale). </span></a></div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.danger}><FiXCircle className={styles.icon}/></span> Token currently is not buyable </a></div>
          <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.subtext}> <b>{tokenName}</b> appears to be unbuyable (ignore for presale). </span></a></div>

        </ReactTooltip>
      </Fragment>);
      //debugger
      if (buyTax || sellTax >= 10) {
        tableDatas[this.state.tableDatas.length - id - 1].taxStatus = <div className='text-danger'> Sell tax: {sellTax}% <br />Buy Tax: {buyTax}%</div>;
      } else {
        tableDatas[this.state.tableDatas.length - id - 1].taxStatus = <div className='text-success'> Sell tax: {sellTax}% <br />Buy Tax: {buyTax}%</div>;
      }


      this.setState({
        tabledatas: tableDatas
      });
      // holder check
      tableDatas[this.state.tableDatas.length - id - 1].holderStatus =
            (<Fragment>
              <span className={styles.success}>
                <a data-tip data-for='holderStatusSuccess'>
                  <FiCheckCircle className={styles.icon}/>
                  <span className={styles.text}>Okay </span>
                </a>
              </span>
              <ReactTooltip
                id='holderStatusSuccess'
                place="top"
                className={styles.buttonTooltip}
                effect='solid'
              >
                <div className={styles.tooltipHeader}>Holder Analysis</div>
                <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.success}><FiCheckCircle styles={{fontSize: 16}} className={styles.icon}/></span> Owner holds &lt; 5% supply</a></div>
                <div><a data-tip data-for='honeyPotStatusDis'><span className={styles.success}><FiCheckCircle styles={{fontSize: 16}} className={styles.icon}/></span> Holders have &lt; 5% supply</a></div>
              </ReactTooltip>
            </Fragment>)
      ;
      this.setState({
        tabledatas: tableDatas
      });

      // liquidity check
      try {
        const poolAddress = await factoryContract.methods.getPair(tokenAddress, ethAddress).call();
        let ethliquidityAmount = await wethContract.methods.balanceOf(poolAddress).call();
        ethliquidityAmount = (ethliquidityAmount / 1000000000000000000);
        const usdliquidityAmount = await routerContract.methods.getAmountsOut("1000000000000000000", [ethAddress, ethUsdtAddress]).call();
        ethPrice = usdliquidityAmount[1] / Math.pow(10, 6);
        liquidityAmount = (usdliquidityAmount[1] * ethliquidityAmount / 500000).toFixed(0);


      } catch (err) {
      }


      tableDatas = this.state.tableDatas;
      tableDatas[this.state.tableDatas.length - id - 1].liquidityStatus = '$' + internationalNumberFormat.format(liquidityAmount);
      this.setState({
        tabledatas: tableDatas
      });

      // token transferred count trad amount
      const transferEventArray = await tokenContract.getPastEvents('Transfer', {
        fromBlock: 0,
        toBlock: 'latest'
      });
      let countbuffer = 0;
      let tradedbuffer = 0;
      if (transferEventArray.length !== 0) {
        for (let index = 0; index < transferEventArray.length; index++) {
          if (transferEventArray[index].returnValues[0] === pairAddress || transferEventArray[index].returnValues[1] === pairAddress) {
            countbuffer = countbuffer + 1;
            const transaction = await web3.eth.getTransaction(transferEventArray[index].transactionHash);
            tradedbuffer = tradedbuffer + transaction.value / 1;
          }
        }
      }

      txCount = countbuffer;
      traded = internationalNumberFormat.format((tradedbuffer * ethPrice / Math.pow(10, 18)).toFixed(0));
      tableDatas = this.state.tableDatas;
      tableDatas[this.state.tableDatas.length - id - 1].txCount = txCount;
      tableDatas[this.state.tableDatas.length - id - 1].traded = '$' + traded;
      this.setState({
        tabledatas: tableDatas
      });
    } catch (err) {
      return;
    }
  }

  async getTimer(hash, id) {
    let releaseDate;
    try {
      const timetransaction = await web3.eth.getTransaction(hash);
      const blocknumber = timetransaction.blockNumber;
      const timeblock = await web3.eth.getBlock(blocknumber);
      const releasetime = timeblock.timestamp;

      let sincetime = Math.floor(Date.now() / 1000) - releasetime + 60;
      let symbol;
      let hour;
      let minute;
      let second;

      if (sincetime >= 0) {
        symbol = "";
        hour = Math.floor(sincetime / 3600);
        minute = Math.floor((sincetime - 3600 * hour) / 60);
        second = Math.floor(sincetime % 60);
      } else {
        sincetime = 0 - sincetime;
        symbol = "-";
        hour = Math.floor(sincetime / 3600);
        minute = Math.floor((sincetime - 3600 * hour) / 60);
        second = Math.floor(sincetime % 60);
      }


      if (hour !== 0) {
        releaseDate = symbol + hour + 'h ';
      } else {
        releaseDate = symbol + '';
      }
      if (minute !== 0) {
        releaseDate = releaseDate + minute + 'm ';
      }
      if (second !== 0) {
        releaseDate = releaseDate + second + 's';
      }
    } catch (err) {

    }

    const tableDatas = this.state.tableDatas;
    // debugger;
    // added this if statement to prevent undefinded releaseDate
    // if (releaseDate != "releaseDate") {
    tableDatas[this.state.tableDatas.length - id - 1].releaseDate = releaseDate;
    tableDatas[this.state.tableDatas.length - id - 1].DisReleaseDate = <div><FiClock /> {releaseDate}</div>;
    // }

    this.setState({
      tableDatas: tableDatas
    });
  }


  render() {
    const { toggleModal } = this.props;
    const rowsCaptureTable = this.state.tableDatas;
    const captureDataTable = {
      columns: [
        {
          label: 'Listed Since',
          field: 'DisReleaseDate',
          sort: 'disabled',
        },
        {
          label: 'Token',
          field: 'tokeninfo',
          sort: 'disabled',
        },
        {
          label: 'TOKEN/PAIR',
          field: 'tokenpair',
          sort: 'disabled',
        },
        {
          label: 'Chart',
          field: 'tokenAddressDis',
          sort: 'disabled',
        },
        {
          label: 'Sniff',
          field: 'tokenAddressSniff',
          sort: 'disabled',
        },
        {
          label: 'Hash',
          field: 'hashDis',
          sort: 'disabled',
        },

        {
          label: 'Owner',
          field: 'DisOwner',
          sort: 'disabled',
        },
        {
          label: 'Swap',
          field: 'honeyPotStatusDis',
          sort: 'disabled',
        },
        {
          label: 'Mint',
          field: 'mintStatusDis',
          sort: 'disabled',
        },
        {/*
          label: 'Holders',
          field: 'holderStatus',
          sort: 'disabled',
        */},
        {
          label: 'Contract',
          field: 'verifyStatusDis',
          sort: 'disabled',
        },
        {
          label: 'Ownership',
          field: 'renounceStatus',
          sort: 'disabled',
        },
        {
          label: 'Tax',
          field: 'taxStatus',
          sort: 'disabled',
        },
        {
          label: 'Liquidity',
          field: 'liquidityStatus',
          sort: 'disabled',
        },
        {
          label: 'TxCount',
          field: 'txCount',
          sort: 'disabled',
        },
        {
          label: 'Supply',
          field: 'supply',
        },
        {
          label: 'Volume Traded',
          field: 'traded',
          sort: 'disabled',
        },
      ],
      rows: rowsCaptureTable,
    };

    return (
      <div>
        <Helmet>
          <title>SCREENER / UniswapV2</title>
        </Helmet>
        <br />
        <div>
          <button
            onClick={() => toggleModal("tokenFinder")}
          >modal</button>
          <MDBDataTable
            small
            materialSearch
            noBottomColumns
            responsive
            theadColor="indigo"
            data={captureDataTable}
            entriesOptions={[10, 20, 50, 100]}
            entries={10}
            hover
            className="defiTable"
          />
        </div>
        <NotificationContainer />
      </div>
    );
  }
}
Uniswap.propTypes = {
  toggleModal: PropTypes.func,
};

Uniswap.defaultProps = {
  toggleModal: () => false,
};
export default Uniswap;
