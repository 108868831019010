import React, {Fragment, useEffect, useState} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane, Col, Row} from "reactstrap";
import FontAwesome from "../FontAwesome";
import Select from 'react-select';
import ColorPicker from "../ColorPicker/ColorPicker";
import styles from './WidgetScanner.module.scss';
import patterns from './constants';
import {INTERVALS as intervals, OHLCHistoryMaxTicks} from "../../constants/MarketsConstants";
import {useSelector} from "react-redux";
import {getActiveWidget, getWidgetExchange} from "../../selectors/wigdet";
import WidgetScannerAddButton from "./WidgetScannerAddButton";
import WidgetScannerDeleteButton from "./WidgetScannerDeleteButton";
import WidgetScannerEditButton from "./WidgetScannerEditButton";
import classes from './WidgetScanner.module.scss';

const tabs = {
  "Settings": {
    test: "1",
  },
  "Editor": {
    test: "2",
  },
};

const POINTS=[
  {title: "Source"},
  {name: "Open", short: "O"},
  {name: "High", short: "H"},
  {name: "Low", short: "L"},
  {name: "Close", short: "C"},
];
const TICKERS=[
  {title: "Tickers"},
  {name: "All"},
];
const TP=[
  {title: "Type"},
  {name: "Risk", svg: "risk"},
  {name: "USDT", svg: "USDT"},
  {name: "Percentage", svg: "percent"},
];
const ENTRY=[
  {title: "Type"},
  {name: "USDT", svg: "USDT"},
  {name: "Percentage", svg: "percent"},
];
const SL=[
  {title: "Type"},
  {name: "USDT", svg: "USDT"},
  {name: "Percentage", svg: "percent"},
];
const INTERVALS=intervals.map(({name, divider})=>({label: divider, options: []}));
for (let i=1, j=0; i<intervals.length; i++){
  //if (intervals[i].type==="divider"){
  //  j++;
  //}
  INTERVALS[j].options.push(intervals[i]);
}
export default ({onChange, settings}) => {
  const [tab, setTab] = useState(Object.keys(tabs)[0]);
  const [settingsPatterns, setSettingsPatterns] = useState(settings.patterns);
  const getPatternNameById = (searchId) => patterns.find(({options}) =>options.find(({id})=> id === searchId)).options.find(({id})=> id === searchId);
  const getIntervalNameById = (searchId) => intervals.find(({id}) => id === searchId);
  const addNewSettingsPattern = () => {
    setSettingsPatterns([
      ...settingsPatterns,
      settingsPatterns[settingsPatterns.length - 1]
    ]);
  };
  const widget=useSelector(getActiveWidget);
  const exchange=useSelector(state => getWidgetExchange(widget.i, state));

  const removeSettingsPattern = (i) => () => {
    setSettingsPatterns(settingsPatterns.filter((_, index) => i !== index));
  };
  const handleChangePattern = (index, name) => value => setSettingsPatterns(
      settingsPatterns.map((el, i) => i === index ? {...settingsPatterns[i], [name]: value} : settingsPatterns[i])
  );
  const validateLookback = (index)=> {
    if (settingsPatterns[index].lookback>OHLCHistoryMaxTicks[exchange]){
      handleChangePattern(index, 'lookback')(OHLCHistoryMaxTicks[exchange]);
    }
    if (settingsPatterns[index].lookback<30){
      handleChangePattern(index, 'lookback')(30);
    }
  };
  const [isOn, setIsOff] = useState(true);
  const [active, setActive] = useState(false);
  const handleClick = () => {
    setActive(!active);
    setIsOff(!isOn);
    ({target: {value}}) => (handleChangePattern(index, 'active')(value));
  };
  //debugger;
  useEffect(() => onChange(settingsPatterns), [settingsPatterns]);
  return (
    <div className={styles.panelRight}>

      <Fragment>
        <div className={styles.tabHeader}>
          <span className={styles.tabHeadline}><FontAwesome name="analytics" className={"iconHighlight"}/> Scan Names</span>
          {/*<div className={styles.tabParagraph}>Total script(s) to be added:</div>*/}
          <div className={styles.tabParagraph}>Build scanner script(s) with filters below:</div>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.container}>
            {settingsPatterns.map(({id, interval, name, RSILength, EntryPrice, SLPrice, TPPrice, active, lookback, point="Close"}, index) => (
              <Row style={{width: "100%"}}>
                <div className={styles.patternScript}>
                  <Col
                    xs={11}
                    className={styles.accordion}
                  >
                    <input type="checkbox" id={index} />
                    <label htmlFor={index}>
                      <div className={styles.titleAccordion}>
                        <img style={{marginRight: 5, opacity: .5}} className={styles.scannerIcon} alt="list" src="/img/icons/scanner.svg" />
                        <input
                          placeholder="Create a scan name.."
                          value={name}
                          onChange={({target: {value}}) => (handleChangePattern(index, 'name')(value))}
                        />
                        <button
                          className={styles.scanActive}
                          onClick={handleClick}
                          onChange={({target: {value}}) => (handleChangePattern(index, 'active')(value))}
                          style={{
                            marginLeft: 5,
                            border: "solid",
                            borderWidth: "1px",
                            borderColor: isOn ? 'rgba(92, 184, 92, 0.3)' : 'rgba(255, 90, 54, 0.3)',
                            color: isOn ? 'rgba(92, 184, 92, 0.8)' : 'rgba(255, 90, 54, 0.8)',
                          }}
                        >{ isOn ? 'Active' : 'Inactive' }
                        </button>
                        <FontAwesome
                          className={styles.plusIcon}
                          name="chevron-down"
                          prefix="fas"
                          style={{opacity: .5}}
                        />
                      </div>
                    </label>
                    <div className={styles.content}>
                      <span>
                        <div>
                          <Nav tabs className={styles.active1}>
                            {Object.keys(tabs).map((name) => (
                              <NavItem key={name}>
                                <NavLink
                                  className={name === tab ? styles.active : styles.inactive}
                                  onClick={() => setTab(name)}
                                >
                                  {name}
                                </NavLink>
                              </NavItem>))}
                          </Nav>
                        </div>
                        <div className={styles.patternSearch}>
                          Scan type:
                          <span style={{marginLeft: 5}} className={styles.greyLightInput}>
                            <FontAwesome
                              style={{marginRight: 5, marginLeft: 5}}
                              name="search"
                              prefix="far"
                            />
                            <Select
                              options={patterns}
                              className="selectClearSearch"
                              classNamePrefix="react-select"
                              //isSearchable
                              //menuIsOpen
                              placeholder="Search..."
                              onChange={(element)=>handleChangePattern(index, 'id')(element.id)}
                              defaultValue={getPatternNameById(id)}
                              formatOptionLabel={patterns => (
                                <div>
                                  <span className={styles.dropdownName}>{patterns.value}</span>
                                </div>
                              )}
                            />
                          </span>
                        </div>
                        <div className={styles.patternScriptHeader}>Scan passes all of the following filters:</div>

                        <TabContent activeTab={id}>

                          <Fragment>
                            <TabPane tabId="1">
                              one
                            </TabPane>
                            <TabPane tabId="2">
                              two
                            </TabPane>
                          </Fragment>
                        </TabContent>
                      </span>
                      <div className={styles.codeLeft}>
                        {/*<span className={styles.words}>Scan through</span>*/}
                        <div style={{marginLeft: 5}} className={styles.greyLight}>Ticker</div>
                        <span className={styles.greyLightInput}>
                          <Select
                            options={TICKERS}
                            className="selectClearohcl"
                            classNamePrefix="react-select"
                            //menuIsOpen
                            placeholder="Search..."
                            defaultValue={{name: "All"}}
                            onChange={point=>handleChangePattern(index, 'point')(point.name)}
                            formatOptionLabel={TICKERS => (
                              <div>
                                <span className={styles.dropdownTitle}><b>{TICKERS.title}</b></span>
                                <span className={styles.dropdownName}>{TICKERS.name}</span>
                              </div>
                            )}
                          />
                        </span>
                        <span className={styles.words}>Indicator</span>
                        <div className={styles.greyLight}>
                          RSI
                        </div>
                        <span className={styles.greyLightInput}>
                          <input
                            className={styles.inputRSI}
                            onChange={({target: {value}}) => Number(handleChangePattern(index, 'RSILength')(value))}
                            value={RSILength}
                          />
                        </span>

                        <div style={{marginLeft: 5}} className={styles.greyLight}>
                          Color
                        </div>
                        <div className={styles.colorpicker}>
                          <ColorPicker
                            color="green"
                          />
                        </div>
                      </div>

                      <div className={styles.codeLeft}>
                        <span className={styles.words}>and</span>
                        <span className={styles.greyLight}>Bar Type</span>
                        <span className={styles.greyLightInput}>
                          <Select
                            options={intervals}
                            className="selectClear"
                            classNamePrefix="react-select"
                            //menuIsOpen
                            placeholder="Search..."
                            defaultValue={getIntervalNameById(interval)}
                            onChange={(item)=>handleChangePattern(index, 'interval')(item.id)}
                            formatOptionLabel={intervals => (
                              <div>
                                <span className={styles.dropdownTitle}><b>{intervals.divider}</b></span>
                                <span className={styles.dropdownShortName}>{intervals.name}</span>
                                <span className={styles.dropdownName}>{intervals.fullname}</span>
                              </div>
                            )}
                          />
                        </span>
                        <div className="ohcl">
                          <span className={styles.greyLight}>Source</span>
                          <span className={styles.greyLightInput}>
                            <Select
                              options={POINTS}
                              className="selectClearohcl"
                              classNamePrefix="react-select"
                              //menuIsOpen
                              placeholder="Search..."
                              defaultValue={{name: point}}
                              onChange={point=>handleChangePattern(index, 'point')(point.name)}
                              formatOptionLabel={POINTS => (
                                <div>
                                  <span className={styles.dropdownTitle}><b>{POINTS.title}</b></span>
                                  <span className={styles.dropdownName}><b>{POINTS.short}</b> </span>
                                  <span className={styles.dropdownName}>{POINTS.name}</span>
                                </div>
                              )}
                            />
                          </span>
                        </div>

                        {/*<span className={styles.words}>and</span>*/}
                        <span className={styles.greyLight}>Lookback</span>
                        <div className={styles.greyLightInput}>
                          <input
                            className={styles.inputLookback}
                            max={OHLCHistoryMaxTicks[exchange]}
                            min={30}
                            type="number"
                            value={lookback}
                            onBlur={()=>validateLookback(index)}
                            onChange={({target: {value}}) => Number(handleChangePattern(index, 'lookback')(value))}/>
                        </div>
                      </div>
                      <div className={styles.codeLeft}>
                        <span className={styles.words}>draw Entry</span>
                        <div className={styles.yellowLight}>
                          <input
                            className={styles.inputEntry}
                            onChange={({target: {value}}) => Number(handleChangePattern(index, 'EntryPrice')(value))}
                            value={EntryPrice}
                          />
                        </div>
                        <div className="dropdownTP">
                          <Select
                            options={ENTRY}
                            className="scannerTypes"
                            classNamePrefix="react-select"
                            //menuIsOpen
                            defaultValue={{name: <img
                              alt=""
                              height={13}
                              src={`/img/icons/percent.svg`}
                            />}}
                            formatOptionLabel={ENTRY => (
                              <div>
                                <span className={styles.dropdownTitle}><b>{ENTRY.title}</b></span>
                                <span className={styles.dropdownName}><b>
                                  <img
                                    alt=""
                                    height={13}
                                    src={`/img/icons/${ENTRY.svg}.svg`}
                                  />
                                </b> </span>
                                <span className={styles.dropdownName}>{ENTRY.name}</span>
                              </div>
                            )}
                          />

                        </div>

                        <span className={styles.words}>Stop Loss</span>
                        <div className={styles.redLight}>
                          <input
                            className={styles.inputSL}
                            onChange={({target: {value}}) => Number(handleChangePattern(index, 'SLPrice')(value))}
                            value={SLPrice}
                          />
                        </div>
                        <div className="dropdownTP">
                          <Select
                            options={SL}
                            className="scannerTypes"
                            classNamePrefix="react-select"
                            //menuIsOpen
                            defaultValue={{name: <img
                              alt=""
                              height={13}
                              src={`/img/icons/percent.svg`}
                            />}}
                            formatOptionLabel={SL => (
                              <div>
                                <span className={styles.dropdownTitle}><b>{SL.title}</b></span>
                                <span className={styles.dropdownName}><b>
                                  <img
                                    alt=""
                                    height={13}
                                    src={`/img/icons/${SL.svg}.svg`}
                                  />
                                </b> </span>
                                <span className={styles.dropdownName}><b>{SL.short}</b> </span>
                                <span className={styles.dropdownName}>{SL.name}</span>
                              </div>
                            )}
                          />
                        </div>
                        <span className={styles.words}>Take Profit</span>
                        <div className={styles.greenLight}>
                          <input
                            className={styles.inputTP}
                            onChange={({target: {value}}) => Number(handleChangePattern(index, 'TPPrice')(value))}
                            value={TPPrice}
                          />
                        </div>
                        <div className="dropdownTP">
                          <Select
                            options={TP}
                            className="scannerTypes"
                            classNamePrefix="react-select"
                            //menuIsOpen
                            defaultValue={{name: <img
                              alt=""
                              height={13}
                              src={`/img/icons/risk.svg`}
                            />}}
                            formatOptionLabel={TP => (
                              <div>
                                <span className={styles.dropdownTitle}><b>{TP.title}</b></span>
                                <span className={styles.dropdownName}><b>
                                  <img
                                    alt=""
                                    height={13}
                                    src={`/img/icons/${TP.svg}.svg`}
                                  />
                                </b> </span>
                                <span className={styles.dropdownName}><b>{TP.short}</b> </span>
                                <span className={styles.dropdownName}>{TP.name}</span>
                              </div>
                            )}
                          />
                        </div>
                        <span className={styles.words}>levels</span>

                      </div>
                      <div className={styles.listActions}>
                        {settingsPatterns.length > 1 && <button
                          className={styles.addbutton}
                          onClick={removeSettingsPattern(index)}>
                          <FontAwesome
                            name="trash"
                            prefix="fas"
                          /> Remove this scan</button>}
                        {/*{index + 1 === settingsPatterns.length &&
                      <span onClick={addNewSettingsPattern}><FontAwesome
                        name="plus-circle"
                        prefix="fas"
                      /></span>}*/}
                      </div>
                    </div>
                  </Col>
                  <Col xs={2} className={classes.itemAction} >
                    <WidgetScannerAddButton onClick={addNewSettingsPattern}/>
                    <WidgetScannerDeleteButton />
                    <WidgetScannerEditButton />
                  </Col>
                </div>
              </Row>
            ))}
            <button className={styles.addbutton} onClick={addNewSettingsPattern}><FontAwesome
              name="plus-circle"
              prefix="fas"
            />Add new scan</button>
          </div>
        </div>
      </Fragment>
    </div>
  );
};
