import {v4} from "uuid";

export default ({ts1, price1, base, quote, text}) => (  {
  id: v4(),
  type: 'text',
  points: 1,
  ts1,
  price1,
  settings: {
    inputs: {},
    plots: {
      fontColor: {
        label: 'Text Color',
        type: 'color',
        hex: '#ffffff',
        rgba: {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        }
      },
      fontSize: {
        label: 'Text Size',
        type: 'number',
        value: 13,
        col: 2
      },
      fontBold: {
        label: 'Bold',
        text: 'B',
        role: 'toggle-bold',
        type: 'toggle-button',
        checked: false,
        col: 1
      },
      fontItalic: {
        label: 'Italic',
        text: 'I',
        role: 'toggle-italic',
        type: 'toggle-button',
        checked: false,
        col: 1
      },
      textField: {
        type: 'textfield',
        text: text,
        col: 12
      }
    }
  },
  exchange: 'binance',
  market: `${base}-${quote}`,
});
