import {indicatorQuotesInit, quotesInit} from './model/quotes';
import {humanScalePrice, } from './chartTools';
import tickerInterval from "./timescale";

/**
 * View model data structure
 * It is the data of current chart view
 */

function preparePriceScale(min, max, priceScaleCnt) {
  const humanScale = humanScalePrice(max - min, priceScaleCnt);
  const scaleLines = [];
  const start = min - min % humanScale;
  for (let i = Math.min(start, 0.0); i < max; i += humanScale) {
    if (i > min) {
      scaleLines.push(i.toFixed(8));
    }
  }
  return scaleLines;
}

function prepareTimeScale(capacity, quotes, lastData, firstData, offset) {
  let min = new Date(quotes[0].date).getTime();
  let max = new Date(quotes[quotes.length - 1].date).getTime();
  const intervalms = quotes[quotes.length - 1].timestamp - quotes[quotes.length - 2].timestamp;
  if (lastData.timestamp == quotes[quotes.length - 1].timestamp) {
    max = max + (offset + 1) * intervalms;
  }
  if (firstData.timestamp === quotes[0].timestamp) {
    min = min - (capacity - (quotes.length + offset)) * intervalms;
  }
  const yScaleLines = [];

  const ticks = tickerInterval(10, min, max);
  let tickIndex = 0;
  let lastTS = 0;
  for (let i = 0; i < capacity; ++i) {
    const quote = quotes[i];
    if (ticks[tickIndex] == undefined) {
      break;
    }
    const tickMilli = ticks[tickIndex][0].getTime();
    if (quote && (quote.timestamp == tickMilli || quote.timestamp > tickMilli)) {
      yScaleLines.push([i, ticks[tickIndex][1]]);
      tickIndex++;
      lastTS = quote.timestamp;
    } else {
      lastTS += intervalms;
      if (lastTS >= tickMilli) {
        yScaleLines.push([i, ticks[tickIndex][1]]);
        tickIndex++;
      }
    }
  }
  if (firstData.timestamp === quotes[0].timestamp) {
    yScaleLines.forEach(line => line[0] += capacity - (quotes.length + offset));
  }
  return yScaleLines;
}

// Chart view data model
export function initViewModel(chartId, drawList, capacity, offset, quotes, quoteMin, quoteMax) {
  const lastData = quotes[quotes.length - 1];
  const firstDara = quotes[0];
  const q = quotes.slice(
      Math.min(-5, -capacity + offset + 1),
      Math.min(quotes.length, quotes.length + offset)
  );

  const viewModel = {
    /**
     * Quotes model, contains all price data that is in current view
     */
    quotes: null,
    volquotes: null,
    /**
     * Data user cursor points to
     */
    cursorData: null,
    /**
     * Values of the scale lines
     */
    priceLines: null,
    timeLines: null,
  };
  let priceScaleCnt = 8;
  if (chartId.slice(0, 9) == 'mainchart') {
    const {quotes, volquotes} = quotesInit(q, drawList);
    viewModel.quotes = quotes;
    viewModel.volquotes = volquotes;
  } else {
    viewModel.quotes = indicatorQuotesInit(q, drawList);
    priceScaleCnt = 4;
  }
  if (quoteMin, quoteMax) {
    viewModel.quotes.min = quoteMin;
    viewModel.quotes.max = quoteMax;
    viewModel.quotes.range = quoteMax - quoteMin;
  }
  viewModel.cursorData = [null, null];

  viewModel.capacity = capacity;
  viewModel.priceLines = preparePriceScale(viewModel.quotes.min, viewModel.quotes.max, priceScaleCnt);
  viewModel.timeLines = prepareTimeScale(capacity, viewModel.quotes.data, lastData, firstDara, offset);
  return viewModel;
}
