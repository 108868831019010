import {connect} from "react-redux";
import {toggleModal} from "../../../actions/MarketsActions";
import ProfileSettingsModal from "../components/ProfileSettingsModal";
import {editUserAction} from "../../../actions/UserAction";

export default connect(
    (state) => {
      const user = state.user.user.data;

      return {
        rootChannels: state.chat.rootChannels,
        isOpen: state.markets.modals.profileSettings,
        data: state.markets.modalData,
        user: user,
      };
    }, {
      toggleModal,
      editUserAction
    },
)(ProfileSettingsModal);