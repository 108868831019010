import FontAwesome from "../../components/FontAwesome";
import PropTypes from "prop-types";
import styles from "./WidgetScanner.module.scss";
import ReactTooltip from 'react-tooltip';

const WidgetScannerAddButton = ({addNewSettingsPattern}) => {
  return (<button
    onClick={addNewSettingsPattern}
  >
    <a data-tip data-for='addButton'>
      <FontAwesome
        name="plus-circle"
        prefix="fas"
        className={styles.addIcon}
      />
    </a>
    <ReactTooltip
      id='addButton'
      offset="{'top': -10, 'left': -29}"
      arrowColor='transparent'
      className={styles.buttonTooltip}
      effect='solid'
    >
      <span>Add new scan</span>
    </ReactTooltip>
  </button>);
};

WidgetScannerAddButton.propTypes = {
  addNewSettingsPattern: PropTypes.func.isRequired,
};
export default WidgetScannerAddButton;
