// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1S9gV1GClhqtGU3HXS9rgg{padding:4px 8px !important;background-color:#2a2d34 !important;color:rgba(255,255,255,.75) !important;font-weight:400}._1GqH73efp_JkpRcwRq_q_W{height:calc(100% - 26px);width:100%;overflow:hidden}._1GqH73efp_JkpRcwRq_q_W:hover{cursor:pointer}._1GqH73efp_JkpRcwRq_q_W .nyewuaYBsQ4sjIGalKj12{text-align:center}.treemap-chart text.name,.treemap-chart text.name2,.treemap-chart text.price,.treemap-chart text.change{fill:#fff}.rect{stroke:#b8b8b8;stroke-opacity:1;stroke-width:1px;stroke-linejoin:round;paint-order:stroke}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/TreemapWidget/TreemapWidget.scss"],"names":[],"mappings":"AAEE,yBACE,0BAAA,CACA,mCAAA,CACA,sCAAA,CACA,eAAA,CAEF,yBACE,wBAAA,CACA,UAAA,CACA,eAAA,CACA,+BACE,cAAA,CAEF,gDACE,iBAAA,CAQF,wGACE,SAAA,CAGJ,MAEE,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":["@import \"../../../../styles/constants\";\n:local {\n  .buttonTooltip {\n    padding: 4px 8px !important;\n    background-color: $background-dark !important;\n    color: $white-faded !important;\n    font-weight: 400;\n  }\n  .Wrapper {\n    height: calc(100% - 26px);\n    width: 100%;\n    overflow: hidden;\n    &:hover {\n      cursor: pointer;\n    }\n    .tooltiptext {\n      text-align: center;\n    }\n\n  }\n}\n\n:global {\n  .treemap-chart {\n    text.name, text.name2, text.price, text.change {\n      fill: #ffffff;\n    }\n  }\n  .rect {\n    //outline: 1px solid #888;\n    stroke: #B8B8B8;\n    stroke-opacity: 1;\n    stroke-width: 1px;\n    stroke-linejoin: round;\n    paint-order: stroke;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonTooltip": "_1S9gV1GClhqtGU3HXS9rgg",
	"Wrapper": "_1GqH73efp_JkpRcwRq_q_W",
	"tooltiptext": "nyewuaYBsQ4sjIGalKj12"
};
export default ___CSS_LOADER_EXPORT___;
