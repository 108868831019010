import React from "react";
import WidgetToolbar from "../containers/WidgetToolbarContainer";
import styles from "./SelectWidget.scss";
import Logo from "../../../assets/aurlix-grey.svg";

const SelectWidget = (props) => {
  return (
    <div className="widgetWrapper">
      <WidgetToolbar
        {...props}
      />
      <div className={styles.SelectWidget}>
        <div className={styles.ImageWrapper}>
          <img
            src={Logo}
            alt="aurlix"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectWidget;
