import {uid} from "uid";
import {
  GET_MARKETS_LIST_SUCCESS,
  GET_CHART_DATA,
  GET_MTF_INIT_CHART_DATA,
  GET_LATEST_CHART_DATA_SUCCESS,
  GET_LATEST_MTF_CHART_DATA_SUCCESS,
  GET_PREV_CHART_DATA_SUCCESS,
  GET_CHART_DATA_SUCCESS,
  GET_MTF_INIT_CHART_DATA_SUCCESS,
  GET_MARKET_DATA_SUCCESS,
  GET_PRICE_DATA_SUCCESS,
  ADD_WORKSHEET,
  CHANGE_WORKSHEET,
  REMOVE_WORKSHEET,
  ADD_WIDGET,
  REMOVE_WIDGET,
  UPDATE_WIDGET,
  UPDATE_WORKSHEETS,
  SET_WIDGET_ACTIVE,
  DEFAULT_WORKSHEET,
  GET_EXCHANGES,
  GET_EXCHANGES_ERROR,
  GET_EXCHANGES_SUCCESS,
  GET_EXCHANGE_MARKETS,               // get an exchange markets info from api
  GET_EXCHANGE_MARKETS_SUCCESS,
  GET_EXCHANGE_MARKETS_ERROR,
  GET_MARKETS,                        // get from postgres database
  GET_MARKETS_SUCCESS,
  GET_MARKETS_ERROR,
  GET_MARKETS_INFO_SUCCESS,           // get from public json file
  GET_MARKET_SUMMARY,                 // get ticker data from api
  GET_MARKET_SUMMARY_SUCCESS,
  GET_MARKET_SUMMARY_ERROR,
  GET_MARKET_HISTORY,
  GET_MARKET_HISTORY_SUCCESS,
  GET_MARKET_HISTORY_TRADE_SUCCESS,
  GET_MARKET_DOM,
  GET_MARKET_DOM_SUCCESS,
  GET_MARKET_DOM_SNAPSHOT_SUCCESS,
  GET_MARKET_DOM_UPDATE_SUCCESS,
  TOGGLE_MODAL,
  SENTIMENT_DATA,
  GET_AVAILABLE_TFS,
} from "../constants/MarketsConstants";
import {mergeWith} from "lodash";

const initialState = {
  currentWorksheet: DEFAULT_WORKSHEET.id,
  worksheets: [],
  exchanges: [],
  exchangeMarkets: [],
  markets: [],
  marketSummaries: [],
  marketSummary: null,
  marketHistory: [],
  marketOrderbook: {},
  marketOrderbookUpdate: {},
  marketsInfo: {},
  modals: {
    chartSettings: false,
    exchanges: false,
    drawingSettings: false,
    indicatorSettings: false,
    markets: false,
    snapshot: false,
    widgetSettings: false,
    feedFilter: false,
    tokenFinder: false,
    addNewWidget: false,
    WorksheetSettings: false,
    feedSource: false,
    domChartSettings: false,
    chat: false,
    trade: false,
  },
  modalData: null,
};

const getChartItem = (item) => {
  return {
    timestamp: item[0],
    date: new Date(item[0]),
    open: +item[1],
    high: +item[2],
    low: +item[3],
    close: +item[4],
    volume: +item[5],
  };
};

const mapChartData = (data) => {
  if (!data) {
    return [];
  }
  return data.map(getChartItem);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_TFS:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === action.payload.worksheetId) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.widgetId) {
                  return {
                    ...widget,
                    data: [],
                    availableTF: Object.keys(action.data),
                  };
                }
                return widget;
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_EXCHANGES:
      return {
        ...state,
        exchanges: [],
      };
    case GET_EXCHANGES_ERROR:
      return {
        ...state,
        exchanges: [],
      };
    case GET_EXCHANGES_SUCCESS:
      return {
        ...state,
        exchanges: action.data,
      };
    case GET_EXCHANGE_MARKETS:
      return {
        ...state,
        exchangeMarkets: []
      };
    case GET_EXCHANGE_MARKETS_SUCCESS:
      return {
        ...state,
        exchangeMarkets: action.data
      };
    case GET_EXCHANGE_MARKETS_ERROR:
      return {
        ...state,
        exchangeMarkets: []
      };
    case GET_MARKETS:
      return {
        ...state,
        marketsLoading: true,
        markets: [],
      };
    case GET_MARKETS_SUCCESS:
      return {
        ...state,
        marketsLoading: false,
        markets: action.data,
      };
    case GET_MARKETS_ERROR:
      return {
        ...state,
        marketsLoading: false,
        markets: [],
      };
    case GET_MARKETS_LIST_SUCCESS:
      return {
        ...state,
        marketSummaries: action.payload.data.result,
      };
    case UPDATE_WORKSHEETS:
      const getWidgetById=(id)=>state.worksheets.find(({widgets})=>widgets.find(({i}) => i === id))?.widgets.find(({i}) => i === id);
      const getWidgetTempById=(id)=> {
        const widget=getWidgetById(id)??{};
        return Object.fromEntries(Object.entries(widget).filter(([key]) => key[0] === '_'));
      };
      const worksheets=action.worksheets.map((worksheet)=>({
        ...worksheet,
        widgets: worksheet.widgets.map((widget) => ({
          ...getWidgetTempById(widget.i),
          ...widget
        }))
      }));
      return {
        ...state,
        worksheets,
      };
    case GET_CHART_DATA:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === action.payload.worksheetId) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.widgetId) {
                  return {
                    ...widget,
                    basePeriod: action.payload.basePeriod,
                    loading: true,
                  };
                }
                return widget;
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_MTF_INIT_CHART_DATA:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === action.payload.worksheetId) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.widgetId) {
                  return {
                    ...widget,
                    mtf_data: [],
                  };
                }
                return widget;
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === action.payload.worksheet) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.widget) {
                  return {
                    ...widget,
                    data: widget.type === "price" ? {
                      ...widget.data,
                      chart: mapChartData(action.payload.data),
                    } : mapChartData(action.payload.data),
                    loading: false,
                  };
                }
                return widget;
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_MTF_INIT_CHART_DATA_SUCCESS:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === action.payload.worksheet) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.widget) {
                  return {
                    ...widget,
                    mtf_data: mapChartData(action.payload.mtf_data),
                  };
                }
                return widget;
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_PREV_CHART_DATA_SUCCESS:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === action.payload.worksheet) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.widget) {
                  const newData = action.payload.data.filter((d) => !widget.data.find((j) => j.timestamp === d[0]));
                  return {
                    ...widget,
                    data: [
                      ...mapChartData(newData),
                      ...widget.data,
                    ],
                    loading: false,
                  };
                }
                return widget;
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_PRICE_DATA_SUCCESS:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === state.currentWorksheet) {
            return {
              ...worksheet,
              widgets: [
                ...worksheet.widgets.map((widget) => {
                  if (widget.i === action.payload.widget) {
                    if (widget.type === "price") {
                      return {
                        ...widget,
                        data: {
                          ...widget.data,
                          market: action.payload.data,
                        },
                      };
                    } else if (widget.type === "chart") {
                      return {
                        ...widget,
                        data: widget.data.length > 0 ? widget.data.map((item, index) =>
                          (index === widget.data.length - 1) ? {
                            ...item,
                            high: action.payload.data.high,
                            low: action.payload.data.low,
                            isLast: true,
                            close: action.payload.data.close,
                          } : item) : widget.data,
                      };
                    }
                  }
                  return widget;
                }),
              ],
            };
          }
          return worksheet;
        }),
      };
    case GET_LATEST_CHART_DATA_SUCCESS:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          return {
            ...worksheet,
            widgets: worksheet.widgets.map((widget) => {
              if (widget.i === action.payload.widget &&
                          action.payload.id === widget.marketId) {
                const incomingData = action.payload.data;
                const oldData = widget.data;
                if (oldData.length > 0) {

                  oldData[oldData.length - 1].close= +incomingData.close;
                  if (oldData[oldData.length - 1].close>oldData[oldData.length - 1].high) {
                    oldData[oldData.length - 1].high=oldData[oldData.length - 1].close;
                  } else if (oldData[oldData.length - 1].close<oldData[oldData.length - 1].low) {
                    oldData[oldData.length - 1].low=oldData[oldData.length - 1].close;
                  }
                  return {
                    ...widget,
                    data: [...oldData],
                    loading: false,
                  };
                } else return widget;
              }
              return widget;
            }),
          };
        }),
      };
    case GET_LATEST_MTF_CHART_DATA_SUCCESS:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === action.payload.worksheet) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.widget) {
                  const lastData = action.payload.mtf_data;
                  const newItems = lastData.filter((item) =>
                    widget.mtf_data[widget.mtf_data.length - 1].timestamp < item[0]);
                  if (newItems.length > 0){
                    return {
                      ...widget,
                      mtf_data: [
                        ...widget.mtf_data,
                        ...mapChartData(newItems),
                      ],
                      loading: false,
                    };
                  } else {
                    return widget;
                  }
                }
                return widget;
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_MARKET_DATA_SUCCESS:
      if (action.payload.widget) {
        return {
          ...state,
          worksheets: state.worksheets.map((worksheet) => {
            if (worksheet.id === state.currentWorksheet) {
              return {
                ...worksheet,
                widgets: [
                  ...worksheet.widgets.map((widget) => {
                    if (widget.i === action.payload.widget) {
                      return {
                        ...widget,
                        data: action.payload.data,
                      };
                    }
                    return widget;
                  }),
                ],
              };
            }
            return worksheet;
          }),
        };
      } else {
        return {
          ...state,
          marketSummaries: action.payload.data,
        };
      }
    case ADD_WORKSHEET:
      return {
        ...state,
        worksheets: [...state.worksheets, {
          id: uid(10),
          name: `Worksheet ${state.worksheets.length + 1}`,
          widgets: [],
        }],
      };
    case CHANGE_WORKSHEET:
      return {
        ...state,
        currentWorksheet: action.worksheet,
      };
    case REMOVE_WORKSHEET:
      return {
        ...state,
        worksheets: state.worksheets
            .filter((worksheet) => worksheet.id !== state.currentWorksheet)
            .map((worksheet, i) => {
              worksheet.name = `Worksheet ${i + 1}`;
              return worksheet;
            }),
      };
    case ADD_WIDGET:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => worksheet.id === state.currentWorksheet ? ({
          ...worksheet,
          widgets: [
            ...worksheet.widgets,
            action.widget,
          ],
        }) : worksheet),
      };
    case REMOVE_WIDGET:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => worksheet.id === state.currentWorksheet ? ({
          ...worksheet,
          widgets: worksheet.widgets.filter((widget) => widget.i !== action.widget),
        }) : worksheet),
      };
    case UPDATE_WIDGET:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => (worksheet.id === state.currentWorksheet) ? {
          ...worksheet,
          widgets: worksheet.widgets.map((widget) =>
              widget.i === action.widget.i ? action.widget : widget),
        } : worksheet,
        ),
      };
    case SET_WIDGET_ACTIVE:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => worksheet.id === state.currentWorksheet ? ({
          ...worksheet,
          widgets: worksheet.widgets.map((widget) => ({
            ...widget,
            active: widget.i === action.widget,
          })),
        }) : worksheet),
      };
    case SENTIMENT_DATA:
      return {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === state.currentWorksheet) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.payload.data.widget) {
                  return {
                    ...widget,
                    data: action.payload.data,
                  };
                } else {
                  return widget;
                }
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_MARKET_SUMMARY:
    case GET_MARKET_SUMMARY_ERROR:
      return {
        ...state,
        marketSummary: null,
      };
    case GET_MARKET_SUMMARY_SUCCESS:
      return !action.widget ? {
        ...state,
        marketSummary: action.market,
      } : {
        ...state,
        worksheets: state.worksheets.map((worksheet) => {
          if (worksheet.id === state.currentWorksheet) {
            return {
              ...worksheet,
              widgets: worksheet.widgets.map((widget) => {
                if (widget.i === action.widget) {
                  return {
                    ...widget,
                    marketSummary: action.market,
                  };
                } else {
                  return widget;
                }
              }),
            };
          }
          return worksheet;
        }),
      };
    case GET_MARKET_HISTORY:
      return {
        ...state,
        marketHistory: [],
      };
    case GET_MARKET_HISTORY_TRADE_SUCCESS:
      const result = action.payload.data;
      const newTrade = {
        id: result.tradeId,
        side: result.side,
        price: result.price,
        amount: result.amount,
        timestamp: result.unix,
        isNew: true
      };
      const marketHistory = [newTrade, ...state.marketHistory.slice(0, 199)];
      return {
        ...state,
        marketHistory
      };
    case GET_MARKET_HISTORY_SUCCESS:
      let newItems = undefined;
      if (state.marketHistory.length === 0){
        newItems = action.payload.data.slice(0, 200);
        newItems = newItems.map((item) => ({
          ...item,
          isNew: false,
        }));
      } else {
        const lastTrade = state.marketHistory[0];
        newItems = action.payload.data
            .filter((item) => item.timestamp > lastTrade.timestamp);
        newItems = newItems.map((item) => ({
          ...item,
          isNew: true,
        }));
      }
      const oldItems = state.marketHistory.slice(0, 200 - newItems.length);
      return {
        ...state,
        marketHistory: [
          ...newItems,
          ...oldItems.map((item) => ({
            ...item,
            isNew: false,
          })),
        ]
      };
    case GET_MARKET_DOM:
      return {
        ...state,
        marketOrderbook: {},
      };
    case GET_MARKET_DOM_SUCCESS:
      return {
        ...state,
        marketOrderbook: action.payload.data,
      };
    case GET_MARKET_DOM_SNAPSHOT_SUCCESS:
      const snapshot = action.payload.data;
      const bids = snapshot.bids.slice(0, Math.min(40, snapshot.bids.length));
      const asks = snapshot.asks.slice(0, Math.min(40, snapshot.asks.length));
      const newBids = bids.map((bid)=> [bid.price, bid.size]);
      const newAsks = asks.map((ask)=> [ask.price, ask.size]);
      return {
        ...state,
        marketOrderbook: {
          bids: newBids,
          asks: newAsks
        },
      };
    case GET_MARKET_DOM_UPDATE_SUCCESS:
      return {
        ...state,
        marketOrderbookUpdate: action.payload.data
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modalData: action.data,
        modals: {
          ...state.modals,
          [action.modal]: !state.modals[action.modal],
        },
      };
    case GET_MARKETS_INFO_SUCCESS:
      return {
        ...state,
        marketsInfo: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
