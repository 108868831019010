/* eslint no-unused-vars: 0 */
import {
  rsi as rsiCalculator,
  bollingerbands as bbCalculator,
  mfi as mfiCalculator,
  atr as atrCalculator,
  roc as rocCalculator,
  obv as obvCalculator,
  cci as cciCalculator,
  trix as trixCalculator,
  adx as adxCalculator,
  truerange as trCalculator,
  awesomeoscillator as aoCalculator,
  macd as macdCalculator,
  vwap as vwapCalculator,
  keltnerchannels as kcCalculator,
  ema as emaCalculator,
  sma as smaCalculator,
  wma as wmaCalculator,
  stochasticrsi as srsiCalculator,
  psar as psarCalculator,
  forceindex as fiCalculator,
  williamsr as wrCalculator,
  chandelierexit as ceCalculator,
  kst as kstCalculator,
  adl as adlCalculator,
  wema as wemaCalculator,
  stochastic as kdCalculator,
  ichimokucloud as icCalculator,
  volumeprofile as vpCalculator
} from "technicalindicators";
import {
  dif,
  div,
  mul,
  sum,
  iff,
  barsAgo,
  isABigThanB,
  change,
  sumFromBack,
  highestBar,
  lowestBar,
  ceil,
  floor,
  round,
  sqrt,
  abs,
  // cum,
  highest,
  lowest,
  max,
  min,
  // nz,
  medianSeries as hl2
} from "./seriesMath";
import {supdemzone as supdemzoneCalculator} from './supdemzone';
const sources = ["high", "low", "open", "close"];
const strokeTypes = ["Solid", "ShortDash", "ShortDash2", "ShortDot", "ShortDashDot", "ShortDashDotDot", "Dot",
  "Dash", "LongDash", "DashDot", "LongDashDot", "LongDashDotDot", "Histogram", "Area", "Circle"];

let isNew = false;
let newIndicator = {};
let options = {};
let fullData = [];
let plotData = [];
let plotSeries = [];
let swingSeries = [];
let plotColorSeries = [];
let fillUpperSeries = [];
let fillLowerSeries = [];
let draw = [];
let settings = {};
let inputCnt = 0;
const study=(long, short, separated = true) => {
  if (isNew){
    newIndicator["name"] = short;
    newIndicator["fullName"] = long;
    newIndicator["separated"] = separated;
  } else {
    settings["name"] = short;
    settings["fullName"] = long;
    settings["separated"] = separated;
  }
};
const inputSource = (name, value="close") => {
  inputCnt++;
  let sourceKey = "close";
  if (options[inputCnt]){
    sourceKey = options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "select",
      value,
      values: sources
    };
    sourceKey = value;
  }
  const series = fullData.map((item) => item[sourceKey]);
  return series;
};
const inputStrokeType = (name, value="close") => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "style-select",
      value,
      values: strokeTypes
    };
    return value;
  }
};
const inputNumber = (name, value=0) => {
  inputCnt++;
  if (options[inputCnt]){
    return parseFloat(options[inputCnt].value);
  } else {
    options[inputCnt] = {
      label: name,
      type: "number",
      value
    };
    return value;
  }
};
const inputWidth = (name, value=0) => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "style-number",
      value
    };
    return value;
  }
};
const inputStyleNumber = (name, value=0) => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "style-number",
      value
    };
    return value;
  }
};
const inputOpacity = (name, value=1) => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "style-number",
      value
    };
    return value;
  }
};
const inputColor = (name, value="rgb(255, 0, 0, 1)") => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "style-color",
      value
    };
    return value;
  }
};
const inputBoolean = (name, value=true) => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "check",
      value
    };
    return value;
  }
};
const inputLabel = (value='Label') => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: 'Label',
      type: "style-input",
      value
    };
    return value;
  }
};
const inputString = (name, value="") => {
  inputCnt++;
  if (options[inputCnt]){
    return options[inputCnt].value;
  } else {
    options[inputCnt] = {
      label: name,
      type: "input",
      value
    };
    return value;
  }
};
const rsi = (series, period) => {
  if (series.length > 0){
    const rsiResult = rsiCalculator({
      period,
      values: series,
    });
    return rsiResult;
  } else {
    return [];
  }
};

const mfi = (data) => {
  if (data){
    const mfiResult = mfiCalculator(data);
    return mfiResult;
  } else {
    return [];
  }
};
const atr = (data) => {
  if (data){
    const atrResult = atrCalculator(data);
    return atrResult;
  } else {
    return [];
  }
};
const roc = (data) => {
  if (data){
    const rocResult = rocCalculator(data);
    return rocResult;
  } else {
    return [];
  }
};
const obv = (data) => {
  if (data){
    const obvResult = obvCalculator(data);
    return obvResult;
  } else {
    return [];
  }
};
const cci = (data) => {
  if (data){
    const cciResult = cciCalculator(data);
    return cciResult;
  } else {
    return [];
  }
};
const trix = (data) => {
  if (data){
    const trixResult = trixCalculator(data);
    return trixResult;
  } else {
    return [];
  }
};
const adx = (data) => {
  if (data){
    const adxResult = adxCalculator(data);
    return adxResult;
  } else {
    return [];
  }
};
const tr = (data) => {
  if (data){
    const trResult = trCalculator(data);
    return trResult;
  } else {
    return [];
  }
};
const ao = (data) => {
  if (data){
    const aoResult = aoCalculator(data);
    return aoResult;
  } else {
    return [];
  }
};
const macd = (data) => {
  if (data){
    const macdResult = macdCalculator(data);
    return macdResult;
  } else {
    return [];
  }
};
const vwap = (data) => {
  if (data){
    const vwapResult = vwapCalculator(data);
    return vwapResult;
  } else {
    return [];
  }
};
const keltnerchannels = (data) => {
  if (data){
    const kcResult = kcCalculator(data);
    return kcResult;
  } else {
    return [];
  }
};
const ema = (data) => {
  if (data){
    const emaResult = emaCalculator(data);
    return emaResult;
  } else {
    return [];
  }
};
const sma = (data) => {
  if (data){
    const smaResult = smaCalculator(data);
    return smaResult;
  } else {
    return [];
  }
};
const wma = (data) => {
  if (data){
    const wmaResult = wmaCalculator(data);
    return wmaResult;
  } else {
    return [];
  }
};
const bbands = (data) => {
  if (data){
    const bbResult = bbCalculator(data);
    return bbResult;
  } else {
    return [];
  }
};
const stochasticrsi = (data) => {
  if (data){
    const srsiResult = srsiCalculator(data);
    return srsiResult;
  } else {
    return [];
  }
};
const psar = (data) => {
  if (data){
    const psarResult = psarCalculator(data);
    return psarResult;
  } else {
    return [];
  }
};
const forceindex = (data) => {
  if (data){
    const fiResult = fiCalculator(data);
    return fiResult;
  } else {
    return [];
  }
};
const williamsr = (data) => {
  if (data){
    const wrResult = wrCalculator(data);
    return wrResult;
  } else {
    return [];
  }
};
const chandelierexit = (data) => {
  if (data){
    const ceResult = ceCalculator(data);
    return ceResult;
  } else {
    return [];
  }
};
const kst = (data) => {
  if (data){
    const kstResult = kstCalculator(data);
    return kstResult;
  } else {
    return [];
  }
};
const adl = (data) => {
  if (data){
    const adlResult = adlCalculator(data);
    return adlResult;
  } else {
    return [];
  }
};
const wema = (data) => {
  if (data){
    const wemaResult = wemaCalculator(data);
    return wemaResult;
  } else {
    return [];
  }
};
const stochastic = (data) => {
  if (data){
    const kdResult = kdCalculator(data);
    return kdResult;
  } else {
    return [];
  }
};
const ichimokucloud = (data) => {
  if (data){
    const icResult = icCalculator(data);
    return icResult;
  } else {
    return [];
  }
};
const ppo = (data) => {
  if (data && data.close.length > 0){
    const { shortPeriod, longPeriod, signalPeriod, close: values } = data;
    const fastMA = emaCalculator({period: shortPeriod, values});
    const slowMA = emaCalculator({period: longPeriod, values});
    const ppo = mul(div(dif(fastMA, slowMA), slowMA), 100);
    const signal = emaCalculator({period: signalPeriod, values: ppo});
    const hist = dif(ppo, signal);
    return {ppo, hist, signal};
  } else {
    return [];
  }
};
const cvi = (data) => {
  if (data && data.high.length > 0){
    const { high, low, period, rocPeriod } = data;
    const difEMA = emaCalculator({period, values: dif(high, low)});
    const result = rocCalculator({values: difEMA, period: rocPeriod});
    return result;
  } else {
    return [];
  }
};
const apo = (data) => {
  if (data && data.values.length > 0){
    const { shortPeriod, longPeriod, values } = data;
    const shortEMA = emaCalculator({period: shortPeriod, values});
    const longEMA = emaCalculator({period: longPeriod, values});
    const result = dif(shortEMA, longEMA);
    return result;
  } else {
    return [];
  }
};
const kvo = (data) => {
  if (data && data.close.length > 0){
    const { shortPeriod, longPeriod, trigPeriod, high, low, close, volume} = data;
    const typical = div(sum(sum(high, low), close), 3);
    const hlc30 = barsAgo(typical, 0);
    const hlc31 = barsAgo(typical, 1);
    const compSerie = isABigThanB(hlc30, hlc31);
    const trend = iff(compSerie, mul(volume, 100), mul(volume, -100));
    const fastEMA = emaCalculator({period: shortPeriod, values: trend});
    const slowEMA = emaCalculator({period: longPeriod, values: trend});
    const kvo = dif(fastEMA, slowEMA);
    const trig = emaCalculator({period: trigPeriod, values: kvo});
    return {kvo, trig};
  } else {
    return [];
  }
};
const dema = (data) => {
  if (data && data.values.length > 0){
    const { period, values} = data;
    const emaSerie = emaCalculator({period, values});
    const demaRes = dif(mul(2, emaSerie), emaCalculator({period, values: emaSerie}));
    return demaRes;
  } else {
    return [];
  }
};
const mom = (data) => {
  if (data && data.values.length > 0){
    const { period, values: src} = data;
    const srcAgo = barsAgo(src, period);
    const mom = dif(src, srcAgo);
    return mom;
  } else {
    return [];
  }
};
const eom = (data) => {
  if (data && data.high.length > 0){
    const { high, low, volume, period, divisor } = data;
    const eomResult = smaCalculator({period,
      values: mul(mul(divisor, change(hl2(high, low))), div(dif(high, low), volume))});
    return eomResult;
  } else {
    return [];
  }
};
const cmo = (data) => {
  if (data && data.values.length > 0){
    const { period, values } = data;
    const momm = change(values);
    const m1 = momm.map((item)=>item >= 0.0 ? item : 0.0);
    const m2 = momm.map((item)=>item >= 0.0 ? 0.0 : -item);
    const sm1 = sumFromBack(m1, period);
    const sm2 = sumFromBack(m2, period);
    const smdif = dif(sm1, sm2);
    const smsum = sum(sm1, sm2);
    const cmoResult = div(mul(100, smdif), smsum);
    return cmoResult;
  } else {
    return [];
  }
};
const aroonosc = (data) => {
  if (data && data.high.length > 0){
    const { period, high, low } = data;
    const upper = mul(100, div(sum(highestBar(high, period+1), period), period));
    const lower = mul(100, div(sum(lowestBar(low, period+1), period), period));
    const aroonoscResult = dif(upper, lower);
    return aroonoscResult;
  } else {
    return [];
  }
};
const tma = (data) => {
  if (data && data.values.length > 0){
    const { period, values } = data;
    const tmaResult = smaCalculator({values: smaCalculator({period: ceil(period / 2), values}),
      period: floor(period / 2) + 1});
    return tmaResult;
  } else {
    return [];
  }
};
const qstick = (data) => {
  if (data && data.open.length > 0){
    const { period, open, close } = data;
    const difference=dif(sumFromBack(close, period), sumFromBack(open, period));
    return difference;
  } else {
    return [];
  }
};
const marketfi = (data) => {
  if (data && data.high.length > 0){
    const { high, low, volume } = data;
    const result = div(dif(high, low), volume);
    return result;
  } else {
    return [];
  }
};
const medprice = (data) => {
  if (data && data.high.length > 0){
    const { high, low } = data;
    const result = hl2(high, low);
    return result;
  } else {
    return [];
  }
};
const ultosc = (data) => {
  if (data && data.high.length > 0){
    const { high, low, close, shortPeriod, longPeriod, mediumPeriod } = data;

    const average = (bp, tr_, length) => div(sumFromBack(bp, length), sumFromBack(tr_, length));
    const high_ = max(high, barsAgo(close, 1));
    const low_ = min(low, barsAgo(close, 1));
    const bp = dif(close, low_);
    const tr_ = dif(high_, low_);
    const avg7 = average(bp, tr_, shortPeriod);
    const avg14 = average(bp, tr_, mediumPeriod);
    const avg28 = average(bp, tr_, longPeriod);
    const out = div(mul(100, sum(sum(mul(4, avg7), mul(2, avg14)), avg28)), 7);
    return out;
  } else {
    return [];
  }
};
const zlema = (data) => {
  if (data && data.values.length > 0){
    const { values: src, period } = data;

    const lag = floor((period - 1) / 2);
    const zlemaRes = emaCalculator({values: sum(src, dif(src, barsAgo(src, lag))), period});
    return zlemaRes;
  } else {
    return [];
  }
};
const bop = (data) => {
  if (data && data.close.length > 0){
    const { open, high, low, close } = data;
    const result = div(dif(close, open), dif(high, low));
    return result;
  } else {
    return [];
  }
};
const dpo = (data) => {
  if (data && data.values.length > 0){
    const { period, values, center } = data;
    const barsback = ceil(period/2);
    const ma = smaCalculator({values, period});
    const dpores = center ? dif(barsAgo(values, barsback), ma) : dif(values, barsAgo(ma, barsback));
    return dpores;
  } else {
    return [];
  }
};
const rocr = (data) => {
  if (data && data.values.length > 0){
    const { period, values } = data;
    const result = div(values, barsAgo(values, period));
    return result;
  } else {
    return [];
  }
};
const mass = (data) => {
  if (data && data.high.length > 0){
    const { high, low, period } = data;
    const range = dif(high, low);
    const mi = sumFromBack(div(emaCalculator({values: range, period: 9}),
        emaCalculator({values: emaCalculator({values: range, period: 9}), period: 9})), period);
    return mi;
  } else {
    return [];
  }
};
const adxr = (data) => {
  if (data && data.close.length > 0){
    const { period } = data;
    const adxResult = adx(data);
    const adxSerie = adxResult.map((item)=>item.adx);
    const result = div(sum(adxSerie, barsAgo(adxSerie, period)), 2);
    return result;
  } else {
    return [];
  }
};
const hma = (data) => {
  if (data && data.values.length > 0){
    const { period, values } = data;
    const hullma = wmaCalculator({values: dif(mul(2, wmaCalculator({values, period: floor(period/2)})),
        wmaCalculator({values, period})), period: round(sqrt(period))});
    return hullma;
  } else {
    return [];
  }
};
const vhf = (data) => {
  if (data && data.values.length > 0){
    const { period, values } = data;
    const HCP = highest(values, period);
    const LCP = lowest(values, period);
    const MCP= sumFromBack(abs(dif(values, barsAgo(values, 1))), period);
    const VHF = div(dif(HCP, LCP), MCP);
    return VHF;
  } else {
    return [];
  }
};
const vosc = (data) => {
  if (data && data.volume.length > 0){
    const { shortPeriod, longPeriod, volume } = data;
    const short = emaCalculator({values: volume, period: shortPeriod});
    const long = emaCalculator({values: volume, period: longPeriod});
    const osc = mul(100, div(dif(short, long), long));
    return osc;
  } else {
    return [];
  }
};
const volumeprofile = (data) => {
  if (data && data.volume.length > 0){
    const { high, low, open, close, volume, noOfBars } = data;
    const result = vpCalculator({high, low, open, close, volume, noOfBars});
    return result;
  } else {
    return [];
  }
};
const supdemzone = (data) => {
  let barType = "Day";
  const barTypes = ["Day", "Hour", "Minute"];
  inputCnt++;
  if (options['barType'+inputCnt]){
    barType = options['barType'+inputCnt].value;
  } else {
    options['barType'+inputCnt] = {
      label: 'Bar Type',
      type: "select",
      value: barType,
      values: barTypes
    };
  }

  let calculateOn = "On bar close";
  const calculateOnValues = ["On bar close", "On each tick", "On price change"];
  inputCnt++;
  if (options['calculateOn'+inputCnt]){
    calculateOn = options['calculateOn'+inputCnt].value;
  } else {
    options['calculateOn'+inputCnt] = {
      label: 'Calculate',
      type: "select",
      value: calculateOn,
      values: calculateOnValues
    };
  }
  if (data && data.fullData.length > 0){
    const { plotData, fullData } = data;
    const result = supdemzoneCalculator({plotData, fullData, });
    return result;
  } else {
    return [];
  }
};
const calcNewTF = (candleAry, i = 0) => {
  const timestamp = candleAry[0].timestamp;
  const open = candleAry[0].open;
  let high = candleAry[0].high;
  let low = candleAry[0].low;
  const close = candleAry[candleAry.length-1].close;
  let vol = 0;
  let highTS = candleAry[0].timestamp;
  let highIdx = i;
  let lowTS = candleAry[0].timestamp;
  let lowIdx = i;
  for ( let k = 0; k < candleAry.length; k++){
    const bar = candleAry[k];
    vol += bar.volume;
    highTS = high > bar.high ? highTS : bar.timestamp;
    highIdx = high > bar.high ? highIdx : i + k;
    high = Math.max(high, bar.high);
    lowTS = low < bar.low ? lowTS : bar.timestamp;
    lowIdx = low < bar.low ? lowIdx : i + k;
    low = Math.min(low, bar.low);
  }
  return {timestamp, open, high, low, close, vol, highTS, lowTS, highIdx, lowIdx};
};
const getDays = () => {
  const endMills = new Date().getTime();
  const startMilli = fullData[0].timestamp;
  const result = [];
  let yesterdayStartMillis = (parseInt(endMills / 86400000) - 1) * 86400000;
  let todayStartMillis = parseInt(endMills / 86400000) * 86400000;
  while (startMilli <= yesterdayStartMillis){
    result.push([yesterdayStartMillis, todayStartMillis]);
    todayStartMillis = yesterdayStartMillis;
    yesterdayStartMillis = yesterdayStartMillis - 86400000;
  }
  if (todayStartMillis > startMilli){
    result.push([startMilli, todayStartMillis]);
  }
  return result;
};
const getWeekDays = () => {
  const endMills = new Date().getTime();
  const dayNumber = new Date(endMills).getDay();
  const backDayCnt = dayNumber == 0 ? 7 + 6 : dayNumber + 6;
  let preWeekStartMillis = (parseInt(endMills / 86400000) - backDayCnt) * 86400000;
  let curWeekStartMillis = preWeekStartMillis + 7 * 86400000;
  const result = [];
  const startMilli = fullData[0].timestamp;
  while (startMilli <= preWeekStartMillis){
    result.push([preWeekStartMillis, curWeekStartMillis]);
    curWeekStartMillis = preWeekStartMillis;
    preWeekStartMillis = preWeekStartMillis - 86400000 * 7;
  }
  if (curWeekStartMillis > startMilli){
    result.push([startMilli, curWeekStartMillis]);
  }
  return result;
};
const getMonthDays = () => {
  const endMills = new Date().getTime();
  let year = new Date(endMills).getFullYear();
  let month = new Date(endMills).getMonth();
  month = month == 0 ? 12 : month;
  let premonthDay = new Date(Date.UTC(year, month-1, 1, 0, 0, 0)).getTime();
  let curmonthDay = new Date(Date.UTC(year, month, 1, 0, 0, 0)).getTime();
  const result = [];
  const startMilli = fullData[0].timestamp;
  while (startMilli <= premonthDay){
    result.push([premonthDay, curmonthDay]);
    curmonthDay = premonthDay;
    month = new Date(premonthDay).getMonth();
    if (month == 0){
      month = 12;
      year = new Date(premonthDay).getFullYear()-1;
    }
    premonthDay = new Date(Date.UTC(year, month-1, 1, 0, 0, 0)).getTime();
  }
  if (curmonthDay > startMilli){
    result.push([startMilli, curmonthDay]);
  }
  return result;
};
const getYearDays = () => {
  const endMills = new Date().getTime();
  let year = new Date(endMills).getFullYear();
  let preYearDay = new Date(Date.UTC(year-1, 0, 1, 0, 0, 0)).getTime();
  let curYearDay = new Date(Date.UTC(year, 0, 1, 0, 0, 0)).getTime();
  const result = [];
  const startMilli = fullData[0].timestamp;
  while (startMilli <= preYearDay){
    result.push([preYearDay, curYearDay]);
    curYearDay = preYearDay;
    year = new Date(preYearDay).getFullYear()-1;
    preYearDay = new Date(Date.UTC(year, 0, 1, 0, 0, 0)).getTime();
  }
  if (curYearDay > startMilli){
    result.push([startMilli, curYearDay]);
  }
  return result;
};
const getMTFTimestamps = (pivotType) => {
  let result = [];
  switch (pivotType){
    case 'Daily':
      result = getDays();
      break;
    case 'Weekly':
      result = getWeekDays();
      break;
    case 'Monthly':
      result = getMonthDays();
      break;
    case 'Yearly':
      result = getYearDays();
      break;
  }
  return result;
};
const getDataForPivots = (pivotType) => {
  let resultCandle = undefined;
  const candleAry = [];
  let serieLen = 0;
  let result = [];
  switch (pivotType){
    case 'Daily':
      result = getDays();
      break;
    case 'Weekly':
      result = getWeekDays();
      break;
    case 'Monthly':
      result = getMonthDays();
      break;
    case 'Yearly':
      result = getYearDays();
      break;
  }
  const start = result[0][0];
  const end = result[0][1];
  for (let i = fullData.length - 1; i >= 0; i--){
    const candle = fullData[i];
    if (candle.timestamp >= start){
      if (candle.timestamp < end){
        candleAry.unshift(candle);
      } else {
        serieLen++;
      }
    } else {
      break;
    }
  }
  if (candleAry.length > 0){
    resultCandle = calcNewTF(candleAry);
  }
  return {serieLen, resultCandle};
};
const floorpivot = () => {
  let pivotType = "Daily";
  const pivotTypes = ["Daily", "Weekly", "Monthly", "Yearly"];
  inputCnt++;
  if (options['floorpivot'+inputCnt]){
    pivotType = options['floorpivot'+inputCnt].value;
  } else {
    options['floorpivot'+inputCnt] = {
      label: 'floorpivot',
      type: "select",
      value: pivotType,
      values: pivotTypes
    };
  }
  if (fullData.length > 0){
    const {resultCandle, serieLen} = getDataForPivots(pivotType);
    if (resultCandle == undefined){
      return {};
    }
    const plserie = [];
    const r1serie = [];
    const r2serie = [];
    const r3serie = [];
    const s1serie = [];
    const s2serie = [];
    const s3serie = [];
    const pivotLevel = (parseFloat(resultCandle.high) + parseFloat(resultCandle.low) +
      parseFloat(resultCandle.close)) / 3;
    const r1 = (2 * parseFloat(pivotLevel)) - parseFloat(resultCandle.low);
    const r2 = parseFloat(pivotLevel) + parseFloat(resultCandle.high) - parseFloat(resultCandle.low);
    const r3 = parseFloat(r1) + parseFloat(resultCandle.high) - parseFloat(resultCandle.low);
    const s1 = (2 * parseFloat(pivotLevel) )- parseFloat(resultCandle.high);
    const s2 = parseFloat(pivotLevel) - parseFloat(resultCandle.high) + parseFloat(resultCandle.low);
    const s3 = parseFloat(s1) - parseFloat(resultCandle.high) + parseFloat(resultCandle.low);
    for (let i = 0; i < serieLen; i++){
      plserie.push(pivotLevel);
      r1serie.push(r1);
      r2serie.push(r2);
      r3serie.push(r3);
      s1serie.push(s1);
      s2serie.push(s2);
      s3serie.push(s3);
    }
    return {plserie, r1serie, r2serie, r3serie, s1serie, s2serie, s3serie};
  }
  return [];
};
const woodies = () => {
  let pivotType = "Daily";
  const pivotTypes = ["Daily", "Weekly", "Monthly", "Yearly"];
  inputCnt++;
  if (options['woodies'+inputCnt]){
    pivotType = options['woodies'+inputCnt].value;
  } else {
    options['woodies'+inputCnt] = {
      label: 'woodies',
      type: "select",
      value: pivotType,
      values: pivotTypes
    };
  }
  if ( fullData.length > 0){
    const {resultCandle, serieLen} = getDataForPivots(pivotType);
    if (resultCandle == undefined){
      return {};
    }
    const plserie = [];
    const r1serie = [];
    const r2serie = [];
    const s1serie = [];
    const s2serie = [];
    const pivotLevel = (parseFloat(resultCandle.high) + parseFloat(resultCandle.low) +
      2 * parseFloat(resultCandle.close)) / 4;
    const r1 = (2 * parseFloat(pivotLevel)) - parseFloat(resultCandle.low);
    const r2 = parseFloat(pivotLevel) + parseFloat(resultCandle.high) - parseFloat(resultCandle.low);
    const s1 = (2 * parseFloat(pivotLevel) )- parseFloat(resultCandle.high);
    const s2 = parseFloat(pivotLevel) - parseFloat(resultCandle.high) + parseFloat(resultCandle.low);
    for (let i = 0; i < serieLen; i++){
      plserie.push(pivotLevel);
      r1serie.push(r1);
      r2serie.push(r2);
      s1serie.push(s1);
      s2serie.push(s2);
    }
    return {plserie, r1serie, r2serie, s1serie, s2serie};
  }
  return [];
};
const camarilla = () => {
  let pivotType = "Daily";
  const pivotTypes = ["Daily", "Weekly", "Monthly", "Yearly"];
  inputCnt++;
  if (options['camarilla'+inputCnt]){
    pivotType = options['camarilla'+inputCnt].value;
  } else {
    options['camarilla'+inputCnt] = {
      label: 'camarilla',
      type: "select",
      value: pivotType,
      values: pivotTypes
    };
  }
  if ( fullData.length > 0){
    const {resultCandle, serieLen} = getDataForPivots(pivotType);
    if (resultCandle == undefined){
      return {};
    }
    const r1serie = [];
    const r2serie = [];
    const r3serie = [];
    const r4serie = [];
    const s1serie = [];
    const s2serie = [];
    const s3serie = [];
    const s4serie = [];

    const diff = parseFloat(resultCandle.high) - parseFloat(resultCandle.low);
    const r4 = ((diff * 1.1) / 2) + parseFloat(resultCandle.close);
    const r3 = (diff *1.1) / 4 + parseFloat(resultCandle.close);
    const r2 = (diff * 1.1) / 6 + parseFloat(resultCandle.close);
    const r1 = (diff * 1.1) / 12 + parseFloat(resultCandle.close);
    const s1 = parseFloat(resultCandle.close) - (diff * 1.1 / 12);
    const s2 = parseFloat(resultCandle.close) - (diff *1.1 /6);
    const s3 = parseFloat(resultCandle.close) - (diff * 1.1 / 4);
    const s4 = parseFloat(resultCandle.close) - (diff *1.1 / 2);

    for (let i = 0; i < serieLen; i++){
      r1serie.push(r1);
      r2serie.push(r2);
      r3serie.push(r3);
      r4serie.push(r4);
      s1serie.push(s1);
      s2serie.push(s2);
      s3serie.push(s3);
      s4serie.push(s4);
    }
    return {r1serie, r2serie, r3serie, r4serie, s1serie, s2serie, s3serie, s4serie};
  }
  return [];
};
const fibopivot = () => {
  let pivotType = "Daily";
  const pivotTypes = ["Daily", "Weekly", "Monthly", "Yearly"];
  inputCnt++;
  if (options['fibopivot'+inputCnt]){
    pivotType = options['fibopivot'+inputCnt].value;
  } else {
    options['fibopivot'+inputCnt] = {
      label: 'fibopivot',
      type: "select",
      value: pivotType,
      values: pivotTypes
    };
  }
  if ( fullData.length > 0){
    const {resultCandle, serieLen} = getDataForPivots(pivotType);
    if (resultCandle == undefined){
      return {};
    }
    const pivotserie = [];
    const r1serie = [];
    const r2serie = [];
    const r3serie = [];
    const r4serie = [];
    const r5serie = [];
    const s1serie = [];
    const s2serie = [];
    const s3serie = [];
    const s4serie = [];
    const s5serie = [];

    const pivot = (parseFloat(resultCandle.high) + parseFloat(resultCandle.low) +
      parseFloat(resultCandle.close)) / 3;
    // Fibonacci Pivots
    const r1 = pivot + (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.236;
    const s1 = pivot - (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.236;
    const r2 = pivot + (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.382;
    const s2 = pivot - (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.382;
    const r3 = pivot + (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.5;
    const s3 = pivot - (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.5;
    const r4 = pivot + (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.618;
    const s4 = pivot - (parseFloat(resultCandle.high) - parseFloat(resultCandle.low)) * 0.618;
    const r5 = pivot + (parseFloat(resultCandle.high) - parseFloat(resultCandle.low));
    const s5 = pivot - (parseFloat(resultCandle.high) - parseFloat(resultCandle.low));
    for (let i = 0; i < serieLen; i++){
      pivotserie.push(pivot);
      r1serie.push(r1);
      r2serie.push(r2);
      r3serie.push(r3);
      r4serie.push(r4);
      r5serie.push(r5);
      s1serie.push(s1);
      s2serie.push(s2);
      s3serie.push(s3);
      s4serie.push(s4);
      s5serie.push(s5);
    }
    return {pivotserie, r1serie, r2serie, r3serie, r4serie, r5serie, s1serie, s2serie, s3serie, s4serie, s5serie};
  }
  return [];
};
const swing = ()=>{
  if (fullData.length > 0){
    let type = "Current";
    const types = ["Current", "Daily", "Weekly", "Monthly", "Yearly"];
    inputCnt++;
    if (options['swing'+inputCnt]){
      type = options['swing'+inputCnt].value;
    } else {
      options['swing'+inputCnt] = {
        label: 'Select Timeframe',
        type: "select",
        value: type,
        values: types
      };
    }
    let mtfData = [];
    if (type == "Current"){
      mtfData = fullData;
    } else {
      mtfData = getMTFData(type);
    }
    const highSeries = [];
    const lowSeries = [];
    for (let i = 0; i < mtfData.length; i++){
      let newHighSerie = [];
      let newLowSerie = [];
      const highL2 = mtfData[i-2] && mtfData[i-2].high;
      const highL1 = mtfData[i-1] && mtfData[i-1].high;
      const high = mtfData[i] && mtfData[i].high;
      const highR1 = mtfData[i+1] && mtfData[i+1].high;
      const highR2 = mtfData[i+2] && mtfData[i+2].high;
      if (highL2 && highL1 && high && highR1 && highR2 &&
        highL2 < high && highL1 < high && high > highR1 && high > highR2){
        if (type == "Current"){
          newHighSerie = Array(fullData.length - i).fill(high);
        } else {
          newHighSerie = Array(fullData.length - mtfData[i].highIdx).fill(high);
        }
        highSeries.push(newHighSerie);
      }
      const lowL2 = mtfData[i-2] && mtfData[i-2].low;
      const lowL1 = mtfData[i-1] && mtfData[i-1].low;
      const low = mtfData[i] && mtfData[i].low;
      const lowR1 = mtfData[i+1] && mtfData[i+1].low;
      const lowR2 = mtfData[i+2] && mtfData[i+2].low;
      if (lowL2 && lowL1 && low && lowR1 && lowR2 &&
        lowL2 > low && lowL1 > low && low < lowR1 && low < lowR2){
        if (type == "Current"){
          newLowSerie = Array(fullData.length - i).fill(low);
        } else {
          newLowSerie = Array(fullData.length - mtfData[i].lowIdx).fill(low);
        }
        lowSeries.push(newLowSerie);
      }
    }
    return {highSeries, lowSeries};
  }
  return [];
};
const getMTFData = (type) => {
  const mtfTimestamps = getMTFTimestamps(type);
  const stops = [];
  for (const i in mtfTimestamps){
    const ele = mtfTimestamps[i];
    stops.push(ele[1]);
  }
  const startMilli = fullData[0].timestamp;
  if (startMilli < stops[stops.length-1]){
    stops.push(startMilli);
  }
  const mtfData = [];
  let section = [];
  let stopIdx = 0;
  let stop = stops[stopIdx];
  for (let i = fullData.length-1; i >= 0; i--){
    const bar = fullData[i];
    if (stop <= bar.timestamp){
      section.unshift(bar);
    }
    if (stop > bar.timestamp || i == 0){
      if (section.length > 0){
        mtfData.unshift(calcNewTF(section, i+1));
      }
      section = [bar];
      stop = stops[++stopIdx];
    }
  }
  return mtfData;
};
// const wad = (data) => {
//   if (data && data.close.length > 0){
//     let { high, low, close } = data;
//     let trh = max(barsAgo(close, 1), high);
//     let trl = min(barsAgo(close, 1), low);
//     let compSerie = isABigThanB(close, barsAgo(close, 1));

//     today = close[0] > close[1] ? (close[0]-trl) : close[0] < close[1] ? (close[0]-trh) : 0
//     ad = cum(today)
//     return hullma;
//   } else {
//     return [];
//   }
// };
// const fisher = (data) => {
//   if (data && data.high.length > 0){
//     let { high } = data;
//     // let hl2serie = hl2(high, low);
//     // let high_ = highest(hl2serie, period);
//     // let low_ = lowest(hl2serie, period);
//     // const round_ = (val) => val > .99 ? .999 : val < -.99 ? -.999 : val;

//     let value = [];
//     for ( let i = 0; i < high.length; i++){
//       value.push(1+nz(barsAgo(value, 1)));
//     }
//     // let value = round_(sum(mul(.66, dif(div(dif(hl2serie, low_), max(dif(high_, low_), .001)), .5)),
//       // mul(.67, nz(barsAgo(value,1)))));
//     return value;
//   } else {
//     return [];
//   }
// };
// const pvi = (data) => {
//   if (data && data.close.length > 0){
//     let { close, volume, initValue, emaLength } = data;
//     let pvi = [];
//     pvi = na(barsAgo(pvi, 1)) ? initValue : (change(volume) > 0 ? pvi[1] * close / close[1] : pvi[1])
//     let result = div(dif(high, low), volume);
//     return result;
//   } else {
//     return [];
//   }
// };
export const hline = (name, value, style) => {
  draw.push({
    type: 'hline',
    name,
    value,
    style
  });
};
export const hfill = (name, overSold, overBought, style) => {
  draw.push({
    type: 'hfill',
    name,
    top: overBought,
    bottom: overSold,
    style
  });
};
export const plotVolumeProfile = (data, style) => {
  if (data){
    draw.push({
      type: 'volumeprofile',
      data,
      style
    });
  }
};
export const plotSupDemZone = (data, style) => {
  if (data) {
    if (style && (style.hideBrokenZones || style.hideActiveZones)) {
      data.zones = data.zones.filter(zone =>
        (zone.za && !style.hideActiveZones) || (!zone.za && !style.hideBrokenZones));
    }
    draw.push({
      type: 'supdemzone',
      data,
      style
    });
  }
};
export const plotSwingHighLow = (data, style) => {
  if (data){
    draw.push({
      type: 'swinghighlow',
      data,
      style
    });
    const {highSeries, lowSeries} = data;
    if (style.showSH){
      for ( const i in highSeries ) {
        const series = highSeries[i];
        swingSeries.push(series);
      }
    }
    if (style.showSL){
      for ( const i in lowSeries ) {
        const series = lowSeries[i];
        swingSeries.push(series);
      }
    }
  }
};
export const plotRangeHighLow = (data, style) => {
  if (data){
    draw.push({
      type: 'swinghighlow',
      data,
      style
    });
    const {highSeries, lowSeries} = data;
    if (style.showSH){
      for ( const i in highSeries ) {
        const series = highSeries[i];
        swingSeries.push(series);
      }
    }
    if (style.showSL){
      for ( const i in lowSeries ) {
        const series = lowSeries[i];
        swingSeries.push(series);
      }
    }
  }
};
export const plot = (name, series, style) => {
  if (series){
    draw.push({
      type: 'plot',
      name,
      style
    });
    if (Array.isArray(series) && series.length > 0){
      if (style && style.offset){
        const offset = style.offset;
        if (offset < 0){
          for (let i = 0; i < Math.abs(offset); i++){
            series.push(undefined);
          }
        } else {
          series = series.slice(0, series.length - offset - 1);
        }
      }
    }
    plotSeries.push(series);
    if (style && Array.isArray(style.strokeColor)){
      plotColorSeries.push(style.strokeColor);
    }
  }
};
export const fill = (name, upper, lower, style) => {
  draw.push({
    type: 'fill',
    name,
    style
  });
  if (upper && upper.length > 0 && lower && lower.length > 0){
    if (style && style.offset){
      const offset = style.offset;
      if (offset < 0){
        for (let i = 0; i < Math.abs(offset); i++){
          upper.push(undefined);
          lower.push(undefined);
        }
      } else {
        upper = upper.slice(0, upper.length - offset - 1);
        lower = lower.slice(0, lower.length - offset - 1);
      }
    }
    fillUpperSeries.push(upper);
    fillLowerSeries.push(lower);
  }
};
export const createNewIndicator = (originalIndicator, indicatorIdx) => {
  isNew = true;
  newIndicator = {};
  options = {};
  options['code'] = originalIndicator.options.code;
  draw = [];
  inputCnt = 0;
  eval(originalIndicator.options.code);

  newIndicator["id"] = originalIndicator.id;
  newIndicator["idx"] = indicatorIdx;
  newIndicator["options"] = options;
  newIndicator['editable'] = true;
  newIndicator["type"] = originalIndicator.type;
  newIndicator["active"] = originalIndicator.active;
  newIndicator["separated"] = originalIndicator.separated;
  newIndicator["ignoreY"] = originalIndicator.ignoreY;

  return newIndicator;
};
export const runCode = (indicator, data, plotdata) => {
  isNew = false;
  options = indicator.options;
  fullData = data;
  plotData = plotdata;
  draw = [];
  plotSeries = [];
  swingSeries = [];
  plotColorSeries = [];
  fillUpperSeries = [];
  fillLowerSeries = [];
  inputCnt = 0;
  eval(indicator.options.code);
  return {plotSeries, swingSeries, plotColorSeries, fillUpperSeries, fillLowerSeries, draw};
};
export const getUpdatedSettings = (newOptions, indicatorIdx) => {
  isNew = false;
  options = newOptions;
  fullData = [];
  draw = [];
  settings = {};
  inputCnt = 0;
  eval(options.code);
  return {settings};
};
