import {uid} from "uid";
import {getImage, saveImage} from "../api/ImageApi";
import {showAlert} from "../actions/CommonActions";
import domtoimage from "../utils/domToImage";
import {GET_IMAGE_SUCCESS, GET_IMAGE, ALERT_TYPES} from "../const";
import Logo from "../assets/aurlix.svg";

const saveImageAction = (element, height) => async (dispatch) => {
  dispatch({
    type: GET_IMAGE,
  });

  const image = await domtoimage.toJpeg(element, {
    quality: .9,
    useCORS: false,
    cacheBust: true,
    image: {
      top: height,
      left: 20,
      src: Logo,
      width: 100,
      height: 25,
    },
  });

  try {
    const name = `chart-${uid(10)}.jpeg`;
    const result = await saveImage(image, name);

    dispatch({
      type: GET_IMAGE_SUCCESS,
      path: result.path,
    });
  } catch (error) {
    dispatch({
      type: GET_IMAGE_SUCCESS,
      path: undefined,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getImageAction = (id) => async (dispatch) => {
  dispatch({
    type: GET_IMAGE,
  });

  try {
    const result = await getImage(id);

    dispatch({
      type: GET_IMAGE_SUCCESS,
      path: result.path,
    });
  } catch (error) {
    dispatch({
      type: GET_IMAGE_SUCCESS,
      path: undefined,
    });
  }
};

export {
  saveImageAction,
  getImageAction,
};
