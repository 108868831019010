import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import React, {useState} from "react";
import FontAwesome from "../FontAwesome";
import {SketchPicker} from "react-color";
import styles from './ColorPicker.module.scss';

const ColorPicker = ({color}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (<Dropdown
    className={styles.dropdown}
    isOpen={isDropdownOpen}
    toggle={() => setIsDropdownOpen(true)}
    size="sm"
  >
    <DropdownToggle>
      <div className={styles.colorbutton}>
        <div className={styles.example} style={{backgroundColor: color}}/>
        <FontAwesome
          name="chevron-down"
          prefix="fas"
          style={{marginLeft: 'auto', paddingTop: '5px', fontSize: '80%'}}/>
      </div>
    </DropdownToggle>
    <DropdownMenu>
      {
        <div className="popover">
          <div
            role="button"
            tabIndex="0"
            className="cover"
            onClick={() => setIsDropdownOpen(false)}
          />
          <SketchPicker
            color={color}
            onChange={(color) => this.handleColorPickChange(color, key, index)}
          />
        </div>
      }
    </DropdownMenu>
  </Dropdown>);
};
export default ColorPicker;
