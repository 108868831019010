import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import HistoryOrderItem from "./HistoryOrderItem";
import {Helmet} from "react-helmet";
import styles from "./HistoryPage.scss";

class HistoryPage extends Component {
  constructor(props) {
    super(props);

    this.interval = null;
  }

  componentDidMount() {
    this.props.fetchOrderHistory();
    this.interval = setInterval(this.props.fetchOrderHistory, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {orderHistory, ordersHistoryLoading} = this.props;
    return (
      <PerfectScrollbar options={{suppressScrollX: true}}>
        <Helmet>
          <title>History</title>
        </Helmet>
        <div className={classNames(styles.OrdersPage, "ordersPage")}>
          {orderHistory.map((account) => (
            <HistoryOrderItem
              account={account}
              loading={ordersHistoryLoading}
              drawToolBar={this.drawToolBar}
              key={`${account.name}-${account.exchange}`}
            />
          ))}
        </div>
      </PerfectScrollbar>
    );
  }
}

HistoryPage.propTypes = {
  fetchOrderHistory: PropTypes.func,
  orderHistory: PropTypes.array,
  ordersHistoryLoading: PropTypes.bool,
};

HistoryPage.defaultProps = {
  fetchOrderHistory: () => false,
  orderHistory: [],
  ordersHistoryLoading: false,
};

export default HistoryPage;
