import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";

import FloatValue from "../../../../components/FloatValue";

import styles from "./CustomTooltip.scss";

const CustomTooltip = (props) => {
  const {active, payload} = props;
  const data = payload && payload[0] && payload[0].payload;
  const targetMarker = data && data.name.split("-")[0];
  const sourceMarket = data && data.name.split("-")[1];

  return active && (
    <div className={styles.CustomTooltip}>
      <div className={styles.Item}>
        Price: <FloatValue value={data && data.price}/>
      </div>
      <div className={styles.Item}>
        Sum({targetMarker}): {data && numeral(data.sellTargetSum || data.buyTargetSum).format("0.[00]")}
      </div>
      <div className={styles.Item}>
        Sum({sourceMarket}): {data && numeral(data.sellSourceSum || data.buySourceSum).format("0.[00]")}
      </div>
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  type: PropTypes.string,
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
  type: undefined,
};

export default CustomTooltip;
