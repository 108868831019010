import {api} from "../utils/apiUtils";

const getChannelsList = () => api.get("chat/channels");
const getPublicChannels = () => api.get("chat/public-channels");
const getRootChannels = () => api.get("chat/root-channels");
const getChannel = (id) => api.get(`chat/channel/${id}`);
const leaveChannel = (id) => api.get(`chat/channel/${id}/leave`);
const joinChannel = (id) => api.get(`chat/channel/${id}/join`);
const createChannel = (params) => api.post("chat/channel", params);
const deleteChannel = (id) => api.delete(`chat/channel/${id}`);
const updateChannel = (id, params) => api.put(`chat/channel/${id}`, params);

const getMessages = (id, params) => api.get(`chat/channel/${id}/messages`, params);
const createMessage = (params) => api.post("chat/message", params);
const deleteMessage = (id) => api.delete(`chat/message/${id}`);
const updateMessage = (id, params) => api.put(`chat/message/${id}`, params);

const uploadFile = (params, options) => api.post("chat/file", params, options);
const fetchUrl = (params) => api.post("chat/url", params);

const getUsers = () => api.get("chat/users");

export {
  getChannelsList,
  getPublicChannels,
  getRootChannels,
  getChannel,
  leaveChannel,
  joinChannel,
  createChannel,
  deleteChannel,
  updateChannel,
  getMessages,
  getUsers,
  createMessage,
  deleteMessage,
  updateMessage,
  uploadFile,
  fetchUrl,
};
