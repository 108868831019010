import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ChatView from "react-chatview";
import classNames from "classnames";
import { ButtonGroup, Button } from "reactstrap";
import numeral from "numeral";
import Autocomplete from "../../../../components/AutoComplete/AutoComplete";
import autoCompleteData from "../../../../components/AutoComplete/coindata.js";
import ReactTooltip from 'react-tooltip';
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import FontAwesome from "../../../../components/FontAwesome";

import { SOURCES, OPTIONS } from "./FeedConstants";

import styles from "./FeedWidget.scss";

class FeedWidget extends Component {
  constructor() {
    super();

    this.scrollElement = null;
    this.interval = null;
    this.blurTimeout = null;

    this.state = {
      searchFocus: false,
      source: SOURCES[0].value,
      option: OPTIONS[0].value,
      query: "",
    };
  }

  componentDidMount() {
    this.props.getFeedAction(null, true);

    this.interval = setInterval(() => {
      const { feed, checkNewFeeds } = this.props;
      checkNewFeeds(feed.length > 0 && feed[0].iso_date);
    }, 1000);
  }

  componentDidUpdate() {
    const topScroll = this.scrollElement ? this.scrollElement.scrollable.scrollTop : 0;
    if (topScroll === 0 && this.props.lastCount > 0) {
      this.handleGetFeeds(true);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleGetFeeds = (isNew) => {
    const { feed, getFeedAction } = this.props;
    if (feed.length > 0) {
      getFeedAction(feed[isNew ? 0 : feed.length - 1].iso_date, isNew);
    }
  };

  handleScrollTop = () => {
    this.scrollElement.scrollable.scrollTop = 0;
    this.handleGetFeeds(true);
  };

  updateFilter = (type, value) => {
    clearTimeout(this.blurTimeout);
    this.setState({
      [type]: value,
    });
  };

  handleFilterSave = () => {
    this.props.addFilter({
      source: this.state.source,
      option: this.state.option,
      query: this.state.query,
    });
    this.setState({
      searchFocus: false,
      source: SOURCES[0].value,
      option: OPTIONS[0].value,
      query: "",
    });
  };

  getSentimentColor = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return "#ffc700";
    } else if (score > 0.5) {
      return "#5cb85c";
    } else if (score >= -3 && score < -0.5) {
      return "#ff7a00";
    } else if (score < -3) {
      return "#c20000";
    }
  };

  getSentimentWord = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return "Neutral";
    } else if (score <= 3 && score > 0.5) {
      return "Bullish";
    } else if (score > 3) {
      return "Extremely Bullish";
    } else if (score >= -3 && score < -0.5) {
      return "Bearish";
    } else if (score < -3) {
      return "Extremely Bearish";
    }
  };
  getSentimentTriangle = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return <FontAwesome className={styles.sentimentNeutral} name="square" prefix="fas"/>;
    } else if (score <= 3 && score > 0.5) {
      return <FontAwesome className={styles.sentimentBullish} name="arrow-alt-up" prefix="fas"/>;
    } else if (score > 3) {
      return <FontAwesome className={styles.sentimentBullish} name="arrow-alt-up" prefix="fas"/>;
    } else if (score >= -3 && score < -0.5) {
      return <FontAwesome className={styles.sentimentBearish} name="arrow-alt-down" prefix="fas"/>;
    } else if (score < -3) {
      return <FontAwesome className={styles.sentimentBearish} name="arrow-alt-down" prefix="fas"/>;
    }
  };
  getSentimentSimple = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return "Neutral";
    } else if (score <= 3 && score > 0.5) {
      return "Positive";
    } else if (score > 3) {
      return "Positive";
    } else if (score >= -3 && score < -0.5) {
      return "Negative";
    } else if (score < -3) {
      return "Negative";
    }
  };

  render() {
    const { feed, toggleModal } = this.props;
    const days = [];
    for (const item of feed) {
      const day = moment(item.date).format('MMMM Do YYYY');
      let dayIndex = days.findIndex(({day: currentDate}) => currentDate === day);
      if (dayIndex === -1) {
        days.push({day, children: []});
        dayIndex = days.length - 1;
      }
      days[dayIndex].children.push(item);
    }

    return (
      <div className="widgetWrapper">
        <WidgetToolbar
          {...this.props}
        />
        <div className={styles.SearchWrapper}>
          {this.state.searchFocus && (
            <div className={styles.SearchSettings}>
              <ButtonGroup className={styles.BtnGroup} aria-label="Option">
                {OPTIONS.map((option) => (
                  <Button
                    className={classNames(styles.Button, {
                      [styles.Active]: this.state.option === option.value,
                    })}
                    key={option.value}
                    onClick={() => this.updateFilter("option", option.value)}
                  >
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
              <ButtonGroup className={styles.BtnGroup} aria-label="Source">
                {SOURCES.map((source) => (
                  <Button
                    className={classNames(styles.Button, {
                      [styles.Active]: this.state.source === source.value,
                    })}
                    key={source.value}
                    onClick={() => this.updateFilter("source", source.value)}
                  >
                    {source.label}
                  </Button>
                ))}
              </ButtonGroup>
              <Button
                className={classNames(styles.Button, styles.Add)}
                onClick={this.handleFilterSave}
              >
                Add
              </Button>
            </div>
          )}
        </div>
        <div className="marketsWidget disableDrag">

          <div className={styles.searchIcon}>
            <FontAwesome
              prefix="far"
              name="search"
            />
            <div className={styles.Search}>
              <Autocomplete
                data={autoCompleteData}
                placeholder="Search..."
              />
            </div>
          </div>
          <div className={styles.filters}>
            <a data-tip data-for='filterButton'>
              <button
                className={styles.menu}
                onClick={() => toggleModal("feedFilter")}
              >
                <FontAwesome
                  prefix="fas"
                  name="filter"
                />&nbsp;Filters&nbsp;<span className={styles.tickerplus}>+1</span>
              </button>
            </a>
            <ReactTooltip
              id='filterButton'
              offset="{'top': 30, 'left': 78}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Apply filters</span>
            </ReactTooltip>
            <a data-tip data-for='tickerButton'>
              <button
                className={styles.menu}
                onClick={() => toggleModal("feedFilter")}
              >
                <FontAwesome
                  prefix="fab"
                  name="btc"
                />&nbsp;Tickers&nbsp;<span className={styles.tickerplus}>+2</span>
              </button>
            </a>
            <ReactTooltip
              id='tickerButton'
              offset="{'top': 30, 'left': 90}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Apply tickers</span>
            </ReactTooltip>
            <a data-tip data-for='exchangeButton'>
              <button
                className={styles.menu}
                onClick={() => toggleModal("feedFilter")}
              >
                <FontAwesome
                  prefix="far"
                  name="globe"
                />&nbsp;Exchanges&nbsp;<span className={styles.tickerplus}>+2</span>
              </button>
            </a>
            <ReactTooltip
              id='exchangeButton'
              offset="{'top': -7, 'left': 0}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Apply exchanges</span>
            </ReactTooltip>
            <a data-tip data-for='categoriesButton'>
              <button
                className={styles.menu}
                onClick={() => toggleModal("feedFilter")}
              >
                <FontAwesome
                  prefix="fas"
                  name="list"
                />&nbsp;Sectors&nbsp;<span className={styles.tickerplus}>+2</span>
              </button>
            </a>
            <ReactTooltip
              id='categoriesButton'
              offset="{'top': -7, 'left': 0}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Apply categories</span>
            </ReactTooltip>
            <a data-tip data-for='sourcesButton'>
              <button
                className={styles.menu}
                onClick={() => toggleModal("feedFilter")}
              >
                <FontAwesome
                  prefix="fas"
                  name="code"
                />&nbsp;Sources&nbsp;<span className={styles.tickerplus}>+35</span>
              </button>
            </a>
            <ReactTooltip
              id='sourcesButton'
              offset="{'top': -7, 'left': 0}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Apply sources</span>
            </ReactTooltip>
          </div>
          <ChatView
            className={styles.FeedContainer}
            scrollLoadThreshold={20}
            onInfiniteLoad={async () => await this.handleGetFeeds(false)}
            ref={(el) => this.scrollElement = el}
          >
            {days.map(({day, children}) =>
              (<Fragment key={day}>
                <div className={styles.dateSeperator}>{day}</div>
                {children.map((item) => (
                  <div
                    className={styles.FeedItem}
                    key={item.id}
                    onClick={() => window.open(item.link, "_blank")}
                    role="button"
                    tabIndex="0"
                  >
                    <tr className={styles.Title}>
                      <td
                        className={styles.Icon}
                      >
                        <FontAwesome wrapperClassName={styles.Icon} name="rss" prefix="fas" />
                      </td>
                      <td className={styles.Time}>
                        {moment(item.iso_date).format("HH:mm:ss")}
                      </td>
                      <td
                        className={styles.Title}
                      >
                        {item.title}<span className={styles.Source}> - {item.source}</span>
                        <span className={styles.ticker}>
                          <FontAwesome className={styles.tickerdollarsign} name="dollar-sign" prefix="fas" />
                          BTC
                        </span>
                        <span className={styles.ticker}>
                          <FontAwesome className={styles.tickerdollarsign} name="dollar-sign" prefix="fas" />
                          DOGE
                        </span> |
                        <span
                          className={classNames(styles.Score, styles.tooltip, {
                            [styles.Neutral]: item.sentiment_score >= -0.5 && item.sentiment_score <= 0.5,
                            [styles.Bullish]: item.sentiment_score <= 3 && item.sentiment_score > 0.5,
                            [styles.ExtBullish]: item.sentiment_score > 3,
                            [styles.Bearish]: item.sentiment_score >= -3 && item.sentiment_score < -0.5,
                            [styles.ExtBearish]: item.sentiment_score < -3,
                          })}
                        >
                          {this.getSentimentTriangle(item.sentiment_score)}{this.getSentimentSimple(item.sentiment_score)}
                          <span className={styles.tooltiptext}>
                            <b>Sentiment:</b>
                            <br/>
                            {numeral((item.sentiment_score/5) || 0).format("0%")} {this.getSentimentSimple(item.sentiment_score)}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </div>
                )
                )}
              </Fragment>)

            )}
          </ChatView>
        </div>
      </div>
    );
  }
}

FeedWidget.propTypes = {
  addFilter: PropTypes.func,
  checkNewFeeds: PropTypes.func,
  feed: PropTypes.array,
  getFeedAction: PropTypes.func,
  lastCount: PropTypes.number,
  toggleModal: PropTypes.func,
};

FeedWidget.defaultProps = {
  addFilter: () => false,
  checkNewFeeds: PropTypes.func,
  feed: [],
  getFeedAction: () => false,
  lastCount: 0,
  toggleModal: () => false,
};

export default FeedWidget;
