import React, {Component} from "react";
import PropTypes from "prop-types";
import {ButtonDropdown, DropdownMenu, DropdownItem, Badge, UncontrolledDropdown, DropdownToggle} from "reactstrap";
import ReactTooltip from 'react-tooltip';
import FontAwesome from "../../../components/FontAwesome";
import UserStatus from "./UserStatus";

import styles from "./Toolbar.scss";

class Toolbar extends Component {
  constructor() {
    super();

    this.state = {
      chatDropdownOpen: false,
      chatStatusOpen: false,
    };
  }

  handleToggleDropdown = (event, status) => {
    event && event.stopPropagation();
    this.setState({
      chatDropdownOpen: !this.state.chatDropdownOpen,
    });
    status && status.stopPropagation();
    this.setState({
      chatStatusOpen: !this.state.chatStatusOpen,
    });
  };

  drawChatSettings = () => {
    const {channelObject, leaveChannelAction, toggleModal, deleteChannelAction, user} = this.props;

    return (
      <ButtonDropdown
        className={styles.Right}
        isOpen={this.state.chatDropdownOpen}
        toggle={(event) => this.handleToggleDropdown(event)}
      >
        <DropdownToggle
          className={styles.Right}
          type="button"
          onClick={(event) => this.handleToggleDropdown(event)}
        >
          <img className={styles.Icon} alt="minimize" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right">
          {(channelObject.type === "direct" && channelObject.user === user.id) && (
            <div>
              <DropdownItem className={styles.dropdownHeader} header>
                Direct Messages
              </DropdownItem>
              <DropdownItem
                className={styles.dropItem}
                onClick={(event) => {
                  event.stopPropagation();
                  deleteChannelAction(channelObject._id);
                }}>
                <FontAwesome
                  className={styles.dropItemsvg}
                  name="trash-alt"
                  prefix="fas"
                /> Remove {channelObject.title}
              </DropdownItem>
              <DropdownItem
                className={styles.dropItem}
                onClick={(event) => {
                  event.stopPropagation();
                  deleteChannelAction(channelObject._id);
                }}>
                <FontAwesome
                  className={styles.dropItemsvg}
                  name="ban"
                  prefix="fas"
                /> Block {channelObject.title}
              </DropdownItem>
              <DropdownItem
                className={styles.dropItem}
                onClick={(event) => {
                  event.stopPropagation();
                  toggleModal("chat", {type: "direct"});
                }}
              >
                <FontAwesome
                  className={styles.dropItemsvg}
                  name="cog"
                  prefix="fas"
                /> Edit Settings
              </DropdownItem>
            </div>
          )}
          {(channelObject.type === "public" && channelObject.user !== user.id) && (
            <div>
              <DropdownItem className={styles.dropdownHeader} header>
                Channel Menu
              </DropdownItem>
              <DropdownItem
                className={styles.dropItem}
                onClick={(event) => {
                  event.stopPropagation();
                  leaveChannelAction(channelObject._id);
                }}
              >
                <FontAwesome
                  className={styles.dropItemsvg}
                  name="sign-out-alt"
                  prefix="fas"
                /> Leave {channelObject.title}
              </DropdownItem>
            </div>
          )}
          {(channelObject.type === "team" && channelObject.user === user.id) && (
            <div>
              <DropdownItem className={styles.dropdownHeader} header>
                Teams Menu
              </DropdownItem>
              <DropdownItem
                className={styles.dropItem}
                onClick={(event) => {
                  event.stopPropagation();
                  deleteChannelAction(channelObject._id);
                }}>
                <FontAwesome
                  className={styles.dropItemsvg}
                  name="trash-alt"
                  prefix="fas"
                /> Delete {channelObject.title}
              </DropdownItem>
              <DropdownItem
                className={styles.dropItem}
                onClick={(event) => {
                  event.stopPropagation();
                  toggleModal("chat", channelObject);
                }}
              >
                <FontAwesome
                  className={styles.dropItemsvg}
                  name="cog"
                  prefix="fas"
                /> Manage {channelObject.title}
              </DropdownItem>

            </div>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  getUnreadMessages = () => {
    const {hasNewItems} = this.props;
    let messages = 0;
    hasNewItems.forEach((item) => {
      messages = messages + item.count;
    });

    return messages;
  };

  render() {
    const {expanded, onHandleOpenChat, onHandleExpandChat, opened} = this.props;
    const unreadMessages = this.getUnreadMessages();

    return (
      <div
        className={styles.Wrapper}
        role="button"
        tabIndex="0"
      > <a data-tip data-for='userStatus'>
        <UncontrolledDropdown
            className={styles.statusButton}
          >
            <DropdownToggle
            className={styles.left}
            type="button"
            //onClick={(status) => this.handleToggleDropdown(status)}
            >
            <FontAwesome
                className={styles.commentAltLines}
                name="comment-alt-lines"
                prefix="far"
              />
          </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right">

            <DropdownItem className={styles.dropdownHeader} header>
                user status
              </DropdownItem>
            <DropdownItem
                className={styles.dropItem}
              >
                <span className={styles.onlineDotGreen} /> Available
              </DropdownItem>
            <DropdownItem
                className={styles.dropItem}
              >
                <span className={styles.onlineDotOrange} /> Idle
              </DropdownItem>
            <DropdownItem
                className={styles.dropItem}
              >
                <span className={styles.onlineDotRed} /> Busy
              </DropdownItem>
            <DropdownItem
                className={styles.dropItem}
              >
                <span className={styles.onlineDotOffline} /> Offline
              </DropdownItem>

          </DropdownMenu>
          </UncontrolledDropdown>
        </a>
        <ReactTooltip
          id='userStatus'
          offset="{'top': -7}"
          arrowColor='#30333a'
          className={styles.buttonTooltip}
          effect='solid'
        >
          <span>Change online status</span>
        </ReactTooltip>
        <button
          className={styles.title}
          onClick={onHandleOpenChat}
        >
          <b>message</b>chat <UserStatus className={styles.StatusTitle} isOnline/>
        </button>
        <button className={styles.Right} onClick={onHandleExpandChat}>
          {expanded ?
            <img className={styles.Icon} alt="minimize" src="/img/icons/minimize.svg" /> :
            <img className={styles.Icon} alt="maxamize" src="/img/icons/maxamize.svg" />
          }
        </button>
        {(opened || expanded) && this.drawChatSettings()}
        {!expanded && unreadMessages > 0 && (
          <Badge className={styles.Badge} color="danger">
            {unreadMessages}
          </Badge>
        )}
      </div>
    );
  }
}

Toolbar.propTypes = {
  channelObject: PropTypes.object,
  deleteChannelAction: PropTypes.func,
  expanded: PropTypes.bool,
  hasNewItems: PropTypes.array,
  leaveChannelAction: PropTypes.func,
  onHandleExpandChat: PropTypes.func,
  onHandleOpenChat: PropTypes.func,
  opened: PropTypes.bool,
  toggleModal: PropTypes.func,
  user: PropTypes.object,
};

Toolbar.defaultProps = {
  channelObject: {},
  deleteChannelAction: () => false,
  expanded: false,
  hasNewItems: [],
  leaveChannelAction: () => false,
  onHandleExpandChat: () => false,
  onHandleOpenChat: () => false,
  opened: false,
  toggleModal: () => false,
  user: {},
};

export default Toolbar;
