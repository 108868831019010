import {emojiIndex} from "emoji-mart";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const GET_PUBLIC_CHANNELS_SUCCESS = "GET_PUBLIC_CHANNELS_SUCCESS";
export const GET_ROOT_CHANNELS_SUCCESS = "GET_ROOT_CHANNELS_SUCCESS";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const SET_CHANNEL = "SET_CHANNEL";

export const MESSAGE_CREATED = "MESSAGE_CREATED";
export const MESSAGE_DELETED = "MESSAGE_DELETED";
export const MESSAGE_UPDATED = "MESSAGE_UPDATED";
export const MESSAGE_SENT = "sentMessageRes";
export const MESSAGE_DELETED_SOCKET = "deletedMessageRes";
export const MESSAGE_UPDATED_SOCKET = "updatedMessageRes";
export const SET_MESSAGE_FOR_EDITING = "SET_MESSAGE_FOR_EDITING";
export const URL_FETCH = "URL_FETCH";
export const URL_FETCH_SUCCESS = "URL_FETCH_SUCCESS";

export const USER_LOGGED_IN = "userLoggedIn";
export const USER_LOGGED_OUT = "userLoggedOut";
export const USER_STATUS_UPDATED = "USER_STATUS_UPDATED";
export const USER_TYPING = "userTypingRes";

export const EMOJI_SET = "google";

export const EMOJI_REGEXP = /({{EMOJI:(.*?)}})/gim;
export const LINK_REGEXP = /({{LINK:(.*?)}})/gim;
export const FILE_REGEXP = /({{FILE:(.*?)}})/gim;
export const EMAIL_REGEXP = /({{EMAIL:(.*?)}})/gim;

export const parseMessage = (value) => {
  const withEmogies = value.replace(EMOJI_REGEXP, (a, b, c) => {
    const emojies = emojiIndex.search(c).map((o) => o.native);
    return emojies[0];
  });
  const withLinks = withEmogies.replace(LINK_REGEXP, (a, b, c) => c);
  const withEmails = withLinks.replace(EMAIL_REGEXP, (a, b, c) => c);
  return withEmails;
};
