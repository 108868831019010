import React, {Component} from "react";
import PropTypes from "prop-types";
import FontAwesome from "../../../components/FontAwesome";
import classNames from "classnames";
import {sortBy} from "lodash";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import FlagIconFactory from "react-flag-icon-css";

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

import styles from "./MarketsModal.scss";

const marketSources = [
  {name: "ALL", value: "all"},
  {name: "CEX", value: "cryptocurrency"},
  {name: "DEFI", value: "defi"},
];

class ExchangesModal extends Component {
  constructor() {
    super();

    this.input = null;
    this.state = {
      filter: "all",
      search: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.getExchangesListAction();
    }
  }

  updateExchange = (item) => {
    const {toggleModal, updateWidget, widget} = this.props;
    updateWidget({
      ...widget,
      exchange: item.id,
    });
    toggleModal("exchanges");
  };

  handleSearchInput = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  filterExchanges = (exchange) => {
    const {search} = this.state;
    const statement = exchange.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    if (!this.props.widget) {
      return statement;
    }
    switch (this.props.widget.type) {
      case "markets":
      case "treemap":
        return statement && exchange.hasFetchTickers;
      default:
        return statement;
    }
  };

  replaceCodes = (code) => {
    return code.replace("uk", "gb");
  };

  render() {
    const {isOpen, toggleModal, exchanges} = this.props;
    return (
      <Modal
        autoFocus={false}
        onEnter={() => setTimeout(() => {
          this.input && this.input.focus();
        }, 500)}
        isOpen={isOpen}
        toggle={() => toggleModal("exchanges")}
        className={styles.MarketsModal}
        size="lg"
      >
        <ModalHeader
          className={styles.Header}
          toggle={() => toggleModal("exchanges")}
        >
          Exchanges <span>& Markets</span>
          <div className={styles.test1}>
            <FontAwesome
              prefix="far"
              name="search"
            />
            <div className={styles.Search}>
              <input
                type="text"
                ref={(input) => {
                  this.input = input;
                }}
                placeholder="Filter Exchange..."
                onChange={this.handleSearchInput}
                value={this.state.search}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <ul className={styles.TypesList}>
            {marketSources.map((type) => (
              <li key={type.value}>
                <button
                  className={classNames({[styles.Active]: type.value === this.state.filter})}
                  onClick={(event) => {
                    event.preventDefault();
                    this.setState({filter: type.value});
                  }}
                  tabIndex="0"
                >
                  {type.name}
                </button>
              </li>
            ))}
          </ul>
          <div
            className={styles.MarketsList}
          >
            {sortBy(exchanges, (item) => item.name)
                .map((item) => (
                  <div
                    className={styles.MarketItem}
                    key={item.id}
                    onClick={() => this.updateExchange(item)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className={styles.Logo1}>
                      CEX
                    </div>
                    <div className={styles.Name}>
                      <span className="ticker">1,255</span>
                      <span className="base">/ markets</span>
                      <div className="tickerLong">{item.countries ? item.countries[0] : "N/A"} Exchange</div>
                    </div>

                    <div className={styles.exchangeGroup}>
                      <div>
                        <div className={styles.base2}>
                          Crypto
                        </div>
                        <div className={styles.Exchange}>
                          <img
                            className={styles.Icon}
                            alt=""
                            height={18}
                            src={`/img/exchanges/${item.id}.png`}
                          /> {item.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ExchangesModal.propTypes = {
  exchanges: PropTypes.array,
  getExchangesListAction: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
};

ExchangesModal.defaultProps = {
  exchanges: [],
  getExchangesListAction: () => false,
  isOpen: false,
  toggleModal: () => false,
  widget: undefined,
};

export default ExchangesModal;
