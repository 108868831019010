/* eslint disable */

import React, {Component} from "react";
import PropTypes from "prop-types";
import Chart from './index';
import {chartDefaultConfig} from "./chart";
import {initGeometry} from "./geometry";
import styles from "./style.scss";
import {ResizableBox} from 'react-resizable';
import {tickSocket} from "../../../../../utils/websockets";

const chartMinHeight = 50;

class CanvasChart extends Component {
  constructor(props) {
    super(props);
    const config = this.getChartConfig();
    this.state = {
      mainChartId: "mainchart_" + this.props.index,
      config: config,
      capacity: 0,
      minOffset: 0,
      splitHeights: [],
      zoom: 8,
      chartOffset: 4,
      chartPreviousOffset: 4,
      dragStartX: undefined,
      cursor: [],
      cursorId: '',
      moveY: 0,
      preMoveY: 0,
      isLoadingHistory: false,
    };
  }

  componentDidMount() {
    const {width, widget: {data, i, exchange, marketId}} = this.props;
    const {zoom, config} = this.state;
    const capacity = this.getCapacity(config, width, zoom);
    const minOffset = -data.length + capacity;
    const splitHeights = this.getSplitHeights();
    this.setState({
      capacity,
      minOffset,
      splitHeights,
      chartOffset: Math.max(minOffset, this.state.chartPreviousOffset),
      chartPreviousOffset: Math.max(minOffset, this.state.chartPreviousOffset)
    }, () => this.handleResizeMove());

    tickSocket.emit("tickerSubscribe", {
      params: {
        widgetId: i,
        exchange: exchange,
        id: marketId
      }
    });
    if (this.props.widget.active) {
      document.title=this.props.widget.marketId+" "+ parseFloat(this.props.widget.data[this.props.widget.data.length-1].close);
    }

    tickSocket.on("tickerSubscribe", ({exchange, id, data})=>{
      if (exchange!==this.props.widget.exchange||this.props.widget.marketId!==id) return;
      if (this.isComponentMounted&&this.props.widget.active) {
        document.title=this.props.widget.marketId+" "+ parseFloat(data.close);
      }
    });
    this.isComponentMounted = true;


  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.height != this.props.height) {
      const splitHeights = this.getSplitHeights();
      this.setState({
        splitHeights
      });
    }
    if (prevProps.width != this.props.width) {
      const {zoom, chartOffset, config} = this.state;
      const newWidth = this.props.width;
      const newCapacity = this.getCapacity(config, newWidth, zoom);
      const minOffset = -this.props.widget.data.length + newCapacity;
      let newChartOffset = Math.max(minOffset, this.state.chartPreviousOffset);
      if (newCapacity <= Math.round(chartOffset) + 6) {
        newChartOffset = newCapacity - 6;
        this.setState({
          chartPreviousOffset: newChartOffset,
          chartOffset: newChartOffset,
        });
      }
      this.setState({
        capacity: newCapacity,
        minOffset,
        chartOffset: newChartOffset
      });
    }
    if (prevProps.widget.indicators.length != this.props.widget.indicators.length) {
      const splitHeights = this.getSplitHeights();
      this.setState({
        splitHeights
      }, () => this.handleResizeMove());
    }
    if (prevProps.widget.data.length !== this.props.widget.data.length) {
      const {width, widget: {data}} = this.props;
      const {zoom, config} = this.state;
      const capacity = this.getCapacity(config, width, zoom);
      const minOffset = -data.length + capacity;
      this.setState({
        capacity,
        minOffset,
        isLoadingHistory: false
      });
    }
  }
  componentWillUnmount() {
    tickSocket.emit("tickerUnsubscribe", {
      params: {
        id: this.props.widget.marketId,
        widgetId: this.props.widget.i,
        exchange: this.props.widget.exchange
      }
    });
    this.isComponentMounted = false;

  }
  getSplitHeights = () => {
    const {index, widget: {indicators, splitHeightsPercentage}} = this.props;
    const separatedIndicators = indicators.filter((item) => item.separated);
    const len = separatedIndicators.length;
    const splitPercentage = len < 5 ? 20 : 10;
    const heightsInPct = splitHeightsPercentage && splitHeightsPercentage.length === 1 + len ?
      splitHeightsPercentage : [100 - len * splitPercentage, ...Array(len).fill(splitPercentage)];
    const totalHeight = document.getElementById('resize' + index);
    const contentHeight = totalHeight === null ? 0 : totalHeight.offsetHeight + len * 2;
    // add some extra pixels per indicator // - (len) * (resizeHandleWidth - );
    const splitHeights = [];
    for (let i = 0; i < heightsInPct.length; i++) {
      const percent = heightsInPct[i];
      const height = contentHeight * percent / 100;
      splitHeights.push(height);
    }
    return splitHeights;
  }
  getChartConfig = () => {
    const {width, widget: {data}} = this.props;
    // const {mainChartId, zoom, chartOffset} = this.state;
    // const mainContent = document.getElementById("mainchart_" + index);
    // const canvases = mainContent.querySelectorAll('canvas');
    // const base = canvases[0];
    const base = document.createElement('canvas');
    const config = chartDefaultConfig(base, data, width, 8, 4);  //zoom, chart offset
    return config;
  }

  getCapacity = (config, width, zoom) => {
    const geometry = initGeometry(config.geometry, width, 100);//100 is placeholder
    const contentWidth = geometry.boxPrice.content[2];
    const stickLength = zoom * 2;
    const capacity = Math.floor(contentWidth / stickLength);
    return capacity;
  }
  mouseDownHanlder = (event) => {
    if (event.buttons & 1) {
      this.setState({
        dragStartX: event.clientX
      });
    }
  }
  mouseUpHandler = (event) => {
    this.setState({
      chartPreviousOffset: this.state.chartOffset,
      dragStartX: undefined,
      preMoveY: this.state.moveY
    });
  }
  mouseMoveHandler = (event) => {
    const {chartPreviousOffset, isLoadingHistory, minOffset, dragStartX, zoom, capacity, preMoveY} = this.state;
    const {widget, updateData} = this.props;
    if (event.buttons & 1 && dragStartX) {
      let moveY = preMoveY + dragStartX - event.clientX;
      moveY = moveY % zoom;
      this.setState({
        moveY
      });
      const newOffset = parseInt((dragStartX - event.clientX + preMoveY) / zoom);
      if ((Math.max(minOffset + newOffset, newOffset + chartPreviousOffset) <= capacity - 6) &&
        (Math.max(minOffset + newOffset, newOffset + chartPreviousOffset) >= minOffset)) {
        if (chartPreviousOffset >= minOffset) {
          this.setState({
            chartOffset: Math.max(minOffset + newOffset, newOffset + chartPreviousOffset)
          });
        } else {
          this.setState({
            chartOffset: newOffset + chartPreviousOffset
          });
        }
      }
      if ((Math.max(minOffset + newOffset, newOffset + chartPreviousOffset) < minOffset + 50) &&
        !isLoadingHistory) {
        this.setState({
          isLoadingHistory: true
        }, () => updateData(widget));
      }
    }
  }
  wheelHandler = (event) => {
    const {widget: {data}, widget, width, updateData} = this.props;
    const {zoom, chartOffset, config, capacity, isLoadingHistory} = this.state;
    let delta = event.deltaY;
    if (delta >= 1) delta = 1.5;
    if (delta <= -1) delta = -1.5;
    let newZoom = zoom - delta;
    const min = 2;
    const max = 30;
    if (newZoom < min) newZoom = min;
    if (newZoom > max) newZoom = max;
    const newCapacity = this.getCapacity(config, width, newZoom);
    if (newCapacity > capacity && Math.abs(chartOffset) + newCapacity > data.length &&
      !isLoadingHistory) {
      this.setState({
        isLoadingHistory: true
      }, () => updateData(widget));
    }
    if (newCapacity > Math.round(chartOffset) + 2){
      this.setState({
        zoom: newZoom,
        minOffset: -data.length + newCapacity,
        capacity: newCapacity
      });
    }
  }
  setCursor = (cursor, chartId) => {
    this.setState({
      cursor,
      cursorId: chartId
    });
  }

  drawSeparatedIndicator = (indicator, idx) => {
    const {splitHeights, chartOffset, moveY, zoom, cursor, cursorId, config, dragStartX, minOffset} = this.state;
    const {index, width, updateWidget, widget, widget: {indicators}, handleToggleModal} = this.props;
    const separatedIndicators = indicators.filter((item) => item.separated);
    const len = separatedIndicators.length;
    let isLast = false;
    if (idx == len - 1){
      isLast = true;
    }
    return (
      <ResizableBox
        key={'indicator_'+index+'_'+idx}
        axis={"y"}
        width={width+10}
        height={splitHeights[idx+1] ? Math.round(splitHeights[idx+1]) : 0}
        resizeHandles={["n"]}
        handle={<div handleindex={idx+1} className="react-resizable-handle-custom" />}
        onResize={this.handleResizeMove}
        onResizeStop={this.handleResizeStop}
        minConstraints={[100, chartMinHeight]}
        maxConstraints={(splitHeights[idx] && splitHeights[idx+1]) ? [width, splitHeights[idx+1] +
          splitHeights[idx] - chartMinHeight] : [width, splitHeights[idx+1] || chartMinHeight]}>
        <Chart
          indicators={[indicator]}
          handleToggleModal={handleToggleModal}
          dragStartX={dragStartX}
          config={config}
          isFirst={false}
          isLast={isLast}
          chartId={'indicator_'+index+'_'+idx}
          width={width}
          height={splitHeights[idx+1] ? Math.round(splitHeights[idx+1]) : 0}
          chartOffset={chartOffset}
          minOffset={minOffset}
          moveY={moveY}
          zoom={zoom}
          cursorData={[cursor, cursorId]}
          onMouseDown={this.mouseDownHanlder}
          onMouseUp={this.mouseUpHandler}
          onMouseMove={this.mouseMoveHandler}
          onWheel={this.wheelHandler}
          setCursor={this.setCursor}
          separated
          updateWidget={updateWidget}
          widget={widget}
        />
      </ResizableBox>
    );
  }
  handleResizeMove = (event, resizable) => {

    const {splitHeights} = this.state;
    const newSplitHeights = [...splitHeights];

    const resizables = document.querySelectorAll(`#resize${this.props.index} .react-resizable`);
    for (let i = 1; i < splitHeights.length; i++) {
      if (splitHeights[i] !== resizables[i - 1].clientHeight) {
        newSplitHeights[i] = resizables[i - 1].clientHeight;
        newSplitHeights[i - 1] = splitHeights[i - 1] + splitHeights[i] - newSplitHeights[i];
        this.setState({
          splitHeights: newSplitHeights
        });
        break;
      }
    }
  }
  handleResizeStop = () => {
    const {splitHeights} = this.state;
    const splitHeightsPercentage = [];
    const totalHeight = splitHeights.reduce((h1, h2) => h1 + h2);
    for (let i = 0; i < splitHeights.length; i++) {
      splitHeightsPercentage.push(100 * splitHeights[i] / totalHeight);
    }
    this.props.updateWidget({...this.props.widget, splitHeightsPercentage});
  }
  render() {
    const {splitHeights, capacity, chartOffset, minOffset, moveY, zoom, cursor,
      cursorId, mainChartId, config, dragStartX} = this.state;
    const {width, widget, widget: {indicators}, updateWidget, index,
      drawingItemId, drawingPoints, handleDrawingCheck, handleToggleModal,
      drawingTemplates, handleToggleChartMenu, orders, cancelOrderAction} = this.props;
    const separatedIndicators = indicators.filter((item) => item.separated);
    // const sameIndicators = indicators.filter((item) => !item.separated);
    // console.debug("CAP:", this.state.capacity, chartOffset)
    const len = separatedIndicators.length;
    let separatedIndicatorIdx = 0;
    let isLast = false;
    if (len == 0){
      isLast = true;
    }
    const totalHeight = document.getElementById('resize'+index);
    const contentHeight = totalHeight === null ? 0 :totalHeight.offsetHeight +
    len * 2; // add some extra pixels per indicator // - (len) * (resizeHandleWidth - );
    return (
      <div
        id={'resize'+index}
        className={styles.ResizeContainer}
      >
        <div style={{
          minHeight: chartMinHeight,
          height: splitHeights.length > 1?
                  `calc(100% - ${Math.round(contentHeight - splitHeights[0]) - 4}px)` :
                  `100%`//calc(100% - ${Math.round(height - splitHeights[0])}px)`
        }}>
          <Chart
            indicators={indicators}//sameIndicators}
            drawingItemId={drawingItemId}
            drawingPoints={drawingPoints}
            handleDrawingCheck={handleDrawingCheck}
            handleToggleModal={handleToggleModal}
            handleToggleChartMenu={handleToggleChartMenu}
            drawingTemplates={drawingTemplates}
            cancelOrderAction={cancelOrderAction}
            dragStartX={dragStartX}
            config={config}
            isFirst
            isLast={isLast}
            chartId={mainChartId}
            width={width}
            height={splitHeights[0] ? splitHeights[0] : 0}
            chartOffset={chartOffset}
            minOffset={minOffset}
            moveY={moveY}
            capacity={capacity}
            zoom={zoom}
            cursorData={[cursor, cursorId]}
            onMouseDown={this.mouseDownHanlder}
            onMouseUp={this.mouseUpHandler}
            onMouseMove={this.mouseMoveHandler}
            onWheel={this.wheelHandler}
            setCursor={this.setCursor}
            separated={false}
            updateWidget={updateWidget}
            widget={widget}
            orders={orders}
          />
        </div>
        {indicators.map((indicator) => indicator.separated && this.drawSeparatedIndicator(indicator, separatedIndicatorIdx++))}
      </div>
    );
  }
}

CanvasChart.propTypes = {
  drawingItemId: PropTypes.string,
  drawingPoints: PropTypes.number,
  drawingTemplates: PropTypes.object.isRequired,
  index: PropTypes.number,
  handleDrawingCheck: PropTypes.func,
  handleToggleChartMenu: PropTypes.func,
  handleToggleModal: PropTypes.func,
  orders: PropTypes.func,
  cancelOrderAction: PropTypes.func,
  height: PropTypes.number,
  resizingWidgetId: PropTypes.string,
  updateWidget: PropTypes.func,
  width: PropTypes.number.isRequired,
  widget: PropTypes.object,
  updateData: PropTypes.func,
};

CanvasChart.defaultProps = {
  drawingItemId: 'cross',
  drawingPoints: 0,
  index: 0,
  height: 200,
  handleDrawingCheck: () => {},
  resizingWidgetId: '',
  widget: {},
  updateData: () => false,
};

export default CanvasChart;
