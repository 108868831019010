import {connect} from "react-redux";
import HistoryWidget from "../components/HistoryWidget/HistoryWidget";

import {getMarketHistory, subscribeMarketHistory, unsubscribeMarketHistory} from "../../../actions/MarketsActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null;
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;

      return {
        activeWidget: activeWidget,
        marketHistory: state.markets.marketHistory,
        widget: widget,
      };
    }, {
      getMarketHistory,
      subscribeMarketHistory,
      unsubscribeMarketHistory
    },
)(HistoryWidget);
