// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HE8VlUWtA9WWMegryvnOF{height:calc(100% - 60px);width:100%;display:table}.HE8VlUWtA9WWMegryvnOF ._2USngqK24XQirjCgl2RVkw{margin:0 auto;display:table-cell;vertical-align:middle;text-align:center}.HE8VlUWtA9WWMegryvnOF ._2USngqK24XQirjCgl2RVkw img{width:70%}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/SelectWidget.scss"],"names":[],"mappings":"AAGE,uBACE,wBAAA,CACA,UAAA,CACA,aAAA,CAEA,gDACE,aAAA,CACA,kBAAA,CACA,qBAAA,CACA,iBAAA,CAEA,oDACE,SAAA","sourcesContent":["@import \"../../../styles/constants.scss\";\n\n:local {\n  .SelectWidget {\n    height: calc(100% - 60px);\n    width: 100%;\n    display: table;\n\n    .ImageWrapper {\n      margin: 0 auto;\n      display: table-cell;\n      vertical-align: middle;\n      text-align: center;\n\n      img {\n        width: 70%;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectWidget": "HE8VlUWtA9WWMegryvnOF",
	"ImageWrapper": "_2USngqK24XQirjCgl2RVkw"
};
export default ___CSS_LOADER_EXPORT___;
