import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from 'react-toastify';

import classNames from "classnames";
import Favicon from "react-favicon";
import Header from "./Header";
import Chat from "../pages/Chat/containers/ChatContainer";
import FontAwesome from "./FontAwesome";

import { ALERT_TYPES } from "../const";

import favIcon from "../assets/favicon.ico";
import styles from "./Layout.scss";

class Layout extends Component {
  state={
    isLoading: true
  }
  componentDidMount() {
    this.props.getUserName()
        .catch(()=> {
          if (!['/login', '/signup'].includes(this.props.location.pathname)) {
            this.props.history.push('/login');
          }
        })
        .then(()=>{
          this.setState({isLoading: false});
        });

  }

  componentDidUpdate(prevProps) {
    // const { alert, isLogged } = this.props;
    // clearTimeout(alertTimeout);
    // alertTimeout = setTimeout(() => {
    //   this.showAlert(alert.type, alert.message, alert.timeout);
    // }, 500);
    // if (prevProps.isLogged === false && isLogged !== false) {
    //   location.reload();
    // }
  }

  displayIcon = (message, icon) => {
    if (typeof icon === "object") {
      return (
        <FontAwesome
          name={icon.name}
          prefix={icon.prefix}
          wrapperClassName={styles.ToastImage}
        />
      );
    }
    return (
      <img
        className={styles.ToastImage}
        alt={message.type}
        height={18}
        width={18}
        src={icon}
      />
    );
  }

  showAlert = (type, message = "Error", timeout = 2000) => {
    let messageContent = null;
    let messageIcon = null;
    const messageOptions = {
      type: type || ALERT_TYPES.INFO,
      autoClose: timeout,
    };

    if (typeof message === "object" && message?.type) {
      switch (message.type) {
        case "url":
          messageContent = (
            <Fragment>
              <div>{message.message}</div>
              <a href={message.path} target="_blank">Click to open</a>
            </Fragment>
          );
          break;
        case "order":
        default:
          messageIcon = message.icon;
          messageContent = (
            <Fragment>
              <b>{message.title}</b>
              <div>
                {message.message}
              </div>
            </Fragment>
          );
          break;
      }
    } else {
      messageContent = message;
    }

    {
      message?.icon && toast((
        <div className={styles.ToastContent}>
          {message.icon && this.displayIcon(message, messageIcon)}
          <div className={styles.ToastMessage}>
            {messageContent}
          </div>
        </div>
      ), messageOptions);
    }
  };

  render() {
    const { location, children, isLogged } = this.props;
    const hasFooter = ["/markets"].indexOf(location.pathname) >= 0;

    return !this.state.isLoading&&(
      <div className={styles.Layout}>
        <Favicon url={favIcon} />
        <Header isLogged={this.props.isLogged} doLogout={this.props.doLogout} />
        <ToastContainer
          toastClassName={styles.Toast}
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
        <div className={classNames(styles.Content, { [styles.WithFooter]: hasFooter })}>
          {children}
        </div>
        {isLogged && (
          <Chat />
        )}
      </div>
    );
  }
}

Layout.propTypes = {
  getUserName: PropTypes.func,
  doLogout: PropTypes.func,
  history: PropTypes.object,
  alert: PropTypes.object,
  children: PropTypes.any.isRequired,
  isLogged: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  location: PropTypes.object.isRequired,
};

Layout.defaultProps = {
  alert: {},
  isLogged: false,
};

export default Layout;
