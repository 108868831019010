import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import enhanceWithClickOutside from "react-click-outside";
import FontAwesome from "../../../../components/FontAwesome";

import styles from "./DomMenu.scss";
import FloatValue from "../../../../components/FloatValue";
import MarketName from "../../../../components/MarketName";

class DomMenu extends PureComponent {
  constructor() {
    super();

    this.state = {};
  }

  handleClickOutside() {
    this.props.onHandleToggleChartMenu();
  }

  handleAddAlert = () => {
    console.log("alert added");
  };

  removeOrder = () => {
    const {orders, currentItem, cancelOrderAction} = this.props;
    orders.forEach((order) => {
      if (order.Limit === currentItem.Rate) {
        cancelOrderAction(order.accountName, order);
      }
    });
  };

  render() {
    const {position, isOpen, currentItem, activeWidget, openTradeWidget, onHandleToggleChartMenu} = this.props;

    return (
      <div
        className={classNames(styles.DomMenu, {[styles.Hidden]: !isOpen})}
        style={{left: position[0], top: position[1]}}
      >
        <button
          className={styles.Button}
          onClick={() => {
            openTradeWidget(currentItem.Rate.toFixed(8), currentItem.type);
            onHandleToggleChartMenu();
          }}
        >
          <FontAwesome
            name={currentItem && currentItem.type === "buy" ? "arrow-up" : "arrow-down"}
            prefix="fas"
          />
          {" "}{currentItem && currentItem.type === "buy" ? "Buy" : "Sell"}
          {" "}{activeWidget && (<MarketName name={activeWidget.name}/>)}
          {" @ "}{currentItem && (<FloatValue value={currentItem.Rate}/>)}
        </button>

        {currentItem && (currentItem.sellOrders > 0 || currentItem.buyOrders > 0) && (
          <div className={styles.Divider}/>
        )}

        {currentItem && (currentItem.sellOrders > 0 || currentItem.buyOrders > 0) && (
          <button
            className={styles.Button}
            onClick={() => this.removeOrder()}
          >
            <FontAwesome name="times" prefix="fas"/> Cancel {currentItem && (
              <FloatValue value={currentItem.Quantity}/>
            )} {activeWidget && (
              <MarketName name={activeWidget.name}/>
            )} Order
          </button>
        )}
      </div>
    );
  }
}

DomMenu.propTypes = {
  activeWidget: PropTypes.object,
  cancelOrderAction: PropTypes.func,
  currentItem: PropTypes.object,
  isOpen: PropTypes.bool,
  onHandleToggleChartMenu: PropTypes.func,
  openTradeWidget: PropTypes.func,
  orders: PropTypes.array,
  position: PropTypes.array,
};

DomMenu.defaultProps = {
  activeWidget: undefined,
  cancelOrderAction: () => false,
  currentItem: undefined,
  isOpen: false,
  onHandleToggleChartMenu: () => false,
  openTradeWidget: () => false,
  orders: [],
  position: [0, 0],
};

export default enhanceWithClickOutside(DomMenu);
