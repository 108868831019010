import {INTERVALS, UPDATE_WIDGET} from "../constants/MarketsConstants";
import {getMarketExchanges, getOHLCVHistory} from "../api/MarketsApi";
import {
  getProgressIndex,
  getWidgetMarkets, getWidgetSettings,
  isWidgetStopped
} from "../selectors/wigdetScanner";
import {getActiveWorksheet} from "../selectors/worksheet";
import {getWidgetById, getWidgetExchange} from "../selectors/wigdet";
import {RSI} from "@debut/indicators";
import {fetchPattern} from "../api/PatternsApi";
import allPatterns from '../components/WidgetScanner/constants';
export const stopScan = (widgetId) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_WIDGET,
    widget: {
      ...getWidgetById(widgetId, getState()),
      _progress: 0,
      _patterns: [],
      _isStopped: true,
      _isLoading: false
    },
  });
};

export const pauseScan = (widgetId) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_WIDGET,
    widget: {
      ...getWidgetById(widgetId, getState()),
      _isStopped: true,
      _isLoading: false
    },
  });
};

export const startScan = (widgetId) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_WIDGET,
    widget: {
      ...getWidgetById(widgetId, getState()),
      _isStopped: false,
      _isLoading: true
    },
  });
  const getPatternById=(searchId)=>allPatterns[0].options.find(({id})=>id===searchId);
  let startIndex=getProgressIndex(widgetId, getState());
  if (startIndex) startIndex += 1;
  const marketList=getWidgetMarkets(widgetId, getState());
  const settings=getWidgetSettings(widgetId, getState());
  for (let i=startIndex; i<marketList.length; i++){
    if (isWidgetStopped(widgetId, getState())) {
      break;
    }
    const intervals=Object.fromEntries(settings.patterns.map((data)=>[data.interval, Number(data.lookback)]).sort((a, b)=>a[1]-b[1]));
    let histories={};
    let history = [];
    let rsi = [];
    for (const interval in intervals){
      histories[interval]=await dispatch(getOHLCHistory(widgetId, marketList[i].id, interval, intervals[interval]));
    }

    for (const setting of settings.patterns) {
      history=histories[setting.interval];

      if (isWidgetStopped(widgetId, getState())) {
        break;
      }
      await new Promise(async (resolve) => {
        dispatch({
          type: UPDATE_WIDGET,
          widget: {
            ...getWidgetById(widgetId, getState()),
            _progress: i
          },
        });
        try {
          const point=setting.point??"Close";
          const pointIndex={
            Open: 1,
            High: 2,
            Low: 3,
            Close: 4,
          }[point];
          rsi = await dispatch(calculateRSI(history));
          const {data: patternsResults} = await fetchPattern(setting.id, {
            settings: {
              point
            },
            ...getPatternById(setting.id).format({
              data: history,
              rsi
            })
          });

          const newPatterns = [];
          patternsResults[setting.id].reverse();
          const data=history;
          data.reverse();
          for (const pattern of patternsResults[setting.id]) {
            newPatterns.push(
                {
                  scannerId: setting.id,
                  id: marketList[i].id,
                  base: marketList[i].base,
                  quote: marketList[i].quote,
                  sentiment: getPatternById(setting.id).sentiment,
                  interval: "1d",
                  type: "RSI Divergence",
                  result: getPatternById(setting.id).result,
                  ageStart: pattern.age_start,
                  ageEnd: pattern.age_end,
                  ts1: pattern.start_dtm,
                  ts2: pattern.end_dtm,
                  price1: pattern.price_start,
                  price2: pattern.price_end,
                }
            );
          }

          const widget = getWidgetById(widgetId, getState());
          if (!isWidgetStopped(widgetId, getState())) {
            dispatch({
              type: UPDATE_WIDGET,
              widget: {
                ...widget,
                _patterns: [
                  ...widget._patterns ?? [],
                  ...newPatterns
                ]
              },
            });
          }

          setTimeout(resolve, 1000);
        } catch (e) {
          console.log(e);
          dispatch(pauseScan(widgetId));
        }
      });
    }

    if (startIndex < i && i < marketList.length && i % 50 === 0)  {
      let sleep = 5000;
      if (i > 200) sleep = 10000;
      if (i > 800) sleep = 15000;
      histories = null;
      history = null;
      rsi = null;
      await garbageCollect(sleep);
    }
  }
};

const garbageCollect = (duration) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

export const fetchMarketExchanges = (widgetId) => async (dispatch, getState) => {
  const {data}=await getMarketExchanges(getWidgetExchange(widgetId, getState()));
  dispatch({
    type: UPDATE_WIDGET,
    widget: {
      ...getWidgetById(widgetId, getState()),
      _markets: data
    },
  });
};


export const getOHLCHistory = (widgetId, marketName, Interval, lookback) => async (dispatch, getState) => {
  const state = getState();
  const worksheetId = getActiveWorksheet(state).id;
  const exchange = getWidgetExchange(widgetId, state);
  const interval = INTERVALS.find(({id, type}) => id === Interval && type !== "divider");
  const dateFrom = new Date().getTime() - (interval.min * 60 * lookback * 1000);
  let data;
  for (let i=0; i<3; i++) {
    try {
      data= (await getOHLCVHistory({
        exchange,
        marketName,
        basePeriod: Interval,
        worksheetId,
        widgetId,
        since: dateFrom
      })).data;
      break;
    } catch (e) {
      console.log(e);
    }
  }
  if (!data){
    throw new Error();
  }
  return data.data.slice(data.data.length-lookback, data.data.length-1);
};
export const calculateRSI = (data) => async () => {
  const rsi=new RSI(14);
  return data.map((data)=>rsi.nextValue(data[4]));
};

export const updateSettings = (widgetId, data) => async (dispatch, getState) => {
  return  dispatch({
    type: UPDATE_WIDGET,
    widget: {
      ...getWidgetById(widgetId, getState()),
      settings: data
    },
  });
};
