import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./OrderType.scss";

const OrderType = ({type}) => {
  const isBuy = type.toUpperCase().indexOf("BUY") >= 0 ? true : false;
  return type ? (
    <div className={classNames(
        styles.OrderType,
        {
          green: isBuy,
          red: !isBuy
        }
    )}>
      {isBuy ? "BUY" : "SELL"}
    </div>
  ) : null;
};

OrderType.propTypes = {
  type: PropTypes.string,
};

OrderType.defaultProps = {
  type: undefined,
};

export default OrderType;
