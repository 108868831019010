import {Manager} from "socket.io-client";

const socketOptions = {
  transports: ["websocket"],
  path: '/socket.io',
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999,
  autoConnect: false,
  closeOnBeforeunload: false,
};
const manager=new Manager(process.env.REACT_APP_BACKEND_WS_PATH, socketOptions);
const tickManager=new Manager(process.env.REACT_APP_TICK_WS_PATH, {
  ...socketOptions,
  path: '/ticks/socket.io',

});
export const marketSocket = manager.socket(`/market`);
export const chatSocket = manager.socket(`/chat`);
export const tickSocket = tickManager.socket(`/ticks`);
let prevSocketId;

const marketMessageTypes = [
  "getTicksV2Res",
  "getMTFInitChartDataRes",
  "getLatestTickV2Res",
  "getLatestMTFTickV2Res",
  "getPrevTickV2Res",
  "getMarketSummariesRes",
  "getMarketDomRes",
  "subscribeMarketDomSnapshotRes",
  "subscribeMarketDomUpdateRes",
  "getMarketHistoryRes",
  "subscribeMarketHistoryRes",
  "getActiveOrdersRes",
  "getOrdersHistoryRes",
  "getBalanceRes",
  "getFeedRes",
  "getNewFeedRes",
  "getNewsSentimentRes",
  "getPriceDataRes",
].reduce((accum, msg) => {
  accum[msg] = msg;
  return accum;
}, {});

const chatMessageTypes = [
  "sentMessageRes",
  "deletedMessageRes",
  "updatedMessageRes",
  "userLoggedIn",
  "userLoggedOut",
  "userTypingRes",
].reduce((accum, msg) => {
  accum[msg] = msg;
  return accum;
}, {});

export const init = (store) => {
  // add listeners to socket messages so we can re-dispatch them as actions
  Object.keys(marketMessageTypes).forEach((type) =>
    marketSocket.on(type, (payload) => {
      return store.dispatch({type, payload});
    }));

  Object.keys(chatMessageTypes).forEach((type) => chatSocket.on(type, (payload) => {
    return store.dispatch({type, payload});
  }));

  marketSocket.on("disconnect", (reason) => {
    if (reason === "io server disconnect") {
      marketSocket.connect();
    }
  });

  marketSocket.on("connect", () => {
    if (prevSocketId) {
      emit("socketReconnect", {
        params: {
          prevSocketId
        }
      });
    }
    prevSocketId = marketSocket.id;
  });
};

export const emitTicker = (params) => {
  tickSocket.emit('tickerSubscribe', {
    id: Math.random() * 100000000000,
    params
  });
};
export const emit = (type, payload, room = "market") => {
  const rand = Math.floor(Math.random() * 100000000000);
  const options = payload ? {id: rand, ...payload} : {id: rand};

  switch (room) {
    case "chat":
      return chatSocket.emit(type, options);
  }
  // if (type==="getLatestTickV2Binance"){
  //   return tickSocket.emit(type, options);
  // }

  return marketSocket.emit(type, options);

};
