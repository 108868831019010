import React, {Component} from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { scaleOrdinal }  from "d3-scale";
import * as d3 from "d3";

import BalanceAccountItem from "./BalanceAccountItem";
import TotalChart from "./TotalChart";
import FloatValue from "../../../components/FloatValue";
import Block from "../../../components/Block";
import Table from "../../../components/Table";
import {Helmet} from "react-helmet";
import styles from "./BalancePage.scss";
const colors = d3.scale.category10();

class BalancePage extends Component {
  constructor() {
    super();

    this.interval = null;
    this.timeout = null;
  }

  componentDidMount() {
    const {init, getMarketsInfoAction} = this.props;
    init();
    getMarketsInfoAction();
    this.interval = setInterval(init, 10 * 1000);
  }

  componentWillUnmount() {
    this.props.destroy();
    clearInterval(this.interval);
  }

  drawTotalContent = () => {
    const {balance} = this.props;

    const data = [];
    let allAccountsTotalBTC = 0;
    let allAccountsTotalUSDT = 0;
    balance.forEach((account) => {
      const name = `${account.name}, ${account.exchange.toUpperCase()}`;
      let totalBTC = 0;
      let totalUSDT = 0;
      const color = colors(name);

      account.balance.forEach((item) => {
        totalBTC += item.rateBTC * item.total;
        totalUSDT += item.rateUSDT * item.total;
      });

      data.push({
        name: name,
        stroke: color,
        fill: color,
        valueBTC: totalBTC,
        valueUSDT: totalUSDT,
      });
      allAccountsTotalBTC += totalBTC;
      allAccountsTotalUSDT += totalUSDT;
      //debugger;
    });

    return (
      <div>
        <TotalChart
          data={data}
          totalBTC={allAccountsTotalBTC}
          totalUSDT={allAccountsTotalUSDT}
        />
        <Table
          fields={[
            {key: "exchange", label: "Exchange", disableSort: true},
            {key: "balance", label: "Balance", disableSort: true},
          ]}
        >
          {data.map((account) => (
            <tr key={account.name}>
              <td className={styles.exchange}>
                <span
                  className={styles.Indicator}
                  style={{backgroundColor: account.fill, marginRight: 5}}
                /> {account.name.split(",")[1]} <span className={styles.accountName}>[{account.name.split(",")[0]}]</span>
              </td>
              <td>
                <img alt="BTC" className={styles.menuIcon} src={`/img/icons/BTC.svg`}/> <FloatValue value={account.valueBTC}/>
                <br/>
                <img alt="USDT" className={styles.menuIcon} src={`/img/icons/USDT.svg`}/> <FloatValue value={account.valueUSDT}/>
              </td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>
              <img alt="BTC" className={styles.menuIcon} src={`/img/icons/BTC.svg`}/> <FloatValue value={allAccountsTotalBTC}/>
              <br/>
              <img alt="USDT" className={styles.menuIcon} src={`/img/icons/USDT.svg`}/> <FloatValue value={allAccountsTotalUSDT}/>
            </td>
          </tr>
        </Table>
      </div>
    );
  };

  render() {
    const {balance, markets} = this.props;

    return (
      <PerfectScrollbar options={{suppressScrollX: true}}>
        <Helmet>
          <title>Balances</title>
        </Helmet>
        <div className={styles.BalancePage}>
          <Block
            toolbar="Balance"
            content={this.drawTotalContent()}
            col={3}
            right
          />
          {balance.map((account) => {
            const accountBalance = balance.find((a) => a.name === account.name && a.exchange === account.exchange);
            return (
              <BalanceAccountItem
                account={account}
                balance={accountBalance ? accountBalance.balance : []}
                balanceLength={balance.length}
                key={`${account.name}-${account.exchange}`}
                markets={markets}
              />
            );
          })}
        </div>
      </PerfectScrollbar>
    );
  }
}

BalancePage.propTypes = {
  balance: PropTypes.array,
  destroy: PropTypes.func.isRequired,
  getMarketsInfoAction: PropTypes.func,
  init: PropTypes.func.isRequired,
  markets: PropTypes.array,
};

BalancePage.defaultProps = {
  balance: [],
  getMarketsInfoAction: () => false,
  markets: [],
};

export default BalancePage;
