/* eslint react/jsx-handler-names: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Select from "react-select";
import classNames from "classnames";
import styles from "./ChatModal.scss";

const initialState = {
  title: undefined,
  member: "",
  members: [],
  //hideMembers: true,
  type: "team",
  modalType: undefined,
  alreadyExists: false,
  alreadyJoined: false
};

class TeamsModal extends Component {
  constructor() {
    super();

    this.state = initialState;
  }

  componentDidMount() {
    this.props.getPublicChannelsAction();
  }

  componentDidUpdate(prevProps) {
    const {data, isOpen, users} = this.props;
    if (!prevProps.isOpen && isOpen) {
      if (data && data._id) {
        const memberList = data.members.map((item) => item.id);
        const members = users.filter((item) => memberList.indexOf(item._id) >= 0).map((item) => ({
          label: item.username,
          value: item._id,
          avatar: item.avatar,
        }));
        this.setState({
          title: {label: data.title, value: data.title},
          members: members,
          type: "team",
        });

      }
    }
  }

  handleChange = (field, value) => {
    const {channels} = this.props;
    let data = {
      [field]: value,
    };
    //debugger;
    const alreadyExists = this.state.alreadyExists;
    let alreadyJoined = this.state.alreadyJoined;
    if (field === "title") {

      const trimmedValue = value.value && value.value.toLowerCase().replace(/\s/g, '');
      alreadyJoined = value.value && channels.find((item) =>
        item.title.toLowerCase().replace(/\s/g, '') === trimmedValue && item.type === "team"
      );

      //const isPublic = value && publicChannels.filter((item) => item._id === value.value).length > 0;
      data = {
        ...data,
        //hideMembers: !value || isPublic,
        //type: value ? (isPublic ? "public" : "private", "team") : undefined,
        //modalType: this.state.modalType === "settings" ? "settings" : (isPublic ? "join" : "create"),
        alreadyExists,
        alreadyJoined
      };
    }
    this.setState(data);
  };

  handleDeleteChannel = () => {
    const {deleteChannelAction, data} = this.props;
    deleteChannelAction(data._id);
    this.closeModal();
  };

  handleSaveChannel = () => {
    const {data, createChannelAction, updateChannelAction} = this.props;
    const {title, type, members} = this.state;


    debugger;
    if (title) {
      createChannelAction({
        members: members.map((item) => item.value),
        title: title.label,
        type: type,
      });

      this.closeModal();
    }

  };

  closeModal = () => {
    this.setState(initialState);
    this.props.toggleModal("teams");
  };

  render() {
    const {isOpen, data, users} = this.props;
    const {alreadyExists, title, alreadyJoined} = this.state;
    const members = users.map((item) => ({
      value: item._id,
      label: item.username,
      avatar: item.avatar,
    }));

    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={this.closeModal}
        className={classNames(styles.ChatModal, styles.moveable)}
        size="md"
      >
        <ModalHeader
          className={styles.Header}
          toggle={this.closeModal}
        >
          Teams <span>& Projects</span>
        </ModalHeader>
        <ModalBody>

          <div className={styles.Wrapper}>
            <span className={styles.title}>Channel name</span>
            <input
              name="channel"
              placeholder="Create a team name..."
              onChange={(event) => this.handleChange(
                  "title", {
                    label: event.target.value,
                    value: event.target.value
                  }
              )}
              value={title && title.label}
            />
            {alreadyJoined &&
              <div className={styles.WarningText}>
                You already have a channel with the same name
              </div>
            }
            <br/>
            <span className={styles.title}>Add People</span>
            <div className="chatmodal">
              <Select
                isSearchable
                isMulti
                className="OrderTypes"
                classNamePrefix="react-select"
                name="members"
                placeholder="Add team members..."
                value={this.state.members}
                onChange={(value) => this.handleChange("members", value)}
                options={members}
              />
            </div>
            <br/>
            <span className={styles.title}>Description (optional)</span>
            <textarea
              className={styles.description}
              //placeholder="Create channel introduction..."
            />
            <span className={styles.title}>Privacy</span>
            <div className="chatmodal">
              <Select
                options={{label: <span><b>LMT</b> Limit</span>, value: "LMT"}}
                defaultValue={{label: <span><b>ENCRYPTED</b> - Allow team access</span>, value: "LMT"}}
                className="OrderTypes"
                classNamePrefix="react-select"
                onChange={this.handleChange}
              //menuIsOpen
              />
            </div>
          </div>
          <button
            className={styles.SaveButton}
            onClick={this.handleSaveChannel}
            type="button"
            disabled={alreadyExists || alreadyJoined}
          >
            Create
          </button>
          <button
            className={styles.CancelButton}
            onClick={this.closeModal}
            type="button"
          >
            Cancel
          </button>
          {data && data._id && (
            <button
              className={styles.DeleteButton}
              onClick={this.handleDeleteChannel}
              type="button"
            >
              Delete
            </button>

          )}
        </ModalBody>
      </Modal>
    );
  }
}

TeamsModal.propTypes = {
  createChannelAction: PropTypes.func,
  data: PropTypes.object,
  deleteChannelAction: PropTypes.func,
  getPublicChannelsAction: PropTypes.func,
  isOpen: PropTypes.bool,
  joinChannelAction: PropTypes.func,
  publicChannels: PropTypes.array,
  channels: PropTypes.array,
  toggleModal: PropTypes.func,
  updateChannelAction: PropTypes.func,
  users: PropTypes.array,
};

TeamsModal.defaultProps = {
  createChannelAction: () => false,
  data: undefined,
  deleteChannelAction: () => false,
  getPublicChannelsAction: () => false,
  isOpen: false,
  joinChannelAction: () => false,
  publicChannels: [],
  channels: [],
  toggleModal: () => false,
  updateChannelAction: () => false,
  users: [],
};

export default TeamsModal;
