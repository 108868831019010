import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {Nav, NavItem} from "reactstrap";
import "./Submenu.scss";

const Submenu = ({items}) => {
  return (
    <Nav className="app-submenu mr-auto">
      {items.map((item) => (
        <NavItem key={item.name}>
          <Link
            className={classNames("nav-link", {active: location.pathname.indexOf(item.href) >= 0})}
            to={item.href || "#"}
          >
            {item.name}
          </Link>
        </NavItem>
      ))}
    </Nav>
  );
};

Submenu.propTypes = {
  items: PropTypes.array,
};

Submenu.defaultProps = {
  items: [],
};

export default Submenu;
