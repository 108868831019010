import React, {PureComponent, Fragment} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {sortBy} from "lodash";
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import FontAwesome from "../../../components/FontAwesome";
import styles from "./BalancePage.scss";
import FloatValue from "../../../components/FloatValue";
import Collapse from "../../../components/Collapse";
import Table from "../../../components/Table";

class BalanceAccountItem extends PureComponent {
  constructor() {
    super();

    this.state = {
      sortingKey: null,
      sortingType: null,
      hideZero: true,
      dropdownOpen: false,
    };
  }

  handleToggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  handleToggleHideZero = () => {
    this.setState({
      hideZero: !this.state.hideZero,
    });
  };

  getCurrencyName = (currency) => {
    const {markets} = this.props;
    const currentCurrency = markets.find((market) => market.MarketCurrency === currency);
    return currentCurrency ? currentCurrency.MarketCurrencyLong : currency;
  };

  onSortClick = (key, type) => this.setState({sortingKey: key, sortingType: type});

  sortData = (items) => {
    const {sortingKey, sortingType} = this.state;
    const sortedData = sortingKey ? sortBy(items, (item) => {
      switch (sortingKey) {
        case "symbol":
          return item.symbol;
        case "name":
          return item.name;
        case "free":
          return item.free;
        case "used":
          return item.used;
        case "total":
          return item.total;
        case "rate":
          return item.rateBTC;
        case "BTCValue":
          return item.total * item.rateBTC;
        case "USDTValue":
          return item.total * item.rateUSDT;
        default:
          return item[sortingKey];
      }
    }) : items;

    return sortingType === "desc" ? sortedData.reverse() : sortedData;
  };

  drawActions = () => (
    <div className={styles.buttonZero}>
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={() => this.handleToggleDropdown()}
      >
        {/* eslint-enable */}
        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown()}
        >
          <FontAwesome
            name='bars'
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right">
          <DropdownItem
            className={classNames({
              active: this.state.hideZero,
            })}
            onClick={this.handleToggleHideZero}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked
              onChange={()=>null}
              onClick={this.handleToggleHideZero}
            /> Hide zero balances
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );

  drawContent = () => {
    const {balance, account} = this.props;
    return (
      <Table
        fields={[
          {key: "symbol", label: "Ticker"},
          {key: "name", label: "Name"},
          {key: "free", label: "Available"},
          {key: "used", label: "Held"},
          {key: "total", label: "Total"},
          {key: "rate", label: "Current Price BTC"},
          {key: "BTCValue", label: "BTC Value"},
          {key: "USDTValue", label: "USDT Value"},
        ]}
        sort={this.onSortClick}
      >
        {this.sortData(balance)
            .filter((item) => this.state.hideZero ? item.total !== 0 : true)
            .map((item) => (
              <tr key={item.symbol}>
                <td className={styles.ticker}>
                  {item.symbol}
                </td>
                <td className={styles.longname}>
                  {item.name}
                  <div className={styles.base}>
                    <span className={styles.exchangename}>{/*{this.props.exchange}*/}Store of Value</span>
                  </div>

                </td>
                <td>
                  <FloatValue value={item.free}/>
                </td>
                <td>
                  <FloatValue value={item.used}/>
                </td>
                <td>
                  <FloatValue value={item.total}/>
                </td>
                <td>
                  <FloatValue value={item.rateBTC}/>
                </td>
                <td>
                  <FloatValue value={item.rateBTC * item.total}/>
                </td>
                <td>
                  <FloatValue value={item.rateUSDT * item.total}/>
                </td>
              </tr>
            ))}
      </Table>
    );
  };

  render() {
    const {account, balance} = this.props;
    const data = balance.filter((item) => this.state.hideZero ? item.total !== 0 : true);
    return (
      <Collapse
        col={7}
        label={(
          <Fragment>
            <img
              alt=""
              height={22}
              style={{marginRight: 5}}
              src={`/img/exchanges/${account.exchange.toLowerCase()}.png`}
            /> {`${account.exchange.toUpperCase()}`}<span className={styles.accountName}> [{`${account.name}`}]</span>
          </Fragment>
        )}
        description={`Open positions (${data.length})`}
        actions={this.drawActions()}
      >
        {this.drawContent()}
      </Collapse>
    );
  }
}

BalanceAccountItem.propTypes = {
  account: PropTypes.object,
  balance: PropTypes.array,
  markets: PropTypes.array,
};

BalanceAccountItem.defaultProps = {
  account: {},
  balance: [],
  markets: [],
};

export default BalanceAccountItem;
