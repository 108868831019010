import React, {Component} from "react";
import PropTypes from "prop-types";
import {sortBy} from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import FontAwesome from "../../../../components/FontAwesome";
import Loader from "../../../../components/Loader";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import MarketItem, {MARKET_ITEM_ROW_HEIGHT} from "./MarketItem";
import styles from "./MarketsWidget.scss";
import {getMarketExchanges, getMarketQuotes} from '../../../../api/MarketsApi';
import WidgetCoinFilter, {
  excludeFromAltsList,
  filterCoinFn
} from "../../../../components/WidgetCoinFilter/WidgetCoinFilter";
const FIELDS = [
  {name: "TICKER", key: "TICKER", width: 2},
  {name: "TOKEN/PAIR", key: "TOKEN", width: 2},
  {name: "PRICE", key: "PRICE", width: 2},
  {name: "CHANGE", key: "CHANGE", width: 2},
];
class MarketsWidget extends Component {
  constructor() {
    super();

    this.debounce = null;
    this.interval = null;
    this.state = {
      list: [],
      sortKey: FIELDS[0].name,
      sortType: "asc",
      onlyFavourites: false,
      filter: "",
      base: "BTC",
      firstShowedElement: 0,
      pageSize: 30,
      isLoading: true
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.widget && this.props.widget && prevProps.widget.exchange !== this.props.widget.exchange) {
      this.setState({isLoading: true});
      await this.loadData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async loadData(){
    const {data} = await getMarketExchanges(this.props.widget.exchange);

    const {data: quotes} = await getMarketQuotes(this.props.widget.exchange);
    this.setState({
      list: data
          .map(data=>Object.assign(data, quotes[data.id]??quotes[data.symbol]))
          .filter(({last})=>last),
      isLoading: false
    });
  }
  onSortClick = (key, type) => this.setState({sortingKey: key, sortingType: type});

  sortData = (items) => {
    const {sortingKey, sortingType} = this.state;
    const sortedData = sortingKey ? sortBy(items, (item) => {
      switch (sortingKey) {
        case "PRICE":
          return item.last;
        case "CHANGE":
          return item.percentage;
        case "TOKEN":
        default:
          return item.symbol;

      }
    }) : items;

    return sortingType === "desc" ? sortedData.reverse() : sortedData;
  };

  handleSort = (key) => {
    const {sortKey, sortType} = this.state;
    let newSortKey;
    let newSortType;

    if (!sortKey || sortKey !== key) {
      newSortKey = key;
      newSortType = "asc";
    } else if (sortKey && sortType === "asc") {
      newSortKey = sortKey;
      newSortType = "desc";
    } else if (sortKey && sortType === "desc") {
      newSortKey = null;
      newSortType = "asc";
    }
    this.setState({
      sortKey: newSortKey,
      sortType: newSortType,
    });
    this.onSortClick(newSortKey, newSortType);
  };

  addToFavourites = (item) => {
    const {updateWidget, widget} = this.props;
    if (widget.favourites) {
      if (widget.favourites.indexOf(item.symbol) >= 0) {
        updateWidget({
          ...widget,
          favourites: widget.favourites.filter((fav) => fav !== item.symbol),
        });
      } else {
        updateWidget({
          ...widget,
          favourites: [...widget.favourites, item.symbol],
        });
      }
    } else {
      updateWidget({
        ...widget,
        favourites: [item.symbol],
      });
    }
  };

  filterMarkets = (value) => {
    this.setState({
      filter: value,
    });
  };

  drawSorter = (field) => {
    const {sortKey, sortType} = this.state;
    return (
      <span className="fa-layers">
        <FontAwesome
          name="chevron-up"
          dataFaTransform="up-6"
          style={{opacity: sortKey === field.name && sortType === "asc" ? 1 : .5}}
        />
        <FontAwesome
          name="chevron-down"
          dataFaTransform="down-6"
          style={{opacity: sortKey === field.name && sortType === "desc" ? 1 : .5}}
        />
      </span>
    );
  };

  handleToggleFavourites = () => {
    this.setState({onlyFavourites: !this.state.onlyFavourites});
  };
  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  handleScroll({scrollTop, innerHeight}){
    clearTimeout(this.debounce);
    this.debounce=setTimeout(()=>{
      this.setState({firstShowedElement: Math.floor((scrollTop/MARKET_ITEM_ROW_HEIGHT))});
    }, 10);
  }
  render() {
    const {activeWidget, widget: {favourites, w, expanded, exchange}, updateActiveWidget} = this.props;
    const {list: data, isLoading} = this.state;
    const {onlyFavourites, filter, base, firstShowedElement, pageSize} = this.state;

    let sortedData=this.sortData(data)
        .filter((item) => item.symbol && item.symbol.toLowerCase().indexOf(filter.toLowerCase()) >= 0 &&
            (onlyFavourites && favourites ? favourites.indexOf(item.symbol) >= 0 : true));

    sortedData=sortedData
        .filter(filterCoinFn(base));

    const paginatedSortedData=sortedData
        .slice(firstShowedElement, firstShowedElement+pageSize);
    return (
      <div className="widgetWrapper">
        <WidgetToolbar
          {...this.props}
          filterMarkets={this.filterMarkets}
        />
        <div className={styles.favourites}>
          <WidgetCoinFilter
            onChange={(base)=> {
              this.setState({base});
            }}/>
        </div>
        <div className="marketsWidget OnlyMarkets disableDrag">
          <table>
            <thead>
              <tr>
                {FIELDS.filter((field) => field.width <= w || expanded).map((field) => (
                  <th
                    key={field.name}
                    className={field.key}
                    onClick={() => this.handleSort(field.name)}
                  >
                    {` ${field.name}`}
                    {this.drawSorter(field)}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <PerfectScrollbar
            className="table-body"
            onScrollY={(e)=>this.handleScroll(e)}
            options={{suppressScrollX: true, maxScrollbarLength: 100, minScrollbarLength: 100}}
          >
            <div style={{height: MARKET_ITEM_ROW_HEIGHT*firstShowedElement}} />
            {!isLoading ? (
              <table style={{height: (MARKET_ITEM_ROW_HEIGHT*(sortedData.length-firstShowedElement))}}>
                <tbody>
                  {paginatedSortedData
                      .map((item) => {
                        return (
                          <MarketItem
                            widgetId={this.props.widget.i}
                            activeWidget={activeWidget}
                            addToFavourites={this.addToFavourites}
                            exchange={exchange}
                            favourites={favourites}
                            fields={FIELDS.filter((field) => field.width <= w || expanded)}
                            item={item}
                            key={`${item.symbol}`}
                            updateActiveWidget={updateActiveWidget}
                          />
                        );
                      })
                  }
                </tbody>
              </table>
            ) : (
              <Loader/>
            )}
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

MarketsWidget.propTypes = {
  activeWidget: PropTypes.object,
  filter: PropTypes.string,
  getMarketSummaries: PropTypes.func,
  updateActiveWidget: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
};

MarketsWidget.defaultProps = {
  activeWidget: undefined,
  filter: "",
  getMarketSummaries: () => false,
  updateActiveWidget: () => false,
  widget: {},
};

export default MarketsWidget;
