import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  Modal, ModalBody, ModalHeader, FormGroup, Input,
  Nav, NavItem, NavLink, TabContent, TabPane, Col,
} from "reactstrap";
import InputColor from "react-input-color";
import classNames from "classnames";
import Select from "../../../components/Select";
import styles from "./ChartSettingsModal.scss";

const chartSettings = [
  {
    id: "type", type: "select", value: "Candlestick",
    values: ["Candlestick"], // "pointFigure"
  },
];

class ChartSettingsModal extends Component {
  constructor() {
    super();

    this.state = {
      type: "Candlestick",
      activeTab: "1",
    };
  }

  componentDidUpdate(prevProps) {
    const {isOpen, widget} = this.props;
    if (!prevProps.isOpen && isOpen) {
      this.setState({
        ...this.state,
        ...widget.options,
      });
    }
  }

  toggleTab = (tab) => {
    this.state.activeTab !== tab && this.setState({
      activeTab: tab,
    });
  };

  handleSaveChartSettings = () => {
    const {toggleModal, updateWidget, widget} = this.props;
    updateWidget({
      ...widget,
      options: this.state,
    });
    toggleModal("chartSettings");
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  drawInput = (option) => {
    switch (option.type) {
      case "color":
        return (
          <FormGroup row key={option.id}>
            <Col sm={4}>
              <Input
                type="text"
                onChange={(value) => this.handleChange(option.id, value)}
                value={this.state[option.id] || option.value}
              />
            </Col>
            <Col className="colorInput" sm={2}>
              <InputColor
                value={this.state[option.id]}
                defaultValue={option.value}
                onChange={(value) => this.handleChange(option.id, value)}
              />
            </Col>
          </FormGroup>
        );
      case "select":
        return (
          <FormGroup row key={option.id}>
            <Col sm={3}>
              <Select
                value={this.state[option.id] || option.value}
                onChange={(event) => this.handleChange(option.id, event.target.value)}
              >
                {option.values.map((value) => (
                  <option key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Col>
          </FormGroup>
        );
      default:
        return (
          <FormGroup row key={option.id}>
            <Col sm={3}>
              <Input
                type={option.type}
                value={this.state[option.id] || option.value}
                onChange={(event) => this.handleChange(option.id, event.target.value)}
              />
            </Col>
          </FormGroup>
        );
    }
  };

  render() {
    const {isOpen, toggleModal} = this.props;
    const { activeTab } = this.state;

    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={() => toggleModal("chartSettings")}
        className={classNames("chart-settings-modal", styles.moveable)}
        size="lg"
      >
        <ModalHeader toggle={() => toggleModal("chartSettings")}>
          Chart <span>& Settings</span>
        </ModalHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({active: this.state.activeTab === "1"})}
              onClick={() => this.toggle("1")}
            >
              Parameters
            </NavLink>
          </NavItem>
        </Nav>
        <ModalBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="wrapper">
                <FormGroup row>
                  <Col sm={12}>
                    <div className="TextTitle">
                      <span>Chart Type</span>
                      {chartSettings.map((item) => this.drawInput(item))}
                    </div>
                  </Col>
                </FormGroup>
              </div>
            </TabPane>
          </TabContent>
          <button
            className="SaveButton"
            onClick={this.handleSaveChartSettings}
            type="button"
          >
            Save
          </button>
          <button
            className="CancelButton"
            onClick={() => toggleModal("chartSettings")}
            type="button"
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    );
  }
}

ChartSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
};

ChartSettingsModal.defaultProps = {
  isOpen: false,
  toggleModal: () => false,
  widget: {},
};

export default ChartSettingsModal;
