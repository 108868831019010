import {
  GET_USERS_SUCCESS,
  GET_CHANNELS_SUCCESS,
  GET_PUBLIC_CHANNELS_SUCCESS,
  GET_ROOT_CHANNELS_SUCCESS,
  GET_CHANNEL_SUCCESS,
  GET_MESSAGES_SUCCESS,
  SET_CHANNEL,
  MESSAGE_SENT,
  GET_MESSAGES,
  MESSAGE_CREATED,
  MESSAGE_DELETED,
  MESSAGE_UPDATED,
  MESSAGE_DELETED_SOCKET,
  MESSAGE_UPDATED_SOCKET,
  SET_MESSAGE_FOR_EDITING,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_STATUS_UPDATED,
  USER_TYPING,
  URL_FETCH_SUCCESS,
  URL_FETCH,
  parseMessage,
} from "../constants/ChatConstants";
import {notify} from "../utils/notify";
import {unionBy} from "lodash";

const initialState = {
  userStatusChanged: false,
  userTyping: [],
  editingMessage: null,
  hasNewItems: [],
  users: [],
  urlsData: [],
  channels: [],
  publicChannels: [],
  rootChannels: [],
  messages: [],
  members: [],
  channel: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
      };
    case GET_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action.data,
      };
    case GET_CHANNEL_SUCCESS:
      return {
        ...state,
        channel: action.data,
      };
    case GET_PUBLIC_CHANNELS_SUCCESS:
      return {
        ...state,
        publicChannels: action.data,
      };
    case GET_ROOT_CHANNELS_SUCCESS:
      return {
        ...state,
        rootChannels: action.data,
      };
    case SET_CHANNEL:
      return {
        ...state,
        channel: action.data,
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: state.messages[0] && state.messages[0].channel !== state.channel ? action.data : unionBy([
          ...action.data,
          ...state.messages,
        ], "_id").filter((item) => !item.isTemp),
        totalMessages: action.total,
      };
    case GET_MESSAGES:
      return {
        ...state,
        hasNewItems: state.hasNewItems.filter((item) => item.channel !== state.channel),
        userTyping: [],
      };
    case MESSAGE_SENT:
      const existChannel = state.channels.find((item) => item._id === action.payload.channel);
      if (existChannel) {
        notify(parseMessage(action.payload.message), {
          body: "New message",
          icon: `/img/favicon.ico`,
        });
        const existNewItems = state.hasNewItems.find((item) => item.channel === action.payload.channel);
        return {
          ...state,
          hasNewItems: existNewItems ? state.hasNewItems.map((item) => item.channel === action.payload.channel ? ({
            ...item,
            count: item.count + 1,
          }) : item) : [
            ...state.hasNewItems,
            {
              channel: action.payload.channel,
              count: 1,
            },
          ],
        };
      }
      return {
        ...state,
      };
    case MESSAGE_CREATED:
      return {
        ...state,
        messages: [...state.messages, {
          _id: action.id,
          body: action.message,
          user: action.user,
          created: new Date(),
          burnTimeout: action.burnTimeout,
          createdDate: action.createdDate,
          isTemp: action.isTemp,
        }],
      };
    case MESSAGE_DELETED:
      return {
        ...state,
        messages: state.messages.filter((item) => item._id !== action.id),
      };
    case MESSAGE_UPDATED:
      return {
        ...state,
        messages: state.messages.map((item) => item._id === action.id ? ({
          ...item,
          body: action.message.body,
          burnTimeout: action.message.burnTimeout,
          updatedDate: new Date(),
        }) : item),
      };
    case MESSAGE_DELETED_SOCKET:
      return {
        ...state,
        messages: state.messages.filter((item) => item._id !== action.payload.message),
      };
    case MESSAGE_UPDATED_SOCKET:
      const channelExist = state.channels.find((item) => item._id === action.payload.channel);
      if (channelExist) {
        return {
          ...state,
          hasNewItems: [
            ...state.hasNewItems,
            action.payload.channel,
          ],
        };
      }
      return {
        ...state,
      };
    case SET_MESSAGE_FOR_EDITING:
      return {
        ...state,
        editingMessage: action.message,
      };
    case USER_LOGGED_IN:
    case USER_LOGGED_OUT:
      return {
        ...state,
        userStatusChanged: true,
      };
    case USER_STATUS_UPDATED:
      return {
        ...state,
        userStatusChanged: action.status,
      };
    case URL_FETCH:
      return {
        ...state,
        urlsData: [
          ...state.urlsData,
          {url: action.url},
        ],
      };
    case URL_FETCH_SUCCESS:
      return {
        ...state,
        urlsData: state.urlsData.map((item) => item.url === action.data.url ? action.data : item),
      };
    case USER_TYPING:
      if (state.channels.find((item) => item._id === state.channel && item._id === action.payload.channel)) {
        return {
          ...state,
          userTyping: action.payload.isTyping ? [
            ...state.userTyping,
            action.payload.user,
          ] : state.userTyping.filter((item) => item !== action.payload.user),
        };
      }
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
