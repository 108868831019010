/* eslint-disable */
import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import { hfillDef, plotDef, fillDef, hlineDef, areaDef, vp } from "./defaults";
import { toScreen } from '../CanvasChart/coordinates';
import {cloneDeep} from "lodash";

class Indicator extends Component {
  constructor() {
    super();
    this.state={
      currentItem: null
    };
  }
  componentDidMount(){
  }
  componentDidUpdate(prevProps, prevState){
    if (prevProps.cursorData != this.props.cursorData) {
      const currentItem = this.getCurrentItem();
      this.setState({
        currentItem
      });
    }
  }
  getStrokeDasharrayCanvas = (type) => {
    var a = this.getStrokeDasharray(type).split(",");

    if (a.length === 1) return [];

    return a.map(function (d) {
      return Number(d);
    });
  }
  getStrokeDasharray = (type) => {
    switch (type) {
      default:
      case "Solid":
        return "none";
      case "ShortDash":
        return "6, 2";
      case "ShortDash2":
        return "6, 3";
      case "ShortDot":
        return "2, 2";
      case "ShortDashDot":
        return "6, 2, 2, 2";
      case "ShortDashDotDot":
        return "6, 2, 2, 2, 2, 2";
      case "Dot":
        return "2, 6";
      case "Dash":
        return "6, 6";
      case "LongDash":
        return "16, 6";
      case "DashDot":
        return "8, 6, 2, 6";
      case "LongDashDot":
        return "16, 6, 2, 6";
      case "LongDashDotDot":
        return "16, 6, 2, 6, 2, 6";
    }
  };
  drawLineSeries = (plotIdx, style) => {
    let strokeColor = style && style.strokeColor ? style.strokeColor : plotDef.style.strokeColor;
    let strokeWidth = style && style.strokeWidth ? style.strokeWidth : plotDef.style.strokeWidth;
    let strokeType = style && style.strokeType ? style.strokeType : plotDef.style.strokeType;
    let strokeLabel = style && style.strokeLabel;

    const {chartView, quotes, moveY, chartOffset} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    const data = quotes.data;
    const dataLen = data.length;
    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;
    ctx.lineCap = "round";
    ctx.lineWidth = strokeWidth;
    ctx.setLineDash(this.getStrokeDasharrayCanvas(strokeType));
    for(let i = 0; i < dataLen; ++i) {
      const value = data[i][plotIdx];
      const preValue = i === 0 ? data[i][plotIdx] : data[i - 1][plotIdx];
      // const xStart = boxContent[0] + (i-2.5) * stickLength + left - moveY * 2;
      // let xEnd = boxContent[0] + (i-1.5) * stickLength + left - moveY * 2;
      const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 3)) * stickLength) - moveY * 2;
      let xEnd = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 2)) * stickLength) - moveY * 2;

      let strokeColorFn = strokeColor;
      let newStrokeColor = strokeColor;
      let colors = style.colors;
      let preCandle = data[i - 1] || {};
      let Candle = data[i];
      let nextCandle = data[i + 1] || {};
      if (typeof strokeColorFn == "function"){
        newStrokeColor = strokeColorFn(preCandle, Candle, nextCandle, plotIdx, colors);
      } else if (strokeColorFn.slice(0, 9) == 'funcStyle') {
        strokeColorFn = strokeColorFn.slice(9);
        const colorFunc = eval(strokeColorFn);
        newStrokeColor = colorFunc(preCandle, Candle, nextCandle, plotIdx, colors);
      }

      ctx.strokeStyle = newStrokeColor;
      ctx.beginPath();
      ctx.moveTo(
        xStart,
        toScreen(preValue, boxContent[3], quotes.min, quotes.max) + boxContent[1]
      );
      ctx.lineTo(
        xEnd,
        toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]
      );
      ctx.stroke();
    }
    // Draw Label
    if (strokeLabel) {
      const xStart = boxContent[0] + (dataLen - 1 - 2.5) * stickLength + left - moveY * 2;
      const value = data[dataLen - 1][plotIdx];
      const ypos = toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1];
      ctx.fillText(strokeLabel, xStart, ypos);
    }
  }
  drawFill = (style, fillUpperIdx, fillLowerIdx) => {
    const {chartView, quotes, moveY, drawList, chartOffset} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    let fillBetweenColor = style && style.fillBetweenColor ? style.fillBetweenColor : fillDef.style.fillBetweenColor;
    let fillCrossColor = style && style.fillCrossColor ? style.fillCrossColor : fillDef.style.fillCrossColor;

    const data = quotes.data;
    const dataLen = data.length;
    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;
    // Between Area
    // top line below
    ctx.save();
    ctx.beginPath();
    // let xStart = boxContent[0] - 2.5 * stickLength + left - moveY * 2;
    let xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen + 2.5) * stickLength) - moveY * 2;
    ctx.moveTo(xStart, toScreen(data[0][fillUpperIdx], boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    for(let i = 1; i < dataLen; ++i) {
      const value = data[i][fillUpperIdx];
      // const xStart = boxContent[0] + (i - 1.5) * stickLength + left - moveY * 2;
      const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 1.5)) * stickLength) - moveY * 2;
      ctx.lineTo(xStart, toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    }
    // let lastX = boxContent[0] + (dataLen - 1 - 1.5) * stickLength + left - moveY * 2;
    let lastX = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (1 + 1.5) * stickLength) - moveY * 2;
    ctx.lineTo(lastX, boxContent[3]);
    ctx.lineTo(xStart, boxContent[3]);
    ctx.closePath();
    ctx.clip();

    // bottom line above
    // xStart = boxContent[0] - 2.5 * stickLength + left - moveY * 2;
    xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen + 2.5) * stickLength) - moveY * 2;
    ctx.beginPath();
    ctx.moveTo(xStart, toScreen(data[0][fillLowerIdx], boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    for(let i = 1; i < dataLen; ++i) {
      const value = data[i][fillLowerIdx];
      // const xStart = boxContent[0] + (i - 1.5) * stickLength + left - moveY * 2;
      const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 1.5)) * stickLength) - moveY * 2;
      ctx.lineTo(xStart, toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    }
    // lastX = boxContent[0] + (dataLen - 1 - 1.5) * stickLength + left - moveY * 2;
    lastX = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (1 + 1.5) * stickLength) - moveY * 2;
    ctx.lineTo(lastX, boxContent[0]);
    ctx.lineTo(xStart, boxContent[0]);
    ctx.closePath();

    ctx.fillStyle=fillBetweenColor;
    ctx.fill();
    ctx.restore();
    // Cross Area
    // top line above
    ctx.save();
    ctx.beginPath();
    // xStart = boxContent[0] - 2.5 * stickLength + left - moveY * 2;
    xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen + 2.5) * stickLength) - moveY * 2;
    ctx.moveTo(xStart, toScreen(data[0][fillUpperIdx], boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    for(let i = 1; i < dataLen; ++i) {
      const value = data[i][fillUpperIdx];
      // const xStart = boxContent[0] + (i - 1.5) * stickLength + left - moveY * 2;
      const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 1.5)) * stickLength) - moveY * 2;
      ctx.lineTo(xStart, toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    }
    // lastX = boxContent[0] + (dataLen - 1 - 1.5) * stickLength + left - moveY * 2;
    lastX = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (1 + 1.5) * stickLength) - moveY * 2;
    ctx.lineTo(lastX, boxContent[0]);
    ctx.lineTo(xStart, boxContent[0]);
    ctx.closePath();
    ctx.clip();

    // bottom line below
    // xStart = boxContent[0] - 2.5 * stickLength + left - moveY * 2;
    xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen + 2.5) * stickLength) - moveY * 2;
    ctx.beginPath();
    ctx.moveTo(xStart, toScreen(data[0][fillLowerIdx], boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    for(let i = 1; i < dataLen; ++i) {
      const value = data[i][fillLowerIdx];
      // const xStart = boxContent[0] + (i - 1.5) * stickLength + left - moveY * 2;
      const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 1.5)) * stickLength) - moveY * 2;
      ctx.lineTo(xStart, toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    }
    // lastX = boxContent[0] + (dataLen - 1 - 1.5) * stickLength + left - moveY * 2;
    lastX = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (1 + 1.5) * stickLength) - moveY * 2;
    ctx.lineTo(lastX, boxContent[3]);
    ctx.lineTo(xStart, boxContent[3]);
    ctx.closePath();

    ctx.fillStyle=fillCrossColor;
    ctx.fill();

    ctx.restore();
  };
  drawhFill = (updatedDrawingStyles, ele) => {
    let top = ele.top;
    let bottom = ele.bottom;
    let style = updatedDrawingStyles ? updatedDrawingStyles : ele.style;
    let fillColor = style && style.fillColor ? style.fillColor : hfillDef.style.fillColor;

    const {chartView, quotes, moveY, drawList} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    const data = quotes.data;
    const dataLen = data.length;
    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;
    const xStart = boxContent[0] - 2.5 * stickLength + left - moveY * 2;
    const xEnd = boxContent[2];

    ctx.fillStyle = fillColor;
    ctx.beginPath();
    ctx.moveTo(xStart, toScreen(bottom, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    ctx.lineTo(xEnd, toScreen(bottom, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    ctx.lineTo(xEnd, toScreen(top, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    ctx.lineTo(xStart, toScreen(top, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    ctx.closePath();
    ctx.fill();
  };
  drawhline = (updatedDrawingStyles, ele) => {
    let style = updatedDrawingStyles ? updatedDrawingStyles : ele.style;
    let strokeWidth = style && style.strokeWidth ? style.strokeWidth : hfillDef.style.strokeWidth;
    let strokeColor = style && style.strokeColor ? style.strokeColor : hfillDef.style.strokeColor;
    let strokeType = style && style.strokeType ? style.strokeType : hfillDef.style.strokeType;
    const value = ele.value;

    const {chartView, quotes, moveY, drawList} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;
    const xStart = boxContent[0] - 2.5 * stickLength + left - moveY * 2;
    const xEnd = boxContent[2];

    ctx.setLineDash(this.getStrokeDasharrayCanvas(strokeType));

    // ctx.strokeStyle = strokeBorder ? strokeColor : "rgba(0, 0, 0, 0)";
    ctx.strokeStyle = strokeColor;
    ctx.lineWidth = strokeWidth;
    ctx.beginPath();
    ctx.moveTo(xStart, toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    ctx.lineTo(xEnd, toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]);
    ctx.closePath();
    ctx.stroke();
  };
  drawArea = (style, plotIdx) => {
    const {chartView, quotes, moveY, chartOffset} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    let baseLine =  style && style.baseLine ? style.baseLine : areaDef.style.baseLine;
    let strokeColor = style && style.strokeColor ? style.strokeColor : areaDef.style.strokeColor;

    const data = quotes.data;
    const dataLen = data.length;
    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;

    for(let i = 0; i < dataLen; ++i) {
      const value = data[i][plotIdx];
      const nextValue = data[i + 1] ? data[i + 1][plotIdx] : value;

      let strokeColorFn = strokeColor;
      let newStrokeColor = strokeColor;
      let colors = style.colors;
      let preCandle = data[i - 1] || {};
      let Candle = data[i];
      let nextCandle = data[i + 1] || {};
      if (typeof strokeColorFn == "function"){
        newStrokeColor = strokeColorFn(preCandle, Candle, nextCandle, plotIdx, colors);
      } else if (strokeColorFn.slice(0, 9) == 'funcStyle') {
        strokeColorFn = strokeColorFn.slice(9);
        const colorFunc = eval(strokeColorFn);
        newStrokeColor = colorFunc(preCandle, Candle, nextCandle, plotIdx, colors);
      }

      // let xStart = boxContent[0] + (i - 2.5) * stickLength + left - moveY * 2;
      // let xEnd = boxContent[0] + (i - 1.5) * stickLength + left - moveY * 2;
      const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 3)) * stickLength) - moveY * 2;
      const xEnd = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 2)) * stickLength) - moveY * 2;

      ctx.fillStyle = newStrokeColor;
      ctx.beginPath();
      ctx.moveTo(
        xStart,
        toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]
      );
      ctx.lineTo(
        xEnd,
        toScreen(nextValue, boxContent[3], quotes.min, quotes.max) + boxContent[1]
      );
      ctx.lineTo(
        xEnd,
        toScreen(baseLine, boxContent[3], quotes.min, quotes.max) + boxContent[1]
      );
      ctx.lineTo(
        xStart,
        toScreen(baseLine, boxContent[3], quotes.min, quotes.max) + boxContent[1]
      );
      ctx.closePath();
      ctx.fill();
    }
  }
  drawCircleSeries = (plotIdx, style) => {
    let strokeColor = style && style.strokeColor ? style.strokeColor : plotDef.style.strokeColor;
    let strokeWidth = style && style.strokeWidth ? style.strokeWidth : plotDef.style.strokeWidth;

    const {chartView, quotes, moveY, chartOffset} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    const data = quotes.data;
    const dataLen = data.length;
    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;

    ctx.lineWidth = strokeWidth;
    for(let i = 0; i < dataLen; ++i) {
      const value = data[i][plotIdx];

      let strokeColorFn = strokeColor;
      let newStrokeColor = strokeColor;
      let colors = style.colors;
      let preCandle = data[i - 1] || {};
      let Candle = data[i];
      let nextCandle = data[i + 1] || {};
      if (typeof strokeColorFn == "function"){
        newStrokeColor = strokeColorFn(preCandle, Candle, nextCandle, plotIdx, colors);
      } else if (strokeColorFn.slice(0, 9) == 'funcStyle') {
        strokeColorFn = strokeColorFn.slice(9);
        const colorFunc = eval(strokeColorFn);
        newStrokeColor = colorFunc(preCandle, Candle, nextCandle, plotIdx, colors);
      }
      ctx.strokeStyle = newStrokeColor;
      let xEnd = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 2)) * stickLength) - moveY * 2;

      ctx.beginPath();
      ctx.arc(
        xEnd,
        toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1],
        2, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.closePath();
    }
  }
  drawHistogram = (plotIdx, style) => {
    const {chartView, quotes, moveY, chartOffset} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    const data = quotes.data;
    const dataLen = data.length;
    const stickLength = chartView.stickLength;
    const stickMargin = chartView.stickMargin;
    let left = boxContent[2] % chartView.stickLength;
    const colors = style.colors;
    for(let i = 0; i < dataLen; ++i) {
      const value = data[i][plotIdx];
      // const xStart = boxContent[0] + (i-2) * stickLength + stickMargin + left - moveY * 2;
      // const xEnd = boxContent[0] + (i-1) * stickLength - stickMargin + left - moveY * 2;
      const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 3)) * stickLength) + stickMargin - moveY * 2;
      const xEnd = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 2)) * stickLength) - stickMargin - moveY * 2;

      const boxStick = [
        xStart,
        toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1],
        xEnd - xStart,
        toScreen(0, boxContent[3], quotes.min, quotes.max) + boxContent[1] - toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1],
      ];
      let strokeColorFn = style.strokeColor;
      let newStrokeColor = style.strokeColor;
      let colors = style.colors;
      let preCandle = data[i - 1] || {};
      let Candle = data[i];
      let nextCandle = data[i + 1] || {};
      if (typeof strokeColorFn == "function"){
        newStrokeColor = strokeColorFn(preCandle, Candle, nextCandle, plotIdx, colors);
      } else if (strokeColorFn.slice(0, 9) == 'funcStyle') {
        strokeColorFn = strokeColorFn.slice(9);
        const colorFunc = eval(strokeColorFn);
        newStrokeColor = colorFunc(preCandle, Candle, nextCandle, plotIdx, colors);
      }
      ctx.fillStyle = newStrokeColor;
      ctx.beginPath();
      ctx.fillRect(...boxStick);
    }
  }
  drawSupDemZone = (data, style) => {
    const {chartView, quotes, moveY, chartOffset} = this.props;
    const ctx = chartView.ctx;
    const demandColor = style && style.demandColor;
    const supplyColor = style && style.supplyColor;
    const activeLineOpacity = style && style.activeZoneLineOpacity;
    const activeAreaOpacity = style && style.activeZoneAreaOpacity;
    const brokenLineOpacity = style && style.brokenZoneLineOpacity;
    const brokenAreaOpacity = style && style.brokenZoneAreaOpacity;
    const lineWidth = style && style.lineWidth;
    const extendZones = style && style.extendZones;

    const boxContent = chartView.geometry.boxPrice.content;
    let {zones, fullData, plotData} = data
    let barIndex = 0
    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;
    let stickIntervalMs = fullData[1].timestamp - fullData[0].timestamp;
    if (zones.length == 0){
      return;
    }
    let endBar = fullData[fullData.length - 1];
    if (!plotData) {
      return;
    }
    const quotesData = quotes.data;
    const dataLen = quotesData.length;
    for ( let i in zones ){
      let zone = zones[i];
      let zb = zone.zb;
      let ze = zone.ze;
      let zl = zone.zl;
      let zh = zone.zh;
      let za = zone.za;
      let yPos = [toScreen(zl, boxContent[3], quotes.min, quotes.max) + boxContent[1],
      toScreen(zh, boxContent[3], quotes.min, quotes.max) + boxContent[1]]
      let x1 = 0;
      let x2 = 0;
      let xStart = 0;
      let xEnd = 0;
      if (barIndex == 0) {
        x1 = zb;
        x2 = (za == false) ? ze : endBar;
        x2 = (za == true && extendZones) ? endBar : x2;
        let startStick = (x1.timestamp - quotes.data[0].timestamp) / stickIntervalMs;
        let endStick = (x2.timestamp - quotes.data[0].timestamp) / stickIntervalMs;
        xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (startStick - 2.5)) * stickLength) - moveY * 2;
        xEnd = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (endStick - 2.5)) * stickLength) - moveY * 2;
      }
      if (x2 < x1){
        continue;
      }
      let fillOpacity = 1;
      let strokeOpacity = 1;
      if (za == true){
        fillOpacity = activeAreaOpacity;
        strokeOpacity = activeLineOpacity;
      } else {
        fillOpacity = brokenAreaOpacity;
        strokeOpacity = brokenLineOpacity;
      }
      let color = "rgba(0, 255, 0, 1)";
      if (zone.zt == "s"){
        color = supplyColor;
      } else {
        color = demandColor;
      }
      let colorOpacity = color.substring(color.lastIndexOf(',') + 1, color.length - 1);
      ctx.fillStyle = color.substring(0, color.lastIndexOf(',') + 1) + fillOpacity*colorOpacity + ")";
      ctx.strokeStyle = color.substring(0, color.lastIndexOf(',') + 1) + strokeOpacity*colorOpacity + ")";
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.rect(xStart, yPos[0], xEnd - xStart, yPos[1] - yPos[0]);
      ctx.stroke();
      ctx.closePath();
      ctx.fill();
    }
  }
  drawVP = (data, style) => {
    const {chartView, quotes} = this.props;
    const ctx = chartView.ctx;
    const upVolColor = style ? style.upVolColor : vp.style.upVolColor;
    const dnVolColor = style ? style.dnVolColor : vp.style.dnVolColor;
    const totalVolColor = style ? style.totalVolColor : vp.style.totalVolColor;
    const vaUpVolColor = style ? style.vaUpVolColor : vp.style.vaUpVolColor;
    const vaDnVolColor = style ? style.vaDnVolColor : vp.style.vaDnVolColor;
    const vaTotalVolColor = style ? style.vaTotalVolColor : vp.style.vaTotalVolColor;
    const width = style ? style.width : vp.style.width;
    const placement = style ? style.placement : vp.style.placement;
    const volType = style ? style.volType : vp.style.volType;
    const vaVolPct = style ? style.vaVolPct : vp.style.vaVolPct;
    // const showPOC = style ? style.showPOC : vp.style.showPOC;
    const showPOC = true;
    const pocColor = style ? style.pocColor : vp.style.pocColor;

    let volSum = 0;
    let maxVol = 0;
    let pocIdx = 0;
    for (let i = 0; i < data.length; i++){
      volSum += data[i].totalVolume;
      pocIdx = maxVol < data[i].totalVolume ? parseInt(i) : pocIdx;
      maxVol = Math.max(maxVol, data[i].totalVolume);
    }
    let vaVolSumResult = parseFloat(vaVolPct) >= 100 ? volSum : volSum * parseFloat(vaVolPct) / 100;
    let aboveIdx = pocIdx;
    let beneathIdx = pocIdx;
    let vaVolSum = maxVol;
    if (volSum > 0){
      if (parseFloat(vaVolPct) < 100){
        while (vaVolSum < vaVolSumResult){
          let aboveFstVol = data[aboveIdx+1] ? data[aboveIdx+1].totalVolume : 0;
          let aboveSndVol = data[aboveIdx+2] ? data[aboveIdx+2].totalVolume : 0;
          let aboveSumVol = aboveFstVol + aboveSndVol;
          let beneathFstVol = data[beneathIdx-1] ? data[beneathIdx-1].totalVolume : 0;
          let beneathSndVol = data[beneathIdx-2] ? data[beneathIdx-2].totalVolume : 0;
          let beneathSumVol = beneathFstVol + beneathSndVol;
          if (aboveFstVol == 0 && aboveSndVol==0 && beneathFstVol==0 && beneathSndVol==0){
            break;
          }
          if (aboveSumVol > beneathSumVol){
            aboveIdx += 2;
            vaVolSum += aboveSumVol;
          } else {
            beneathIdx -= 2;
            vaVolSum += beneathSumVol;
          }
        }
      } else {
        beneathIdx = 0;
        aboveIdx = data.length - 1;
      }
    }
    const boxContent = chartView.geometry.boxPrice.content;
    const maxVolWidth = boxContent[2] * parseFloat(width) / 100;  //eg: 30%
    const volWidthRate = maxVolWidth / maxVol;

    for (let i = 0; i < data.length; i++){
      const item = data[i];
      const yBottom = toScreen(item.rangeStart, boxContent[3], quotes.min, quotes.max) + boxContent[1];
      const yTop = toScreen(item.rangeEnd, boxContent[3], quotes.min, quotes.max) + boxContent[1];
      let newUpVolColor = undefined;
      let newDnVolColor = undefined;
      let newTotalVolColor = undefined;
      if ( i >= beneathIdx && i <= aboveIdx) {
        newUpVolColor = vaUpVolColor;
        newDnVolColor = vaDnVolColor;
        newTotalVolColor = vaTotalVolColor;
      } else {
        newUpVolColor = upVolColor;
        newDnVolColor = dnVolColor;
        newTotalVolColor = totalVolColor;
      }
      if (placement == 'Right'){
        const xBearStart = boxContent[2] - volWidthRate * item.totalVolume;
        const xBullStart = boxContent[2] - volWidthRate * item.bullishVolume;
        const xEnd = boxContent[2];
        if (volType == 'Up & Down Volume'){
          ctx.fillStyle = newDnVolColor;
          ctx.fillRect(xBearStart, yTop, xBullStart - xBearStart, yBottom - yTop);
          ctx.strokeStyle = newDnVolColor;
          ctx.strokeRect(xBearStart, yTop, xBullStart - xBearStart, yBottom - yTop);
          ctx.fillStyle = newUpVolColor;
          ctx.fillRect(xBullStart, yTop, xEnd - xBullStart, yBottom - yTop);
          ctx.strokeStyle = newUpVolColor;
          ctx.strokeRect(xBullStart, yTop, xEnd - xBullStart, yBottom - yTop);
        } else {
          ctx.fillStyle = newTotalVolColor;
          ctx.fillRect(xBearStart, yTop, boxContent[2] - xBearStart, yBottom - yTop);
          ctx.strokeStyle = newTotalVolColor;
          ctx.strokeRect(xBearStart, yTop, boxContent[2] - xBearStart, yBottom - yTop);
        }
      } else {
        if (volType == 'Up & Down Volume'){
          const xBearStart = volWidthRate * item.bullishVolume;
          const bearWidth = volWidthRate * item.bearishVolume;
          ctx.fillStyle = newDnVolColor;
          ctx.fillRect(xBearStart, yTop, bearWidth, yBottom - yTop);
          ctx.strokeStyle = newDnVolColor;
          ctx.strokeRect(xBearStart, yTop, bearWidth, yBottom - yTop);
          ctx.fillStyle = newUpVolColor;
          ctx.fillRect(0, yTop, xBearStart, yBottom - yTop);
          ctx.strokeStyle = newUpVolColor;
          ctx.strokeRect(0, yTop, xBearStart, yBottom - yTop);
        } else {
          ctx.fillStyle = newTotalVolColor;
          ctx.fillRect(0, yTop, volWidthRate * item.totalVolume, yBottom - yTop);
          ctx.strokeStyle = newTotalVolColor;
          ctx.strokeRect(0, yTop, volWidthRate * item.totalVolume, yBottom - yTop);
        }
      }
      if (showPOC && i == pocIdx) {
        const pocY = (yBottom + yTop) / 2;
        ctx.strokeStyle = pocColor;
        ctx. getStrokeDasharray
        ctx.beginPath();
        ctx.setLineDash(this.getStrokeDasharrayCanvas('Dash'));
        ctx.moveTo(0, pocY);
        ctx.lineTo(boxContent[2], pocY);
        ctx.stroke();
        ctx.closePath();
      }
    }
  }
  drawSwing = (Series, style, idx) => {
    let strokeColor = style && style.strokeColor ? style.strokeColor : plotDef.style.strokeColor;
    let strokeWidth = style && style.strokeWidth ? style.strokeWidth : plotDef.style.strokeWidth;
    let strokeType = style && style.strokeType ? style.strokeType : plotDef.style.strokeType;
    const {highSeries, lowSeries} = Series;
    const seriesSum = highSeries.length + lowSeries.length;
    const {chartView, quotes, moveY, chartOffset} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    const ctx = chartView.ctx;

    const data = quotes.data;
    const dataLen = data.length;
    const stickLength = chartView.stickLength;
    let left = boxContent[2] % chartView.stickLength;
    ctx.lineCap = "round";
    ctx.lineWidth = strokeWidth;
    ctx.setLineDash(this.getStrokeDasharrayCanvas(strokeType));
    ctx.strokeStyle = strokeColor;
    for (let j = 0; j < seriesSum; j++){
      const plotIdx = idx + j;
      for(let i = 0; i < dataLen; ++i) {
        const value = data[i][plotIdx];
        // const xStart = boxContent[0] + (i-1.5) * stickLength + left - moveY * 2;
        // let xEnd = boxContent[0] + (i-0.5) * stickLength + left - moveY * 2;
        const xStart = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 2.5)) * stickLength) - moveY * 2;
        let xEnd = boxContent[2] - ((chartOffset > 0 ? chartOffset : 0) * stickLength + (dataLen - (i - 1.5)) * stickLength) - moveY * 2;

        ctx.beginPath();
        ctx.moveTo(
          xStart,
          toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]
        );
        ctx.lineTo(
          xEnd,
          toScreen(value, boxContent[3], quotes.min, quotes.max) + boxContent[1]
        );
        ctx.stroke();
        ctx.closePath();
      }
    }
  }
  drawChart = () => {
    const {indicator, drawList, chartView} = this.props;
    let plotCnt = -1;
    let fillCnt = -1;
    if (indicator.editable){
      const updatedDrawingStyles = indicator.updatedDrawingStyles;
      return (

        <Fragment>
          {
            drawList.map((ele, drawIdx)=>{
              const ctx = chartView.ctx;
              ctx.save();
              const boxContent = chartView.geometry.boxPrice.content;
              ctx.beginPath();
              ctx.rect(boxContent[0], boxContent[1], boxContent[2] - boxContent[0], boxContent[3] - boxContent[1]);
              ctx.closePath();
              ctx.clip();
              let style = updatedDrawingStyles && updatedDrawingStyles[drawIdx] ? updatedDrawingStyles[drawIdx] : ele.style;
              if (ele.type == 'plot'){
                plotCnt++;
                let name = ele.name;
                let showLast = style && style.showLast ? true : false;
                let strokeType = style && style.strokeType ? style.strokeType : plotDef.style.strokeType;
                let plotIdx = 'plot_' + indicator.name + indicator.idx + plotCnt;
                let plotColorIdx = 'plotColor_' + indicator.name + indicator.idx + plotCnt;
                if (strokeType == "Circle"){
                  // console.log("circle")
                  this.drawCircleSeries(plotIdx, style);
                } else if (strokeType == "Area"){
                  // console.log("area")
                  this.drawArea(style, plotIdx);
                } else if (strokeType == "Histogram"){
                  // console.log("histogram")
                  this.drawHistogram(plotIdx, style);
                } else {
                  // console.log("line series")
                  this.drawLineSeries(plotIdx, style);
                }
              } else if (ele.type == 'fill'){
                // console.log("fill")
                fillCnt++;
                let fillUpperIdx = 'fill_upper_' + indicator.name + indicator.idx + fillCnt;
                let fillLowerIdx = 'fill_lower_' + indicator.name + indicator.idx + fillCnt;

                this.drawFill(style, fillUpperIdx, fillLowerIdx);
              } else if (ele.type == 'hfill'){
                // console.log("hfill")
                this.drawhFill(updatedDrawingStyles && updatedDrawingStyles[drawIdx], ele);
              } else if (ele.type == 'hline'){
                // console.log("hline")
                this.drawhline(updatedDrawingStyles && updatedDrawingStyles[drawIdx], ele);
              } else if (ele.type == 'volumeprofile'){
                // console.log("volumeprofile")
                this.drawVP(ele.data, style);
              } else if (ele.type == 'supdemzone'){
                // console.log("supdemzone")
                this.drawSupDemZone(ele.data, style);
              } else if (ele.type == 'swinghighlow'){
                // console.log("swinghighlow")
                let plotIdx = 'plot_' + indicator.name + indicator.idx;
                this.drawSwing(ele.data, style, plotIdx);
              }
              ctx.restore();
            })
          }
        </Fragment>)
    }
  }

  getCurrentItem = () => {
    const {chartView, cursorData, quotes, moveY} = this.props;
    const x = cursorData[0][0];
    const y = cursorData[0][1];
    const boxPrice = chartView.geometry.boxPrice.content;

    let left = boxPrice[2] % chartView.stickLength;
    const stickNumber = Math.round(
      (x - left - chartView.stickLength / 2 + moveY * 2) / (chartView.stickLength)
    ) + 2;
    const intervalms = quotes.data[quotes.data.length - 1].timestamp - quotes.data[quotes.data.length - 2].timestamp;
    const xValue = quotes.data[stickNumber] ?
      quotes.data[stickNumber] :
      {
        timestamp: (stickNumber - quotes.data.length + 1) * intervalms + quotes.data[quotes.data.length - 1].timestamp,
      };

    return xValue;
  }

  getTooltipLabel = () => {
    const {indicator: {id, editable, name, options}} = this.props;

    if (editable){
      const paramInputs = Object.keys(options)
        .filter((key) => options[key] && options[key].type && options[key].type.indexOf("style") == -1);

      let label = "(";
      for ( let i in paramInputs ){
        let key = paramInputs[i];
        let ele = cloneDeep(options[key]);
        if (ele.value === false) {ele.value = ""}
        if (ele.value === true) {ele.value = ""}
        else if (ele.value != "" && ele.value!==undefined) {
          label += ele.value + ', ';
        }
      }
      label =label.slice(0, -2)+ '): ';
      return `${name} ${label}`;
      // console.log(`${fullName} ${label}`);
    }
  }

  drawTooltip = () => {
    const {currentItem} = this.state;
    let {indicator, indicatorSeqIdx, handleToggleModal, drawList, chartView} = this.props;
    const boxContent = chartView.geometry.boxPrice.content;
    let plots = [];
    if (indicator.editable){
      plots = drawList.filter((item)=> item.type == 'plot');
    }

    const updatedDrawingStyles = indicator.updatedDrawingStyles;
    const formatCash = n => {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
    };
    let style = updatedDrawingStyles && updatedDrawingStyles[0] ? updatedDrawingStyles[0] : (plots[0] ? plots[0].style : plotDef.style);
    let strokeColor = style && style.strokeColor ? style.strokeColor : plotDef.style.strokeColor;
    let strokeColorFn = strokeColor;
    let fill = strokeColor;
    if (typeof strokeColorFn == "function" || strokeColorFn.slice(0, 9) == 'funcStyle'){
      fill = plotDef.style.strokeColor;
    }
    return (
      <div
        style={{
          lineHeight: "1",
          position: 'absolute',
          width: boxContent[2] / 2,
          top: indicator.separated ? 0 : (indicatorSeqIdx + 1) * 15 + 'px',
          zIndex: 1,
          pointerEvents: 'none'
        }}
      >
        <svg
          width="100%"
          height="13"
        >
          <rect
            width="10"
            height="10"
            fill={fill}
          />
          <text
            x="15"
            dominantBaseline="hanging"
          >
            <tspan
              fill='#7e8591'
              fontSize="10px"
              alignmentBaseline="hanging"
              style={{cursor: 'url("/img/icons/pointer.png")  10 5, auto'}}
              pointerEvents="all"
              onClick={() => {
                handleToggleModal("indicatorSettings", {indicator, drawList});
              }}
            >
              {this.getTooltipLabel()}
            </tspan>
            {
              plots && plots.map((item, idx)=>{
                let style = updatedDrawingStyles && updatedDrawingStyles[idx] ? updatedDrawingStyles[idx] : item.style;
                let strokeColor = style && style.strokeColor ? style.strokeColor : plotDef.style.strokeColor;
                let strokeColorFn = strokeColor;
                let fill = strokeColor;
                if (typeof strokeColorFn == "function" || strokeColorFn.slice(0, 9) == 'funcStyle'){
                  fill = plotDef.style.strokeColor;
                }
                return (
                  <tspan
                    key={idx}
                    fill={strokeColor}
                    fontSize="10px"
                    alignmentBaseline="hanging"
                    fillOpacity="1"
                  >
                    {currentItem && currentItem['plot_'+ indicator.name + indicator.idx +idx] ?
                      formatCash(parseFloat(currentItem['plot_'+ indicator.name + indicator.idx +idx])) : ""}
                    {" "}
                  </tspan>
                );
              })
            }
          </text>
        </svg>
      </div>);
  }
  render() {
    const {drawList, chartView} = this.props;
    return (
      <Fragment>
        { chartView && drawList && this.drawChart()}
        { chartView && drawList && this.drawTooltip()}
      </Fragment>
    );
  }
}
Indicator.propTypes = {
  chartOffset: PropTypes.number,
  chartView: PropTypes.object,
  cursorData: PropTypes.array,
  quotes: PropTypes.object,
  moveY: PropTypes.number,
  indicator: PropTypes.object,
  indicatorSeqIdx: PropTypes.number,
  drawList: PropTypes.array,
  handleToggleModal: PropTypes.func,
  widget: PropTypes.object,
}

export default Indicator;
