import {
  GET_ORDERS,
  GET_ORDERS_ERROR,
  GET_ORDERS_SUCCESS,
  GET_ORDER_HISTORY,
  GET_ORDER_HISTORY_SUCCESS,
  GET_ORDER_HISTORY_ERROR,
  SET_ORDER,
  ADD_ORDER,
  REMOVE_ORDER,
  UPDATE_ORDER,
  CANCEL_ORDER_SUCCESS,
  UPDATE_TEMP_ORDER,
  CLEAR_TEMP_ORDERS,
  ADD_TEMP_ORDER,
  REMOVE_TEMP_ORDER,
} from "../const";

const initialState = {
  choosedOrder: null,
  orders: {
    loading: false,
    data: [],
    error: null,
  },
  orderHistory: {
    loading: false,
    data: [],
    error: null,
  },
  tempOrders: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true,
          error: null,
        },
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        choosedOrder: action.payload.data[0],
        orders: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case GET_ORDERS_ERROR:
      return {
        ...state,
        orders: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    case SET_ORDER:
      return {
        ...state,
        choosedOrder: action.order,
      };
    case ADD_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: [...state.orders.data, action.order],
        },
      };
    case REMOVE_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: state.orders.data.filter((order) => order.OrderUuid !== state.choosedOrder.OrderUuid),
        },
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: state.orders.data.map((order) => (order.OrderUuid === action.order.OrderUuid) ? action.order : order),
        },
      };
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: state.orders.data[0].OrderUuid ?
            state.orders.data.filter((order) => order.OrderUuid !== state.choosedOrder.OrderUuid) :
            state.orders.data.map((account) => ({
              ...account,
              list: account.list.filter((order) => order.OrderUuid !== action.order),
            })),
        },
      };
    case GET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          loading: true,
          error: null,
        },
      };
    case GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistory: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case GET_ORDER_HISTORY_ERROR:
      return {
        ...state,
        orderHistory: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    case CLEAR_TEMP_ORDERS:
      return {
        ...state,
        tempOrders: [],
      };
    case REMOVE_TEMP_ORDER:
      return {
        ...state,
        tempOrders: state.tempOrders.filter((order) => order.id !== action.orderId),
      };
    case ADD_TEMP_ORDER:
      const orderExists = state.tempOrders.find((order) => {
        return order.name === action.order.name && order.exchange === action.order.exchange;
      });
      return {
        ...state,
        tempOrders: orderExists ? state.tempOrders : [
          ...state.tempOrders,
          action.order,
        ],
      };
    case UPDATE_TEMP_ORDER:
      return {
        ...state,
        tempOrders: state.tempOrders.map((order) => {
          return order.id === action.order.id ? {
            ...order,
            ...action.order,
          } : order;
        }),
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
