import {connect} from "react-redux";
import ChartWidget from "../components/ChartWidget/ChartWidget";

import {cancelOrderAction, updateTempOrder, removeTempOrder} from "../../../actions/OrdersActions";
import {saveImageAction} from "../../../actions/ImageActions";
import {
  getChartData, getLatestChartData, setWidgetActive, updateWidget, toggleModal, openTradeWidget, getPriceData,
  getPrevChartData, getExchangeMarketsAction, getLatestMTFChartData, getMTFInitChartData, getAvailableTFAction,
  unsubscribeLatestTick
} from "../../../actions/MarketsActions";

import {defaultActiveOrder} from "../../../constants/OrdersConstants";

export default connect(
    (state, props) => {
      const mappedOrders = [];
      const mappedHistoryOrders = [];
      const {height, width, index, id, resizingWidgetId} = props;

      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === id) : null;
      const splittedSymbol = widget?.name?.split("-")??[];
      const symbol = `${splittedSymbol[1]}/${splittedSymbol[0]}`;
      const drawingTemplates = state.user.drawingTemplates;

      state.orders.orders.data
          .filter((account) => account.exchange.toLowerCase() === widget.exchange.toLowerCase())
          .forEach((account) => {
            widget && account.list.filter((order) => order.symbol === symbol).forEach((order) => {
              mappedOrders.push({
                ...defaultActiveOrder,
                accountName: account.name,
                symbol: order.symbol,
                draggable: false,
                yValue: order.price,
                id: order.id,
                bgFill: order.side === "buy" ? "#6BA583" : "#DB0000",
                text: `${order.side.toUpperCase()} [LIMIT] ${order.amount}`,
                side: order.side,
                edge: {
                  ...defaultActiveOrder.edge,
                  fill: order.side === "buy" ? "#6BA583" : "#DB0000",
                },
              });
            });
          });

      state.orders.orderHistory.data
          .filter((account) => account.exchange.toLowerCase() === widget.exchange.toLowerCase())
          .forEach((account) => {
            widget && account.list.filter((order) => order.symbol === symbol).forEach((order) => {
              mappedHistoryOrders.push({
                ...order,
                accountName: account.name,
              });
            });
          });

      const tempOrder = state.orders.tempOrders.find((order) => {
        return order.name === widget.name && order.exchange === widget.exchange;
      });

      return {
        activeWidget: activeWidget,
        drawingTemplates,
        exchangeMarkets: state.markets.exchangeMarkets,
        height,
        width,
        index,
        resizingWidgetId,
        imageSaving: state.image.loading,
        orders: mappedOrders,
        orderHistory: mappedHistoryOrders,
        tempOrder: tempOrder || {},
        widget: widget,
      };
    }, {
      getAvailableTFAction,
      getChartData,
      getExchangeMarketsAction,
      getPriceData,
      getLatestChartData,
      getLatestMTFChartData,
      getMTFInitChartData,
      getPrevChartData,
      cancelOrderAction,
      setWidgetActive,
      updateWidget,
      toggleModal,
      saveImageAction,
      updateTempOrder,
      removeTempOrder,
      openTradeWidget,
      unsubscribeLatestTick
    },
)(ChartWidget);
