import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FontAwesome from "./FontAwesome";

import styles from "./Table.scss";

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortKey: props.fields.length > 0 ? props.fields[0].key : null,
      sortType: "asc",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.sortKey && this.props.fields.length > 0) {
      this.setState({
        sortKey: this.props.fields[0].key,
      });
    }
  }

  handleSort = (key) => {
    const {sort} = this.props;
    const {sortKey, sortType} = this.state;
    let newSortKey;
    let newSortType;

    if (!sortKey || sortKey !== key) {
      newSortKey = key;
      newSortType = "asc";
    } else if (sortKey && sortType === "asc") {
      newSortKey = sortKey;
      newSortType = "desc";
    } else if (sortKey && sortType === "desc") {
      newSortKey = sortKey;
      newSortType = "asc";
    }
    this.setState({
      sortKey: newSortKey,
      sortType: newSortType,
    });
    sort(newSortKey, newSortType);
  };

  render() {
    const {fields, children, className} = this.props;
    const {sortKey, sortType} = this.state;

    return (
      <table className={classNames(styles.Table, className)}>
        <thead>
          <tr>
            {fields.map((field) => (
              <th
                className={classNames({[styles.Clickable]: !field.disableSort})}
                key={field.key}
                onClick={() => !field.disableSort && this.handleSort(field.key)}
              >
                {`${field.label} `}
                {!field.disableSort && (
                  <span className="fa-layers">
                    <FontAwesome
                      name="chevron-up"
                      dataFaTransform="up-6"
                      style={{opacity: sortKey === field.key && sortType === "asc" ? 1 : .5}}
                    />
                    <FontAwesome
                      name="chevron-down"
                      dataFaTransform="down-6"
                      style={{opacity: sortKey === field.key && sortType === "desc" ? 1 : .5}}
                    />
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  fields: PropTypes.array,
  sort: PropTypes.func,
};

Table.defaultProps = {
  children: null,
  className: "",
  fields: [],
  sort: () => false,
};

export default Table;
