import {connect} from "react-redux";
import TokenFinderModal from "../components/DeFiScannerWidget/pages/tokenFinderModal";
import {toggleModal} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      return {
        isOpen: state.markets.modals.tokenFinder,
        widget: activeWidget,
      };
    }, {
      toggleModal,
    },
)(TokenFinderModal);
