// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3d1-CDK2_PyUSXwEj0tJSJ{height:100%}._3a5plPC8anKw5qlKm3yw1F{height:85%;width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}._30gff-S_iX60gRsFJR795h span{color:#333740 !important;font-size:70px !important;font-weight:700;line-height:1}._105E81etp-mD9P71Rjfr0j{font-size:38px;font-weight:700;color:#333740}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/ChartWidget/CanvasChart/Watermark.scss"],"names":[],"mappings":"AACE,yBACE,WAAA,CAGF,yBACE,UAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAIA,8BACE,wBAAA,CACA,yBAAA,CACA,eAAA,CACA,aAAA,CAIJ,yBACE,cAAA,CACA,eAAA,CACA,aAAA","sourcesContent":[":local {\n  .watermarkwrapper {\n    height: 100%;\n  }\n\n  .watermark {\n    height: 85%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .tickerlabel{\n    span {\n      color: #333740 !important;\n      font-size: 70px !important;\n      font-weight: 700;\n      line-height: 1;\n    }\n  }\n\n  .longlabel{\n    font-size: 38px;\n    font-weight: 700;\n    color: #333740;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"watermarkwrapper": "_3d1-CDK2_PyUSXwEj0tJSJ",
	"watermark": "_3a5plPC8anKw5qlKm3yw1F",
	"tickerlabel": "_30gff-S_iX60gRsFJR795h",
	"longlabel": "_105E81etp-mD9P71Rjfr0j"
};
export default ___CSS_LOADER_EXPORT___;
