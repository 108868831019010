import React from "react";
import styles from "./Loader.scss";

const Loader = () => {
  return (
    <div className={styles.CustomLoader}>
      <p>
        Loading
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </p>
    </div>
  );
};

export default Loader;
