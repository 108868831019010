export default function scaleGrid(view, quotes, priceLines, timeLines, moveY) {
  drawPriceScaleGrid(view.ctx, priceLines, quotes.min, quotes.range, view);
  drawTimeScaleGrid(view.ctx, timeLines, view, moveY);
}

function drawPriceScaleGrid(ctx, scaleValues, priceMin, priceRange, chartView) {
  const priceBox = chartView.geometry.boxPrice.padding;
  const priceBoxContent = chartView.geometry.boxPrice.content;
  const style = chartView.style;
  const ratio = priceBoxContent[3] / priceRange;

  for (const scaleValue of scaleValues) {
    const screenY = priceBoxContent[1] + priceBoxContent[3] - (scaleValue - priceMin) * ratio;

    ctx.strokeStyle = style.colorGrid;
    ctx.beginPath();
    ctx.moveTo(priceBox[0], screenY);
    ctx.lineTo(
        priceBox[0] + priceBox[2],
        screenY
    );
    ctx.stroke();
  }
}

function drawTimeScaleGrid(ctx, scaleValues, chartView, moveY) {
  const boxPrice = chartView.geometry.boxPrice.padding;
  const boxContent = chartView.geometry.boxPrice.content;
  const boxVolume = chartView.geometry.boxVolume ? chartView.geometry.boxVolume.padding : null;

  const style = chartView.style;
  const stickLength = chartView.stickLength;
  const stickMargin = chartView.stickMargin;
  const left = boxContent[2] % stickLength;
  for (let i = 0; i < scaleValues.length; ++i) {
    const verticalLine = scaleValues[i];
    ctx.strokeStyle = style.colorGrid;
    const xStart = boxContent[0] + (verticalLine[0] - 2) * stickLength + stickMargin - moveY * 2 + left;
    let xEnd = boxContent[0] + (verticalLine[0] - 1) * stickLength - stickMargin - moveY * 2 + left;
    if (xEnd < xStart) {
      xEnd = xStart;
    }
    const xMid = (xEnd - xStart) / 2;
    const drawingStickBegin = xStart + xMid;
    if (drawingStickBegin > boxContent[2]){
      continue;
    }
    ctx.beginPath();
    ctx.moveTo(drawingStickBegin, boxPrice[1]);
    if (boxVolume) {
      ctx.lineTo(drawingStickBegin, boxVolume[1] + boxVolume[3]);
    } else {
      ctx.lineTo(drawingStickBegin, boxPrice[1] + boxPrice[3]);
    }
    ctx.stroke();
  }
}
