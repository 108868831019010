import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  TMP_SECRET,
  TMP_SECRET_SUCCESS,
  TMP_SECRET_ERROR,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  SETTINGS_UPDATED,
  UPDATE_DRAWING,
  UPDATE_INDICATOR_TEMPLATE
} from "../const";

const initialState = {
  user: {
    loading: false,
    data: null,
    errors: [],
  },
  displayError: false,
  tmpSecretImg: undefined,
  tmpSecretKey: undefined,
  tokenValid: false,
  drawingTemplates: {},
  indicatorTemplates: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: {
          data: null,
          loading: true,
          errors: [],
        },
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          loading: false,
          data: action.result,
          errors: [],
        },
      };
    case GET_USER_ERROR:
      return {
        ...state,
        user: {
          loading: false,
          data: null,
          errors: action.error,
        },
      };
    case TMP_SECRET:
    case TMP_SECRET_ERROR:
      return {
        ...state,
        tmpSecretImg: undefined,
        tmpSecretKey: undefined,
      };
    case TMP_SECRET_SUCCESS:
      return {
        ...state,
        tmpSecretImg: action.tmpSecretImg,
        tmpSecretKey: action.tmpSecretKey,
      };
    case LOGOUT:
      return {
        ...state,
        displayError: false,
        user: {
          ...state.user,
          loading: true,
        },
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        displayError: false,
        user: {
          loading: false,
          data: null,
          errors: [],
        },
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          errors: action.error,
        },
        displayError: action.error,
      };
    case LOGIN:
      return {
        ...state,
        displayError: false,
        user: {
          loading: true,
          data: null,
          errors: [],
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        displayError: false,
        user: {
          loading: false,
          data: action.result,
          errors: [],
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        user: {
          loading: false,
          data: null,
          errors: action.error,
        },
        displayError: action.error,
      };
    case SIGNUP:
      return {
        ...state,
        displayError: false,
        user: {
          loading: true,
          data: null,
          errors: [],
        },
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: {
          loading: false,
          data: action.result,
          errors: [],
        },
        displayError: action.error,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        user: {
          loading: false,
          data: null,
          errors: action.error,
        },
        displayError: action.error,
      };
    case CHECK_TOKEN:
      return {
        ...state,
        tokenValid: false,
      };
    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        tokenValid: true,
      };
    case CHECK_TOKEN_ERROR:
      return {
        ...state,
        tokenValid: false,
        displayError: action.error,
      };
    case UPDATE_DRAWING:
      const templates = action.templates;
      return {
        ...state,
        drawingTemplates: templates
      };
    case UPDATE_INDICATOR_TEMPLATE:
      const indicatorTemplates = action.templates;
      return {
        ...state,
        indicatorTemplates
      };
    case SETTINGS_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            settings: action.settings,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
