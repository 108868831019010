import {uid} from "uid";

export const GET_MARKETS_LIST_SUCCESS = "getMarketsListRes";
export const GET_MARKET_HISTORY = "GET_MARKET_HISTORY";
export const GET_MARKET_HISTORY_SUCCESS = "getMarketHistoryRes";
export const GET_MARKET_HISTORY_TRADE_SUCCESS = "subscribeMarketHistoryRes";
export const GET_MARKET_DOM = "GET_MARKET_DOM";
export const CLEAR_MARKET_HISTORY = "CLEAR_MARKET_HISTORY";
export const GET_MARKET_DOM_SUCCESS = "getMarketDomRes";
export const GET_MARKET_DOM_SNAPSHOT_SUCCESS = "subscribeMarketDomSnapshotRes";
export const GET_MARKET_DOM_UPDATE_SUCCESS = "subscribeMarketDomUpdateRes";
export const GET_CHART_DATA_SUCCESS = "getTicksV2Res";
export const GET_MTF_INIT_CHART_DATA_SUCCESS = "getMTFInitChartDataRes";
export const GET_LATEST_CHART_DATA_SUCCESS = "getLatestTickV2Res";
export const GET_LATEST_MTF_CHART_DATA_SUCCESS = "getLatestMTFTickV2Res";
export const GET_PREV_CHART_DATA_SUCCESS = "getPrevTickV2Res";
export const GET_MARKET_DATA_SUCCESS = "getMarketSummariesRes";
export const GET_PRICE_DATA_SUCCESS = "getPriceDataRes";

export const GET_EXCHANGES = "GET_EXCHANGES";
export const GET_EXCHANGES_SUCCESS = "GET_EXCHANGES_SUCCESS";
export const GET_EXCHANGES_ERROR = "GET_EXCHANGES_ERROR";

export const GET_CHART_DATA = "GET_CHART_DATA";
export const GET_MTF_INIT_CHART_DATA = "GET_MTF_INIT_CHART_DATA";
export const GET_AVAILABLE_TFS = "GET_AVAILABLE_TFS";
export const ADD_WORKSHEET = "ADD_WORKSHEET";
export const CHANGE_WORKSHEET = "CHANGE_WORKSHEET";
export const REMOVE_WORKSHEET = "REMOVE_WORKSHEET";
export const UPDATE_WORKSHEETS = "UPDATE_WORKSHEETS";
export const ADD_WIDGET = "ADD_WIDGET";
export const REMOVE_WIDGET = "REMOVE_WIDGET";
export const UPDATE_WIDGET = "UPDATE_WIDGET";
export const SET_WIDGET_ACTIVE = "SET_WIDGET_ACTIVE";
export const GET_EXCHANGE_MARKETS = "GET_EXCHANGE_MARKETS";
export const GET_EXCHANGE_MARKETS_SUCCESS = "GET_EXCHANGE_MARKETS_SUCCESS";
export const GET_EXCHANGE_MARKETS_ERROR = "GET_EXCHANGE_MARKETS_ERROR";
export const GET_MARKETS = "GET_MARKETS";
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS";
export const GET_MARKETS_ERROR = "GET_MARKETS_ERROR";
export const GET_MARKETS_INFO_SUCCESS = "GET_MARKETS_INFO_SUCCESS";
export const GET_MARKET_SUMMARY = "GET_MARKET_SUMMARY";
export const GET_MARKET_SUMMARY_SUCCESS = "GET_MARKET_SUMMARY_SUCCESS";
export const GET_MARKET_SUMMARY_ERROR = "GET_MARKET_SUMMARY_ERROR";
export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const SENTIMENT_DATA = "getNewsSentimentRes";

export const ROWS = 10;
export const COLS = 8;

export const ACTIVE_WIDGETS = ["chart", "price", "sentiment", "trade"];

export const DEFAULT_WIDGET = {
  i: uid(10),
  active: true,
  type: undefined,
  name: "",
  period: "day",
  exchange: "all",
  data: [],
  indicators: [], // for chart widget type
  favourites: [], // for market widet type
  loading: false,
  expanded: false,
  options: undefined,
  splitHeightsPercentage: [100], //100% height for widget itself, other heights belong to indicators
  x: 0,
  y: 1,
  w: 2,
  h: 2,
  maxH: COLS,
  maxW: ROWS,
};

export const DEFAULT_WORKSHEET = {
  id: uid(10),
  name: "Worksheet 1",
  widgets: [{
    ...DEFAULT_WIDGET,
    i: uid(10),
    active: false,
    type: "markets",
    name: "markets",
    exchange: "binance",
    h: COLS,
    w: 2,
    x: 0,
  }, {
    ...DEFAULT_WIDGET,
    i: uid(10),
    active: true,
    w: ROWS - 2,
    h: COLS / 2,
    x: 2,
    type: "chart",
    name: "USDT-BTC",
    exchange: "binance",
    mtf_data: [],
    availableTF: [],
    basePeriod: {
      from: {value: "day", min: 1440},
      to: {value: "day", min: 1440}
    },
  }],
};

export const INTERVALS = [
  {divider: "Minutes", type: "divider", value: "mins"},
  {fullname: "1 minute", id: "1m", name: "m1", value: "oneMin", min: 1},
  {fullname: "5 minutes", id: "5m", name: "m5", value: "fiveMin", min: 5},
  {fullname: "10 minutes", id: "10m", name: "m10", value: "tenMin", min: 10},
  {fullname: "15 minutes", id: "15m", name: "m15", value: "fifteenMin", min: 15},
  {fullname: "30 minutes", id: "30m", name: "m30", value: "thirtyMin", min: 30},
  {fullname: "45 minutes", id: "45m", name: "m45", value: "fourtyFiveMin", min: 45},
  {divider: "Hours", type: "divider", value: "hours"},
  {fullname: "1 hour", id: "1h", name: "h1", value: "hour", min: 60},
  {fullname: "2 hours", id: "2h", name: "h2", value: "twoHours", min: 120},
  {fullname: "4 hours", id: "4h", name: "h4", value: "fourHours", min: 240},
  {fullname: "6 hours", id: "6h", name: "h6", value: "sixHours", min: 360},
  {divider: "Days", type: "divider", value: "days"},
  {fullname: "1 day", id: "1d", name: "d1", value: "day", min: 1440},
  {fullname: "1 week", id: "1w", name: "w1", value: "week", min: 10080},
  {fullname: "1 month", id: "1M", name: "M1", value: "month", min: 40320},
];

export const exchanges = {
  'poloniex': {
    id: 'poloniex',
    active: true,
    trades: true,
    ob_l2_snap: false,
    ob_l2_update: 1           // 1: broadcast snapshot at start
  },
  'binance': {
    id: 'binance',
    active: false,
    trades: true,
    ob_l2_snap: true,
    ob_l2_update: 2,          // 2: broads snapshot at start by REST API
    depth: 20
  },
  'binanceje': {
    id: 'binanceje',
    active: false,
    trades: true,
    ob_l2_snap: true,
    ob_l2_update: 2,
    depth: 20
  },
  'bittrex': {
    id: 'bittrex',
    active: false,
    trades: true,            //error
    ob_l2_snap: false,
    ob_l2_update: 1,
  },
  'bitfinex': {
    id: 'bitfinex',
    active: false,
    trades: true,
    ob_l2_snap: false,
    ob_l2_update: 1,
  },
  'okex': {                 //error
    id: 'okex',
    active: false,
    trades: true,
    ob_l2_snap: true,
    ob_l2_update: 1,
    depth: 5
  },
  'bitstamp': {
    id: 'bitstamp',
    active: false,
    trades: true,
    ob_l2_snap: true,
    ob_l2_update: 2,
    depth: 100
  },
  'huobi': {
    id: 'huobi',
    active: false,
    trades: true,
    ob_l2_snap: true,
    ob_l2_update: 0,
    depth: 150
  },
  'upbit': {
    id: 'upbit',
    active: false,
    trades: true,
    ob_l2_snap: true,
    ob_l2_update: 0,
    depth: 15
  },
  'zb': {
    id: 'zb',
    active: false,
    trades: true,
    ob_l2_snap: true,
    ob_l2_update: 0,
    depth: 50
  },
  'bitmex': {
    id: 'bitmex',
    active: true,
    trades: true,
    ob_l2_snap: false,
    ob_l2_update: 1,
  },
  'cex': {
    id: 'cex',
    active: true,
    trades: true,                  //error
    ob_l2_snap: false,
    ob_l2_update: 1,               // no response
  },
  'coinbasepro': {
    id: 'coinbasepro',
    active: true,
    trades: true,
    ob_l2_snap: false,
    ob_l2_update: 1,
  },
  'hitbtc2': {
    id: 'hitbtc2',
    active: true,
    trades: true,
    ob_l2_snap: false,
    ob_l2_update: 1,
  },
  'coinex': {
    id: 'coinex',
    active: true,
    trades: true,
    ob_l2_snap: false,
    ob_l2_update: 1,
  },
};
export const OHLCHistoryMaxTicks={
  binance: 500,
  binancecoinm: 200,
  binanceus: 500,
  binanceusdm: 500,
  bitfinex: 100,
  digifinex: 270,
  ftx: 106,
  ftxus: 45,
  gateio: 500,
  gemini: 250,
  hitbtc: 100,
  huobi: 500,
  huobijp: 500,
  kraken: 500,
  kucoin: 500,
  okex: 100,
  poloniex: 496,
  upbit: 61,
  zb: 150,
};
