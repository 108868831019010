import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import classNames from "classnames";
//import FontAwesome from "../../../components/FontAwesome";
import styles from "./tokenFinderModal.scss";

class tokenFinderModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps) {
  }

  render() {
    const {isOpen, toggleModal} = this.props;

    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={() => toggleModal("tokenFinder")}
        className={classNames("feed-modal", styles.moveable)}
        size="lg"
      >
        <ModalHeader
          className={styles.Header}
          toggle={() => toggleModal("tokenFinder")}
        >
          Swap <span>& Analysis</span>
        </ModalHeader>
        <ModalBody className={styles.ModalBody}>

          <div className={styles.scrollbar}>
            <div className={styles.recently}>
              <div className={styles.title}>Recently used</div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

tokenFinderModal.propTypes = {
  addWidget: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  widget: PropTypes.object,
  updateWidget: PropTypes.func.isRequired,
};

tokenFinderModal.defaultProps = {
  addWidget: () => false,
  isOpen: false,
  toggleModal: () => false,
  widget: undefined,
};

export default tokenFinderModal;
