// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2BKcBqCTTryXJPzgGo2IMP ._3pfmsybp-Tz8Gqqj1OYQSk{border:none;padding:2px}._2BKcBqCTTryXJPzgGo2IMP table{width:100%}._2BKcBqCTTryXJPzgGo2IMP table thead,._2BKcBqCTTryXJPzgGo2IMP table tbody{width:100%;display:inline-table}._2BKcBqCTTryXJPzgGo2IMP table thead tr td,._2BKcBqCTTryXJPzgGo2IMP table thead tr th,._2BKcBqCTTryXJPzgGo2IMP table tbody tr td,._2BKcBqCTTryXJPzgGo2IMP table tbody tr th{width:25%;text-overflow:ellipsis;white-space:nowrap;font-size:12px}._2BKcBqCTTryXJPzgGo2IMP table thead tr:nth-child(even),._2BKcBqCTTryXJPzgGo2IMP table tbody tr:nth-child(even){background:rgba(51,51,51,.5)}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/TradeWidget/Orders.scss"],"names":[],"mappings":"AAII,kDACE,WAAA,CACA,WAAA,CAGF,+BACE,UAAA,CAEA,0EACE,UAAA,CACA,oBAAA,CAGE,4KACE,SAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAGF,gHACE,4BAAA","sourcesContent":["@import \"../../../../styles/constants.scss\";\n\n:local {\n  .Orders {\n    .Cancel {\n      border: none;\n      padding: 2px;\n    }\n\n    table {\n      width: 100%;\n\n      thead, tbody {\n        width: 100%;\n        display: inline-table;\n\n        tr {\n          td, th {\n            width: 25%;\n            text-overflow: ellipsis;\n            white-space: nowrap;\n            font-size: 12px;\n          }\n\n          &:nth-child(even) {\n            background: rgba(51, 51, 51, 0.5);\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Orders": "_2BKcBqCTTryXJPzgGo2IMP",
	"Cancel": "_3pfmsybp-Tz8Gqqj1OYQSk"
};
export default ___CSS_LOADER_EXPORT___;
