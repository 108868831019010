/* eslint max-len: 0 */
import {uid} from "uid";
import {ALERT_TYPES} from "../const";
import {
  ACTIVE_WIDGETS,
  ADD_WIDGET,
  ADD_WORKSHEET,
  CHANGE_WORKSHEET,
  COLS,
  DEFAULT_WIDGET,
  DEFAULT_WORKSHEET,
  GET_AVAILABLE_TFS,
  GET_CHART_DATA,
  GET_CHART_DATA_SUCCESS,
  GET_EXCHANGE_MARKETS,
  GET_EXCHANGE_MARKETS_ERROR,
  GET_EXCHANGE_MARKETS_SUCCESS,
  GET_EXCHANGES,
  GET_EXCHANGES_ERROR,
  GET_EXCHANGES_SUCCESS,
  GET_MARKET_HISTORY,
  GET_MARKET_SUMMARY,
  GET_MARKET_SUMMARY_ERROR,
  GET_MARKET_SUMMARY_SUCCESS,
  GET_MARKETS,
  GET_MARKETS_ERROR,
  GET_MARKETS_INFO_SUCCESS,
  GET_MARKETS_SUCCESS,
  GET_MTF_INIT_CHART_DATA,
  GET_PREV_CHART_DATA_SUCCESS, INTERVALS, OHLCHistoryMaxTicks,
  REMOVE_WIDGET,
  REMOVE_WORKSHEET,
  ROWS,
  SET_WIDGET_ACTIVE,
  TOGGLE_MODAL,
  UPDATE_WIDGET,
  UPDATE_WORKSHEETS,
} from "../constants/MarketsConstants";
import {getUserWorksheetsResult} from "../api/UserApi";
import {
  getChartIntervals,
  getExchangeMarkets,
  getExchangesList, getMarketsExchanges,
  getMarketsInfo,
  getMarketsList,
  getMarketSummary,
  getOHLCVHistory,
} from "../api/MarketsApi";
import {showAlert} from "../actions/CommonActions";
import {doUpdateState} from "../actions/UserAction";
import {emitTicker} from "../utils/websockets";
import {markets} from "../reducers";

const getCurrentWidgetMarket = (state) => {
  const {currentWorksheet, worksheets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const widgets = currentWorksheetData.widgets;
  const activeWidget = widgets.find((widget) => widget.active);

  return {
    market: (activeWidget && ACTIVE_WIDGETS.indexOf(activeWidget.type) >= 0 && activeWidget.name) ? activeWidget.name : false,
    exchange: (activeWidget && ACTIVE_WIDGETS.indexOf(activeWidget.type) >= 0 && activeWidget.exchange) ? activeWidget.exchange : false,
  };
};

const getAvailableTFAction = (widget) => async (dispatch, getState) => {
  const state = getState();
  const worksheetId = state.markets.currentWorksheet;
  try {
    const result = await getChartIntervals({exchange: widget.exchange});
    dispatch({
      type: GET_AVAILABLE_TFS,
      data: result.data,
      payload: {
        worksheetId,
        widgetId: widget.i,
      },
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getMarketDom = (exchange, marketSplitted) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emit("getMarketDom", {
    params: {
      market: `${marketSplitted[1]}/${marketSplitted[0]}`,
      exchange,
    },
  });
};

const subscribeMarketDomUpdate = (exchange, marketSplitted, subscribeObj) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emit("subscribeMarketDomUpdate", {
    params: {
      market: `${marketSplitted[1]}/${marketSplitted[0]}`,
      exchange,
      subscribeObj,
    },
  });
};
const subscribeMarketDomSnapshot = (exchange, marketSplitted, subscribeObj) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emit("subscribeMarketDomSnapshot", {
    params: {
      market: `${marketSplitted[1]}/${marketSplitted[0]}`,
      exchange,
      subscribeObj,
    },
  });
};

const unsubscribePreMarketDom = (exchange, subscribeObj) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emit("unsubscribePreMarketDom", {
    params: {
      exchange,
      subscribeObj,
    }
  });
};

const getMarketHistory = (update) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  const {market, exchange} = getCurrentWidgetMarket(state);
  if (!market || !exchange) {
    return false;
  }
  update && dispatch({
    type: GET_MARKET_HISTORY,
  });
  const marketSplitted = market.split("-");
  market && emit("getMarketHistory", {
    params: {
      market: `${marketSplitted[1]}/${marketSplitted[0]}`,
      exchange,
    },
  });
};
const subscribeMarketHistory = (exchange, marketSplitted, subscribeObj) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emit("subscribeMarketHistory", {
    params: {
      market: `${marketSplitted[1]}/${marketSplitted[0]}`,
      exchange,
      subscribeObj,
    },
  });
};
const unsubscribeMarketHistory = (exchange, subscribeObj) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emit("unsubscribeMarketHistory", {
    params: {
      exchange,
      subscribeObj
    }
  });
};
const getMarketSummaries = (widgetId) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  if (widgetId) {
    const {currentWorksheet, worksheets} = state.markets;
    const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
    if (!currentWorksheetData) {
      return false;
    }
    const currentWidget = currentWorksheetData.widgets.find((widget) => widget.i === widgetId);
    if (currentWidget && currentWidget.exchange && currentWidget.exchange !== "all") {
      emit("getMarketSummaries", {
        params: {
          widgetId,
          period: currentWidget && currentWidget.period,
          exchange: currentWidget.exchange,
        },
      });
    }
  } else {
    emit("getMarketSummaries");
  }
};


const getPriceData = (widgetId) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading || !widgetId) {
    return false;
  }
  const {currentWorksheet, worksheets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const currentWidget = currentWorksheetData.widgets.find((widget) => widget.i === widgetId);
  const widgetSplitted = currentWidget.name.split("-");
  emit("getPriceData", {
    params: {
      widgetId,
      symbol: currentWidget.name ? `${widgetSplitted[1]}/${widgetSplitted[0]}` : '',
      period: currentWidget && currentWidget.period,
      exchange: currentWidget && currentWidget.exchange,
    },
  });
};

const unsubscribePriceData = (exchange, symbol) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  const widgetSplitted = symbol.split("-");
  emit("unsubscribePriceData", {
    params: {
      symbol: `${widgetSplitted[1]}/${widgetSplitted[0]}`,
      exchange: exchange,
    }
  });
};

const unsubscribeLatestTick = (widget, basePeriod) => (dispatch, getState, {emit}) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emit("unsubscribeLatestTick", {
    params: {
      marketName: widget.name,
      exchange: widget.exchange,
      basePeriod: widget.basePeriod,
    }
  });
};

export const setWorksheets=(worksheets)=>(dispatch)=>{
  dispatch({
    type: UPDATE_WORKSHEETS,
    worksheets: worksheets,
  });
};

const getUserWorksheets = () => async (dispatch) => {
  try {
    const result = await getUserWorksheetsResult();
    const worksheets = result.data.length === 0 ? [DEFAULT_WORKSHEET] : result.data;

    dispatch({
      type: UPDATE_WORKSHEETS,
      worksheets: worksheets,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const setWidgetActive = (id) => (dispatch, getState) => {

  dispatch({
    type: SET_WIDGET_ACTIVE,
    widget: id,
  });
  const state = getState();
  const {currentWorksheet, worksheets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const widgets = currentWorksheetData.widgets;
  const activeWidget = widgets.find((widget) => widget.active);
  if (activeWidget.data[activeWidget.data.length-1]?.close) {
    document.title=activeWidget.marketId+" "+activeWidget.data[activeWidget.data.length-1]?.close;
  }
};

const updateActiveWidget = (market, exchange, additionalItems) => (dispatch, getState) => {

  const state = getState();
  const {currentWorksheet, worksheets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const widgets = currentWorksheetData.widgets;
  const activeWidget = widgets.find((widget) => widget.active);

  activeWidget && dispatch(updateWidget({
    ...activeWidget,
    name: market,
    exchange: exchange || activeWidget.exchange,
    ...additionalItems
  }));
};

export const updateFirstChartWidget = (market, exchange, additionalItems) => (dispatch, getState) => {

  const state = getState();
  const {currentWorksheet, worksheets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const widgets = currentWorksheetData.widgets;
  const chartWidget = widgets.find((widget) => widget.type==="chart");
  chartWidget && dispatch(updateWidget({
    ...chartWidget,
    name: market,
    exchange: exchange || chartWidget.exchange,
    ...additionalItems
  }));
};

const addWorksheet = () => (dispatch) => {
  dispatch({
    type: ADD_WORKSHEET,
  });
};

const changeWorksheet = (id) => (dispatch) => {
  dispatch({
    type: CHANGE_WORKSHEET,
    worksheet: id,
  });
};

const removeWorksheet = () => (dispatch) => {
  dispatch({
    type: REMOVE_WORKSHEET,
  });
};

const createWidget = (widgets, widget = {}) => {
  const calculateXPos = () => {
    if (!widgets || widgets.length === 0) {
      return 0;
    } else {
      const lastItem = widgets[widgets.length - 1];
      let lastItemPos = lastItem.x + lastItem.w;
      if (lastItemPos === 5) {
        lastItemPos = 0;
      }
      return lastItemPos;
    }
  };

  return {
    ...DEFAULT_WIDGET,
    ...widget,
    active: false,
    i: uid(10),
    x: calculateXPos(),
  };
};

const addWidget = (widget) => (dispatch, getState) => {
  const state = getState();
  const {worksheets, currentWorksheet} = state.markets;
  const worksheet = worksheets.find((item) => item.id === currentWorksheet);
  const existedWidgets = worksheet ? worksheet.widgets : [];

  dispatch({
    type: ADD_WIDGET,
    widget: createWidget(existedWidgets, widget),
  });
};

const openTradeWidget = (price = null, type = null) => (dispatch, getState) => {
  const state = getState();
  const {currentWorksheet, worksheets} = state.markets;
  const currentWorksheetData = worksheets.find((worksheet) => worksheet.id === currentWorksheet);
  if (!currentWorksheetData) {
    return false;
  }
  const widgets = currentWorksheetData.widgets;
  const activeWidget = widgets.find((widget) => widget.active);

  const widgetData = {
    type: "trade",
    unpinned: true,
    fX: document.body.clientWidth / 2 - 230,
    fY: document.body.offsetHeight / 2 - 400,
    fH: 580,
    fW: 450,
    name: activeWidget.name,
    exchange: activeWidget.exchange,
  };

  if (price) {
    widgetData.price = price;
  }
  if (type) {
    widgetData.orderType = type;
  }

  activeWidget && dispatch(addWidget(widgetData));
};

const removeWidget = (id) => (dispatch, getState) => {
  const state = getState();
  const {worksheets, currentWorksheet} = state.markets;
  const worksheet = worksheets.find((item) => item.id === currentWorksheet);
  const existedWidgets = worksheet ? worksheet.widgets.filter((item) => item.i !== id) : [];
  const firstPossibleWidget = existedWidgets.filter((item) => ACTIVE_WIDGETS.indexOf(item.type) >= 0)[0];

  firstPossibleWidget && dispatch(setWidgetActive(firstPossibleWidget.i));
  dispatch({
    type: REMOVE_WIDGET,
    widget: id,
  });
};

const updateStateTimeout = null;

const updateWidget = (widget) => (dispatch) => {
  if (widget.type === "price") {
    widget = {
      ...widget,
      maxH: 2,
      maxW: 1,
      w: 1,
      h: 2,
    };
  } else {
    widget = {
      ...widget,
      maxH: COLS,
      maxW: ROWS,
    };
  }

  dispatch({
    type: UPDATE_WIDGET,
    widget: widget,
  });

  if ((ACTIVE_WIDGETS.indexOf(widget.type) >= 0) && widget.name === "") {
    dispatch(toggleModal("markets"));
    dispatch({
      type: SET_WIDGET_ACTIVE,
      widget: widget.i,
    });
  } else if ((widget.type === "markets" || widget.type === "treemap") && widget.exchange === "all") {
    dispatch(toggleModal("exchanges", {
      widgetId: widget.i,
    }));
  }

  dispatch(doUpdateState());
};

const getExchangesListAction = () => async (dispatch) => {
  dispatch({
    type: GET_EXCHANGES,
  });

  try {
    const result = await getExchangesList();

    dispatch({
      type: GET_EXCHANGES_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: GET_EXCHANGES_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getMarketsListAction = (params) => async (dispatch) => {
  dispatch({
    type: GET_MARKETS,
  });

  try {
    const result = await getMarketsList(params);
    dispatch({
      type: GET_MARKETS_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MARKETS_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getExchangeMarketsAction = (params) => async (dispatch) => {
  dispatch({
    type: GET_EXCHANGE_MARKETS,
  });

  try {
    const result = await getExchangeMarkets(params);

    dispatch({
      type: GET_EXCHANGE_MARKETS_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: GET_EXCHANGE_MARKETS_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getMarketsInfoAction = () => async (dispatch) => {
  try {
    const result = await getMarketsInfo();

    dispatch({
      type: GET_MARKETS_INFO_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getMarketSummaryAction = (widgetId, market, exchange) => async (dispatch, getState) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }

  dispatch({
    type: GET_MARKET_SUMMARY,
  });

  try {
    const result = await getMarketSummary(widgetId, market, exchange);

    dispatch({
      type: GET_MARKET_SUMMARY_SUCCESS,
      market: result.data.data,
      widget: result.data.widget,
    });
  } catch (error) {
    dispatch({
      type: GET_MARKET_SUMMARY_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getChartData = (widget, period = null, update = true, date = null) => async (dispatch, getState) => {
  const dateTo=Date.now();
  let basePeriod="1m";
  const periodName=period?.from?.value ?? "day";
  const interval=INTERVALS.find(({value, type})=>value===periodName&&type!=="divider");
  basePeriod=interval.id;
  const ticks=OHLCHistoryMaxTicks[widget.exchange];
  const dateFrom=dateTo-(interval.min*60*ticks*1000);
  const state = getState();
  const worksheetId = state.markets.currentWorksheet;
  update && dispatch({
    type: GET_CHART_DATA,
    payload: {
      worksheetId,
      widgetId: widget.i,
      basePeriod: period,
    },
  });
  const result = await getOHLCVHistory({
    exchange: widget.exchange,
    marketName: widget.marketId,
    basePeriod,
    worksheetId,
    widgetId: widget.i,
    since: dateFrom
  });
  // if (widget.active){
  //   document.title=widget.marketId+" "+result.data.data[result.data.data.length-1][4];
  // }
  dispatch({
    type: GET_CHART_DATA_SUCCESS,
    payload: result.data,
  });
};

const getLatestChartData = (widget) => (dispatch, getState) => {
  const state = getState();
  if (state.image.loading) {
    return false;
  }
  emitTicker({
    id: widget.marketId,
    exchange: widget.exchange,
    widgetId: widget.i,
  });
};

//todo: remove, unused
const getMTFInitChartData = (widget, update = true) => (dispatch, getState, {emit}) => {
  const state = getState();
  const worksheetId = state.markets.currentWorksheet;

  update && dispatch({
    type: GET_MTF_INIT_CHART_DATA,
    payload: {
      worksheetId,
      widgetId: widget.i,
    },
  });

  emit("getMTFInitChartData", {
    params: {
      exchange: widget.exchange,
      marketName: widget.name,
      tickInterval: "hour",
      worksheetId,
      widgetId: widget.i,
    },
  });
};

//todo: remove, unused
const getLatestMTFChartData = (widget) => (dispatch, getState, {emit}) => {
  const state = getState();
  const worksheetId = state.markets.currentWorksheet;
  emit("getLatestMTFTickV2", {
    params: {
      marketName: widget.name,
      exchange: widget.exchange,
      tickInterval: "hour",
      worksheetId,
      widgetId: widget.i,
    },
  });
};

const getPrevChartData = (widget) => async (dispatch, getState) => {
  const dateTo=widget.data[0].timestamp;
  let basePeriod="1m";
  const periodName=widget.basePeriod?.from?.value ?? "day";
  const interval=INTERVALS.find(({value, type})=>value===periodName&&type!=="divider");
  basePeriod=interval.id;
  const dateFrom=dateTo-(interval.min*60*300*1000);
  const state = getState();
  const worksheetId = state.markets.currentWorksheet;
  const result = await getOHLCVHistory({
    exchange: widget.exchange,
    marketName: widget.marketId,
    basePeriod,
    worksheetId,
    widgetId: widget.i,
    since: dateFrom
  });
  dispatch({
    type: GET_PREV_CHART_DATA_SUCCESS,
    payload: result.data,
  });


  // const state = getState();
  // const worksheetId = state.markets.currentWorksheet;
  // const basePeriod = widget.basePeriod;
  // const result = await getOHLCVHistory({
  //   exchange: widget.exchange,
  //   marketName: widget.marketId,
  //   basePeriod,
  //   worksheetId,
  //   widgetId: widget.i,
  //   dateTo: widget.data[0].timestamp
  // });
  // dispatch({
  //   type: GET_PREV_CHART_DATA_SUCCESS,
  //   payload: result.data,
  // });
};

const toggleModal = (modal, data) => (dispatch) => {
  dispatch({
    type: TOGGLE_MODAL,
    modal: modal,
    data: data,
  });
};

export {
  getUserWorksheets,
  addWorksheet,
  changeWorksheet,
  removeWorksheet,
  createWidget,
  addWidget,
  openTradeWidget,
  removeWidget,
  updateWidget,
  getExchangesListAction,
  getExchangeMarketsAction,
  getMarketsListAction,
  getMarketsInfoAction,
  getChartData,
  getAvailableTFAction,
  getMTFInitChartData,
  getLatestChartData,
  getLatestMTFChartData,
  getPrevChartData,
  setWidgetActive,
  getMarketSummaryAction,
  updateActiveWidget,
  getMarketSummaries,
  getMarketDom,
  subscribeMarketDomUpdate,
  subscribeMarketDomSnapshot,
  unsubscribePreMarketDom,
  getMarketHistory,
  subscribeMarketHistory,
  unsubscribeMarketHistory,
  getPriceData,
  unsubscribePriceData,
  unsubscribeLatestTick,
  toggleModal,
};
