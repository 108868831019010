/* eslint react/jsx-handler-names: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import classNames from "classnames";
import Select from "react-select";
import styles from "./ChatModal.scss";

const initialState = {
  type: "join",
  modalType: undefined,
};

class ChannelModal extends Component {
  constructor() {
    super();

    this.state = initialState;
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }
  handleChange = (field, value) => {
    const data = {
      [field]: value,
    };
    this.setState(data);
  };

  handleSaveChannel = () => {
    const {joinChannelAction} = this.props;
    const {title} = this.state;

    debugger;
    joinChannelAction(title.value);
    this.closeModal();

  };

  closeModal = () => {
    this.setState(initialState);
    this.props.toggleModal("channel");
  };

  render() {
    const {isOpen, publicChannels} = this.props;
    const options = publicChannels.map((item) => ({
      value: item._id,
      label: item.title,
    }));
    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={this.closeModal}
        className={classNames(styles.ChatModal, styles.moveable)}
        size="md"
      >
        <ModalHeader
          className={styles.Header}
          toggle={this.closeModal}
        >
          Channels <span>& Hangouts</span>
        </ModalHeader>
        <ModalBody>

          <div>
            <span className={styles.title}>Find a channel to join.</span>
            <div className="chatmodal">
              <Select
                isSearchable
                isMulti
                className="OrderTypes"
                classNamePrefix="react-select"
                placeholder="Search..."
                value={this.state.title}
                optionClassName={styles.Option}
                onChange={(value) => this.handleChange("title", value)}
                options={options}
              />
            </div>
            <br/>
          </div>
          <button
            className={styles.SaveButton}
            onClick={this.handleSaveChannel}
            type="button"
          >
            Join
          </button>
          <button
            className={styles.CancelButton}
            onClick={this.closeModal}
            type="button"
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    );
  }
}

ChannelModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  publicChannels: PropTypes.array,
  joinChannelAction: PropTypes.func,
};

ChannelModal.defaultProps = {
  isOpen: false,
  toggleModal: () => false,
  publicChannels: [],
  joinChannelAction: () => false,
};

export default ChannelModal;
