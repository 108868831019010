// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._38N3Z5jxL88xaYEWUvsPQO{padding:4px 8px !important;background-color:#2a2d34 !important;color:rgba(255,255,255,.75) !important;font-weight:400}._2cRcE7P6pC-Qa3NytR1xai{height:calc(100% - 26px);width:100%;overflow:hidden}._2cRcE7P6pC-Qa3NytR1xai:hover{cursor:pointer}._2cRcE7P6pC-Qa3NytR1xai .poNghyGPogoFZnP41Lxx_{text-align:center}.bubblechart text.name,.bubblechart text.name2,.bubblechart text.price,.bubblechart text.change{fill:#fff}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/BubbleWidget/BubbleWidget.scss"],"names":[],"mappings":"AAEE,yBACE,0BAAA,CACA,mCAAA,CACA,sCAAA,CACA,eAAA,CAEF,yBACE,wBAAA,CACA,UAAA,CACA,eAAA,CACA,+BACE,cAAA,CAEF,gDACE,iBAAA,CAQF,gGACE,SAAA","sourcesContent":["@import \"../../../../styles/constants\";\n:local {\n  .buttonTooltip {\n    padding: 4px 8px !important;\n    background-color: $background-dark !important;\n    color: $white-faded !important;\n    font-weight: 400;\n  }\n  .Wrapper {\n    height: calc(100% - 26px);\n    width: 100%;\n    overflow: hidden;\n    &:hover {\n      cursor: pointer;\n    }\n    .tooltiptext {\n      text-align: center;\n    }\n\n  }\n}\n\n:global {\n  .bubblechart {\n    text.name, text.name2, text.price, text.change {\n      fill: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonTooltip": "_38N3Z5jxL88xaYEWUvsPQO",
	"Wrapper": "_2cRcE7P6pC-Qa3NytR1xai",
	"tooltiptext": "poNghyGPogoFZnP41Lxx_"
};
export default ___CSS_LOADER_EXPORT___;
