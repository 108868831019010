import FontAwesome from "../FontAwesome";
import React from "react";
import styles from './WidgetSearch.module.scss';
import PropTypes from "prop-types";
const WidgetSearch =({onChange})=>{
  return (<div>
    <div className={styles.WidgetSearch}>
      <FontAwesome
        prefix="fas"
        name="search"
      />
      <input
        className="Search"
        placeholder="Search by symbol..."
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  </div>);
};
WidgetSearch.propTypes={
  onChange: PropTypes.func
};
export default WidgetSearch;