import {connect} from "react-redux";
import Layout from "../components/Layout";
import {doLogout, getUserName} from "../actions/UserAction";
import {withRouter} from "react-router";

export default connect(
    (state) => {
      const {common, user} = state;

      return {
        isLogged: user.user.data || false,
        alert: common.alert,
      };
    }, {
      doLogout,
      getUserName,
    }
)(withRouter(Layout));
