import getTrendLine from "./indicators/getTrendLine";
import getHorizontalRay from "./indicators/getHorizontalRay";
import flow from "lodash/flow";
import redLineSettings from "./indicators/redLineSettings";
import greenLineSettings from "./indicators/greenLineSettings";
import blueLineSettings from "./indicators/blueLineSettings";
import getText from "./indicators/getText";

const serializer1 = ({data, rsi}) => {
  const response = {
    Time: [],
    Open: [],
    High: [],
    Low: [],
    Close: [],
    RSI: [],
  };
  data.forEach(([Timestamp, Open, High, Low, Close], i) => {
    if (rsi[i]) {
      response.Time.push(Timestamp);
      response.Open.push(Open);
      response.High.push(High);
      response.Low.push(Low);
      response.Close.push(Close);
      response.RSI.push(rsi[i]);
    }
  });
  return response;
};
export default [
  {
    label: "Divergence Types",
    options: [
      {
        value: <span>Regular Bullish Divergence [RSI]</span>,
        category: 'Divergence',
        result: "Regular",
        sentiment: <span style={{color: '#1bd487'}}><span style={{fontSize: '17px'}}>↑</span> Bullish</span>,
        id: "bullish_divergence",
        format: serializer1,
        indicator: "RSI",
        drawingTools: [
          getTrendLine,
          flow([(item) => ({...item, price1: item.price1 * 1.24, text: `TP1: ${(item.price1 * 1.2).toFixed(3)} (${(20).toFixed(1)}%) R/R: ${(((item.price1 * 1.2)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.44, text: `TP2: ${(item.price1 * 1.4).toFixed(3)} (${(40).toFixed(1)}%) R/R: ${(((item.price1 * 1.4)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.04, text: `Entry: ${(item.price1).toFixed(3)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 0.94, text: `SL: ${(item.price1*0.9).toFixed(3)} (${(10).toFixed(1)}%)`}), getText]),
          flow([(item) => ({...item, settings: blueLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 0.9, settings: redLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 1.2, settings: greenLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 1.4, settings: greenLineSettings}), getHorizontalRay])

        ]
      },
      {
        value: <span>Hidden Bullish Divergence [RSI]</span>,
        sentiment: <span style={{color: '#1bd487'}}><span style={{fontSize: '17px'}}>↑</span> Bullish</span>,
        result: "Hidden",
        format: serializer1,
        indicator: "RSI",
        id: "bullish_hidden_divergence",
        category: 'Divergence',
        drawingTools: [
          getTrendLine,
          flow([(item) => ({...item, price1: item.price1 * 1.24, text: `TP1: ${(item.price1 * 1.2).toFixed(3)} (${(20).toFixed(1)}%) R/R: ${(((item.price1 * 1.2)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.44, text: `TP2: ${(item.price1 * 1.4).toFixed(3)} (${(40).toFixed(1)}%) R/R: ${(((item.price1 * 1.4)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.04, text: `Entry: ${(item.price1).toFixed(3)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 0.94, text: `SL: ${(item.price1*0.9).toFixed(3)} (${(10).toFixed(1)}%)`}), getText]),
          flow([(item) => ({...item, settings: blueLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 0.9, settings: redLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 1.2, settings: greenLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 1.4, settings: greenLineSettings}), getHorizontalRay])
        ]
      },
      {
        value: <span>Regular Bearish Divergence [RSI]</span>,
        sentiment: <span style={{color: '#fe3c4e'}}><span style={{fontSize: '17px'}}>↓</span> Bearish</span>,
        result: "Regular",
        format: serializer1,
        indicator: "RSI",
        id: "bearish_divergence",
        category: 'Divergence',
        drawingTools: [
          getTrendLine,
          flow([(item) => ({...item, price1: item.price1 * 0.84, text: `TP1: ${(item.price1 * 1.2).toFixed(3)} (${(20).toFixed(1)}%) R/R: ${(((item.price1 * 1.2)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 0.64, text: `TP2: ${(item.price1 * 1.4).toFixed(3)} (${(40).toFixed(1)}%) R/R: ${(((item.price1 * 1.4)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.04, text: `Entry: ${(item.price1).toFixed(3)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.14, text: `SL: ${(item.price1*0.9).toFixed(3)} (${(10).toFixed(1)}%)`}), getText]),
          flow([(item) => ({...item, settings: blueLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 0.8, settings: greenLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 0.6, settings: greenLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 1.1, settings: redLineSettings}), getHorizontalRay]),
        ]
      },
      {
        value: <span>Hidden Bearish Divergence [RSI]</span>,
        sentiment: <span style={{color: '#fe3c4e'}}><span style={{fontSize: '17px'}}>↓</span> Bearish</span>,
        result: "Hidden",
        format: serializer1,
        indicator: "RSI",
        id: "bearish_hidden_divergence",
        category: 'Divergence',
        drawingTools: [
          getTrendLine,
          flow([(item) => ({...item, settings: blueLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 0.84, text: `TP1: ${(item.price1 * 1.2).toFixed(3)} (${(20).toFixed(1)}%) R/R: ${(((item.price1 * 1.2)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 0.64, text: `TP2: ${(item.price1 * 1.4).toFixed(3)} (${(40).toFixed(1)}%) R/R: ${(((item.price1 * 1.4)-item.price1)/(item.price1-(item.price1*0.9))).toFixed(2)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.04, text: `Entry: ${(item.price1).toFixed(3)}`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 1.14, text: `SL: ${(item.price1*0.9).toFixed(3)} (${(10).toFixed(1)}%)`}), getText]),
          flow([(item) => ({...item, price1: item.price1 * 0.8, settings: greenLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 0.6, settings: greenLineSettings}), getHorizontalRay]),
          flow([(item) => ({...item, price1: item.price1 * 1.1, settings: redLineSettings}), getHorizontalRay])
        ]
      },
    ]
  },
];
