import React, {Component} from 'react';
import styles from "./DeFiScannerWidget.scss";
import Main from './Main.js';
import TokenListingTab from './pages/tokenlistingtab.js';
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";

class DeFiScannerWidget extends Component {

  render() {
    const { toggleModal } = this.props;

    return (
      <div className="widgetWrapper">
        <WidgetToolbar
          {...this.props}
        />
        <div>
          <button
            onClick={() => toggleModal("tokenFinder")}
          >modal</button>
          <Router>
            <div className="row">
              <div className="col-1">
                <TokenListingTab/>
              </div>
              <div className="col-11">
                <Main />
              </div>
            </div>
          </Router>
        </div>
      </div>
    );
  }
}
DeFiScannerWidget.propTypes = {
  toggleModal: PropTypes.func,
};

DeFiScannerWidget.defaultProps = {
  toggleModal: () => false,
};
export default DeFiScannerWidget;
