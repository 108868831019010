// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3JAtrB1GRVWzcpkEhbiAkq{position:fixed;bottom:0;right:10px;width:300px;padding-bottom:5px;background:#30333a;border-radius:3px 3px 0 0;z-index:1;transition:height .3s,width .3s;-webkit-transition:height .3s,width .3s}._3JAtrB1GRVWzcpkEhbiAkq._2mKbM8MmpLr2tyd5WHOTjI{max-height:600px;height:30%}._3JAtrB1GRVWzcpkEhbiAkq.tt6SVgUvUrtWBI0dXUwZJ{height:70%;max-height:100%;width:40%}", "",{"version":3,"sources":["webpack://src/pages/Chat/components/Chat.scss"],"names":[],"mappings":"AAGE,yBACE,cAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CAEA,kBAAA,CACA,kBAAA,CACA,yBAAA,CACA,SAAA,CACA,+BAAA,CACA,uCAAA,CAEA,iDACE,gBAAA,CACA,UAAA,CAGF,+CACE,UAAA,CACA,eAAA,CACA,SAAA","sourcesContent":["@import \"../../../styles/constants.scss\";\n\n:local {\n  .Wrapper {\n    position: fixed;\n    bottom: 0;\n    right: 10px;\n    width: 300px;\n    //height: 30px;\n    padding-bottom: 5px;\n    background: #30333a;\n    border-radius: 3px 3px 0 0;\n    z-index: 1;\n    transition: height 0.3s, width 0.3s;\n    -webkit-transition: height 0.3s, width 0.3s;\n\n    &.Opened {\n      max-height: 600px;\n      height: 30%;\n    }\n\n    &.Expanded {\n      height: 70%;\n      max-height: 100%;\n      width: 40%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "_3JAtrB1GRVWzcpkEhbiAkq",
	"Opened": "_2mKbM8MmpLr2tyd5WHOTjI",
	"Expanded": "tt6SVgUvUrtWBI0dXUwZJ"
};
export default ___CSS_LOADER_EXPORT___;
