import {connect} from "react-redux";
import SentimentWidget from "../components/SentimentWidget/SentimentWidget";

import {subscribeSentimentData, unSubscribeSentimentData} from "../../../actions/FeedActions";
import {setWidgetActive} from "../../../actions/MarketsActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null;

      return {
        activeWidget: activeWidget,
        sentiment: widget.data && widget.data.score ? widget.data : {},
        widget: widget,
      };
    }, {
      subscribeSentimentData,
      unSubscribeSentimentData,
      setWidgetActive,
    },
)(SentimentWidget);
