import {connect} from "react-redux";
import AddNewPanelModal from "../components/AddNewPanelModal";
import {toggleModal, addWidget, updateWidget} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      return {
        isOpen: state.markets.modals.addNewPanel,
        widget: activeWidget,
      };
    }, {
      toggleModal,
      addWidget,
      updateWidget,
    },
)(AddNewPanelModal);
