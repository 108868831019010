import {api} from "../utils/apiUtils";

const getImage = (id) => api.get(`image/${id}`);
const saveImage = (image, name) => api.post("image/save", {
  image,
  name,
});

export {
  getImage,
  saveImage,
};
