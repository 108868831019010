export const SOURCES = [
  {label: "Title", value: "title"},
  {label: "Author", value: "creator"},
  {label: "Content", value: "content"},
];

export const OPTIONS = [
  {label: "Allow", value: "allow"},
  {label: "Block", value: "block"},
];
