import React, { useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Button} from "reactstrap";
import classNames from "classnames";
import FontAwesome from "../../../../components/FontAwesome";
import styles from "./WorksheetSettingsModal.scss";
import {cloneDeep} from "lodash";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import WorkSheetItem from "./WorksheetItem";
import {uid} from "uid";
import {useHistory} from "react-router-dom";
const grid=8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  height: 30,
  padding: "5px 10px",
  fontSize: 13,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: "#333740",
  // styles we need to apply on draggables
  ...draggableStyle,
  // display: "flex"
});
const getListStyle = isDraggingOver => ({
  padding: grid,
});


const WorksheetSettingsModal=(props)=>{
  const {isOpen,  toggleModal, worksheets} = props;
  const history =useHistory();
  const getWorksheets=()=>worksheets.map(({name, isVisible, id})=>({
    id,
    name,
    isSelected: false,
    isEdit: false,
  }));
  const [placeholderProps, setPlaceholderProps]=useState({
    top: 0,
    clientContent: "",
  });
  const [currentWorksheets, setCurrentWorksheets]=useState([]);
  useEffect(()=> {
    setCurrentWorksheets(getWorksheets());
  }, [isOpen]);
  const handleChangeValue=(value)=> setCurrentWorksheets(currentWorksheets.map(item => item.id === value.id ? value : item));
  const handleRemoveWorksheet=(id)=> setCurrentWorksheets(currentWorksheets.filter((item) => item.id !== id));
  const handleAddWorksheet=()=> {
    setCurrentWorksheets(currentWorksheets.concat([{
      id: uid(10),
      name: `Worksheet ${currentWorksheets.length + 1}`,
      widgets: [],
    }]));
  };
  const  handleSaveWorksheet= async ()=> {
    const worksheets=currentWorksheets.map(item=> {
      const worksheet=props.worksheets.find(({id})=>id===item.id)??item;
      return {
        ...worksheet,
        name: item.name,
      };
    });
    const isActiveWidgetRemoved=!currentWorksheets.find(({id})=>id===props.currentWorksheet);

    props.setWorksheets(worksheets);
    toggleModal("worksheetSettings");
    const worksheetsCopy=cloneDeep(worksheets);
    for (const i in worksheetsCopy){
      for (const j in worksheetsCopy[i].widgets){
        worksheetsCopy[i].widgets[j].data=[];
      }
    }
    if (isActiveWidgetRemoved){
      history.push(`/markets/workspaces/${worksheets[0].id}`);
    }
    await props.doUpdateState(worksheetsCopy);
  };
  const handleOnDragUpdate=({destination: {index}})=>{
    setPlaceholderProps({
      ...placeholderProps,
      top: index*38+12
    });
  };
  const moveWorksheet=(index, newIndex)=> {
    const temp=cloneDeep(currentWorksheets);

    temp.splice(index, 1);
    temp.splice(newIndex, 0, currentWorksheets[index]);
    setCurrentWorksheets(temp);
  };


  return (
    <Modal
      autoFocus={false}
      isOpen={isOpen}
      toggle={() => toggleModal("worksheetSettings")}
      className={classNames("feed-modal", styles.moveable)}
      size="xl"
    >
      <ModalHeader
        className={styles.Header}
        toggle={() => toggleModal("worksheetSettings")}
      >
        Worksheet <span>& Settings</span>
      </ModalHeader>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={styles.navitem}
          >
            Sheets
          </NavLink>
        </NavItem>
      </Nav>
      <ModalBody className={styles.ModalBody}>
        <div className={styles.contentModal} />
        <span className={styles.tabHeadline}><FontAwesome name="list" className={"iconHighlight"}/> Worksheet Names</span>
        <div className={styles.tabParagraph}>Sort list by dragging rows up/down and rename worksheet(s) here.</div>
        <div style={{overflow: "hidden", position: "relative"}}>
          <DragDropContext
            onDragEnd={({source, destination})=> {
              setCurrentWorksheets(currentWorksheets.filter(({id})=>id==='duplication'));

              return moveWorksheet(source.index,  destination.index);
            }}
            onDragUpdate={handleOnDragUpdate}
            onDragStart={({source: {index}})=>setPlaceholderProps({
              ...placeholderProps,
              clientContent: currentWorksheets[index].name
            })}

          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}

                >
                  {currentWorksheets.map((item, index) => (

                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}

                    >
                      {(provided, snapshot) => (
                        <WorkSheetItem
                          handleChangeValue={handleChangeValue}
                          handleRemoveWorksheet={handleRemoveWorksheet}
                          handleAddWorksheet={handleAddWorksheet}
                          provided={provided}
                          snapshot={snapshot}
                          getItemStyle={getItemStyle}
                          isRemoveAvailable={currentWorksheets.length>1}
                          style={{backgroundColor: "#383d48"}}
                          item={item}/>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  {snapshot.isDraggingOver && (

                    <div
                      style={{
                        opacity: 0.6,
                        position: "absolute",
                        top: placeholderProps.top,
                        width: "86%",
                        left: "18px"
                      }}
                    >
                      <div style={getItemStyle(false, { border: '1px dashed #666', opacity: 0.8, background: "#383d48" })}>
                        <img style={{marginRight: 10}} className="Icon" alt="list" src="/img/icons/list.svg" />
                        {placeholderProps.clientContent}
                      </div>
                    </div>)}

                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <button
          className={styles.SaveButton}
          onClick={() =>handleSaveWorksheet()}
          type="button"
        >
          Save
        </button>
        <Button
          className={styles.CancelButton}
          onClick={() => setCurrentWorksheets(getWorksheets())}
          type="button"
        >
          Cancel
        </Button>
      </ModalBody>


    </Modal>
  );
};

WorksheetSettingsModal.propTypes = {
  currentWorksheet: PropTypes.string,
  worksheets: PropTypes.array,
  addWidget: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  doUpdateState: PropTypes.func,
  setWorksheets: PropTypes.func,
  widget: PropTypes.object,
  updateWidget: PropTypes.func.isRequired,
  getUserWorksheets: PropTypes.func.isRequired,
};

WorksheetSettingsModal.defaultProps = {
  addWidget: () => false,
  isOpen: false,
  toggleModal: () => false,
  widget: undefined,
};

export default WorksheetSettingsModal;
