import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import React from "react";
import classes from './DateTimePicker.module.scss';
import PropTypes from 'prop-types';

function DateTimePicker({value, onChange}){
  return (<DatePicker
    className={classes.datePicker}
    format="MM/DD/YYYY HH:mm:ss"
    value={value}
    plugins={[
      <TimePicker key="timepicker" position="bottom"/>
    ]}
    onChange={(date)=>onChange(new Date(date))}
  />);
}
DateTimePicker.propTypes={
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func
};
export default DateTimePicker;
