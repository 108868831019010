import React from "react";
import styles from "./NoActiveWidget.scss";

const NoActiveWidget = () => (
  <div className={styles.Wrapper}>
    <div className={styles.Content}>
      Add a chart or price widget to current worksheet.
    </div>
  </div>
);

export default NoActiveWidget;
