// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3ZNwZzWqV0EglY4mLWzzmN{cursor:row-resize;border-top:1px solid #e8e8e8}.JiE11uZpBAy1zpv_jLOOp{height:100%}._38v2ib5aBO73isIg2OfspI{width:100%;height:100%;position:relative;flex-grow:1}._20q49FZB-gyGerYWEocYOu{position:absolute;top:0;left:0;z-index:0}.tOd5nyWHY0x102VNaXCy{position:absolute;top:0;left:0;z-index:1}.lF1Xbrdc8Lr8SiadRqvkJ{position:relative;height:calc(100% - 2px)}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/ChartWidget/CanvasChart/style.scss"],"names":[],"mappings":"AACE,yBACE,iBAAA,CACA,4BAAA,CAEF,uBACE,WAAA,CAEF,yBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CAEF,yBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA,CAEF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA,CAEF,uBACE,iBAAA,CACA,uBAAA","sourcesContent":[":local {\n  .gutter{\n    cursor: row-resize;\n    border-top: 1px solid #e8e8e8;\n  }\n  .Split{\n    height: 100%;\n  }\n  .Chart {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    flex-grow: 1;\n  }\n  .ChartCanvas {\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 0;\n  }\n  .ChartCanvasScale {\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 1;\n  }\n  .ResizeContainer{\n    position: relative;\n    height: calc(100% - 2px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gutter": "_3ZNwZzWqV0EglY4mLWzzmN",
	"Split": "JiE11uZpBAy1zpv_jLOOp",
	"Chart": "_38v2ib5aBO73isIg2OfspI",
	"ChartCanvas": "_20q49FZB-gyGerYWEocYOu",
	"ChartCanvasScale": "tOd5nyWHY0x102VNaXCy",
	"ResizeContainer": "lF1Xbrdc8Lr8SiadRqvkJ"
};
export default ___CSS_LOADER_EXPORT___;
