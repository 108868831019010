import {connect} from "react-redux";
import DeFiScannerWidget from "../components/DeFiScannerWidget/DeFiScannerWidget";
import {toggleModal} from "../../../actions/MarketsActions";
import {getMarketSummaries, updateWidget} from "../../../actions/MarketsActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null;

      return {
        activeWidget: activeWidget,
        widget: {
          ...widget,
          data: widget?.data.map((item) => ({
            ...item,
            change: item.percentage || (item.change && item.change / item.close) || 0,
          }))??[],
        },
        heightUnit: props.heightUnit,
      };
    }, {
      getMarketSummaries,
      updateWidget,
      toggleModal,
    },
)(DeFiScannerWidget);
