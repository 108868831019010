/* eslint react/no-did-mount-set-state: 0 */
/* eslint max-len: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Picker, emojiIndex} from "emoji-mart";
import PerfectScrollbar from "react-perfect-scrollbar";
import enhanceWithClickOutside from "react-click-outside";
import {ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle} from "reactstrap";
import ReactTooltip from 'react-tooltip';
import styles from "./InputBlock.scss";
import FontAwesome from "../../../components/FontAwesome";
import {EMOJI_SET, EMAIL_REGEXP, EMOJI_REGEXP, LINK_REGEXP} from "../../../constants/ChatConstants";

const burnParams = [
  {key: "minutes", title: "Minutes"},
  {key: "1 minute", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "1 minute"},
  {key: "5 minutes", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "5 minutes"},
  {key: "10 minutes", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "10 minutes"},
  {key: "15 minutes", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "15 minutes"},
  {key: "30 minutes", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "30 minutes"},
  {key: "45 minutes", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "45 minutes"},
  {key: "hours", title: "Hours"},
  {key: "1 hour", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "1 hour"},
  {key: "2 hours", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "2 hours"},
  {key: "4 hours", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "4 hours"},
  {key: "6 hours", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "6 hours"},
  {key: "days", title: "Days"},
  {key: "1 day", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "1 day"},
  {key: "1 week", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "1 week"},
  {key: "1 month", img: <FontAwesome style={{marginRight: "5px"}} name="fire" prefix="fas"/>, label: "1 month"},
];

class InputBlock extends Component {
  constructor() {
    super();

    this.input = null;

    this.state = {
      message: "",
      emojiEnabled: false,
      addDropdownOpen: false,
      messageToolsHidden: true,
      burnDropdownOpen: false,
      burnTimeout: "none",
      burnScrollUpdated: true,
    };
  }

  componentDidMount() {
    if (this.props.editingMessage) {
      this.setState({
        message: this.parseMessage(this.props.editingMessage.body),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        burnScrollUpdated: false,
      });
      setTimeout(() => {
        this.setState({
          burnScrollUpdated: true,
        });
      }, 50);
    }
  }

  handleClickOutside() {
    this.setState({
      emojiEnabled: false,
    });
  }

  handleChange = (value) => {
    this.setState({
      message: value,
    });
  };

  handleChangeBurn = (value) => {
    this.setState({
      burnTimeout: value,
    }, () => {
      this.input.focus();
    });
  };

  parseUrls = (message) => {
    // URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    let replacedText = message.replace(replacePattern1, "{{LINK:$1}}");

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, "{{LINK:http://$2}}");

    // Change email addresses to mailto:: links.
    const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, "{{EMAIL:$1}}");

    return replacedText;
  };

  handleKeyPress = (event) => {
    const {sendMessageAction, editingMessage, updateMessageAction} = this.props;
    const {burnTimeout, message} = this.state;

    if (event.key === "Enter") {
      event.preventDefault();
      if (editingMessage) {
        updateMessageAction(editingMessage._id, {
          ...editingMessage,
          body: this.parseUrls(message),
          burnTimeout: burnTimeout,
        });
      } else {
        const withUrls = this.parseUrls(message);
        sendMessageAction({
          message: withUrls,
          burnTimeout: burnTimeout,
        });
      }
      this.setState({
        message: "",
        burnTimeout: "none",
      });
    } else if (event.key === "Escape") {
      if (burnTimeout !== "none") {
        event.preventDefault();
        this.handleChangeBurn("none");
      }
    }
  };

  handleSaveEditing = () => {
    const {editingMessage, updateMessageAction} = this.props;
    const {burnTimeout, message} = this.state;
    updateMessageAction(editingMessage._id, {
      ...editingMessage,
      body: this.parseUrls(message),
      burnTimeout: burnTimeout,
    });
  };

  handleEmojiToggle = () => {
    this.setState({
      emojiEnabled: !this.state.emojiEnabled,
      addDropdownOpen: false,
      burnDropdownOpen: false,
    });
  };

  handleSelectEmoji = (emoji) => {
    this.setState({
      emojiEnabled: false,
      message: this.state.message + "{{EMOJI:" + emoji.id + "}}",
    }, () => {
      this.input.focus();
    });
  };

  handleAddDropdown = () => {
    this.setState({
      addDropdownOpen: !this.state.addDropdownOpen,
      burnDropdownOpen: false,
      emojiEnabled: false,
    });
  };

  handleBurnDropdown = () => {
    this.setState({
      burnDropdownOpen: !this.state.burnDropdownOpen,
      addDropdownOpen: false,
      emojiEnabled: false,
    });
  };

  handleToggleTools = (hidden) => {
    this.setState({
      messageToolsHidden: hidden,
    });
  };

  handleTextareaFocus = () => {
    this.props.notifyIsTyping(true, this.props.channelObject);
    this.handleToggleTools(true);
    if (this.state.emojiEnabled) {
      this.handleEmojiToggle();
    }
  };

  handleTextareaBlur = () => {
    this.props.notifyIsTyping(false, this.props.channelObject);
  };

  parseMessage = (value) => {
    const withEmogies = value.replace(EMOJI_REGEXP, (a, b, c) => {
      const emojies = emojiIndex.search(c).map((o) => o.native);
      return emojies[0];
    });
    const withLinks = withEmogies.replace(LINK_REGEXP, (a, b, c) => c);
    const withEmails = withLinks.replace(EMAIL_REGEXP, (a, b, c) => c);
    return withEmails;
  };

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    debugger;
    this.props.uploadFileAction({file, burnTimeout: this.state.burnTimeout});
    this.input.focus();
  };

  handleCancelEditing = () => {
    this.props.setMessageForEditing(null);
    this.setState({
      message: "",
    });
  };

  render() {
    const {addDropdownOpen, emojiEnabled, messageToolsHidden, burnDropdownOpen, burnTimeout} = this.state;
    const {editingMessage, userTyping, expanded} = this.props;

    return (
      <div className={classNames(styles.InputBlock, "input-block")}>
        <div className={styles.Input}>
          <div className={styles.TextInput}>
            <textarea
              className={classNames({[styles.WithTools]: !messageToolsHidden})}
              ref={(el) => this.input = el}
              rows={1}
              value={this.parseMessage(this.state.message)}
              onChange={(event) => this.handleChange(event.target.value)}
              onFocus={this.handleTextareaFocus}
              onBlur={this.handleTextareaBlur}
              onKeyDown={this.handleKeyPress}
              placeholder={!burnTimeout || burnTimeout === "none" ? "Write a message..." : "Write a burn message..."}
            />
            <a data-tip data-for='menuButton'>
              <DropdownToggle
                className={classNames(styles.Button, {
                  [styles.Hidden]: messageToolsHidden === false,
                })}
                type="button"
                onClick={() => this.handleToggleTools(!messageToolsHidden)}
              >
                <FontAwesome name="ellipsis-h" prefix="fas"/>
              </DropdownToggle>
            </a>
            <ReactTooltip
              id='menuButton'
              offset="{'top': 0, 'left': 10}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Menu</span>
            </ReactTooltip>
            <div
              className={classNames(styles.MessageTools, {
                [styles.Hidden]: messageToolsHidden,
              })}
            >
              <a data-tip data-for='emojiButton'>
                <DropdownToggle
                  className={styles.Button}
                  onClick={this.handleEmojiToggle}
                >
                  <FontAwesome name="smile" prefix="far"/>
                </DropdownToggle>
              </a>
              <ReactTooltip
                id='emojiButton'
                offset="{'top': -10, 'left': 10}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>Emoji set</span>
              </ReactTooltip>
              {/* eslint-disable */}
              <ButtonDropdown
                dropup
                className={styles.Dropdown}
                isOpen={burnDropdownOpen}
                toggle={this.handleBurnDropdown}
              >
                {/* eslint-enable */}
                <a data-tip data-for='burnButton'>
                  <DropdownToggle
                    className={styles.Button}
                    type="button"
                    onClick={this.handleBurnDropdown}
                  >
                    <FontAwesome
                      name="fire"
                      prefix="fas"
                      wrapperClassName={classNames({[styles.BurnMessage]: burnTimeout !== "none"})}
                    />
                  </DropdownToggle>
                </a>
                <ReactTooltip
                  id='burnButton'
                  offset="{'top': -10, 'left': 10}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Burn messages</span>
                </ReactTooltip>
                <DropdownMenu className={classNames("dropdown-menu-right", styles.OptionsDropdown)}>
                  {this.state.burnScrollUpdated && (
                    <PerfectScrollbar
                      className={classNames(styles.Options, {
                        [styles.Expanded]: expanded,
                      })}
                      options={{suppressScrollX: true}}
                    >
                      {burnParams.map((item) => (
                        <DropdownItem
                          className={styles.DropdownItemBurn}
                          key={item.key}
                          onClick={() => !item.isDivider && this.handleChangeBurn(item.key)}
                          divider={item.isDivider}
                        >
                          <span className={styles.dropdownTitle}>{item.title}</span>
                          <span className={styles.dropdownText}>{item.img}</span>
                          <span className={styles.dropdownText}>{item.label}</span>
                        </DropdownItem>
                      ))}
                    </PerfectScrollbar>
                  )}
                </DropdownMenu>
              </ButtonDropdown>
              {/* eslint-disable */}
              <ButtonDropdown
                dropup
                className={styles.Dropdown}
                isOpen={addDropdownOpen}
                toggle={this.handleAddDropdown}
              >
                {/* eslint-enable */}
                <a data-tip data-for='uploadButton'>
                  <DropdownToggle
                    className={styles.Button}
                    type="button"
                    onClick={this.handleAddDropdown}
                  >
                    <FontAwesome name="paperclip" prefix="far"/>
                  </DropdownToggle>
                </a>
                <ReactTooltip
                  id='uploadButton'
                  offset="{'top': -10, 'left': 50}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Upload a picture or file</span>
                </ReactTooltip>
                <DropdownMenu className="dropdown-menu-right">
                  <DropdownItem
                    className={styles.DropdownItemBurn}
                    onClick={() => false}
                  >
                    <FontAwesome name="upload" prefix="fas"/> Upload File
                    <input
                      className={styles.FileInput}
                      onChange={this.handleFileUpload}
                      type="file"
                      name="file"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>

            {emojiEnabled && (
              <Picker
                onSelect={this.handleSelectEmoji}
                set={EMOJI_SET}
                emojiTooltip
                sheetSize={32}
                style={{
                  position: "absolute",
                  bottom: expanded ? "50px" : "35px",
                  right: "5px",
                  width: expanded ? "335px" : "270px",
                  height: expanded ? "300px" : "205px",
                }}
                backgroundImageFn={(set, sheetSize) => `https://unpkg.com/emoji-datasource-${set}@5.0.1/img/${set}/sheets-256/${sheetSize}.png`}
              />
            )}

          </div>
        </div>
        <div className={styles.ChatActions}>
          <div className={styles.Typing}>
            {userTyping.length > 0 && (
              <span>
                <span className={styles.User}>{userTyping.join(", ")}</span> {userTyping.length > 1 ? "are" : "is"} typing...
              </span>
            )}
          </div>
          {!editingMessage && (
            <div className={styles.Help}>
              {!burnTimeout || burnTimeout === "none" ? (
                <span>
                  enter to <span className={styles.White}>send</span>
                </span>
              ) : (
                <span>
                  escape to <span className={styles.White}>cancel</span>, enter to <span className={styles.Orange}>burn</span>
                </span>
              )}
            </div>
          )}
        </div>
        {editingMessage && (
          <div className={styles.EditingActions}>
            <button
              className={styles.Cancel}
              onClick={this.handleCancelEditing}
            >
              Cancel
            </button>
            <button
              className={styles.Save}
              onClick={this.handleSaveEditing}
            >
              <FontAwesome prefix="fas" name="level-down-alt" wrapperClassName={styles.Icon}/> Save
            </button>
          </div>
        )}
      </div>
    );
  }
}

InputBlock.propTypes = {
  channelObject: PropTypes.object,
  editingMessage: PropTypes.object,
  expanded: PropTypes.bool,
  notifyIsTyping: PropTypes.func,
  sendMessageAction: PropTypes.func.isRequired,
  setMessageForEditing: PropTypes.func,
  updateMessageAction: PropTypes.func,
  uploadFileAction: PropTypes.func.isRequired,
  userTyping: PropTypes.array,
};

InputBlock.defaultProps = {
  channelObject: undefined,
  editingMessage: undefined,
  expanded: false,
  notifyIsTyping: () => false,
  setMessageForEditing: () => false,
  updateMessageAction: () => false,
  userTyping: [],
};

export default enhanceWithClickOutside(InputBlock);
