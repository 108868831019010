import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./FloatValue.scss";

const FloatValue = ({value, className, isNegative, isPositive}) => {
  const intValue = parseInt(value);
  const intLength = intValue.toString().length;
  const zeroCount = 9 - intLength;
  const zeroIndex = value && Number.parseFloat(value).toFixed(8).search(/[1-9]/);

  return (
    <div
      className={classNames(styles.FloatValue, className, {
        [styles.Red]: isNegative,
        [styles.Green]: isPositive,
      })}
    >
      {value && value >= 1 ? (
        <span className={styles.White}>
          {intValue}
        </span>
      ) : ""}
      {value && value >= 1 ? (
        <span>{(value - intValue).toFixed(zeroCount > 0 ? zeroCount : 0).substr(1)}</span>
      ) : ""}

      {value && value < 1 && value !== 0 ? (
        <span>{Number.parseFloat(value).toFixed(8).substr(0, zeroIndex)}</span>
      ) : ""}
      {value && value < 1 && value !== 0 ? (
        <span className={styles.White}>{value && Number.parseFloat(value).toFixed(8).substr(zeroIndex)}</span>
      ) : ""}

      {(!value || value === 0) ? (
        <span>0</span>
      ) : ""}
    </div>
  );
};

FloatValue.propTypes = {
  className: PropTypes.string,
  isNegative: PropTypes.bool,
  isPositive: PropTypes.bool,
  value: PropTypes.number,
};

FloatValue.defaultProps = {
  className: "",
  isNegative: false,
  isPositive: false,
  value: 0,
};

export default FloatValue;
