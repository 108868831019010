import React, {Component} from 'react';
import styles from './tokenlistingtab.scss';
import {
  Link
} from "react-router-dom";
import { Button } from 'react-bootstrap';
import UniswapImage from '../asset/uniswap.jpg';
import PancakeswapImage from '../asset/pancakeswap.jpg';

class TokenListingTab extends Component {

  render() {
    return (
      <div>
        <br/>
        <Link to="/token-listings/uniswap">
          <Button><img className={styles.image} alt="uniswap" src={UniswapImage} /></Button>
        </Link>
        <br/>
        <Link to="/token-listings/pancakeswap">
          <Button><img className={styles.image} alt="pancakeswap" src={PancakeswapImage} /></Button>
        </Link>
      </div>
    );
  }
}

export default TokenListingTab;
