/* eslint max-len: 0 */
import React, {PureComponent, Fragment} from "react";
import PropTypes from "prop-types";
import {sortBy} from "lodash";
import moment from "moment";
import FontAwesome from "../../../components/FontAwesome";

import OrderType from "../../../components/OrderType";
import MarketName from "../../../components/MarketName";
import FloatValue from "../../../components/FloatValue";
import Collapse from "../../../components/Collapse";
import Table from "../../../components/Table";
import Loader from "../../../components/Loader";

import styles from "./HistoryPage.scss";

const fields = [
  {key: "closed", label: "Closed Date"},
  {key: "market", label: "Market"},
  {key: "orderSide", label: "Side"},
  {key: "orderType", label: "Type"},
  {key: "price", label: "Price"},
  {key: "quantity", label: "Quantity"},
  {key: "filled", label: "Filled"},
  {key: "status", label: "Status"},
];

class HistoryOrderItem extends PureComponent {
  constructor() {
    super();

    this.state = {
      historyOrderSortingKey: null,
      historyOrderSortingType: null,
      filter: "",
    };
  }

  onHistorySortClick = (key, type) => this.setState({historyOrderSortingKey: key, historyOrderSortingType: type});

  sortHistoryOrderData = (items) => {
    const {historyOrderSortingKey, historyOrderSortingType} = this.state;
    const sortedData = historyOrderSortingKey ? sortBy(items, (item) => {
      switch (historyOrderSortingKey) {
        case "closed":
          return item.lastTradeTimestamp;
        case "market":
          return item.symbol;
        case "orderSide":
          return item.type;
        case "price":
          return item.price;
        case "quantity":
          return item.amount;
        case "filled":
          return item.filled;
        default:
          return item[historyOrderSortingKey];
      }
    }) : items;

    return historyOrderSortingType === "desc" ? sortedData.reverse() : sortedData;
  };

  drawToolBar = (account) => {
    return (
      <Fragment>
        <img
          alt=""
          className={styles.Icon}
          height={22}
          src={`/img/exchanges/${account.exchange.toLowerCase()}.png`}
        /> {`${account.name}, ${account.exchange.toUpperCase()}`}
      </Fragment>
    );
  };

  drawActions = () => {
    return (
      <div className={styles.FilterWrapper}>
        <input
          className={styles.Filter}
          placeholder="Search..."
          onChange={(event) => this.setState({filter: event.target.value})}
        />
        <FontAwesome
          className={styles.Icon}
          name='search'
        />
      </div>
    );
  };

  drawFieldContent = (field, item) => {
    switch (field.key) {
      case "closed":
        return moment(item.lastTradeTimestamp).add((new Date()).getTimezoneOffset() * -1, "minutes").format("D MMM YYYY, HH:mm");
      case "market":
        return (
          <Fragment>
            <img
              alt=""
              className={styles.Icon}
              height={22}
              src={`/img/symbols/${item.symbol.split("/")[0].toUpperCase()}.png?`+
                `exchange=${item.exchange}`
              }
            /> <MarketName name={item.symbol} reversed/>
          </Fragment>
        );
      case "orderSide":
        return <OrderType type={item.type}/>;
      case "orderType":
        return "Limit";
      case "price":
        return <FloatValue value={item.price}/>;
      case "quantity":
        return <FloatValue value={item.amount}/>;
      case "filled":
        return <FloatValue value={item.filled}/>;
      case "status":
        return "Executed";
      default:
        return null;
    }
  };

  drawHistoryContent = (account) => {
    const {loading} = this.props;
    const list = account.list || [];
    const filteredList = this.sortHistoryOrderData(list)
        .filter((item) => item.symbol.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0);

    return (
      <Table
        fields={fields}
        sort={this.onHistorySortClick}
      >
        {loading && (
          <tr>
            <td>
              <Loader/>
            </td>
          </tr>
        )}
        {loading === false && filteredList.length === 0 && (
          <tr>
            <td colSpan={fields.length}>
              There are no orders
            </td>
          </tr>
        )}
        {loading === false && filteredList.length > 0 && filteredList.map((item) => (
          <tr key={item.OrderUuid}>
            {fields.map((field) => (
              <td key={field.key} className={field.key}>
                {this.drawFieldContent(field, item)}
              </td>
            ))}
          </tr>
        ))}
      </Table>
    );
  };

  render() {
    const {account} = this.props;
    return (
      <Collapse
        label={this.drawToolBar(account)}
        description={`Closed Orders (${account.list.length})`}
        actions={this.drawActions(account)}
      >
        {this.drawHistoryContent(account)}
      </Collapse>
    );
  }
}

HistoryOrderItem.propTypes = {
  account: PropTypes.object,
  loading: PropTypes.bool,
};

HistoryOrderItem.defaultProps = {
  account: {},
  loading: false,
};

export default HistoryOrderItem;
