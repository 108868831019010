import FontAwesome from "../../components/FontAwesome";
import PropTypes from "prop-types";
import styles from "./WidgetScanner.module.scss";
import ReactTooltip from 'react-tooltip';

const WidgetScannerDeleteButton = ({handleRemoveWorksheet}) => {
  return (<button
    //onClick={() => handleRemoveWorksheet()}
  >
    <a data-tip data-for='deleteButton'>
      <FontAwesome
        name="trash"
        prefix="fas"
        className={styles.deleteIcon}
      />
    </a>
    <ReactTooltip
      id='deleteButton'
      offset="{'top': -10, 'left': 8}"
      arrowColor='transparent'
      className={styles.buttonTooltip}
      effect='solid'
    >
      <span>Delete</span>
    </ReactTooltip>
  </button>);
};

WidgetScannerDeleteButton.propTypes = {
  handleRemoveWorksheet: PropTypes.func.isRequired,
};
export default WidgetScannerDeleteButton;
