import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FontAwesome from "../../../../../components/FontAwesome";
import styles from "./DrawingToolContextMenu.scss";
class DrawingToolContextMenu extends PureComponent {
  constructor() {
    super();

    this.state = {
    };
  }
  handleDelete = () => {
    const {drawingToolContextMenuItemId, widget, updateWidget} = this.props;
    let drawingTools = widget.drawingTools;
    drawingTools = drawingTools.filter((item) => item.id != drawingToolContextMenuItemId);
    updateWidget({
      ...widget,
      drawingTools
    });
  }
  handleClone = () => {
    const {drawingToolContextMenuItemId, widget, updateWidget} = this.props;
    const drawingTools = widget.drawingTools;
    const item = drawingTools.find((item) => item.id == drawingToolContextMenuItemId);
    const newItem = {};
    for (const key in item){
      let newValue = item[key];
      if (key.length > 5 && key.slice(0, 5) == 'price'){
        const value = item[key];
        newValue = value * 1.05;
      }
      newItem[key] = newValue;
    }
    const cc = window.cc;
    const newId = cc.generateUuid();
    newItem.id = newId;
    updateWidget({
      ...widget,
      drawingTools: [...drawingTools, newItem]
    });
  }
  render() {
    const {drawingToolContextMenuOpen, drawingToolContextMenuPos, drawingToolContextMenuItemId, handleToggleModal} = this.props;
    return (
      <div
        className={classNames(styles.contextMenu, {[styles.Hidden]: !drawingToolContextMenuOpen})}
        style={{left: drawingToolContextMenuPos[0], top: drawingToolContextMenuPos[1]}}
      >
        <ul>
          <span className={styles.drawingItemTitle}>Settings</span>
          <li
            onClick={() => handleToggleModal("drawingSettings", drawingToolContextMenuItemId)}
          >
            <FontAwesome name="cog" prefix="fas" style={{width: '12px'}}/> Edit Settings
          </li>
          <li
            onClick={this.handleClone}
          >
            <FontAwesome name="clone" prefix="fas" style={{width: '12px'}}/> Duplicate
          </li>
          <span className={styles.drawingItemTitle}>Other</span>

          <li
            onClick={this.handleDelete}
          >
            <FontAwesome name="trash-alt" prefix="fas" style={{width: '12px'}}/> Delete
          </li>
        </ul>
      </div>
    );
  }
}

DrawingToolContextMenu.propTypes = {
  drawingToolContextMenuItemId: PropTypes.string,
  drawingToolContextMenuOpen: PropTypes.bool,
  drawingToolContextMenuPos: PropTypes.array,
  handleToggleModal: PropTypes.func,
  updateWidget: PropTypes.func,
  widget: PropTypes.object,
};

DrawingToolContextMenu.defaultProps = {
  drawingToolContextMenuItemId: '',
  drawingToolContextMenuOpen: false,
  drawingToolContextMenuPos: [0, 0]
};

export default DrawingToolContextMenu;
