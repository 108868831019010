import queryStringHelper from "query-string";
import axios from "axios";

export const getHostName = () => {
  return window.location.origin;
};

export const getApiUrl = () => `${getHostName()}/api`;

export const apiMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const api = {
  get: (url, params, options) => request(url, apiMethods.GET, params, options),
  post: (url, params, options) => request(url, apiMethods.POST, params, options),
  put: (url, params, options) => request(url, apiMethods.PUT, params, options),
  patch: (url, params, options) => request(url, apiMethods.PATCH, params, options),
  delete: (url, params, options) => request(url, apiMethods.DELETE, params, options),
};

const request = (url, method, params = {}, options = {}) => {
  let queryString = "";

  if (url.indexOf("/") === 0) {
    url = url.substr(1);
  }

  if ([apiMethods.GET, apiMethods.DELETE].indexOf(method) > -1) {
    queryString = queryStringHelper.stringify(params, { arrayFormat: "bracket" });
    if (queryString) {
      queryString = "?" + queryString;
    }
  }
  return axios({
    method,
    url: `${getApiUrl()}/${url}${queryString}`,
    data: params,
    withCredentials: true,
  }).then(parseResponse)
      .then(checkStatus);
};

const parseResponse = async (response) => {
  return {
    status: response.data.status,
    data: response.data,
  };
};

const checkStatus = (props) => {
  const { data, status } = props;
  if (status >= 200 && status < 300) {
    return data;
  } else {
    throw new Error(data.error);
  }
};
