import {
  relativeFontSize,
  formatPrice
} from '../chartTools';

export default function drawAxes(view, quotes, priceLines, timeLines, moveY) {
  const box = view.geometry.boxPrice.padding;
  const ctx = view.ctx;
  ctx.strokeStyle = view.style.colorBorder;
  ctx.lineWidth = 1;
  ctx.beginPath();
  ctx.moveTo(box[2] + box[0], box[1]);
  ctx.lineTo(box[2] + box[0], box[3]+box[1]);
  ctx.moveTo(box[2] + box[0], box[3]+box[1]);
  ctx.lineTo(box[0], box[3]+box[1]);
  ctx.stroke();

  const contentBox = view.geometry.boxPrice.content;
  drawPriceScale(view.ctx, priceLines, quotes, view);
  if (view.isLast){
    drawTimeScale(view.ctx, box, contentBox, timeLines, view, moveY);
  }
}

function drawPriceScale(ctx, scaleValues, quotes, chartView) {
  const priceBox = chartView.geometry.boxPrice.padding;
  const priceBoxContent = chartView.geometry.boxPrice.content;
  const style = chartView.style;
  const fontSize = relativeFontSize(chartView.width, chartView.fontSize);
  const ratio = priceBoxContent[3] / quotes.range;
  // Draw price scale
  for (const scaleValue of scaleValues) {
    const screenY = priceBoxContent[1] + priceBoxContent[3] - (scaleValue - quotes.min) * ratio;

    ctx.strokeStyle = style.colorScale;
    ctx.beginPath();
    ctx.moveTo(
        priceBox[0] + priceBox[2],
        screenY
    );
    ctx.lineTo(
        priceBox[0] + priceBox[2] + chartView.config.padding,
        screenY
    );
    ctx.stroke();
    // styling y axis
    const intValue = parseInt(scaleValue);
    const zeroIndex = scaleValue && Number.parseFloat(scaleValue).toFixed(8).search(/[1-9]/);

    ctx.font = `${fontSize}px "Arial"`;
    if (scaleValue && scaleValue >= 1){
      ctx.fillStyle = '#fff';
      ctx.fillText(
          intValue,
          priceBox[0] + priceBox[2] + chartView.config.padding * 2,
          screenY + fontSize / 3
      );
      const intWidth = ctx.measureText(intValue).width;
      ctx.fillStyle = '#aaa';
      ctx.fillText(
          (scaleValue - intValue).toFixed(8).substr(1),
          priceBox[0] + priceBox[2] + chartView.config.padding * 2 + intWidth,
          screenY + fontSize / 3
      );
    }

    if (scaleValue && scaleValue < 1 && scaleValue !== 0){
      ctx.fillStyle = '#aaa';
      const text = Number.parseFloat(scaleValue).toFixed(8).substr(0, zeroIndex);
      ctx.fillText(
          text,
          priceBox[0] + priceBox[2] + chartView.config.padding * 2,
          screenY + fontSize / 3
      );
      ctx.fillStyle = '#fff';
      const intWidth = ctx.measureText(text).width;
      ctx.fillText(
          scaleValue && Number.parseFloat(scaleValue).toFixed(8).substr(zeroIndex),
          priceBox[0] + priceBox[2] + chartView.config.padding * 2 + intWidth,
          screenY + fontSize / 3
      );
    }
  }
  // Draw last close red line
  const data = quotes.data;
  const lastClose = data[data.length-1].close;
  const screenY = priceBoxContent[1] + priceBoxContent[3] - (lastClose - quotes.min) * ratio;
  ctx.setLineDash([2, 4]);
  const lastChange = data[data.length-1].close - data[data.length-1].open;
  if (lastChange < 0){
    ctx.strokeStyle = chartView.style.colorBear;
  } else {
    ctx.strokeStyle = chartView.style.colorBull;
  }
  ctx.lineWidth = 3;
  ctx.beginPath();
  ctx.moveTo(
      priceBox[0],
      screenY
  );
  ctx.lineTo(
      priceBox[0] + priceBox[2],
      screenY
  );
  ctx.stroke();
  ctx.lineWidth = 1;
  // Draw price text background rect
  ctx.beginPath();
  if (lastChange < 0){
    ctx.fillStyle = chartView.style.colorBear;
  } else {
    ctx.fillStyle = chartView.style.colorBull;
  }
  ctx.fillRect(
      priceBox[0] + priceBox[2],
      screenY - fontSize / 2 - 3.2 * 2,
      chartView.config.geometry.boxPrice.margin[1] * 2,
      fontSize + 6 * 2);
  ctx.stroke();
  // Draw pin
  ctx.setLineDash([]);
  ctx.strokeStyle = style.colorScale;
  ctx.beginPath();
  ctx.moveTo(
      priceBox[0] + priceBox[2],
      screenY
  );
  ctx.lineTo(
      priceBox[0] + priceBox[2] + chartView.config.padding,
      screenY
  );
  ctx.stroke();
  // Draw price text
  const text = formatPrice(lastClose);
  ctx.font = `${fontSize}px "Arial"`;
  ctx.fillStyle = style.colorScale;
  ctx.fillText(
      text,
      priceBox[0] + priceBox[2] + chartView.config.padding * 2,
      screenY + fontSize / 3
  );
}

function drawTimeScale(ctx, boxPrice, boxContent, scaleValues, chartView, moveY) {
  const style = chartView.style;
  const fontSize = relativeFontSize(chartView.width, chartView.fontSize);
  let previousLabelX = Number.MAX_SAFE_INTEGER;
  const stickLength = chartView.stickLength;
  const stickMargin = chartView.stickMargin;
  const left = boxContent[2] % stickLength;
  for (let i = scaleValues.length - 1; i >= 0; --i) {
    const verticalLine = scaleValues[i];
    ctx.strokeStyle = style.colorGrid;
    const xStart = boxContent[0] + (verticalLine[0] - 2) * stickLength + stickMargin - moveY * 2 + left;
    let xEnd = boxContent[0] + (verticalLine[0] - 1) * stickLength - stickMargin - moveY * 2 + left;
    if (xEnd < xStart) {
      xEnd = xStart;
    }
    const xMid = (xEnd - xStart) / 2;
    ctx.fillStyle = '#999';
    ctx.font = `${fontSize}px "Arial"`;

    const labelWidth = ctx.measureText(verticalLine[1]).width;
    const drawingStickBegin = xStart + xMid - labelWidth / 2;
    // prevent drawing labels on top each other
    if (drawingStickBegin + labelWidth < previousLabelX && xStart + xMid < boxContent[2]) {
      ctx.fillText(
          verticalLine[1],
          drawingStickBegin,
          boxPrice[1] + boxPrice[3] + fontSize + 6
      );
    }

    previousLabelX = drawingStickBegin;

  }
}
