import React, {Component, Fragment} from "react";
import {  BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";

import Layout from "../containers/Layout";
import MarketsWrapper from "./Markets/components/MarketsWrapper";
import MarketsContainer from "./Markets/containers/MarketsContainer";
import OrdersContainer from "./Orders/containers/OrdersPageContainer";
import HistoryContainer from "./History/containers/HistoryPageContainer";
import AccountsContainer from "./Accounts/containers/AccountsContainer";
import BalanceContainer from "./Balance/containers/BalancePageContainer";
import LoginContainer from "./Login/containers/LoginContainer";
import SignupContainer from "./Signup/containers/SignupContainer";
import SettingsContainer from "./Settings/containers/SettingsContainer";
import AccountPage from "./Settings/components/Account/AccountPage";
import SubscriptionPage from "./Settings/components/Subscription/SubscriptionPage";
import ImageContainer from "./Image/containers/ImageContainer";
import SecurityPage from "./Settings/components/Security/SecurityPage";
import SettingsPage from "./Settings/components/Settings/SettingsPage";
import {marketSocket, chatSocket, tickSocket} from "../utils/websockets";
import {TosPage} from "./Signup/components/TosPage";
import {PrivacyPage} from "./Signup/components/PrivacyPage";
//import { FPSStats } from "fps-react";

class App extends Component {
    componentDidMount = () => {
      tickSocket.connect();
      marketSocket.connect();
      chatSocket.connect();
    }

    render() {
      const marketRoutes=(
        <Route
          path="/markets"
        >
          {/*<FPSStats />*/}

          <MarketsWrapper>
            <Route
              path="/markets/workspaces/:workspace?"
              component={MarketsContainer}
            />
            <Route
              path="/markets/orders"
              component={OrdersContainer}
            />
            <Route
              path="/markets/history"
              component={HistoryContainer}
            />
            <Route
              path="/markets/accounts"
              component={AccountsContainer}
            />
            <Route
              path="/markets/balances"
              component={BalanceContainer}
            />
          </MarketsWrapper>
        </Route>
      );
      const settingRoutes=(
        <Route
          path="/settings"
        >
          <SettingsContainer>
            <Route
              path="/settings/account"
              component={AccountPage}
            />
            <Route
              path="/settings/subscription"
              component={SubscriptionPage}
            />
            <Route
              path="/settings/others"
              component={SettingsPage}
            />
            <Route
              path="/settings/security"
              component={SecurityPage}
            />
          </SettingsContainer>

        </Route>);

      return (
        <Fragment>
          <Helmet>
            <title>aurlix</title>
          </Helmet>

          <Router>
            <Layout>
              <Switch>
                <Redirect from='/' to='/login' exact/>
                {marketRoutes}
                {settingRoutes}
                <Route
                  path="/image/:id"
                  component={ImageContainer}
                />
                <Route
                  path="/login"
                  component={LoginContainer}
                  onEnter={this.handleCheckIsGuest}
                />
                <Route
                  path="/signup"
                  component={SignupContainer}
                  onEnter={this.handleCheckIsGuest}
                />
                <Route
                  path="/tos"
                  component={TosPage}
                />
                <Route
                  path="/privacy"
                  component={PrivacyPage}
                />
              </Switch>
            </Layout>
          </Router>
        </Fragment>
      );
    }
}

export default App;
