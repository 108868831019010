import {connect} from "react-redux";
import TradeWidget from "../components/TradeWidget/TradeWidget";

import {getMarketSummaryAction, setWidgetActive, toggleModal} from "../../../actions/MarketsActions";
import {
  cancelOrderAction, createOrderAction, updateTempOrder, addTempOrder, removeTempOrder,
} from "../../../actions/OrdersActions";
import {fetchAccountBalance} from "../../../actions/BalanceActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : {};
      const accounts = state.user.user.data ? state.user.user.data.accounts : [];
      let symbol = null;

      if (widget.name) {
        const splittedWidget = widget.name.split("-");
        symbol = `${splittedWidget[1]}/${splittedWidget[0]}`;
      }

      const mappedOrders = [];
      state.orders.orders.data
          .filter((account) => widget.exchange && account.exchange.toLowerCase() === widget.exchange.toLowerCase())
          .forEach((account) => {
            account.list.forEach((order) => {
              if (order.symbol === symbol) {
                mappedOrders.push({
                  ...order,
                  accountName: account.name,
                });
              }
            });
          });

      const tempOrder = state.orders.tempOrders.find((order) => {
        return order.name === widget.name && order.exchange === widget.exchange;
      });

      return {
        accounts: accounts
            .filter((account) => widget.exchange && account.exchange.toLowerCase() === widget.exchange.toLowerCase()),
        accountBalance: state.balance.accountBalance.data,
        marketSummary: widget && widget.marketSummary,
        tempOrder: tempOrder || {},
        orders: mappedOrders,
        widget: widget,
      };
    }, {
      getMarketSummaryAction,
      cancelOrderAction,
      createOrderAction,
      setWidgetActive,
      fetchAccountBalance,
      updateTempOrder,
      addTempOrder,
      removeTempOrder,
      toggleModal,
    }
)(TradeWidget);
