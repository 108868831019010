import React, {Component} from "react";
import Slider from 'rc-slider';
import '../rc-slider.scss';
import styles from "../OrderSettings.scss";
class MarketOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //accountInput: null,
      value: 0,
      //selectedOptions: [],
      //account: props.accounts && props.accounts[0] ? props.accounts[0].name : "",
      //accountExchange: props.accounts && props.accounts[0] && props.accounts[0].exchange,
      //actionType: actionTypes[0].value,
      //orderType: orderTypes[0].value,
      //orderDuration: orderDurations[0].value,
      //dropdownOpen: false,
      //accountsDropdownOpen: false,
      //...initialTempOrder,
    };
  }
  setValue = (index) => {
    this.setState({
      value: index,
    });
  };
  onSliderChange = (value) => {
    this.setState({ value });
  };
  render() {
    const marks = {
      0: <div className={styles.dots}>0%</div>,
      100: <div className={styles.dots} style={{marginRight: '20px' }}>100%</div>,
    };
    return (
      <div>
        <div className={styles.orderTypesExpanded}>
          <div className={styles.greyDarkHeader}>{/*<img
            alt=""
            className={styles.Icon}
            height={14}
            src={`/img/icons/${currency.toUpperCase()}.svg?`+
              `exchange=${account.exchange}`
            }
          />*/} {/*{currency}*/} Quantity</div>
          <div>
            <input
              type="text"
              placeholder="0"
              style={{width: 120, marginRight: 10}}
              className={styles.outlineHeader}
            //onBlur={(event) => this.handleValueBlur("quantity", event.target.value)}
            //onChange={(event) => this.handleChange("quantity", event.target.value)}
            //value={parseFloat(quantity)}
            />

          </div>
          <div className="currentPrice">Price Impact:</div>
          <div className="currentPrice">0.24%</div>
        </div>

        <div className={styles.orderTypesExpanded}>
          <div className={styles.greyDarkHeader}>Total</div>
          <div>
            <input
              type="text"
              placeholder="0"
              className={styles.outlineHeader}
              style={{width: 145}}
              //onBlur={(event) => this.handleValueBlur("quantity", event.target.value)}
              //onChange={(event) => this.handleChange("quantity", event.target.value)}
              //value={parseFloat(quantity)}
            />
          </div>
        </div>
        <div className={styles.slider}>
          <Slider
            marks={marks}
            value={this.state.value}
            min={this.state.min}
            max={this.state.max}
            step={this.state.step}
            onChange={this.onSliderChange}
            dotStyle={{
              borderColor: '#666',
              height: 7,
              width: 7,
              backgroundColor: '#666',
            }}
            activeDotStyle={{
              borderColor: '#07e',
              height: 7,
              width: 7,
              backgroundColor: '#07e',
            }}
            trackStyle={{
              backgroundColor: '#07e',
              height: 2,
              marginTop: 2,
            }}
            handleStyle={{
              borderColor: '#07e',
              height: 10,
              width: 10,
              marginTop: -2,
              backgroundColor: '#07e',
            }}
            railStyle={{
              backgroundColor: '#666',
              height: 1,
              marginTop: 2,
            }}
          />
          <div className={styles.InlineInput1}>
            <input
              type="number"
              className={styles.outlineHeader}
              value={this.state.value}
            />
          </div>
        </div>

      </div>
    );
  }
}
export default MarketOrder;
