import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ScrollArea from "react-scrollbar";
import ReactTooltip from 'react-tooltip';
import UserStatus from "./UserStatus";
import ChannelItem from "./ChannelItem";
import FontAwesome from "../../../components/FontAwesome";
import {DropdownMenu, DropdownItem, Badge, UncontrolledDropdown, DropdownToggle} from "reactstrap";


import styles from "./ChannelsBlock.scss";

class ChannelsBlock extends Component {

  constructor() {
    super();
    this.state = {
      open: false,
      setOpen: false,
    };
  }

  setCurrentChannel = (channel) => {
    const {setChannelAction, toggleChatOpened} = this.props;
    toggleChatOpened();
    setChannelAction(channel);
  };

  drawUnreadBadge = (count) => {
    return (
      <Badge className={styles.Badge} color="danger">
        {count}
      </Badge>
    );
  };

  checkIfHasNewItems = (channel) => {
    const hasNewItems = this.props.hasNewItems.find((item) => item.channel === channel);
    return hasNewItems;
  };

  mapAllChannels = () => {
    const {channels} = this.props;
    return channels.filter((channel) => channel.type === "public" && !channel.parent).map((channel) => ({
      ...channel,
      children: channels.filter((item) => item.parent === channel._id),
    }));
  };

  render() {
    const {channels, toggleModal, channel, channelOpened} = this.props;
    const allChannels = this.mapAllChannels();
    const onlineUsers = channels.filter((channel) => channel.type === "direct" && channel.online).length;
    const directChannels = channels.filter((channel) => channel.type === "direct");
    const teamChannels = channels.filter((channel) => channel.type === "team");
    const {open, setOpen} = this.state;
    return (
      <div
        className={classNames(styles.SidebarLeft, {
          [styles.Hidden]: !channelOpened,
        })}
      >
        <ScrollArea
          className={styles.Channels}
          speed={0.8}
          horizontal={false}
          contentStyle={{overflow: "visible"}}
        >
          <div className={styles.searchIcon}>
            <FontAwesome
              name="search"
              prefix="far"
            />
            <input
              placeholder="Search..."/>
          </div>
          <div className={styles.accordion}>
            <input type="checkbox" id="title1" />
            <label htmlFor="title1">
              <FontAwesome
                wrapperClassName={styles.Icon}
                name="comment-alt-lines"
                prefix="fas"
              /> Channels
              <UncontrolledDropdown
                className={styles.AddButton}
                direction="up"
              >

                <DropdownToggle
                  //className={styles.left}
                  type="button"
                >
                  <FontAwesome
                    className={styles.commentAltLines}
                    name="plus"
                    prefix="far"
                  />
                </DropdownToggle>

                <DropdownMenu
                  className={styles.dropdownMenu}
                >
                  <DropdownItem
                    className={styles.dropItem}
                    onClick={() => toggleModal("channel")}
                  >
                    <span className={styles.dropdownItem}><FontAwesome
                      wrapperClassName={styles.Icon}
                      name="plus"
                      prefix="fas"
                    /> Create a channel</span>
                  </DropdownItem>
                  <DropdownItem
                    className={styles.dropItem}
                    onClick={() => toggleModal("channel")}
                  >
                    <span className={styles.dropdownItem}><FontAwesome
                      wrapperClassName={styles.Icon}
                      name="comment-alt-lines"
                      prefix="fas"
                    /> Join a channel</span>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
            </label>
            <div className={styles.content}>
              <ul className={styles.ChannelsList}>
                {allChannels.map((item) => (
                  <ChannelItem
                    key={item._id}
                    channel={channel}
                    item={item}
                    checkIfHasNewItems={this.checkIfHasNewItems}
                    setCurrentChannel={this.setCurrentChannel}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.accordion}>
            <input type="checkbox" id="title2" />
            <label htmlFor="title2">
              <FontAwesome
                wrapperClassName={styles.Icon}
                name="users"
                prefix="fas"
              /> Teams

              <UncontrolledDropdown
                className={styles.AddButton}
                direction="up"
              >
                <DropdownToggle
                  //className={styles.left}
                  type="button"
                >
                  <FontAwesome
                    className={styles.commentAltLines}
                    name="plus"
                    prefix="far"
                  />
                </DropdownToggle>

                <DropdownMenu className={styles.dropdownMenu}>
                  <DropdownItem
                    className={styles.dropItem}
                    onClick={() => toggleModal("teams")}
                  >
                    <span className={styles.dropdownItem}>
                      <FontAwesome
                        wrapperClassName={styles.Icon}
                        name="users"
                        prefix="fas"
                      /> Create a team</span>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>

            </label>
            <div className={styles.content}>
              <ul className={styles.ChannelsList}>
                {teamChannels.map((item) => (
                  <ChannelItem
                    key={item._id}
                    channel={channel}
                    item={item}
                    checkIfHasNewItems={this.checkIfHasNewItems}
                    setCurrentChannel={this.setCurrentChannel}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.accordion}>
            <input type="checkbox" id="title3" />
            <label htmlFor="title3">
              <FontAwesome
                wrapperClassName={styles.Icon}
                name="user-friends"
                prefix="fas"
              /> People ({onlineUsers})
              <UncontrolledDropdown
                className={styles.AddButton}
                direction="up"
              >
                <DropdownToggle
                  //className={styles.left}
                  type="button"
                >
                  <FontAwesome
                    className={styles.commentAltLines}
                    name="plus"
                    prefix="far"
                  />
                </DropdownToggle>

                <DropdownMenu className={styles.dropdownMenu}>
                  <DropdownItem
                    className={styles.dropItem}
                    onClick={() => toggleModal("people")}
                  >
                    <span className={styles.dropdownItem}><FontAwesome
                      wrapperClassName={styles.Icon}
                      name="user-friends"
                      prefix="fas"
                    /> add people</span>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
            </label>
            <div className={styles.content}>
              <ul className={styles.ChannelsList}>
                {directChannels.map((item) => {
                  const hasNewItems = this.checkIfHasNewItems(item._id);
                  return (
                    <li
                      className={classNames(styles.ChannelItem, {
                        [styles.Active]: channel && channel === item._id,
                        [styles.Unread]: hasNewItems,
                      })}
                      key={item._id}
                      onClick={() => this.setCurrentChannel(item._id)}
                    >
                      <UserStatus isOnline={item.online}/> {item.title}
                      {hasNewItems && this.drawUnreadBadge(hasNewItems.count)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </ScrollArea>
        <div className={classNames(styles.Channels, styles.Settings)}>
          <h3 className={styles.Label}>
            <FontAwesome
              wrapperClassName={styles.Icon}
              name="sliders-v"
              prefix="fas"
            /> Settings
            <button
              className={styles.AddButton1}
              type="button"
              onClick={() => toggleModal("profileSettings", {})}
            >
              <FontAwesome name="cog" prefix="fas"/>
            </button>
          </h3>
        </div>
      </div>
    );
  }
}

ChannelsBlock.propTypes = {
  channel: PropTypes.string,
  channelOpened: PropTypes.bool,
  channels: PropTypes.array,
  hasNewItems: PropTypes.array,
  setChannelAction: PropTypes.func,
  toggleChatOpened: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
};

ChannelsBlock.defaultProps = {
  channel: undefined,
  channelOpened: false,
  channels: [],
  hasNewItems: [],
  setChannelAction: () => false,
  toggleChatOpened: () => false,
};

export default ChannelsBlock;
