import React from 'react';

const TimeInForce = [
  {
    label: "Order Expires",
    options: [
      { label: <span><b>GTC</b> Good ‘Til Canceled</span>, value: "GTC" },
    ]
  },
];
export default TimeInForce;
