
export default [
  {
    title: "Lines",
  }, {
    id: "brush",
    label: "Freehand Draw",
    points: 2,
    svg: <img alt="Draw" src="/img/icons/draw.svg" />
  }, {
    id: "trendline",
    label: "Line",
    points: 2,
    svg: <img alt="Line" src="/img/icons/trendline.svg" />
  }, {
    id: "trendangle",
    label: "Angle Line",
    points: 2,
    svg: <img alt="Angle Line" src="/img/icons/trendangle.svg" />
  }, {
    id: "horizontalline",
    label: "Horizontal Line",
    points: 1,
    svg: <img alt="Horizontal Line" src="/img/icons/hline.svg" />
  }, {
    id: "horizontalray",
    label: "Horizontal Ray",
    points: 1,
    svg: <img alt="Horizontal Ray" src="/img/icons/hray.svg" />
  }, {
    id: "verticalline",
    label: "Vertical Line",
    points: 1,
    svg: <img alt="Vertical Line" src="/img/icons/vline.svg" />
  }, {
    id: "arrowline",
    label: "Line Arrow",
    points: 2,
    svg: <img alt="Line Arrow" src="/img/icons/arrow.svg" />
  }, {
    id: "ray",
    label: "Ray",
    points: 2,
    svg: <img alt="Ray" src="/img/icons/ray.svg" />
  }, {
    id: "extended",
    label: "Extended",
    points: 2,
    svg: <img alt="Extended" src="/img/icons/extend.svg" />
  }, {
    id: "parallelchannel",
    label: "Price Channel",
    points: 3,
    svg: <img alt="Price Channel" src="/img/icons/parallel.svg" />
  },
  {
    title: "Shapes",
  }, {
    id: "rectangle",
    label: "Rectangle",
    points: 2,
    svg: <img alt="Rectangle" src="/img/icons/rectangle.svg" />
  }, {
    id: "ellipse",
    label: "Ellipse",
    points: 3,
    svg: <img alt="Ellipse" src="/img/icons/ellipse.svg" />
  }, {
    id: "triangle",
    label: "Triangle",
    points: 3,
    svg: <img alt="Triangle" src="/img/icons/triangle.svg" />
  },
  {
    title: "Text Tools",
  }, {
    id: "text",
    label: "Text",
    points: 1,
    svg: <img alt="Text" src="/img/icons/text.svg" />
  },
];
