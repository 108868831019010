import {Col, Input, Row} from "reactstrap";
import React, {useState} from "react";
import PropTypes from "prop-types";
import WorksheetEditButton from "./WorksheetEditButton";
import WorksheetDeleteButton from "./WorksheetDeleteButton";
import WorksheetAddButton from "./WorksheetAddButton";
import classes from './WorksheetSettingsModal.scss';
import styles from "./WorksheetSettingsModal.scss";
import FontAwesome from "../../../../components/FontAwesome";
import ReactTooltip from 'react-tooltip';

const WorkSheetItem = ({
  provided,
  snapshot,
  item,
  handleChangeValue,
  getItemStyle,
  handleRemoveWorksheet,
  isRemoveAvailable,
  handleAddWorksheet
}) => {
  const [isActive, setIsActive]=useState(false);
  return (
    <div
      className={styles.hideIcon}
      onMouseOver={()=>setIsActive(true)}
      onFocus={()=>setIsActive(true)}
      onMouseLeave={()=>setIsActive(false)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style),
      }}
    >
      <Row>
        <Col xs={11}>
          {item.isEdit ?
            <Input
              type="text"
              autoFocus
              value={item.name}
              style={{
                background: "#333740",
                fontSize: 13,
                paddingLeft: 35
              }}
              onChange={({target: {value}}) => handleChangeValue({
                ...item,
                name: value
              })}
            /> : <div

              style={{
                background: "#333740",
                padding: '5px 10px',
              }}>
              <img style={{marginRight: 10, opacity: .5}} className="Icon" alt="list" src="/img/icons/list.svg" />
              {item.name}
              <a data-tip data-for='dragButton'>
                <div className={styles.dragIcon}>
                  <span className={styles.tooltipDrag}>
                    <FontAwesome name="ellipsis-v"/>
                    <FontAwesome name="ellipsis-v"/>
                  </span>
                </div>
              </a>
              <ReactTooltip
                id='dragButton'
                offset="{'top': -7, 'left': -435}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>Reorder list</span>
              </ReactTooltip>
            </div>}
        </Col>
        <Col xs={2} className={classes.itemAction} style={{display: isActive?"flex":"none"}}>
          <WorksheetEditButton handleChangeValue={handleChangeValue} item={item}/>
          <WorksheetAddButton handleAddWorksheet={handleAddWorksheet}/>
          {isRemoveAvailable&&<WorksheetDeleteButton handleRemoveWorksheet={() => handleRemoveWorksheet(item.id)}/>}

        </Col>
      </Row>
    </div>
  );
};

WorkSheetItem.propTypes = {
  provided: PropTypes.object.isRequired,
  isRemoveAvailable: PropTypes.bool.isRequired,
  snapshot: PropTypes.object.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  getItemStyle: PropTypes.func.isRequired,
  handleAddWorksheet: PropTypes.func.isRequired,
  handleRemoveWorksheet: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

WorkSheetItem.defaultProps = {};

export default WorkSheetItem;
