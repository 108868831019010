import {api} from "../utils/apiUtils";

const createOrder = (data, name) => api.post("order/create", {data, name});
const cancelOrder = (name, id, symbol) => api.post("order/cancel", {name, id, symbol});
const renewOrder = (name, order, force) => api.post("order/redo", {name, order, force});

export {
  createOrder,
  cancelOrder,
  renewOrder,
};
