import {connect} from "react-redux";
import WidgetToolbar from "../components/WidgetToolbar";

import {removeWidget, updateWidget, toggleModal} from "../../../actions/MarketsActions";
import {saveImageAction} from "../../../actions/ImageActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      return {
        activeWidget: activeWidget,
        user: state.user.user.data,
      };
    }, {
      removeWidget,
      saveImageAction,
      updateWidget,
      toggleModal,
    }
)(WidgetToolbar);
