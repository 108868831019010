// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zya6M5EG014DVwXQu5ZY3{font-size:\"12px\";alignment-baseline:\"hanging\";dominant-baseline:\"hanging\"}._186e2ieSmbDKrWINnoTu0l{line-height:1;position:absolute;top:10;z-index:2;pointer-events:none}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/ChartWidget/CanvasChart/OHLCTooltip.scss"],"names":[],"mappings":"AACE,uBACE,gBAAA,CACA,4BAAA,CACA,2BAAA,CAEF,yBACE,aAAA,CACA,iBAAA,CACA,MAAA,CACA,SAAA,CACA,mBAAA","sourcesContent":[":local {\n  .textlabel{\n    font-size: \"12px\";\n    alignment-baseline : \"hanging\";\n    dominant-baseline: \"hanging\"\n  }\n  .test {\n    line-height: 1;\n    position: absolute;\n    top: 10;\n    z-index: 2;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textlabel": "Zya6M5EG014DVwXQu5ZY3",
	"test": "_186e2ieSmbDKrWINnoTu0l"
};
export default ___CSS_LOADER_EXPORT___;
