import FontAwesome from "../../../../components/FontAwesome";
import PropTypes from "prop-types";
import styles from "./WorksheetSettingsModal.scss";
import ReactTooltip from 'react-tooltip';

const WorksheetDeleteButton = ({handleRemoveWorksheet}) => {
  return (<button
    onClick={() => handleRemoveWorksheet()}
  >
    <a data-tip data-for='deleteButton'>
      <FontAwesome
        name="trash"
        prefix="fas"
        className={styles.deleteIcon}
      />
    </a>
    <ReactTooltip
      id='deleteButton'
      offset="{'top': 20, 'left': -585}"
      arrowColor='transparent'
      className={styles.buttonTooltip}
      effect='solid'
    >
      <span>Delete</span>
    </ReactTooltip>
  </button>);
};

WorksheetDeleteButton.propTypes = {
  handleRemoveWorksheet: PropTypes.func.isRequired,
};
export default WorksheetDeleteButton;
