import React from "react";
import {render} from "react-dom";
import {Provider} from "react-redux";
import configureStore from "./utils/createStore";
import {init as websocketInit} from "./utils/websockets";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/base.scss";
import App from "./pages/App";
import './cc.min';
const store = configureStore();
websocketInit(store);
render(
    <Provider store={store}>
    <App />
  </Provider>,
    document.getElementById("root")
);
