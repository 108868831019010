import {GET_FEED, GET_NEW_FEED, UPDATE_FILTERS} from "../const";
import {unionBy, uniqBy} from "lodash";

const initialState = {
  lastCount: 0,
  filters: [],
  feed: {
    loading: false,
    items: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEED:
      const items = action.isNew ? action.data : unionBy(state.feed.items, action.data, "id");
      return {
        ...state,
        lastCount: action.isNew ? 0 : state.lastCount,
        feed: {
          ...state.feed,
          items: uniqBy(items, (item) => item.id),
        },
      };
    case GET_NEW_FEED:
      return {
        ...state,
        lastCount: action.payload.data,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
