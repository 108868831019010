import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as d3 from "d3";
import numeral from "numeral";
import ReactTooltip from 'react-tooltip';
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import {SYMBOLS_PATH} from "../../../../constants/locationPathnames";
import styles from "./BubbleWidget.scss";
import { COLS } from "../../../../constants/MarketsConstants";
import Loader from "../../../../components/Loader";

const format = "0,0.[00]";
const formatDollar = "$0,0.[00]";
const percentFormat = "0,0.[00]";

class BubbleWidget extends Component {
  constructor() {
    super();

    this.interval = null;
    this.wrapper = null;
    this.zero = "#bcb2b1";
    this.negativeColor = d3.scale.linear().range(["#aa2121", "#ed7171"]);
    this.positiveColor = d3.scale.linear().range(["#7ec17e", "#215e2c"]);
    this.state = {
      quoteAsset: null,
      height: 0,
      width: 0,
      node: null,
    };
  }

  componentDidMount() {
    this.updateInterval();
  }

  componentDidUpdate(prevProps) {
    const {widget} = this.props;
    if (prevProps.widget && widget && prevProps.widget.exchange !== widget.exchange) {
      this.updateInterval();
    } else if (
      prevProps.widget.h !== widget.h ||
      prevProps.widget.w !== widget.w ||
      prevProps.widget.expanded !== widget.expanded ||
      prevProps.widget.fW !== widget.fW ||
      prevProps.widget.fH !== widget.fH ||
      prevProps.widget.unpinned !== widget.unpinned) {
      this.handleResize();
    } else {
      if (widget.data && widget.data.length > 0 && !this.state.quoteAsset) {
        let quoteAsset = "USD"; //USDT, USDC;
        if (widget.data.findIndex((item) => item.symbol && item.symbol.includes("/") &&
                          (item.symbol.split("/")[0].toUpperCase() === quoteAsset ||
                          item.symbol.split("/")[1].toUpperCase() === quoteAsset)) < 0) {
          quoteAsset = "USDT";
        }
        this.setState({quoteAsset});
      }
      this.update();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateInterval = () => {
    const {getMarketSummaries, widget} = this.props;
    clearInterval(this.interval);
    getMarketSummaries(widget.i);
    this.interval = setInterval(() => {
      getMarketSummaries(widget.i);
    }, 5000);
  };

  handleResize = () => {
    const height = this.wrapper && this.wrapper.clientHeight || 0;
    const width = this.wrapper && this.wrapper.clientWidth || 0;
    this.setState({
      height, width,
    });
  };

  initChart = (el) => {
    this.wrapper = el;
    this.handleResize();
  };

  mapData = () => {
    const {widget: {exchange, data}} = this.props;
    let filteredData = data.filter((item) => item.symbol && item.quoteVolume && item.symbol.includes("/") &&
                      (item.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ||
                      item.symbol.split("/")[1].toUpperCase() === this.state.quoteAsset));

    filteredData = filteredData.sort((a, b) =>
      (b.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ? b.baseVolume : b.quoteVolume) -
      (a.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ? a.baseVolume : a.quoteVolume)
    ).slice(0, 150); //max nodes = 150;

    return {
      name: exchange || "crypto",
      children: filteredData.map(d => {
        return {
          name: d.symbol,
          volume: d.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ? +d.baseVolume : +d.quoteVolume,
          change: +d.change,
          price: +d.close,
        };
      })
    };
  };

  getColor = (item) => {
    if (item.change && item.change !== 0) {
      return item.change > 0 ? this.positiveColor(item.change) : this.negativeColor(item.change);
    }
    return this.zero;
  };

  getStyle = (attr, d, type = "name") => {
    let fontSize = 12;
    switch (attr) {
      case "font-weight":
        return type === "name" ? 600 : 400;
      case "opacity":
        if (type === "name") {
          return (d.dy > 5 && d.dx > 5) ? 1 : 0;
        } else if (type === "name2") {
          return (d.dy > 5 && d.dx > 5) ? 1 : 0;
        } else {
          return (d.dy > 20 && d.dx > 20) ? 1 : 0;
        }
      case "font-size":
        if (d.dx > 420) {
          fontSize = 60;
        } else if (d.dx > 360) {
          fontSize = 52;
        } else if (d.dx > 300) {
          fontSize = 44;
        } else if (d.dx > 120) {
          fontSize = 28;
        } else if (d.dx > 110) {
          fontSize = 20;
        } else if (d.dx > 100) {
          fontSize = 19;
        } else if (d.dx > 90) {
          fontSize = 18;
        } else if (d.dx > 80) {
          fontSize = 16;
        } else if (d.dx > 50) {
          fontSize = 10;
        } else if (d.dx > 40) {
          fontSize = 7;
        } else if (d.dx > 30) {
          fontSize = 5;
        } else if (d.dx > 20) {
          fontSize = 4;
        } else if (d.dx > 15) {
          fontSize = 3;
        } else if (d.dx > 10) {
          fontSize = 2;
        } else if (d.dx > 5) {
          fontSize = 1;
        }
        if (type === "price") {
          if (d.dx > 420) {
            fontSize = 60;
          } else if (d.dx > 360) {
            fontSize = 52;
          } else if (d.dx > 300) {
            fontSize = 44;
          } else if (d.dx > 120) {
            fontSize = 24;
          } else if (d.dx > 110) {
            fontSize = 16;
          } else if (d.dx > 100) {
            fontSize = 14;
          } else if (d.dx > 90) {
            fontSize = 15;
          } else if (d.dx > 80) {
            fontSize = 14;
          } else if (d.dx > 50) {
            fontSize = 9;
          } else if (d.dx > 40) {
            fontSize = 7;
          } else if (d.dx > 30) {
            fontSize = 5;
          } else if (d.dx > 20) {
            fontSize = 4;
          } else if (d.dx > 15) {
            fontSize = 3;
          } else if (d.dx > 10) {
            fontSize = 2;
          } else if (d.dx > 5) {
            fontSize = 1;
          }
        } else if (type === "change") {
          if (d.dx > 420) {
            fontSize = 60;
          } else if (d.dx > 360) {
            fontSize = 52;
          } else if (d.dx > 300) {
            fontSize = 44;
          } else if (d.dx > 120) {
            fontSize = 20;
          } else if (d.dx > 110) {
            fontSize = 16;
          } else if (d.dx > 100) {
            fontSize = 14;
          } else if (d.dx > 90) {
            fontSize = 15;
          } else if (d.dx > 80) {
            fontSize = 14;
          } else if (d.dx > 50) {
            fontSize = 8;
          } else if (d.dx > 40) {
            fontSize = 7;
          } else if (d.dx > 30) {
            fontSize = 5;
          } else if (d.dx > 20) {
            fontSize = 4;
          } else if (d.dx > 15) {
            fontSize = 3;
          } else if (d.dx > 10) {
            fontSize = 2;
          } else if (d.dx > 5) {
            fontSize = 1;
          }
        }
        return fontSize;
    }
  };

  getAttr = (attr, d, type) => {
  }

  handleMouseOver = (d) => {
    this.setState({
      market: d.name.split("/")[0],
      name: d.name.split("/")[0],
      change: d.change,
      price: d.price,
    });
  };

  update = () => {
    const {widget: {data}} = this.props;
    const {height, width, node} = this.state;
    const w = width;
    const h = height;
    const root = this.mapData();
    let svg;

    const negativeValues = data.filter((item) => item.change < 0);
    const positiveValues = data.filter((item) => item.change > 0);
    this.negativeColor.domain([
      d3.min(negativeValues, (item) => item.change),
      d3.max(negativeValues, (item) => item.change),
    ]);
    this.positiveColor.domain([
      d3.min(positiveValues, (item) => item.change),
      d3.max(positiveValues, (item) => item.change),
    ]);
    // eslint-disable-next-line
    //debugger;
    const circle = d3.layout.treemap()
        .sort((a, b) => b.value - a.value)
        .size([w, h])
        .value((d) => d.change);

    {/*const zoom = (d) => {
      x.domain([d.x, d.x + d.dx]);
      y.domain([d.y, d.y + d.dy]);

      this.setState({
        node: d.parent ? d : null,
      });
    };*/}

    if (d3.select(this.wrapper).select("svg").select("g.paper").empty()) {
      svg = d3.select(this.wrapper)
          .select("svg")
          .append("g")
          .attr("class", "paper")
          .attr("transform", "translate(.5,.5)");
    } else {
      svg = d3.select(this.wrapper)
          .select("svg")
          .select("g.paper")
          .attr("transform", "translate(.5,.5)");
    }

    const nodes = circle.nodes(node || root).filter((d) => !d.children);

    const cellNodes = svg.selectAll("g.cell").data(nodes);
    cellNodes.exit().remove();

    const cell = cellNodes.enter()
        .append("g")
        .attr("class", "cell")
        .attr("transform", (d) => "translate(" + d.x + "," + d.y + ")");

    cell.append("circle")
        .attr("class", "rect")
        .attr("r", (d) => d.dx / 10)
        .attr("cx", (d) => d.dx / 5)
        .attr("cy", (d) => d.dy / 5)
        .on("mouseover", this.handleMouseOver)
        .on("mouseleave", this.handleMouseLeave)
        .style("fill", this.getColor);

    cell.append("text")
        .attr("class", "name", "name2")
        .attr("x", (d) => d.dx / 2)
        .attr("y", (d) => d.dy / 2)
        .attr("dy", (d) => (d.dy > 10 && d.dx > 10) ? "-0.4em" : "-0.4em")
        .attr("text-anchor", "middle")
        .style("font-size", (d) => (d.name.split('/')[0] + " / " + d.name.split('/')[1]) ? Math.round(d.dx / 3) / 2.5 : Math.round(d.dx / 3))        .style("opacity", (d) => this.getStyle("opacity", d, "name"))
        .style("font-weight", (d) => this.getStyle("font-weight", d, "name"))
        .text((d) => (d.dy > 40 && d.dx > 40) ? (d.name.split('/')[0] + " / " + d.name.split('/')[1]) : (d.name.split('/')[0] + "/"));

    cell.append("text")
        .attr("class", "price")
        .attr("x", (d) => d.dx / 2)
        .attr("y", (d) => d.dy / 2)
        .attr("dy", (d) => `${Math.round(this.getStyle("font-size", d, "name")/2)}px`)
        .attr("text-anchor", "middle")
        .style("font-size", (d) => `${this.getStyle("font-size", d, "price")}px`)
        .style("opacity", (d) => this.getStyle("opacity", d, "price"))
        .text((d) => numeral(d.price).format(formatDollar));

    cell.append("text")
        .attr("class", "change")
        .attr("x", (d) => d.dx / 2)
        .attr("y", (d) => d.dy / 2)
        .attr("dy", (d) => `${this.getStyle("font-size", d, "name")/2 + this.getStyle("font-size", d, "price")}px`)
        .attr("text-anchor", "middle")
        .style("font-size", (d) => `${this.getStyle("font-size", d, "change")}px`)
        .style("opacity", (d) => this.getStyle("opacity", d, "change"))
        .text((d) => d.change > 0 ?
        `+${numeral(d.change).format(percentFormat)}%` : `${numeral(d.change).format(percentFormat)}%`);

    const cellUpdate = svg.selectAll("g.cell").transition()
        .attr("class", "cell")
        .attr("transform", (d) => "translate(" + d.x + "," + d.y + ")");

    cellUpdate.select("rect.rect")
        .attr("r", (d) => d.dx / 10)
        .attr("cx", (d) => d.dx / 5)
        .attr("cy", (d) => d.dy / 5)
        .style("fill", this.getColor);

    cellUpdate.select("text.name")
        .attr("x", (d) => d.dx / 2)
        .attr("y", (d) => d.dy / 2)
        .style("font-size", (d) => (d.name.split('/')[0] + " / " + d.name.split('/')[1]) ? Math.round(d.dx / 3) / 2.5 : Math.round(d.dx / 3))        .style("opacity", (d) => this.getStyle("opacity", d, "name"))
        .text((d) => (d.dy > 10 && d.dx > 10) ? (d.name.split('/')[0] + " / " + d.name.split('/')[1]) : (d.name.split('/')[0] + "/"));

    cellUpdate.select("text.name2")
        .attr("x", (d) => d.dx / 2)
        .attr("y", (d) => d.dy / 2)
        .style("font-size", (d) => `${this.getStyle("font-size", d, "name")}px`)
        .style("opacity", (d) => this.getStyle("opacity", d, "name2"))
        .text((d) => (d.dy > 10 && d.dx > 10) ? "" : (d.name.split('/')[1]));

    cellUpdate.select("text.price")
        .attr("x", (d) => d.dx / 2)
        .attr("y", (d) => d.dy / 2)
        .attr("dy", (d) => `${Math.round(this.getStyle("font-size", d, "name")/2)}px`)
        .style("font-size", (d) => `${this.getStyle("font-size", d, "price")}px`)
        .style("opacity", (d) => this.getStyle("opacity", d, "price"))
        .text((d) => numeral(d.price).format(formatDollar));

    cellUpdate.select("text.change")
        .attr("x", (d) => d.dx / 2)
        .attr("y", (d) => d.dy / 2).style("font-size", (d) => `${this.getStyle("font-size", d, "change")}px`)
        .attr("dy", (d) => `${this.getStyle("font-size", d, "name")/2 + this.getStyle("font-size", d, "price")}px`)
        .style("opacity", (d) => this.getStyle("opacity", d, "change"))
        .text((d) => d.change > 0 ?
        `+${numeral(d.change).format(percentFormat)}%` : `${numeral(d.change).format(percentFormat)}%`);
  };

  render() {
    const {height, market, width, name, change, price} = this.state;
    const {widget} = this.props;

    return (
      <div
        className="widgetWrapper"
        style={widget.expanded ? {height: `${this.props.heightUnit * COLS - 5}px`} : {}}>
        <WidgetToolbar {...this.props}/>
        {widget.loading || widget.data.length === 0 ? (
          <Loader/>

          ) : (
            <div
              data-tip
              data-for='global'
              className={classNames("disableDrag", styles.Wrapper)}
              ref={this.initChart}
            >
              <div>
                <ReactTooltip
                  id='global'
                  aria-haspopup='true'
                  place='top'
                  className={styles.buttonTooltip}
                >
                  <div className={styles.tooltiptext}>
                    <img
                      alt=""
                      height={18}
                      src={`${SYMBOLS_PATH}${name}.png`}
                    /> {name}
                  </div>
                  <div className={styles.tooltiptext}>Price: ${numeral(price).format(format)}</div>
                  <div className={styles.tooltiptext}>
                    24h Change: <b>{change > 0 ? `+${numeral(change).format(format)}%` : `
                      ${numeral(change).format(format)}%`}</b>
                  </div>
                </ReactTooltip>

              </div>
              <svg
                className="bubblechart"
                height={height}
                width={width}
              />
            </div>
        )}
      </div>
    );
  }
}

BubbleWidget.propTypes = {
  activeWidget: PropTypes.object,
  filter: PropTypes.string,
  getMarketSummaries: PropTypes.func,
  updateActiveWidget: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
  heightUnit: PropTypes.number
};

BubbleWidget.defaultProps = {
  activeWidget: undefined,
  filter: "",
  getMarketSummaries: () => false,
  updateActiveWidget: () => false,
  widget: {},
  heightUnit: 250
};

export default BubbleWidget;
