/* eslint max-len: 0 */
import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import styles from "./DrawingToolMenu.scss";

const drawingItems = [
  [{
    title: "Pointers",
  }, {
    id: "cross",
    label: "Cross",
    points: 0,
    svg: <img alt="Crosshair" src="/img/icons/cross.svg" />
  }, {
    id: "dot",
    label: "Dot",
    points: 0,
    svg: <img alt="Dot" src="/img/icons/dot.svg" />
  }, {
    id: "arrow",
    label: "Cursor",
    points: 0,
    svg: <img alt="Cursor" src="/img/icons/cursor.svg" />
  }],
  [{
    title: "Lines",
  }, {
    id: "brush",
    label: "Freehand Draw",
    points: 2,
    svg: <img alt="Draw" src="/img/icons/draw.svg" />
  }, {
    id: "trendline",
    label: "Line",
    points: 2,
    svg: <img alt="Line" src="/img/icons/trendline.svg" />
  }, {
    id: "trendangle",
    label: "Angle Line",
    points: 2,
    svg: <img alt="Angle Line" src="/img/icons/trendangle.svg" />
  }, {
    id: "horizontalline",
    label: "Horizontal Line",
    points: 1,
    svg: <img alt="Horizontal Line" src="/img/icons/hline.svg" />
  }, {
    id: "horizontalray",
    label: "Horizontal Ray",
    points: 1,
    svg: <img alt="Horizontal Ray" src="/img/icons/hray.svg" />
  }, {
    id: "verticalline",
    label: "Vertical Line",
    points: 1,
    svg: <img alt="Vertical Line" src="/img/icons/vline.svg" />
  }, {
    id: "arrowline",
    label: "Line Arrow",
    points: 2,
    svg: <img alt="Line Arrow" src="/img/icons/arrow.svg" />
  }, {
    id: "ray",
    label: "Ray",
    points: 2,
    svg: <img alt="Ray" src="/img/icons/ray.svg" />
  }, {
    id: "extended",
    label: "Extended",
    points: 2,
    svg: <img alt="Extended" src="/img/icons/extend.svg" />
  }, {
    id: "parallelchannel",
    label: "Price Channel",
    points: 3,
    svg: <img alt="Price Channel" src="/img/icons/parallel.svg" />
  }],
  [{
    title: "Fibonacci Tools",
  }, {
    id: "fibretracement",
    label: "Fibonacci Retracement",
    points: 2,
    svg: <img alt="Fibonacci Retracement" src="/img/icons/fibretracement.svg" />
  }, {
    id: "trendbasedfibextension",
    label: "Fibonacci Extension",
    points: 3,
    svg: <img alt="Fibonacci Extension" src="/img/icons/fibext.svg" />
  }, {
    id: "fibspeedresistancefan",
    label: "Fibonacci Fan",
    points: 3,
    svg: <img alt="Fibonacci Fan" src="/img/icons/fibfan.svg" />
  }, {
    id: "fibtimezone",
    label: "Fibonacci Time Zone",
    points: 2,
    svg: <img alt="Fibonacci Time Zone" src="/img/icons/fibtime.svg" />
  }, {
    id: "trendbasedfibtime",
    label: "Fibonacci Time Extension",
    points: 3,
    svg: <img alt="Fibonacci Time Extension" src="/img/icons/fibtimext.svg" />
  }, {
    id: "fibcycles",
    label: "Fibonacci Ellipse",
    points: 2,
    svg: <img alt="Fibonacci Ellipse" src="/img/icons/fibcircle.svg" />
  }, {
    id: "fibspeedresistancearcs",
    label: "Fibonacci Arc",
    points: 2,
    svg: <img alt="Fibonacci Arc" src="/img/icons/fibarc.svg" />
  }, {
    id: "fibwedge",
    label: "Fibonacci Wedge",
    points: 3,
    svg: <img alt="Fibonacci Wedge" src="/img/icons/fibwedge.svg" />
  }, {
    id: "fibchannel",
    label: "Fibonacci Channel",
    points: 3,
    svg: <img alt="Fibonacci Channel" src="/img/icons/fibchannel.svg" />
  }],
  [{
    title: "Gann Tools",
  }, {
    id: "dynamicsq9",
    label: "Gann Dynamic Square of 9",
    points: 2,
    svg: <img alt="Dynamic Square of 9" src="/img/icons/gannlines.svg" />
  }, {
    id: "gannbox",
    label: "Gann Grid",
    points: 2,
    svg: <img alt="Gann Grid" src="/img/icons/gannbox.svg" />
  }, {
    id: "gannfan",
    label: "Gann Fan",
    points: 2,
    svg: <img alt="Gann Fan" src="/img/icons/gannfan.svg" />
  }],
  [{
    title: "Pitchfork Tools",
  }, {
    id: "pitchfork",
    label: "Pitchfork",
    points: 3,
    svg: <img alt="Pitchfork" src="/img/icons/pitchfork.svg" />
  }, {
    id: "schiffpitchfork",
    label: "Schiff Pitchfork",
    points: 3,
    svg: <img alt="Schiff Pitchfork" src="/img/icons/schiffpitchfork.svg" />
  }, {
    id: "modifiedschiffpitchfork",
    label: "Modified Schiff Pitchfork",
    points: 3,
    svg: <img alt="Modified Schiff Pitchfork" src="/img/icons/schiffpitchfork.svg" />
  }, {
    id: "insidepitchfork",
    label: "Inside Pitchfork",
    points: 3,
    svg: <img alt="Modified Schiff Pitchfork" src="/img/icons/insidepitchfork.svg" />
  }, {
    id: "pitchfan",
    label: "Pitchfan",
    points: 3,
    svg: <img alt="Pitchfan" src="/img/icons/pitchfan.svg" />
  }],
  [{
    title: "Shapes",
  }, {
    id: "rectangle",
    label: "Rectangle",
    points: 2,
    svg: <img alt="Rectangle" src="/img/icons/rectangle.svg" />
  }, {
    id: "ellipse",
    label: "Ellipse",
    points: 3,
    svg: <img alt="Ellipse" src="/img/icons/ellipse.svg" />
  }, {
    id: "triangle",
    label: "Triangle",
    points: 3,
    svg: <img alt="Triangle" src="/img/icons/triangle.svg" />
  }],
  [{
    title: "Text Tools",
  }, {
    id: "text",
    label: "Text",
    points: 1,
    svg: <img alt="Text" src="/img/icons/text.svg" />
  }],
  [{
    title: "Chart Type",
  }, {
    id: "chart",
    label: "Candlestick",
    points: 1,
    svg: <img alt="candlestick" src="/img/icons/candlestick.svg" />
  }],
  [{
    title: "Show/Hide Layers",
  }, {
    id: "hideindicators",
    label: "Hide Indicator Layers",
    points: 1,
    svg: <img alt="layers" src="/img/icons/hideindicators.svg" />
  }, {
    id: "hidedrawings",
    label: "Hide Drawing Layers",
    points: 1,
    svg: <img alt="layers" src="/img/icons/hidedrawings.svg" />
  }, {
    id: "hideall",
    label: "Hide All Layers",
    points: 1,
    svg: <img alt="layers" src="/img/icons/hide.svg" />
  }],
  [{
    title: "Delete",
  }, {
    id: "delindicators",
    label: "All Indicators",
    points: 0,
    svg: <img alt="delete" src="/img/icons/trashindicators.svg" />
  }, {
    id: "deldrawingtool",
    label: "All Drawings",
    points: 0,
    svg: <img alt="delete" src="/img/icons/trashdrawings.svg" />
  }, {
    id: "delall",
    label: "All Indicators & Drawings",
    points: 0,
    svg: <img alt="trash" src="/img/icons/trash.svg" />
  }]
];

class DrawingToolMenu extends PureComponent {
  constructor() {
    super();
    this.state = {
    };
  }
  handleDrawingItem = (itemId, points) => {
    const itemsAry = drawingItems[this.props.index - 1];
    const ele = itemsAry.find((item)=>item.id == itemId);
    const svg = ele.svg;
    this.props.handleDrawingItem(this.props.index, itemId, points, svg);
  }
  render() {
    const itemsAry = drawingItems[this.props.index - 1];
    const top = (this.props.index - 1) * 33 + 'px';
    return (
      <div className={styles.drawingMenu + ' drawingMenu'} style={{top}}>
        <ul>
          {
            itemsAry && itemsAry.map((item)=>{
              return (
                <li
                  key={item.id}
                  onClick={(e)=>this.handleDrawingItem(item.id, item.points)}
                >
                  <div className={styles.drawingItem}>
                    {
                    item.title ?
                      <div className={styles.drawingItemTitle}>
                        {item.title}
                      </div> : ''
                    }
                    {
                      item.svg ?
                        <div className={styles.drawingItemIcon}>
                          {item.svg}
                        </div> : ''
                    }
                    <span>{item.label}</span>
                  </div>
                </li>);
            })
          }
        </ul>
      </div>
    );
  }
}

DrawingToolMenu.propTypes = {
  handleDrawingItem: PropTypes.func,
  index: PropTypes.number,
};

DrawingToolMenu.defaultProps = {
  index: 1,
  handleDrawingItem: () => {}
};

export default DrawingToolMenu;
