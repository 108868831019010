import numeral from "numeral";
import {format} from "d3-format";

const defaultPriceCoordinate = {
  bgFill: "#FFFFFF",
  bgOpacity: 1,

  stroke: "#6574CD",
  strokeOpacity: 1,
  strokeDasharray: "ShortDash2",
  strokeWidth: 1,

  textFill: "#6574CD",
  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: "normal",
  text: "Alert",
  textBox: {
    height: 24,
    left: 20,
    padding: { left: 10, right: 5 },
    closeIcon: {
      padding: { left: 5, right: 8 },
      width: 8,
    }
  },
  edge: {
    stroke: "#6574CD",
    strokeOpacity: 1,
    strokeWidth: 1,

    fill: "#FFFFFF",
    fillOpacity: 1,
    orient: "right",
    at: "right",
    arrowWidth: 10,
    dx: 0,
    rectWidth: 50,
    rectHeight: 20,
    displayFormat: format(".2f"),
  }
};

export const defaultTempOrder = {
  ...defaultPriceCoordinate,
  yValue: 0,
  draggable: true,
  bgFill: "#6BA583", // #DB0000
  bgOpacity: 1,
  stroke: "#ffffff",
  strokeOpacity: 0.5,
  textFill: "#ffffff",
  text: "Temp order",
  fontSize: 11,
  textBox: {
    ...defaultPriceCoordinate.textBox,
    left: 10,
    closeIcon: {
      padding: {left: 2, right: 8},
      width: 10,
    },
  },
  edge: {
    ...defaultPriceCoordinate.edge,
    fill: "#6BA583", // #DB0000
    fillOpacity: 1,
    arrowWidth: 0,
    stroke: "#ffffff",
    strokeOpacity: 0.5,
    displayFormat: (value) => numeral(value).format("0,0.[00000000]"),
  },
  name: null,
  exchange: null,
  amount: 0,
  type: "BUY", // SELL
};

export const defaultActiveOrder = {
  ...defaultPriceCoordinate,
  yValue: 0,
  draggable: true,
  bgFill: "#6BA583", // #DB0000
  bgOpacity: 1,
  stroke: "#ffffff",
  strokeOpacity: 0.7,
  textFill: "#ffffff",
  text: "Temp order",
  fontSize: 11,
  textBox: {
    ...defaultPriceCoordinate.textBox,
    left: 10,
    closeIcon: {
      padding: {left: 2, right: 8},
      width: 10,
    },
  },
  edge: {
    ...defaultPriceCoordinate.edge,
    fill: "#6BA583", // #DB0000
    fillOpacity: 1,
    arrowWidth: 0,
    stroke: "#ffffff",
    strokeOpacity: 0.5,
    displayFormat: (value) => numeral(value).format("0,0.[00000000]"),
  },
  name: null,
  exchange: null,
  amount: 0,
  type: "BUY", // SELL
};