import {getWidgetById} from "./wigdet";
import {filterCoinFn} from "../components/WidgetCoinFilter/WidgetCoinFilter";

export const getProgressIndex = (id, state) => {
  return getWidgetById(id, state)._progress ?? 0;
};
export const isWidgetStopped = (id, state) => {
  return getWidgetById(id, state)._isStopped ?? true;
};
export const getWidgetMarkets = (id, state) => {
  const filter=getWidgetFilter(id, state);

  return getWidgetById(id, state)._markets?.filter(filterCoinFn(filter)) ?? [];
};
export const isWidgetLoading = (id, state) => {
  return getWidgetById(id, state)._isLoading;
};
export const getWidgetFilter = (id, state) => {
  return getWidgetById(id, state).filter??"";
};
export const getWidgetPatterns = (id, state) => {
  return getWidgetById(id, state)._patterns??[];
};
export const getWidgetSettings = (id, state) => {
  return getWidgetById(id, state).settings??{
    patterns: [
      {
        id: "bearish_divergence",
        lookback: 500,
        point: "Close",
        interval: "1d"
      }
    ]
  };
};