import {connect} from "react-redux";
import WorksheetSettingsModal from "../components/WorksheetSettingsModal/WorksheetSettingsModal";
import {
  toggleModal,
  updateWidget,
  addWorksheet,
  removeWorksheet,
  setWorksheets,
  getUserWorksheets
} from "../../../actions/MarketsActions";
import {doUpdateState} from "../../../actions/UserAction";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      return {
        worksheets: state.markets.worksheets,
        currentWorksheet: state.markets.currentWorksheet,
        isOpen: state.markets.modals.worksheetSettings,
        widget: activeWidget,
      };
    }, {
      toggleModal,
      doUpdateState,
      setWorksheets,
      getUserWorksheets,
      updateWidget,
      addWorksheet,
      removeWorksheet,
    },
)(WorksheetSettingsModal);
