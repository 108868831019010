export default {
  chartType: 'candlestick',
  locale: 'en',
  stickMargin: 2,
  fontSize: 12,
  padding: 5,
  geometry: {
    boxPrice: {
      height: 0.8,
      top: 0,
      padding: 0,
      margin: [0, 0, 0, 0], //placeholder
    },
    boxVolume: {
      height: 0.2,
      top: 0.8,
      padding: 0,
      margin: [0, 0, 0, 0],
    }
  },
};
