import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./MarketsWidget.scss";
import FloatValue from "../../../../components/FloatValue";
import FontAwesome from "../../../../components/FontAwesome";
import {tickSocket} from "../../../../utils/websockets";
export const MARKET_ITEM_ROW_HEIGHT=35;
class MarketItem extends Component {
  constructor() {
    super();
    this.state={
      last: 0,
      percentage: 0,
      longName: "",
    };
    this.isComponentMounted = false;

  }
  componentDidMount() {
    this.isComponentMounted = true;

    this.setState({
      last: this.props.item.last,
      percentage: this.props.item.percentage
    });
    tickSocket.emit("tickerSubscribe", {
      params: {
        widgetId: this.props.widgetId,
        exchange: this.props.exchange,
        id: this.props.item.id
      }
    });
    tickSocket.on("tickerSubscribe", ({exchange, id, data})=>{
      if (this.props.exchange!==exchange||this.props.item.id!==id) return;
      if (this.isComponentMounted) {
        this.setState({
          last: parseFloat(data.close)||this.state.last,
          percentage: parseFloat(data.changePercent)||this.state.percentage
        });
      }
    });
  }
  componentWillUnmount() {
    tickSocket.emit("tickerUnsubscribe", {
      params: {
        id: this.props.item.id,
        widgetId: this.props.widgetId,
        exchange: this.props.exchange
      }
    });
    this.isComponentMounted = false;

  }
  calculateChange = (item) => {
    return this.state.percentage;
  };

  checkIfUndefined = (key) => {
    if (typeof this.state[key] === "undefined") {
      return "-";
    }
    return false;
  };
  checkIfPrice = (key) => {
    const {item} = this.props;
    if (typeof item[key] === 0) {
      return false;
    }
    return false;
  };

  drawItem = (field) => {
    const {item, favourites, addToFavourites} = this.props;
    if (item.last !== 0) {
      switch (field.key) {
        case "TICKER":
          return (
            <div className={styles.ticker}>
              {item.base}
            </div>
          );
      }
      switch (field.key) {
        case "TOKEN":
          {/*const {activeWidget} = this.props;
          //debugger;
          let longName=[item.base];
          if (Array.isArray([item.base])) {
            const exchangeSpecificName = activeWidget.exchange &&
          [item.base].find((nameObj) =>
            nameObj.exchange.toLowerCase().includes(activeWidget.exchange));
            if (exchangeSpecificName) {
              longName = exchangeSpecificName.name;
            } else {
              longName = [item.base]
                  .find((nameObj) => nameObj.exchange === "").name;
            }
          } else {
            longName = [item.base];
          }*/}
          return (
            <span>
              <div>
                <div className={styles.longname}>
                  <div style={{marginRight: 5}} className={styles.baselongName}>
                    {item.longName}longName
                  </div>
                  <span className={styles.base}>/
                    <span className={styles.baseQuote}>{item.quote}</span>
                  </span>
                  &nbsp;<FontAwesome
                    name="star"
                    className="star"
                    handleClick={(event) => {
                      event.stopPropagation();
                      addToFavourites(item);
                    }}
                    prefix={favourites && favourites.indexOf(item.symbol) >= 0 ? "fas" : "far"}
                  />
                </div>
                <div className={styles.base}>
                  <span className={styles.exchangename}>{/*{this.props.exchange}*/}Store of Value</span>
                </div>
              </div>
            </span>
          );
      }
      switch (field.name) {
        case "PRICE":
          return this.checkIfUndefined("last") || (
            <FloatValue value={this.state.last}/>
          );
      }
      switch (field.name) {
        case "CHANGE":
          return (
            <div>
              <span className={
                classNames({
                  up: this.state.percentage > 0,
                  down: this.state.percentage < 0,
                  green: this.state.percentage > 0,
                  red: this.state.percentage < 0,
                })}
              >
                {this.calculateChange(item)}%
              </span>
              {/*<div className="tickerLong"><span className={
                classNames({
                  green: this.state.percentage > 0,
                  red: this.state.percentage < 0,
                })}
              ><FloatValue value={this.state.last}/></span></div>*/}
            </div>
          );

      }

    }

  };

  render() {
    const {
      item, updateActiveWidget, activeWidget, fields, getRowWidth, exchange,
    } = this.props;
    const marketSplitted = item.symbol.split("/");
    const marketName = `${marketSplitted[1]}-${marketSplitted[0]}`;

    return (
      <tr
        style={{height: MARKET_ITEM_ROW_HEIGHT, overflow: "hidden"}}
        className={classNames({
          "Active": activeWidget && (activeWidget.name === marketName && activeWidget.exchange === item.exchange),
          "Red": item.change < 0,
          "Green": item.change > 0,
        })}
        onClick={() => updateActiveWidget(marketName, exchange, {marketId: this.props.item.id})}
      >

        {fields.map((field) => (
          <td
            className={field.key}
            key={field.name}
            style={{width: getRowWidth(field)}}
          >
            {this.drawItem(field)}
          </td>
        ))}
      </tr>
    );

  }
}

MarketItem.propTypes = {
  activeWidget: PropTypes.object,
  addToFavourites: PropTypes.func,
  exchange: PropTypes.string,
  widgetId: PropTypes.string,
  widget: PropTypes.string,
  favourites: PropTypes.array,
  fields: PropTypes.array,
  getRowWidth: PropTypes.func,
  item: PropTypes.object,
  updateActiveWidget: PropTypes.func,
};

MarketItem.defaultProps = {
  activeWidget: {},
  addToFavourites: () => false,
  exchange: undefined,
  favourites: [],
  fields: [],
  getRowWidth: () => false,
  item: undefined,
  updateActiveWidget: () => false,
  widget: {},
};

export default MarketItem;
