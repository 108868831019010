/* eslint react/no-array-index-key: 0 */
/* eslint no-unused-vars: 0 */
/* eslint max-len: 0 */
/* eslint react/no-danger: 0 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Form, FormGroup, Label, Row, Col, Input, Modal, ModalBody,
  ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, Button,
  Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Container,
} from "reactstrap";
import FontAwesome from "../../../components/FontAwesome";
import { SketchPicker } from 'react-color';
import styles from "./DrawingToolSettingsModal.scss";
import CustomButton from "../../../components/Button";
import { lineSettings } from "./ChartWidget/CanvasChart/drawingToolSettings";
import DateTimePicker from "../../../components/DateTimePicker";
class DrawingToolSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemData: undefined,
      activeTab: '1',
      dragStartPos: []
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { widget, drawingToolId } = this.props;
    if (prevProps.drawingToolId != drawingToolId) {
      const drawingTools = widget && widget.drawingTools || [];
      const itemData = drawingTools.find((item) => item.id == drawingToolId);
      this.setState({
        itemData
      });
    }
  }
  toggleTab = (tab) => {
    this.state.activeTab !== tab && this.setState({
      activeTab: tab,
    });
  };
  toggleDropDown = (key, index) => {
    this.setState((prevState) => ({
      ['toggle_' + key + index]: !prevState['toggle_' + key + index]
    }));
  }
  handleDropDownItemClick = (key, dropdownIdx, index) => {
    const { drawingToolId } = this.props;
    this.setState({
      [drawingToolId + '_select_' + key + index]: dropdownIdx
    });
  }
  handleColorPickChange = (color, key, index) => {
    const { drawingToolId } = this.props;
    this.setState({
      [drawingToolId + '_colorHEX_' + key + index]: color.hex,
      [drawingToolId + '_colorRGBA_' + key + index]: color.rgb
    });
  };
  handleSwitch = (e, key, index) => {
    const { drawingToolId } = this.props;
    const { target } = e;
    const { name, checked } = target;
    this.setState({
      [drawingToolId + '_checkbox_' + key + index + name]: checked
    });

    if (key == 'levelhead0') {
      const { itemData } = this.state;
      const setting = itemData.settings.levels;
      setting.levelUI.levels[index - 1].map((onelevel, rowIdx) => {
        const activeKey = 'levelactive';
        const activeIndex = index * 10 + rowIdx + 1;
        this.setState({
          [drawingToolId + '_checkbox_' + activeKey + activeIndex + 'single']: checked
        });
      });
    }
  }
  handleNumber = (e, key, index) => {
    console.log(e);
    const { drawingToolId } = this.props;
    this.setState({
      [drawingToolId + '_number_' + key + index]: e.target.value
    });
  }
  handleSlider = (e, key, index) => {
    const { drawingToolId } = this.props;
    this.setState({
      [drawingToolId + '_range_' + key + index]: parseInt(e.target.value)
    });
  }
  handleLevelButton = (e) => {
    const { drawingToolId } = this.props;
    const { itemData } = this.state;
    const { target } = e;
    const role = target.getAttribute('role');
    const colIdx = parseInt(target.getAttribute('index'));

    const settings = itemData.settings;
    const levelColAry = [...settings.inputs.levels.levelUI.levels];
    const colLevels = settings.inputs.levels.levelUI.levels[colIdx];
    if (role == 'add') {
      const defaultSetting = lineSettings[itemData.type];
      const firstLevel = defaultSetting.inputs.levels.levelUI.levels[colIdx][0];
      levelColAry[colIdx] = [...colLevels, firstLevel];
    } else if (role == 'remove') {
      if (colLevels.length > 1) {
        const removedLevels = [];
        for (let i = 0; i < colLevels.length; i++) {
          const activeKey = 'levelactive';
          const activeIndex = (colIdx + 1) * 10 + i + 1;
          const levelState =
            this.state[drawingToolId + '_checkbox_' + activeKey + activeIndex + 'single'] == undefined ?
              colLevels[i]['active'].checked :
              this.state[drawingToolId + '_checkbox_' + activeKey + activeIndex + 'single'];
          if (!levelState) {
            removedLevels.push(colLevels[i]);
          } else {
            this.setState({
              [drawingToolId + '_checkbox_' + activeKey + activeIndex + 'single']: false
            });
          }
        }
        levelColAry[colIdx] = [...removedLevels];
        this.setState({
          [drawingToolId + '_checkbox_' + 'levelhead0' + (colIdx + 1) + 'single']: false
        });
      }
    }
    const newSettings = {
      ...settings,
      inputs: {
        levels: {
          ...settings.inputs.levels,
          levelUI: {
            ...settings.inputs.levels.levelUI,
            levels: levelColAry
          }
        },
      },
    };
    this.setState({
      itemData: {
        ...itemData,
        settings: newSettings
      }
    });
  }
  handleToggleButton = (e, key, index) => {
    const { drawingToolId } = this.props;
    this.setState((prevState) => ({
      [drawingToolId + '_toggle_' + key + index]: !prevState[drawingToolId + '_toggle_' + key + index]
    }));
  }
  handleTextfield = (e, key, index) => {
    const { drawingToolId } = this.props;
    this.setState({
      [drawingToolId + '_textfield_' + key + index]: e.target.value
    });
  }
  drawInput = (key, setting, index, col) => {
    if (setting == undefined) {
      return null;
    }
    col = col || 3;
    const { drawingToolId } = this.props;
    const type = setting.type;
    switch (type) {
      case "textfield":
        return (
          <Col sm={11}>
            <FormGroup>
              <Label>{setting.label}</Label>
              <Input
                type="textarea"
                name="text"
                onChange={(e) => this.handleTextfield(e, key, index)}
                value={this.state[drawingToolId + '_textfield_' + key + index] == undefined ? setting.text : this.state[drawingToolId + '_textfield_' + key + index]}
              />
            </FormGroup>
          </Col>);
      case "toggle-button":
        const role = setting.role;
        const currentState = this.state[drawingToolId + '_toggle_' + key + index] == undefined ? setting.checked : this.state[drawingToolId + '_toggle_' + key + index];
        return (
          <Col sm={col}>
            <FormGroup>
              <Label>
                {
                  role == 'toggle-italic' ? <i>{setting.label}</i> :
                    role == 'toggle-bold' ? <b>{setting.label}</b> : setting.label
                }
              </Label>
              <Button
                role={role}
                onClick={(e) => this.handleToggleButton(e, key, index)}
                index={index}
                className={currentState ? 'toggle-on' : 'toggle-off'}
              >
                {
                  role == 'toggle-italic' ? <i>{setting.text}</i> :
                    role == 'toggle-bold' ? <b>{setting.text}</b> : setting.text
                }
              </Button>
            </FormGroup>
          </Col>);
      case "button":
        return (
          <Col sm={3}>
            <FormGroup>
              <Label>{setting.label}</Label>
              <Button
                role={setting.role}
                index={index - 1}
                onClick={this.handleLevelButton}
                size='sm'
              >
                {setting.text}
              </Button>
            </FormGroup>
          </Col>);
      case "label":
        return (
          <Col sm={3}>
            <FormGroup>
              <Label>{setting.value}</Label>
            </FormGroup>
          </Col>);
      case "number":
        return (
          <Col sm={3}>
            <FormGroup>
              {
                setting.label ? <Label>{setting.label}</Label> : null
              }
              <Input
                type="text"
                value={this.state[drawingToolId + '_number_' + key + index] == undefined ? setting.value : this.state[drawingToolId + '_number_' + key + index]}
                onChange={(e) => this.handleNumber(e, key, index)}
              />
            </FormGroup>
          </Col>);
      case "dateTime":
        const timestamp=this.state[drawingToolId + '_number_' + key + index] == undefined ? setting.value : this.state[drawingToolId + '_number_' + key + index];
        const date=new Date(timestamp);
        return (
          <Col md={3}>
            <FormGroup>
              {
                setting.label ? <Label>{setting.label}</Label> : null
              }
              <DateTimePicker
                value={date}
                onChange={(date)=>this.handleNumber({target: {value: date.getTime()}}, key, index)}
              />
            </FormGroup>
          </Col>);
      case "checkbox":
        return (
          <Col md={col}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="single"
                  checked={this.state[drawingToolId + '_checkbox_' + key + index + 'single'] == undefined ? setting.checked : this.state[drawingToolId + '_checkbox_' + key + index + 'single']}
                  onChange={(e) => this.handleSwitch(e, key, index)}
                />{' '}
                {
                  setting.label ? <Label>{setting.label}</Label> : null
                }
              </Label>
            </FormGroup>
          </Col>);
      case "select":
        const values = setting.values;
        let currentValue = values[setting.index];
        if (this.state[drawingToolId + '_select_' + key + index] != undefined) {
          currentValue = values[this.state[drawingToolId + '_select_' + key + index]];
        }
        return (
          <Col sm={3}>
            <FormGroup key={key}>
              {
                setting.label ? <Label>{setting.label}</Label> : null
              }
              <Dropdown
                isOpen={this.state['toggle_' + key + index]}
                toggle={() => this.toggleDropDown(key, index)}
                size="sm"
              >
                <DropdownToggle>
                  <div className="toggleButton">
                    <Fragment>
                      <div style={{ marginTop: '-3px' }} dangerouslySetInnerHTML={{ __html: currentValue.svg }} />
                      {<span>{currentValue.name}</span>}
                    </Fragment>
                    <FontAwesome name="chevron-down" prefix="fas" style={{ marginLeft: 'auto', paddingTop: '5px', fontSize: '80%'}} />
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  {
                    values.map((item, dropdownIdx) => (
                      <DropdownItem
                        key={key}
                        onClick={() => this.handleDropDownItemClick(key, dropdownIdx, index)}
                      >
                        <div className="toggleButton">
                          <div style={{ marginTop: '-3px' }} dangerouslySetInnerHTML={{ __html: item.svg }} />
                          <span>{item.name}</span>
                        </div>
                      </DropdownItem>))
                  }
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>);
      case "color":
        const pickedRGBAColor = this.state[drawingToolId + '_colorRGBA_' + key + index];
        const pickedHexColor = this.state[drawingToolId + '_colorHEX_' + key + index];
        const defaultRGBAColor = setting.rgba;
        const defaultHexColor = setting.hex;
        const hexColor = pickedHexColor || defaultHexColor;
        const rgbaColor = pickedRGBAColor ?
          'rgba(' + pickedRGBAColor.r + ',' +
          pickedRGBAColor.g + ',' +
          pickedRGBAColor.b + ',' +
          pickedRGBAColor.a + ')' :
          'rgba(' + defaultRGBAColor.r + ',' +
          defaultRGBAColor.g + ',' +
          defaultRGBAColor.b + ',' +
          defaultRGBAColor.a + ')';
        const opacity = pickedRGBAColor ? pickedRGBAColor.a : defaultRGBAColor.a;
        return (
          <Col sm={3}>
            <FormGroup key={key}>
              {
                setting.label ? <Label>{setting.label}</Label> : null
              }
              <Dropdown
                isOpen={this.state['toggle_' + key + index]}
                toggle={() => this.toggleDropDown(key, index)}
                size="sm"
              >
                <DropdownToggle>
                  <div className="toggleButton">
                    <Fragment>
                      <div className="colorRect" style={{ backgroundColor: rgbaColor }} />
                      <span>{parseInt(opacity * 100) + '%'}</span>
                    </Fragment>
                    <FontAwesome name="chevron-down" prefix="fas" style={{ marginLeft: 'auto', paddingTop: '5px', fontSize: '80%' }} />
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  {
                    <div className="popover">
                      <div
                        role="button"
                        tabIndex="0"
                        className="cover"
                      // onClick={()=>this.handleClose(key)}
                      />
                      <SketchPicker
                        color={rgbaColor}
                        onChange={(color) => this.handleColorPickChange(color, key, index)}
                      />
                    </div>
                  }
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>);
      case "switch":
        const extendValues = setting.values;
        return (
          <Col sm={3}>
            <FormGroup>
              {
                setting.label ? <Label>{setting.label}</Label> : null
              }
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div>
                  <Input
                    type="checkbox"
                    name="left"
                    checked={this.state[drawingToolId + '_checkbox_' + key + index + 'left'] == undefined ? extendValues[0] : this.state[drawingToolId + '_checkbox_' + key + index + 'left']}
                    onChange={(e) => this.handleSwitch(e, key, index)}
                  />{' '}
                  <Label>Start</Label>
                </div>
                <div>
                  <Input
                    type="checkbox"
                    name="right"
                    checked={this.state[drawingToolId + '_checkbox_' + key + index + 'right'] == undefined ? extendValues[1] : this.state[drawingToolId + '_checkbox_' + key + index + 'right']}
                    onChange={(e) => this.handleSwitch(e, key, index)}
                  />{' '}
                  <Label>End</Label>
                </div>
              </div>
            </FormGroup>
          </Col>);
      case 'level':
        return (
          <FormGroup>
            <div className={styles.levelcontainer}>
              <div className={styles.level}>
                <div className={styles.levelcontent}>
                  <Row>
                    {
                      Array.from(Array(setting.levelUI.column).keys()).map((colIdx) => {
                        return (
                          <Col key={colIdx} md={parseInt(12 / setting.levelUI.column)}>
                            {
                              setting.levelUI.title ?
                                <Row>
                                  {
                                    this.drawInput('leveltitle', setting.levelUI.title[colIdx], 0, setting.levelUI.title[colIdx].col)
                                  }
                                </Row> :
                                null
                            }
                            {
                              setting.levelUI.heads ?
                                <Row>
                                  {
                                    setting.levelUI.heads[colIdx].map((headSetting, headIdx) => {
                                      return this.drawInput('levelhead' + headIdx, headSetting, colIdx + 1, headSetting.col);
                                    })
                                  }
                                </Row> :
                                null
                            }
                            {setting.levelUI.levels[colIdx].map((onelevel, rowIdx) => this.drawLevel(onelevel, colIdx, rowIdx))}
                            {
                              setting.levelUI.buttons ?
                                <Row>
                                  {
                                    setting.levelUI.buttons[colIdx].map((buttonSetting, btnIdx) => {
                                      return this.drawInput('levelbutton' + btnIdx, buttonSetting, colIdx + 1, buttonSetting.col);
                                    })
                                  }
                                </Row> :
                                null
                            }
                            {
                              setting.levelUI.bgRange ?
                                <Row>
                                  {
                                    this.drawInput('levelrange', setting.levelUI.bgRange[colIdx], colIdx + 1, setting.levelUI.bgRange[colIdx].col)
                                  }
                                </Row> :
                                null
                            }
                            {
                              setting.levelUI.lineExtend ?
                                <Row>
                                  {
                                    this.drawInput('levelextend', setting.levelUI.lineExtend[colIdx], colIdx + 1, setting.levelUI.lineExtend[colIdx].col)
                                  }
                                </Row> :
                                null
                            }
                          </Col>);
                      })
                    }
                  </Row>
                </div>
              </div>
            </div>
          </FormGroup>
        );
      case 'range':
        return (
          <Col sm={3}>
            <FormGroup>
              <Label>{setting.label}</Label>
              <input
                className={styles.bgtransparencyslider}
                type="range"
                min="0"
                max="100"
                value={this.state[drawingToolId + '_range_' + key + index] == undefined ? setting.value : this.state[drawingToolId + '_range_' + key + index]}
                onChange={(e) => this.handleSlider(e, key, index)}
              />
            </FormGroup>
          </Col>
        );
    }
  }
  drawLevel = (oneRow, colIdx, rowIdx) => {
    return (
      <Row>
        {
          Object.keys(oneRow).map((key) => {
            return this.drawInput('level' + key, oneRow[key], (colIdx + 1) * 10 + rowIdx + 1, oneRow[key].col);
          })
        }
      </Row>);
  }
  getStateData = (settings, key, index) => {
    let newKey = key;
    if (index > 0) {
      newKey = 'level' + key;
    }
    const { drawingToolId } = this.props;
    if (settings[key] && settings[key].type == "textfield") {
      return {
        ...settings[key],
        text: this.state[drawingToolId + '_textfield_' + newKey + index] != undefined ? this.state[drawingToolId + '_textfield_' + newKey + index] : settings[key].text,
      };
    } else if (settings[key] && settings[key].type == "toggle-button") {
      return {
        ...settings[key],
        checked: this.state[drawingToolId + '_toggle_' + newKey + index] != undefined ? this.state[drawingToolId + '_toggle_' + newKey + index] : settings[key].checked,
      };
    } else if (settings[key] && settings[key].type == "color") {
      return {
        ...settings[key],
        hex: this.state[drawingToolId + '_colorHEX_' + newKey + index] != undefined ? this.state[drawingToolId + '_colorHEX_' + newKey + index] : settings[key].hex,
        rgba: this.state[drawingToolId + '_colorRGBA_' + newKey + index] != undefined ? this.state[drawingToolId + '_colorRGBA_' + newKey + index] : settings[key].rgba,
      };
    } else if (settings[key] && settings[key].type === "select") {
      const selectAry = settings[key].values;
      const dropdownIndex = this.state[drawingToolId + '_select_' + newKey + index] != undefined ? this.state[drawingToolId + '_select_' + newKey + index] : settings[key].index;
      return {
        ...settings[key],
        index: dropdownIndex,
        value: selectAry[dropdownIndex].value
      };
    } else if (settings[key] && settings[key].type === "switch") {
      const extendValues = settings[key].values;
      const left = this.state[drawingToolId + '_checkbox_' + newKey + index + 'left'] == undefined ? extendValues[0] : this.state[drawingToolId + '_checkbox_' + newKey + index + 'left'];
      const right = this.state[drawingToolId + '_checkbox_' + newKey + index + 'right'] == undefined ? extendValues[1] : this.state[drawingToolId + '_checkbox_' + newKey + index + 'right'];
      return {
        ...settings[key],
        values: [left, right]
      };
    } else if (settings[key] && settings[key].type === "number") {
      const value = this.state[drawingToolId + '_number_' + newKey + index] != undefined ? this.state[drawingToolId + '_number_' + newKey + index] : settings[key].value;
      return {
        ...settings[key],
        value
      };
    } else if (settings[key] && settings[key].type === "dateTime") {
      const value = this.state[drawingToolId + '_number_' + newKey + index] != undefined ? this.state[drawingToolId + '_number_' + newKey + index] : settings[key].value;
      return {
        ...settings[key],
        value
      };
    } else if (settings[key] && settings[key].type === "checkbox") {
      const checked = this.state[drawingToolId + '_checkbox_' + newKey + index + 'single'] == undefined ? settings[key].checked : this.state[drawingToolId + '_checkbox_' + newKey + index + 'single'];
      return {
        ...settings[key],
        checked
      };
    } else if (settings[key] && settings[key].type === "range") {
      const value = this.state[drawingToolId + '_range_' + newKey + index] == undefined ? settings[key].value : this.state[drawingToolId + '_range_' + newKey + index];
      return {
        ...settings[key],
        value
      };
    } else if (settings[key] && settings[key].type === "label") {
      return {
        ...settings[key]
      };
    }
  }
  handleApplyDefaultSetting = () => {
    const { itemData } = this.state;
    const { drawingToolId } = this.props;
    const type = itemData.type;
    const defaultSetting = lineSettings[type];
    const newData = {
      ...itemData,
      settings: defaultSetting
    };
    const initState = {};
    for (const key in this.state) {
      if (key.includes(drawingToolId)) {
        initState[key] = null;
      }
    }
    this.setState({
      itemData: newData,
      ...initState
    });
  }
  handleApplySetting = () => {
    const { widget, updateWidget, drawingToolId, drawingTemplates, updateDrawingTemplate } = this.props;
    const { itemData: { type } } = this.state;
    const updatedSettings = this.getCurrentModalSetting();
    let drawingTools = widget.drawingTools;
    drawingTools = drawingTools.map((item) => {
      if (item.id == drawingToolId) {
        item.settings = updatedSettings;
        if (item.settings.coordinates) {
          Object.entries(item.settings.coordinates).forEach(([key, value])=>{
            if (value.originValue && value.value) {
              item[value.originValue]=Number(value.value);
            }
          });
        }
      }
      return item;
    });
    updateWidget({
      ...widget,
      drawingTools
    });
    let templateName = document.getElementsByClassName('templateName')[0].value;
    if (templateName == '') {  //autosave
      templateName = 'AutoSave';
    } else {
      templateName += new Date().getTime();
    }
    updateDrawingTemplate({
      ...drawingTemplates,
      [type]: {
        ...drawingTemplates[type],
        [templateName]: updatedSettings,
        'AutoSave': updatedSettings,
      }
    });
  }
  getCurrentModalSetting = () => {
    const { drawingToolId } = this.props;
    const { itemData } = this.state;
    const settings = itemData.settings;
    const updatedSettings = {};
    Object.keys(settings).forEach((index) => {
      updatedSettings[index] = {};
      Object.keys(settings[index]).forEach((key) => {
        if (key == 'levels') {
          const column = settings[index][key]['levelUI']['column'];
          const newLevels = [];
          const newBgRanges = [];
          const newExtends = [];
          Array.from(Array(column).keys()).map((colIdx) => {
            if (settings[index][key]['levelUI']['bgRange']) {
              const value = this.state[drawingToolId + '_range_' + 'levelrange' + (colIdx + 1)] == undefined ? settings[index][key]['levelUI']['bgRange'][colIdx].value : this.state[drawingToolId + '_range_' + 'levelrange' + (colIdx + 1)];
              newBgRanges.push({
                ...settings[index][key]['levelUI']['bgRange'][colIdx],
                value
              });
            }
            if (settings[index][key]['levelUI']['lineExtend']) {
              const extendValues = settings[index][key]['levelUI']['lineExtend'][colIdx].values;
              const left = this.state[drawingToolId + '_checkbox_' + 'levelextend' + (colIdx + 1) + 'left'] == undefined ? extendValues[0] : this.state[drawingToolId + '_checkbox_' + 'levelextend' + (colIdx + 1) + 'left'];
              const right = this.state[drawingToolId + '_checkbox_' + 'levelextend' + (colIdx + 1) + 'right'] == undefined ? extendValues[1] : this.state[drawingToolId + '_checkbox_' + 'levelextend' + (colIdx + 1) + 'right'];
              newExtends.push({
                ...settings[index][key]['levelUI']['lineExtend'][colIdx],
                values: [left, right]
              });
            }
            const newCol = [];
            settings[index][key]['levelUI']['levels'][colIdx].map((oneLevel, rowIdx) => {
              const newOneLevel = {};
              Object.keys(oneLevel).map((levelKey) => {
                newOneLevel[levelKey] = this.getStateData(oneLevel, levelKey, (colIdx + 1) * 10 + rowIdx + 1);
              });
              newCol.push(newOneLevel);
            });
            newLevels.push(newCol);
          });
          updatedSettings[index][key] = {
            ...settings[index][key],
            levelUI: {
              ...settings[index][key]['levelUI'],
              levels: newLevels
            }
          };
          if (settings[index][key]['levelUI']['bgRange']) {
            updatedSettings[index][key] = {
              ...updatedSettings[index][key],
              levelUI: {
                ...updatedSettings[index][key]['levelUI'],
                bgRange: newBgRanges
              }
            };
          }
          if (settings[index][key]['levelUI']['lineExtend']) {
            updatedSettings[index][key] = {
              ...updatedSettings[index][key],
              levelUI: {
                ...updatedSettings[index][key]['levelUI'],
                lineExtend: newExtends
              }
            };
          }
        } else {
          updatedSettings[index][key] = this.getStateData(settings[index], key, 0);
        }
      });
    });
    return updatedSettings;
  }
  dragMouseDown = (e) => {
    e.preventDefault();
    const dragStartPos = [e.clientX, e.clientY];
    this.setState({
      dragStartPos
    });
    document.onmouseup = this.closeDragElement;
    document.onmousemove = this.elementDrag;
  }
  closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  }
  elementDrag = (e) => {
    e.preventDefault();
    const elmnt = document.getElementById('drawingSettingModal');
    const { dragStartPos } = this.state;
    const newX = dragStartPos[0] - e.clientX;
    const newY = dragStartPos[1] - e.clientY;
    this.setState({
      dragStartPos: [e.clientX, e.clientY]
    });
    elmnt.style.top = (elmnt.offsetTop - newY) + "px";
    elmnt.style.left = (elmnt.offsetLeft - newX) + "px";
  }
  getSavedTemplatesList = () => {
    const { drawingTemplates } = this.props;
    const { itemData: { type } } = this.state;
    if (drawingTemplates && drawingTemplates[type]) {
      const templates = drawingTemplates[type];
      const keys = Object.keys(templates);
      const list = [];
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key != 'AutoSave') {
          list.push([key.slice(0, -13), key]);
        }
      }
      return list;
    } else {
      return [];
    }
  }
  handleLoadTemplate = (e) => {
    const { currentTarget } = e;
    const templateKey = currentTarget.getAttribute('templateKey');
    const { itemData } = this.state;
    const { drawingToolId, drawingTemplates } = this.props;
    const templates = drawingTemplates[itemData.type];
    const savedSetting = templates[templateKey];
    if (savedSetting) {
      const newData = {
        ...itemData,
        settings: savedSetting
      };
      const initState = {};
      for (const key in this.state) {
        if (key.includes(drawingToolId)) {
          initState[key] = null;
        }
      }
      this.setState({
        itemData: newData,
        ...initState
      });
    }
  }
  handleRemoveTemplate = (e, templateKey) => {
    const { itemData } = this.state;
    const { drawingTemplates } = this.props;
    const templates = drawingTemplates[itemData.type];
    delete templates[templateKey];
  }
  render() {
    const { isOpen, toggleModal } = this.props;
    const { itemData, activeTab } = this.state;
    if (itemData) {
      const settings = itemData.settings;

      const list = this.getSavedTemplatesList();
      return (
        <Modal
          scrollable
          autoFocus={false}
          isOpen={isOpen}
          toggle={() => toggleModal("drawingSettings")}
          className={classNames("drawing-tool-modal", styles.moveable)}
          id="drawingSettingModal"
          size="lg"
        >
          <ModalHeader
            onMouseDown={this.dragMouseDown}
            toggle={() => toggleModal("drawingSettings")}
          >
            {itemData.type}
          </ModalHeader>
          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === '1' })}
                  onClick={() => this.toggleTab('1')}
                >
                  Parameters
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane
                tabId="1"
              >
                <Form style={{ textAlign: 'left' }}>
                  {settings.inputs && Object.keys(settings.inputs).length ? (
                    <Row className={styles.rowDivider1} row>
                      <Col sm={2} className={styles.blockTitle}>
                        <FontAwesome
                          className={styles.Icon}
                          name="align-right"
                          prefix="fas"
                        /> Inputs
                      </Col>
                      <Col sm={10} className={styles.blockText}>
                        <Row row>
                          {Object.keys(settings.inputs).map((key) => this.drawInput(key, settings.inputs[key], 0, settings.inputs[key] ? settings.inputs[key].col : 1))}
                        </Row>
                      </Col>
                    </Row>
                    ) : null}
                  {settings.plots && Object.keys(settings.plots).length ? (
                    <Row className={styles.rowDivider} row>
                      <Col sm={2} className={styles.blockTitle}>
                        <FontAwesome
                          className={styles.Icon}
                          name="palette"
                          prefix="fa"
                        /> Plots
                      </Col>
                      <Col sm={10} className={styles.blockText}>
                        <Row row>
                          {Object.keys(settings.plots).map((key) => this.drawInput(key, settings.plots[key], 0, settings.plots[key] ? settings.plots[key].col : 1))}
                        </Row>
                      </Col>
                    </Row>
                    ) : 0}
                  {settings.coordinates && Object.keys(settings.coordinates).length ? (
                    <Row className={styles.rowDivider} row>
                      <Col sm={2} className={styles.blockTitle}>
                        <FontAwesome
                          className={styles.Icon}
                          name="crosshairs"
                          prefix="fa"
                        /> Coordinates
                      </Col>
                      <Col sm={10} className={styles.blockText}>
                        <Row row>
                          {Object.keys(settings.coordinates).map((key) => this.drawInput(key, {
                            ...settings.coordinates[key],
                            value: itemData[settings.coordinates[key].originValue]
                          }, 0, 3))}
                        </Row>
                      </Col>
                    </Row>
                    ) : null}
                  <Row className={styles.indicatorBottom}>
                    <Col sm={2} className={styles.blockTitle}>
                      <FontAwesome
                        className={styles.Icon}
                        name="layer-group"
                        prefix="fas"
                      /> Templates
                    </Col>
                    <Col sm={9}>
                      <Row row>
                        <span>Load or create an indicator template.</span>
                      </Row>
                      <Row className="input-group" row>
                        <Dropdown
                          isOpen={this.state['toggle_0' + 'indicatorTemplate']}
                          toggle={() => this.toggleDropDown(0, 'indicatorTemplate')}
                          size="sm"
                          style={{ zIndex: 5 }}
                        >
                          <DropdownToggle className="TemplateButton">
                            Templates <FontAwesome
                              style={{ marginLeft: '5px', paddingTop: '3px', fontSize: '80%', float: 'right'}}
                              name="chevron-down"
                              prefix="fas"
                            />
                          </DropdownToggle>
                          <DropdownMenu style={{ width: '100%' }}>
                            {list.map((tempName) => {
                              return (
                                <DropdownItem
                                  key={tempName[1]}
                                  className="templateDropdownItem"
                                  templateKey={tempName[1]}
                                  onClick={(e) => this.handleLoadTemplate(e)}
                                >
                                  <div className="templateItem">
                                    <span>{tempName[0]}</span>
                                    <FontAwesome
                                      name="times"
                                      prefix="fas"
                                      handleClick={(e) => this.handleRemoveTemplate(e, tempName[1])}
                                    />
                                  </div>
                                </DropdownItem>);
                            })}
                          </DropdownMenu>
                          <input type="text" className="form-control templateName" placeholder="Create one.." />
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
            </TabContent>
            <div className="m-2" style={{ display: 'flex' }}>
              <CustomButton
                label="Reset"
                className="ResetButton"
                onClick={() => this.handleApplyDefaultSetting()}
              />
              <CustomButton
                label="Cancel"
                className="CancelButton"
                onClick={() => {
                  toggleModal("drawingSettings");
                }}
              />
              <CustomButton
                label="Apply"
                className="ApplyButton"
                onClick={() => {
                  this.handleApplySetting();
                  toggleModal("drawingSettings");
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

DrawingToolSettingsModal.propTypes = {
  drawingTemplates: PropTypes.object.isRequired,
  drawingToolId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  updateDrawingTemplate: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object
};

DrawingToolSettingsModal.defaultProps = {
  drawingToolId: '',
  isOpen: false
};

export default DrawingToolSettingsModal;
