// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oZhVdh5AdlMgJ9ClrnnCQ{height:100%;padding-bottom:20px;display:table;width:100%}.oZhVdh5AdlMgJ9ClrnnCQ ._28nm2dt7XZ85ZB993WIIPF{margin:0 auto;display:table-cell;vertical-align:middle;text-align:center;font-size:12px}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/NoActiveWidget.scss"],"names":[],"mappings":"AAGE,uBACE,WAAA,CAEA,mBAAA,CACA,aAAA,CACA,UAAA,CAEA,gDACE,aAAA,CACA,kBAAA,CACA,qBAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":["@import \"../../../styles/constants.scss\";\n\n:local {\n  .Wrapper {\n    height: 100%;\n    //padding: 20px;\n    padding-bottom: 20px;\n    display: table;\n    width: 100%;\n\n    .Content {\n      margin: 0 auto;\n      display: table-cell;\n      vertical-align: middle;\n      text-align: center;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "oZhVdh5AdlMgJ9ClrnnCQ",
	"Content": "_28nm2dt7XZ85ZB993WIIPF"
};
export default ___CSS_LOADER_EXPORT___;
