import {api} from "../utils/apiUtils";

const getAccountsList = () => api.get("account/list");
const getAccount = (id) => api.get(`account/get/${id}`);
const createAccount = (params) => api.post("account/bind", params);
const deleteAccount = (id) => api.delete(`account/unbind/${id}`);
const saveAccount = (id, params) => api.put(`account/edit/${id}`, params);
const updateRedo = (name, isredo, redoday) => api.post("account/setredo", {name, isredo, redoday});

export {
  getAccountsList,
  getAccount,
  createAccount,
  deleteAccount,
  saveAccount,
  updateRedo,
};
