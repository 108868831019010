import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import numeral from "numeral";
import {maxBy} from "lodash";
import Loader from "../../../../components/Loader";
import ScrollArea from "react-scrollbar";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import FloatValue from "../../../../components/FloatValue";
import NoActiveWidget from "../NoActiveWidget";
import DomMenu from "./DomMenu";
import ReactCursorPosition from "react-cursor-position";
import { exchanges } from "../../../../constants/MarketsConstants";

import styles from "./DomWidget.scss";

class DomWidget extends Component {
  constructor() {
    super();

    this.mousePos = null;
    this.scrollEl = null;
    this.domInterval = undefined;
    this.state = {
      menuVisible: false,
      menuPosition: [0, 0],
      menuItem: null,
      orderbook: {},
      buyDataToList: undefined,
      sellDataToList: undefined
    };
    this.componentCleanup = this.componentCleanup.bind(this);
  }
  componentDidMount(){
    window.addEventListener('beforeunload', this.componentCleanup);
    this.getMarketOrderBook();
  }
  componentDidUpdate(prevProps, prevState) {
    const {marketOrderbook, marketOrderbookUpdate, activeWidget, unsubscribePreMarketDom} = this.props;
    const isReceivedBuyData = prevProps.marketOrderbook.buy.length !== marketOrderbook.buy.length;
    const isReceivedSellData = prevProps.marketOrderbook.sell.length !== marketOrderbook.sell.length;
    const isUpdatedMarket = prevProps.activeWidget && activeWidget && prevProps.activeWidget.name !== activeWidget.name;

    if (isReceivedSellData || isReceivedBuyData || isUpdatedMarket) {
      setTimeout(() => this.scrollToMiddle(), 100);
    }
    const isExChanged = activeWidget.exchange != prevProps.activeWidget.exchange;
    const isPairChanged = activeWidget.name != prevProps.activeWidget.name;
    if (isExChanged || isPairChanged) {
      const marketSplitted = prevProps.activeWidget.name && prevProps.activeWidget.name.split("-");
      const unsubscribeObj = marketSplitted && marketSplitted[0] && marketSplitted[1] &&
        this.getSubscribeObj(prevProps.activeWidget.exchange, marketSplitted[0], marketSplitted[1]);
      unsubscribePreMarketDom(prevProps.activeWidget.exchange, unsubscribeObj);
      this.setState({
        buyDataToList: undefined,
        sellDataToList: undefined
      });
      this.getMarketOrderBook(prevProps.activeWidget.exchange, prevProps.activeWidget.name);
    }
    if (JSON.stringify(prevProps.marketOrderbook) != JSON.stringify(marketOrderbook)) {
      this.setState({
        orderbook: marketOrderbook,
        buyDataToList: marketOrderbook.buy.slice(0, 20),
        sellDataToList: marketOrderbook.sell.slice(-20)
      });
    }
    if (prevState.buyDataToList == undefined && prevState.sellDataToList == undefined) {
      setTimeout(() => this.scrollToMiddle(), 100);
    }
    if (prevProps.marketOrderbookUpdate != marketOrderbookUpdate) {
      const bids = marketOrderbookUpdate.bids;
      const asks = marketOrderbookUpdate.asks;
      const {orderbook} = this.state;
      let buyData = orderbook.buy;
      let sellData = orderbook.sell;
      if (!sellData || !buyData) return;
      if (buyData && bids.length > 0){
        for (const i in bids) {
          const bid = bids[i];
          let sameFind = false;
          for (let j = 0; j < buyData.length; j++) {
            const buy = buyData[j];
            if (parseFloat(buy.Rate) == parseFloat(bid.price)) {
              if (bid.size == 0) {
                buyData.splice(j, 1);
                j--;
              } else {
                buyData[j].Quantity = bid.size;
              }
              sameFind = true;
              break;
            } else if (parseFloat(buy.Rate) < parseFloat(bid.price)){
              break;
            }
          }
          if (!sameFind && parseFloat(bid.size) > 0) {
            buyData.push({
              Rate: bid.price,
              Quantity: bid.size,
              type: "buy",
              buyOrders: 0,
              sellOrders: 0,
            });
          }
        }
      }
      if (sellData && asks.length > 0){
        for (const k in asks) {
          const ask = asks[k];
          let sameFind = false;
          for (let m = sellData.length - 1; m >= 0; m--) {
            const sell = sellData[m];
            if (parseFloat(sell.Rate) == parseFloat(ask.price)) {
              if (ask.size == 0) {
                sellData.splice(m, 1);
                m++;
              } else {
                sellData[m].Quantity = ask.size;
              }
              sameFind = true;
              break;
            } else if (parseFloat(sell.Rate) > parseFloat(ask.price)){
              break;
            }
          }
          if (!sameFind && parseFloat(ask.size) > 0) {
            sellData.push({
              Rate: ask.price,
              Quantity: ask.size,
              type: "sell",
              buyOrders: 0,
              sellOrders: 0,
            });
          }
        }
      }
      buyData = buyData.sort((a, b) => {
        return parseFloat(a.Rate) > parseFloat(b.Rate) ? -1 : 1;
      });
      sellData = sellData.sort((a, b) => {
        return parseFloat(a.Rate) > parseFloat(b.Rate) ? -1 : 1;
      });
      const buyDataToList = buyData.slice(0, 20);
      const sellDataToList = sellData.slice(-20);
      this.setState({
        buyDataToList,
        sellDataToList,
        orderbook: {
          buy: buyData,
          sell: sellData
        }
      });
    }
  }

  componentWillUnmount(){
    clearInterval(this.domInterval);
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup() {
    const {unsubscribePreMarketDom, activeWidget} = this.props;
    const exchangeName = activeWidget.exchange;
    const marketSplitted = activeWidget && activeWidget.name.split("-");
    const subscribeObj = marketSplitted && this.getSubscribeObj(exchangeName, marketSplitted[0], marketSplitted[1]);
    subscribeObj && unsubscribePreMarketDom(exchangeName, subscribeObj);
  }

  getMarketOrderBook = (prevExchange, prevName) => {
    const {activeWidget, subscribeMarketDomUpdate, subscribeMarketDomSnapshot,
      unsubscribePreMarketDom, getMarketDom} = this.props;

    clearInterval(this.domInterval);
    if (prevExchange && prevName) {
      const unsubscribeObj = this.getSubscribeObj(prevExchange, prevName.split("-")[0], prevName.split("-")[0]);
      unsubscribePreMarketDom(prevExchange, unsubscribeObj);
    }

    const exchangeName = activeWidget.exchange;
    const marketName = activeWidget.name;
    if (!exchangeName || !marketName){
      return;
    }
    const marketSplitted = marketName.split("-");
    const exchangeInfo = exchanges[exchangeName];

    if (exchangeInfo && (exchangeInfo['ob_l2_update'] == 1 || exchangeInfo['ob_l2_snap'])) {  //snap and update
      const subscribeObj = this.getSubscribeObj(exchangeName, marketSplitted[0], marketSplitted[1]);
      if (exchangeInfo['ob_l2_update'] == 1) {
        subscribeMarketDomUpdate(exchangeName, marketSplitted, subscribeObj);
      } else if (exchangeInfo['ob_l2_snap']) {
        subscribeMarketDomSnapshot(exchangeName, marketSplitted, subscribeObj);
      }
    } else {
      this.domInterval = setInterval(() => {
        getMarketDom(exchangeName, marketSplitted);
      }, 10000);
    }
  }
  getSubscribeObj = (exchangeName, base, quote) => {
    switch (exchangeName) {
      case 'binance':
        return {
          id: quote + base,
          base: quote,
          quote: base,
        };
      case 'poloniex':
        return {
          id: base + '_' + quote,
          base: quote,
          quote: base,
        };
      case 'bitfinex':
        if (base == 'USDT'){
          base = 'UST';
        } else if (quote == 'USDT') {
          quote = 'UST';
        }
        return {
          id: quote + base,
          base: quote,
          quote: base,
        };
      case 'bitmex':
        if (base == 'BTC'){
          base = 'XBT';
        } else if (quote == 'BTC') {
          quote = 'XBT';
        }
        return {
          id: quote + base,
          base: quote,
          quote: base
        };
      case 'coinbasepro':
        return {
          id: quote +'-'+ base,
          base: quote,
          quote: base
        };
      case 'hitbtc2':
        if (base == 'USDT'){
          base = 'USDT20';
        } else if (quote == 'USDT') {
          quote = 'USDT20';
        }
        return {
          id: quote + base,
          base: quote,
          quote: base
        };
      case 'coinex':
        return {
          id: quote + base,
          base: quote,
          quote: base
        };
      case 'okex':
        return {
          id: quote + '-' + base,
          base: quote,
          quote: base
        };
      case 'ftx':
        return {
          id: quote + '/' + base,
          base: quote,
          quote: base
        };
    }
  };
  scrollToMiddle = () => {
    if (this.scrollEl) {
      const el = this.scrollEl.content;
      const element = el.getElementsByClassName("tableCenter")[0];
      element && this.scrollEl.scrollArea.scrollYTo(element.offsetTop - this.scrollEl.state.containerHeight / 2);
    }
  };

  getRowStyle = (quantity, max, type) => {
    const percent = quantity / max * 100;

    switch (type) {
      case "sell":
        return `linear-gradient(to right, #ab000080 ${percent}%, transparent ${percent}%)`;
      case "buy":
        return `linear-gradient(to left, #24a80080 ${percent}%, transparent ${percent}%)`;
      case "total":
        return `linear-gradient(to right, #72757280 ${percent}%, transparent ${percent}%)`;
    }
  };

  handleContextMenu = (event, item) => {
    event.preventDefault();
    if (this.scrollEl && this.mousePos) {
      const scrollEl = this.scrollEl.content.getBoundingClientRect();
      const isLeft = (scrollEl.right - event.clientX) >= (scrollEl.width / 2);
      const xPos = this.mousePos.state.position.x;

      this.setState({
        menuVisible: true,
        menuPosition: [isLeft ? xPos : xPos - 180, this.mousePos.state.position.y],
        menuItem: item,
      });
    }
  };

  handleCloseMenu = () => {
    this.setState({
      menuVisible: false,
      menuItem: null,
    });
  };

  render() {
    const {activeWidget} = this.props;
    const {buyDataToList, sellDataToList} = this.state;
    const sellData = sellDataToList || [];
    const buyData = buyDataToList || [];
    const maxBuyQuantity = maxBy(buyData, (item) => item.Quantity);
    const maxSellQuantity = maxBy(sellData, (item) => item.Quantity);
    const maxTotal = maxBy([...buyData, ...sellData], (item) => item.Quantity * item.Rate);
    const maxTotalValue = maxTotal ? maxTotal.Rate * maxTotal.Quantity : 0;

    let total = 0;

    let sellCount = 0;
    sellData.forEach((item) => {
      sellCount += parseFloat(item.Quantity);
      total += item.Rate * item.Quantity;
    });

    let buyCount = 0;
    buyData.forEach((item) => {
      buyCount += parseFloat(item.Quantity);
      total += item.Rate * item.Quantity;
    });
    const bid = buyData.length;
    // marketSummary && marketSummary.OpenBuyOrders ? numeral(marketSummary.OpenBuyOrders).format("0,0.[00]a") : 0;
    const ask = sellData.length;
    // marketSummary && marketSummary.OpenSellOrders ? numeral(marketSummary.OpenSellOrders).format("0,0.[00]a") : 0;

    return (
      <div className="widgetWrapper">
        <WidgetToolbar
          {...this.props}
        />
        {(!activeWidget.name || !activeWidget.exchange) ? (
          <div className="disableDrag">
            <NoActiveWidget/>
          </div>
        ) : (
          <Fragment>
            <div className="marketsWidget domWidget disableDrag">
              <ReactCursorPosition
                className={styles.MouseWrapper}
                ref={(el) => this.mousePos = el}
              >
                <table className={styles.Table}>
                  <thead>
                    <tr>
                      <th className={styles.BuyOrders}/>
                      <th
                        className={classNames(styles.Buy, styles.RightAlign)}
                      >
                        Bid ({bid})
                      </th>
                      <th className={styles.CenterAlign}>
                        Price
                      </th>
                      <th className={styles.Sell}>
                        Ask ({ask})
                      </th>
                      <th className={styles.SellOrders}/>
                      <th>
                        Total
                      </th>
                    </tr>
                  </thead>
                </table>
                <ScrollArea
                  className={classNames("table-body", styles.ScrollArea)}
                  speed={0.8}
                  ref={(el) => this.scrollEl = el}
                  horizontal={false}
                  contentStyle={buyDataToList && sellDataToList ? null : {height: 'calc(100% - 40px)'}}
                >
                  {buyDataToList && sellDataToList ? (
                    <div className="content">
                      <table className={styles.Table}>
                        <tbody>
                          {sellData.map((item, idx) => (
                            <tr
                              key={`${item.Rate}-${item.Quantity}`}
                              className={classNames("Updated", "Red", {
                                [styles.Active]: this.state.menuItem && this.state.menuItem.type === "sell" &&
                                item.Rate === this.state.menuItem.Rate,
                              })}
                              onContextMenu={(e) => this.handleContextMenu(e, item)}
                            >
                              <td colSpan={2}/>
                              <td className={styles.CenterAlign}>
                                <FloatValue
                                  className={classNames({[styles.Red]: idx === (sellData.length - 1)})}
                                  value={item.Rate}
                                />
                              </td>
                              <td
                                className={styles.Sell}
                                style={{
                                  background: this.getRowStyle(
                                      item.Quantity, maxSellQuantity ? maxSellQuantity.Quantity : 0, "sell",
                                  ),
                                }}
                              >
                                <FloatValue value={item.Quantity}/>
                              </td>
                              <td className={styles.SellOrders}>
                                {item.sellOrders > 0 && (
                                  <div>
                                    {item.sellOrders}
                                  </div>
                                )}
                              </td>
                              <td
                                style={{
                                  background: this.getRowStyle(item.Rate * item.Quantity, maxTotalValue, "total"),
                                }}
                              >
                                <FloatValue value={item.Rate * item.Quantity}/>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <table className={classNames(styles.Table, "tableCenter")}>
                        <tbody>
                          {buyData.map((item, idx) => (
                            <tr
                              key={`${item.Rate}-${item.Quantity}`}
                              className={classNames("Updated", "Green", {
                                [styles.Active]: this.state.menuItem && this.state.menuItem.type === "buy" &&
                                item.Rate === this.state.menuItem.Rate,
                              })}
                              onContextMenu={(e) => this.handleContextMenu(e, item)}
                            >
                              <td className={styles.BuyOrders}>
                                {item.buyOrders > 0 && (
                                  <div>
                                    {item.buyOrders}
                                  </div>
                                )}
                              </td>
                              <td
                                className={classNames(styles.RightAlign, styles.Buy)}
                                style={{
                                  background: this.getRowStyle(
                                      item.Quantity, maxBuyQuantity ? maxBuyQuantity.Quantity : 0, "buy",
                                  ),
                                }}
                              >
                                <FloatValue value={item.Quantity}/>
                              </td>
                              <td className={styles.CenterAlign}>
                                <FloatValue
                                  className={classNames({[styles.Green]: idx === 0})}
                                  value={item.Rate}
                                />
                              </td>
                              <td colSpan={2}/>
                              <td
                                style={{
                                  background: this.getRowStyle(item.Rate * item.Quantity, maxTotalValue, "total"),
                                }}
                              >
                                <FloatValue value={item.Rate * item.Quantity}/>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Loader/>
                  )}
                </ScrollArea>
                <table className={classNames(styles.domBottomBar, styles.Table)}>
                  <tbody>
                    <tr className={styles.domLast}>
                      <td
                        className={classNames(styles.Green, styles.RightAlign)}
                      >
                        {numeral(buyCount).format("0,0.[00]a")}
                      </td>
                      <td className={styles.CenterAlign}>
                        <a
                          className={styles.ClearBtn}
                          href="clear"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToMiddle();
                          }}
                        >
                          Go to Center
                        </a>
                      </td>
                      <td
                        className={styles.Red}
                      >
                        {numeral(sellCount).format("0,0.[00]a")}
                      </td>
                      <td>
                        {numeral(total).format("0,0.[00]a")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ReactCursorPosition>
            </div>
            <DomMenu
              {...this.props}
              isOpen={this.state.menuVisible}
              position={this.state.menuPosition}
              currentItem={this.state.menuItem}
              onHandleToggleChartMenu={this.handleCloseMenu}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

DomWidget.propTypes = {
  activeWidget: PropTypes.object,
  getMarketDom: PropTypes.func.isRequired,
  marketOrderbook: PropTypes.object,
  marketOrderbookUpdate: PropTypes.object,
  subscribeMarketDomSnapshot: PropTypes.func.isRequired,
  subscribeMarketDomUpdate: PropTypes.func.isRequired,
  unsubscribePreMarketDom: PropTypes.func.isRequired,
};

DomWidget.defaultProps = {
  activeWidget: {},
  marketOrderbook: {},
  marketOrderbookUpdate: {}
};

export default DomWidget;
