import React, {cloneElement} from "react";
import PropTypes from "prop-types";
import Submenu from "../../../components/Submenu";
import {withRouter} from "react-router";

const subPages = [
  {name: "Account", href: "/settings/account"},
  {name: "Security", href: "/settings/security"},
  {name: "Settings", href: "/settings/others"},
  {name: "Billing", href: "/settings/subscription"},
];

const SettingsPage = (props) => {
  const {children, location} = props;
  return (
    <div>
      <Submenu items={subPages} location={location}/>
      {cloneElement(children, {...props})}
    </div>
  );
};

SettingsPage.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object.isRequired,
};

SettingsPage.defaultProps = {
  children: undefined,
};

export default withRouter(SettingsPage);
