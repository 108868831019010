import {connect} from "react-redux";
import {doCheckToken, doSignup, getTmpSecret} from "../../../actions/UserAction";
import SignupPage from "../components/SignupPage";

export default connect(
    (state) => ({
      tmpSecretImg: state.user.tmpSecretImg,
      tmpSecretKey: state.user.tmpSecretKey,
      tokenValid: state.user.tokenValid,
    }), {
      doCheckToken,
      doSignup,
      getTmpSecret,
    }
)(SignupPage);
