import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Select.scss";

const Select = ({children, className, onBlur, onChange, value, wrapperClassName}) => {
  return (
    <div className={classNames(styles.Wrapper, wrapperClassName)}>
      <select
        className={className}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      >
        {children}
      </select>
    </div>
  );
};

Select.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
  wrapperClassName: PropTypes.string,
};

Select.defaultProps = {
  children: null,
  className: "",
  onBlur: () => false,
  onChange: () => false,
  value: undefined,
  wrapperClassName: "",
};

export default Select;
