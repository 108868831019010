import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Collapse from "../../../../components/Collapse";
import Select from "../../../../components/Select";

import styles from "../SettingsPage.scss";

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationEnabled: props.user.settings.desktopNotify || false,
      theme: props.user.settings.theme || "dark",
    };
  }

  handleSaveTheme = () => {
    this.props.editUserAction({
      theme: this.state.theme,
    });
  };

  handleChange = (value) => {
    this.setState({
      theme: value,
    });
  };

  drawThemeSettings = () => {
    return (
      <Fragment>
        <div className={styles.InputGroup}>
          <label>Select theme</label>
          <Select
            className={styles.Select}
            onChange={(event) => this.handleChange(event.target.value)}
            value={this.state.theme}
            wrapperClassName={styles.SelectWrapper}
          >
            <option value="dark">Dark</option>
          </Select>
        </div>
        <button
          className={styles.SaveBtn}
          onClick={this.handleSaveTheme}
        >
          Save
        </button>
      </Fragment>
    );
  };

  handleSaveNotification = () => {
    this.setState({
      notificationEnabled: !this.state.notificationEnabled,
    }, () => {
      this.props.editUserAction({
        notification: this.state.notificationEnabled,
      });
    });
  };

  drawNotificationSettings = () => {
    return (
      <Fragment>
        <div className={styles.InputGroup}>
          <label>Browsers notifications</label>
          <button
            className={classNames(styles.SaveBtn, {
              [styles.Secondary]: this.state.notificationEnabled,
            })}
            onClick={this.handleSaveNotification}
          >
            {this.state.notificationEnabled ? "Disable" : "Enable"}
          </button>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <div className={styles.Wrapper}>
        <Collapse
          label="Theme"
          description="Light or dark theme options"
        >
          {this.drawThemeSettings()}
        </Collapse>
        <Collapse
          label="Notifications"
          description="Enable or disable chat browser notifications"
        >
          {this.drawNotificationSettings()}
        </Collapse>
      </div>
    );
  }
}

SettingsPage.propTypes = {
  editUserAction: PropTypes.func,
  user: PropTypes.object,
};

SettingsPage.defaultProps = {
  editUserAction: () => false,
  user: {},
};

export default SettingsPage;
