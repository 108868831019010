import { relativeFontSize, formatPrice } from '../chartTools';
import { fromScreen } from '../coordinates';

function getStrokeDasharrayCanvas(type) {
  const a = getStrokeDasharray(type).split(",");

  if (a.length === 1) return [];

  return a.map(function(d) {
    return Number(d);
  });
}
function getStrokeDasharray(type) {
  switch (type) {
    default:
    case "Solid":
      return "none";
    case "ShortDash":
      return "6, 2";
    case "ShortDash2":
      return "6, 3";
    case "ShortDot":
      return "2, 2";
    case "ShortDashDot":
      return "6, 2, 2, 2";
    case "ShortDashDotDot":
      return "6, 2, 2, 2, 2, 2";
    case "Dot":
      return "2, 6";
    case "Dash":
      return "6, 6";
    case "LongDash":
      return "16, 6";
    case "DashDot":
      return "8, 6, 2, 6";
    case "LongDashDot":
      return "16, 6, 2, 6";
    case "LongDashDotDot":
      return "16, 6, 2, 6, 2, 6";
  }
}
export default function crosshair(view, quotes, cursorData, cursor, moveY) {
  const [x, y] = cursor;

  const boxPricePadding = view.geometry.boxPrice.padding;
  const boxVolumePadding = view.geometry.boxVolume ? view.geometry.boxVolume.padding : null;

  // const insidePrice = inside(cursor, boxPricePadding);
  // const insideVolume = boxVolumePadding ? inside(cursor, boxVolumePadding) : false;
  // if (!insidePrice && !insideVolume) return [null, null];

  drawCrosshair(view.crosshairCtx, x, y, boxPricePadding, boxVolumePadding, view, cursorData);

  return getCursorData(view, cursor, quotes, moveY);
}

function getCursorData(view, cursor, quotes, moveY) {
  const [x, y] = cursor;
  const boxPrice = view.geometry.boxPrice.content;
  // const boxPricePadding = view.geometry.boxPrice.padding;
  // const boxVolume = view.geometry.boxVolume ? view.geometry.boxVolume.content : null;
  // const boxVolumePadding = view.geometry.boxVolume ? view.geometry.boxVolume.padding : null;

  // const insidePrice = inside(cursor, boxPricePadding);
  // const insideVolume = boxVolumePadding ? inside(cursor, boxVolumePadding) : false;
  //let yValue;
  // if (insidePrice) {
  const yValue = fromScreen(y - boxPrice[1], boxPrice[3], quotes.min, quotes.max);
  // } else if (insideVolume) {
  //   yValue = fromScreen(y - boxVolume[1], boxVolume[3], 0, quotes.maxVolume);
  // }
  const left = boxPrice[2] % view.stickLength;
  const stickNumber = Math.round(
      (x - left - view.stickLength / 2 + moveY * 2) / (view.stickLength)
  ) + 2;
  const intervalms = quotes.data[quotes.data.length - 1].timestamp - quotes.data[quotes.data.length - 2].timestamp;
  const xValue = quotes.data[stickNumber] ?
    quotes.data[stickNumber] :
    {
      timestamp: (stickNumber - quotes.data.length + 1) * intervalms + quotes.data[quotes.data.length - 1].timestamp,
    };

  const eventData = [
    xValue,
    yValue,
  ];

  return eventData;
}

function drawCrosshair(ctx, x, y, boxPrice, boxVolume, chartView, cursorData) {
  const fontSize = relativeFontSize(chartView.width, chartView.fontSize);
  const style = chartView.style;
  ctx.strokeStyle = style.colorCrosshair;
  ctx.setLineDash(getStrokeDasharrayCanvas('LongDash'));
  // Draw vertical
  ctx.beginPath();
  ctx.moveTo(x, boxPrice[1]);
  if (boxVolume) {
    ctx.lineTo(x, boxVolume[1] + boxVolume[3]);
  } else {
    ctx.lineTo(x, boxPrice[1] + boxPrice[3]);
  }
  ctx.stroke();
  // Draw period scale label
  if (cursorData && cursorData[0]){
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(cursorData[0].timestamp);
    let dateStr = date.getDate() + " " + months[date.getMonth()] + " '" + (date.getFullYear() % 100);
    if (chartView.period < 1440){
      let minutes = date.getMinutes();
      let hours = date.getHours();
      if (minutes < 10){
        minutes = "0" + minutes;
      }
      if (hours < 10){
        hours = "0" + hours;
      }
      dateStr = dateStr + " " + hours + ":" + minutes;
    }
    ctx.font = `${fontSize}px "Arial"`;
    const width = ctx.measureText(dateStr).width;
    //Draw Rect
    ctx.beginPath();
    ctx.fillStyle = "#4d525d";
    ctx.fillRect(
        x - width / 2 - 3 * 2,
        boxPrice[1] + boxPrice[3] + 3 * 2,
        width + 6 * 2,
        fontSize + 3 * 2);
    ctx.stroke();
    ctx.fillStyle = style.colorScale;
    ctx.fillText(
        dateStr,
        x - width / 2,
        boxPrice[1] + boxPrice[3] + fontSize + 5
    );
  }
  // Draw horizontol
  ctx.beginPath();
  ctx.moveTo(boxPrice[0], y);
  ctx.lineTo(boxPrice[0] + boxPrice[2], y);
  ctx.stroke();
  // draw price text background rect
  ctx.beginPath();
  ctx.fillStyle = "#4d525d";
  ctx.fillRect(
      boxPrice[0] + boxPrice[2],
      y - fontSize / 2 - 3.2 * 2,
      chartView.config.geometry.boxPrice.margin[1] * 2,
      fontSize + 6 * 2);
  ctx.stroke();
  // draw pin
  ctx.setLineDash([]);
  ctx.strokeStyle = style.colorScale;
  ctx.beginPath();
  ctx.moveTo(
      boxPrice[0] + boxPrice[2],
      y
  );
  ctx.lineTo(
      boxPrice[0] + boxPrice[2] + chartView.config.padding,
      y
  );
  ctx.stroke();
  // draw price text
  ctx.beginPath();
  ctx.font = `${fontSize}px "Arial"`;

  if (cursorData && cursorData[1]){
    const yValue = cursorData[1];
    const text = formatPrice(yValue);

    ctx.fillStyle = style.colorScale;
    ctx.fillText(
        text,
        boxPrice[0] + boxPrice[2] + chartView.config.padding * 2,
        y + fontSize / 3
    );
  }
}

export const getDatePriceData= getCursorData;
