exports.VolumeWeightedAveragePrice =
  `var separated = inputBoolean("Separate from chart", false);
  study("Volume Weighted Average Price", "VWAP", separated);
  let plotvalues = vwap({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    volume: fullData.map((item) => item.volume)
  });
  plot('VWAP', plotvalues, {
    strokeWidth: inputWidth('Width', 2),
    strokeColor: inputColor('Color', 'rgba(229,115,115,1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.IchimokuCloud =
  `var separated = inputBoolean("Separate from chart", false);
  study("Ichimoku Cloud", "Ichimoku", separated);
  var high = fullData.map((item)=>item.high);
  var low = fullData.map((item)=>item.low);
  var close = fullData.map((item)=>item.close);
  var conversionPeriod = inputNumber('Conversion Period', 9);
  var basePeriod = inputNumber('Base Period', 26);
  var spanPeriod = inputNumber('Span Period', 52);
  var displacement = inputNumber('Displacement', 26);
  var chickuSpanColor = inputColor('Chiku Span', 'rgba(128,128,128,1)');
  var conversionLineColor = inputColor('Conversion Line', 'rgba(0,0,255,1)');
  var baseLineColor = inputColor('Base Line', 'rgba(255,0,0,1)');
  var spanAColor = inputColor('SPAN A', 'rgba(0,255,0,1)');
  var spanBColor = inputColor('SPAN B', 'rgba(255,0,0,1)');
  var ichimokuResults = ichimokucloud({
    conversionPeriod,
    basePeriod,
    spanPeriod,
    displacement,
    high,
    low
  });
  var conversions = ichimokuResults.map((item)=>item.conversion);
  var bases = ichimokuResults.map((item)=>item.base);
  var spanAs = ichimokuResults.map((item)=>item.spanA);
  var spanBs = ichimokuResults.map((item)=>item.spanB);
  plot('ChikuSpan', close, {
    strokeColor: chickuSpanColor,
    offset: -displacement
  });
  plot('ConversionLine', conversions, {
    strokeColor: conversionLineColor
  });
  plot('BaseLine', bases, {
    strokeColor: baseLineColor
  });
  plot('Leading Span A', spanAs, {
    strokeColor: spanAColor,
    offset: displacement
  });
  plot('Leading Span B', spanBs, {
    strokeColor: spanBColor,
    offset: displacement
  });
  fill('Cloud', spanAs, spanBs, {
    offset: displacement,
    fillBetweenColor: inputColor('SPAN A Cloud', 'rgba(0,255,0,0.1)'),
    fillCrossColor: inputColor('SPAN B Cloud', 'rgba(255,0,0,0.1)')
  });`;
exports.KeltnerChannels =
  `var separated = inputBoolean("Separate from chart", false);
  study("Keltner Channels", "KeltnerChannels", separated);
  var useSMA = inputBoolean('Use SMA', false);
  var maPeriod = inputNumber('MA Period', 20);
  var atrPeriod = inputNumber('ATR Period', 10);
  var multiplier = inputNumber('ATR Multiplier', 1);
  var color = inputColor('Color ', 'rgba(66,165,245,1)');
  var kcOutput = keltnerchannels({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    useSMA,
    maPeriod,
    atrPeriod,
    multiplier
  });
  var middle = kcOutput.map((item)=>item.middle);
  var upper = kcOutput.map((item)=>item.upper);
  var lower = kcOutput.map((item)=>item.lower);
  var cloudFillColor = inputColor('Fill Color ', 'rgba(66,165,245,0.2)');
  plot('Base', middle, {
    strokeColor: color
  });
  plot('Upper', upper, {
    strokeColor: color
  });
  plot('Lower', lower, {
    strokeColor: color
  });
  fill('Channel', upper, lower, {
    fillBetweenColor: cloudFillColor,
    fillCrossColor: cloudFillColor
  });`;
exports.PercentagePriceOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Percentage Price Oscillator", "PPO", separated);
  var shortPeriod = inputNumber('Short Period', 12);
  var longPeriod = inputNumber('Long Period', 26);
  var signalPeriod = inputNumber('Signal Period', 9);
  var ppoResults = ppo({
    shortPeriod,
    longPeriod,
    signalPeriod,
    close: fullData.map((item)=>item.close)
  });
  plot('PPO', ppoResults.ppo, {
    strokeType: inputStrokeType('PPO Plot Type', 'Solid'),
    strokeWidth: inputWidth('PPO Width', 2),
    strokeColor: inputColor('PPO Color', 'rgba(0,0,255,1)')
  });
  plot('Histogram', ppoResults.hist, {
    strokeType: inputStrokeType('Histogram Plot Type', 'Histogram'),
    strokeColor: inputColor('Histogram Color', 'rgba(255,255,0,1)'),
    strokeWidth: inputWidth('Histogram Width', 5)
  });
  plot('Signal', ppoResults.signal, {
    strokeType: inputStrokeType('Signal Plot Type', 'Solid'),
    strokeWidth: inputWidth('Signal Width', 2),
    strokeColor: inputColor('Signal Color', 'rgba(255,165,0,1)')
  });`;
exports.TripleEMA =
  `var separated = inputBoolean("Separate from chart", false);
  study("Triple EMA", "TripleEMA", separated);
  var emaList = [50, 100, 200];
  var mainput = inputSource('Source', 'close');
  var defaultColors = ['rgba(0,255,0,1)', 'rgba(0,0,255,1)', 'rgba(255,0,0,1)'];
  emaList.forEach((period, index) => {
    let emaperiod = inputNumber('EMA Period ' + index, period);
    let emacolor = inputColor('EMA Color ' + period, defaultColors[index]);
    let strokeWidth = inputWidth('Width ' + period, 2);
    let smavalues = ema({
      period: emaperiod,
      values: mainput
    });
    plot('EMA ' + period, smavalues, {
      strokeColor: emacolor,
      strokeWidth: strokeWidth
    });
  });`;
exports.ChaikinsVolatilityIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("ChaikinsVolatilityIndex", "CVI", separated);
  var high = fullData.map((item)=>item.high);
  var low = fullData.map((item)=>item.low);
  var period = inputNumber('Period', 10);
  var rocPeriod = inputNumber('ROC Period', 12);
  var color = inputColor('Color', 'rgba(244,67,54,1)');
  var results = cvi({
    high,
    low,
    period,
    rocPeriod
  });
  plot('CVI', results, {
    strokeWidth: inputWidth("CVI Width", 2),
    strokeColor: color,
    strokeType: inputStrokeType('CVI Type', 'Solid')
  });`;
// exports.ForecastOscillator =
//   `var values = inputSource('Source', 'close');
//   var period = inputNumber('Period', 14);
//   var foscResults = fosc({
//     period,
//     values
//   });
//   var strokeColor = foscResults.map((fosc, index) => {
//     return fosc > 0 ? inputColor('Up Color ', '#4A9D4A') : inputColor('Down Color ', '#9D4A4A');
//   });
//   plot('fosc', foscResults, {
//     strokeType: inputStrokeType('Plot Type', 'Solid')
//     strokeWidth: inputWidth('Line Width', 2),
//     strokeColor: strokeColor
//   });`;
exports.AbsolutePriceOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("AbsolutePriceOscillator", "APO", separated);
  var values = inputSource('Source', 'close');
  var shortPeriod = inputNumber('Short Period', 10);
  var longPeriod = inputNumber('Long Period', 20);
  var apoResults = apo({
    shortPeriod,
    longPeriod,
    values
  });
  plot('APO', apoResults, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('Color', 'rgba(255,205,210,1)')
  });`;
exports.BollingerBands =
  `var separated = inputBoolean("Separate from chart", false);
  study("BollingerBands", "BB", separated);
  var input = {
    period: inputNumber('Period', 14),
    values: inputSource('Source', 'close'),
    stdDev: inputNumber('Standard Deviation', 2)
  };
  let result =  bbands(input);
  let middle = result.map((item)=>item.middle);
  let upper = result.map((item)=>item.upper);
  let lower = result.map((item)=>item.lower);
  let cloudFillColor = inputColor('Fill Color', 'rgba(255,249,196,0.1)');
  plot('Middle', middle,{
    strokeBorder: inputBoolean('Middle Border', true),
    strokeWidth: inputWidth('Middle Width', 1),
    strokeColor: inputColor('Middle Color', 'rgba(66,165,245,1)')
  });
  plot('Upper', upper,{
    strokeBorder: inputBoolean('Upper Border', true),
    strokeWidth: inputWidth('Upper Width', 1),
    strokeColor: inputColor('Upper Color', 'rgba(66,165,245,1)')
  });
  plot('Lower', lower,{
    strokeBorder: inputBoolean('Lower Border', true),
    strokeWidth: inputWidth('Lower Width', 1),
    strokeColor: inputColor('Lower Color', 'rgba(66,165,245,1)')
  });
  fill('Bands', upper, lower, {
    fillBetweenColor: cloudFillColor,
    fillCrossColor: cloudFillColor
  });`;
exports.KlingerVolumeOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("KlingerVolumeOscillator", "KVO", separated);
  var shortPeriod = inputNumber('Short Period', 34);
  var longPeriod = inputNumber('Long Period', 55);
  var trigPeriod = inputNumber('Trig Period', 13);
  var kvoResults = kvo({
    shortPeriod,
    longPeriod,
    trigPeriod,
    high: fullData.map((item)=>item.high),
    low: fullData.map((item)=>item.low),
    close: fullData.map((item)=>item.close),
    volume: fullData.map((item)=>item.volume)
  });
  plot('KVO', kvoResults.kvo, {
    strokeType: inputStrokeType('KVO Plot Type', 'Solid'),
    strokeWidth: inputWidth('KVO Line Width', 2),
    strokeColor: inputColor('KVO Color', 'rgba(255,235,238,1)')
  });
  plot('Signal', kvoResults.trig, {
    strokeType: inputStrokeType('Trig Plot Type', 'Solid'),
    strokeWidth: inputWidth('Trig Line Width', 2),
    strokeColor: inputColor('Trig Color', 'rgba(0,255,0,1)')
  });`;
exports.DoubleEMA =
  `var separated = inputBoolean("Separate from chart", false);
  study("DoubleEMA", "DEMA", separated);
  var mainput = inputSource('Source', 'close');
  var emaperiod = inputNumber('Period', 9);
  var emacolor = inputColor('Color', 'rgba(128,0,128,1)');
  var lineWidth = inputWidth('Width', 2);
  var emavalues = dema({
    period: emaperiod,
    values: mainput
  });
  plot('EMA', emavalues, {
    strokeColor: emacolor,
    strokeWidth: lineWidth
  });`;
// exports.LinearRegression =
//   `var mainput = inputSource('Source', 'close');
//   var linregperiod = inputNumber('Period', 50);
//   var linregcolor = inputColor('Color', '#e57373');
//   var lineWidth = inputWidth('Line Width', 2);
//   var linregvalues = linreg({
//     period: linregperiod,
//     values: mainput
//   });
//   plot('linreg', linregvalues, {
//     strokeColor: linregcolor,
//     strokeWidth: lineWidth
//   });`;
exports.EaseOfMovement =
  `var separated = inputBoolean("Separate from chart", true);
  study("Ease Of Movement", "EOM", separated);
  var period = inputNumber('Period', 14);
  var divisor = inputNumber('Divisor', 10000);
  var eomResults = eom({
    high: fullData.map((item)=>item.high),
    low: fullData.map((item)=>item.low),
    volume: fullData.map((item)=>item.volume),
    period,
    divisor
  });
  plot('EOM', eomResults, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('Color', 'rgba(255,235,238,1)')
  });`;
exports.ChandeMomentumOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Chande Momentum Oscillator", "ChandeMO", separated);
  var values = inputSource('Source', 'close');
  var period = inputNumber('Period', 9);
  var results = cmo({
    values,
    period
  });
  plot('CMO', results, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeColor: inputColor('Color', 'rgba(244,67,54,1)'),
    strokeWidth: inputWidth('LineWidth', 2)
  });`;
exports.AroonOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Aroon Oscillator", "AroonOsc", separated);
  var period = inputNumber('Period', 14);
  var result = aroonosc({
    period,
    high: fullData.map((item)=>item.high),
    low: fullData.map((item)=>item.low),
  });
  var top = inputNumber('Top', 80);
  var bottom = inputNumber('Bottom', -80);
  hfill('Band', top, bottom, {
    fillColor: inputColor('Band Color', 'rgba(255, 192, 203, 0.1)'),
    strokeWidth: inputWidth('Band Border Width', 1),
    strokeBorder: inputBoolean('Band', true),
    strokeColor: inputColor('Band Border Color', 'rgba(255, 192, 203, 1)'),
    strokeType: inputStrokeType('Border Type', 'Dash')
  });
  plot('AroonOsc', result, {
    baseLine: inputNumber('Base Value', 0),
    fillColor: inputColor("Fill Top Color", 'rgba(74,157,74,0.1)'),
    strokeWidth: inputWidth("Width", 1),
    strokeColor: inputColor("Color", 'rgba(56,193,114,1)'),
    strokeType: inputStrokeType('Plot Type', 'Area')
  });`;
exports.MoneyFlowIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("Money Flow Index", "MFI", separated);
  var period = inputNumber('MFI Period', 14);
  var overBought = inputNumber('Over Bought', 80);
  var overSold = inputNumber('Over Sold', 20);
  var bandColor = inputColor('Band Color', 'rgba(255, 192, 203, 0.1)');
  hfill('Range', overSold, overBought, {
    fillColor: bandColor,
    strokeWidth: inputWidth('Band Border Width', 1),
    strokeBorder: inputBoolean('Band', true),
    strokeColor: inputColor('Band Border Color', 'rgba(255, 192, 203, 1)'),
    strokeType: inputStrokeType('Band Border Type', 'Dash')
  });
  var mfiValues = mfi({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    volume: fullData.map((item) => item.volume),
    period
  });
  plot("MFI", mfiValues, {
    strokeWidth: inputWidth('MFI Width', 1),
    strokeColor: inputColor('MFI Color'),
    strokeType: inputStrokeType('MFI Type', 'Solid')
  });`;
exports.FishnetAverage =
  `var separated = inputBoolean("Separate from chart", false);
  study("Fishnet Average", "FISHNETAVG", separated);
  let close = fullData.map((item) => item.close);
  let fishNetPeriod = inputString('Fishnet', '5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,'+
    '90,95,100,105,110,115,120,125,130,135,140,145,150,155,160,165,170,175,180,185,190,195,200');
  var periods = fishNetPeriod.split(',');
  periods.forEach(period => {
    plot('Period', sma({
      values: close,
      period: parseInt(period)
    }), {
      strokeColor: inputColor( period + ' Period Color', 'rgba(197,142,255,1)')
    });
  });`;
exports.SimpleMovingAverage =
  `var separated = inputBoolean("Separate from chart", false);
  study("Simple Moving Average", "SMA", separated);
  var mainput = inputSource('Source', 'close');
  var smaperiod = inputNumber('Period', 50);
  var smacolor = inputColor('Color', 'rgba(197,142,255,1)');
  var lineWidth = inputWidth('Width', 2);
  var smavalues = sma({
    period: smaperiod,
    values: mainput
  });
  plot('SMA', smavalues, {
    strokeColor: smacolor,
    strokeWidth: lineWidth
  });`;
exports.WeightedMovingAverage =
  `var separated = inputBoolean("Separate from chart", false);
  study("Weighted Moving Average", "WMA", separated);
  var mainput = inputSource('Source', 'close');
  var wmaperiod = inputNumber('Period', 50);
  var wmacolor = inputColor('Color', 'rgba(197,142,255,1)');
  var lineWidth = inputWidth('Width', 2);
  var wmavalues = wma({
    period: wmaperiod,
    values: mainput
  });
  plot('WMA', wmavalues, {
    strokeColor: wmacolor,
    strokeWidth: lineWidth
  });`;
exports.AverageTrueRange =
  `var separated = inputBoolean("Separate from chart", true);
  study("Average True Range", "ATR", separated);
  let period = inputNumber('Period', 14);
  let strokeColor = inputColor('Color', 'rgba(100,181,246, 1)');
  let atrValues = atr({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    period
  });
  plot('ATR', atrValues, {
    strokeColor,
    strokeWidth: inputWidth('Line Width', 2),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.TriangularMovingAverage =
  `var separated = inputBoolean("Separate from chart", false);
  study("Triangular Moving Average", "TMA", separated);
  var mainput = inputSource('Source', 'close');
  var trimaperiod = inputNumber('Period', 50);
  var tmaresult = tma({
    period: trimaperiod,
    values: mainput
  });
  plot('TMA', tmaresult, {
    strokeColor: inputColor('Stroke1 Color', 'rgba(128,0,128,1)'),
    strokeWidth: inputWidth('Line Width', 2),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
// exports.VariableIndexDynamicAverage =
//   `var mainput = inputSource('Source', 'close');
//   var short_period = inputNumber('Short Period', 2);
//   var long_period = inputNumber('Long Period', 5);
//   var alpha = inputNumber('Alpha', 0.2);
//   var vidyacolor = inputColor('Color', 'purple');
//   var lineWidth = inputWidth('Line Width', 2);
//   var vidyavalues = vidya({
//     short_period,
//     long_period,
//     alpha: alpha,
//     values: mainput
//   });
//   plot('vidya', vidyavalues, {
//     strokeColor: vidyacolor,
//     strokeWidth: lineWidth
//   });`;
exports.RateOfChange =
  `var separated = inputBoolean("Separate from chart", true);
  study("Rate of Change", "ROC", separated);
  var values = inputSource('ROC Source', 'close');
  var period = inputNumber('ROC Period', 14);
  var rocValues = roc({
    values,
    period
  });
  plot("roc", rocValues, {
    strokeWidth: inputWidth("ROC Width", 2),
    strokeColor: inputColor('ROC Color', 'rgba(255,0,0,1)'),
    strokeType: inputStrokeType('ROC Type', 'Solid')
  });`;
exports.OnBalanceVolume =
  `var separated = inputBoolean("Separate from chart", true);
  study("On Balance Volume", "OBV", separated);
  var obvValues = obv({
    close: fullData.map((item) => item.close),
    volume: fullData.map((item) => item.volume),
  });
  plot('OBV', obvValues, {
    strokeColor: inputColor('Color', 'rgba(0, 255, 0, 1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('LineWidth', 2)
  });`;
exports.Qstick =
  `var separated = inputBoolean("Separate from chart", true);
  study("Qstick", "Qstick", separated);
  var period = inputNumber('Period', 8);
  var emaPeriod = inputNumber('EMA Period', 5);
  var results = qstick({
    open: fullData.map((item)=>item.open),
    close: fullData.map((item)=>item.close),
    period
  });
  plot('Qstick', results, {
    strokeColor: inputColor('Color', 'rgba(244,67,54,1)'),
    strokeType: inputStrokeType('Plot Type', 'Histogram'),
    strokeWidth: inputWidth('Width', 5)
  });
  plot('EMA', ema({
    values: results,
    period: emaPeriod
  }), {
    strokeColor: inputColor('EMA Color', 'rgba(0,0,255,1)'),
    strokeType: inputStrokeType('Plot Type', 'Solild'),
    strokeWidth: inputWidth('EMA Line Width', 1)
  });`;
exports.MarketFacilitationIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("Market Facilitation Index", "MarketFI", separated);
  plot('MarketFI', marketfi({
    high: fullData.map((item)=>item.high),
    low: fullData.map((item)=>item.low),
    volume: fullData.map((item)=>item.volume)
  }), {
    strokeColor: inputColor('Color', 'rgba(0,255,0,1)'),
    strokeType: inputStrokeType('Plot Type', 'Histogram'),
    strokeWidth: inputWidth('Width', 5)
  });`;
// exports.PositiveVolumeIndex =
//   `study("PositiveVolumeIndex", "PVI", separated);
//   var initValue = inputNumber("Init Value", 1000);
//   var emaLength = inputNumber("EMA Length", 255);
//   var result = pvi({
//     close: fullData.map((item) => item.close),
//     volume: fullData.map((item) => item.volume),
//     initValue,
//     emaLength
//   })
//   plot('pvi', result, {
//     strokeColor: inputColor('Color', 'green'),
//     strokeType: inputStrokeType('Plot Type', 'Histogram'),
//     strokeWidth: inputWidth('Width', 5)
//   });`;
// exports.FisherTransform =
//   `study("Fisher Transform", "fisher", separated);
//   var period = inputNumber('Period', 9);
//   var result = fisher({
//     period,
//     high: fullData.map((item) => item.high),
//     low: fullData.map((item) => item.low),
//   });
//   plot('fisher', result, {
//     strokeType: inputStrokeType('Plot Type', 'Solid'),
//     strokeWidth: inputWidth('Line Width', 2),
//     strokeColor: inputColor('Fisher Color', 'rgba(239, 154, 154, 1)')
//   });`;
exports.ExponentialMovingAverage =
  `var separated = inputBoolean("Separate from chart", false);
  study("Exponential Moving Average", "EMA", separated);
  var mainput = inputSource('Source', 'close');
  var emaperiod = inputNumber('Period', 9);
  var emacolor = inputColor('Color', 'rgba(197,142,255,1)');
  var lineWidth = inputWidth('Width', 2);
  var emavalues = ema({
    period: emaperiod,
    values: mainput
  });
  plot('EMA', emavalues, {
    strokeColor: emacolor,
    strokeWidth: lineWidth
  });`;
exports.StochasticOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Stochastic Oscillator", "SO", separated);
  let result = stochastic({
    high: fullData.map((item)=>item.high),
    low: fullData.map((item)=>item.low),
    close: fullData.map((item)=>item.close),
    period: inputNumber("Period", 14),
    signalPeriod: inputNumber("Single Period", 3)
  });
  let k = result.map((item)=>item.k);
  let d = result.map((item)=>item.d);
  plot('Stoch %k', k, {
    strokeColor: inputColor('%K Color', 'rgba(100,256,100,1)')
  });
  plot('Stoch %d', d, {
    strokeColor: inputColor('%D color', 'rgba(149,117,205,1)')
  });
  var overBought = inputNumber('Upper Band', 80);
  var overSold = inputNumber('Lower Band', 20);
  var bandColor = inputColor('Band Color', 'rgba(255,192,203,0.2)');
  hfill('Range', overSold, overBought, {
    strokeWidth: inputNumber("Band Border Width", 1),
    fillColor: bandColor,
    strokeBorder: inputBoolean('Band', true),
    strokeColor: inputColor('Band Border Color', 'rgba(255,192,203,1)')
  });`;
// exports.ChaikinOscillatorCO =
//   `var {
//     high,
//     low,
//     close,
//     volume
//   } = this.data;
//   var short_period = inputNumber('Short Period', 2);
//   var long_period = inputNumber('Long Period', 5);
//   var adResults = adosc({
//     short_period,
//     long_period,
//     high,
//     low,
//     close,
//     volume
//   });
//   plot('adosc', adResults, {
//     strokeType: inputStrokeType('Plot Type', 'Solid')
//     strokeWidth: inputWidth('Line Width', 2),
//     strokeColor: inputColor('Color', '#ffcdd2')
//   });`;
exports.MedianPrice =
  `var separated = inputBoolean("Separate from chart", false);
  study("Median Price", "MedPrice", separated);
  var values = inputSource('Value 1', 'high');
  var values1 = inputSource('Value 2', 'low');
  var med = medprice({
    high: values,
    low: values1
  });
  plot('MedPrice', med, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('Color', 'rgba(244,67,54,1)')
  });`;
exports.AwesomeOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Awesome Oscillator", "AO", separated);
  var gainingAbove = inputColor('Gainging Above Color', 'rgba(74,157,74,1)');
  var losingAbove = inputColor('Losing Above Color', 'rgba(74,249,74,1)');
  var gainingBelow = inputColor('Gainging Below Color', 'rgba(249,74,74,1)');
  var losingBelow = inputColor('Losing Below Color', 'rgba(157,74,74,1)');
  var aoResults = ao({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    fastPeriod: inputNumber('Fast Period', 5),
    slowPeriod: inputNumber('Slow Period', 34)
  });
  var aoColor = aoResults.map((val, index) => {
    return val > 0 ? val > aoResults[index + 1] ? gainingAbove : losingAbove :
      val > aoResults[index + 1] ? gainingBelow : losingBelow;
  });
  plot('Histogram', aoResults, {
    strokeColor: aoColor,
    strokeType: inputStrokeType('Plot Type', 'Histogram'),
    strokeWidth: inputWidth('Width', 5)
  });`;
exports.UltimateOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Ultimate Oscillator", "UO", separated);
  let shortPeriod = inputNumber('Short Period', 7);
  let mediumPeriod = inputNumber('Medium Period', 14);
  let longPeriod = inputNumber('Long Period', 28);
  let results = ultosc({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    shortPeriod,
    longPeriod,
    mediumPeriod
  });
  plot('UO', results, {
    strokeColor: inputColor('Color', 'rgba(92,107,192,1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('Width', 2)
  });`;
exports.ZeroLagEMA =
  `var separated = inputBoolean("Separate from chart", false);
  study("Zero Lag EMA", "ZLEMA", separated);
  var mainput = inputSource('Source', 'close');
  var zlemaperiod = inputNumber('Period', 14);
  var zlemacolor = inputColor('Color', 'rgba(128,0,128,1)');
  var lineWidth = inputWidth('Line Width', 2);
  var zlemavalues = zlema({
    period: zlemaperiod,
    values: mainput
  });
  plot('ZLEMA', zlemavalues, {
    strokeColor: zlemacolor,
    strokeWidth: lineWidth
  });`;
exports.NewsSentiment =
  `var separated = inputBoolean("Separate from chart", true);
  study("News Sentiment", "NS", separated);
  var plotSignalAndMacd = inputBoolean('Plot Signal and NS', true);
  var result = macd({
    fastPeriod: inputNumber('Fast Period', 10),
    slowPeriod: inputNumber('Slow Period', 26),
    signalPeriod: inputNumber('Signal Period', 9),
    values: inputSource('Source', 'close'),
    SimpleMAOscillator: inputBoolean('SimpleMA Oscillator', false),
    SimpleMASignal: inputBoolean('SimpleMA Signal', false),
  });
  var histogram = result.map((item) => item.histogram);
  var signal = result.map((item) => item.signal);
  var NS = result.map((item) => item.NS);

  var histogramColorFn = (preCandle, Candle, nextCandle, id, colors) => {
    return Candle[id] > 0 ? Candle[id] > nextCandle[id] ? colors[0] : colors[1] :
    Candle[id] > nextCandle[id] ? colors[2] : colors[3];
  };
  plot('Histogram', histogram, {
    colors: ['rgba(74,157,74,1)', 'rgba(74,249,74,1)', 'rgba(249,74,74,1)', 'rgba(157,74,74,1)'],
    strokeColor: histogramColorFn,
    strokeType: 'Histogram',
    strokeWidth: 5
  });

  if (plotSignalAndMacd) {
    plot('NS', NS, {
      strokeWidth: inputWidth('Width', 2),
      strokeColor: inputColor('NS Color', 'rgba(250,107,73,1)'),
      strokeType: inputStrokeType('Plot Type', 'Solid')
    });
    plot('Signal', signal, {
      strokeWidth: inputWidth('Width', 2),
      strokeColor: inputColor('Signal Color', 'rgba(86,149,83,1)'),
      strokeType: inputStrokeType('Plot Type', 'Solid')
    });
  }`;
exports.MovingAverageConvergenceDivergence =
    `var separated = inputBoolean("Separate from chart", true);
    study("Moving Average Convergence Divergence", "MACD", separated);
    var plotSignalAndMacd = inputBoolean('Plot Signal and MACD', true);
    var result = macd({
      fastPeriod: inputNumber('Fast Period', 10),
      slowPeriod: inputNumber('Slow Period', 26),
      signalPeriod: inputNumber('Signal Period', 9),
      values: inputSource('Source', 'close'),
      SimpleMAOscillator: inputBoolean('SimpleMA Oscillator', false),
      SimpleMASignal: inputBoolean('SimpleMA Signal', false),
    });
    var histogram = result.map((item) => item.histogram);
    var signal = result.map((item) => item.signal);
    var MACD = result.map((item) => item.MACD);

    var histogramColorFn = (preCandle, Candle, nextCandle, id, colors) => {
      return Candle[id] > 0 ? Candle[id] > nextCandle[id] ? colors[0] : colors[1] :
      Candle[id] > nextCandle[id] ? colors[2] : colors[3];
    };
    plot('Histogram', histogram, {
      colors: ['rgba(74,157,74,1)', 'rgba(74,249,74,1)', 'rgba(249,74,74,1)', 'rgba(157,74,74,1)'],
      strokeColor: histogramColorFn,
      strokeType: 'Histogram',
      strokeWidth: 5
    });

    if (plotSignalAndMacd) {
      plot('MACD', MACD, {
        strokeWidth: inputWidth('Width', 2),
        strokeColor: inputColor('MACD Color', 'rgba(250,107,73,1)'),
        strokeType: inputStrokeType('Plot Type', 'Solid')
      });
      plot('Signal', signal, {
        strokeWidth: inputWidth('Width', 2),
        strokeColor: inputColor('Signal Color', 'rgba(86,149,83,1)'),
        strokeType: inputStrokeType('Plot Type', 'Solid')
      });
    }`;
exports.BalanceOfPower =
  `var separated = inputBoolean("Separate from chart", true);
  study("Balance Of Power", "BOP", separated);
  var results = bop({
    open: fullData.map((item) => item.open),
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close)
  });
  plot('BOP', results, {
    strokeWidth: inputWidth('Line Width', 1),
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeColor: inputColor('Color', 'rgba(229,115,115,1)')
  });`;
exports.CommodityChannelIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("Commodity Channel Index", "CCI", separated);
  var period = inputNumber('Period', 14);
  var results = cci({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    period
  });
  plot('CCI', results, {
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('Color', 'rgba(239, 83, 80, 1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.Momentum =
  `var separated = inputBoolean("Separate from chart", true);
  study("Momentum", "momentum", separated);
  var values = inputSource("Source", 'close');
  var period = inputNumber('Period', 10);
  plot('Momentum', mom({
    values,
    period
  }), {
    strokeColor: inputColor('Color', 'rgba(245,245,245,1)'),
    strokeWidth: inputWidth('LineWidth', 2)
  });`;
exports.DetrendedPriceOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Detrended Price Oscillator", "DPO", separated);
  var values = inputSource('Source', 'close');
  var period = inputNumber('Period', 21);
  var center = inputBoolean("Is Centered", true);
  var dpoResults = dpo({
    period,
    values,
    center
  });
  plot('DPO', dpoResults, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('Color', 'rgba(225,190,231,1)')
  });`;
exports.RateOfChangeRate =
  `var separated = inputBoolean("Separate from chart", true);
  study("Rate Of Change Rate", "ROCR", separated);
  var values = inputSource('Source', 'close');
  var period = inputNumber('Period', 14);
  var rocrValues = rocr({
    values,
    period
  });
  plot('ROCR', rocrValues, {
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('Color','rgba(225,190,231,1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
// exports.TimeSeriesForecast =
//   `var mainput = inputSource('Source', 'close');
//   var timeseriesperiod = inputNumber('Period', 50);
//   var timeseriescolor = inputColor('Color', 'purple');
//   var lineWidth = inputWidth('Line Width', 2);
//   var timeseriesvalues = tsf({
//     period: timeseriesperiod,
//     values: mainput
//   });
//   plot('timeseries', timeseriesvalues, {
//     strokeColor: timeseriescolor,
//     strokeWidth: lineWidth
//   });`;
// exports.NegativeVolumeIndex =
//   `var {
//     close,
//     volume
//   } = this.data;
//   plot('nvi', nvi({
//     close,
//     volume
//   }), {
//     strokeColor: inputColor('Color', 'green'),
//     plotType: inputPlotType('Plot Type', AvailablePlotType.Histogram)
//   });`;
exports.MassIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("MassIndex", "MassIndex", separated);
  var period = inputNumber('Period', 10);
  plot('MassIndex', mass({
    high: fullData.map((item)=>item.high),
    low: fullData.map((item)=>item.low),
    period
  }), {
    strokeColor: inputColor('Color', 'rgba(245,245,245,1)'),
    strokeWidth: inputWidth('LineWidth', 2),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
// exports.HistoricalVolatility =
//   `var values = inputSource('Source', 'close');
//   var period = inputNumber('Period', 10);
//   var results = volatility({
//     values,
//     period
//   });
//   plot('volatility', results, {
//     strokeWidth: {
//       value: 1
//     },
//     strokeColor: inputColor('Color', '#448AFF'),
//     strokeType: inputStrokeType('Plot Type', 'Solid')
//     opacity: 1
//   });`;
exports.AverageDirectionalMovementRating =
  `var separated = inputBoolean("Separate from chart", true);
  study("Average Directional Movement Rating", "ADXR", separated);
  var period = inputNumber('Period', 5);
  var adxr_results = adxr({
    period,
    high: fullData.map((item)=>item.high),
    low: fullData.map((item)=>item.low),
    close: fullData.map((item)=>item.close)
  });
  plot('ADXR', adxr_results, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('LineWidth', 2),
    strokeColor: inputColor('Color', 'rgba(239, 154, 154, 1)')
  });`;
exports.StochasticRSI =
  `var separated = inputBoolean("Separate from chart", true);
  study("Stochastic RSI", "StochasticRSI", separated);
  let kPeriod = inputNumber('K', 3);
  let dPeriod = inputNumber('D', 3);
  let values = inputSource('RSI Source', 'close');
  let rsiPeriod = inputNumber('RSI Period', 14);
  let stochasticPeriod = inputNumber('Stochastic Period', 14);
  var inputStochasticRSI = {
    values,
    stochasticPeriod,
    kPeriod,
    dPeriod,
    rsiPeriod
  };
  var result = stochasticrsi(inputStochasticRSI);
  var rangestart = inputNumber('Range End', 80);
  var rangeend = inputNumber('Range Start', 20);
  var bandColor = inputColor('Band Color', 'rgba(255,192,203,0.1)');
  let k = result.map((item)=>item.k);
  let d = result.map((item)=>item.d);
  plot('K', k, {
    strokeColor: inputColor('K Color', 'rgba(255,0,0,1)')
  });
  plot('D', d, {
    strokeColor: inputColor('D Color', 'rgba(255,165,0,1)')
  });
  hfill('Range', rangestart, rangeend, {
    strokeWidth: inputWidth("Width", 1),
    fillColor: bandColor,
    strokeBorder: inputBoolean('Band', true),
    strokeColor: inputColor('Band Border Color', 'rgba(255,192,203,1)')
  });`;
exports.TRIX =
  `var separated = inputBoolean("Separate from chart", true);
  study("TRIX", "TRIX", separated);
  var values = inputSource('Source', 'close');
  var period = inputNumber('Period', 18);
  var results = trix({
    values,
    period
  });
  plot('TRIX', results, {
    strokeWidth: 1,
    strokeColor: inputColor('Trix Color', 'rgba(92, 107, 192, 1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.HullMovingAverage =
  `var separated = inputBoolean("Separate from chart", false);
  study("Hull MA", "HMA", separated);
  var mainput = inputSource('Source', 'close');
  var hmaperiod = inputNumber('Period', 9);
  var hmacolor = inputColor('Color', 'rgba(128,0,128,1)');
  var lineWidth = inputWidth('Line Width', 2);
  var hmavalues = hma({
    period: hmaperiod,
    values: mainput
  });
  plot('HMA', hmavalues, {
    strokeColor: hmacolor,
    strokeWidth: lineWidth,
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.AverageDirectionalIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("Average Directional Index", "ADX", separated);
  var period = inputNumber('Period', 5);
  var adx_results = adx({
    period,
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close)
  });
  var series = adx_results.map((item) => item.adx);
  plot('ADX', series, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('LineWidth', 2),
    strokeColor: inputColor('Color', 'rgba(239, 154, 154, 1)')
  });`;
// exports.WilliamAccumulationDistribution =
//   `study("William A/D", "WA/D", separated);
//   plot('wad', wad({
//     high: fullData.map((item) => item.high),
//     low: fullData.map((item) => item.low),
//     close: fullData.map((item) => item.close)
//   }), {
//     strokeColor: inputColor('Color', 'rgba(66,165,245,1)'),
//     strokeWidth: inputWidth('LineWidth', 2),
//     strokeType: inputStrokeType('Plot Type', 'Solid')
//   });`;
exports.VolumeWeightedMovingAverage =
  `var separated = inputBoolean("Separate from chart", true);
  study("VolumeWeightedMovingAverage", "VWMA", separated);
  var mainput = inputSource('Source', 'close');
  var vwmaperiod = inputNumber('Period', 50);
  var vwmacolor = inputColor('Color', 'purple');
  var lineWidth = inputWidth('Line Width', 2);
  var vwmavalues = vwma({
    period: vwmaperiod,
    volume: this.data.volume,
    close: mainput
  });
  plot('VWMA', vwmavalues, {
    strokeColor: vwmacolor,
    strokeWidth: lineWidth
  });`;
exports.Volume =
  `var separated = inputBoolean("Separate from chart", false);
  study("Volume", "VOL", separated);
  var showAverageVolume = inputBoolean('Show Average Volume', true);
  var volumeAveragePeriod = inputNumber('Average Period', 20);
  var upColor = inputColor('Up Volume Color', 'rgba(23, 57, 39, 0.5)');
  var downColor = inputColor('Down Volume Color', 'rgba(86, 35, 25, 0.5)');

  var volume = fullData.map((item) => item.volume);

  var histogramColorFn = (preCandle, Candle, nextCandle, id, colors) => {
    return Candle.open > Candle.close ? colors[1] : colors[0];
  };

  plot('Volume', volume, {
    colors: ['rgba(23, 57, 39, 0.8)', 'rgba(86, 35, 25, 0.8)'],
    strokeColor: histogramColorFn,
    strokeType: inputStrokeType('Volume Plot Type', 'Histogram'),
    strokeWidth: inputWidth('Width', 2)
  });
  if (showAverageVolume) {
    plot('Volume SMA', sma({
      values: volume,
      period: volumeAveragePeriod
    }), {
      baseLine: inputNumber('Base Value', 0),
      strokeType: inputStrokeType('SMA Plot Type', 'Area'),
      strokeColor: inputColor('SMA Color', 'rgba(100, 59, 31, 0.1)')
    });
  }`;
exports.VerticalHorizontalFilter =
  `var separated = inputBoolean("Separate from chart", true);
  study("Vertical Horizontal Filter", "VHF", separated);
  var values = inputSource('VHF Source', 'close');
  var period = inputNumber('VHF Period', 28);
  var vhfValues = vhf({
    values,
    period
  });
  plot('VHF', vhfValues, {
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('VHF Color', 'rgba(68,138,255,1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.VolumeProfile =
  `var separated = inputBoolean("Separate from chart", false);
  study("Volume Profile", "VP", separated);
  var high = plotData.map((item)=>item.high);
  var low = plotData.map((item)=>item.low);
  var open = plotData.map((item)=>item.open);
  var close = plotData.map((item)=>item.close);
  var volume = plotData.map((item)=>item.volume);
  var noOfBars = inputNumber('Number of Rows', 30);
  var result = volumeprofile({
    open,
    high,
    low,
    close,
    volume,
    noOfBars
  });
  plotVolumeProfile(result, {
    upVolColor: inputColor('Up Volume', 'rgba(107,165,131,0.5)'),
    dnVolColor: inputColor('Down Volume', 'rgba(255,0,0,0.5)'),
    totalVolColor: inputColor('Total Volume', 'rgba(255,0,255,0.5)'),
    vaUpVolColor: inputColor('Value Area Up Volume', 'rgba(73,145,149,0.5)'),
    vaDnVolColor: inputColor('Value Area Down Volume', 'rgba(129,49,152,0.5)'),
    vaTotalVolColor: inputColor('Value Area Total Volume', 'rgba(255,100,100,0.5)'),
    width: inputStyleNumber('Width(% of Box)', 30),
    vaVolPct: inputStyleNumber('Value Area Volume Percent', 70),
    placement: 'Right',
    volType: 'Up & Down Volume',
    pocColor: inputColor('POC Color', 'rgba(255,0,0,1)'),
    pocWidth: inputStyleNumber('POC Width', 4),
  });`;
exports.SupDemZone =
  `var separated = inputBoolean("Separate from chart", false);
  study("Supply Demand Zone", "SupDemZone", separated);
  var zones = supdemzone({
    plotData,
    fullData,
  })
  var hideActiveZones = inputBoolean('Hide Active Zones', false);
  var hideBrokenZones = inputBoolean('Hide Broken Zones', true);
  var extendZones = inputBoolean('Extend Zones', false);
  plotSupDemZone({zones, fullData, plotData}, {
    hideActiveZones,
    hideBrokenZones,
    extendZones,
    demandColor: inputColor('Demand Color', 'rgba(127, 255, 0, 1)'),
    supplyColor: inputColor('Supply Color', 'rgba(255, 99, 71, 1)'),
    activeZoneLineOpacity: inputStyleNumber('Active Zone Line Opacity', 0.3),
    activeZoneAreaOpacity: inputStyleNumber('Active Zone Area Opacity', 0.15),
    brokenZoneLineOpacity: inputStyleNumber('Broken Zone Line Opacity', 0.1),
    brokenZoneAreaOpacity: inputStyleNumber('Broken Zone Area Opacity', 0.05),
    lineWidth: inputStyleNumber('Line Width', 1),
  })
  `;
exports.RelativeStrengthIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("Relative Strength Index", "RSI", separated);
  // Indicator input settings
  var period = inputNumber('RSI Length', 14);
  var values = inputSource('Source');
  var top = inputNumber('Upper Level', 70);
  var mid = inputNumber('Mid Level', 50);
  var bottom = inputNumber('Lower Level', 30);
  // Indicator plot settings
  hline('Overbought', top, {
    strokeLevelLabel: 'Upper Level',
    strokeLevel: 70,
    strokeWidth: 1,
    strokeColor: "rgba(155, 155, 155, 1)",
    strokeType: 'Dot'
  });
  hline('Mid', mid, {
    strokeLevelLabel: 'Mid Level',
    strokeLevel: 50,
    strokeWidth: 1,
    strokeColor: "rgba(155, 155, 155, 1)",
    strokeType: 'Dot'
  });
  hline('Oversold', bottom, {
    strokeLevelLabel: 'Lower Level',
    strokeLevel: 30,
    strokeWidth: 1,
    strokeColor: "rgba(155, 155, 155, 1)",
    strokeType: 'Dot'
  });
  hfill('Background', top, bottom, {
    fillColor: 'rgba(74, 74, 74, 0.1)',
  });
  var rsiValues = rsi(values, period);
  plot('RSI', rsiValues, {
    strokeWidth: 1,
    strokeColor: "rgba(245, 166, 35, 1)",
    strokeType: 'Solid'
  });`;
// exports.KaufmanAdaptiveMovingAvg =
//   `var mainput = inputSource('Source', 'close');
//   var kamaperiod = inputNumber('Period', 21);
//   var kamacolor = inputColor('Color', 'purple');
//   var lineWidth = inputWidth('Line Width', 2);
//   var kamavalues = kama({
//     period: kamaperiod,
//     values: mainput
//   });
//   plot('kama', kamavalues, {
//     strokeColor: kamacolor,
//     strokeWidth: lineWidth
//   });`;
exports.ParabolicSAR =
  `var separated = inputBoolean("Separate from chart", false);
  study("Parabolic SAR", "PSAR", separated);
  var high = fullData.map((item)=>item.high);
  var low = fullData.map((item)=>item.low);
  var step = inputNumber('AF Step', 0.02);
  var max = inputNumber('AF Max', 0.2);
  var psar_values = psar({
    high,
    low,
    max,
    step
  });
  var upColor = inputColor('Up Circle Color', 'rgba(0,255,0,1)');
  var downColor = inputColor('Down Circle  Color', 'rgba(244,67,54,1)');
  var strokeColor = psar_values.map((item, index) => {
    return item < low[index] ? downColor : upColor;
  });
  plot('PSAR', psar_values, {
    strokeType: inputStrokeType('Plot Type', 'Circle'),
    strokeWidth: inputWidth('Width', 2),
    strokeColor
  });`;
exports.VolumeOscillator =
  `var separated = inputBoolean("Separate from chart", true);
  study("Volume Oscillator", "VOSC", separated);
  var shortPeriod = inputNumber('Short Period', 5);
  var longPeriod = inputNumber('Long Period', 10);
  var voscResults = vosc({
    shortPeriod,
    longPeriod,
    volume: fullData.map((item)=>item.volume)
  });
  plot('VOSC', voscResults, {
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('Line Width', 2),
    strokeColor: inputColor('Color', 'rgba(255,205,210,1)')
  });`;
exports.TrueRange =
  `var separated = inputBoolean("Separate from chart", true);
  study("True Range", "TR", separated);
  var series = tr({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close)
  });
  plot('TR', series, {
    strokeColor: inputColor('Color', 'rgba(66, 165, 245, 1)'),
    strokeType: inputStrokeType('Plot Type', 'Solid'),
    strokeWidth: inputWidth('Width', 2)
  });`;
exports.ForceIndex =
  `var separated = inputBoolean("Separate from chart", true);
  study("Force Index", "FI", separated);
  var series = forceindex({
    period: inputNumber("Period", 13),
    volume: fullData.map((item) => item.volume),
    close: fullData.map((item) => item.close)
  });
  plot('FI', series, {
    baseLine: inputNumber('Base Value', 0),
    fillColor: inputColor("Fill Top Color", 'rgba(162,245,191,0.1)'),
    strokeWidth: inputWidth("Width", 1),
    strokeColor: inputColor("Color", 'rgba(56,193,114,1)'),
    strokeType: inputStrokeType('Plot Type', 'Area')
  });`;
exports.WilliamsR =
  `var separated = inputBoolean("Separate from chart", true);
  study("WilliamsR", "WilliamsR", separated);
  var series = williamsr({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    period: inputNumber("Period", 14)
  });
  var top = inputNumber('Band Top', -20);
  var bottom = inputNumber('Band Bottom', -80);
  var bandColor = inputColor('Band Color', 'rgba(255, 192, 203, 0.1)');
  hfill('Range', top, bottom, {
    fillColor: bandColor,
    strokeWidth: inputWidth('Band Border Width', 1),
    strokeBorder: inputBoolean('Band', true),
    strokeColor: inputColor('Band Border Color', 'rgba(255, 192, 203, 1)'),
    strokeType: inputStrokeType('Border Type', 'Dash')
  });
  plot('WillamR', series, {
    strokeWidth: inputWidth("Width", 1),
    strokeColor: inputColor('Color', "rgba(255, 255, 0, 1)"),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.ChandelierExit =
  `var separated = inputBoolean("Separate from chart", false);
  study("ChandelierExit", "ChandelierExit", separated);
  var result = chandelierexit({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    period: inputNumber("Period", 22),
    multiplier: inputNumber("Multiplier", 3),
  });
  let exitLong = result.map((item)=>item.exitLong);
  let exitShort = result.map((item)=>item.exitShort);
  plot('ExitLong', exitLong, {
    strokeWidth: inputWidth("Long Width", 1),
    strokeColor: inputColor('Long Color', "rgba(0, 255, 0, 1)"),
    strokeType: inputStrokeType('Long Plot Type', 'Solid')
  });
  plot('ExitShort', exitShort, {
    strokeWidth: inputWidth("Short Width", 1),
    strokeColor: inputColor('Short Color', "rgba(255, 0, 0, 1)"),
    strokeType: inputStrokeType('Short Plot Type', 'Solid')
  });`;
exports.KnowSureThing =
  `var separated = inputBoolean("Separate from chart", true);
  study("KnowSureThing", "KST", separated);
  var values = inputSource('KST Source', 'close');
  var result = kst({
    ROCPer1: inputNumber("ROC1 Period",10),
    ROCPer2: inputNumber("ROC2 Period",15),
    ROCPer3: inputNumber("ROC3 Period",20),
    ROCPer4: inputNumber("ROC4 Period",30),
    SMAROCPer1: inputNumber("SMA ROC1 Period",10),
    SMAROCPer2: inputNumber("SMA ROC2 Period",10),
    SMAROCPer3: inputNumber("SMA ROC3 Period",10),
    SMAROCPer4: inputNumber("SMA ROC4 Period",15),
    signalPeriod: inputNumber("Single Period",9),
    values
  });
  let kstresult = result.map((item)=>item.kst);
  let signal = result.map((item)=>item.signal);
  plot('KST', kstresult, {
    strokeWidth: inputWidth("KST Width", 1),
    strokeColor: inputColor('KST Color', "rgba(0, 255, 0, 1)"),
    strokeType: inputStrokeType('KST Plot Type', 'Solid')
  });
  plot('Signal', signal, {
    strokeWidth: inputWidth("Signal Width", 1),
    strokeColor: inputColor('Signal Color', "rgba(255, 0, 0, 1)"),
    strokeType: inputStrokeType('Signal Plot Type', 'Solid')
  });`;
exports.AccumulationDistributionLine =
  `var separated = inputBoolean("Separate from chart", true);
  study("Accumulation Distribution Line", "ADL", separated);
  var result = adl({
    high: fullData.map((item) => item.high),
    low: fullData.map((item) => item.low),
    close: fullData.map((item) => item.close),
    volume: fullData.map((item) => item.volume)
  });
  plot('ADL', result, {
    strokeWidth: inputWidth("Width", 1),
    strokeColor: inputColor('Color', "rgba(0, 255, 0, 1)"),
    strokeType: inputStrokeType('Plot Type', 'Solid')
  });`;
exports.WildersSmoothing =
  `var separated = inputBoolean("Separate from chart", false);
  study("WildersSmoothing", "WEMA", separated);
  var mainput = inputSource('Source', 'close');
  var wemaperiod = inputNumber('Period', 50);
  var wemacolor = inputColor('Color', 'rgba(197,142,255,1)');
  var lineWidth = inputWidth('Width', 2);
  var wemavalues = wema({
    period: wemaperiod,
    values: mainput
  });
  plot('WEMA', wemavalues, {
    strokeColor: wemacolor,
    strokeWidth: lineWidth
  });`;
exports.FloorPivotPoints =
  `var separated = inputBoolean("Separate from chart", false);
  study("FloorPivots", "FloorPivots", separated);
  var fppRes = floorpivot();
  plot('R3', fppRes.r3serie, {
    strokeLabel: inputLabel('R3'),
    strokeType: inputStrokeType('R3 Level Type', 'Solid'),
    strokeWidth: inputWidth('R3 Level Width', 1),
    strokeColor: inputColor('R3 Level Color', 'rgba(255,165,0,1)')
  });
  plot('R2', fppRes.r2serie, {
    strokeLabel: inputLabel('R2'),
    strokeType: inputStrokeType('R2 Level Type', 'Solid'),
    strokeWidth: inputWidth('R2 Level Width', 1),
    strokeColor: inputColor('R2 Level Color', 'rgba(255,0,0,1)')
  });
  plot('R1', fppRes.r1serie, {
    strokeLabel: inputLabel('R1'),
    strokeType: inputStrokeType('R1 Level Type', 'Solid'),
    strokeWidth: inputWidth('R1 Level Width', 1),
    strokeColor: inputColor('R1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('PP', fppRes.plserie, {
    strokeLabel: inputLabel('PP'),
    strokeType: inputStrokeType('Pivot Level Type', 'Solid'),
    strokeWidth: inputWidth('Pivot Level Width', 1),
    strokeColor: inputColor('Pivot Level Color', 'rgba(0,0,255,1)')
  });
  plot('S1', fppRes.s1serie, {
    strokeLabel: inputLabel('S1'),
    strokeType: inputStrokeType('S1 Level Type', 'Solid'),
    strokeWidth: inputWidth('S1 Level Width', 1),
    strokeColor: inputColor('S1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('S2', fppRes.s2serie, {
    strokeLabel: inputLabel('S2'),
    strokeType: inputStrokeType('S2 Level Type', 'Solid'),
    strokeWidth: inputWidth('S2 Level Width', 1),
    strokeColor: inputColor('S2 Level Color', 'rgba(255,0,0,1)')
  });
  plot('S3', fppRes.s3serie, {
    strokeLabel: inputLabel('S3'),
    strokeType: inputStrokeType('S3 Level Type', 'Solid'),
    strokeWidth: inputWidth('S3 Level Width', 1),
    strokeColor: inputColor('S3 Level Color', 'rgba(255,165,0,1)')
  });`;
exports.WoodiesPivotPoints =
  `var separated = inputBoolean("Separate from chart", false);
  study("WoodiesPivots", "WoodiesPivots", separated);
  var wppRes = woodies();
  plot('R2', wppRes.r2serie, {
    strokeLabel: inputLabel('R2'),
    strokeType: inputStrokeType('R2 Level Type', 'Solid'),
    strokeWidth: inputWidth('R2 Level Width', 1),
    strokeColor: inputColor('R2 Level Color', 'rgba(255,0,0,1)')
  });
  plot('R1', wppRes.r1serie, {
    strokeLabel: inputLabel('R1'),
    strokeType: inputStrokeType('R1 Level Type', 'Solid'),
    strokeWidth: inputWidth('R1 Level Width', 1),
    strokeColor: inputColor('R1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('PP', wppRes.plserie, {
    strokeLabel: inputLabel('PP'),
    strokeType: inputStrokeType('Pivot Level Type', 'Solid'),
    strokeWidth: inputWidth('Pivot Level Width', 1),
    strokeColor: inputColor('Pivot Level Color', 'rgba(0,0,255,1)')
  });
  plot('S1', wppRes.s1serie, {
    strokeLabel: inputLabel('S1'),
    strokeType: inputStrokeType('S1 Level Type', 'Solid'),
    strokeWidth: inputWidth('S1 Level Width', 1),
    strokeColor: inputColor('S1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('S2', wppRes.s2serie, {
    strokeLabel: inputLabel('S2'),
    strokeType: inputStrokeType('S2 Level Type', 'Solid'),
    strokeWidth: inputWidth('S2 Level Width', 1),
    strokeColor: inputColor('S2 Level Color', 'rgba(255,0,0,1)')
  });`;
exports.CamarillaPivotPoints =
  `var separated = inputBoolean("Separate from chart", false);
  study("CamarillaPivots", "CamarillaPivots", separated);
  var cppRes = camarilla();
  plot('R4', cppRes.r4serie, {
    strokeLabel: inputLabel('R4'),
    strokeType: inputStrokeType('R4 Level Type', 'Solid'),
    strokeWidth: inputWidth('R4 Level Width', 1),
    strokeColor: inputColor('R4 Level Color', 'rgba(255,0,255,1)')
  });
  plot('R3', cppRes.r3serie, {
    strokeLabel: inputLabel('R3'),
    strokeType: inputStrokeType('R3 Level Type', 'Solid'),
    strokeWidth: inputWidth('R3 Level Width', 1),
    strokeColor: inputColor('R3 Level Color', 'rgba(255,165,0,1)')
  });
  plot('R2', cppRes.r2serie, {
    strokeLabel: inputLabel('R2'),
    strokeType: inputStrokeType('R2 Level Type', 'Solid'),
    strokeWidth: inputWidth('R2 Level Width', 1),
    strokeColor: inputColor('R2 Level Color', 'rgba(255,0,0,1)')
  });
  plot('R1', cppRes.r1serie, {
    strokeLabel: inputLabel('R1'),
    strokeType: inputStrokeType('R1 Level Type', 'Solid'),
    strokeWidth: inputWidth('R1 Level Width', 1),
    strokeColor: inputColor('R1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('S1', cppRes.s1serie, {
    strokeLabel: inputLabel('S1'),
    strokeType: inputStrokeType('S1 Level Type', 'Solid'),
    strokeWidth: inputWidth('S1 Level Width', 1),
    strokeColor: inputColor('S1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('S2', cppRes.s2serie, {
    strokeLabel: inputLabel('S2'),
    strokeType: inputStrokeType('S2 Level Type', 'Solid'),
    strokeWidth: inputWidth('S2 Level Width', 1),
    strokeColor: inputColor('S2 Level Color', 'rgba(255,0,0,1)')
  });
  plot('S3', cppRes.s3serie, {
    strokeLabel: inputLabel('S3'),
    strokeType: inputStrokeType('S3 Level Type', 'Solid'),
    strokeWidth: inputWidth('S3 Level Width', 1),
    strokeColor: inputColor('S3 Level Color', 'rgba(255,165,0,1)')
  });
  plot('S4', cppRes.s4serie, {
    strokeLabel: inputLabel('S4'),
    strokeType: inputStrokeType('S4 Level Type', 'Solid'),
    strokeWidth: inputWidth('S4 Level Width', 1),
    strokeColor: inputColor('S4 Level Color', 'rgba(255,0,255,1)')
  });`;
exports.FibonacciPivotPoints =
  `var separated = inputBoolean("Separate from chart", false);
  study("FibonacciPivots", "FibonacciPivots", separated);
  var fiboppRes = fibopivot();
  plot('R5', fiboppRes.r5serie, {
    strokeLabel: inputLabel('R5'),
    strokeType: inputStrokeType('R5 Level Type', 'Solid'),
    strokeWidth: inputWidth('R5 Level Width', 1),
    strokeColor: inputColor('R5 Level Color', 'rgba(0,255,125,1)')
  });
  plot('R4', fiboppRes.r4serie, {
    strokeLabel: inputLabel('R4'),
    strokeType: inputStrokeType('R4 Level Type', 'Solid'),
    strokeWidth: inputWidth('R4 Level Width', 1),
    strokeColor: inputColor('R4 Level Color', 'rgba(255,0,255,1)')
  });
  plot('R3', fiboppRes.r3serie, {
    strokeLabel: inputLabel('R3'),
    strokeType: inputStrokeType('R3 Level Type', 'Solid'),
    strokeWidth: inputWidth('R3 Level Width', 1),
    strokeColor: inputColor('R3 Level Color', 'rgba(255,165,0,1)')
  });
  plot('R2', fiboppRes.r2serie, {
    strokeLabel: inputLabel('R2'),
    strokeType: inputStrokeType('R2 Level Type', 'Solid'),
    strokeWidth: inputWidth('R2 Level Width', 1),
    strokeColor: inputColor('R2 Level Color', 'rgba(255,0,0,1)')
  });
  plot('R1', fiboppRes.r1serie, {
    strokeLabel: inputLabel('R1'),
    strokeType: inputStrokeType('R1 Level Type', 'Solid'),
    strokeWidth: inputWidth('R1 Level Width', 1),
    strokeColor: inputColor('R1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('PP', fiboppRes.pivotserie, {
    strokeLabel: inputLabel('PP'),
    strokeType: inputStrokeType('Pivot Level Type', 'Solid'),
    strokeWidth: inputWidth('Pivot Level Width', 1),
    strokeColor: inputColor('Pivot Level Color', 'rgba(0,0,255,1)')
  });
  plot('S1', fiboppRes.s1serie, {
    strokeLabel: inputLabel('S1'),
    strokeType: inputStrokeType('S1 Level Type', 'Solid'),
    strokeWidth: inputWidth('S1 Level Width', 1),
    strokeColor: inputColor('S1 Level Color', 'rgba(0,255,0,1)')
  });
  plot('S2', fiboppRes.s2serie, {
    strokeLabel: inputLabel('S2'),
    strokeType: inputStrokeType('S2 Level Type', 'Solid'),
    strokeWidth: inputWidth('S2 Level Width', 1),
    strokeColor: inputColor('S2 Level Color', 'rgba(255,0,0,1)')
  });
  plot('S3', fiboppRes.s3serie, {
    strokeLabel: inputLabel('S3'),
    strokeType: inputStrokeType('S3 Level Type', 'Solid'),
    strokeWidth: inputWidth('S3 Level Width', 1),
    strokeColor: inputColor('S3 Level Color', 'rgba(255,165,0,1)')
  });
  plot('S4', fiboppRes.s4serie, {
    strokeLabel: inputLabel('S4'),
    strokeType: inputStrokeType('S4 Level Type', 'Solid'),
    strokeWidth: inputWidth('S4 Level Width', 1),
    strokeColor: inputColor('S4 Level Color', 'rgba(255,0,255,1)')
  });
  plot('S5', fiboppRes.s5serie, {
    strokeLabel: inputLabel('S5'),
    strokeType: inputStrokeType('S5 Level Type', 'Solid'),
    strokeWidth: inputWidth('S5 Level Width', 1),
    strokeColor: inputColor('S5 Level Color', 'rgba(0,255,125,1)')
  });`;
exports.SwingHighLow =
  `var separated = inputBoolean("Separate from chart", false);
  study("Swing Highs Lows", "SwingHighLow", separated);
  var showSH = inputBoolean("Show Swing Highs", true);
  var showSL = inputBoolean("Show Swing Lows", true);
  var color = inputColor('Color', 'rgba(255, 192, 203, 1)');
  var type = inputStrokeType('Type', 'Solid');
  var width = inputWidth('Width', 2);
  var result = swing();
  plotSwingHighLow(result, {
    showSH,
    showSL,
    strokeWidth: width,
    strokeColor: color,
    strokeType: type
  });`;
exports.RangeHighLow =
    `var separated = inputBoolean("Separate from chart", false);
    study("Range Highs Lows", "RangeHighLow", separated);
    var showSH = inputBoolean("Show Range Highs", true);
    var showSL = inputBoolean("Show Range Lows", true);
    var color = inputColor('Color', 'rgba(255, 192, 203, 1)');
    var type = inputStrokeType('Type', 'Solid');
    var width = inputWidth('Width', 2);
    var result = swing();
    plotRangeHighLow(result, {
      showSH,
      showSL,
      strokeWidth: width,
      strokeColor: color,
      strokeType: type
    });`;
