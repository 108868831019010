import {createStore, compose, applyMiddleware, combineReducers} from "redux";
import {apiMiddleware} from "redux-api-middleware";
import {reducer as reduxFormReducer} from "redux-form";
import {routerReducer} from "react-router-redux";
import thunk from "redux-thunk";
import thunkMiddleware from "redux-thunk";
import * as reducers from "../reducers/index";
import {emit} from "./websockets";

const enhancer = compose(
    // Middleware you want to use in development:
    applyMiddleware(
        thunkMiddleware.withExtraArgument({emit}),
        thunk,
        apiMiddleware,
    ),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
);

const reducer = combineReducers({
  ...reducers,
  form: reduxFormReducer,
  routing: routerReducer,
});

const configureStore = (initialState = {}) => {
  return createStore(reducer, initialState, enhancer);
};

export default configureStore;
