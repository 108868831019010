import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Modal, ModalBody, ModalHeader, Col, Input,
  Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import FontAwesome from "../../../../components/FontAwesome";
import styles from "./FeedFilterModal.scss";
import feed from "./FeedFilterModal.scss";
import Button from "../../../../components/Button";
import {CATEGORY} from './CategoryConstants.js';
import {CoinTypes} from './CoinConstants.js';
import SourcesConstants from './SourcesConstants.js';
import {SOURCES, OPTIONS} from "./FeedConstants";
import {EXCHANGES} from "./ExchangeConstants";
import Select from 'react-select';
import ColorPicker from "../../../../components/ColorPicker/ColorPicker";

class FeedFilterModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }
  componentDidUpdate(prevState) {
    if (this.props.isOpen && !prevState.isOpen && this.props.filters.length === 0) {
      this.handleAddFilter();
    }
  }

  handleChange = (filter, type, value) => {
    this.props.updateFilter({
      ...filter,
      [type]: value,
    });
  };

  toggleTab = (tab) => {
    this.state.activeTab !== tab && this.setState({
      activeTab: tab,
    });
  };

  handleAddFilter = () => {
    this.props.addFilter({
      source: SOURCES[0].value,
      option: OPTIONS[0].value,
      query: "",
    });
  };

  render() {
    const {isOpen, toggleModal} = this.props;
    const { activeTab } = this.state;

    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={() => toggleModal("feedFilter")}
        className={classNames("feed-modal", styles.moveable)}
        size="lg"
      >
        <ModalHeader
          className={styles.Header}
          toggle={() => toggleModal("feedFilter")}
        >
          Newsfeed <span>& Settings</span>
        </ModalHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '1' })}
              onClick={() => this.toggleTab('1')}
            >
              Filters
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '2' })}
              onClick={() => this.toggleTab('2')}
            >
              Tickers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '3' })}
              onClick={() => this.toggleTab('3')}
            >
              Exchanges
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '4' })}
              onClick={() => this.toggleTab('4')}
            >
              Sectors
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '5' })}
              onClick={() => this.toggleTab('5')}
            >
              Sources
            </NavLink>
          </NavItem>
        </Nav>
        <ModalBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className={feed.ModalContent}>
                <span className={styles.tabHeadline}>
                  <FontAwesome name="filter" className={"iconHighlight"}/> add/remove Filters
                </span>
                <div className={styles.tabParagraph}>Choose the condition(s) the feed URL(s) should meet.</div>

                <div>
                  <div className={styles.wrapper}>
                    <Col xs={2} className={feed.selectbox}>
                      <div className="feedmodal">
                        <Select
                          options={OPTIONS}
                          defaultValue={{label: <span>Allow</span>, value: "temp"}}
                          className="OrderTypes"
                          classNamePrefix="react-select"
                          isSearchable
                        //menuIsOpen
                        />
                      </div>
                    </Col>
                    <Col xs={2} className={feed.selectbox}>
                      <div className="feedmodal">
                        <Select
                          options={SOURCES}
                          defaultValue={{label: <span>Title</span>, value: "title"}}
                          className="OrderTypes"
                          classNamePrefix="react-select"
                          isSearchable
                        //menuIsOpen
                        />
                      </div>
                    </Col>
                    <span
                      className={styles.test}
                      style={{marginRight: 5}}
                    >
                      <FontAwesome
                        prefix="fas"
                        name="filter"
                      />
                      <input
                        className={styles.test}
                        placeholder="Add filter..."
                      />
                    </span>
                    <div className={feed.selectcolor}>
                      <ColorPicker
                        color="#679267"
                      />
                    </div>
                    <Col className={styles.plusMinus}>
                      <button
                        className={styles.ActionButton}
                      >
                        <FontAwesome name="trash"/>
                      </button>
                      <button
                        className={feed.ActionButton}
                        onClick={this.handleAddFilter}
                      >
                        <FontAwesome name="plus-circle"/>
                      </button>
                    </Col>
                  </div>
                </div>
                {/*  {filters.map((filter) => (
                    <Row
                      className={feed.FilterItem}
                      key={filter.uid}
                    >
                      <Col xs={2} className={feed.Columns}>
                        <Select1
                          value={filter.option}
                          onChange={(event) => this.handleChange(filter, "option", event.target.value)}
                        >
                          {OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select1>
                      </Col>
                      <Col xs={2} className={feed.Columns}>
                        <Select1
                          value={filter.source}
                          onChange={(event) => this.handleChange(filter, "source", event.target.value)}
                        >
                          {SOURCES.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select1>
                      </Col>
                      <Col xs={7} className={feed.Columns}>
                        <Input
                          value={filter.query}
                          className={styles.ActionButton1}
                          placeholder="Add a filter..."
                          onChange={(event) => this.handleChange(filter, "query", event.target.value)}
                        />
                      </Col>
                      <Col className={styles.plusMinus}>
                        <Button
                          className={feed.ActionButton}
                          label={<FontAwesome name="trash"/>}
                          onClick={() => removeFilter(filter)}
                        />
                        <Button
                          className={feed.ActionButton}
                          label={<FontAwesome name="plus-circle"/>}
                          onClick={this.handleAddFilter}
                        />
                      </Col>
                    </Row>
                  ))}*/}
              </div>


            </TabPane>
            <TabPane tabId="2">
              <span className={styles.tabHeadline}>
                <FontAwesome name="btc" prefix="fab" className={"iconHighlight"}/> add/remove tickers
              </span>
              <div className={styles.tabParagraph}>Choose one or more ticker(s) here.</div>
              <div>
                <div className={styles.wrapper}>
                  <Col xs={2} className={feed.selectbox}>
                    <div className="feedmodal">
                      <Select
                        options={OPTIONS}
                        defaultValue={{label: <span>Allow</span>, value: "temp"}}
                        className="OrderTypes"
                        classNamePrefix="react-select"
                        isSearchable
                      //menuIsOpen
                      />
                    </div>
                  </Col>
                  <div className="selectexchanges">
                    <div className="feedmodalsearch">
                      <span className={styles.test}>
                        <FontAwesome
                          prefix="fab"
                          name="btc"
                        />
                        <Select
                          options={CoinTypes}
                          placeholder="Add ticker..."
                          className="OrderTypes"
                          classNamePrefix="react-select"
                          isMulti
                          //menuIsOpen
                          formatOptionLabel={CoinTypes => (
                            <div>
                              <b>{CoinTypes.ticker}</b>
                              {CoinTypes.long}
                            </div>
                          )}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={feed.selectcolor}>
                    <ColorPicker
                      color="#da2c43"
                    />
                  </div>
                  <Col className={styles.plusMinus}>
                    <Button
                      className={feed.ActionButton}
                      label={<FontAwesome name="trash"/>}
                    />
                    <Button
                      className={feed.ActionButton}
                      label={<FontAwesome name="plus-circle"/>}
                      onClick={this.handleAddFilter}
                    />
                  </Col>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <span className={styles.tabHeadline}>
                <FontAwesome name="globe" prefix="far" className={"iconHighlight"}/> add/remove Exchanges
              </span>
              <div className={styles.tabParagraph}>Choose one or more exchange condition(s) here.</div>
              <div>
                <div className={styles.wrapper}>
                  <Col xs={2} className={feed.selectbox}>
                    <div className="feedmodal">
                      <Select
                        options={OPTIONS}
                        defaultValue={{label: <span>Allow</span>, value: "temp"}}
                        className="OrderTypes"
                        classNamePrefix="react-select"
                        isSearchable
                        //menuIsOpen
                      />
                    </div>
                  </Col>
                  <div className="selectexchanges">
                    <div className="feedmodalsearch">
                      <span className={styles.test}>
                        <FontAwesome
                          prefix="far"
                          name="globe"
                        />
                        <Select
                          options={EXCHANGES}
                          placeholder="Add exchange..."
                          className="OrderTypes"
                          classNamePrefix="react-select"
                          isSearchable
                          isMulti
                          //menuIsOpen
                          formatOptionLabel={EXCHANGES => (
                            <div>
                              {EXCHANGES.image}
                              {EXCHANGES.label}
                            </div>
                          )}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={feed.selectcolor}>
                    <ColorPicker
                      color="#417dc1"
                    />
                  </div>
                  <Col className={styles.plusMinus}>
                    <Button
                      className={feed.ActionButton}
                      label={<FontAwesome name="trash"/>}
                    />
                    <Button
                      className={feed.ActionButton}
                      label={<FontAwesome name="plus-circle"/>}
                      onClick={this.handleAddFilter}
                    />
                  </Col>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="4">
              <span className={styles.tabHeadline}>
                <FontAwesome name="list" className={"iconHighlight"}/> add/remove Sectors
              </span>
              <div className={styles.tabParagraph}>Choose one or more sector(s) here.</div>
              <div>
                <div className={styles.wrapper}>
                  <Col xs={2} className={feed.selectbox}>
                    <div className="feedmodal">
                      <Select
                        options={OPTIONS}
                        defaultValue={{label: <span>Allow</span>, value: "temp"}}
                        className="OrderTypes"
                        classNamePrefix="react-select"
                        isSearchable
                      //menuIsOpen
                      />
                    </div>
                  </Col>
                  <span className="selectcategory">
                    <div className="feedmodalsearch">
                      <span className={styles.test}>
                        <FontAwesome
                          prefix="fas"
                          name="list"
                        />
                        <Select
                          options={CATEGORY}
                          className="OrderTypes"
                          classNamePrefix="react-select"
                          isSearchable
                          isMulti
                          //menuIsOpen
                          placeholder="Add category..."
                          formatOptionLabel={CATEGORY => (
                            <div>
                              {CATEGORY.image}
                              {CATEGORY.label}
                            </div>
                          )}
                        />
                      </span>
                    </div>
                  </span>
                  <div className={feed.selectcolor}>
                    <ColorPicker
                      color="#da9100"
                    />
                  </div>
                  <Col className={styles.plusMinus}>
                    <Button
                      className={feed.ActionButton}
                      label={<FontAwesome name="trash"/>}
                    />
                    <Button
                      className={feed.ActionButton}
                      label={<FontAwesome name="plus-circle"/>}
                      onClick={this.handleAddFilter}
                    />
                  </Col>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="5">
              <span className={styles.tabHeadline}><FontAwesome name="code" className={"iconHighlight"}/> NewsFeed Sources</span>
              <div className={styles.tabParagraph}>Paste RSS publicly accessible feed URL&apos;s here.</div>
              <div className={styles.rssFeeds}>
                {SourcesConstants.map(data => (
                  <div key={data.url}>
                    <div className={styles.sourcesURL}>
                      {/*<div className={styles.onlinesources}>
                        <img alt="Draw" src="/img/icons/broadcast.svg" />
                      </div>*/}
                      <div className={styles.onlinesourcesURL}>
                        <span className={styles.test}>
                          <FontAwesome
                            prefix="fas"
                            name="link"
                          />
                          <Input
                            className={styles.feedSources}
                            type="text"
                            value={data.url}
                          />
                        </span>
                      </div>
                      <div className={styles.plusMinus}>
                        <Button
                          className={feed.ActionButton}
                          label={<FontAwesome name="trash"/>}
                        />
                        <Button
                          className={feed.ActionButton}
                          label={<FontAwesome name="plus-circle"/>}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
          </TabContent>
          <button
            className={feed.SaveButton}
            onClick={() => toggleModal("feedFilter")}
            type="button"
          >
            Save
          </button>
          <button
            className={feed.CancelButton}
            onClick={() => toggleModal("feedFilter")}
            type="button"
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    );
  }
}

FeedFilterModal.propTypes = {
  addFilter: PropTypes.func.isRequired,
  filters: PropTypes.array,
  isOpen: PropTypes.bool,
  removeFilter: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

FeedFilterModal.defaultProps = {
  filters: [],
  isOpen: false,
};

export default FeedFilterModal;
