/* eslint max-len: 0 */
/* eslint no-unused-vars: 0 */
import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CanvasChart from "./CanvasChart/CanvasChart";
import Loader from "../../../../components/Loader";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import ChartMenu from "./ChartMenu";
import DrawingToolMenu from "./DrawingToolMenu";
import {COLS, ROWS, INTERVALS} from "../../../../constants/MarketsConstants";

const SECONDS = 2;

import styles from "./ChartWidget.scss";

const toggleSVG = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16" width="4" height="16"><path d="M.6 1.4l1.4-1.4 8 8-8 8-1.4-1.4 6.389-6.532-6.389-6.668z"/></svg>;

class ChartWidget extends Component {
  constructor(props) {
    super(props);
    const {widget, getExchangeMarketsAction} = this.props;
    getExchangeMarketsAction(widget.exchange);                  // to get precision

    this.interval = null;
    this.state = {
      isLoading: true,
      open: false,
      indicator: undefined,
      menuOpen: false,
      menuPosition: [0, 0],
      chartMenuOpen: false,
      chartMenuPosition: [0, 0],
      currentItem: null,
      showSideBar: false,
      openMenuIdx: 1,
      showDrawingToolMenu: false,
      drawingItemId: 'cross',
      drawingPoints: 0,
      drawingFinished: true,
      drawingCategories: [
        {
          type: 'item',
          index: 1,
          id: 'cross',
          points: 0,
          selected: true,
          defaultSVG: <img alt="crosshair" src="/img/icons/cross.svg" />
        },
        {
          type: 'item',
          index: 2,
          id: 'trendline',
          points: 2,
          selected: false,
          defaultSVG: <img alt="trendline" src="/img/icons/trendline.svg" />
        },
        {
          type: 'item',
          index: 3,
          id: 'fibretracement',
          points: 2,
          selected: false,
          defaultSVG: <img alt="fibretracement" src="/img/icons/fibretracement.svg" />
        },
        {
          type: 'item',
          index: 4,
          id: 'gann',
          points: 2,
          selected: false,
          defaultSVG: <img alt="gannfan" src="/img/icons/gannfan.svg" />
        },
        {
          type: 'item',
          index: 5,
          id: 'pitchfork',
          points: 2,
          selected: false,
          defaultSVG: <img alt="Pitchfork" src="/img/icons/pitchfork.svg" />
        },
        {
          type: 'item',
          index: 6,
          id: 'shapes',
          points: 2,
          selected: false,
          defaultSVG: <img alt="shapes" src="/img/icons/shapes.svg" />
        },
        {
          type: 'item',
          index: 7,
          id: 'text',
          points: 1,
          selected: false,
          defaultSVG: <img alt="text" src="/img/icons/text.svg" />

        },
        {
          id: 'separator',
          type: 'separator',
          index: 0,
          points: 0,
          selected: false,
          defaultSVG: <svg/>
        },
        {
          type: 'item',
          index: 8,
          id: 'candlestick',
          points: 2,
          selected: false,
          defaultSVG: <img alt="candlestick" src="/img/icons/candlestick.svg" />

        },
        {
          type: 'item',
          index: 9,
          id: 'layers',
          points: 2,
          selected: false,
          defaultSVG: <img alt="layers" src="/img/icons/layers.svg" />
        },
        {
          type: 'separator',
          id: 'separator2',
          index: 0,
          points: 0,
          selected: false,
          defaultSVG: <svg/>
        },
        {
          type: 'item',
          index: 10,
          id: 'deldrawingtool',
          points: 0,
          selected: false,
          defaultSVG: <img alt="trash" src="/img/icons/trash.svg" />
        }
      ]
    };
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentDidMount() {
    const {widget, getAvailableTFAction} = this.props;
    if (widget.availableTF && widget.availableTF.length == 0) {
      getAvailableTFAction(widget);
    } else {
      this.startGettingChartData();
    }
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {widget, orders, tempOrder} = this.props;
    let shouldUpdate = false;
    Object.keys(widget).forEach((key) => {
      if (nextProps.widget[key] !== widget[key]) {
        shouldUpdate = true;
      }
    });
    Object.keys(tempOrder).forEach((key) => {
      if (nextProps.tempOrder[key] !== tempOrder[key]) {
        shouldUpdate = true;
      }
    });
    Object.keys(this.state).forEach((key) => {
      if (nextState[key] !== this.state[key]) {
        shouldUpdate = true;
      }
    });
    if (nextProps.orders.length !== orders.length) {
      shouldUpdate = true;
    }
    if (nextProps.tempOrder.id !== tempOrder.id) {
      shouldUpdate = true;
    }
    return shouldUpdate;
  }

  componentDidUpdate(prevProps, prevState) {
    const {widget, getAvailableTFAction} = this.props;
    const {drawingFinished, drawingCategories} = this.state;
    if (widget.loading) {
      return false;
    }
    if (prevState.drawingFinished !== this.state.drawingFinished){
      if (drawingFinished){
        drawingCategories.map((item)=>{
          item.selected = false;
        });
        drawingCategories[0].selected = true;
        this.setState({
          drawingCategories,
          drawingItemId: drawingCategories[0].id,
          drawingPoints: drawingCategories[0].points
        });
      }
    }
    if (prevProps.widget.exchange !== widget.exchange) {
      getAvailableTFAction(widget);
    } else {
      if (prevProps.widget.period !== widget.period ||
        prevProps.widget.name !== widget.name) {
        this.setState({isLoading: true});
        this.startGettingChartData();
      } else if (widget.data.length === 0) {
        this.startGettingChartData();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
    this.wrapper.removeEventListener("contextmenu", this.setActiveWidget);
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup() {
    const {unsubscribeLatestTick} = this.props;
    unsubscribeLatestTick(this.props.widget);
  }


  addEvent = (el) => {
    if (el) {
      this.wrapper = el;
      this.wrapper.addEventListener("contextmenu", this.setActiveWidget, false);
    }
  };

  setActiveWidget = () => {
    const {widget, setWidgetActive} = this.props;
    setWidgetActive(widget.i);
  };
  startGettingChartData = () => {

    const {getChartData, getLatestChartData, widget} = this.props;
    //check if period is supported by exchange
    if (widget.availableTF) {

      const availableTF = widget.availableTF;
      const currentPeriod = widget.period;
      let basePeriod = {};
      const currentPeriodInfo = INTERVALS.find((interval) => interval.value == currentPeriod);
      if (currentPeriodInfo) {
        const currentPeriodInMin = currentPeriodInfo.min;
        const availableIntervals = INTERVALS.filter((interval) => availableTF.indexOf(interval.id) > -1);
        const newBasePeriod = availableIntervals.reverse().find((interval) => currentPeriodInMin % interval.min == 0);

        basePeriod = {
          from: {value: newBasePeriod?.value, min: newBasePeriod?.min},
          to: {value: widget.period, min: currentPeriodInMin},
        };
      } else {
        //custom interval
        basePeriod = widget.basePeriod;
      }
      this.fetchData(widget, basePeriod);
      // this.props.getLatestChartData(widget, basePeriod);

    }
  }

  fetchData = async (widget, basePeriod) => {
    clearTimeout(this.interval);
    this.props.getChartData(widget, basePeriod)
        .then(()=>this.setState({isLoading: false}));
    this.interval=setTimeout(
        ()=>this.fetchData(widget, basePeriod),
        (60-(new Date()).getSeconds())*1000
    );
  }
  handleToggleMenu = (indicator, position) => {
    if (!indicator && !position) {
      this.setState({
        menuOpen: false,
      });
    } else {
      this.setState({
        menuOpen: !this.state.menuOpen,
        menuPosition: position,
        indicator: {
          indicator,
        },
      });
    }
  };

  handleToggleChartMenu = (isOpen, position, currentItem) => {
    this.setState({
      chartMenuOpen: isOpen,
    });
    if (isOpen) {
      this.setState({
        chartMenuPosition: position,
        currentItem: currentItem,
      });
    }
  };

  updateIndicator = (id, options) => {
    const {updateWidget, widget} = this.props;
    updateWidget({
      ...widget,
      indicators: widget.indicators.map((item) => item.uid !== id ? item : {
        ...item,
        options: options,
      }),
    });
  };

  removeIndicator = (id) => {
    const {updateWidget, widget} = this.props;
    updateWidget({
      ...widget,
      indicators: widget.indicators.filter((item) => item.uid !== id),
    });
  };
  toggleSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar
    });
  }
  showDrawingMenu = (e, index) => {
    const target = e.currentTarget;
    if (this.state.showDrawingToolMenu && index == this.state.openMenuIdx){
      target.blur();
    }
    if (this.state.showDrawingToolMenu && index != this.state.openMenuIdx){
      document.removeEventListener('click', this.closeDrawingMenu);
    }
    this.setState({
      openMenuIdx: index,
      showDrawingToolMenu: true
    });
    setTimeout(() => {
      document.addEventListener('click', this.closeDrawingMenu);
    });
  }
  closeDrawingMenu = (e)=>{
    this.setState({
      openMenuIdx: 0,
      showDrawingToolMenu: false
    }, () => {
      document.removeEventListener('click', this.closeDrawingMenu);
    });
  }
  handleDrawingItem = (menuIdx, drawingItemId, drawingPoints, svg)=>{
    const {drawingCategories} = this.state;
    drawingCategories.map((item)=>{
      if (item.index == menuIdx && menuIdx !== 6 ){
        item.defaultSVG = svg;
        item.id = drawingItemId;
        item.points = drawingPoints;
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    this.setState({
      drawingCategories,
      drawingItemId,
      drawingPoints,
    });
  }
  handleDrawingCheck = (drawingFinished) => {
    this.setState({
      drawingFinished
    });
  }
  render() {
    const {
      widget, height, index, width, resizingWidgetId, orders, orderHistory, updateWidget, cancelOrderAction, setWidgetActive, toggleModal,
      tempOrder, updateTempOrder, removeTempOrder, getPrevChartData, exchangeMarkets, getLatestMTFChartData, getMTFInitChartData,
      drawingTemplates,
    } = this.props;
    let precision = 8;
    for (const i in exchangeMarkets) {
      const market = exchangeMarkets[i];
      if (market.id == widget.name) {
        precision = market.precision.price;
        break;
      }
    }

    return (
      <div
        ref={this.addEvent}
        className={classNames("widgetWrapper", {"Active": widget.active})}
        onClick={() => !widget.active && setWidgetActive(widget.i)}
        role="button"
        tabIndex="0"
        style={widget.expanded ? {height: `${height * COLS - 5}px`} : {}}
      >
        <div style={{position: 'relative', height: '100%'}}>
          <div className={styles.sidebar}>
            <ul className={`${this.state.showSideBar ? styles.sideBarShow : styles.sideBarHide}`}>
              {
                this.state.drawingCategories.map((item, idx)=>{
                  return (item.type == 'item' ?
                    <li key={item.id}>
                      <div
                        className={styles.icon + ' icon'}
                        role="button"
                        tabIndex="0"
                        onClick={(e)=>this.handleDrawingItem(idx + 1, item.id, item.points, item.defaultSVG)}
                      >
                        {
                          item.selected ?
                            <div className={styles.selected}>{item.defaultSVG}</div> : item.defaultSVG
                        }
                      </div>
                      <div
                        className={styles.toggle}
                        role="button"
                        tabIndex="0"
                        onClick={(e)=>this.showDrawingMenu(e, item.index)}
                      >
                        {toggleSVG}
                      </div>
                    </li>:
                    <li key={item.id} className={styles.separator}/>
                  );
                })
              }
            </ul>
          </div>
          <div className={styles.toggleContainer + ` ${this.state.showSideBar ? styles.toggleShow : styles.toggleHide}`}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.toggleSideBar}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16" width="4" height="16">
                <path d="M.6 1.4l1.4-1.4 8 8-8 8-1.4-1.4 6.389-6.532-6.389-6.668z"/>
              </svg>
            </div>
          </div>
          <div className={styles.chart + ` ${this.state.showSideBar ? styles.chartNarrow : styles.chartExpand}`}>
            <WidgetToolbar
              {...this.props}
            />
            {this.state.isLoading || widget.data.length === 0 ? (
              <Loader/>
            ) : (
              <div
                className="chartWrapper disableDrag"
              >
                <CanvasChart
                  index={index}
                  height={widget.expanded ? height * COLS : height * widget.h - 30}
                  width={(widget.expanded ? width : width * widget.w / ROWS) - 20}
                  updateWidget={updateWidget}
                  widget={widget}
                  cancelOrderAction={cancelOrderAction}
                  resizingWidgetId={resizingWidgetId}
                  drawingItemId={this.state.drawingItemId}
                  drawingPoints={this.state.drawingPoints}
                  handleDrawingCheck={this.handleDrawingCheck}
                  handleToggleModal={toggleModal}
                  drawingTemplates={drawingTemplates}
                  handleToggleChartMenu={this.handleToggleChartMenu}
                  orders={orders}
                  updateData={getPrevChartData}
                />
                <ChartMenu
                  {...this.props}
                  currentItem={this.state.currentItem}
                  height={widget.expanded ? height * COLS : height * widget.h - 20}
                  isOpen={this.state.chartMenuOpen}
                  toggleMenu={this.handleToggleMenu}
                  position={this.state.chartMenuPosition}
                  handleToggleChartMenu={this.handleToggleChartMenu}
                />
              </div>
            )}
          </div>
          {
            this.state.showDrawingToolMenu ?
              <DrawingToolMenu
                index={this.state.openMenuIdx}
                handleDrawingItem={this.handleDrawingItem}
              /> : null
          }
        </div>
      </div>
    );
  }
}

ChartWidget.propTypes = {
  cancelOrderAction: PropTypes.func,
  drawingTemplates: PropTypes.object.isRequired,
  exchangeMarkets: PropTypes.array,
  getAvailableTFAction: PropTypes.func,
  getChartData: PropTypes.func,
  getExchangeMarketsAction: PropTypes.func,
  getLatestChartData: PropTypes.func,
  getLatestMTFChartData: PropTypes.func,
  getMTFInitChartData: PropTypes.func,
  getPrevChartData: PropTypes.func,
  getPriceData: PropTypes.func,
  unsubscribeLatestTick: PropTypes.func,
  height: PropTypes.number,
  index: PropTypes.number,
  orderHistory: PropTypes.array,
  orders: PropTypes.array,
  removeTempOrder: PropTypes.func,
  resizingWidgetId: PropTypes.string,
  setWidgetActive: PropTypes.func,
  tempOrder: PropTypes.object,
  toggleModal: PropTypes.func,
  updateTempOrder: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
  width: PropTypes.number,
};

ChartWidget.defaultProps = {
  cancelOrderAction: () => false,
  getAvailableTFAction: () => false,
  getChartData: () => false,
  getExchangeMarketsAction: () => false,
  getLatestChartData: () => false,
  getLatestMTFChartData: () => false,
  getMTFInitChartData: () => false,
  getPrevChartData: () => false,
  getPriceData: () => false,
  unsubscribeLatestTick: () => false,
  height: 250,
  index: undefined,
  exchangeMarkets: [],
  orderHistory: [],
  orders: [],
  removeTempOrder: () => false,
  resizingWidgetId: '',
  setWidgetActive: () => false,
  tempOrder: {},
  toggleModal: () => false,
  updateTempOrder: () => false,
  widget: {},
  width: 500,
};

export default ChartWidget;
