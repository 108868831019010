import {uid} from "uid";
import axios from "axios";
import {ALERT_TYPES} from "../const";
import {getApiUrl} from "../utils/apiUtils";
import {
  getChannel, getChannelsList, createChannel, deleteChannel, updateChannel, getUsers, getMessages, createMessage,
  leaveChannel, getPublicChannels, joinChannel, deleteMessage, updateMessage, getRootChannels, fetchUrl,
} from "../api/ChatApi";
import {showAlert} from "../actions/CommonActions";
import {
  GET_USERS_SUCCESS,
  GET_CHANNELS_SUCCESS,
  GET_CHANNEL_SUCCESS,
  GET_MESSAGES_SUCCESS,
  SET_CHANNEL,
  GET_MESSAGES,
  GET_PUBLIC_CHANNELS_SUCCESS,
  MESSAGE_CREATED,
  MESSAGE_DELETED,
  MESSAGE_UPDATED,
  SET_MESSAGE_FOR_EDITING,
  USER_STATUS_UPDATED,
  GET_ROOT_CHANNELS_SUCCESS,
  URL_FETCH,
  URL_FETCH_SUCCESS,
} from "../constants/ChatConstants";

const getUsersAction = () => async (dispatch) => {
  try {
    const users = await getUsers();
    dispatch({
      type: GET_USERS_SUCCESS,
      data: users.data,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

// Channel actions
const getChannelsAction = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const channels = await getChannelsList();
    dispatch({
      type: GET_CHANNELS_SUCCESS,
      data: channels.data,
    });

    dispatch({
      type: USER_STATUS_UPDATED,
      data: false,
    });

    if (!state.chat.channel || !channels.data.find((item) => item._id === state.chat.channel)) {
      dispatch(setChannelAction(channels.data[0] && channels.data[0]._id));
    }
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getPublicChannelsAction = () => async (dispatch) => {
  try {
    const channels = await getPublicChannels();
    dispatch({
      type: GET_PUBLIC_CHANNELS_SUCCESS,
      data: channels.data,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getRootChannelsAction = () => async (dispatch) => {
  try {
    const channels = await getRootChannels();
    dispatch({
      type: GET_ROOT_CHANNELS_SUCCESS,
      data: channels.data,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const getChannelAction = (channel) => async (dispatch) => {
  try {
    const result = await getChannel(channel);
    await dispatch({
      type: GET_CHANNEL_SUCCESS,
      data: result.data,
    });
    dispatch(getMessagesAction());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const leaveChannelAction = (channel) => async (dispatch) => {
  try {
    await leaveChannel(channel);
    dispatch(getChannelsAction());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const joinChannelAction = (channel) => async (dispatch) => {
  try {
    await joinChannel(channel);
    dispatch(getChannelsAction());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const setChannelAction = (channel) => async (dispatch) => {
  await dispatch({
    type: SET_CHANNEL,
    data: channel,
  });
  dispatch(getMessagesAction());
};

const createChannelAction = (channel) => async (dispatch) => {
  try {
    await createChannel(channel);
    dispatch(getChannelsAction());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const updateChannelAction = (id, channel) => async (dispatch) => {
  try {
    await updateChannel(id, channel);
    dispatch(getChannelsAction());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const deleteChannelAction = (channel) => async (dispatch) => {
  try {
    await deleteChannel(channel);
    dispatch(getChannelsAction());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

// Messages actions
const getMessagesAction = (date = null) => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: GET_MESSAGES,
  });
  try {
    const result = await getMessages(state.chat.channel, {date: date});
    dispatch({
      type: GET_MESSAGES_SUCCESS,
      data: result.data,
      total: result.total,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const sendMessageAction = ({message, burnTimeout}) => async (dispatch, getState, {emit}) => {
  const state = getState();

  try {
    dispatch({
      type: MESSAGE_CREATED,
      id: uid(10),
      message: message,
      user: state.user.user.data.id,
      burnTimeout: burnTimeout !== "none" ? burnTimeout : null,
      createdDate: new Date(),
      isTemp: true,
    });
    await createMessage({
      channel: state.chat.channel,
      message: message,
      burnTimeout: burnTimeout !== "none" ? burnTimeout : null,
    });
    // FIXME not secure
    emit("sentMessage", {
      params: {
        channel: state.chat.channel,
        message: message,
      },
    }, "chat");
    dispatch(getMessagesAction());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const deleteMessageAction = (id) => async (dispatch, getState, {emit}) => {
  try {
    dispatch({
      type: MESSAGE_DELETED,
      id: id,
    });
    await deleteMessage(id);
    emit("deletedMessage", {
      params: {
        message: id,
      },
    }, "chat");
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const updateMessageAction = (id, message) => async (dispatch, getState, {emit}) => {
  const state = getState();
  try {
    dispatch({
      type: SET_MESSAGE_FOR_EDITING,
      message: null,
    });
    dispatch({
      type: MESSAGE_UPDATED,
      id: id,
      message: message,
    });
    await updateMessage(id, {
      body: message.body,
      burnTimeout: message.burnTimeout !== "none" ? message.burnTimeout : null,
    });
    dispatch(getMessagesAction());
    emit("updatedMessage", {
      params: {
        channel: state.chat.channel,
      },
    }, "chat");
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const uploadFileAction = ({file, burnTimeout}) => async (dispatch, getState) => {
  const state = getState();
  if (file.size > 10000000) {
    dispatch(showAlert(ALERT_TYPES.ERROR, "File is too big"));
  } else {
    const data = new FormData();
    data.append("file", file);
    data.append("name", file.name);

    dispatch({
      type: MESSAGE_CREATED,
      id: uid(10),
      message: "Uploading...",
      user: state.user.user.data.id,
      isTemp: true,
    });
    const result = await axios.post(`${getApiUrl()}/chat/file`, data);
    dispatch(sendMessageAction({
      message: `{{FILE:${result.data.data}|${file.name}|${file.size}}}`,
      burnTimeout: burnTimeout !== "none" ? burnTimeout : null,
    }));
  }
};

const fetchUrlAction = (url) => async (dispatch) => {
  try {
    dispatch({
      type: URL_FETCH,
      url: url,
    });
    const result = await fetchUrl({url: url});
    dispatch({
      type: URL_FETCH_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const setMessageForEditing = (message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE_FOR_EDITING,
    message: message,
  });
};

const notifyIsTyping = (isTyping, channel) => (dispatch, getState, {emit}) => {
  const state = getState();
  const user = state.user.user.data;
  const channel = state.chat.channel;

  emit("userTyping", {
    params: {
      isTyping: isTyping,
      user: user.id,
      channel: channel,
    },
  }, "chat");
};

export {
  getUsersAction,
  getChannelsAction,
  getPublicChannelsAction,
  getRootChannelsAction,
  getChannelAction,
  leaveChannelAction,
  joinChannelAction,
  setChannelAction,
  createChannelAction,
  updateChannelAction,
  deleteChannelAction,
  getMessagesAction,
  sendMessageAction,
  deleteMessageAction,
  updateMessageAction,
  uploadFileAction,
  fetchUrlAction,
  setMessageForEditing,
  notifyIsTyping,
};
