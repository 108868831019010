import {connect} from "react-redux";
import MarketsWidget from "../components/MarketsWidget/MarketsWidget";
import {getMarketsInfoAction} from "../../../actions/MarketsActions";

import {
  getMarketSummaries,
  updateActiveWidget,
  updateWidget
} from "../../../actions/MarketsActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null;

      return {
        activeWidget: activeWidget,
        filter: props.filter,
        marketHistory: state.markets.marketHistory,
        widget: widget,
      };
    }, {
      getMarketSummaries,
      updateActiveWidget,
      updateWidget,
      getMarketsInfoAction,
    },
)(MarketsWidget);
