import React, {Component} from "react";
import PropTypes from "prop-types";
import {Container, Row, Col, Input, InputGroup} from "reactstrap";
import styles from "./LoginPage.scss";
import FontAwesome from "../../../components/FontAwesome";
import {Link} from "react-router-dom";
const fields = [
  {name: "email", type: "email", title: "Email", placeholder: "Email", icon: 'user'},
  {name: "password", type: "password", title: "Password", placeholder: "Password", icon: 'lock'},
  {name: "token", type: "text", title: "2FA token", placeholder: "2FA Token", icon: 'key'},
];
const defaultState={
  email: "",
  password: "",
  token: "",
};
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  handleLogin = async () => {
    const response=await this.props.doLogin(this.state);
    await this.props.getUserName();
    if (response) {
      this.props.history.push('/markets/workspaces');
    } else {
      this.setState(defaultState);
    }
  };

  onHandleChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  async onKeyUp(event) {
    if (event.charCode === 13) {
      await this.handleLogin();
    }
  }

  render() {
    return (
      <div className="login-wrapper">
        <Container>
          <Row>
            <Col className="text-left">
              <h2>Welcome Back :)</h2>
              <h6>We make it easy for you to Create, Build & Design Endless Possibilities.</h6>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xs="8" sm="8">
              {fields.map((field) => (
                <InputGroup
                  key={field.name}
                  onKeyPress={this.onKeyUp}
                >
                  <div className="input-group-addon" addonType="prepend">
                    <FontAwesome
                      name={field.icon}
                      prefix="fas"
                    />
                  </div>
                  <Input
                    type={field.type}
                    name={field.name}
                    onChange={(event) => this.onHandleChange(field.name, event.target.value)}
                    placeholder={field.placeholder}
                    value={this.state[field.name]}
                  />
                </InputGroup>
              ))}
              <row>
                <button
                  className="SaveButton"
                  onClick={this.handleLogin}
                  type="button"
                >
                  Login
                </button>
                <row className={styles.checkrow}>
                  New to aurlix?<Link to="/signup"> Join us now!</Link>
                </row>
              </row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

LoginPage.propTypes = {
  doLogin: PropTypes.func.isRequired,
  getUserName: PropTypes.func.isRequired,
};

export default LoginPage;
