// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UVcbRO4k-VwNtfSJ0Qhc1{height:100%}.UVcbRO4k-VwNtfSJ0Qhc1 ._3bVIBJAg8qKBVUPaKAy5uw{height:100%}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/MarketsWrapper.scss"],"names":[],"mappings":"AAGE,uBACE,WAAA,CAEA,gDACE,WAAA","sourcesContent":["@import \"../../../styles/constants.scss\";\n\n:local {\n  .Wrapper {\n    height: 100%;\n\n    .Content {\n      height: 100%;//;calc(100% - 26px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "UVcbRO4k-VwNtfSJ0Qhc1",
	"Content": "_3bVIBJAg8qKBVUPaKAy5uw"
};
export default ___CSS_LOADER_EXPORT___;
