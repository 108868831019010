import flow from "lodash/flow";
import {getActiveWorksheet} from "./worksheet";

export const getWidgetById=(id, state)=>flow([
  getActiveWorksheet,
  ({widgets})=>widgets.find(({i})=>id===i)
])(state);

export const getWidgetExchange=(id, state)=>flow([
  getActiveWorksheet,
  ({widgets})=>widgets.find(({i})=>id===i)?.exchange
])(state);

export const getActiveWidget=flow([
  getActiveWorksheet,
  ({widgets})=>widgets.find(({active})=>active)
]);