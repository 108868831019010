/* eslint max-len: 0 */
/* eslint no-unused-vars: 0 */
import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FontAwesome from "../../../../components/FontAwesome";
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";

import styles from "./ChartMenu.scss";
import FloatValue from "../../../../components/FloatValue";
import MarketName from "../../../../components/MarketName";

const CHART_TYPES = [{
  id: "Candlestick",
  label: "Candlestick",
}];

class ChartMenu extends PureComponent {
  constructor() {
    super();

    this.state = {
      btnChartDropright: false,
    };
  }

  handleMakeSnapshot = async () => {
    const {saveImageAction, widget, height, toggleModal, handleToggleChartMenu} = this.props;
    handleToggleChartMenu();
    toggleModal("snapshot");
    saveImageAction(document.querySelectorAll(".widgetWrapper.Active")[0], height * widget.h);
  };

  handleRemoveIndicators = () => {
    const {updateWidget, widget, handleToggleChartMenu} = this.props;
    handleToggleChartMenu();
    updateWidget({
      ...widget,
      indicators: [],
    });
  };
  handleRemoveDrawing = () => {
    const {updateWidget, widget, handleToggleChartMenu} = this.props;
    handleToggleChartMenu();
    updateWidget({
      ...widget,
      drawings: [],
    });
  };

  render() {
    const {
      position, isOpen, currentItem, activeWidget, toggleModal, handleToggleChartMenu, openTradeWidget, updateWidget,
      widget,
    } = this.props;
    const currencies = (widget.name.split("-"));
    const currency = (currencies[1]);
    return (

      <div
        className={classNames(styles.ChartMenu, {[styles.Hidden]: !isOpen})}
        style={{minWidth: 100 + 11.5*(activeWidget?.name.length??10), left: position[0], top: position[1]}}
      >
        <span className={styles.drawingItemTitle}>Trade</span>
        {/*<button*/}
        {/*className={styles.Button}*/}
        {/*>*/}
        {/*<FontAwesome name="bell" prefix="fas"/> Add Alert {currentItem && <FloatValue value={currentItem.close}/>}*/}
        {/*</button>*/}
        {/*<div className={styles.Divider}/>*/}
        <button
          className={styles.Button}
          onClick={() => {
            openTradeWidget(currentItem ? currentItem.close.toFixed(8) : null, "buy");
            handleToggleChartMenu();
          }}
        >
          <FontAwesome className={styles.buttonIcon} name="arrow-up" prefix="fas"/><span className={styles.buttonItem}>Buy {currency} @ {currentItem && (
            <FloatValue value={currentItem.close}/>
          )}</span>
        </button>
        <button
          className={styles.Button}
          onClick={() => {
            openTradeWidget(currentItem ? currentItem.close.toFixed(8) : null, "sell");
            handleToggleChartMenu();
          }}
        >
          <FontAwesome className={styles.buttonIcon} name="arrow-down" prefix="fas" /><span className={styles.buttonItem}>Sell {currency} @ {currentItem && (
            <FloatValue value={currentItem.close}/>
          )}</span>
        </button>
        <span className={styles.drawingItemTitle}>Settings</span>
        <button
          className={styles.Button}
          onClick={() => {
            handleToggleChartMenu();
            toggleModal("chartSettings");
          }}
        >
          <FontAwesome className={styles.buttonIcon} name="cog" prefix="fas"/>
          <span className={styles.buttonItem}>Edit Settings</span>
        </button>
        <div className={styles.DropdownWrapper}>
          <ButtonDropdown
            direction="right"
            className={styles.Dropdown}
            isOpen={this.state.btnChartDropright}
            toggle={() => this.setState({btnChartDropright: !this.state.btnChartDropright})}
            onMouseEnter={() => this.setState({btnChartDropright: true})}
            onMouseLeave={() => this.setState({btnChartDropright: false})}
          >
            <DropdownToggle className={styles.Button}>
              <img alt="layers" className={styles.buttonSVG} src="/img/icons/chart.svg" />
              Chart Type
              <FontAwesome
                wrapperClassName={styles.Caret}
                name="chevron-right"
              />
            </DropdownToggle>


            <DropdownMenu className={styles.temp}>
              {CHART_TYPES.map((item) => (
                <DropdownItem
                  className={classNames(styles.Button, {
                    active: widget.options && widget.options.type ?
                      widget.options.type === item.id : item.id === "Candlestick",
                  })}
                  key={item.id}
                  onClick={() => {
                    updateWidget({
                      ...widget,
                      options: {
                        ...widget.options,
                        type: item.id,
                      },
                    });
                    handleToggleChartMenu();
                  }}
                >
                  <img alt={item.label} className={styles.buttonSVG} src={`/img/icons/${item.label.toLowerCase()}.svg`} />{item.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </div>

        <span className={styles.drawingItemTitle}>Studies</span>
        <button
          className={styles.Button}
          onClick={() => {
            handleToggleChartMenu();
            toggleModal("widgetSettings");
          }}
        >
          <img alt="layers" className={styles.buttonSVG2} src="/img/icons/indicators.svg" />
          <span className={styles.buttonItem}>Indicators</span>
        </button>
        <span className={styles.drawingItemTitle}>Canvas</span>
        <div className={styles.DropdownWrapper}>
          <ButtonDropdown
            direction="right"
            className={styles.Dropdown}
            isOpen={this.state.btnChartDropright1}
            toggle={() => this.setState({btnChartDropright1: !this.state.btnChartDropright1})}
            onMouseEnter={() => this.setState({btnChartDropright1: true})}
            onMouseLeave={() => this.setState({btnChartDropright1: false})}
          >
            <DropdownToggle className={styles.Button}>

              <FontAwesome className={styles.buttonIcon} name="layer-group" prefix="fas"/>
              <span className={styles.buttonItem}>Hide/Unhide</span>
              <FontAwesome
                wrapperClassName={styles.Caret}
                name="chevron-right"
                prefix="fas"
              />
            </DropdownToggle>
            <DropdownMenu className={styles.temp}>
              <DropdownItem
                className={classNames(styles.Button)}
                onClick={this.handleRemoveIndicators}
              >
                <img alt="layers" className={styles.menuIcon} src="/img/icons/hideindicators.svg" /> Indicator Layers
              </DropdownItem>
              <DropdownItem
                className={classNames(styles.Button)}
              >
                <img alt="layers" className={styles.menuIcon} src="/img/icons/hidedrawings.svg" /> Drawing Layers
              </DropdownItem>
              <DropdownItem
                className={classNames(styles.Button)}
              >
                <img alt="layers" className={styles.menuIcon} src="/img/icons/hide.svg" /> All Indicators & Drawings
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div><div className={styles.DropdownWrapper}>
          <ButtonDropdown
            direction="right"
            className={styles.Dropdown}
            isOpen={this.state.btnChartDropright2}
            toggle={() => this.setState({btnChartDropright2: !this.state.btnChartDropright2})}
            onMouseEnter={() => this.setState({btnChartDropright2: true})}
            onMouseLeave={() => this.setState({btnChartDropright2: false})}
          >
            <DropdownToggle className={styles.Button}>

              <FontAwesome className={styles.buttonIcon} name="trash-alt" prefix="fas"/>
              <span className={styles.buttonItem}>Delete</span>
              <FontAwesome
                wrapperClassName={styles.Caret}
                name="chevron-right"
                prefix="fas"
              />
            </DropdownToggle>
            <DropdownMenu className={styles.temp}>
              <DropdownItem
                className={classNames(styles.Button)}
                onClick={this.handleRemoveIndicators}
              >
                <img alt="delete" className={styles.menuIcon} src="/img/icons/trashindicators.svg" /> All Indicators
              </DropdownItem>
              <DropdownItem
                className={classNames(styles.Button)}
              >
                <img alt="delete" className={styles.menuIcon} src="/img/icons/trashdrawings.svg" /> All Drawings
              </DropdownItem>
              <DropdownItem
                className={classNames(styles.Button)}
              >
                <img alt="delete" className={styles.menuIcon} src="/img/icons/trash.svg" />All Indicators & Drawings
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <span className={styles.drawingItemTitle}>Other</span>
          <button
            className={styles.Button}
            onClick={() => {
              handleToggleChartMenu();
              toggleModal("chartSettings");
            }}
          >
            <FontAwesome className={styles.buttonIcon} name="undo" prefix="fas"/><span className={styles.buttonItem}>Reset Chart</span>
          </button>
        </div>
      </div>
    );
  }
}

ChartMenu.propTypes = {
  activeWidget: PropTypes.object,
  currentItem: PropTypes.object,
  handleToggleChartMenu: PropTypes.func,
  height: PropTypes.number,
  isOpen: PropTypes.bool,
  openTradeWidget: PropTypes.func,
  position: PropTypes.array,
  saveImageAction: PropTypes.func,
  toggleModal: PropTypes.func,
  updateWidget: PropTypes.func,
  widget: PropTypes.object,
};

ChartMenu.defaultProps = {
  activeWidget: undefined,
  currentItem: undefined,
  handleToggleChartMenu: () => false,
  height: 0,
  isOpen: false,
  openTradeWidget: () => false,
  position: [0, 0],
  saveImageAction: () => false,
  toggleModal: () => false,
  updateWidget: () => false,
  widget: undefined,
};

export default ChartMenu;
