import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import classNames from "classnames";
import FontAwesome from "../../../components/FontAwesome";
import styles from "./AddNewPanelModal.scss";

class addNewPanelModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps) {
  }

  render() {
    const {isOpen, widget, addWidget, toggleModal} = this.props;

    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={() => toggleModal("addNewPanel")}
        className={classNames("feed-modal", styles.moveable)}
        size="lg"
      >
        <ModalHeader
          className={styles.Header}
          toggle={() => toggleModal("addNewPanel")}
        >
          Panels <span>& Widgets</span>
        </ModalHeader>
        <ModalBody className={styles.ModalBody}>
          <div className={styles.contentModal}>
            <div className={styles.search}>
              <FontAwesome
                prefix="fas"
                name="search"
              />
              <input
                className={styles.searchText}
                placeholder="Search..."
              />
            </div>
            <button
              className={styles.gold}
              onClick={this.handleToggleFavourites}
            >
              <FontAwesome
                prefix="fas"
                name="star"
                className={styles.star}
              /> Favourites</button>
            <button
              className={styles.white}
            >
              ALL
            </button>
          </div>
          <div className={styles.scrollbar}>
            <div className={styles.recently}>
              <div className={styles.title}>Recently used</div>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/candlestick.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    availableTF: ['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w', '1M'],
                    type: "chart",
                    name: "USDT-BTC",
                    marketId: "BTCUSDT",
                    exchange: "binance",
                    w: 2,
                    h: 2,
                    indicators: [],
                    data: [],
                    basePeriod: {from: {value: "day", min: 1440}, to: {value: "day", min: 1440}}
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Price Chart
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Visualize financial data graphically.</span>
                </div>
              </button>
            </div>
            <div className={styles.other}>
              <div className={styles.title}>Trading & Charts</div>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/candlestick.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    availableTF: ['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w', '1M'],
                    type: "chart",
                    name: "USDT-BTC",
                    marketId: "BTCUSDT",
                    exchange: "binance",
                    w: 2,
                    h: 2,
                    indicators: [],
                    data: [],
                    basePeriod: {from: {value: "day", min: 1440}, to: {value: "day", min: 1440}}
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Price Chart
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Visualize financial data graphically.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/BTC.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "markets",
                    exchange: "binance",
                    w: 2,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Exchange List
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Shows all CEX / DEX exchanges.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/orderbook.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "dom",
                    name: "USDT-BTC",
                    exchange: "binance",
                    w: 2,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Orderbook
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Shows buy and sell orders by price.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/domchart.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "domChart",
                    name: "USDT-BTC",
                    exchange: "binance",
                    w: 2,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Orderbook Chart
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Shows all buy and sell orders graphically.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/tradehistory.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "history",
                    name: "USDT-BTC",
                    exchange: "binance",
                    w: 2,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Trade History
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>View all the latest buy and sell orders.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/scanner.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    type: "scanner",
                    name: "Scanner",
                    exchange: "binance",
                    w: 5,
                    h: 5,
                    minW: 5
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Market Scanner
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Quickly find trading setups.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/trade.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "trade",
                    unpinned: true,
                    fX: document.body.clientWidth / 2 - 230,
                    fY: document.body.offsetHeight / 2 - 400,
                    fH: 580,
                    fW: 450,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Trading Panel
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>A multifunctional pit-stop for trading.</span>
                </div>
              </button>


              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/balances.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "balancesummary",
                    w: 2,
                    h: 3,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Balance Summary
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>View all your account trading balances.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/positions.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "openpositions",
                    w: 10,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Open Positions
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>View your current trading positions.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/orders.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "workingorders",
                    w: 10,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Working Orders
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>View your current working orders.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/history.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "ordershistory",
                    w: 10,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Orders History
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>View your trading orders history.</span>
                </div>
              </button>
            </div>
            <div className={styles.other}>
              <div className={styles.title}>DeFi</div>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/scanner.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "defiscanner",
                    w: 8,
                    h: 4,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Market Screener
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Quickly find trading setups.</span>
                </div>
              </button>

            </div>
            <div className={styles.other}>
              <div className={styles.title}>Heatmaps</div>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/heatmap.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "treemap",
                    name: "USDT-BTC",
                    exchange: "binance",
                    w: 2,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Exchange Heatmap
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Visual representation of price data using colors.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/bubbles.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "bubble",
                    name: "USDT-BTC",
                    exchange: "binance",
                    w: 4,
                    h: 4,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Bubble Heatmap
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Visual representation of price data using colors.</span>
                </div>
              </button>
            </div>

            <div className={styles.other}>
              <div className={styles.title}>Analytics & Data</div>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/priceticker.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "price",
                    name: "USDT-BTC",
                    exchange: "binance",
                    maxH: 2,
                    maxW: 1,
                    w: 1,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Price Ticker
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Displays the latest price data.</span>
                </div>
              </button>
              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/rss.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "feed",
                    w: 2,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  News Feeds
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>View data feeds from various news sources.</span>
                </div>
              </button>


              <button
                className={styles.card}
                style={{ backgroundImage: `url(${`/img/icons/widgets/user.svg`})`, backgroundSize: "90px", backgroundRepeat: "no-repeat", backgroundPosition: 'right' }}
                onClick={() => {
                  addWidget({
                    ...widget,
                    type: "sentiment",
                    w: 1,
                    h: 2,
                  });
                  toggleModal("addNewPanel");
                }}
              >
                <div className={styles.cardTitle}>
                  Overall Sentiment
                  <span className={styles.cardStar}>
                    <FontAwesome
                      prefix="far"
                      name="star"
                    /></span>
                </div>
                <div className={styles.cardType}>
                  <span className={styles.cardInfo}>Discover how people feel about a ticker.</span>
                </div>
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

addNewPanelModal.propTypes = {
  addWidget: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  widget: PropTypes.object,
  updateWidget: PropTypes.func.isRequired,
};

addNewPanelModal.defaultProps = {
  addWidget: () => false,
  isOpen: false,
  toggleModal: () => false,
  widget: undefined,
};

export default addNewPanelModal;
