// User actions constants
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const TMP_SECRET = "TMP_SECRET";
export const TMP_SECRET_SUCCESS = "TMP_SECRET_SUCCESS";
export const TMP_SECRET_ERROR = "TMP_SECRET_ERROR";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_ERROR = "CHECK_TOKEN_ERROR";
export const SETTINGS_UPDATED = "SETTINGS_UPDATED";
export const UPDATE_DRAWING = "UPDATE_DRAWING";
export const UPDATE_INDICATOR_TEMPLATE = "UPDATE_INDICATOR_TEMPLATE";

// Accounts actions constants
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_ERROR = "GET_ACCOUNTS_ERROR";
export const GET_ACCOUNT = "GET_ACCOUNTS";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_ERROR = "ADD_ACCOUNT_ERROR";
export const SAVE_ACCOUNT = "SAVE_ACCOUNT";
export const SAVE_ACCOUNT_SUCCESS = "SAVE_ACCOUNT_SUCCESS";
export const SAVE_ACCOUNT_ERROR = "SAVE_ACCOUNT_ERROR";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";

// Balance actions constants
export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";
export const GET_BALANCE_ERROR = "GET_BALANCE_ERROR";
export const GET_ACCOUNT_BALANCE = "GET_ACCOUNT_BALANCE";
export const GET_ACCOUNT__BALANCE_SUCCESS = "GET_ACCOUNT__BALANCE_SUCCESS";
export const GET_ACCOUNT__BALANCE_ERROR = "GET_ACCOUNT__BALANCE_ERROR";

// Performance actions constants
export const GET_BALANCE_HISTORY = "GET_BALANCE_HISTORY";
export const GET_BALANCE_HISTORY_SUCCESS = "getBalanceRes";
export const GET_BALANCE_HISTORY_ERROR = "GET_BALANCE_HISTORY_ERROR";

// Orders actions constants
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "getActiveOrdersRes";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";
export const SET_ORDER = "SET_ORDER";
export const ADD_ORDER = "ADD_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_ERROR = "CANCEL_ORDER_ERROR";
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const GET_ORDER_HISTORY_SUCCESS = "getOrdersHistoryRes";
export const GET_ORDER_HISTORY_ERROR = "GET_ORDER_HISTORY_ERROR";
export const UPDATE_TEMP_ORDER = "UPDATE_TEMP_ORDER";
export const CLEAR_TEMP_ORDERS = "CLEAR_TEMP_ORDERS";
export const ADD_TEMP_ORDER = "ADD_TEMP_ORDER";
export const REMOVE_TEMP_ORDER = "REMOVE_TEMP_ORDER";

// Image actions constants
export const GET_IMAGE_SUCCESS = "GET_IMAGE_SUCCESS";
export const GET_IMAGE = "GET_IMAGE";

// Feed actions constants
export const GET_FEED = "getFeedRes";
export const GET_NEW_FEED = "getNewFeedRes";
export const UPDATE_FILTERS = "UPDATE_FILTERS";

// Commont actions constants
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const ALERT_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
};
