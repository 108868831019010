/* eslint max-len: 0 */
import {uid} from "uid";
import { createOrder, cancelOrder, renewOrder } from "../api/OrdersApi";
import { updateRedo } from "../api/AccountsApi";
import { showAlert } from "../actions/CommonActions";
import { fetchAccounts } from "../actions/AccountsActions";

import { defaultTempOrder } from "../constants/OrdersConstants";

import {
  GET_ORDERS,
  GET_ORDER_HISTORY,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  ALERT_TYPES,
  UPDATE_TEMP_ORDER,
  CLEAR_TEMP_ORDERS,
  ADD_TEMP_ORDER,
  REMOVE_TEMP_ORDER,
} from "../const";

function toTitleCase(str) {
  return str.replace(
      /([^\W_]+[^\s-]*) */g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

const fetchOrdersList = () => async (dispatch, getState, { emit }) => {
  const state = getState();

  if (state.orders.orders.loading || state.image.loading) {
    return false;
  }

  if (state.orders.orders.length === 0) {
    dispatch({
      type: GET_ORDERS,
    });
  }

  emit("getActiveOrders");
};

const fetchOrderHistory = () => async (dispatch, getState, { emit }) => {
  const state = getState();

  if (state.orders.orderHistory.loading || state.image.loading) {
    return false;
  }

  if (state.orders.orderHistory.length === 0) {
    dispatch({
      type: GET_ORDER_HISTORY,
    });
  }

  emit("getOrdersHistory");
};

const createOrderAction = (market, accountName, data) => async (dispatch) => {
  dispatch({
    type: CREATE_ORDER,
  });
  const splittedSymbol = market.split("-");
  const dataObject = {
    symbol: `${splittedSymbol[1]}/${splittedSymbol[0]}`,
    type: toTitleCase(data.orderType),
    side: data.actionType.toLowerCase(),
    amount: parseFloat(data.amountToBuy),
    price: parseFloat(data.limitPrice),
  };

  try {
    const result = await createOrder(dataObject, accountName);

    dispatch({
      type: CREATE_ORDER_SUCCESS,
      order: result.data,
    });

    dispatch(showAlert(ALERT_TYPES.SUCCESS, {
      title: `${dataObject.type} Order Created`,
      message: `Submitted exchange ${dataObject.type} Order for ${dataObject.amount} ${splittedSymbol[0]} by using ${splittedSymbol[1]}`,
      icon: `/img/symbols/${splittedSymbol[0].toUpperCase()}.png`,
      type: "order",
    }));
  } catch (error) {
    dispatch({
      type: CREATE_ORDER_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const cancelOrderAction = (account, order) => async (dispatch) => {
  dispatch({
    type: CANCEL_ORDER,
  });

  try {
    const result = await cancelOrder(account, order.id, order.symbol);

    dispatch({
      type: CANCEL_ORDER_SUCCESS,
      order: result.data,
    });
    dispatch(showAlert(ALERT_TYPES.SUCCESS, {
      title: `${order.side.toUpperCase()} Order Cancelled`,
      message: `Order for ${order.amount} ${order.symbol} cancelled`,
      icon: `/img/symbols/${order.symbol.toUpperCase()}.png`,
      type: "order",
    }));
  } catch (error) {
    dispatch({
      type: CANCEL_ORDER_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const renewOrderAction = (account, order = null) => async (dispatch) => {
  try {
    await renewOrder(account, order ? order.id : null, true);
    dispatch(showAlert(ALERT_TYPES.SUCCESS, {
      title: `${order.side.toUpperCase()} Order Renewed`,
      message: `Order for ${order.amount} ${order.symbol} renewed`,
      icon: `/img/symbols/${order.symbol.toUpperCase()}.png`,
      type: "order",
    }));
    dispatch(fetchOrdersList());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const setRedoAction = (name, isredo, redoday) => async (dispatch) => {
  try {
    await updateRedo(name, isredo, redoday);
    dispatch(showAlert(ALERT_TYPES.SUCCESS, "Order renewal updated."));
    dispatch(fetchAccounts());
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const clearTempOrders = () => (dispatch) => {
  dispatch({
    type: CLEAR_TEMP_ORDERS,
  });
};

const addTempOrder = (order) => (dispatch) => {
  dispatch({
    type: ADD_TEMP_ORDER,
    order: {
      ...defaultTempOrder,
      ...order,
      id: uid(10),
      bgFill: order.type === "BUY" ? "#535353" : "#535353",
      text: `PENDING ${order.type} [LIMIT] ${order.amount}`,
      edge: {
        ...defaultTempOrder.edge,
        fill: order.type === "BUY" ? "#535353" : "#535353",
      },
    },
  });
};

const updateTempOrder = (order) => (dispatch) => {
  dispatch({
    type: UPDATE_TEMP_ORDER,
    order: {
      ...order,
      bgFill: order.type === "BUY" ? "#535353" : "#535353",
      text: `PENDING ${order.type} [LIMIT] ${order.amount}`,
      edge: {
        ...defaultTempOrder.edge,
        fill: order.type === "BUY" ? "#535353" : "#535353",
      },
    },
  });
};

const removeTempOrder = (orderId) => (dispatch) => {
  dispatch({
    type: REMOVE_TEMP_ORDER,
    orderId: orderId,
  });
};

export {
  fetchOrdersList,
  fetchOrderHistory,
  cancelOrderAction,
  createOrderAction,
  renewOrderAction,
  setRedoAction,
  clearTempOrders,
  updateTempOrder,
  addTempOrder,
  removeTempOrder,
};
