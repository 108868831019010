// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fpH_4XP5ky2NFvWo5a9Mq{display:inline-block;outline:none}", "",{"version":3,"sources":["webpack://src/components/FontAwesome.scss"],"names":[],"mappings":"AACE,uBACE,oBAAA,CACA,YAAA","sourcesContent":[":local {\n  .IconWrapper {\n    display: inline-block;\n    outline: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconWrapper": "fpH_4XP5ky2NFvWo5a9Mq"
};
export default ___CSS_LOADER_EXPORT___;
