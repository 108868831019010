const lineThicknessAry = [
  {
    name: '1px',
    value: 1,
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 6" width="28" height="28"><path fill="currentColor" d="M1.5 2a.5.5 0 0 0 0 1h19a.5.5 0 0 0 0-1h-19z"/></svg>'
  },
  {
    name: '2px',
    value: 2,
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 6" width="28" height="28"><path fill="currentColor" d="M2 2a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2H2z"/></svg>'
  },
  {
    name: '3px',
    value: 3,
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 6" width="28" height="28"><path fill="currentColor" d="M2 1a1.5 1.5 0 0 0 0 3h18a1.5 1.5 0 0 0 0-3H2z"/></svg>'
  },
  {
    name: '4px',
    value: 4,
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 6" width="28" height="28"><path fill="currentColor" d="M2 1a2 2 0 0 0 0 4h18a2 2 0 0 0 0-4H2z"/></svg>'
  }
];
const lineStrokeStyleAry = [
  {
    name: 'Line',
    value: 'line',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><path stroke="currentColor" d="M4 13.5h20"/></svg>'
  },
  {
    name: 'Dash',
    value: 'dash',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><path fill="currentColor" d="M4 13h5v1H4v-1zM12 13h5v1h-5v-1zM20 13h5v1h-5v-1z"/></svg>'
  },
  {
    name: 'Dot',
    value: 'dot',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"><circle cx="9" cy="14" r="1"/><circle cx="4" cy="14" r="1"/><circle cx="14" cy="14" r="1"/><circle cx="19" cy="14" r="1"/><circle cx="24" cy="14" r="1"/></svg>'
  }
];
const lineDefaultOptions = {
  lineColor: { label: "Color", type: "color", hex: "#ffffff", rgba: { r: 255, g: 255, b: 255, a: 1 }, col: 4 },
  lineThickness: { label: "Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry, col: 4 },
  lineStyle: { label: "Style", type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
};

const lineExtend = { label: "Extend Line", type: "switch", values: [false, false] };
const fillColor = { label: "Fill Color", type: "color", hex: "#0000ff", rgba: { r: 0, g: 0, b: 255, a: 0.5 } };

const trendline = {
  inputs: {
    ...lineDefaultOptions,
  },
  plots: {
    lineExtend,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const ellipse = {
  inputs: {},
  plots: {
    lineColor: { label: "Color", type: "color", hex: "#999734", rgba: { r: 153, g: 152, b: 52, a: 1 } },
    lineThickness: { label: "Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Style", type: "select", value: 'line', index: 0, values: lineStrokeStyleAry },
    fillColor: { label: "Fill Color", type: "color", hex: "#999734", rgba: { r: 153, g: 152, b: 52, a: 0.5 } }
  },
};
const trendangle = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
    lineExtend,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const arrowline = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
    lineExtend,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const ray = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
    lineExtend: { label: "Extend Line", type: "switch", values: [false, true] }
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const extended = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
    lineExtend: { label: "Extend Line", type: "switch", values: [true, true] }
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const parallelchannel = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
    lineExtend,
    fillColor: { label: "Fill Color", type: "color", hex: "#ff0000", rgba: { r: 178, g: 168, b: 211, a: 0.5 } }
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};
const horizontalline = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    }
  }
};
const horizontalray = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    }
  }
};
const verticalline = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    }
  }
};
const rectangle = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
    fillColor,
    lineExtend,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const triangle = {
  inputs: {},
  plots: {
    ...lineDefaultOptions,
    fillColor: { label: "Fill Color", type: "color", hex: "#ff0000", rgba: { r: 255, g: 0, b: 0, a: 0.5 } }
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};

const bgRange = { label: "Background", type: "range", value: 20, min: 0, max: 100 };
const pitchforklevels = [
  {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 0.25, col: 2 },
    fillColor: { type: "color", hex: "#986d22", rgba: { r: 152, g: 109, b: 34, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 0.382, col: 2 },
    fillColor: { type: "color", hex: "#759c2d", rgba: { r: 117, g: 156, b: 45, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.5, col: 2 },
    fillColor: { type: "color", hex: "#44982a", rgba: { r: 68, g: 152, b: 42, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 0.618, col: 2 },
    fillColor: { type: "color", hex: "#439669", rgba: { r: 67, g: 150, b: 105, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 0.75, col: 2 },
    fillColor: { type: "color", hex: "#296495", rgba: { r: 41, g: 100, b: 149, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1, col: 2 },
    fillColor: { type: "color", hex: "#000b93", rgba: { r: 0, g: 11, b: 147, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 1.5, col: 2 },
    fillColor: { type: "color", hex: "#5d1693", rgba: { r: 93, g: 22, b: 147, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 1.75, col: 2 },
    fillColor: { type: "color", hex: "#8c1e63", rgba: { r: 140, g: 30, b: 99, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  },
  {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 2, col: 2 },
    fillColor: { type: "color", hex: "#971f11", rgba: { r: 151, g: 31, b: 17, a: 1 }, col: 3 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 2 }
  }
];

const pitchforkSetting = {
  plots: {
    lineColor: { label: "Mid Color", type: "color", hex: "#ffffff", rgba: { r: 255, g: 255, b: 255, a: 1 } },
    lineThickness: { label: "Mid Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Mid Style", type: "select", value: 'line', index: 0, values: lineStrokeStyleAry },
    bgRange,
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 2 },
            { type: "label", value: "Fill Color", col: 3 },
            { type: "label", value: "Style", col: 2 },
          ]
        ],
        buttons: [
          [
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 3 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 3 }
          ]
        ],
        levels: [
          pitchforklevels
        ]
      }
    }
  },
};
const pitchfork = {
  inputs: {
    ...pitchforkSetting
  },
  plots: {
    ...pitchforkSetting
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};
const schiffpitchfork = {
  inputs: {},
  plots: {
    ...pitchforkSetting
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};
const modifiedschiffpitchfork = {
  inputs: {},
  plots: {
    ...pitchforkSetting
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};
const insidepitchfork = {
  inputs: {},
  plots: {
    ...pitchforkSetting
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};
const pitchfan = {
  inputs: {},
  plots: {
    ...pitchforkSetting
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};

const gannboxPriceSettings = [
  {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 }
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.25, col: 4 },
    fillColor: { type: "color", hex: "#986d22", rgba: { r: 152, g: 109, b: 34, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.382, col: 4 },
    fillColor: { type: "color", hex: "#759c2d", rgba: { r: 117, g: 156, b: 45, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.5, col: 4 },
    fillColor: { type: "color", hex: "#44982a", rgba: { r: 68, g: 152, b: 42, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.618, col: 4 },
    fillColor: { type: "color", hex: "#439669", rgba: { r: 67, g: 150, b: 105, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.75, col: 4 },
    fillColor: { type: "color", hex: "#296495", rgba: { r: 41, g: 100, b: 149, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 1, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 },
  },
];
const gannboxTimeSettings = [
  {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.25, col: 4 },
    fillColor: { type: "color", hex: "#986d22", rgba: { r: 152, g: 109, b: 34, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.382, col: 4 },
    fillColor: { type: "color", hex: "#759c2d", rgba: { r: 117, g: 156, b: 45, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.5, col: 4 },
    fillColor: { type: "color", hex: "#44982a", rgba: { r: 68, g: 152, b: 42, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.618, col: 4 },
    fillColor: { type: "color", hex: "#439669", rgba: { r: 67, g: 150, b: 105, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.75, col: 4 },
    fillColor: { type: "color", hex: "#296495", rgba: { r: 41, g: 100, b: 149, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 1, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 },
  },
];
const gannbox = {
  plots: {},
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 2,
        title: [
          {
            type: "label",
            value: "Price Levels",
            col: 6
          },
          {
            type: "label",
            value: "Time Levels",
            col: 6
          }
        ],
        heads: [
          [
            { type: "checkbox", checked: true, col: 2 },
            { type: "label", value: "Value", col: 4 },
            { type: "label", value: "Fill Color", col: 6 },
          ],
          [
            { type: "checkbox", checked: true, col: 2 },
            { type: "label", value: "Value", col: 4 },
            { type: "label", value: "Fill Color", col: 6 },
          ]
        ],
        buttons: [
          [
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 6 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 6 }
          ],
          [
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 6 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 6 }
          ]
        ],
        bgRange: [
          { label: "Background", type: "range", value: 20, min: 0, max: 100, col: 6 },
          { label: "Background", type: "range", value: 20, min: 0, max: 100, col: 6 },
        ],
        levels: [
          gannboxPriceSettings,
          gannboxTimeSettings
        ]
      }
    }
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const gannfanSettings = [
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "1/8", col: 1 },
    fillColor: { type: "color", hex: "#986d22", rgba: { r: 152, g: 109, b: 34, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "1/4", col: 1 },
    fillColor: { type: "color", hex: "#759c2d", rgba: { r: 117, g: 156, b: 45, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "1/3", col: 1 },
    fillColor: { type: "color", hex: "#44982a", rgba: { r: 68, g: 152, b: 42, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "1/2", col: 1 },
    fillColor: { type: "color", hex: "#439669", rgba: { r: 67, g: 150, b: 105, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "1/1", col: 1 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "2/1", col: 1 },
    fillColor: { type: "color", hex: "#296495", rgba: { r: 41, g: 100, b: 149, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "3/1", col: 1 },
    fillColor: { type: "color", hex: "#000b93", rgba: { r: 0, g: 11, b: 147, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "4/1", col: 1 },
    fillColor: { type: "color", hex: "#5d1693", rgba: { r: 93, g: 22, b: 147, a: 1 }, col: 3 },
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "label", value: "8/1", col: 1 },
    fillColor: { type: "color", hex: "#971f11", rgba: { r: 151, g: 31, b: 17, a: 1 }, col: 3 },
  }
];
const gannfan = {
  plots: {},
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 2 },
            { type: "label", value: "Fill Color", col: 3 },
          ]
        ],
        buttons: [
          [
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 3 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 3 }
          ]
        ],
        bgRange: [
          { label: "Background", type: "range", value: 20, min: 0, max: 100, col: 3 }
        ],
        levels: [
          gannfanSettings,
        ]
      }
    }
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};

const fibretracementSettings = [
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0, col: 3 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.236, col: 3 },
    fillColor: { type: "color", hex: "#bc3a30", rgba: { r: 188, g: 58, b: 48, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.382, col: 3 },
    fillColor: { type: "color", hex: "#a1c949", rgba: { r: 161, g: 201, b: 73, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.5, col: 3 },
    fillColor: { type: "color", hex: "#63c847", rgba: { r: 99, g: 200, b: 71, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.618, col: 3 },
    fillColor: { type: "color", hex: "#63c899", rgba: { r: 99, g: 200, b: 153, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.786, col: 3 },
    fillColor: { type: "color", hex: "#4b93c7", rgba: { r: 75, g: 147, b: 199, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1, col: 3 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1.618, col: 3 },
    fillColor: { type: "color", hex: "#272ec4", rgba: { r: 39, g: 46, b: 196, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 2.618, col: 3 },
    fillColor: { type: "color", hex: "#bc3a30", rgba: { r: 188, g: 58, b: 48, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 3.618, col: 3 },
    fillColor: { type: "color", hex: "#8936c5", rgba: { r: 137, g: 54, b: 197, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 4.236, col: 3 },
    fillColor: { type: "color", hex: "#bc3c91", rgba: { r: 188, g: 60, b: 145, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }
];

const fibretracement = {
  plots: {
    lineColor: { label: "Trend Color", type: "color", hex: "#ffffff", rgba: { r: 255, g: 255, b: 255, a: 1 } },
    lineThickness: { label: "Trend Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Trend Style", type: "select", value: 'dot', index: 2, values: lineStrokeStyleAry },
    lineExtend,
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 3 },
            { type: "label", value: "Fill Color", col: 4 },
            { type: "label", value: "Style", col: 4 },
          ]
        ],
        buttons: [
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 4 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 4 }
          ]
        ],
        bgRange: [
          { label: "Background", type: "range", value: 20, min: 0, max: 100, col: 8 }
        ],
        levels: [
          fibretracementSettings,
        ]
      }
    },
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const trendbasedfibextension = {
  inputs: {},
  plots: {
    ...fibretracement
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};

const fibspeedresistancefanPriceSettings = [
  {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 }
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.25, col: 4 },
    fillColor: { type: "color", hex: "#986d22", rgba: { r: 152, g: 109, b: 34, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.382, col: 4 },
    fillColor: { type: "color", hex: "#759c2d", rgba: { r: 117, g: 156, b: 45, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.5, col: 4 },
    fillColor: { type: "color", hex: "#44982a", rgba: { r: 68, g: 152, b: 42, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.618, col: 4 },
    fillColor: { type: "color", hex: "#439669", rgba: { r: 67, g: 150, b: 105, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.75, col: 4 },
    fillColor: { type: "color", hex: "#296495", rgba: { r: 41, g: 100, b: 149, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 1, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 },
  },
];
const fibspeedresistancefanTimeSettings = [
  {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.25, col: 4 },
    fillColor: { type: "color", hex: "#986d22", rgba: { r: 152, g: 109, b: 34, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.382, col: 4 },
    fillColor: { type: "color", hex: "#759c2d", rgba: { r: 117, g: 156, b: 45, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.5, col: 4 },
    fillColor: { type: "color", hex: "#44982a", rgba: { r: 68, g: 152, b: 42, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.618, col: 4 },
    fillColor: { type: "color", hex: "#439669", rgba: { r: 67, g: 150, b: 105, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 0.75, col: 4 },
    fillColor: { type: "color", hex: "#296495", rgba: { r: 41, g: 100, b: 149, a: 1 }, col: 6 },
  }, {
    active: { type: "checkbox", checked: true, col: 2 },
    value: { type: "number", value: 1, col: 4 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 6 },
  },
];

const fibspeedresistancefan = {
  plots: {
    lineColor: { label: "Color", type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 } },
    lineThickness: { label: "Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Style", type: "select", value: 'line', index: 0, values: lineStrokeStyleAry },
    bgRange,
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 2,
        title: [
          {
            type: "label",
            value: "Price Levels",
            col: 6
          },
          {
            type: "label",
            value: "Time Levels",
            col: 6
          }
        ],
        heads: [
          [
            { type: "checkbox", checked: true, col: 2 },
            { type: "label", value: "Value", col: 4 },
            { type: "label", value: "Fill Color", col: 6 },
          ],
          [
            { type: "checkbox", checked: true, col: 2 },
            { type: "label", value: "Value", col: 4 },
            { type: "label", value: "Fill Color", col: 6 },
          ]
        ],
        buttons: [
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 6 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 6 }
          ],
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 6 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 6 }
          ]
        ],
        levels: [
          fibspeedresistancefanPriceSettings,
          fibspeedresistancefanTimeSettings
        ]
      }
    }
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};

const fibtimezoneSettings = [
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0, col: 3 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 2, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 3, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 5, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 8, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 13, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 21, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 34, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 55, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 89, col: 3 },
    fillColor: { type: "color", hex: "#2156d3", rgba: { r: 33, g: 86, b: 211, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }
];
const fibtimezone = {
  plots: {
    bgRange: { label: "Background", type: "range", value: 0, min: 0, max: 100 },
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 3 },
            { type: "label", value: "Fill Color", col: 4 },
            { type: "label", value: "Style", col: 4 },
          ]
        ],
        buttons: [
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 3 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 3 }
          ]
        ],
        levels: [
          fibtimezoneSettings
        ]
      }
    }
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};

const trendbasedfibtimezoneSettings = [
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0, col: 3 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.382, col: 3 },
    fillColor: { type: "color", hex: "#bc3a30", rgba: { r: 188, g: 58, b: 48, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: false, col: 1 },
    value: { type: "number", value: 0.5, col: 3 },
    fillColor: { type: "color", hex: "#a1c949", rgba: { r: 161, g: 201, b: 73, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.618, col: 3 },
    fillColor: { type: "color", hex: "#63c847", rgba: { r: 99, g: 200, b: 71, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1, col: 3 },
    fillColor: { type: "color", hex: "#63c899", rgba: { r: 99, g: 200, b: 153, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1.382, col: 3 },
    fillColor: { type: "color", hex: "#4b93c7", rgba: { r: 75, g: 147, b: 199, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1.618, col: 3 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 2, col: 3 },
    fillColor: { type: "color", hex: "#272ec4", rgba: { r: 39, g: 46, b: 196, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 2.382, col: 3 },
    fillColor: { type: "color", hex: "#303abc", rgba: { r: 48, g: 58, b: 188, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 2.618, col: 3 },
    fillColor: { type: "color", hex: "#8936c5", rgba: { r: 137, g: 54, b: 197, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 3, col: 3 },
    fillColor: { type: "color", hex: "#bc3c91", rgba: { r: 188, g: 60, b: 145, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }
];

const trendbasedfibtime = {
  plots: {
    lineColor: { label: "Color", type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 } },
    lineThickness: { label: "Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Style", type: "select", value: 'dot', index: 2, values: lineStrokeStyleAry },
    bgRange: { label: "Background", type: "range", value: 20, min: 0, max: 100 },
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 3 },
            { type: "label", value: "Fill Color", col: 4 },
            { type: "label", value: "Style", col: 4 },
          ]
        ],
        buttons: [
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 3 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 3 }
          ]
        ],
        levels: [
          trendbasedfibtimezoneSettings
        ]
      }
    }
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};

const fibCirclesSettings = [
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.236, col: 3 },
    fillColor: { type: "color", hex: "#bc3a30", rgba: { r: 188, g: 58, b: 48, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.382, col: 3 },
    fillColor: { type: "color", hex: "#a1c949", rgba: { r: 161, g: 201, b: 73, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.5, col: 3 },
    fillColor: { type: "color", hex: "#63c847", rgba: { r: 99, g: 200, b: 71, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.618, col: 3 },
    fillColor: { type: "color", hex: "#63c899", rgba: { r: 99, g: 200, b: 153, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.786, col: 3 },
    fillColor: { type: "color", hex: "#4b93c7", rgba: { r: 75, g: 147, b: 199, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1, col: 3 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1.618, col: 3 },
    fillColor: { type: "color", hex: "#272ec4", rgba: { r: 39, g: 46, b: 196, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 2.618, col: 3 },
    fillColor: { type: "color", hex: "#bc3a30", rgba: { r: 188, g: 58, b: 48, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 3.618, col: 3 },
    fillColor: { type: "color", hex: "#8936c5", rgba: { r: 137, g: 54, b: 197, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 4.236, col: 3 },
    fillColor: { type: "color", hex: "#bc3c91", rgba: { r: 188, g: 60, b: 145, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 4.618, col: 3 },
    fillColor: { type: "color", hex: "#bc3c91", rgba: { r: 188, g: 60, b: 145, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }
];

const fibcycles = {
  plots: {
    lineColor: { label: "Color", type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 } },
    lineThickness: { label: "Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Style", type: "select", value: 'dot', index: 2, values: lineStrokeStyleAry },
    bgRange: { label: "Background", type: "range", value: 20, min: 0, max: 100 },
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 3 },
            { type: "label", value: "Fill Color", col: 4 },
            { type: "label", value: "Style", col: 4 },
          ]
        ],
        buttons: [
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 3 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 3 }
          ]
        ],
        levels: [
          fibCirclesSettings
        ]
      }
    }
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const fibspeedresistancearcs = {
  inputs: {},
  plots: {
    ...fibcycles,
  },
  coordinates: {
    startX: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    startY: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    endX: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    endY: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    }
  }
};
const fibwedgeSettings = [
  {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.236, col: 3 },
    fillColor: { type: "color", hex: "#bc3a30", rgba: { r: 188, g: 58, b: 48, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.382, col: 3 },
    fillColor: { type: "color", hex: "#a1c949", rgba: { r: 161, g: 201, b: 73, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.5, col: 3 },
    fillColor: { type: "color", hex: "#63c847", rgba: { r: 99, g: 200, b: 71, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.618, col: 3 },
    fillColor: { type: "color", hex: "#63c899", rgba: { r: 99, g: 200, b: 153, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 0.786, col: 3 },
    fillColor: { type: "color", hex: "#4b93c7", rgba: { r: 75, g: 147, b: 199, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }, {
    active: { type: "checkbox", checked: true, col: 1 },
    value: { type: "number", value: 1, col: 3 },
    fillColor: { type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 }, col: 4 },
    style: { type: "select", value: 'line', index: 0, values: lineStrokeStyleAry, col: 4 }
  }
];
const fibwedge = {
  plots: {
    lineColor: { label: "Color", type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 } },
    lineThickness: { label: "Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Style", type: "select", value: 'line', index: 0, values: lineStrokeStyleAry },
    bgRange: { label: "Background", type: "range", value: 20, min: 0, max: 100 },
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 3 },
            { type: "label", value: "Fill Color", col: 4 },
            { type: "label", value: "Style", col: 4 },
          ]
        ],
        buttons: [
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 3 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 3 }
          ]
        ],
        levels: [
          fibwedgeSettings
        ]
      }
    }
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};

const fibchannel = {
  plots: {
    lineThickness: { label: "Trend Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
    lineStyle: { label: "Trend Style", type: "select", value: 'line', index: 0, values: lineStrokeStyleAry },
    lineExtend,
  },
  inputs: {
    levels: {
      type: 'level',
      levelUI: {
        column: 1,
        heads: [
          [
            { type: "checkbox", checked: true, col: 1 },
            { type: "label", value: "Value", col: 3 },
            { type: "label", value: "Fill Color", col: 4 },
            { type: "label", value: "Style", col: 4 },
          ]
        ],
        buttons: [
          [                                                                                         // buttons for colume0
            { label: "Add Level", role: "add", type: "button", text: 'Add', col: 3 },
            { label: "Remove Checked Level", role: "remove", type: "button", text: 'Remove', col: 3 }
          ]
        ],
        bgRange: [
          { label: "Background", type: "range", value: 20, min: 0, max: 100, col: 6 }
        ],
        levels: [
          fibretracementSettings,
        ]
      }
    }
  },
  coordinates: {
    t1: {
      type: "dateTime",
      label: "Point 1 Date",
      originValue: "ts1"
    },
    price1: {
      type: "number",
      label: "Point 1 Price",
      originValue: "price1"
    },
    t2: {
      type: "dateTime",
      label: "Point 2 Date",
      originValue: "ts2"
    },
    price2: {
      type: "number",
      label: "Point 2 Price",
      originValue: "price2"
    },
    t3: {
      type: "dateTime",
      label: "Point 3 Date",
      originValue: "ts3"
    },
    price3: {
      type: "number",
      label: "Point 3 Price",
      originValue: "price3"
    },
  }
};
const text = {
  inputs: {},
  plots: {
    fontColor: { label: "Text Color", type: "color", hex: "#808080", rgba: { r: 128, g: 128, b: 128, a: 1 } },
    fontSize: { label: "Text Size", type: "number", value: 20, col: 2 },
    fontBold: { label: "Bold", text: 'B', role: "toggle-bold", type: "toggle-button", checked: false, col: 1 },
    fontItalic: { label: "Italic", text: 'I', role: "toggle-italic", type: "toggle-button", checked: false, col: 1 },
    textField: { type: "textfield", text: 'Text', col: 12 },
  }
};
const brush = {
  inputs: {},
  plots: {
    lineColor: { label: "Color", type: "color", hex: "#ffffff", rgba: { r: 255, g: 255, b: 255, a: 1 } },
    lineThickness: { label: "Thickness", type: "select", value: 2, index: 1, values: lineThicknessAry },
  }
};
const lineSettings = {
  brush,
  ellipse,
  trendline,
  trendangle,
  arrowline,
  ray,
  extended,
  parallelchannel,
  horizontalline,
  horizontalray,
  verticalline,
  triangle,
  rectangle,
  pitchfork,
  schiffpitchfork,
  modifiedschiffpitchfork,
  insidepitchfork,
  pitchfan,
  gannbox,
  gannfan,
  fibretracement,
  trendbasedfibextension,
  fibspeedresistancefan,
  fibtimezone,
  trendbasedfibtime,
  fibcycles,
  fibspeedresistancearcs,
  fibwedge,
  fibchannel,
  text
};
export {
  lineSettings
};
