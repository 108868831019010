/* eslint-disable */
// let HIGH = [];
// let LOW = [];
// let OPEN = [];
// let CLOSE = [];
// Copia un pedazo de un array
// @param array
// @param int posicion de inicio
// @param int posicion de finalizacion
// @return array
export function copy(data, ini, end) {
  var arr = [];
  for (var i = ini; i < end; i++)
      arr.push(data[i]);

  return arr;
}

// @param array de objetos
// @param string property
// @return array 
export function dim(array, prop) {
  var res = [];

  for (var i = 0; i < array.length; i++)
      res.push(array[i][prop]);

  return res;
}

// uso:
// testTimming(function(){var x= 2/2020; });
export function testTimming(f) {
  var t0 = performance.now();
  f();
  var t1 = performance.now();
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// true if n is not a valid number (n is NaN), otherwise false.
export function na(n) {
  if (Array.isArray(n) && n.length > 0){
    let res = [];
    for (let i = 0; i < n.length; i++){
      let ele = n[i];
      res.push(isNaN(parseFloat(ele)) || !isFinite(ele));
    }
    return res;
  } else {
    return isNaN(parseFloat(n)) || !isFinite(n);
  }
}

// Replaces NaN values with zeros (or given value) in a series.
export function nz(a, b=0){
  if (Array.isArray(a) && a.length > 0){
    var res = [];
    for (var i = 0; i < a.length; i++){
      let ele = a[i];
      if (isNaN(parseFloat(ele)) || !isFinite(ele)){
        res.push(b);
      } else {
        res.push(ele);
      }
    }
  } else if (isNaN(parseFloat(a)) || !isFinite(a)){
    return b;
  } else {
    return a;
  }
}
// @param arreglo
export function avg(arr) {
  var acc = 0;
  for (var i = 0; i < arr.length; i++)
      acc += arr[i];

  return acc / arr.length;
}

// recorta un arreglo eliminando los primeros n-elementos
// @param arreglo
// @param longitud maxima
export function trimmer(arr, lon) {
  var res = [];

  for (var i = arr.length - lon; i < arr.length; i++)
      res.push(arr[i]);

  return res;
}


/*
Basic Math functions
*/

export function pow(serie, power) {
  return serie.map(function(x) {
      return Math.pow(x, power);
  });
}

export function sqrt(serie) {
  return Array.isArray(serie) ? serie.map(Math.sqrt) : Math.sqrt(serie);
}

export function cbrt(serie) {
  return Array.isArray(serie) ? serie.map(Math.cbrt) : Math.cbrt(serie);
}

export function abs(serie) {
  return Array.isArray(serie) ? serie.map(Math.abs) : Math.abs(serie);
}

export function ceil(serie) {
  return Array.isArray(serie) ? serie.map(Math.ceil) : Math.ceil(serie);
}

export function floor(serie) {
  return Array.isArray(serie) ? serie.map(Math.floor) : Math.floor(serie);
}

export function round(serie) {
  return Array.isArray(serie) ? serie.map(Math.round) : Math.round(serie);
}

export function trunc(serie) {
  return Array.isArray(serie) ? serie.map(Math.trunc) : Math.trunc(serie);
}

export function hypot(serie) {
  return Array.isArray(serie) ? serie.map(Math.hypot) : Math.hypot(serie);
}

export function log(serie) {
  return Array.isArray(serie) ? serie.map(Math.log) : Math.log(serie);
}

export function log10(serie) {
  return Array.isArray(serie) ? serie.map(Math.log10) : Math.log10(serie);
}

export function sin(serie) {
  return Array.isArray(serie) ? serie.map(Math.sin) : Math.sin(serie);
}

export function cos(serie) {
  return Array.isArray(serie) ? serie.map(Math.cos) : Math.cos(serie);
}

export function tan(serie) {
  return Array.isArray(serie) ? serie.map(Math.tan) : Math.tan(serie);
}

// tangente inversa en grados
export function tanh(serie) {
  return Array.isArray(serie) ? serie.map(Math.tanh) : Math.tanh(serie);
}

// genera una serie de valores random de 0 a 1
export function random(size) {
  res = [];
  for (var i = 0; i < size; i++)
      res.push(Math.random());

  return res;
}


// JS tiene mas funciones trigonometricas que se pueden incluir

/* 
Indicators as functions
*/

// @param data serie
// @param bars ago
// @return data serie
export function barsAgo(serie = CLOSE, n) {
  var res = [];

  for (var i = 0; i < serie.length - n; i++)
      res.push(serie[i]);

  return res;
}

// Cuenta el numero de barras en el chart
export function count() {
  return CLOSE.length;
}

// A collection of historical bar low prices n-bars ago
// @param bars ago
// @return data serie of low values
export function low(n) {
  return barsAgo(LOW, n);
}

// A collection of historical bar high prices n-bars ago
// @param bars ago
// @return data serie of low values
export function high(n) {
  return barsAgo(HIGH, n);
}

// A collection of historical bar open prices n-bars ago
// @param bars ago
// @return data serie of low values
export function open(n) {
  return barsAgo(OPEN, n);
}

// A collection of historical bar close prices n-bars ago
// @param bars ago
// @return data serie of low values
export function close(n) {
  return barsAgo(CLOSE, n);
}

// A collection of historical bar volume prices n-bars ago
// @param bars ago
// @return data serie of low values
export function volume(n) {
  return barsAgo(VOLUME, n);
}

// @param data serie
// @return data serie
export function yesterday(serie = CLOSE) {
  return barsAgo(serie, 1);
}

// alias de yesterday()
export function prev(serie = CLOSE) {
  return barsAgo(serie, 1);
}

// Exponential Movil Average
// @param data serie de datos de entrada
// @param periodo
// @return data serie de resultado
export function ema(serie = CLOSE, n = 14) {
  var res = [];
  var k = 2 / (n + 1);

  function calc_ema(dato, ema_prev) {
      return ((dato * k) + (ema_prev * (1 - k)));
  }

  // seed
  var calc = avg(copy(serie, 0, n));
  res.push(calc);

  for (var i = n; i < serie.length; i++) {
      calc = calc_ema(serie[i], calc, k);
      res.push(calc);
  }

  return res;
}


// Simple Movil Average
// @param data serie de datos de entrada
// @param periodo
// @return data serie de resultado
export function sma(serie = CLOSE, n = 14) {
  var res = [];
  var acc = 0;

  for (var i = 0; i < n; i++)
      acc += serie[i];

  res.push(acc / n);
  for (var i = n; i < serie.length; i++) {
      acc += serie[i] - serie[i - n];
      res.push(acc / n);
  }

  return res;
}

// Cumulative sum
// realiza la suma acumulada de los valores en el periodo especificado
// @param data serie de datos de entrada
// @param periodo
// @return data serie de resultado
export function cum(serie, n) {
  var res = [];
  var acc = 0;

  for (var i = 0; i < n; i++)
      acc += serie[i];

  res.push(acc);
  for (var i = n; i < serie.length; i++) {
      acc += serie[i] - serie[i - n];
      res.push(acc);
  }

  return res;
}

// Cumulative product (productoria)
export function prod(serie, n) {
  var res = [];
  var acc = 1;

  for (var i = 0; i < n; i++)
      acc *= serie[i];

  res.push(acc);
  for (var i = n; i < serie.length; i++) {
      acc *= serie[i] / serie[i - n];
      res.push(acc);
  }

  return res;
}

export function sumScalar(serie, scalar) {
  var res = [];

  for (var i = 0; i < serie.length; i++)
      res.push(serie[i] + scalar);

  return res;
}

export function diffScalar(serie, scalar) {
  res = [];

  for (var i = 0; i < serie.length; i++)
      res.push(serie[i] - scalar);

  return res;
}

export function mulScalar(serie, scalar) {
  var res = [];

  for (var i = 0; i < serie.length; i++)
      res.push(serie[i] * scalar);

  return res;
}

export function divScalar(serie, scalar) {
  var res = [];

  for (var i = 0; i < serie.length; i++)
      res.push(serie[i] / scalar);

  return res;
}

export function sumSeries(serie1, serie2) {
  var res = [];
  var start, end, len;

  if (serie1.length > serie2.length) {
      dif = serie1.length - serie2.length;
      start = dif;
      end = serie1.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i] + serie2[i - dif]);
      }

  } else {
      dif = serie2.length - serie1.length;
      start = dif;
      end = serie2.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i - dif] + serie2[i]);
      }

  }
  return res;
}

// serie1 - serie2
export function diffSeries(serie1, serie2) {
  var res = [];
  var start, end, len;

  if (serie1.length > serie2.length) {
      dif = serie1.length - serie2.length;
      start = dif;
      end = serie1.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i] - serie2[i - dif]);
      }

  } else {
      dif = serie2.length - serie1.length;
      start = dif;
      end = serie2.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i - dif] - serie2[i]);
      }

  }
  return res;
}

// serie1*serie2 elemento a elemento
export function mulSeries(serie1, serie2) {
  var res = [];
  var start, end, len;

  if (serie1.length > serie2.length) {
      dif = serie1.length - serie2.length;
      start = dif;
      end = serie1.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i] * serie2[i - dif]);
      }

  } else {
      dif = serie2.length - serie1.length;
      start = dif;
      end = serie2.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i - dif] * serie2[i]);
      }

  }
  return res;
}

// serie1/serie2 elemento a elemento
export function divSeries(serie1, serie2) {
  var res = [];
  var start, end, len;

  if (serie1.length > serie2.length) {
      dif = serie1.length - serie2.length;
      start = dif;
      end = serie1.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i] / serie2[i - dif]);
      }

  } else {
      dif = serie2.length - serie1.length;
      start = dif;
      end = serie2.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i - dif] / serie2[i]);
      }

  }
  return res;
}


// a+b
export function sum(a, b) {
  if (Array.isArray(a) && Array.isArray(b))
      return sumSeries(a, b);
  else
  if (Array.isArray(a) && !isNaN(b))
      return sumScalar(a, b);
  else
  if (!isNaN(a) && Array.isArray(b))
      return sumScalar(b, a);
  else
  if (!isNaN(a) && !isNaN(b))
      return a + b;
  else
      throw "Invalid SUM";
}

// a-b
export function dif(a, b) {
  if (Array.isArray(a) && Array.isArray(b))
      return diffSeries(a, b);
  else
  if (Array.isArray(a) && !isNaN(b))
      return diffScalar(a, b);
  else
  if (!isNaN(a) && !isNaN(b))
      return a - b;
  else
      throw "Invalid DIF";
}

// a*b
export function mul(a, b) {
  if (Array.isArray(a) && Array.isArray(b))
      return mulSeries(a, b);
  else
  if (Array.isArray(a) && !isNaN(b))
      return mulScalar(a, b);
  else
  if (!isNaN(a) && Array.isArray(b))
      return mulScalar(b, a);
  else
  if (!isNaN(a) && !isNaN(b))
      return a * b;
  else
      throw "Invalid MUL";
}

// a/b
export function div(a, b) {
  if (Array.isArray(a) && Array.isArray(b))
      return divSeries(a, b);
  else
  if (Array.isArray(a) && !isNaN(b))
      return divScalar(a, b);
  else
  if (!isNaN(a) && !isNaN(b))
      return a / b;
  else
      throw "Invalid DIV";
}



// Sumatoria condicional movil
// @param data serie de datos de entrada
// @param periodo
// @param funcion con condicional
// @return data serie de resultado
export function sumIf(serie, n, conditional) {
  var res = [];
  var acc = 0;

  for (var i = 0; i < n; i++)
      if (conditional(serie[i]))
          acc += serie[i];

  res.push(acc);
  for (var i = n; i < serie.length; i++) {

      if (conditional(serie[i - n]))
          acc -= serie[i - n];

      if (conditional(serie[i]))
          acc += serie[i];

      res.push(acc);
  }

  return res;
}

// Contador condicional movil
// @param data serie de datos de entrada
// @param periodo
// @param funcion con condicional
// @return data serie de resultado
export function countIf(serie, n, conditional) {
  var res = [];
  var acc = 0;

  for (var i = 0; i < n; i++)
      if (conditional(serie[i]))
          acc++;

  res.push(acc);
  for (var i = n; i < serie.length; i++) {

      if (conditional(serie[i - n]))
          acc--;

      if (conditional(serie[i]))
          acc++;

      res.push(acc);
  }

  return res;
}

// Cuenta las barras que cumplen la condicion respecto del valor de la barra previa
// hace (period-1) comparaciones
// @param array serie de datos
// @param int periodo
// @param funcion condicional sobre (dato[current bar],dato[previous bar])
// @param shouldBreak bool si una barra no cumple deja de contar si es true
export function countIfPrev(serie, period, conditional, shouldBreak = false) {
  var res = [];

  for (var b = period - 1; b < serie.length; b++) {
      var cnt = 0;
      for (var i = 0; i < period - 1; i++) {
          if (conditional(serie[b - i], serie[b - i - 1]))
              cnt++;
          else
          if (shouldBreak)
              break;
      }
      res.push(cnt);
  }
  return res;
}

// Predicado
// funcion delegada que devuelve un boolean de acuerdo a si la condicion se cumple o no
// @param data serie de datos de entrada
// @param periodo
// @param funcion con condicional
// @return data serie de resultado
export function predicate(serie, conditional, n = 1) {
  var res = [];
  var succ;

  for (var i = n - 1; i < serie.length; i++) {
      res.push(conditional(serie[i]));
  }

  return res;
}

// Minimo movil
// @param data serie de datos de entrada
// @param periodo
// @return data serie de resultado
export function lowest(serie, n = serie.length) {
  var res = [];

  for (var j = 0; j < serie.length - n + 1; j++) {
      var min = Number.MAX_VALUE;

      for (var i = j; i < j + n; i++) {
          if (serie[i] < min)
              min = serie[i];
      }
      res.push(min);
  }

  return res;
}
export function max(a, b) {
  if (Array.isArray(a) && Array.isArray(b))
      return maxSeries(a, b);
  else
  if (Array.isArray(a) && !isNaN(b))
      return maxScalar(a, b);
  else
  if (!isNaN(a) && Array.isArray(b))
      return maxScalar(b, a);
  else
  if (!isNaN(a) && !isNaN(b))
      return a > b ? a : b;
  else
      throw "Invalid MAX";
}

export function maxSeries(serie1, serie2) {
  var res = [];
  var start, end, len;
  if (serie1.length > serie2.length) {
      dif = serie1.length - serie2.length;
      start = dif;
      end = serie1.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i] > serie2[i - dif] ? serie1[i] : serie2[i - dif]);
      }

  } else {
      dif = serie2.length - serie1.length;
      start = dif;
      end = serie2.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i - dif] > serie2[i] ? serie1[i - dif] : serie2[i]);
      }

  }
  return res;
}
export function maxScalar(serie, scalar) {
  var res = [];

  for (var i = 0; i < serie.length; i++)
      res.push(serie[i] > scalar ? serie[i] : scalar);

  return res;
}

export function min(a, b) {
  if (Array.isArray(a) && Array.isArray(b))
      return minSeries(a, b);
  else
  if (Array.isArray(a) && !isNaN(b))
      return minScalar(a, b);
  else
  if (!isNaN(a) && Array.isArray(b))
      return minScalar(b, a);
  else
  if (!isNaN(a) && !isNaN(b))
      return a < b ? a : b;
  else
      throw "Invalid MIN";
}

export function minSeries(serie1, serie2) {
  var res = [];
  var start, end, len;

  if (serie1.length > serie2.length) {
      dif = serie1.length - serie2.length;
      start = dif;
      end = serie1.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i] < serie2[i - dif] ? serie1[i] : serie2[i - dif]);
      }

  } else {
      dif = serie2.length - serie1.length;
      start = dif;
      end = serie2.length;

      for (var i = start; i < end; i++) {
          res.push(serie1[i - dif] < serie2[i] ? serie1[i - dif] : serie2[i]);
      }

  }
  return res;
}
export function minScalar(serie, scalar) {
  var res = [];

  for (var i = 0; i < serie.length; i++)
      res.push(serie[i] < scalar ? serie[i] : scalar);

  return res;
}

// Maximo movil
// @param data serie de datos de entrada
// @param periodo
// @return data serie de resultado
export function highest(serie, n = serie.length) {
  var res = [];

  for (var j = 0; j < serie.length - n + 1; j++) {
      var max = Number.MIN_VALUE;

      for (var i = j; i < j + n; i++) {
          if (serie[i] > max)
              max = serie[i];
      }
      res.push(max);
  }

  return res;
}

// la mediana entre data series paralelos como HIGH y LOW dando (HIGH+LOW)/2  --ok
// equivalente a Median en NT si serie1 y serie2 son HIGH y LOW
export function medianSeries(serie1, serie2) {
  return div(sumSeries(serie1, serie2), 2);
}

// Movil Median for a data serie -- ok
// @author #aminmeyghani & #boctulus
// equivalente a GetMedian() de NT
export function median(serie = CLOSE, n) {
  var res = [];

  for (var i = n; i <= serie.length; i++) {
      var subserie = copy(serie, i - n, i);
      var numbers = subserie.sort((a, b) => a - b);
      var middle = Math.floor(numbers.length / 2);
      var isEven = numbers.length % 2 === 0;

      res.push(isEven ? (numbers[middle] + numbers[middle - 1]) / 2 : numbers[middle]);
  }

  return res;
}


// stdDev**2 --- ok
export function variance(serie, n) {
  var res = [];

  var means = sma(serie, n);
  var i = 0;
  for (m of means) {
      var d = diffScalar(copy(serie, i, i + n), m);
      var d2 = mulSeries(d, d);
      var sum_d2 = 0;

      for (var j = 0; j < d2.length; j++)
          sum_d2 += d2[j];

      res.push(sum_d2 / (n - 1));
      i++;
  }

  return res;
}

// Standar deviation -- ok
export function stdDev(serie, n) {
  var res = [];
  var std2 = variance(serie, n);

  for (var i in std2)
      res.push(Math.sqrt(std2[i]));

  return res;
}

export function barsUp(serie = CLOSE, period) {
  var res = [];

  for (var b = period - 1; b < serie.length; b++) {
      var cnt = 0;
      for (var i = 0; i < period - 1; i++) {
          if (serie[b - i] > serie[b - i - 1])
              cnt++;
          else
              break;
      }
      res.push(cnt);
  }
  return res;
}

// Cantidad de barras consecutivas con valores decrecientes
// @return int
export function barsDown(serie = CLOSE, period) {
  var res = [];

  for (var b = period - 1; b < serie.length; b++) {
      var cnt = 0;
      for (var i = 0; i < period - 1; i++) {
          if (serie[b - i] < serie[b - i - 1])
              cnt++;
          else
              break;
      }
      res.push(cnt);
  }
  return res;
}

// El numero de barras consecutivas que supera el ultimo valor es mayor o igual a barCount?
// si barCount no se especifica verifica que en el periodo siempre haya valores crecientes
// @return boolean
export function nBarsUp(serie = CLOSE, period, barCount = period - 1) {
  var res = [];

  for (var b = period - 1; b < serie.length; b++) {
      var cnt = 0;
      for (var i = 0; i < period - 1; i++) {
          if (serie[b - i] > serie[b - i - 1])
              cnt++;
          else
              break;
      }
      res.push(cnt >= barCount);
  }
  return res;
}

// El numero de barras consecutivas que es inferior al ultimo valor es mayor o igual a barCount?
// si barCount no se especifica verifica que en el periodo siempre haya valores decrecientes
// @return boolean
export function nBarsDown(serie = CLOSE, period, barCount = period - 1) {
  var res = [];

  for (var b = period - 1; b < serie.length; b++) {
      var cnt = 0;
      for (var i = 0; i < period - 1; i++) {
          if (serie[b - i] < serie[b - i - 1])
              cnt++;
          else
              break;
      }
      res.push(cnt >= barCount);
  }
  return res;
}

// Checks for a rising condition which is true when the current value is greater than the previous value
// --ok
export function rising(serie = CLOSE) {
  var res = [];
  for (var i = 1; i < serie.length; i++)
      res.push(serie[i] > serie[i - 1]);

  return res;
}

//  otra implementacion  de rising --ok
export function rising2(serie = CLOSE) {
  return predicate(diffSeries(serie, prev(serie)), (x) => {
      return x > 0;
  }, 1);
}

//  otra implementacion  de rising --ok
export function rising3(serie = CLOSE) {
  return predicate(countIfPrev(serie, 2, function(x1, x0) {
      return x1 > x0
  }), (x) => {
      return x > 0;
  }, 1);
}


// Checks for a falling condition which is true when the current value is less than the previous value
export function falling(serie = CLOSE) {
  var res = [];
  for (var i = 1; i < serie.length; i++)
      res.push(serie[i] < serie[i - 1]);

  return res;
}

// CrossAbove(IserieSeries series1, IserieSeries series2, int lookBackPeriod)
export function crossAbove(serie1, serie2, n) {
  var res = [];
  var succ;

  if (serie1.length < serie2.length)
      serie2 = trimmer(serie2, serie1.length);

  if (serie2.length < serie1.length)
      serie1 = trimmer(serie1, serie2.length);

  for (var i = n - 1; i < serie1.length; i++) {
      succ = true;
      for (var j = 1; j < n; j++) {
          if (serie1[i - j] < serie2[i - j])
              continue;
          else {
              succ = false;
              break;
          }
      }

      res.push(succ && (serie1[i] > serie2[i]));
  }

  return res;
}

// aparentemente ok (dificil de verificar exaustivamente)
export function crossBelow(serie1, serie2, n) {
  var res = [];
  var succ;

  if (serie1.length < serie2.length)
      serie2 = trimmer(serie2, serie1.length);

  if (serie2.length < serie1.length)
      serie1 = trimmer(serie1, serie2.length);

  for (var i = n - 1; i < serie1.length; i++) {
      succ = true;
      for (var j = 1; j < n; j++) {
          if (serie1[i - j] > serie2[i - j])
              continue;
          else {
              succ = false;
              break;
          }
      }

      res.push(succ && (serie1[i] < serie2[i]));
  }

  return res;
}

// Cross above some value -- ok
// retorna true cuando se produce un cruce hacia arriba en la ultima barra
// Si hubo un valor superior en en periodo considerado (cruce previo) se invalida => false
export function exceed(serie, value, n) {
  var res = [];

  for (var i = n - 1; i < serie.length; i++) {
      var subarr = copy(serie, i - n + 1, i);
      res.push((countIf(subarr, subarr.length, function(x) {
          return (x < value);
      }) == subarr.length) && (serie[i] > value));
  }

  return res;
}

// Cross below some value --ok
export function fallBelow(serie, value, n) {
  var res = [];

  for (var i = n - 1; i < serie.length; i++) {
      var subarr = copy(serie, i - n + 1, i);
      res.push((countIf(subarr, subarr.length, function(x) {
          return (x > value);
      }) == subarr.length) && (serie[i] < value));
  }

  return res;
}

// serie1 cruza hacia arriba a serie2 o constante?
// si quiero saber si el cruce es hacia abajo, invierto el orden de los argumentos
export function cross(a, b, n) {
  if (Array.isArray(a) && Array.isArray(b))
      return crossAbove(a, b, n);
  else
  if (Array.isArray(a) && !isNaN(b))
      return exceed(a, b, n);
  else
      throw "Argument Exception in CROSS function";
}

// Slope(IserieSeries series, int startBarsAgo, int endBarsAgo)	--ok
export function slope(serie, startBarsAgo, endBarsAgo) {
  var dx = startBarsAgo - endBarsAgo;
  var dy = diffSeries(barsAgo(serie, endBarsAgo), barsAgo(serie, startBarsAgo));

  return div(dy, dx);
}

// Returns the number of bars ago the highest price value occurred for the lookback period.
// Colorario: retorna 0 si la ultima barra tiene el valor mas alto
export function highestBar(serie = CLOSE, n) {
  var res = [];

  for (var i = n - 1; i < serie.length; i++) {
      var mx = highest(copy(serie, i - n + 1, i))[0];

      if (serie[i] >= mx)
          res.push(0);
      else
          for (var j = 1; j < n; j++) {
              if (serie[i - j] == mx) {
                  res.push(-j);
                  break;
              }
          }
  }

  return res;
}


// Returns the number of bars ago the lowest price value occured for the lookback period.
export function lowestBar(serie = CLOSE, n) {
  var res = [];

  for (var i = n - 1; i < serie.length; i++) {
      var mm = lowest(copy(serie, i - n + 1, i))[0];

      if (serie[i] <= mm)
          res.push(0);
      else
          for (var j = 1; j < n; j++) {
              if (serie[i - j] == mm) {
                  res.push(-j);
                  break;
              }
          }
  }

  return res;
}


// precio ponderado
export function weighted(n) {
  var res = [];

  for (var i = n - 1; i < CLOSE.length; i++)
      res.push((HIGH[i] + LOW[i] + CLOSE[i] + CLOSE[i]) / 4);

  return res;
}

// precio tipico
export function typical(n) {
  var res = [];

  for (var i = n - 1; i < CLOSE.length; i++)
      res.push((HIGH[i] + LOW[i] + CLOSE[i]) / 3);

  return res;
}
export function isABigThanB(a, b) {
  if (Array.isArray(a) && Array.isArray(b))
      return isABigThanBSeries(a, b);
  else if (Array.isArray(a) && !isNaN(b))
      return isABigThanBScalar(a, b, true);
  else if (!isNaN(a) && Array.isArray(b))
      return isABigThanBScalar(b, a, false);
  else if (!isNaN(a) && !isNaN(b))
      return a > b;
  else
      throw "Invalid Compare";
}
export function isABigThanBScalar(serie1, scalar, correlation){
  var res = [];
  for (var i = 0; i < serie1.length; i++) {
    if (correlation){
      res.push(serie1[i] > scalar);
    } else {
      res.push(serie1[i] < scalar);
    }
  }
  return res;
}
export function isABigThanBSeries(serie1, serie2){
  var res = [];
  var start, end, len;
  if (serie1.length > serie2.length) {
    dif = serie1.length - serie2.length;
    start = dif;
    end = serie1.length;

    for (var i = 0; i < end; i++) {
      if (i < start){
        res.push(false);
      } else {
        res.push(serie1[i] > serie2[i - dif]);
      }
    }

  } else {
      dif = serie2.length - serie1.length;
      start = dif;
      end = serie2.length;

      for (var i = 0; i < end; i++) {
        if (i < start){
          res.push(false);
        } else {
          res.push(serie1[i - dif] > serie2[i]);
        }
      }

  }
  return res;
}
export function change(serie, length=1){
  var res = [];
  var len = serie.length;
  for (var i = length; i < len; i++) {
    res.push(serie[i] - serie[i - length]);
  }
  return res;
}
export function sumFromBack(serie, cnt=1){
  var res = [];
  var len = serie.length;
  let sum = 0;
  for (var i = cnt - 1; i < len; i++) {
    sum = 0;
    for (var j = i + 1 - cnt; j < i + 1; j++){
      sum += serie[j];
    }
    res.push(sum);
  }
  return res;
}
// Triple Exponential Moving Average
export function tema(serie = CLOSE, n = 14) {
  return diffSeries(mulScalar(ema(serie, n), 3), ema(ema(ema(serie, n), n), n));
}

export function stdError(serie, n = 14) {
  return div(stdDev(serie, n), Math.sqrt(n));
}

// Retorna un array de gaps
export function gapDown() {
  var res = [];

  for (var i = 1; i < count(); i++) {
      res.push(HIGH[i] < LOW[i - 1]);
  }

  return res;
}

export function gapUp() {
  var res = [];

  for (var i = 1; i < count(); i++) {
      res.push(HIGH[i] > LOW[i - 1]);
  }

  return res;
}

// Inside Day
export function inside() {
  var res = [];

  for (var i = 1; i < count(); i++) {
      res.push(HIGH[i] < HIGH[i - 1] && LOW[i] > LOW[i - 1]);
  }

  return res;
}

// Outside Day
export function outside() {
  var res = [];

  for (var i = 1; i < count(); i++) {
      res.push(HIGH[i] > HIGH[i - 1] && LOW[i] < LOW[i - 1]);
  }

  return res;
}

export function range() {
  return diff(HIGH, LOW);
}

// immediate IF function -- ok
// retorna valores de la serie1 o de la serie2 segun sea true/false en la serie de condiciones
export function iff(serie_conditions, serie1, serie2) {
  var res = [];

  for (var i = 0; i < serie_conditions.length; i++)
      res.push(serie_conditions[i] ? serie1[i] : serie2[i]);

  return res;
}

// %K = 100(C - L14)/(H14 - L14)
// %D = 3-period moving average of %K
export function stochasticsFast(serie, periodK = 14, periodD = 3) {
  var res = {};
  var res = {};

  res.K = mulScalar(100, div(diff(CLOSE, low(periodK)), diff(high(periodK), low(periodK))));
  res.D = sma(K, periodD);

  return res;
}
