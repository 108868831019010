/**
 * Price data model
 */
const initialData = {
  /**
   * An array of price data series in following format:
   * {symbol, o, h, l, c, date, volume}
   */
  data: null,
  /**
   * Minimum and maximum values
   */
  min: 1000000.0,
  max: 0.0,
  range: 0.0,
  /**
   * Last change (open price - close price)
   */
  lastChange: 0.0,
  /**
   * Some extra data calculated from 'data' field
   */
  plugins: {
  }
};

export function quotesInit(quotesData, drawList) {
  const quotes = { ...initialData };
  quotes.data = quotesData;
  const volquotes = { ...initialData };
  volquotes.data = quotesData;

  for (const quote of quotesData) {
    if (quote.high > quotes.max) quotes.max = quote.high;
    if (quote.low < quotes.min) quotes.min = quote.low;
  }

  if (Object.keys(drawList).length > 0) {
    const seriesKeys = [];
    const volumeKeys = [];
    const indicatorKeys = Object.keys(drawList);
    indicatorKeys.map((key)=>{
      const drawItems = drawList[key];
      let plotcnt = -1;
      drawItems.map((ele)=>{
        if (ele.type == 'plot'){
          plotcnt++;
          if (key.slice(0, 2) == 'VOL'){
            volumeKeys.push('plot_' + key + plotcnt);
          } else {
            seriesKeys.push('plot_' + key + plotcnt);
          }
        }
      });
    });

    let totalMax = quotes.max;
    let totalMin = quotes.min;
    for (const key of seriesKeys){
      let max = quotesData[0][key];
      let min = quotesData[0][key];
      for (const quote of quotesData) {
        if (quote[key] > max) max = quote[key];
        if (quote[key] < min) min = quote[key];
      }
      if (totalMax < max) {
        totalMax = max;
      }
      if (totalMin > min) {
        totalMin = min;
      }
    }
    quotes.max = totalMax;
    quotes.min = totalMin;
    // volume quotes
    let totalVolMax = undefined;
    let totalVolMin = undefined;
    for (const key of volumeKeys){
      let max = quotesData[0][key];
      let min = quotesData[0][key];
      for (const quote of quotesData) {
        if (quote[key] > max) max = quote[key];
        if (quote[key] < min) min = quote[key];
      }
      if (totalVolMax == undefined || totalVolMax < max) {
        totalVolMax = max;
      }
      if (totalVolMin == undefined || totalVolMin > min) {
        totalVolMin = min;
      }
    }
    volquotes.max = totalVolMax;
    volquotes.min = totalVolMin;
  }

  const range = quotes.max - quotes.min;
  quotes.max = quotes.max + range * 0.1;
  quotes.min = quotes.min - range * 0.1;
  quotes.range = quotes.max - quotes.min;

  const volrange = volquotes.max - volquotes.min;
  volquotes.max = volquotes.max + volrange * 4;
  volquotes.range = volrange;

  return {quotes, volquotes};
}

export function indicatorQuotesInit(quotesData, drawList) {
  const quotes = { ...initialData };
  quotes.data = quotesData;

  const seriesKeys = [];
  const indicatorKeys = Object.keys(drawList);
  indicatorKeys.map((key)=>{
    const drawItems = drawList[key];
    let plotcnt = -1;
    drawItems.map((ele)=>{
      if (ele.type == 'plot'){
        plotcnt++;
        seriesKeys.push('plot_' + key + plotcnt);
      }
    });
  });

  let totalMax = undefined;
  let totalMin = undefined;
  for (const key of seriesKeys){
    let max = quotesData[0][key];
    let min = quotesData[0][key];
    for (const quote of quotesData) {
      if (max == undefined || quote[key] > max) max = quote[key];
      if (min == undefined || quote[key] < min) min = quote[key];
    }
    if (totalMax == undefined || totalMax < max) {
      totalMax = max;
    }
    if (totalMin == undefined || totalMin > min) {
      totalMin = min;
    }
  }
  const range = totalMax - totalMin;
  totalMax = totalMax + range * 0.1;
  totalMin = totalMin - range * 0.1;
  quotes.max = totalMax;
  quotes.min = totalMin;
  quotes.range = quotes.max - quotes.min;

  return quotes;
}
