import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import numeral from "numeral";
import moment from "moment";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import {DonutChart} from 'react-circle-chart';
import FontAwesome from "../../../../components/FontAwesome";
import ReactTooltip from 'react-tooltip';

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few sec",
    ss: "%d secs",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

import styles from "./SentimentWidget.scss";

class PriceWidget extends Component {
  constructor() {
    super();

    this.element = null;
  }

  componentDidMount() {
    const {subscribeSentimentData, widget} = this.props;
    subscribeSentimentData(widget.i);
  }

  async componentDidUpdate(prevProps) {
    const {widget, unSubscribeSentimentData, subscribeSentimentData} = this.props;
    if (prevProps.widget.period !== widget.period || prevProps.widget.name !== widget.name) {
      await unSubscribeSentimentData(widget.i);
      subscribeSentimentData(widget.i);
    }
  }

  componentWillUnmount() {
    const {unSubscribeSentimentData, widget} = this.props;
    unSubscribeSentimentData(widget.i);
  }

  getSentimentTriangle = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return <FontAwesome className={styles.sentimentNeutral} name="square" prefix="fas"/>;
    } else if (score > 0.5) {
      return <FontAwesome className={styles.sentimentBullish} name="arrow-alt-up" prefix="fas"/>;
    } else if (score < -0.5) {
      return <FontAwesome className={styles.sentimentBearish} name="arrow-alt-down" prefix="fas"/>;
    }
  };

  getSentimentColor = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return "rgba(255, 199, 0, 0.8";
    } else if (score > 0.5) {
      return "rgba(92, 184, 92, 0.8)";
    } else if (score < -0.5) {
      return "rgba(255, 90, 54, 0.8)";
    }
  };
  getSentimentBorder = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return "rgba(255, 199, 0, 0.3";
    } else if (score > 0.5) {
      return "rgba(92, 184, 92, 0.3)";
    } else if (score < -0.5) {
      return "rgba(255, 90, 54, 0.3)";
    }
  };

  getSentimentSimple = (score) => {
    if (score >= -0.5 && score <= 0.5) {
      return "Neutral";
    } else if (score > 0.5) {
      return "Positive";
    } else if (score < -0.5) {
      return "Negative";
    }
  };
  getSentimentValue = (score) => {
    //debugger;

    let positive = 0;
    let neutral = 0;
    let negative = 0;

    if (score >= -0.5 && score <= 0.5) {
      neutral = score;
      return neutral;
    } else if (score !== undefined && score !== 0 && score > 0.5) {
      positive = score;
      return positive;
    } else if (score < -0.5) {
      negative = score;
      return negative;
    }
  };

  render() {
    const {sentiment, widget, setWidgetActive} = this.props;
    const score = sentiment && sentiment.score ? numeral(sentiment.score).format("0.[00]") : 0;
    const updated = sentiment && sentiment.last ? moment(sentiment.last).fromNow() : "n/a";

    return (
      <div
        className={classNames("widgetWrapper", {"Active": widget.active})}
        onClick={() => !widget.active && setWidgetActive(widget.i)}
        role="button"
        tabIndex="0"
      >
        <WidgetToolbar
          {...this.props}
        />
        <div className={styles.SentimentWidget}>
          <div className={styles.Values}>
            <div>
              <img className={styles.cardSVG} alt="user" src="/img/icons/user.svg"/>
            </div>
            <div className={styles.titleGroup}>
              <span>
                <FontAwesome
                  prefix="far"
                  name="search"
                />
              </span>
              <input
                placeholder="Search..."
              />
              <button className={styles.title}>
                d1
              </button>
              <button className={styles.title}>
                <FontAwesome
                  prefix="fas"
                  name="rss"
                />
              </button>
            </div>

            <div style={{marginTop: 10, opacity: 0.8}} className={styles.donutChart}>
              <DonutChart
                items={[
                  { value: (parseInt((sentiment.score/5)*100)),
                    label: "Positive",
                    color: (this.getSentimentColor(score)) },
                ]}
                roundedCaps={false}
                size={90}
                totalFontSize={20}
                totalTextColor={"#fff"}
                trackWidth={"sm"}
                trackColor={"rgba(255, 255, 255, 0.1)"}
              />

              <div style={{marginTop: 20}} className={styles.rating}>Sentiment:
                <span
                  style={{
                    marginLeft: 5,
                    color: this.getSentimentColor(score),
                    border: "solid",
                    borderColor: this.getSentimentBorder(score),
                    borderWidth: 1,
                    borderRadius: 3
                  }}
                  className={styles.sentimentArrow}
                >
                  {this.getSentimentTriangle(score)} {this.getSentimentSimple(score)}

                </span>


              </div>
              <div className={styles.rating}>Updated:
                <span> {updated}</span>
              </div>
            </div>
          </div>
          <div style={{marginTop: 30}} className={styles.linearBubbleChart1}>
            <a data-tip data-for='linearBubbleNumber1'>
              <span className={styles.linearBubbleNumber}>46</span>
            </a>
            <ReactTooltip
              id='linearBubbleNumber1'
              offset="{'top': -8}"
              //arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span><b>Found:</b><br/>46 Positive articles<br/> in the last 24 hrs</span>
            </ReactTooltip>
            <span className={styles.linearBubbleTitle}>Positive</span>
          </div>
          <div className={styles.linearBubbleChart2}>
            <a data-tip data-for='linearBubbleNumber2'>
              <span className={styles.linearBubbleNumber}>05</span>
            </a>
            <ReactTooltip
              id='linearBubbleNumber2'
              offset="{'top': -8}"
              //arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span><b>Found:</b><br/>5 Neutral articles<br/> in the last 24 hrs</span>
            </ReactTooltip>
            <span className={styles.linearBubbleTitle}>Neutral</span>

          </div>
          <div className={styles.linearBubbleChart3}>
            <a data-tip data-for='linearBubbleNumber3'>
              <span className={styles.linearBubbleNumber}>23</span>
            </a>
            <ReactTooltip
              id='linearBubbleNumber3'
              offset="{'top': -8}"
              //arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span><b>Found:</b><br/>23 Negative articles<br/> in the last 24 hrs</span>
            </ReactTooltip>
            <span className={styles.linearBubbleTitle}>Negative</span>
          </div>
        </div>
      </div>

    );
  }
}

PriceWidget.propTypes = {
  sentiment: PropTypes.object,
  setWidgetActive: PropTypes.func,
  subscribeSentimentData: PropTypes.func,
  unSubscribeSentimentData: PropTypes.func,
  widget: PropTypes.object,
};

PriceWidget.defaultProps = {
  sentiment: undefined,
  setWidgetActive: () => false,
  subscribeSentimentData: () => false,
  unSubscribeSentimentData: () => false,
  widget: undefined,
};

export default PriceWidget;
