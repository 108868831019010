import {
  SHOW_ALERT,
} from "../const";

const showAlert = (type, message, timeout) => (dispatch) => {
  dispatch({
    type: SHOW_ALERT,
    alertType: type,
    message: message,
    timeout: timeout,
  });
};

export {
  showAlert,
};
