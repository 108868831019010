import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from "recharts";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import NoActiveWidget from "../NoActiveWidget";
import {sortBy} from "lodash";
import numeral from "numeral";
import {format} from "d3-format";
import CustomTooltip from "./CustomTooltip";

import "./DomChartWidget.scss";

class DomChartWidget extends PureComponent {
  calculateData = () => {
    const {marketOrderbook, activeWidget} = this.props;

    const orderbook = [];

    const sellData = marketOrderbook.asks ? sortBy(marketOrderbook.asks, (item) => item[0]) : [];
    const buyData = marketOrderbook.bids ? sortBy(marketOrderbook.bids, (item) => item[0]).reverse() : [];

    let buySourceSum = 0;
    let buyTargetSum = 0;

    buyData.forEach((item) => {
      buyTargetSum = buyTargetSum + item[0] * item[1];
      buySourceSum = buySourceSum + item[1];
      orderbook.push({
        price: item[0],
        type: "buy",
        name: activeWidget && activeWidget.name,
        buySourceSum: buySourceSum,
        buyTargetSum: buyTargetSum,
      });
    });

    let sellSourceSum = 0;
    let sellTargetSum = 0;

    sellData.forEach((item) => {
      sellTargetSum = sellTargetSum + item[0] * item[1];
      sellSourceSum = sellSourceSum + item[1];
      orderbook.push({
        price: item[0],
        type: "sell",
        name: activeWidget && activeWidget.name,
        sellSourceSum: sellSourceSum,
        sellTargetSum: sellTargetSum,
      });
    });

    return sortBy(orderbook, (item) => item.price);
  };

  drawChart = () => {
    const {widget: {options}} = this.props;
    const data = this.calculateData();
    const orientation = options && options.orientation || "right";
    const margin = {
      right: orientation === "left" ? 5 : -10,
      left: orientation === "left" ? -10 : 5,
      top: 0,
      bottom: 0,
    };

    return (
      <ResponsiveContainer className="domChart">
        <AreaChart
          data={data}
          margin={margin}
        >
          <CartesianGrid/>
          <XAxis
            tickFormatter={format(".8f")}
            dataKey="price"
          />
          <YAxis
            orientation={orientation}
            tickFormatter={(value) => numeral(value).format("0.[00]a")}
          />
          <Area
            type='basis'
            dataKey='buySourceSum'
            stroke={options && options.buyFill || "#5c8971"}
            fill={options && options.buyFill || "#5c8971"}
            fillOpacity={options && options.buyOpacity || 0.8}
          />
          <Area
            type='basis'
            dataKey='sellSourceSum'
            stroke={options && options.sellFill || "#b50611"}
            fill={options && options.sellFill || "#b50611"}
            fillOpacity={options && options.sellOpacity || 0.8}
          />
          <Tooltip
            content={<CustomTooltip/>}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  render() {
    return (
      <div className="widgetWrapper">
        <WidgetToolbar
          {...this.props}
        />
        <div className="marketsWidget disableDrag">
          {(!this.props.activeWidget.name || !this.props.activeWidget.exchange) ? (
            <NoActiveWidget/>
          ) : this.drawChart()}
        </div>
      </div>
    );
  }
}

DomChartWidget.propTypes = {
  activeWidget: PropTypes.object,
  marketOrderbook: PropTypes.object,
  widget: PropTypes.object,
};

DomChartWidget.defaultProps = {
  activeWidget: {},
  marketOrderbook: {},
  widget: undefined,
};

export default DomChartWidget;
