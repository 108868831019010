import React, {PureComponent, Fragment} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import Loader from "../../../../components/Loader";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import PerfectScrollbar from "react-perfect-scrollbar";
import FloatValue from "../../../../components/FloatValue";
import NoActiveWidget from "../NoActiveWidget";
import "./HistoryWidget.scss";

const FIELDS = ["Time", "Type", "Price", "Amount"];

class HistoryWidget extends PureComponent {
  constructor() {
    super();
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentDidMount() {
    this.subscribeTrades();
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeWidget.name && this.props.activeWidget.exchange &&
      (prevProps.activeWidget.name !== this.props.activeWidget.name ||
      prevProps.activeWidget.exchange !== this.props.activeWidget.exchange)) {
      this.subscribeTrades(prevProps.activeWidget.name, prevProps.activeWidget.exchange);
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    clearInterval(this.interval);
    this.interval = null;
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup() {
    const {unsubscribeMarketHistory, activeWidget} = this.props;
    const exchangeName = activeWidget.exchange;
    const marketSplitted = activeWidget.name.split("-");
    const subscribeObj = this.getSubscribeObj(exchangeName, marketSplitted[0], marketSplitted[1]);
    unsubscribeMarketHistory(exchangeName, subscribeObj);
  }

  interval = null;

  subscribeTrades(prevName, prevExchange){
    const {subscribeMarketHistory, unsubscribeMarketHistory, activeWidget, getMarketHistory} = this.props;
    const exchangeName = activeWidget.exchange;
    const marketName = activeWidget.name;
    if (!marketName || !exchangeName) return;
    const marketSplitted = marketName.split("-");

    clearInterval(this.interval);
    if (prevName && prevExchange) {
      const unsubscribeObj = this.getSubscribeObj(exchangeName, prevName.split("-")[0], prevName.split("-")[1]);
      unsubscribeMarketHistory(prevExchange, unsubscribeObj);
    }

    const subscribeObj = this.getSubscribeObj(exchangeName, marketSplitted[0], marketSplitted[1]);
    getMarketHistory(true);
    if (exchangeName && subscribeObj) {  //snap and update
      subscribeMarketHistory(exchangeName, marketSplitted, subscribeObj);
    } else {
      this.interval = setInterval(() => {
        getMarketHistory();
      }, 10000);
    }
  }

  getSubscribeObj = (exchangeName, base, quote) => {
    switch (exchangeName) {
      case 'binance':
      case 'binancejr':
        return {
          id: quote + base,
          base: quote,
          quote: base,
        };
      case 'poloniex':
        return {
          id: base + '_' + quote,
          base: quote,
          quote: base,
        };
      case 'bitfinex':
        if (base == 'USDT'){
          base = 'UST';
        } else if (quote == 'USDT') {
          quote = 'UST';
        }
        return {
          id: quote + base,
          base: quote,
          quote: base,
        };
      case 'bitmex':
        if (base == 'BTC'){
          base = 'XBT';
        } else if (quote == 'BTC') {
          quote = 'XBT';
        }
        return {
          id: quote + base,
          base: quote,
          quote: base
        };
      case 'coinbasepro':
        return {
          id: quote +'-'+ base,
          base: quote,
          quote: base
        };
      case 'hitbtc2':
        if (base == 'USDT'){
          base = 'USDT20';
        } else if (quote == 'USDT') {
          quote = 'USDT20';
        }
        return {
          id: quote + base,
          base: quote,
          quote: base
        };
      case 'coinex':
        return {
          id: quote + base,
          base: quote,
          quote: base
        };
      case 'okex':
        return {
          id: quote + '-' + base,
          base: quote,
          quote: base
        };
      case 'ftx':
        return {
          id: quote + '/' + base,
          base: quote,
          quote: base
        };
      // case 'ethfinex': //moved to bitfinex
      //   if (base == 'USDT'){
      //     base = 'UST';
      //   } else if (quote == 'USDT') {
      //     quote = 'UST';
      //   }
      //   return {
      //     id: quote + base,
      //     base: quote,
      //     quote: base,
      //   };
      default:
        return null;
    }
  };
  render() {
    const {activeWidget, marketHistory} = this.props;

    return (
      <div className="widgetWrapper">
        <WidgetToolbar
          {...this.props}
        />
        <div className="historyWidget disableDrag">
          {(!activeWidget.name || !activeWidget.exchange) ? (
            <NoActiveWidget/>
          ) : (
            <Fragment>
              <table>
                <thead>
                  <tr>
                    {FIELDS.map((field) => (
                      <th key={field}>
                        {` ${field}`}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
              <PerfectScrollbar
                className="table-body"
                options={{suppressScrollX: true}}
              >
                {marketHistory && marketHistory.length > 0 ? (
                  <table>
                    <tbody>
                      {marketHistory.map((item) => (
                        <tr
                          className={classNames({"Updated Gray": item.isNew})}
                          key={item.id}
                        >
                          <td>
                            {moment(item.timestamp).format("HH:mm:ss")}
                          </td>
                          <td>
                            <span className={
                              classNames({
                                up: item.side === "buy",
                                down: item.side === "sell",
                                green: item.side === "buy",
                                red: item.side === "sell",
                              })}
                            >
                              {item.side.toUpperCase()}
                            </span>
                          </td>
                          <td>

                            <FloatValue
                              value={item.price}
                            />

                          </td>

                          <td>

                            <FloatValue
                              value={item.amount}
                            />

                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Loader/>
                )}
              </PerfectScrollbar>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

HistoryWidget.propTypes = {
  activeWidget: PropTypes.object,
  getMarketHistory: PropTypes.func,
  marketHistory: PropTypes.array,
  subscribeMarketHistory: PropTypes.func.isRequired,
  unsubscribeMarketHistory: PropTypes.func.isRequired,
};

HistoryWidget.defaultProps = {
  activeWidget: {},
  getMarketHistory: () => false,
  marketHistory: [],
};

export default HistoryWidget;
