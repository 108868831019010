import {getBalanceList, getAccountBalanceList} from "../api/BalanceApi";
import {showAlert} from "../actions/CommonActions";

import {
  GET_BALANCE,
  GET_BALANCE_ERROR,
  GET_BALANCE_SUCCESS,
  ALERT_TYPES,
  GET_ACCOUNT_BALANCE,
  GET_ACCOUNT__BALANCE_SUCCESS,
  GET_ACCOUNT__BALANCE_ERROR,
} from "../const";

const init = () => async (dispatch, getState) => {
  const state = getState();
  if (state.balance.balance.loading === false) {
    dispatch(fetchBalance());
  }
};

const destroy = () => () => {
  return false;
};

const fetchBalance = () => async (dispatch) => {
  dispatch({
    type: GET_BALANCE,
  });

  try {
    const result = await getBalanceList();

    dispatch({
      type: GET_BALANCE_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BALANCE_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const fetchAccountBalance = (account) => async (dispatch) => {
  dispatch({
    type: GET_ACCOUNT_BALANCE,
  });

  try {
    const result = await getAccountBalanceList(account);

    dispatch({
      type: GET_ACCOUNT__BALANCE_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ACCOUNT__BALANCE_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

export {
  init,
  destroy,
  fetchBalance,
  fetchAccountBalance,
};
