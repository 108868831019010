// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HWGugDGYQKKZLq73URXmT{padding:4px 8px !important;background-color:#2a2d34 !important;color:rgba(255,255,255,.75) !important;font-weight:400}._304a3bnR0eY1bYXBN3gKQo{height:calc(100% - 26px);width:100%;overflow:hidden}._304a3bnR0eY1bYXBN3gKQo:hover{cursor:pointer}._304a3bnR0eY1bYXBN3gKQo .ZyKPln7gWH0R7rcj9ErXe{text-align:center}.bubblechart text.name,.bubblechart text.name2,.bubblechart text.price,.bubblechart text.change{fill:#fff}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/DeFiScannerWidget/DeFiScannerWidget.scss"],"names":[],"mappings":"AAEE,uBACE,0BAAA,CACA,mCAAA,CACA,sCAAA,CACA,eAAA,CAEF,yBACE,wBAAA,CACA,UAAA,CACA,eAAA,CACA,+BACE,cAAA,CAEF,gDACE,iBAAA,CAQF,gGACE,SAAA","sourcesContent":["@import \"../../../../styles/constants\";\n:local {\n  .buttonTooltip {\n    padding: 4px 8px !important;\n    background-color: $background-dark !important;\n    color: $white-faded !important;\n    font-weight: 400;\n  }\n  .Wrapper {\n    height: calc(100% - 26px);\n    width: 100%;\n    overflow: hidden;\n    &:hover {\n      cursor: pointer;\n    }\n    .tooltiptext {\n      text-align: center;\n    }\n\n  }\n}\n\n:global {\n  .bubblechart {\n    text.name, text.name2, text.price, text.change {\n      fill: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonTooltip": "HWGugDGYQKKZLq73URXmT",
	"Wrapper": "_304a3bnR0eY1bYXBN3gKQo",
	"tooltiptext": "ZyKPln7gWH0R7rcj9ErXe"
};
export default ___CSS_LOADER_EXPORT___;
