import {subscribe} from "../api/SettingsApi";
import {showAlert} from "../actions/CommonActions";

import {ALERT_TYPES} from "../const";

const subsribeAction = (type, total, callback) => async (dispatch) => {
  try {
    await subscribe({
      type,
      total,
    });
    callback();
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

export {
  subsribeAction,
};
