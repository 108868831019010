import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import FontAwesome from "../../../components/FontAwesome";
import classNames from "classnames";
import styles from './WidgetToolbar.scss';
import MarketName from "../../../components/MarketName";
import {ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle} from "reactstrap";
import {INTERVALS} from "../../../constants/MarketsConstants";
import drawingtechnical from './DrawingTechnicalConstants.js';
import drawingshapes from './DrawingShapesConstants.js';
import drawingpointers from './DrawingPointersConstants.js';
import ReactTooltip from 'react-tooltip';

import "./WidgetToolbar.scss";
import {cloneDeep} from 'lodash';
const customIntervals = [
  {short: "m", value: "minutes", min: 1},
  {short: "h", value: "hours", min: 60},
  {short: "d", value: "days", min: 1440},
  {short: "w", value: "weeks", min: 10080},
  {short: "M", value: "months", min: 40320},
];

class WidgetToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsDropdownOpen: false,
      intervalDropdownOpen: false,
      customIntervalDropdownOpen: false,
      exchangeDropdownOpen: false,
      chatDropdownOpen: false,
      customIntervalMin: 1,
      customIntervalTF: 'minutes',
    };
  }

  toggleWidgetSize = () => {
    const {widget, updateWidget} = this.props;
    updateWidget({
      ...widget,
      expanded: widget.expanded === false,
    });
  };

  drawIntervalItem = (interval) => {
    const {updateWidget, widget} = this.props;
    if (widget.exchange == "poloniex" && interval.name == "m1") {
      return null;
    }
    if (interval.type && interval.type === "divider") {
      return (
        <DropdownItem className="widgetConnectItem" key={`${interval.name}-${interval.value}`}>
          <span className={styles.drawingItemTitle}>{interval.divider}</span>
          <span className="dropdownIntervalHover">
            <span className="dropdownIntervalShort">{name}</span> {interval.fullname}
          </span>
        </DropdownItem>
      );
    }
    return (
      <DropdownItem
        className={classNames({active: interval.value === widget.period})}
        data-test-id={interval.value}
        key={interval.name}
        onClick={() => {
          const widgetClone=cloneDeep(widget);
          if (!widgetClone.basePeriod){
            widgetClone.basePeriod={
              from: {min: null, value: null},
              to: {min: null, value: null},
            };
          }
          widgetClone.basePeriod.from.min=widgetClone.basePeriod.to.min=interval.min;
          widgetClone.basePeriod.from.value=widgetClone.basePeriod.to.value=interval.value;
          widgetClone.period=interval.value;
          updateWidget(widgetClone);
        }}
      >
        <span className="dropdownIntervalShort" >{interval.name}</span>{interval.fullname}
      </DropdownItem>
    );
  };

  drawCustomIntervalItem = (interval) => {
    const {widget} = this.props;
    if (widget.exchange == "poloniex" && interval.value == "minutes") {
      return null;
    }
    return (
      <DropdownItem
        key={interval.short}
        onClick={() => this.setState({customIntervalTF: interval.value})}
      >
        {interval.value}
      </DropdownItem>
    );
  };

  drawIntervalDropdownPrice = () => {
    const {widget} = this.props;
    let period = widget.period;
    const periodInfo = INTERVALS.find((item) => item.value === widget.period);
    if (periodInfo) {
      period = periodInfo.name;
    }
    return (

      <ButtonDropdown
        isOpen={this.state.intervalDropdownOpen}
        toggle={() => this.handleToggleDropdown("interval")}
      >

        <DropdownToggle
          className="IntervalsButton"
          type="button"
        >
          {period}
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-left intervalDropDown"
        >
          <DropdownItem header>
            Intervals
          </DropdownItem>
          <span style={{marginLeft: 10}} className="dropdownIntervalShort">d1</span>
          <span className="dropdownIntervalType"> 1Day</span>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  drawIntervalDropdown = () => {
    const {widget} = this.props;
    let isCustom = true;
    let period = widget.period;
    const periodInfo = INTERVALS.find((item) => item.value === widget.period);
    if (periodInfo) {
      period = periodInfo.name;
      isCustom = false;
    }
    return (
      <span>
        <a data-tip data-for='intervalButton'>
          <ButtonDropdown
            isOpen={this.state.intervalDropdownOpen}
            toggle={() => this.handleToggleDropdown("interval")}
          >

            <DropdownToggle
              className="IntervalsButton"
              type="button"
              onClick={() => this.handleToggleDropdown("interval")}
            >
              {period}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-left intervalDropDown">

              {/*{INTERVALS.map(({id, name, fullname, divider}) => (
            <DropdownItem className="widgetConnectItem" key={id} {...{ id }}>
              <span className={styles.drawingItemTitle}>{divider}</span>
              <span className="dropdownIntervalHover">
                <span className="dropdownIntervalShort">{name}</span> {fullname}
              </span>
            </DropdownItem>))}*/}
              {
                INTERVALS.map(this.drawIntervalItem)
              }

              <div
                className="dropdown-menu-label"
              >
                <span className={styles.drawingItemTitle}>CUSTOM INTERVAL</span>
              </div>
              <div className={`customIntervalContainer ${classNames({active: isCustom})}`}>
                <input
                  className={`intervalInput ${classNames({active: isCustom})}`}
                  value={this.state.customIntervalMin}
                  onChange={(event) => this.handleCustomInterval(event.target.value)}
                />
                <ButtonDropdown
                  isOpen={this.state.customIntervalDropdownOpen}
                  toggle={() => this.handleToggleDropdown("customInterval")}
                >

                  <DropdownToggle
                    className="customIntervalButton"
                    onClick={() => this.handleToggleDropdown("customInterval")}
                  >
                    {this.state.customIntervalTF}
                    <FontAwesome
                      name="chevron-down"
                      prefix="fas"
                      className="setBtnCustomIntervalAfter"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-left">
                    {
                      customIntervals.map(this.drawCustomIntervalItem)
                    }
                  </DropdownMenu>
                </ButtonDropdown>
                <button
                  tabIndex={0}
                  className="setBtnCustomInterval button"
                  onClick={() => this.setCustomInterval()}
                >
                  Set
                </button>
              </div>
            </DropdownMenu>
          </ButtonDropdown>
        </a>
        <ReactTooltip
          id='intervalButton'
          offset="{'top': -8}"
          arrowColor='transparent'
          className={styles.buttonTooltip}
          effect='solid'
        >
          <span>{periodInfo.fullname}</span>
        </ReactTooltip>
      </span>
    );
  };

  drawExchangeModal = () => {
    //const {widget, toggleModal} = this.props;

  };
  drawLinkColors = () => {

    return (
      <span>
        <a data-tip data-for='connectButton'>
          <ButtonDropdown
            className="widgetConnectDropdown"
            isOpen={this.state.widgetConnectSettingsDropdownOpen}
            toggle={() => this.handleToggleDropdown("widgetConnectSettings")}
          >
            <DropdownToggle
              type="button"
              onClick={() => this.handleToggleDropdown("widgetConnectSettings")}
            >
              <span className="widgetConnect" />
            </DropdownToggle>
            <DropdownMenu style={{width: 100, paddingTop: 0}}>
              <span style={{marginLeft: 10}} className={styles.drawingItemTitle}>Link</span>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotRed" /> Red
              </DropdownItem>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotOrange" /> Orange
              </DropdownItem>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotYellow" /> Yellow
              </DropdownItem>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotGreen" /> Green
              </DropdownItem>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotBlue" /> Blue
              </DropdownItem>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotPink" /> Pink
              </DropdownItem>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotPurple" /> Purple
              </DropdownItem>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDotBrown" /> Brown
              </DropdownItem>
              <span style={{marginLeft: 10}} className={styles.drawingItemTitle}>Remove</span>
              <DropdownItem className="widgetConnectItem">
                <span className="widgetConnectDot1" /> Unlink
              </DropdownItem>

            </DropdownMenu>
          </ButtonDropdown>
        </a>
        <ReactTooltip
          id='connectButton'
          offset="{'top': -7}"
          arrowColor='transparent'
          className={styles.buttonTooltip}
          effect='solid'
        >
          <span>Link Panels</span>
        </ReactTooltip>
      </span>
    );
  };
  drawWidgetName = () => {
    const {widget, activeWidget, updateWidget, toggleModal} = this.props;

    switch (widget.type) {
      case "scanner":
        return (
          <button
            className="ChartTitle"
            onClick={() => toggleModal("exchanges", {
              widgetId: widget.i,
            })}
          >
            <span className="ChartTab">SCANNER</span>
            <span className={styles.toolbardivider}>|</span>
            <a data-tip data-for='exchangeButton'>
              <button
                className="ChartTab"
              >
                <span className={styles.exchangetab}>
                  {widget.exchange}
                </span>
              </button>
            </a>
            <ReactTooltip
              id='exchangeButton'
              offset="{'top': -7}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            >
              <span>Change exchange</span>
            </ReactTooltip>
            {this.drawExchangeModal()}
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>
          </button>
        );
      case "chart":
        return (
          <div>
            <div
              className="ChartTitle "
            >
              <button
                className="ChartTab"

              ><a data-tip data-for='pairsButton'>
                <button
                    className={styles.titleCap}
                    onClick={() => toggleModal("markets", {
                      widgetId: widget.i,
                    })}
                  ><MarketName name={widget.name} />
                  </button>
                </a>
                <ReactTooltip
                  id='pairsButton'
                  offset="{'top': -7}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Change symbol</span>
                </ReactTooltip>
                <span className={styles.toolbardivider}>|</span>
                <a data-tip data-for='exchangeButton'>
                  <button
                    className={styles.titleCap}
                    onClick={() => toggleModal("exchanges", {
                      widgetId: widget.i,
                    })}
                  >{widget.exchange}
                  </button>
                </a>
                <ReactTooltip
                  id='exchangeButton'
                  offset="{'top': -7}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Change exchange</span>
                </ReactTooltip>
              </button>
              <button
                className="ChartTab"
              >{this.drawLinkColors()}&nbsp;
              </button>
            </div>

            <div className={styles.row}>
              <div className={styles.intervalbutton}>
                <div className={styles.searchIcon}>
                  <FontAwesome
                    prefix="far"
                    name="search"
                  />
                </div>
                <input
                  placeholder="Search Ticker..."
                />
              </div>

              <div className={styles.intervalbutton}>
                <button className={styles.intervalbutton1}>
                  {this.drawIntervalDropdown()}
                </button>
              </div>
              <a data-tip data-for='pointerButton'>
                <div className={styles.intervalbutton}>
                  <ButtonDropdown
                    className={styles.drawingpointersdropdown}
                    isOpen={this.state.drawingPointersDropdownSettingsDropdownOpen}
                    toggle={() => this.handleToggleDropdown("drawingPointersDropdownSettings")}
                  >
                    <DropdownToggle
                      type="button"
                      onClick={() => this.handleToggleDropdown("drawingPointersDropdownSettings")}
                    >
                      <img height={19} alt="menu" src="/img/icons/cross.svg" />
                    </DropdownToggle>
                    <DropdownMenu className={styles.drawingpointersdropdownshow}>
                      <DropdownItem className={styles.intervalbuttonsearch}>
                        <div className={styles.searchIcondropdown}>
                          <FontAwesome
                            prefix="far"
                            name="search"
                          />
                        </div><input className={styles.searchindicatordropdown} placeholder="Search..."/>
                      </DropdownItem>
                      <div className={styles.dropdownheight}>
                        {drawingpointers.map(({id, label, svg, title}) => (
                          <DropdownItem
                            className="widgetConnectItem"
                            key={id}
                            {...{ id }}
                          >
                            <span className={styles.drawingItemTitle}>{title}</span>
                            {svg}
                            {label}
                          </DropdownItem>))}
                      </div>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </a>
              <ReactTooltip
                id='pointerButton'
                offset="{'top': -8}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>Cross</span>
              </ReactTooltip>
              <a data-tip data-for='chartButton'>
                <div className={styles.intervalbutton}>
                  <ButtonDropdown
                    className="widgetConnectDropdown"
                    isOpen={this.state.chartTypeDropdownOpen}
                    toggle={() => this.handleToggleDropdown("chartType")}
                  >
                    <DropdownToggle
                      type="button"
                      onClick={() => this.handleToggleDropdown("chartType")}
                    >
                      <img height={19} alt="menu" src="/img/icons/chart.svg" />
                    </DropdownToggle>
                    <DropdownMenu className={styles.drawingmenudropdownshow}>
                      <DropdownItem className="dropdown-menu-markets-header" header>
                        <span className={styles.drawingItemTitle}>Chart Type</span>
                      </DropdownItem>
                      <DropdownItem className="widgetConnectItem">
                        <img height={19} alt="menu" src="/img/icons/chart.svg" />&nbsp;Candlestick
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </a>
              <ReactTooltip
                id='chartButton'
                offset="{'top': -8}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>Candlestick</span>
              </ReactTooltip>

              <div className={styles.intervalbutton}>
                <a data-tip data-for='toolsButton'>
                  <ButtonDropdown
                    className={styles.drawingtoolsdropdown}
                    isOpen={this.state.drawingTechnicalDropdownSettingsDropdownOpen}
                    toggle={() => this.handleToggleDropdown("drawingTechnicalDropdownSettings")}
                  >
                    <DropdownToggle
                      type="button"
                      onClick={() => this.handleToggleDropdown("drawingTechnicalDropdownSettings")}
                    >
                      <img height={19} alt="menu" src="/img/icons/fibretracement.svg" />
                    </DropdownToggle>
                    <DropdownMenu className={styles.drawingtechnicaldropdownshow}>
                      <DropdownItem className={styles.intervalbuttonsearch}>
                        <div className={styles.searchIcondropdown}>
                          <FontAwesome
                            prefix="far"
                            name="search"
                          />
                        </div><input className={styles.searchindicatordropdown} placeholder="Search..."/>
                      </DropdownItem>
                      <div className={styles.dropdownheight}>
                        {drawingtechnical.map(({id, label, svg, title}) => (
                          <DropdownItem className="widgetConnectItem" key={id} {...{ id }}>
                            <span className={styles.drawingItemTitle}>{title}</span>
                            {svg}
                            {label}
                          </DropdownItem>))}
                      </div>
                    </DropdownMenu>
                  </ButtonDropdown>
                </a>
                <ReactTooltip
                  id='toolsButton'
                  offset="{'top': -8}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Fibonacci retracement</span>
                </ReactTooltip>
              </div>
              <div className={styles.intervalbutton}>
                <a data-tip data-for='drawingButton'>
                  <ButtonDropdown
                    className={styles.drawingshapesdropdown}
                    isOpen={this.state.drawingShapesDropdownSettingsDropdownOpen}
                    toggle={() => this.handleToggleDropdown("drawingShapesDropdownSettings")}
                  >
                    <DropdownToggle
                      type="button"
                      onClick={() => this.handleToggleDropdown("drawingShapesDropdownSettings")}
                    >
                      <img height={19} alt="menu" src="/img/icons/draw.svg" />
                    </DropdownToggle>
                    <DropdownMenu className={styles.drawingshapesdropdownshow}>
                      <DropdownItem className={styles.intervalbuttonsearch}>
                        <div className={styles.searchIcondropdown}>
                          <FontAwesome
                            prefix="far"
                            name="search"
                          />
                        </div><input className={styles.searchindicatordropdown} placeholder="Search..."/>
                      </DropdownItem>
                      <div className={styles.dropdownheight}>
                        {drawingshapes.map(({id, label, points, svg, title}) => (
                          <DropdownItem
                            className="widgetConnectItem"
                            key={id}
                            {...{ id }}
                          //onClick={(e)=>this.handleDrawingItem(id, points)}
                          >
                            <span className={styles.drawingItemTitle}>{title}</span>
                            {svg}
                            {label}
                          </DropdownItem>))}
                      </div>
                    </DropdownMenu>
                  </ButtonDropdown>
                </a>
                <ReactTooltip
                  id='drawingButton'
                  offset="{'top': -8}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Freehand draw</span>
                </ReactTooltip>
              </div>
              <div className={styles.intervalbutton}>
                <a data-tip data-for='indicatorButton'>
                  <button
                    className={styles.Button}
                    onClick={() => {
                      toggleModal("widgetSettings");
                    }}
                  >
                    <img height={19} alt="menu" src="/img/icons/indicators.svg" />
                  </button>
                </a>
                <ReactTooltip
                  id='indicatorButton'
                  offset="{'top': -3}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Indicators</span>
                </ReactTooltip>
              </div>
              <div className={styles.intervalbutton}>
                <a data-tip data-for='patternsButton'>
                  <ButtonDropdown
                    className={styles.drawingtoolsdropdown}
                    isOpen={this.state.patternsSettingsDropdownOpen}
                    toggle={() => this.handleToggleDropdown("patternsSettings")}
                  >
                    <DropdownToggle
                      type="button"
                      onClick={() => this.handleToggleDropdown("patternsSettings")}
                    >
                      <img height={19} alt="menu" src="/img/icons/patterns.svg" />
                    </DropdownToggle>
                    <DropdownMenu className={styles.drawingtoolsdropdownshow}>
                      {/* Todo: Make sticky header & search*/}
                      <DropdownItem style={{ width: "280px"}} className="dropdown-menu-markets-header" header>
                        <span className={styles.drawingItemTitle}>Screener</span>
                      </DropdownItem>
                      <DropdownItem className={styles.intervalbuttonsearch}>
                        <div className={styles.searchIcondropdown}>
                          <FontAwesome
                            prefix="far"
                            name="search"
                          />
                        </div>
                        <input className={styles.searchindicatordropdown} placeholder="Search..."/>
                      </DropdownItem>
                      <div className={styles.dropdownheight}>
                        <DropdownItem className="dropdown-menu-markets-header" header>
                          <span className={styles.drawingItemTitle}>Divergence</span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/RSIRegularBullish.svg" />
                          <div className={styles.menuoverflow}>RSI Regular Bullish Divergence</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/RSIHiddenBullish.svg" />
                          <div className={styles.menuoverflow}>RSI Hidden Bullish Divergence</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/RSIRegularBearish.svg" />
                          <div className={styles.menuoverflow}>RSI Regular Bearish Divergence</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="dropdown-menu-markets-header" header>
                          <span className={styles.drawingItemTitle}>Patterns</span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/AscendingTriangle.svg" />
                          <div className={styles.menuoverflow}>Ascending Triangle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/DescendingTriangle.svg" />
                          <div className={styles.menuoverflow}>Descending Triangle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/BullishSymmetricTriangle.svg" />
                          <div className={styles.menuoverflow}>Bullish Symmetric Triangle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/BearishSymmetricTriangle.svg" />
                          <div className={styles.menuoverflow}>Bearish Symmetric Triangle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/CupandHandle.svg" />
                          <div className={styles.menuoverflow}>Cup and Handle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/InverseCupandHandle.svg" />
                          <div className={styles.menuoverflow}>InverseCupandHandle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/InverseCupandHandle.svg" />
                          <div className={styles.menuoverflow}>InverseCupandHandle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                        <DropdownItem className="widgetConnectItem">
                          <img height={19} alt="menu" src="/img/icons/InverseCupandHandle.svg" />
                          <div className={styles.menuoverflow}>InverseCupandHandle</div>
                          <span className={styles.rightIcon}>
                            <FontAwesome
                              prefix="far"
                              name="info-circle"
                            />
                          </span>
                        </DropdownItem>
                      </div>
                    </DropdownMenu>
                  </ButtonDropdown>
                </a>
                <ReactTooltip
                  id='patternsButton'
                  offset="{'top': -8}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Patterns</span>
                </ReactTooltip>
              </div>
              <div className={styles.intervalbutton}>
                <a data-tip data-for='layerButton'>
                  <ButtonDropdown
                    className="widgetConnectDropdown"
                    isOpen={this.state.layersSettingsDropdownOpen}
                    toggle={() => this.handleToggleDropdown("layersSettings")}
                  >
                    <DropdownToggle
                      type="button"
                      onClick={() => this.handleToggleDropdown("layersSettings")}
                    >
                      <img height={19} alt="menu" src="/img/icons/layers.svg" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="dropdown-menu-markets-header" header>
                        <span className={styles.drawingItemTitle}>Show/Hide Layers</span>
                      </DropdownItem>
                      <DropdownItem className="widgetConnectItem">
                        <img height={19} alt="menu" src="/img/icons/hideindicators.svg" />&nbsp;Hide Indicator Layers
                      </DropdownItem>
                      <DropdownItem className="widgetConnectItem">
                        <img height={19} alt="menu" src="/img/icons/hidedrawings.svg" />&nbsp;Hide Drawing Layers
                      </DropdownItem>
                      <DropdownItem className="widgetConnectItem">
                        <img height={19} alt="menu" src="/img/icons/hide.svg" />&nbsp;Hide All Layers
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </a>
                <ReactTooltip
                  id='layerButton'
                  offset="{'top': -8}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                >
                  <span>Layers</span>
                </ReactTooltip>
              </div>

              {/*<div className={styles.doublespan2}>
                  <span className={styles.spanexchangegroup}>
                    <MarketName
                      name={widget.name}
                      className={styles.LowercaseCoinPair}
                      handleOnClick={() => toggleModal("markets")}
                    />
                  </span>

                </div>

              <div className={styles.doublespan3}>
                <button
                  className="OverviewButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "OverviewChart",
                  })}
                >
                  Overview
                </button>
                <button
                  className="ChartButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "chart",
                  })}
                >
                  Chart
                </button>
                <button
                  className="NewsButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "feedChart",
                  })}
                >
                  News
                </button></div>*/}
            </div>
          </div>
        );
      case "history":
        return activeWidget && activeWidget.name ? (
          <div className="ChartTitle">
            <a data-tip data-for='pairsButton'>
              <button
                className="ChartTab"
                onClick={() => toggleModal("markets", {
                  widgetId: widget.i,
                })}
              >
                <MarketName
                  name={activeWidget.name}
                  handleOnClick={() => toggleModal("markets")}
                />
              </button>
            </a>
            <ReactTooltip
              id='pairsButton'
              offset="{'top': -7}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            />
            <button className="ChartTab2"><span className={styles.toolbardivider}>|</span>
              <a data-tip data-for='exchangeButton'>
                <button className={styles.titleCap}>
                  {activeWidget.exchange}
                </button>
              </a>
              <ReactTooltip
                id='exchangeButton'
                offset="{'top': -7}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              />
              <button
                className="ChartTab"
              >{this.drawLinkColors()}
              </button>
            </button>
          </div>
        ) : (
          <div className="ChartTitle">
            Trade History
          </div>
        );
      case "openpositions":
        return (
          <div className="ChartTitle">
            <span className="ChartTab"> OPEN POSITIONS</span>
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>
          </div>
        );
      case "balancesummary":
        return (
          <div className="ChartTitle">
            <span className="ChartTab"> BALANCE SUMMARY</span>
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>
          </div>
        );
      case "ordershistory":
        return (
          <div className="ChartTitle">
            <span className="ChartTab"> ORDERS HISTORY</span>
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>
          </div>
        );
      case "statistics":
        return (
          <div className="ChartTitle">
            <span className="ChartTab"> STATISTICS</span>
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>
          </div>
        );
      case "workingorders":
        return (
          <div className="ChartTitle">
            <span className="ChartTab"> WORKING ORDERS</span>
            {this.drawLinkColors()}
          </div>
        );
      case "dom":
        return activeWidget && activeWidget.name ? (
          <div className="ChartTitle">
            <a data-tip data-for='pairsButton'>
              <button
                className="ChartTab"
                onClick={() => toggleModal("markets", {
                  widgetId: widget.i,
                })}
              >
                <MarketName
                  name={activeWidget.name}
                  handleOnClick={() => toggleModal("markets")}
                />
              </button>
            </a>
            <ReactTooltip
              id='pairsButton'
              offset="{'top': -7}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            />
            <button className="ChartTab2"><span className={styles.toolbardivider}>|</span>
              <a data-tip data-for='exchangeButton'>
                <button className={styles.titleCap}>
                  {activeWidget.exchange}
                </button>
              </a>
              <ReactTooltip
                id='exchangeButton'
                offset="{'top': -7}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              />
              <button
                className="ChartTab"
              >{this.drawLinkColors()}
              </button>
            </button>
          </div>
        ) : (
          <div className="ChartTitle">
            Orderbook
          </div>
        );
      case "domChart":
        return activeWidget && activeWidget.name ? (
          <div className="ChartTitle">
            <MarketName name={activeWidget.name}/>
            {", "}
            <img
              alt=""
              height={13}
              src={`/img/exchanges/${activeWidget.exchange.toLowerCase()}.png`}
            />
            {activeWidget.exchange}
            {this.drawLinkColors()}
          </div>
        ) : (
          <div className="ChartTitle">
            Orderbook Chart
          </div>
        );
      case "trade":
        return (
          <div className="ChartTitle">
            {widget.name ? (

              <div
                className={styles.Header}
                toggle={() => toggleModal("TradeWidget")}
              >
                Trades <span>& Orders</span>
              </div>

            ) : (
              <div className="buttons">
                <button onClick={() => toggleModal("markets")}>
                  Select Market
                </button>
              </div>
            )}
          </div>
        );
      case "sentiment":
        const targetMarket = widget.name ? widget.name.split("-")[1] : "";
        return (
          <div
            className="ChartTitle "
          >
            <button
              className="ChartTab"

            ><a data-tip data-for='pairsButton'>
              <button
                  className={styles.titleCap}
                  onClick={() => toggleModal("markets", {
                    widgetId: widget.i,
                  })}
                ><MarketName name={widget.name} />
                </button>
              </a>
              <ReactTooltip
                id='pairsButton'
                offset="{'top': -7}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>Change symbol</span>
              </ReactTooltip>
              <span className={styles.toolbardivider}>|</span>
              <a data-tip data-for='exchangeButton'>
                <button
                  className={styles.titleCap}
                  onClick={() => toggleModal("exchanges", {
                    widgetId: widget.i,
                  })}
                >{widget.exchange}
                </button>
              </a>
              <ReactTooltip
                id='exchangeButton'
                offset="{'top': -7}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>Change exchange</span>
              </ReactTooltip>
            </button>
            <button
              className="ChartTab"
            >{this.drawLinkColors()}&nbsp;
            </button>
          </div>
        );
      case "markets":

        const {filterMarkets} = this.props;
        return (
          <div>
            <div className="ChartTitle">
              <button
                className="ChartTab"
                onClick={() => toggleModal("exchanges", {
                  widgetId: widget.i,
                })}
              >
                <span className="titleWhite">&nbsp;&nbsp;EXCHANGE&nbsp;
                </span>
                <a data-tip data-for='exchangeButton'>
                  <button className={styles.titleCap}>
                    <span className={styles.toolbardivider}>|</span>{widget.exchange}
                  </button>
                </a>
                <ReactTooltip
                  id='exchangeButton'
                  offset="{'top': -7}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                />
                &nbsp;{this.drawLinkColors()}&nbsp;
              </button>
            </div>

            {this.drawExchangeModal()}
            <div className={styles.row}>
              <div className="exchangeSearch">
                <FontAwesome
                  prefix="far"
                  name="search"
                />&nbsp;
                <input
                  placeholder="Filter Ticker..."
                  onChange={(event) => filterMarkets(event.target.value)}
                />

              </div>
              <div className={styles.intervalbuttonmarkets}>
                <button>
                  {this.drawIntervalDropdown()}
                </button>
              </div>
            </div>
          </div>
        );
      case "treemap":
        return (
          <div className="ChartTitle">
            <button
              className="ChartTab"
              onClick={() => toggleModal("exchanges", {
                widgetId: widget.i,
              })}
            ><span
              className="titleWhite"
              style={{paddingLeft: 3}}
              >HEATMAP</span>

            </button>
            <span className={styles.toolbardivider}>|</span>
            <a data-tip data-for='exchangeButton'>
              <button
                className={styles.titleCap}
                style={{paddingRight: 0}}
                onClick={() => toggleModal("exchanges", {
                  widgetId: widget.i,
                })}
              >
                {widget.exchange}
              </button>
            </a>
            <ReactTooltip
              id='exchangeButton'
              offset="{'top': -7}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            />
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>

          </div>
        );
      case "bubble":
        return (
          <div className="ChartTitle">
            <button
              className="ChartTab"
              onClick={() => toggleModal("exchanges", {
                widgetId: widget.i,
              })}
            ><span
              className="titleWhite"
              style={{paddingLeft: 3}}
              >BUBBLES</span>

            </button>
            <span className={styles.toolbardivider}>|</span>
            <a data-tip data-for='exchangeButton'>
              <button
                className={styles.titleCap}
                style={{paddingRight: 0}}
                onClick={() => toggleModal("exchanges", {
                  widgetId: widget.i,
                })}
              >
                {widget.exchange}
              </button>
            </a>
            <ReactTooltip
              id='exchangeButton'
              offset="{'top': -7}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            />
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>

          </div>
        );
      case "defiscanner":
        return (
          <div className="ChartTitle">
            <button
              className="ChartTab"
              onClick={() => toggleModal("exchanges", {
                widgetId: widget.i,
              })}
            ><span
              className={styles.titleCap}
              style={{paddingLeft: 3}}
              >DEFI SCREENER</span>

            </button>
            <span className={styles.toolbardivider}>|</span>
            <a data-tip data-for='exchangeButton'>
              <button
                className={styles.titleCap}
                style={{paddingRight: 0}}
                onClick={() => toggleModal("exchanges", {
                  widgetId: widget.i,
                })}
              >
                Uniswap v2
              </button>
            </a>
            <ReactTooltip
              id='exchangeButton'
              offset="{'top': -7}"
              arrowColor='transparent'
              className={styles.buttonTooltip}
              effect='solid'
            />
            <button
              className="ChartTab"
            >{this.drawLinkColors()}
            </button>

          </div>
        );
      case "price":
        return (
          <div>
            <div className="ChartTitlePrice">
              <span
                className="ChartTab"
                onClick={() => toggleModal("markets", {
                  widgetId: widget.i,
                })}
              >
                <a data-tip data-for='pairsButton'>
                  <button
                    className="titleWhite"
                    style={{paddingRight: 0}}
                  >
                    <MarketName
                      name={widget.name}
                      handleOnClick={() => toggleModal("markets")}
                    />
                  </button>
                </a>
                <ReactTooltip
                  id='pairsButton'
                  offset="{'top': -7}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                />
                <span className={styles.toolbardivider}>|</span>
                <a data-tip data-for='exchangeButton'>
                  <button
                    className={styles.titleCap}
                  >{widget.exchange}
                  </button>
                </a>
                <ReactTooltip
                  id='exchangeButton'
                  offset="{'top': -7}"
                  arrowColor='transparent'
                  className={styles.buttonTooltip}
                  effect='solid'
                />
                &nbsp;{this.drawLinkColors()}&nbsp;

              </span>
            </div>
            <div className={styles.intervalbutton}>
              <div className={styles.searchIcon}>
                <FontAwesome
                  prefix="far"
                  name="search"
                />
              </div>
              <input placeholder="Search..." />
            </div>
            <div className={styles.intervalbutton}>
              <button>
                {this.drawIntervalDropdown()}&nbsp;<FontAwesome
                  prefix="fas"
                  name="angle-down"
                />
              </button>
            </div>
          </div>


        );
      case "feed":
        return (
          <div className="ChartTitle">
            <span className="ChartTab">NEWSFEED
              <button
                className="ChartTab"
              >{this.drawLinkColors()}
              </button>
            </span>
          </div>
        );

      case "OverviewChart":
        return (
          <div>
            <div
              className="ChartTitle "
            >
              <button
                className="ChartTab"
                onClick={() => toggleModal("markets", {
                  widgetId: widget.i,
                })}
              >
                <MarketName
                  name={widget.name}
                  handleOnClick={() => toggleModal("markets")}
                />
              </button>
              <button className="ChartTab2"><span className={styles.toolbardivider}>|</span>
                <span className="titleCap">{widget.exchange}
                </span>,&nbsp;<span>{this.drawIntervalDropdown()}</span>
                {this.drawLinkColors()}
              </button>
            </div>

            <div className={styles.row}>
              <div className={styles.image}>
                <img
                  alt={widget.name.split("-")[1].toUpperCase()}
                  src={`/img/symbols/${widget.name.split("-")[1].toUpperCase()}.png`}
                />
              </div>
              <div className={styles.double}>
                <span className={styles.doublespan1}>Bitcoin</span>
              </div>
              <div className={styles.doublespan3}>
                <button
                  className="OverviewButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "OverviewChart",
                  })}
                >
                  Overview
                </button>
                <button
                  className="ChartButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "chart",
                  })}
                >
                  Chart
                </button>
                <button
                  className="NewsButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "feedChart",
                  })}
                >
                  News
                </button></div>
            </div>
          </div>
        );
      case "feedChart":
        return (
          <div>
            <div
              className="ChartTitle "
            >
              <button
                className="ChartTab"
                onClick={() => toggleModal("markets", {
                  widgetId: widget.i,
                })}
              >
                <MarketName
                  name={widget.name}
                  handleOnClick={() => toggleModal("markets")}
                />
              </button>
              <button className="ChartTab2"><span className={styles.toolbardivider}>|</span>
                <span className="titleCap">{widget.exchange}
                </span>,&nbsp;<span>{this.drawIntervalDropdown()}</span>
                {this.drawLinkColors()}
              </button>
            </div>

            <div className={styles.row}>
              <div className={styles.image}>
                <img
                  alt={widget.name.split("-")[1].toUpperCase()}
                  src={`/img/symbols/${widget.name.split("-")[1].toUpperCase()}.png`}
                />
              </div>
              <div className={styles.double}>
                <span className={styles.doublespan1}>Bitcoin</span>
              </div>
              <div className={styles.doublespan3}>
                <button
                  className="OverviewButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "OverviewChart",
                  })}
                >
                  Overview
                </button>
                <button
                  className="ChartButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "chart",
                  })}
                >
                  Chart
                </button>
                <button
                  className="NewsButton"
                  onClick={() => updateWidget({
                    ...widget,
                    type: "feedChart",
                  })}
                >
                  News
                </button></div>
            </div>
          </div>
        );
      case "chat":
        return (
          <div className="ChartTitle">
            Chat
          </div>
        );
      default:
        return null;
    }
  };
  handleCustomInterval = (value) => {
    if (value < 10000) {
      this.setState({
        customIntervalMin: value
      });
    }
  }

  setCustomInterval = () => {
    const {widget, updateWidget} = this.props;
    const {customIntervalMin, customIntervalTF} = this.state;
    const customInterval = customIntervals.find((item) => item.value == customIntervalTF);

    const totalMin = customInterval.min * customIntervalMin;

    const availableTF = widget.availableTF;
    const availableIntervals = INTERVALS.filter((interval) => availableTF.indexOf(interval.id) > -1);
    const newBasePeriod = availableIntervals.reverse().find((interval) => totalMin % interval.min == 0);

    const period = customIntervalMin + customInterval.short;  //1m, 1h, 1d,...
    const basePeriod = {
      from: {value: newBasePeriod.value, min: newBasePeriod.min},
      to: {value: period, min: totalMin},
    };
    updateWidget({
      ...widget,
      period,
      basePeriod,
    });
    this.handleToggleDropdown("interval");
  }
  handleToggleDropdown = (type) => {
    let data = null;
    switch (type) {
      case "interval":
        data = {
          intervalDropdownOpen: !this.state.intervalDropdownOpen,
        };
        break;
      case "customInterval":
        data = {
          customIntervalDropdownOpen: !this.state.customIntervalDropdownOpen,
        };
        break;
      case "settings":
        data = {
          settingsDropdownOpen: !this.state.settingsDropdownOpen,
        };
        break;
      case "widgetConnectSettings":
        data = {
          widgetConnectSettingsDropdownOpen: !this.state.widgetConnectSettingsDropdownOpen,
        };
        break;
      case "drawingToolsDropdownSettings":
        data = {
          drawingToolsDropdownSettingsDropdownOpen: !this.state.drawingToolsDropdownSettingsDropdownOpen,
        };
        break;
      case "drawingTechnicalDropdownSettings":
        data = {
          drawingTechnicalDropdownSettingsDropdownOpen: !this.state.drawingTechnicalDropdownSettingsDropdownOpen,
        };
        break;
      case "drawingPointersDropdownSettings":
        data = {
          drawingPointersDropdownSettingsDropdownOpen: !this.state.drawingPointersDropdownSettingsDropdownOpen,
        };
        break;
      case "drawingShapesDropdownSettings":
        data = {
          drawingShapesDropdownSettingsDropdownOpen: !this.state.drawingShapesDropdownSettingsDropdownOpen,
        };
        break;
      case "chartType":
        data = {
          chartTypeDropdownOpen: !this.state.chartTypeDropdownOpen,
        };
        break;
      case "indicatorsSettings":
        data = {
          indicatorsSettingsDropdownOpen: !this.state.indicatorsSettingsDropdownOpen,
        };
        break;
      case "patternsSettings":
        data = {
          patternsSettingsDropdownOpen: !this.state.patternsSettingsDropdownOpen,
        };
        break;
      case "layersSettings":
        data = {
          layersSettingsDropdownOpen: !this.state.layersSettingsDropdownOpen,
        };
        break;
      case "exchange":
        data = {
          exchangeDropdownOpen: !this.state.exchangeDropdownOpen,
        };
        break;
      case "chat":
        data = {
          chatDropdownOpen: !this.state.chatDropdownOpen,
        };
        break;
      default:
        break;
    }

    data && this.setState(data);
  };

  drawChartSettings = () => {
    const {toggleModal, user} = this.props;
    if (!user) {
      return "";
    }
    return (

      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right">

          <DropdownItem
            className="dropItem"
            disabled={!user.isPaid}
            onClick={() => user.isPaid && toggleModal("chartSettings")}
          >
            <FontAwesome
              className="buttonItem"
              name="cog"
              prefix="fas"
            />Settings
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  drawScannerSettings = () => {
    const {toggleModal, user} = this.props;
    if (!user) {
      return "";
    }
    return (

      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Columns
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked
              onChange={()=>null}
            /> Token/Pair
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked
              onChange={()=>null}
            /> Sentiment
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked
              onChange={()=>null}
            /> Interval
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked
              onChange={()=>null}
            /> Type
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked
              onChange={()=>null}
            /> Result
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className="checkbox"
              checked
              onChange={()=>null}
            /> Age
          </DropdownItem>
          <DropdownItem className="dropdown-menu-markets-header" header>
            Other
          </DropdownItem>
          <DropdownItem
            className="dropItem"
            disabled={!user.isPaid}
            onClick={() => user.isPaid && toggleModal("widgetScannerSettingsModal")}
          >
            <FontAwesome
              className="buttonItem"
              name="cog"
              prefix="fas"
            /> Settings
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  drawFeedSettings = () => {
    const {toggleModal} = this.props;

    return (

      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >
        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right">
          <DropdownItem
            className="dropItem"
            onClick={() => toggleModal("feedFilter")}
          >
            <FontAwesome
              className="buttonItem"
              name="cog"
              prefix="fas"
            /> Settings
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  drawDomChartSettings = () => {
    const {toggleModal, widget} = this.props;
    return (

      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right">
          <DropdownItem onClick={() => toggleModal("domChartSettings", {id: widget.i})}>
            <FontAwesome
              name="cog"
              prefix="fas"
            /> Settings
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  drawPriceSettings = () => {

    return (
      <ButtonDropdown
        className="tabBars"
        style={{marginRight: 5}}
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Columns
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}
            /> High
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}

            /> Low
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              style={{backgroundColor: "#ccc"}}
              checked
              onChange={()=>null}

            /> Bid
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}

            /> Ask
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  drawBubbleSettings = () => {

    return (
      <ButtonDropdown
        className="tabBars"
        //style={{marginRight: 5}}
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Bubble Type
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}
            /> Change (%)
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              onChange={()=>null}

            /> Magnitude
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              onChange={()=>null}

            /> Ranking
          </DropdownItem>

        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  drawMarketSettings = () => {

    return (
      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Columns
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}
            /> Ticker
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}
            /> Token/Pair
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}

            /> Price
          </DropdownItem>
          {/*<DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              style={{backgroundColor: "#ccc"}}
              checked={false}
              onChange={()=>null}

            /> Change
          </DropdownItem>*/}
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}

            /> Change (%)
          </DropdownItem>
          {/*<DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Volume
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Bid
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Ask
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Spread
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Spread (%)
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Open
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> High
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Low
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked={false}
              onChange={()=>null}

            /> Close
          </DropdownItem>*/}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  drawHeatmapSettings = () => {

    return (
      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Heatmap Types
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              onChange={()=>null}
            /> Change (%)
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
              onChange={()=>null}
            /> Magnitude
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              onChange={()=>null}
            /> Ranking
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  drawSentimentSettings = () => {

    return (
      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Types
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <FontAwesome
              prefix="fas"
              name="rss"
            /> News
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <FontAwesome
              prefix="fab"
              name="twitter"
            /> Twitter
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  drawHistorySettings = () => {

    return (
      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Columns
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Time
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Type
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Price
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Amount
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              unchecked
            /> Imbalance
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              unchecked
            /> Buy
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              unchecked
            /> Sell
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  drawDomSettings = () => {

    return (
      <ButtonDropdown
        className="tabBars"
        isOpen={this.state.settingsDropdownOpen}
        toggle={() => this.handleToggleDropdown("settings")}
      >

        <DropdownToggle
          type="button"
          onClick={() => this.handleToggleDropdown("settings")}
          className="btn-group"
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-markets">
          <DropdownItem className="dropdown-menu-markets-header" header>
            Columns
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Bid
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Price
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Ask
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              checked
            /> Total
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              unchecked
            /> Volume
          </DropdownItem>
          <DropdownItem
            className={classNames("dropItem")}
          >
            <input
              type="checkbox"
              className={classNames("checkbox")}
              unchecked
            /> Trades
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  drawChatSettings = () => {
    const {channelObject, leaveChannelAction, toggleModal, deleteChannelAction, user} = this.props;
    if (!user) {
      return "";
    }
    return (

      <ButtonDropdown
        style={{width: 28}}
        isOpen={this.state.chatDropdownOpen}
        toggle={() => this.handleToggleDropdown("chat")}
      >
        <button
          type="button"
          onClick={() => this.handleToggleDropdown("chat")}
        >
          <img className="Icon" alt="menu" src="/img/icons/bars.svg" />
        </button>
        <DropdownMenu className="dropdown-menu-right">
          {channelObject.type !== "public" && (channelObject.type === "direct" || channelObject.user === user.id) && (
            <DropdownItem onClick={() => deleteChannelAction(channelObject._id)}>
              <FontAwesome
                name="trash-alt"
                prefix="fas"
              /> Delete Channel
            </DropdownItem>
          )}
          {(channelObject.type === "public" || (channelObject.type === "private" && channelObject.user !== user.id)) && (
            <DropdownItem onClick={() => leaveChannelAction(channelObject._id)}>
              <FontAwesome
                name="sign-out-alt"
                prefix="fas"
              /> Leave Channel
            </DropdownItem>
          )}
          {(channelObject.type === "team" || (channelObject.type === "private" && channelObject.user !== user.id)) && (
            <DropdownItem onClick={() => toggleModal("chat", channelObject)}>
              <FontAwesome
                name="cog"
                prefix="fas"
              /> Channel Settings
            </DropdownItem>
          )}
        </DropdownMenu>
      </ButtonDropdown>

    );
  };

  render() {
    const {widget, removeWidget} = this.props;
    if (widget.exchange == "poloniex" && this.state.customIntervalTF == "minutes") {
      this.setState({customIntervalTF: "hours"});
    }
    return (
      <div className={classNames("app-chart-toolbar", {"withSearch": widget.type === "markets"})}>
        <div className={classNames("tools", {
          chartTools: widget.type === "markets",

        })}
        >
          {this.drawWidgetName()}
        </div>
        <div className="buttons disableDrag">

          {widget.type === "scanner" && (
            this.drawScannerSettings()
          )}
          {widget.type === "chart" && (
            this.drawChartSettings()
          )}
          {widget.type === "feed" && (
            this.drawFeedSettings()
          )}
          {widget.type === "feedChart" && (
            this.drawFeedSettings()
          )}
          {widget.type === "domChart" && (
            this.drawDomChartSettings()
          )}
          {widget.type === "price" && (
            this.drawPriceSettings()
          )}
          {widget.type === "chat" && (
            this.drawChatSettings()
          )}
          {widget.type === "markets" && (
            this.drawMarketSettings()
          )}
          {widget.type === "bubble" && (
            this.drawBubbleSettings()
          )}
          {widget.type === "dom" && (
            this.drawDomSettings()
          )}
          {widget.type === "treemap" && (
            this.drawHeatmapSettings()
          )}
          {widget.type === "history" && (
            this.drawHistorySettings()
          )}
          {widget.type === "sentiment" && (
            this.drawSentimentSettings()
          )}
          {!widget.unpinned && widget.type && ["sentiment", "price"].indexOf(widget.type) < 0 && (
            <button
              type="button"
              className="tabExpand"
              onClick={() => this.toggleWidgetSize()}
            >
              {widget.expanded ?
                <img className="Icon" alt="minimize" src="/img/icons/minimize.svg" /> :
                <img className="Icon" alt="maxamize" src="/img/icons/maxamize.svg" />
              }
            </button>
          )}
          <button
            type="button"
            className="tabClose"
            onClick={(event) => {
              event.stopPropagation();
              removeWidget(widget.i);
            }}
          >
            <img className="Icon" alt="close" src="/img/icons/close.svg" />
          </button>
        </div>
      </div>
    );
  }
}

WidgetToolbar.propTypes = {
  activeWidget: PropTypes.object,
  channelObject: PropTypes.object,
  deleteChannelAction: PropTypes.func,
  filterMarkets: PropTypes.func,
  height: PropTypes.number,
  leaveChannelAction: PropTypes.func,
  removeWidget: PropTypes.func,
  saveImageAction: PropTypes.func,
  toggleModal: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  user: PropTypes.object,
  widget: PropTypes.object,
};

WidgetToolbar.defaultProps = {
  activeWidget: undefined,
  channelObject: undefined,
  deleteChannelAction: () => false,
  filterMarkets: () => false,
  height: 0,
  leaveChannelAction: () => false,
  removeWidget: () => false,
  saveImageAction: () => false,
  toggleModal: () => false,
  user: undefined,
  widget: {},
};

export default WidgetToolbar;
