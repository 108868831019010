import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import styles from './Watermark.scss';
import MarketName from "../../../../../components/MarketName";
class Watermark extends PureComponent {
  constructor() {
    super();

    this.state = {
    };
  }
  render() {
    const {widget} = this.props;
    const test = widget.name ? Math.round(widget.w * 3) * 10 : Math.round(widget.w * 3);
    //console.log(widget);
    //debugger;
    return (
      <div className={styles.watermark}>
        <div>
          <span
            style={{fontSize: `${test}`}}
            className={styles.tickerlabel}
          >
            <MarketName name={widget.name}/>
          </span>
        </div>
        <div>
          <span className={styles.longlabel}>
            Bitcoin / Tether
          </span>
        </div>
      </div>

    );
  }
}

Watermark.propTypes = {
  widget: PropTypes.object,
};

Watermark.defaultProps = {
  widget: {},
};

export default Watermark;
