import {
  GET_BALANCE_HISTORY,
  GET_BALANCE_HISTORY_ERROR,
  GET_BALANCE_HISTORY_SUCCESS,
} from "../const";

const initialState = {
  balance: {
    loading: false,
    data: [],
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE_HISTORY:
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: true,
          error: null,
        },
      };
    case GET_BALANCE_HISTORY_SUCCESS:
      return {
        ...state,
        balance: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case GET_BALANCE_HISTORY_ERROR:
      return {
        ...state,
        balance: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
