import {api} from "../utils/apiUtils";
import axios from "axios";
const marketApi=axios.create({url: '/markets-api'});
const historyApi=axios.create({url: '/history-api'});

const getExchangesList = () => marketApi.get("/markets-api/");
const getExchangeMarkets = (exchange) => api.get(`market/list/${exchange}`);
const getMarketsList = (params) => api.get("market/list", params);
const getMarketsInfo = () => api.get("market/markets-info");
const getMarketSummary = (widgetId, market, exchange) =>
  api.get(`market/marketsummary/${widgetId}/${market}/${exchange}`);
const getChartIntervals = (params) => api.get("market/getChartInterval", params);
const getOHLCVHistory = (params) => historyApi.post("/history-api/ohlcvHistory", params);
export const getMarketExchanges = (market) => marketApi.get(`/markets-api/exchanges/${market}`);
export const getMarketQuotes = (market) => marketApi.get(`/markets-api/quotes/${market}`);

export {
  getChartIntervals,
  getExchangesList,
  getExchangeMarkets,
  getMarketsInfo,
  getMarketsList,
  getMarketSummary,
  getOHLCVHistory,
};
