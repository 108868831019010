import {connect} from "react-redux";
import IndicatorSettingsModal from "../components/IndicatorSettingsModal";
import {toggleModal, updateWidget} from "../../../actions/MarketsActions";
import { updateIndicatorTemplate } from "../../../actions/UserAction";
export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const indicatorTemplates = state.user.indicatorTemplates;
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;

      return {
        isOpen: state.markets.modals.indicatorSettings,
        indicator: state.markets.modalData ? state.markets.modalData.indicator : null,
        drawList: state.markets.modalData ? state.markets.modalData.drawList : null,
        widget: activeWidget,
        indicatorTemplates
      };
    }, {
      toggleModal,
      updateWidget,
      updateIndicatorTemplate
    },
)(IndicatorSettingsModal);
