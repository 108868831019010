import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import ActiveOrderItem from "./ActiveOrderItem";
import OrderModal from "./OrderModal";
import {Helmet} from "react-helmet";
import styles from "./OrdersPage.scss";

class OrdersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      selectedAccount: null,
    };
    this.interval = null;
  }

  componentDidMount() {
    const {fetchAccounts, fetchOrdersList} = this.props;
    fetchAccounts();
    fetchOrdersList();
    this.interval = setInterval(fetchOrdersList, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleToggleModal = (accountName) => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      selectedAccount: accountName ? this.props.accounts.find((account) => account.name === accountName) : null,
    });
  };

  render() {
    const {orders, renewOrderAction, setRedoAction, cancelOrderAction, ordersLoading} = this.props;
    const {modalOpen, selectedAccount} = this.state;
    return (
      <PerfectScrollbar options={{suppressScrollX: true}}>
        <Helmet>
          <title>Orders</title>
        </Helmet>
        <div className={classNames(styles.OrdersPage, "ordersPage")}>
          {orders.map((account) => (
            <ActiveOrderItem
              cancelOrderAction={cancelOrderAction}
              account={account}
              loading={ordersLoading}
              onHandleToggleModal={this.handleToggleModal}
              key={`${account.name}-${account.exchange}`}
              renewOrderAction={renewOrderAction}
            />
          ))}

          <OrderModal
            isOpen={modalOpen}
            account={selectedAccount}
            onToggleModal={this.handleToggleModal}
            renewOrderAction={renewOrderAction}
            setRedoAction={setRedoAction}
          />
        </div>
      </PerfectScrollbar>
    );
  }
}

OrdersPage.propTypes = {
  accounts: PropTypes.array,
  cancelOrderAction: PropTypes.func,
  fetchAccounts: PropTypes.func,
  fetchOrdersList: PropTypes.func,
  orders: PropTypes.array,
  ordersLoading: PropTypes.bool,
  renewOrderAction: PropTypes.func,
  setRedoAction: PropTypes.func,
};

OrdersPage.defaultProps = {
  accounts: [],
  cancelOrderAction: () => false,
  fetchAccounts: () => false,
  fetchOrdersList: () => false,
  orders: [],
  ordersLoading: false,
  renewOrderAction: () => false,
  setRedoAction: () => false,
};

export default OrdersPage;
