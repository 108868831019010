import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Block.scss";

const Block = (props) => {
  return (
    <div
      className={classNames("Block", {["Right"]: props.right})}
      style={{width: `${props.col * 10}%`}}
    >
      <div className="BlockBorder">
        <div className="ToolBar">
          {props.toolbar}
        </div>
        <div
          className={classNames("Content", props.contentClassName, {
            ["Opened"]: props.showContent,
          })}
        >
          {props.content}
        </div>
      </div>
    </div>
  );
};

Block.propTypes = {
  col: PropTypes.number,
  content: PropTypes.any,
  contentClassName: PropTypes.string,
  right: PropTypes.bool,
  showContent: PropTypes.bool,
  toolbar: PropTypes.any,
};

Block.defaultProps = {
  col: 10,
  content: null,
  contentClassName: undefined,
  right: false,
  showContent: true,
  toolbar: null,
};

export default Block;
