import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Nav, ModalHeader, NavItem, TabContent, TabPane} from "reactstrap";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import OrderSettings from "./OrderSettings";
import Orders from "./Orders";
import styles from "./TradeWidget.scss";

import "./TradeWidget.scss";

const tabs = [
  {label: "Trade", value: "settings"},
  {label: "Orders", value: "orders"},
];

class TradeWidget extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: tabs[0].value,
    };
  }

  componentDidMount() {
    const {widget, getMarketSummaryAction} = this.props;
    widget && widget.name && widget.exchange && getMarketSummaryAction(widget.i, widget.name, widget.exchange);
  }

  componentDidUpdate(prevProps) {
    const {widget, getMarketSummaryAction} = this.props;
    if (!prevProps.widget && widget ||
      (widget && (prevProps.widget.name !== widget.name))) {
      getMarketSummaryAction(widget.i, widget.name, widget.exchange);
    }
  }

  drawTabContent = () => {
    switch (this.state.activeTab) {
      case "orders":
        return (<Orders {...this.props} />);
      case "settings":
        return (<OrderSettings {...this.props} />);
    }
  };

  toggle = (tab) => {
    this.setState({activeTab: tab});
  };

  render() {
    const {widget, setWidgetActive, toggleModal} = this.props;
    return (
      <div
        className={classNames("widgetWrapper", {
          "Active": widget.active && !widget.unpinned,
          "Unpinned": widget.unpinned,
        })}
        onClick={() => !widget.active && setWidgetActive(widget.i)}
        role="button"
        tabIndex="0"
      >
        <WidgetToolbar
          {...this.props}
        />

        <div className="TradeWidget disableDrag">
          <Nav tabs>
            {tabs.map((tab) => (
              <NavItem key={tab.value} />
            ))}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {tabs.map((tab) => (
              <TabPane tabId={tab.value} key={tab.value}>
                {this.drawTabContent()}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    );
  }
}

TradeWidget.propTypes = {
  getMarketSummaryAction: PropTypes.func,
  setWidgetActive: PropTypes.func,
  widget: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
};

TradeWidget.defaultProps = {
  getMarketSummaryAction: () => false,
  setWidgetActive: () => false,
  widget: {},
  isOpen: false,
  toggleModal: () => false,
};

export default TradeWidget;
