/* eslint react/no-array-index-key: 0 */
/* eslint no-unused-vars: 0 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Form, FormGroup, Label, Row, Col, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink,
  TabContent, TabPane, Dropdown, DropdownItem, ButtonDropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, Button,
} from "reactstrap";
import FontAwesome from "../../../components/FontAwesome";
import InputColor from "react-input-color";
import { SketchPicker } from 'react-color';
import {uid} from "uid";
import MonacoEditor from "react-monaco-editor";
import { monacoSetting } from "./ChartWidget/Indicator/monacoSetting";
import { hfillDef, plotDef, fillDef, hlineDef, areaDef, vp, supdemzone } from "./ChartWidget/Indicator/defaults";
import CustomButton from "../../../components/Button";
import styles from "./IndicatorSettingsModal.scss";
import Editor from "@monaco-editor/react";

const { createNewIndicator, getUpdatedSettings } = require('./ChartWidget/Indicator/parser');
const strokeTypes = ["Solid", "ShortDash", "ShortDash2", "ShortDot", "ShortDashDot", "ShortDashDotDot", "Dot",
  "Dash", "LongDash", "DashDot", "LongDashDot", "LongDashDotDot", "Histogram", "Area", "Circle"];

class IndicatorSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.updateNumberTimeout = null;
    this.timeout = null;

    this.state = {
      activeTab: "1",
      code: "",
      indicatorOptions: undefined,
      updatedDrawingStyles: undefined,
      drawinglineDropdownOpen: false,
      dragStartPos: []
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen, indicator } = this.props;
    if (indicator && (prevProps.indicator == null || (prevProps.indicator.options != indicator.options))) {
      this.setState({
        indicatorOptions: indicator.options
      });
    }
    if (indicator &&
      (prevProps.indicator == null || (prevProps.indicator.updatedDrawingStyles != indicator.updatedDrawingStyles))) {
      this.setState({
        updatedDrawingStyles: indicator.updatedDrawingStyles
      });
    }
    if (prevProps.isOpen && !isOpen) {
      const newState = {};
      Object.keys(this.state).forEach((param) => newState[param] = param === "activeTab" ? "1" : undefined);
      this.setState(newState);
    }
    if (!prevProps.isOpen && isOpen) {
      const paramInputs = Object.keys(indicator.options)
          .filter((key) => indicator.options[key].type !== "color");

      this.setState({
        code: indicator.options.code
      });

      if (indicator.options.percents) {
        this.setState({
          numbers: indicator.options.percents.value.sort((a, b) => b - a).map((value) => ({
            id: uid(10),
            value: value,
          })),
        });
      }
    }
  }

  handleCodeChange = (value) => {
    this.setState({
      code: value,
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
  getDrawListUpdatedStyle = (drawOption, drawIdx) => {
    const type = drawOption.type;
    const { indicator: { uid } } = this.props;
    const { updatedDrawingStyles } = this.state;
    const savedStyle = updatedDrawingStyles && updatedDrawingStyles[drawIdx];
    if (type == 'plot') {
      const strokeTypeKey = "" + uid + '_' + drawIdx + '_plot_strokeType';
      const strokeWidthKey = "" + uid + '_' + drawIdx + '_plot_strokeWidth';
      const strokeLabelKey = "" + uid + '_' + drawIdx + '_plot_strokeLabel';
      const strokeType = this.state[strokeTypeKey] ||
        (savedStyle && savedStyle.strokeType) || drawOption.style.strokeType;
      const strokeWidth = this.state[strokeWidthKey] ||
        (savedStyle && savedStyle.strokeWidth) || drawOption.style.strokeWidth;
      const strokeLabel = this.state[strokeLabelKey] ||
        (savedStyle && savedStyle.strokeLabel) || drawOption.style.strokeLabel;
      const style = savedStyle || drawOption.style;
      const strokeColorPrefix = ("" + style.strokeColor).slice(0, 9);
      if (typeof style.strokeColor == "function" || strokeColorPrefix == "funcStyle") {
        // Histogram
        const strokeColorKey = "" + uid + '_' + drawIdx + '_plot_strokeColor';
        const colors = style.colors;
        const newColors = colors.map((color, idx) => {
          const newStrokeColorKey = strokeColorKey + idx + '_color_rgb';
          return this.state[newStrokeColorKey] ?
            'rgba(' +
            this.state[newStrokeColorKey].r + ', ' +
            this.state[newStrokeColorKey].g + ', ' +
            this.state[newStrokeColorKey].b + ', ' +
            this.state[newStrokeColorKey].a + ')' :
            savedStyle ? savedStyle.colors[idx] : drawOption.style.colors[idx];
        });
        let strokeColor = style.strokeColor;
        if (typeof style.strokeColor == "function") {
          strokeColor = "funcStyle" + style.strokeColor;
        }
        return {
          strokeType,
          strokeWidth,
          colors: newColors,
          strokeColor,
          strokeLabel
        };
      } else {
        const strokeColorKey = "" + uid + '_' + drawIdx + '_plot_strokeColor_color_rgb';
        const strokeColor = this.state[strokeColorKey] ?
          'rgba(' +
          this.state[strokeColorKey].r + ', ' +
          this.state[strokeColorKey].g + ', ' +
          this.state[strokeColorKey].b + ', ' +
          this.state[strokeColorKey].a + ')' :
          savedStyle ? savedStyle.strokeColor : drawOption.style.strokeColor;
        return {
          strokeType,
          strokeWidth,
          strokeColor,
          strokeLabel
        };
      }
    } else if (type == 'fill') {
      const fillBetweenColorKey = "" + uid + '_' + drawIdx + '_fillbetween_fillColor_color_rgb';
      const fillBetweenColor = this.state[fillBetweenColorKey] ?
        'rgba(' +
        this.state[fillBetweenColorKey].r + ', ' +
        this.state[fillBetweenColorKey].g + ', ' +
        this.state[fillBetweenColorKey].b + ', ' +
        this.state[fillBetweenColorKey].a + ')' :
        (savedStyle && savedStyle.fillBetweenColor || drawOption.style.fillBetweenColor);
      const fillCrossColorKey = "" + uid + '_' + drawIdx + '_fillcross_fillColor_color_rgb';
      const fillCrossColor = this.state[fillCrossColorKey] ?
        'rgba(' +
        this.state[fillCrossColorKey].r + ', ' +
        this.state[fillCrossColorKey].g + ', ' +
        this.state[fillCrossColorKey].b + ', ' +
        this.state[fillCrossColorKey].a + ')' :
        (savedStyle && savedStyle.fillCrossColor || drawOption.style.fillCrossColor);
      return {
        fillBetweenColor,
        fillCrossColor
      };
    } else if (type == 'hfill') {
      const fillColorKey = "" + uid + '_' + drawIdx + '_hfill_fillColor_color_rgb';
      const fillColor = this.state[fillColorKey] ?
        'rgba(' +
        this.state[fillColorKey].r + ', ' +
        this.state[fillColorKey].g + ', ' +
        this.state[fillColorKey].b + ', ' +
        this.state[fillColorKey].a + ')' :
        savedStyle ? savedStyle.fillColor : drawOption.style.fillColor;
      return {
        fillColor
      };
    } else if (type == 'hline') {
      const strokeTypeKey = "" + uid + '_' + drawIdx + '_hline_strokeType';
      const strokeWidthKey = "" + uid + '_' + drawIdx + '_hline_strokeWidth';
      const strokeColorKey = "" + uid + '_' + drawIdx + '_hline_strokeColor_color_rgb';

      const strokeType = this.state[strokeTypeKey] ||
        (savedStyle && savedStyle.strokeType) || drawOption.style.strokeType;
      const strokeWidth = this.state[strokeWidthKey] ||
        (savedStyle && savedStyle.strokeWidth) || drawOption.style.strokeWidth;
      const strokeColor = this.state[strokeColorKey] ?
        'rgba(' +
        this.state[strokeColorKey].r + ', ' +
        this.state[strokeColorKey].g + ', ' +
        this.state[strokeColorKey].b + ', ' +
        this.state[strokeColorKey].a + ')' :
        savedStyle ? savedStyle.strokeColor : drawOption.style.strokeColor;

      return {
        strokeType,
        strokeWidth,
        strokeColor,
      };
    } else if (type == 'volumeprofile') {
      const upVolColorKey = "" + uid + '_' + drawIdx + '_vp_upVolColor_color_rgb';
      const upVolColor = this.state[upVolColorKey] ?
        'rgba(' +
        this.state[upVolColorKey].r + ', ' +
        this.state[upVolColorKey].g + ', ' +
        this.state[upVolColorKey].b + ', ' +
        this.state[upVolColorKey].a + ')' :
        savedStyle ? savedStyle.upVolColor : drawOption.style.upVolColor;
      const dnVolColorKey = "" + uid + '_' + drawIdx + '_vp_dnVolColor_color_rgb';
      const dnVolColor = this.state[dnVolColorKey] ?
        'rgba(' +
        this.state[dnVolColorKey].r + ', ' +
        this.state[dnVolColorKey].g + ', ' +
        this.state[dnVolColorKey].b + ', ' +
        this.state[dnVolColorKey].a + ')' :
        savedStyle ? savedStyle.dnVolColor : drawOption.style.dnVolColor;
      const totalVolColorKey = "" + uid + '_' + drawIdx + '_vp_totalVolColor_color_rgb';
      const totalVolColor = this.state[totalVolColorKey] ?
        'rgba(' +
        this.state[totalVolColorKey].r + ', ' +
        this.state[totalVolColorKey].g + ', ' +
        this.state[totalVolColorKey].b + ', ' +
        this.state[totalVolColorKey].a + ')' :
        savedStyle ? savedStyle.totalVolColor : drawOption.style.totalVolColor;

      const vaUpVolColorKey = "" + uid + '_' + drawIdx + '_vp_vaUpVolColor_color_rgb';
      const vaUpVolColor = this.state[vaUpVolColorKey] ?
        'rgba(' +
        this.state[vaUpVolColorKey].r + ', ' +
        this.state[vaUpVolColorKey].g + ', ' +
        this.state[vaUpVolColorKey].b + ', ' +
        this.state[vaUpVolColorKey].a + ')' :
        savedStyle ? savedStyle.vaUpVolColor : drawOption.style.vaUpVolColor;
      const vaDnVolColorKey = "" + uid + '_' + drawIdx + '_vp_vaDnVolColor_color_rgb';
      const vaDnVolColor = this.state[vaDnVolColorKey] ?
        'rgba(' +
        this.state[vaDnVolColorKey].r + ', ' +
        this.state[vaDnVolColorKey].g + ', ' +
        this.state[vaDnVolColorKey].b + ', ' +
        this.state[vaDnVolColorKey].a + ')' :
        savedStyle ? savedStyle.vaDnVolColor : drawOption.style.vaDnVolColor;
      const vaTotalVolColorKey = "" + uid + '_' + drawIdx + '_vp_vaTotalVolColor_color_rgb';
      const vaTotalVolColor = this.state[vaTotalVolColorKey] ?
        'rgba(' +
        this.state[vaTotalVolColorKey].r + ', ' +
        this.state[vaTotalVolColorKey].g + ', ' +
        this.state[vaTotalVolColorKey].b + ', ' +
        this.state[vaTotalVolColorKey].a + ')' :
        savedStyle ? savedStyle.vaTotalVolColor : drawOption.style.vaTotalVolColor;
      const vaVolPctKey = "" + uid + '_' + drawIdx + '_vp_vaVolPct';
      const vaVolPct = this.state[vaVolPctKey] || (savedStyle && savedStyle.vaVolPct) || drawOption.style.vaVolPct;

      const widthKey = "" + uid + '_' + drawIdx + '_vp_width';
      const width = this.state[widthKey] || (savedStyle && savedStyle.width) || drawOption.style.width;
      const placementKey = "" + uid + '_' + drawIdx + '_vp_placement';
      const placement = this.state[placementKey] ||
        (savedStyle && savedStyle.placement) || drawOption.style.placement;
      const volTypeKey = "" + uid + '_' + drawIdx + '_vp_volType';
      const volType = this.state[volTypeKey] ||
        (savedStyle && savedStyle.volType) || drawOption.style.volType;

      // let showPOCKey = "" + uid + '_' + drawIdx + '_vp_showPOC';
      // let showPOC = this.state[showPOCKey] ||
      //   (savedStyle && savedStyle.showPOC) || drawOption.style.showPOC;
      const pocColorKey = "" + uid + '_' + drawIdx + '_vp_pocColor_color_rgb';
      const pocColor = this.state[pocColorKey] ?
        'rgba(' +
        this.state[pocColorKey].r + ', ' +
        this.state[pocColorKey].g + ', ' +
        this.state[pocColorKey].b + ', ' +
        this.state[pocColorKey].a + ')' :
        savedStyle ? savedStyle.pocColor : drawOption.style.pocColor;

      return {
        width,
        vaVolPct,
        placement,
        volType,
        upVolColor,
        dnVolColor,
        totalVolColor,
        vaUpVolColor,
        vaDnVolColor,
        vaTotalVolColor,
        pocColor,
        // showPOC
      };
    } else if (type == 'supdemzone') {
      const supplyColorKey = "" + uid + '_' + drawIdx + '_supdemzone_supplyColor_color_rgb';
      const supplyColor = this.state[supplyColorKey] ?
        'rgba(' +
        this.state[supplyColorKey].r + ', ' +
        this.state[supplyColorKey].g + ', ' +
        this.state[supplyColorKey].b + ', ' +
        this.state[supplyColorKey].a + ')' :
        savedStyle ? savedStyle.supplyColor : drawOption.style.supplyColor;
      const demandColorKey = "" + uid + '_' + drawIdx + '_supdemzone_demandColor_color_rgb';
      const demandColor = this.state[demandColorKey] ?
        'rgba(' +
        this.state[demandColorKey].r + ', ' +
        this.state[demandColorKey].g + ', ' +
        this.state[demandColorKey].b + ', ' +
        this.state[demandColorKey].a + ')' :
        savedStyle ? savedStyle.demandColor : drawOption.style.demandColor;

      const activeZoneLineOpacityKey = "" + uid + '_' + drawIdx + '_supdemzone_activeZoneLineOpacity';
      const activeZoneLineOpacity = this.state[activeZoneLineOpacityKey] ||
        (savedStyle && savedStyle.activeZoneLineOpacity) || drawOption.style.activeZoneLineOpacity;

      const activeZoneAreaOpacityKey = "" + uid + '_' + drawIdx + '_supdemzone_activeZoneAreaOpacity';
      const activeZoneAreaOpacity = this.state[activeZoneAreaOpacityKey] ||
        (savedStyle && savedStyle.activeZoneAreaOpacityOpacity) || drawOption.style.activeZoneAreaOpacity;

      const brokenZoneLineOpacityKey = "" + uid + '_' + drawIdx + '_supdemzone_brokenZoneLineOpacity';
      const brokenZoneLineOpacity = this.state[brokenZoneLineOpacityKey] ||
        (savedStyle && savedStyle.brokenZoneLineOpacity) || drawOption.style.brokenZoneLineOpacity;

      const brokenZoneAreaOpacityKey = "" + uid + '_' + drawIdx + '_supdemzone_brokenZoneAreaOpacity';
      const brokenZoneAreaOpacity = this.state[brokenZoneAreaOpacityKey] ||
        (savedStyle && savedStyle.brokenZoneAreaOpacity) || drawOption.style.brokenZoneAreaOpacity;

      const lineWidthKey = "" + uid + '_' + drawIdx + '_supdemzone_lineWidth';
      const lineWidth = this.state[lineWidthKey] || (savedStyle && savedStyle.lineWidth) || drawOption.style.lineWidth;

      return {
        lineWidth,
        supplyColor,
        demandColor,
        activeZoneLineOpacity,
        activeZoneAreaOpacity,
        brokenZoneLineOpacity,
        brokenZoneAreaOpacity,
      };

    } else if (type == 'swinghighlow') {
      const strokeTypeKey = "" + uid + '_' + drawIdx + '_swing_strokeType';
      const strokeWidthKey = "" + uid + '_' + drawIdx + '_swing_strokeWidth';
      const strokeColorKey = "" + uid + '_' + drawIdx + '_swing_strokeColor_color_rgb';

      const strokeType = this.state[strokeTypeKey] ||
        (savedStyle && savedStyle.strokeType) || drawOption.style.strokeType;
      const strokeWidth = this.state[strokeWidthKey] ||
        (savedStyle && savedStyle.strokeWidth) || drawOption.style.strokeWidth;
      const strokeColor = this.state[strokeColorKey] ?
        'rgba(' +
        this.state[strokeColorKey].r + ', ' +
        this.state[strokeColorKey].g + ', ' +
        this.state[strokeColorKey].b + ', ' +
        this.state[strokeColorKey].a + ')' :
        savedStyle ? savedStyle.strokeColor : drawOption.style.strokeColor;
      return {
        strokeType,
        strokeWidth,
        strokeColor
      };
    } else if (type == 'rangehighlow') {
      const strokeTypeKey = "" + uid + '_' + drawIdx + '_swing_strokeType';
      const strokeWidthKey = "" + uid + '_' + drawIdx + '_swing_strokeWidth';
      const strokeColorKey = "" + uid + '_' + drawIdx + '_swing_strokeColor_color_rgb';

      const strokeType = this.state[strokeTypeKey] ||
        (savedStyle && savedStyle.strokeType) || drawOption.style.strokeType;
      const strokeWidth = this.state[strokeWidthKey] ||
        (savedStyle && savedStyle.strokeWidth) || drawOption.style.strokeWidth;
      const strokeColor = this.state[strokeColorKey] ?
        'rgba(' +
        this.state[strokeColorKey].r + ', ' +
        this.state[strokeColorKey].g + ', ' +
        this.state[strokeColorKey].b + ', ' +
        this.state[strokeColorKey].a + ')' :
        savedStyle ? savedStyle.strokeColor : drawOption.style.strokeColor;
      return {
        strokeType,
        strokeWidth,
        strokeColor
      };
    }
  }
  handleSaveIndicator = () => {
    const { indicator, drawList, indicatorTemplates, updateIndicatorTemplate } = this.props;
    const { indicatorOptions } = this.state;
    const updatedOptions = {};
    let updatedSettings = {};
    if (this.state.activeTab !== "2") {
      Object.keys(indicatorOptions).forEach((key) => {
        const statekey = indicator.uid + '_' + key;
        if (key === "percents") {
          updatedOptions[key] = {
            ...indicatorOptions[key],
            value: this.state.numbers ?
              this.state.numbers.map((item) => parseFloat(item.value)).sort((a, b) => b - a) :
              indicatorOptions[key].value,
          };
        } else if (indicatorOptions[key].type === "style-color") {
          const result = this.state[statekey + '_color_rgb'] ?
            'rgba(' +
            this.state[statekey + '_color_rgb'].r + ', ' +
            this.state[statekey + '_color_rgb'].g + ', ' +
            this.state[statekey + '_color_rgb'].b + ', ' +
            this.state[statekey + '_color_rgb'].a + ')' :
            indicatorOptions[key].value;
          updatedOptions[key] = {
            ...indicatorOptions[key],
            value: result
          };
        } else if (indicatorOptions[key].type === "check") {
          updatedOptions[key] = {
            ...indicatorOptions[key],
            value: this.state[statekey] == undefined ? indicatorOptions[key].value : this.state[statekey],
          };
        } else if (indicatorOptions[key].hasOwnProperty("value")) {
          updatedOptions[key] = {
            ...indicatorOptions[key],
            value: this.state[statekey] || indicatorOptions[key].value,
          };
        } else if (key !== "code") {
          updatedOptions[key] = indicatorOptions[key];
        }
      });
      // Get DrawList update
      const newUpdatedDrawingStyles = drawList.map((drawOption, idx) => {
        return this.getDrawListUpdatedStyle(drawOption, idx);
      });
      //options changed.
      if (indicator.editable) {
        const newOptions = {
          ...indicatorOptions,
          ...updatedOptions,
        };
        updatedSettings = getUpdatedSettings(newOptions, indicator.idx);
      }
      this.updateIndicator(indicator.uid, {
        ...indicatorOptions,
        ...updatedOptions,
      }, updatedSettings, newUpdatedDrawingStyles);
      // Save indicator template
      let templateName = document.getElementsByClassName('templateName')[0].value;
      const doubled = this.getSameTemplateName(templateName);
      if (templateName == '') {  //autosave
        templateName = 'AutoSave';
      } else if (doubled) {
        templateName = doubled;
      } else {
        templateName += new Date().getTime();
      }
      const type = indicator.id;
      updateIndicatorTemplate({
        ...indicatorTemplates,
        [type]: {
          ...indicatorTemplates[type],
          [templateName]: {
            options: {
              name: updatedSettings.settings.name,
              fullName: updatedSettings.settings.fullName,
              separated: updatedSettings.settings.separated,
              ...indicatorOptions,
              ...updatedOptions,
            },
            styles: newUpdatedDrawingStyles
          }
        }
      });
    } else {
      if (indicator.editable) {
        const code = this.state.code;
        updatedSettings = getUpdatedSettings({ code }, indicator.idx);
        this.updateIndicator(indicator.uid, { code }, updatedSettings, null);
      }
    }
  };

  updateIndicator = (id, options, updatedSettings = [], updatedDrawingStyles = null) => {
    const { updateWidget, widget, indicator } = this.props;
    if (indicator.editable) {
      updateWidget({
        ...widget,
        indicators: widget.indicators.map((item) => item.uid !== id ? item : {
          ...item,
          name: updatedSettings.settings.name,
          fullName: updatedSettings.settings.fullName,
          // separated: updatedSettings.settings.separated,
          options: options,
          updatedDrawingStyles
        }),
      });
    } else {
      updateWidget({
        ...widget,
        indicators: widget.indicators.map((item) => item.uid !== id ? item : {
          ...item,
          options: options,
          updatedDrawingStyles
        }),
      });
    }
  };

  removeIndicator = (id) => {
    const { updateWidget, widget } = this.props;
    updateWidget({
      ...widget,
      indicators: widget.indicators.filter((item) => item.uid !== id),
    });
  };

  handleRemoveIndicator = () => {
    const { indicator, toggleModal } = this.props;
    this.removeIndicator(indicator.uid);
  };

  updateNumbersOption = () => {
    clearTimeout(this.updateNumberTimeout);
    this.updateNumberTimeout = setTimeout(() => this.handleSaveIndicator(), 500);
  };

  setActiveNumber = (idx) => this.setState({ currentNumber: idx });

  handleAddNumber = () => {
    this.setState({
      numbers: [...this.state.numbers, { id: uid(10), value: 0 }],
    });
    this.updateNumbersOption();
  };

  handleRemoveNumber = () => {
    this.setState({
      numbers: this.state.numbers.filter((number, idx) =>
        (!this.state.currentNumber && idx === 0) || number.id !== this.state.currentNumber),
    });
    this.updateNumbersOption();
  };

  handleNumberChange = (value) => {
    const { numbers, currentNumber } = this.state;
    this.setState({
      numbers: numbers.map((item, idx) => {
        if ((!currentNumber && idx === 0) || currentNumber === item.id) {
          return {
            ...item,
            value: value,
          };
        }
        return item;
      }),
    });
    this.updateNumbersOption();
  };

  handleClick = (key) => {
    this.setState({
      [key]: !this.state[key]
    });
  };

  handleClose = (key) => {
    this.setState({
      [key]: !this.state[key]
    });
  };

  handleColorPickChange = (color, key) => {
    this.setState({
      [key + '_color_hex']: color.hex,
      [key + '_color_rgb']: color.rgb
    });
  };

  toggleDropDown = (key) => {
    this.setState((prevState) => ({
      ['toggle_' + key]: !prevState['toggle_' + key]
    }));
  }

  dragMouseDown = (e) => {
    e.preventDefault();
    const dragStartPos = [e.clientX, e.clientY];
    this.setState({
      dragStartPos
    });
    document.onmouseup = this.closeDragElement;
    document.onmousemove = this.elementDrag;
  }
  closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  }
  elementDrag = (e) => {
    e.preventDefault();
    const elmnt = document.getElementById('indicatorSettingModal');
    const { dragStartPos } = this.state;
    const newX = dragStartPos[0] - e.clientX;
    const newY = dragStartPos[1] - e.clientY;
    this.setState({
      dragStartPos: [e.clientX, e.clientY]
    });
    elmnt.style.top = (elmnt.offsetTop - newY) + "px";
    elmnt.style.left = (elmnt.offsetLeft - newX) + "px";
  }

  handleApplyDefaultSetting = () => {
    const { indicator, updateWidget, widget } = this.props;
    const indicatorUid = indicator.uid;
    const initState = {};
    for (const key in this.state) {
      if (key.includes(indicatorUid)) {
        initState[key] = null;
      }
    }
    const fakeIndicator = { options: { code: indicator.options.code } };
    const newfakeindi = createNewIndicator(fakeIndicator, indicator.idx);
    this.setState({
      ...initState,
      indicatorOptions: newfakeindi.options,
      updatedDrawingStyles: null
    });
    updateWidget({
      ...widget,
      indicators: widget.indicators.map((item) => item.uid !== indicatorUid ? item : {
        ...item,
        options: newfakeindi.options,
        updatedDrawingStyles: null
      }),
    });
  }

  drawInput = (key, option, col) => {
    col = col || 3;
    const { indicator } = this.props;
    key = indicator.uid + '_' + key;
    switch (option.type) {
      case "style-color":
        const pickedRGBAColor = this.state[key + '_color_rgb'];
        const pickedHexColor = this.state[key + '_color_hex'];
        const defaultRGBAColor = option.value.substring(5, option.value.length - 1).replace(/ /g, '').split(',');
        const hexR = parseInt(defaultRGBAColor[0]).toString(16).length == 2 ?
          parseInt(defaultRGBAColor[0]).toString(16) : ('0' + parseInt(defaultRGBAColor[0]).toString(16));
        const hexG = parseInt(defaultRGBAColor[1]).toString(16).length == 2 ?
          parseInt(defaultRGBAColor[1]).toString(16) : ('0' + parseInt(defaultRGBAColor[1]).toString(16));
        const hexB = parseInt(defaultRGBAColor[2]).toString(16).length == 2 ?
          parseInt(defaultRGBAColor[2]).toString(16) : ('0' + parseInt(defaultRGBAColor[2]).toString(16));
        const defaultHexColor = '#' + hexR + hexG + hexB;
        const hexColor = pickedHexColor || defaultHexColor;
        const rgbaColor = pickedRGBAColor ?
          'rgba(' + pickedRGBAColor.r + ',' +
          pickedRGBAColor.g + ',' +
          pickedRGBAColor.b + ',' +
          pickedRGBAColor.a + ')' : option.value;
        const opacity = pickedRGBAColor ? pickedRGBAColor.a : defaultRGBAColor[3];
        return (

          <div className={styles.inputCode}>
            <div className={styles.spacingAbove}>
              <span className={styles.greyLight}>
                {option.label}
              </span>
              <span className={styles.greyLightInput}>
                <Dropdown
                  className={styles.color}
                  isOpen={this.state['toggle_' + key]}
                  toggle={() => this.toggleDropDown(key)}
                  size="sm"
                >
                  <DropdownToggle>
                    <div className="toggleButton">
                      <div className="colorRect" style={{ backgroundColor: rgbaColor }} />
                      <FontAwesome
                        name="chevron-down"
                        prefix="far"
                        className={styles.chevron}
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    {
                      <div className="popover">
                        <SketchPicker
                          color={rgbaColor}
                          onChange={(color) => this.handleColorPickChange(color, key)}
                        />
                      </div>
                    }
                  </DropdownMenu>
                </Dropdown>
              </span>
            </div>
          </div>
        );
      case "style-number":
        return (

          <div className={styles.inputCode}>
            <div className={styles.spacingAbove}>
              <span className={styles.greyLight}>
                {option.label}
              </span>
              <span className={styles.greyLightInput}>
                <Input
                  type="text"
                  value={this.state[key] == undefined ? option.value : this.state[key]}
                  onChange={(event) => this.handleChange(key, event.target.value)}
                />
              </span>
            </div>
          </div>

        );
      case "style-input":
        return (
          <div className={styles.inputCode}>
            <div className={styles.spacingAbove}>
              {option.label}
              <Input
                type="text"
                value={this.state[key] == undefined ? option.value : this.state[key]}
                onChange={(event) => this.handleChange(key, event.target.value)}
              />
            </div>
          </div>

        );
      case "select":
      case "style-select":
        return (
          <div className={styles.inputCode}>
            <div className={styles.spacingAbove}>
              <div className={styles.OHCL}>
                <span className={styles.greyLight}>
                  {option.label}
                </span>
                <span className={styles.greyLightInput}>
                  <Dropdown
                    isOpen={this.state['toggle_' + key]}
                    toggle={() => this.toggleDropDown(key)}
                    size="sm"
                  >
                    <DropdownToggle>
                      <div className="toggleButton">
                        <img
                          alt={option.value}
                          className={styles.lineicons}
                          src={`/img/icons/lines/${option.value}.svg`}
                        />
                        <FontAwesome
                          name="chevron-down"
                          prefix="far"
                          className={styles.chevronlinedropdown}
                        />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className={styles.dropdownOptionsSettings}>
                      <span className={styles.drawingItemTitle}>{option.label}</span>
                      {
                        option.values.map((value, dropdownIdx) => (
                          <DropdownItem
                            key={key+dropdownIdx}
                            onClick={(event) => this.handleChange(key, value)}
                          >
                            <div className="toggleButton">
                              <img
                                alt={value}
                                className={styles.lineicons}
                                src={`/img/icons/lines/${value}.svg`}
                              />
                              <span>{value}</span>
                            </div>
                          </DropdownItem>))
                      }
                    </DropdownMenu>
                  </Dropdown>
                </span>
              </div>
            </div>
          </div>
        );
      case "check":
        return (
          <span>
            <div className={styles.spacingAboveCheckmark}>
              <input
                type="checkbox"
                className="checkbox"
                checked={this.state[key] == undefined ? option.value : this.state[key]}
                onChange={(event) => this.handleChange(key, event.target.checked)}
              /> {option.label}
            </div>
          </span>
        );
      default:
        return (
          <div className={styles.inputCode}>
            <div className={styles.spacingAbove}>
              <span className={styles.greyLight}>
                {option.label}
              </span>
              <span className={styles.greyLightInput}>
                <Input
                  type="text"
                  value={this.state[key] == undefined ? option.value : this.state[key]}
                  onChange={(event) => this.handleChange(key, event.target.value)}
                />
              </span>
            </div>
          </div>
        );
    }
  };
  handleToggleDropdown = (type) => {
    let data = null;
    switch (type) {
      case "drawinglineDropdown":
        data = {
          drawinglineDropdownOpen: !this.state.drawinglineDropdownOpen,
        };
        break;
      default:
        break;
    }

    data && this.setState(data);
  };
  drawStyle = (drawOption, drawIdx) => {
    const type = drawOption.type;
    const { updatedDrawingStyles } = this.state;
    const style = updatedDrawingStyles && updatedDrawingStyles[drawIdx] || drawOption.style;
    if (type == 'plot') {
      const plotLabel = drawOption.name;

      const strokeType = {
        type: 'style-select',
        label: 'Line Type',
        value: style.strokeType || plotDef.style.strokeType,
        values: strokeTypes
      };
      const strokeTypeKey = "" + drawIdx + '_plot_strokeType';

      const strokeWidth = {
        type: 'style-number',
        label: 'Thickness',
        value: style.strokeWidth || plotDef.style.strokeWidth
      };
      const strokeWidthKey = "" + drawIdx + '_plot_strokeWidth';

      const strokeLabel = {
        type: 'style-input',
        label: 'Label',
        value: style.strokeLabel || plotDef.style.strokeLabel
      };
      const strokeLabelKey = "" + drawIdx + '_plot_strokeLabel';

      let strokeColor = undefined;
      const strokeColorKey = "" + drawIdx + '_plot_strokeColor';

      const strokeColorPrefix = ("" + style.strokeColor).slice(0, 9);
      if (typeof style.strokeColor == "function" || strokeColorPrefix == "funcStyle") {
        // Histogram
        const colors = style.colors;
        return (
          <Fragment>
            <span>
              Histogram
              {
                colors.map((color, idx) => {
                  const colorSetting = {
                    type: 'style-color',
                    label: 'Color ' + idx,
                    value: color
                  };
                  const newStrokeColorKey = strokeColorKey + idx;
                  return this.drawInput(newStrokeColorKey, colorSetting, 3);
                })
              }
              {this.drawInput(strokeWidthKey, strokeWidth, 3)}
              {this.drawInput(strokeTypeKey, strokeType, 3)}
            </span>
          </Fragment>
        );
      } else {
        strokeColor = {
          type: 'style-color',
          label: 'Color',
          value: style.strokeColor
        };
        return (
          <div>
            {style.strokeLabel ? this.drawInput(strokeLabelKey, strokeLabel, 3) : null}
            and
            {this.drawInput(strokeWidthKey, strokeWidth, 3)}

            {this.drawInput(strokeColorKey, strokeColor, 3)}
            {this.drawInput(strokeTypeKey, strokeType, 3)}
          </div>
        );
      }
    } else if (type == 'hfill') {
      const hfilllabel = drawOption.name;

      const fillColor = {
        type: 'style-color',
        label: hfilllabel + ' Color',
        value: style.fillColor || hfillDef.style.fillColor,
      };
      const fillColorKey = "" + drawIdx + '_hfill_fillColor';
      return (
        <span>
          and
          {this.drawInput(fillColorKey, fillColor, 3)}
        </span>
      );
    } else if (type == 'fill') {
      const filllabel = drawOption.name;

      const fillBetweenColor = {
        type: 'style-color',
        label: filllabel + ' Between Fill Color',
        value: style.fillBetweenColor || fillDef.style.fillBetweenColor,
      };
      const fillBetweenColorKey = "" + drawIdx + '_fillbetween_fillColor';

      const fillCrossColor = {
        type: 'style-color',
        label: filllabel + ' Cross Fill Color',
        value: style.fillCrossColor || fillDef.style.fillCrossColor,
      };
      const fillCrossColorKey = "" + drawIdx + '_fillcross_fillColor';
      return (
        <span>
          and
          {this.drawInput(fillBetweenColorKey, fillBetweenColor, 3)}

          {this.drawInput(fillCrossColorKey, fillCrossColor, 3)}
        </span>
      );
    } else if (type == 'hline') {
      const hlinelabel = drawOption.name;

      const strokeType = {
        type: 'style-select',
        label: 'Line Type',
        value: style.strokeType || hlineDef.style.strokeType,
        values: strokeTypes
      };
      const strokeTypeKey = "" + drawIdx + '_hline_strokeType';

      const strokeWidth = {
        type: 'style-number',
        label: 'Thickness',
        value: style.strokeWidth || hlineDef.style.strokeWidth,
      };
      const strokeWidthKey = "" + drawIdx + '_hline_strokeWidth';

      const strokeLevel = {
        type: 'style-number',
        label: style.strokeLevelLabel,
        value: style.strokeLevel || hlineDef.style.strokeLevel,
      };
      const strokeLevelKey = "" + drawIdx + '_hline_strokeLevel';

      const strokeColor = {
        type: 'style-color',
        label: 'Color ',
        value: style.strokeColor || hlineDef.style.strokeColor
      };
      const strokeColorKey = "" + drawIdx + '_hline_strokeColor';
      return (
        <span>
          and
          {this.drawInput(strokeLevelKey, strokeLevel, 3)}

          {this.drawInput(strokeTypeKey, strokeType, 3)}

          {this.drawInput(strokeWidthKey, strokeWidth, 3)}

          {this.drawInput(strokeColorKey, strokeColor, 3)}
        </span>
      );
    } else if (type == 'volumeprofile') {
      const upVolColor = {
        type: 'style-color',
        label: 'Volume Profile Up',
        value: style.upVolColor || vp.style.upVolColor,
      };
      const upVolColorKey = "" + drawIdx + '_vp_upVolColor';
      const dnVolColor = {
        type: 'style-color',
        label: ' Volume Profile Down',
        value: style.dnVolColor || vp.style.dnVolColor,
      };
      const dnVolColorKey = "" + drawIdx + '_vp_dnVolColor';
      const totalVolColor = {
        type: 'style-color',
        label: 'Volume Profile Total',
        value: style.totalVolColor || vp.style.totalVolColor,
      };
      const totalVolColorKey = "" + drawIdx + '_vp_totalVolColor';

      const vaUpVolColor = {
        type: 'style-color',
        label: 'Value Area Up',
        value: style.vaUpVolColor || vp.style.vaUpVolColor,
      };
      const vaUpVolColorKey = "" + drawIdx + '_vp_vaUpVolColor';
      const vaDnVolColor = {
        type: 'style-color',
        label: 'Value Area Down',
        value: style.vaDnVolColor || vp.style.vaDnVolColor,
      };
      const vaDnVolColorKey = "" + drawIdx + '_vp_vaDnVolColor';
      const vaTotalVolColor = {
        type: 'style-color',
        label: 'Value Area Total',
        value: style.vaTotalVolColor || vp.style.vaTotalVolColor,
      };
      const vaTotalVolColorKey = "" + drawIdx + '_vp_vaTotalVolColor';
      const vaVolPct = {
        type: 'style-number',
        label: 'Value Area %',
        value: style.vaVolPct || vp.style.vaVolPct
      };
      const vaVolPctKey = "" + drawIdx + '_vp_vaVolPct';

      const width = {
        type: 'style-number',
        label: 'Width (% of Box)',
        value: style.width || vp.style.width
      };
      const widthKey = "" + drawIdx + '_vp_width';
      const placement = {
        type: 'style-select',
        label: 'Placement',
        value: style.placement || vp.style.placement,
        values: vp.style.placementSelect
      };
      const placementKey = "" + drawIdx + '_vp_placement';
      const volType = {
        type: 'style-select',
        label: 'Type',
        value: style.volType || vp.style.volType,
        values: vp.style.volTypeSelect
      };
      const volTypeKey = "" + drawIdx + '_vp_volType';

      const pocColor = {
        type: 'style-color',
        label: 'POC Color',
        value: style.pocColor || vp.style.pocColor,
      };
      const pocColorKey = "" + drawIdx + '_vp_pocColor';

      // const showPOC = {
      //   type: 'style-check',
      //   label: 'Show POC',
      //   value: style.showPOC || vp.style.showPOC,
      // };
      // let showPOCKey = "" + drawIdx + '_vp_showPOC';
      return (
        <Fragment>
          <span>
            and
            {this.drawInput(widthKey, width, 3)}
            {this.drawInput(placementKey, placement, 3)}
            {this.drawInput(vaVolPctKey, vaVolPct, 3)}
            {this.drawInput(volTypeKey, volType, 3)}
          </span>
          <span>
            and
            {this.drawInput(upVolColorKey, upVolColor, 3)}

            {this.drawInput(dnVolColorKey, dnVolColor, 3)}

            {this.drawInput(totalVolColorKey, totalVolColor, 3)}
          </span>
          <span>
            and
            {this.drawInput(vaUpVolColorKey, vaUpVolColor, 3)}

            {this.drawInput(vaDnVolColorKey, vaDnVolColor, 3)}

            {this.drawInput(vaTotalVolColorKey, vaTotalVolColor, 3)}
          </span>
          <span>
            {/* {this.drawInput(showPOCKey, showPOC, 3)} */}
            and
            {this.drawInput(pocColorKey, pocColor, 3)}
          </span>
        </Fragment>
      );
    } else if (type == 'supdemzone') {
      const supplyColor = {
        type: 'style-color',
        label: 'Supply Color',
        value: style.supplyColor || supdemzone.style.supplyColor,
      };
      const supplyColorKey = "" + drawIdx + '_supdemzone_supplyColor';

      const demandColor = {
        type: 'style-color',
        label: 'Demand Color',
        value: style.demandColor || supdemzone.style.demandColor,
      };
      const demandColorKey = "" + drawIdx + '_supdemzone_demandColor';

      const activeZoneLineOpacity = {
        type: 'style-number',
        label: 'Active Zone Opacity',
        value: style.activeZoneLineOpacity || supdemzone.style.activeZoneLineOpacity,
      };
      const activeZoneLineOpacityKey = "" + drawIdx + '_supdemzone_activeZoneLineOpacity';

      const activeZoneAreaOpacity = {
        type: 'style-number',
        label: 'Active Zone Fill Opacity',
        value: style.activeZoneAreaOpacity || supdemzone.style.activeZoneAreaOpacity,
      };
      const activeZoneAreaOpacityKey = "" + drawIdx + '_supdemzone_activeZoneAreaOpacity';

      const brokenZoneLineOpacity = {
        type: 'style-number',
        label: 'Broken Zone Opacity',
        value: style.brokenZoneLineOpacity || supdemzone.style.brokenZoneLineOpacity,
      };
      const brokenZoneLineOpacityKey = "" + drawIdx + '_supdemzone_brokenZoneLineOpacity';

      const brokenZoneAreaOpacity = {
        type: 'style-number',
        label: 'Broken Zone Fill Opacity',
        value: style.brokenZoneAreaOpacity || supdemzone.style.brokenZoneAreaOpacity,
      };
      const brokenZoneAreaOpacityKey = "" + drawIdx + '_supdemzone_brokenZoneAreaOpacity';

      const lineWidth = {
        type: 'style-number',
        label: 'Width',
        value: style.lineWidth || supdemzone.style.lineWidth,
      };
      const widthKey = "" + drawIdx + '_supdemzone_lineWidth';

      return (
        <Fragment>
          <span>
            {this.drawInput(supplyColorKey, supplyColor, 3)}
            {this.drawInput(activeZoneLineOpacityKey, activeZoneLineOpacity, 3)}
            {this.drawInput(activeZoneAreaOpacityKey, activeZoneAreaOpacity, 3)}
          </span>
          <span>
            {this.drawInput(demandColorKey, demandColor, 3)}
            {this.drawInput(brokenZoneLineOpacityKey, brokenZoneLineOpacity, 3)}
            {this.drawInput(brokenZoneAreaOpacityKey, brokenZoneAreaOpacity, 3)}
          </span>
          <span>
            {this.drawInput(widthKey, lineWidth, 3)}
          </span>
        </Fragment>
      );
    } else if (type == 'swinghighlow') {
      const strokeType = {
        type: 'style-select',
        label: 'Swing Type',
        value: style.strokeType || plotDef.style.strokeType,
        values: strokeTypes
      };
      const strokeTypeKey = "" + drawIdx + '_swing_strokeType';

      const strokeWidth = {
        type: 'style-number',
        label: 'Swing Width',
        value: style.strokeWidth || plotDef.style.strokeWidth
      };
      const strokeWidthKey = "" + drawIdx + '_swing_strokeWidth';

      const strokeColor = {
        type: 'style-color',
        label: 'Swing Color',
        value: style.strokeColor
      };
      const strokeColorKey = "" + drawIdx + '_swing_strokeColor';
      return (
        <span>
          {this.drawInput(strokeWidthKey, strokeWidth, 3)}
          {this.drawInput(strokeColorKey, strokeColor, 3)}
          {this.drawInput(strokeTypeKey, strokeType, 3)}
        </span>
      );
    }
  }
  toggle = (tab) => {
    this.state.activeTab !== tab && this.setState({
      activeTab: tab,
    });
  };
  handleLoadTemplate = (e) => {
    const { currentTarget } = e;
    const templateKey = currentTarget.getAttribute('templateKey');
    const { indicator, indicatorTemplates } = this.props;
    const type = indicator.id;
    const indicatorUid = indicator.uid;
    const templates = indicatorTemplates[type];
    const savedSetting = templates[templateKey];
    if (savedSetting) {
      const initState = {};
      for (const key in this.state) {
        if (key.includes(indicatorUid)) {
          initState[key] = null;
        }
      }

      this.setState({
        updatedDrawingStyles: savedSetting.styles,
        indicatorOptions: savedSetting.options
      });
      document.getElementsByClassName('templateName')[0].value = templateKey.slice(0, -13);
    }
  }
  getSameTemplateName = (name) => {
    const { indicator, indicatorTemplates } = this.props;
    const type = indicator.id;
    if (indicatorTemplates && indicatorTemplates[type]) {
      const templates = indicatorTemplates[type];
      const keys = Object.keys(templates);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const savedName = key.slice(0, -13);
        if (key != 'AutoSave' && name == savedName) {
          return key;
        }
      }
    }
    return null;
  }
  getSavedTemplatesList = () => {
    const { indicator, indicatorTemplates } = this.props;
    const type = indicator.id;
    if (indicatorTemplates && indicatorTemplates[type]) {
      const templates = indicatorTemplates[type];
      const keys = Object.keys(templates);
      const list = [];
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key != 'AutoSave') {
          list.push([key.slice(0, -13), key]);
        }
      }
      return list;
    } else {
      return [];
    }
  }
  handleRemoveTemplate = (e, templateKey) => {
    const { indicatorTemplates, indicator, updateIndicatorTemplate } = this.props;
    const type = indicator.id;
    const templates = indicatorTemplates[type];
    delete templates[templateKey];
    updateIndicatorTemplate({
      ...indicatorTemplates,
      [type]: templates
    });
  }
  toggleTab = (tab) => {
    this.state.activeTab !== tab && this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { indicator, drawList, isOpen, toggleModal } = this.props;
    const { indicatorOptions } = this.state;
    if (indicator && indicatorOptions && drawList) {
      const paramInputs = Object.keys(indicatorOptions)
          .filter((key) => indicatorOptions[key].type && indicatorOptions[key].type.indexOf("style") == -1 &&
          indicatorOptions[key].type != 'check');
      const checkboxInputs = Object.keys(indicatorOptions)
          .filter((key) => indicatorOptions[key].type && indicatorOptions[key].type.indexOf("style") == -1 &&
          indicatorOptions[key].type == 'check');
      const list = this.getSavedTemplatesList();
      const { activeTab } = this.state;
      return (
        <Modal
          autoFocus={false}
          isOpen={isOpen}
          toggle={() => toggleModal("indicatorSettings")}
          className={classNames("indicators-modal", styles.moveable)}
          id='indicatorSettingModal'
          size="lg"
        >
          <ModalHeader
            onMouseDown={this.dragMouseDown}
            toggle={() => toggleModal("indicatorSettings")}
          >
            Indicators <span>& Settings</span>
          </ModalHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === '1' })}
                onClick={() => this.toggleTab('1')}
              >
                Settings
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === '2' })}
                onClick={() => this.toggleTab('2')}
              >
                Editor
              </NavLink>
            </NavItem>
          </Nav>
          <ModalBody>
            <TabContent activeTab={activeTab}>
              <TabPane
                tabId="1"
                style={{marginTop: 30}}
              >
                <div className={styles.patternScript}>

                  <div className={styles.indicatorTitle}>
                    <div className={styles.greyLight}>Name</div>
                    <div style={{height: 27.5, paddingLeft: 5, paddingRight: 5}} className={styles.greyLightInput}>{indicator.fullName}</div>
                  </div>

                  <div className={styles.patternScriptHeader}>Indicator passes all of the following:</div>

                  {/*<div>
                    <FontAwesome
                      name="brackets"
                      prefix="fas"
                      style={{marginLeft: 2, marginRight: 5, marginTop: 5, marginBottom: 5}}
                    /> Inputs:
                  </div>*/}
                  <div className={styles.codeLeft}>
                    {paramInputs.map((key) => this.drawInput(key, indicatorOptions[key]))}
                    {/*</div>

                  <div>
                    <FontAwesome
                      name="swatchbook"
                      prefix="far"
                      style={{marginLeft: 2, marginRight: 5, marginTop: 5, marginBottom: 5}}
                    /> Plots:
                  </div>
                  <div className={styles.codeLeft}>*/}
                    <div className={styles.grid}>
                      {drawList.map((drawOption, idx) => this.drawStyle(drawOption, idx))}
                    </div>

                    {/*</div>
                  <div>
                    <FontAwesome
                      name="sliders-v"
                      prefix="fas"
                      style={{marginLeft: 2, marginRight: 5, marginTop: 5, marginBottom: 5}}
                    /> Other:
                  </div>
                  <div className={styles.codeLeft}>*/}
                    {checkboxInputs.map((key) => (
                      <span key={key}>
                        {this.drawInput(key, indicatorOptions[key], 6)}
                      </span>
                    ))}
                  </div>
                </div>
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane
                style={{marginTop: 30}}
                tabId="2"
              >
                <Editor
                  height="400px"
                  theme="vs-dark"
                  defaultLanguage="javascript"
                  defaultValue="// some comment"
                  value={this.state.code}
                />
              </TabPane>
            </TabContent>
            <div className={styles.templates}>
              <div>
                <span>Load or create a template(s).</span>
              </div>
              <div className={styles.templateDropdown}>
                <Dropdown
                  className={styles.templateGroup}
                  isOpen={this.state['toggle_' + 'indicatorTemplate']}
                  toggle={() => this.toggleDropDown('indicatorTemplate')}
                  size="sm"
                  style={{ zIndex: 5 }}
                >
                  <DropdownToggle>
                    Template <FontAwesome
                      style={{float: 'right'}}
                      className={styles.chevron}
                      name="chevron-down"
                      prefix="fas"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {
                      list.map((tempName, idx) => {
                        return (
                          <DropdownItem
                            key={tempName}
                            className="templateDropdownItem"
                            templateKey={tempName[1]}
                            onClick={(e) => this.handleLoadTemplate(e)}
                          >
                            <div className="templateItem">
                              <span>{tempName[0]}</span>
                              <FontAwesome
                                name="times"
                                prefix="fas"
                                handleClick={(e) => this.handleRemoveTemplate(e, tempName[1])}
                              />
                            </div>
                          </DropdownItem>);
                      })
                    }
                  </DropdownMenu>
                  <input className={styles.templateInput} type="text" placeholder="Create one.." />
                </Dropdown>
              </div>
            </div>
            <div className="m-2" style={{ display: 'flex' }}>
              <CustomButton
                label="Reset"
                className="ResetButton"
                onClick={() => this.handleApplyDefaultSetting()}
              />
              <CustomButton
                label="Remove"
                className="DeleteButton"
                onClick={() => {
                  this.handleRemoveIndicator();
                  toggleModal("indicatorSettings");
                }}
              />
              <CustomButton
                label="Save"
                className="SaveButton"
                onClick={() => {
                  this.handleSaveIndicator();
                  toggleModal("indicatorSettings");
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

IndicatorSettingsModal.propTypes = {
  drawList: PropTypes.object,
  indicator: PropTypes.object,
  indicatorTemplates: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateIndicatorTemplate: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
};

IndicatorSettingsModal.defaultProps = {
  drawList: PropTypes.object,
  indicator: undefined,
  isOpen: false,
  toggleModal: () => false,
  widget: undefined,
};

export default IndicatorSettingsModal;
