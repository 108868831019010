import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import {chartThemes, chartInit, updateViewModel, chartDrawCrosshair, chartUpdateDimension} from "./chart";
import {getDatePriceData} from "./elements/crosshair";
import DrawingToolContextMenu from "./DrawingToolContextMenu";
import OHLCTooltip from "./OHLCTooltip";
import Watermark from "./Watermark";
//import MenuChart from "./MenuChart";
import {lineSettings} from "./drawingToolSettings";
import Indicator from "../Indicator/indicator";
import price from './elements/price';
import drawAxes from './elements/axes';
import scaleGrid from './elements/scaleGrid';
import { calculateData } from "../Indicator/calculators";
import {updateOrderLines, displayOrderPrices} from "./elements/orders";
import {tickSocket} from "../../../../../utils/websockets";
import {cloneDeep} from "lodash";

let brushLines = [];
let brushStartX = 0;
const removeOrderRegexp = /order-(.*)-remove/g;

class Chart extends Component {
  constructor(props) {
    super(props);
    const {injectedData: data, drawList} = this.injectIndicatorData(props);
    this.state = {
      selectedDrawing: null,
      chartFor: 'main',
      chartView: undefined,
      viewModel: {},
      baseCanvas: undefined,
      scaleCanvas: undefined,
      scaleMin: undefined,
      scaleMax: undefined,
      dragStartY: undefined,
      previousRange: undefined,
      stage: undefined,
      layer: undefined,
      line: undefined,
      drawingFinished: true,
      currentCursor: 'url("/img/icons/cross.png") 14 14, auto',
      ts1: undefined,
      ts2: undefined,
      ts3: undefined,
      price1: undefined,
      price2: undefined,
      price3: undefined,
      drawingToolContextMenuPos: [],
      drawingToolContextMenuItemId: '',
      drawingToolContextMenuOpen: false,
      data: data,
      drawList: drawList,
      cursorPriceTime: undefined,
      preventIndicatorUpdate: false
    };
    this.preventIndicatorUpdateTimer;
    this.updateOnDragInterval;
    this.onKeyPress = this.onKeyPress.bind(this);
  }
  componentDidMount() {
    let {widget, chartId, width, height, isLast, config, chartOffset, zoom} = this.props;
    const {scaleMax, scaleMin, data, drawList} = this.state;
    const period = widget.basePeriod.to.min;
    const content = document.getElementById(chartId);
    const canvases = content.querySelectorAll('canvas');
    const base = canvases[0];
    const scale = canvases[1];
    scale.addEventListener('contextmenu', this.handleChartContextenu, false);
    // set indicators data
    const layers = {
      base: base,
      scale: scale
    };
    const leftMargin = config.geometry.boxPrice.margin[1];
    if (isLast){
      config={
        ...config,
        geometry: {
          ...config.geometry,
          boxPrice: {
            ...config.geometry.boxPrice,
            margin: [0, leftMargin, 20, 0]
          }
        }
      };
    }
    const chartState = {
      width,
      height,
      zoom,
      offset: chartOffset,
      config,
      theme: chartThemes()['light'],
    };
    const chartView = chartInit(
        layers,
        chartState,
    );
    chartView.period = period;
    chartView.isLast = isLast;
    chartView.chartId = chartId;
    const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
    tickSocket.on("tickerSubscribe", ({exchange, id, data})=>{
      if (exchange!==this.props.widget.exchange||this.props.widget.marketId!==id) return;
      const newData=cloneDeep(this.state.data);
      newData[newData.length-1].close=parseFloat(data.close);
      const viewModel = updateViewModel(chartId, drawList, newData, chartView, scaleMin, scaleMax, this.props.capacity);

      this.setState({data: newData, viewModel});
    });

    this.setState({
      chartView,
      viewModel,
      baseCanvas: base,
      scaleCanvas: scale,
    });

    if (chartId.slice(0, 9) == 'mainchart'){
      //add drawing panel
      const cc = window.cc;
      const stage = new cc.Stage(content, scale, this.cursorMoveHandler);
      const layer = new cc.Layer(chartId);
      stage.appendChildren(layer);
      stage.on('mousedown', this.highlightOrStartDrawLine);
      stage.on('click', this.drawingClicked);
      stage.on('mouseover', this.drawingOver);
      stage.on('*', (e)=>{
        if (e.type !== 'mousemove'){
          const scaleCanvas = this.state.scaleCanvas;
          const rect = scaleCanvas.getBoundingClientRect();
          const x = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
          const y = (e.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
          stage.update({x, y});
        }
      });
      stage.saveUpdate = this.updateDrawingTool;
      stage.showContextMenu = this.handleDrawingToolContextMenu;
      this.setState({
        stage,
        layer,
      });
    } else {
      this.setState({
        chartFor: 'indicator'
      });
    }
    document.addEventListener("keyup", this.onKeyPress);
  }

  componentDidUpdate(prevProps, prevState) {
    const {scaleCanvas} = this.state;
    let {chartId, moveY, drawingItemId, drawingPoints, config, widget, updateWidget} = this.props;
    let {chartFor, drawList, chartView, viewModel, scaleMin, scaleMax, stage,
      layer, data, cursorPriceTime, preventIndicatorUpdate} = this.state;

    if (prevProps.widget.active !== widget.active && !widget.active) {
      this.setState({
        selectedDrawing: null
      });
      this.props.handleToggleChartMenu && this.props.handleToggleChartMenu(false);
    }

    if (prevProps.drawingItemId != drawingItemId){
      this.setState({
        currentCursor: 'url("/img/icons/pen.png") 8 23, auto'
      });
      //stage.off('click');
      stage.off('mousedown');
      stage.on('mousedown', this.highlightOrStartDrawLine);
      if (drawingPoints > 0){
        this.props.handleDrawingCheck(false);
        this.setState({
          drawingFinished: false
        });
      } else {
        this.props.handleDrawingCheck(true);
        this.setState({
          drawingFinished: true
        });
        if (drawingItemId == 'cross'){
          this.setState({
            currentCursor: 'url("/img/icons/cross.png")  14 14, auto'
          });
        } else if (drawingItemId == 'dot'){
          this.setState({
            currentCursor: 'url("/img/icons/dot.png")  14 14, auto'
          });
        } else if (drawingItemId == 'arrow'){
          this.setState({
            currentCursor: 'url("/img/icons/cursor.png")  7 3, auto'
          });
        } else if (drawingItemId == 'deldrawingtool'){
          const drawingTools = widget.drawingTools ? widget.drawingTools : [];
          const filtered = drawingTools.filter((item) => item.exchange != widget.exchange || item.market != widget.name);
          updateWidget({
            ...widget,
            drawingTools: filtered
          });
        } else if (drawingItemId == 'delindicators'){
          // empty
        }
      }
    }
    if (prevProps.cursorData != this.props.cursorData){
      const cursorData = this.props.cursorData;
      if (chartId == cursorData[1]){
        const {currentCursor} = this.state;
        let newCursor = currentCursor;
        if (stage){
          const target = stage.hitTest({ x: cursorData[0][0], y: cursorData[0][1] });
          if (target){
            newCursor = 'url("/img/icons/pen.png") 8 23, auto';
          }
        }
        chartView.cursor = cursorData[0];
        if (cursorData[0][0] > 0 && cursorData[0][1] > 0){
          scaleCanvas.style.cursor = newCursor;
        } else if (cursorData[0][0] === -100) {
          scaleCanvas.style.cursor = 'url("/img/icons/resize.png") 14 14, auto';
        } else {
          scaleCanvas.style.cursor = 'default';
        }
      } else {
        chartView.cursor = [cursorData[0][0], -100 * 2];
      }
      window.requestAnimationFrame((ts) => {
        const newCursorPriceTime = chartDrawCrosshair(ts, chartView, viewModel, cursorPriceTime, moveY, stage);
        if (cursorPriceTime) {
          if (cursorPriceTime[0] !== newCursorPriceTime[0] || cursorPriceTime[1] !== newCursorPriceTime[1]) {
            this.setState({
              cursorPriceTime: newCursorPriceTime
            });
          }
        } else {
          this.setState({
            cursorPriceTime: newCursorPriceTime
          });
        }
      });
      this.setState({
        chartView
      });
    }
    if (prevProps.widget.data != this.props.widget.data){
      if (this.state.drawingFinished) {
        if (!preventIndicatorUpdate) {
          clearInterval(this.updateOnDragInterval);
          this.updateOnDragInterval = undefined;

          const {injectedData: data, drawList} = this.injectIndicatorData();
          const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
          this.setState({
            data,
            drawList,
            viewModel
          });
          if (chartFor == 'main'){
            try {

              layer?.removeChildren(0, layer.children.length);
              this.refreshOrders(chartView, viewModel);
              this.refreshDrawingTools(chartView, viewModel);
            } catch (e) {
              // empty
            }
          }
        } else {
          const {injectedData: data, drawList} = this.injectIndicatorDataExcluding(["supdemzone"]);
          const supdemzoneIndicator = this.props.indicators.find((item) => item.id === "supdemzone");
          if (supdemzoneIndicator != null) {
            const drawItem = this.state.drawList[supdemzoneIndicator.name + supdemzoneIndicator.idx];
            if (drawItem != null) {
              drawList[supdemzoneIndicator.name + supdemzoneIndicator.idx] = drawItem;
            }
          }
          const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
          this.setState({
            data,
            drawList,
            viewModel
          });
        }
      }
    }
    if (preventIndicatorUpdate && prevProps.zoom === this.props.zoom &&
      prevProps.dragStartX === this.props.dragStartX && !this.props.dragStartX) {
      this.setState({
        preventIndicatorUpdate: false
      });
      clearTimeout(this.preventIndicatorUpdateTimer);
      this.preventIndicatorUpdateTimer = setTimeout(() => {
        clearInterval(this.updateOnDragInterval);
        this.updateOnDragInterval = undefined;
      }, 1000);
    }
    if ((prevProps.dragStartX != this.props.dragStartX) ||
      prevProps.zoom != this.props.zoom) {
      if (this.props.dragStartX || (prevProps.zoom != this.props.zoom)) {
        clearTimeout(this.preventIndicatorUpdateTimer);
        this.setState({
          preventIndicatorUpdate: true
        });
        if (this.updateOnDragInterval === undefined) {
          this.updateOnDragInterval = setInterval(() =>  {
            this.updateAllIndicators();
          }, 40);
        }
      } else {
        clearTimeout(this.preventIndicatorUpdateTimer);
        this.preventIndicatorUpdateTimer = setTimeout(() => {
          this.setState({
            preventIndicatorUpdate: false
          });
          clearInterval(this.updateOnDragInterval);
          this.updateOnDragInterval = undefined;
        }, 1000);
      }
    }
    if (prevProps.moveY != this.props.moveY){
      //console.debug("moveY", this.props.moveY, this.props.chartOffset);
      chartView.offset = this.props.chartOffset;
      const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
      if (chartFor == 'main'){
        layer.removeChildren(0, layer.children.length);
        this.refreshOrders(chartView, viewModel);
        this.refreshDrawingTools(chartView, viewModel);
      }
      this.setState({
        chartView,
        viewModel
      });
    }
    if (prevProps.widget.basePeriod.to.min != this.props.widget.basePeriod.to.min){
      chartView.period = widget.basePeriod.to.min;
      const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
      this.setState({
        chartView,
        viewModel
      });
    }
    if (prevProps.zoom != this.props.zoom){
      if (this.props.chartOffset < this.props.minOffset && this.props.minOffset < 0) {
        chartView.offset = this.props.minOffset;
      }
      chartView.stickLength = this.props.zoom * 2;
      const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
      if (chartFor == 'main'){
        layer.removeChildren(0, layer.children.length);
        this.refreshOrders(chartView, viewModel);
        this.refreshDrawingTools(chartView, viewModel);
      }
      this.setState({
        chartView,
        viewModel
      });
    }
    if (prevProps.widget.drawingTools &&
      (prevProps.widget.drawingTools != this.props.widget.drawingTools)){
      if (chartFor == 'main'){
        layer.removeChildren(0, layer.children.length);
        this.refreshDrawingTools(chartView, viewModel);
        this.refreshOrders(chartView, viewModel);
      }
    }
    if (this.props.orders && prevProps.orders.length != this.props.orders.length) {
      if (chartFor == 'main') {
        this.refreshOrders(chartView, viewModel);
      }
    }
    if (prevProps.height != this.props.height || prevProps.width != this.props.width){
      const {baseCanvas: base, scaleCanvas: scale} = this.state;
      const newWidth = this.props.width;
      const newHeight = this.props.height;
      chartView = chartUpdateDimension({base, scale}, newWidth, newHeight, chartView);
      const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
      this.setState({
        chartView,
        viewModel
      });
      if (chartFor == 'main'){
        const priceBoxContent = chartView.geometry.boxPrice.content;
        const width = priceBoxContent[2];
        const height = priceBoxContent[3];
        const newBounds = {
          top: 0,
          left: 0,
          width,
          height,
          right: width,
          bottom: height,
          x: 0,
          y: 0
        };
        layer.bounds = newBounds;
        stage.setBounds = newBounds;
        //Load Drawing Tools
        layer.removeChildren(0, layer.children.length);
        this.refreshOrders(chartView, viewModel);
        this.refreshDrawingTools(chartView, viewModel);
      }
    }
    if (prevProps.indicators != this.props.indicators) {
      if (!(this.props.separated && this.props.indicators &&
        prevProps.indicators && prevProps.indicators[0] === this.props.indicators[0])) {
        const {injectedData: data, drawList} = this.injectIndicatorData();
        const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
        this.setState({
          data,
          drawList,
          viewModel
        });
      }
    }
    if (prevProps.isLast != this.props.isLast) {
      const leftMargin = config.geometry.boxPrice.margin[1];
      config={
        ...config,
        geometry: {
          ...config.geometry,
          boxPrice: {
            ...config.geometry.boxPrice,
            margin: this.props.isLast ? [0, leftMargin, 20, 0] : [0, leftMargin, 0, 0]
          }
        }
      };
      const {baseCanvas: base, scaleCanvas: scale} = this.state;
      chartView.config = config;
      chartView.isLast = this.props.isLast;
      chartView = chartUpdateDimension({base, scale}, this.props.width, this.props.height, chartView);
      const viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);

      this.setState({
        chartView,
        viewModel
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onKeyPress);
  }

  onKeyPress(e) {
    const keyCode = e.which;
    switch (keyCode) {
      case 27: {
        if (this.state.selectedDrawing) {
          this.setState({
            selectedDrawing: null
          });
        }
        break;
      }
      case 46:
      case 8: {
        if (this.state.selectedDrawing) {
          const {widget, updateWidget} = this.props;
          let drawingTools = widget.drawingTools;
          drawingTools = drawingTools.filter((item) => item.id != this.state.selectedDrawing.id);
          this.setState({
            selectedDrawing: null
          }, () => updateWidget({
            ...widget,
            drawingTools
          }));
        }
        break;
      }
    }
  }

  updateAllIndicators = () => {
    const chartId = this.props.chartId;
    const {chartView, scaleMin, scaleMax} = this.state;

    const {injectedData: data, drawList} = this.injectIndicatorDataExcluding(["supdemzone"]);
    const supdemzoneIndicator = this.props.indicators.find((item) => item.id === "supdemzone");
    if (supdemzoneIndicator != null) {
      const drawItem = this.state.drawList[supdemzoneIndicator.name + supdemzoneIndicator.idx];
      drawItem[0].data.plotData = this.state.viewModel.quotes ? this.state.viewModel.quotes.data : [];
      if (drawItem != null) {
        drawList[supdemzoneIndicator.name + supdemzoneIndicator.idx] = drawItem;
      }
    }

    const updatedViewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
    this.setState({
      data,
      drawList,
      viewModel: updatedViewModel
    });
  }
  injectIndicatorDataExcluding = (excludeIndicators) => {
    if (excludeIndicators) {
      const {widget: {data: initialData}, indicators, separated } = this.props;
      const {viewModel} = this.state;
      const plotData = viewModel.quotes ? viewModel.quotes.data : [];
      const mergeResult = calculateData(plotData, initialData,
          indicators.filter((item) => item.type === "indicator" &&
        !excludeIndicators.includes(item.id)).filter(item => item.separated === separated));
      const injectedData = mergeResult.mergedData;
      const drawList = mergeResult.indicatorDraws;
      return { injectedData, drawList };
    } else {
      return this.injectIndicatorData();
    }
  }
  injectIndicatorData = () => {
    const {widget: {data: initialData}, indicators, separated } = this.props;
    const viewModel = this.state && this.state.viewModel;
    const plotData = viewModel && viewModel.quotes ? viewModel.quotes.data : [];
    const mergeResult = calculateData(plotData, initialData,
        indicators.filter((item) => item.type === "indicator").filter(item => item.separated === separated));
    const injectedData = mergeResult.mergedData;
    const drawList = mergeResult.indicatorDraws;
    return { injectedData, drawList };
  }
  refreshOrders = (chartView, viewModel) => {
    const {layer, scaleCanvas} = this.state;
    const updatedLayer = updateOrderLines(chartView, layer, scaleCanvas, viewModel.quotes, this.props.orders);
    this.setState({layer: updatedLayer});
  }
  refreshDrawingTools = (chartView, viewModel) => {
    const {moveY, widget} = this.props;
    const {stage, scaleCanvas, layer} = this.state;
    const priceBoxContent = chartView.geometry.boxPrice.content;
    const width = priceBoxContent[2];
    const height = priceBoxContent[3];
    stage.contentWidth = chartView.geometry.boxPrice.content[2];
    stage.moveY = moveY;
    stage.stickLength = chartView.stickLength;
    const drawingTools = widget.drawingTools ? widget.drawingTools : [];
    //layer.removeChildren(0, layer.children.length);
    const exchangeName = widget.exchange;
    const market = widget.name;
    drawingTools.map((item)=>{
      if ( item.exchange != exchangeName || item.market != market) {
        return;
      }
      const type = item.type;
      const x1 = this.getLollipopXPos(chartView, viewModel, item.ts1);
      const y1 = this.getLollipopYPos(chartView, viewModel, item.price1);
      let line = undefined;
      if (item.points == 2){
        const x2 = this.getLollipopXPos(chartView, viewModel, item.ts2);
        const y2 = this.getLollipopYPos(chartView, viewModel, item.price2);
        const cc = window.cc;
        switch (type){
          case 'arrowline':
            line = new cc.Arrow({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'extended':
            line = new cc.TrendLine({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'trendline':
            line = new cc.TrendLine({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'trendangle':
            line = new cc.TrendAngle({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'ray':
            line = new cc.TrendLine({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'gannbox':
            line = new cc.GannBox({ x1, y1, x2, y2}, scaleCanvas);
            break;
          case 'gannfan':
            line = new cc.GannFan({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'fibretracement':
            line = new cc.FibonacciRetracement({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'fibtimezone':
            line = new cc.FibonacciTimeZone({ x1, y1, x2, y2}, scaleCanvas, width, height);
            break;
          case 'fibcycles':
            line = new cc.FibonacciCircles({ x1, y1, x2, y2}, scaleCanvas);
            break;
          case 'fibspeedresistancearcs':
            line = new cc.FibonacciSpeedResistanceArcs({ x1, y1, x2, y2}, scaleCanvas);
            break;
          case 'brush':
            line = new cc.Brush({ x1, y1 }, scaleCanvas);
            const currentStickLength = chartView.stickLength;
            const initStickLength = item.sticklength;
            const ratio = currentStickLength / initStickLength;
            const lines = item.lines;
            lines.map((pos)=>{
              const newX = x1 + pos[0] * ratio;
              const newY = this.getLollipopYPos(chartView, viewModel, pos[1]);
              line.push({x: newX, y: newY});
            });
            break;
          case 'rectangle':
            line = new cc.Rect({ x1, y1, x2, y2}, scaleCanvas);
            break;
          default:
            line = new cc.TrendLine({ x1, y1, x2, y2}, scaleCanvas);
        }
      } else if (item.points == 3){
        const x2 = this.getLollipopXPos(chartView, viewModel, item.ts2);
        const y2 = this.getLollipopYPos(chartView, viewModel, item.price2);
        const y3 = this.getLollipopYPos(chartView, viewModel, item.price3);
        const x3 = this.getLollipopXPos(chartView, viewModel, item.ts3);
        const cc = window.cc;
        switch (type){
          case 'parallelchannel':
            line = new cc.ParallelChannel({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'pitchfork':
            line = new cc.PitchFork({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'schiffpitchfork':
            line = new cc.SchiffPitchFork({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'modifiedschiffpitchfork':
            line = new cc.ModifiedSchiffPitchFork({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'insidepitchfork':
            line = new cc.InsidePitchFork({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'pitchfan':
            line = new cc.PitchFan({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'trendbasedfibextension':
            line = new cc.TrendBasedFibonacciExtension({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'fibspeedresistancefan':
            line = new cc.FibonacciSpeedResistanceFan({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'trendbasedfibtime':
            line = new cc.TrendBasedFibonacciTimeZone({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'fibwedge':
            line = new cc.FibonacciWedge({ x1, y1, x2, y2, x3, y3 }, scaleCanvas);
            break;
          case 'fibchannel':
            line = new cc.FibonacciChannel({ x1, y1, x2, y2, x3, y3 }, scaleCanvas, width, height);
            break;
          case 'ellipse':
            const r12Ratio = item.ts3;
            line = new cc.Ellipse({ x1, y1, x2, y2, x3: r12Ratio, y3: null}, scaleCanvas);
            break;
          case 'triangle':
            line = new cc.Triangle({ x1, y1, x2, y2, x3, y3 }, scaleCanvas);
            break;
          default:
            line = new cc.Triangle({ x1, y1, x2, y2, x3, y3 }, scaleCanvas);
            break;
        }
      } else if (item.points == 1){
        const cc = window.cc;
        switch (type){
          case 'horizontalline':
            line = new cc.HorizontalLine({x: x1, y: y1}, scaleCanvas, width, height);
            break;
          case 'horizontalray':
            line = new cc.HorizontalRay({x: x1, y: y1}, scaleCanvas, width, height);
            break;
          case 'verticalline':
            line = new cc.VerticalLine({x: x1, y: y1}, scaleCanvas, width, height);
            break;
          case 'text':
            line = new cc.TextDrawing({x: x1, y: y1}, scaleCanvas);
            break;
          default:
            line = new cc.HorizontalLine({x: x1, y: y1}, scaleCanvas, width, height);
            break;
        }
      }
      line.id = item.id;
      line.isDrawing = false;
      this.applyDrawingSetting(line, {
        ...(item.settings.inputs || {}),
        ...(item.settings.plots || {}),
      });
      layer.appendChildren(line);
    });
    this.setState({
      stage,
      layer
    });
  }
  applyDrawingSetting = (line, settings) => {
    if (settings.lineColor){
      line.setStrokeColor = settings.lineColor.hex;
    }
    if (settings.lineThickness){
      line.setStrokeWidth = settings.lineThickness.value;
    }
    if (settings.lineStyle){
      line.setLineStyle = settings.lineStyle.value;
    }
    if (settings.lineExtend && settings.lineExtend.values){
      const values = settings.lineExtend.values;
      line.setExtend = values;
      // if (line.horizontalLines) {
      //   line.horizontalLines.forEach((item, index) => {
      //     line.horizontalLines[index].setExtend = values;
      //     line.horizontalLines[index].extendLeft = values[1];
      //     line.horizontalLines[index].extendRight = values[0];
      //   });
      // }
    }
    if (settings.fillColor){
      line.setFillColor = settings.fillColor.rgba;
    }
    if (settings.bgRange) {
      line.setBGOpacity = settings.bgRange.value;
    }
    const levels = settings.levels;
    if (levels){
      line.setLevels = levels.levelUI.levels;
      if (levels.levelUI.bgRange) {
        line.setBGOpacity = levels.levelUI.bgRange;
      }
      if (levels.levelUI.lineExtend) {
        line.setExtend = levels.levelUI.lineExtend;
      }
    }
    if (settings.fontColor){
      line.setFontColor = settings.fontColor.rgba;
    }
    if (settings.fontSize){
      line.setFontSize = settings.fontSize.value * 2;
    }
    if (settings.fontBold){
      line.setFontBold = settings.fontBold.checked;
    }
    if (settings.fontItalic){
      line.setFontItalic = settings.fontItalic.checked;
    }
    if (settings.textField){
      line.setText = settings.textField.text;
    }
  }
  getLollipopYPos = (chartView, viewModel, value) => {
    const quotes = viewModel.quotes;
    const boxContent = chartView.geometry.boxPrice.content;
    const height = boxContent[3];
    const max = quotes.max;
    const min = quotes.min;
    const ratio = height / (max - min);
    const screen = height - (value - min) * ratio;
    const y = screen + boxContent[1];
    return y;
  }
  getLollipopXPos = (chartView, viewModel, ts) =>{
    const {moveY, widget, chartOffset} = this.props;
    const quotes = viewModel.quotes.data;
    const dtTS = ts - quotes[quotes.length - 1].timestamp;
    const period = widget.basePeriod.to.min;
    const dsBars = dtTS / period / 60000;
    const boxContent = chartView.geometry.boxPrice.content;
    const stickLength = chartView.stickLength;
    const stickMargin = chartView.stickMargin;
    const xStart = boxContent[2] - stickLength * (4 + (chartOffset > 0 ? chartOffset : 0)) + stickMargin - moveY * 2;
    const newX = xStart + stickLength * dsBars + stickLength / 2 - 4;
    return newX;
  }
  drawingOver = (e) => {
    this.setState({
      selectedDrawing: e.target === this.state.stage ? null : e.target
    });
  }
  drawingClicked = (e) => {
    const orderIdMatch = removeOrderRegexp.exec(e.target.id) || [null, null];
    if (orderIdMatch[1]) {
      const order = this.props.orders.find(o => o.id === orderIdMatch[1]);
      if (order) {
        this.props.cancelOrderAction(order.accountName, order);
      }
      return;
    }
    this.setState({
      selectedDrawing: e.target === this.state.stage ? null : e.target
    });
  }
  highlightOrStartDrawLine = (e) => {
    const { target } = e;
    const cc = window.cc;
    const {stage} = this.state;
    const {drawingPoints} = this.props;
    if (target !== stage) {
      const lines = cc.from(stage).selectAll((l) => l.isSelected === true);
      if (lines.size()){
        lines.forEach((l) => l.isSelected = false);
      }
      target.isSelected = true;
    } else {
      const lines = cc.from(stage).selectAll((l) => l.isSelected === true);
      if (lines.size()) {
        lines.forEach((l) => l.isSelected = false);
      } else {
        if (drawingPoints == 1){
          this.onePointStartDrawLine(e);
        } else if (drawingPoints == 2){
          this.twoPointStartDrawLine(e);
        } else if (drawingPoints == 3){
          this.threePointStartDrawLine(e);
        }
      }
    }
  };
  handleDrawingToolContextMenu = (id, layerX, layerY) => {
    if (id && !id.includes("order")){
      const drawingToolContextMenuPos = [layerX, layerY];
      const drawingToolContextMenuItemId = id;
      const drawingToolContextMenuOpen = true;
      this.setState({
        drawingToolContextMenuPos,
        drawingToolContextMenuItemId,
        drawingToolContextMenuOpen
      });
      document.addEventListener('click', this.closeDrawingToolContextMenu);
    }
  }
  closeDrawingToolContextMenu = () => {
    this.setState({
      drawingToolContextMenuOpen: false
    });
    document.removeEventListener('click', this.closeDrawingToolContextMenu);
  }
  handleChartContextenu = (e) => {
    const {layerX, layerY} = e;
    const {drawingToolContextMenuOpen, chartView, viewModel} = this.state;
    const {handleToggleChartMenu, moveY, cursorData} = this.props;
    if (!drawingToolContextMenuOpen) {
      const quotes = viewModel.quotes;
      const eventData = getDatePriceData(chartView, [cursorData[0][0], cursorData[0][1]], quotes, moveY);
      const close = eventData[1];
      handleToggleChartMenu(true, [layerX, layerY], {close});
      document.addEventListener('click', this.closeChartContextMenu);
    } else {
      handleToggleChartMenu(false);
    }
  }
  closeChartContextMenu = (e) => {
    const {handleToggleChartMenu} = this.props;
    handleToggleChartMenu(false);
    document.removeEventListener('click', this.closeChartContextMenu);
  }
  updateDrawingTool = (id, x1, y1, x2, y2, x3, y3, meta) => {
    const {updateWidget, widget, moveY} = this.props;
    const {viewModel, chartView} = this.state;
    const quotes = viewModel.quotes;
    const eventData1 = getDatePriceData(chartView, [x1, y1], quotes, moveY);
    const ts1 = eventData1[0].timestamp;
    const price1 = eventData1[1];
    const drawingTools = widget.drawingTools;
    drawingTools.map((item)=>{
      if (item.id == id){
        item.ts1 = ts1;
        item.price1 = price1;
        if (item.points == 2){
          const eventData2 = getDatePriceData(chartView, [x2, y2], quotes, moveY);
          const ts2 = eventData2[0].timestamp;
          const price2 = eventData2[1];
          item.ts2 = ts2;
          item.price2 = price2;
          if (item.type == 'brush'){
            const lines = meta.map((item)=>{
              const data = getDatePriceData(chartView, [item.x2, item.y2], quotes, moveY);
              const x = item.x2 - x1;
              const y = data[1];
              return [x, y];
            });
            item.lines = lines;
          }
        } else if (item.points == 3){
          const eventData2 = getDatePriceData(chartView, [x2, y2], quotes, moveY);
          const ts2 = eventData2[0].timestamp;
          const price2 = eventData2[1];
          item.ts2 = ts2;
          item.price2 = price2;
          if (item.type == 'ellipse') {
            item.ts3 = x3;
            item.price3 = null;
          } else {
            const eventData3 = getDatePriceData(chartView, [x3, y3], quotes, moveY);
            const ts3 = eventData3[0].timestamp;
            const price3 = eventData3[1];
            item.ts3 = ts3;
            item.price3 = price3;
          }
        }
      }
    });
    updateWidget({
      ...widget,
      drawingTools
    });
  }
  getCandleCenterX = (x) => {
    const {chartView} = this.state;
    const {moveY} = this.props;
    const stickLength = chartView.stickLength;
    const boxPrice = chartView.geometry.boxPrice.content;
    const left = (boxPrice[2] - moveY * 2) % stickLength;
    const newX = parseInt((x - left) / stickLength) * stickLength + stickLength / 2 + left;
    return newX;
  }
  //-------one point drawing----------
  onePointStartDrawLine = (e) => {
    const cc = window.cc;
    const {stage, layer, scaleCanvas, viewModel, chartView} = this.state;
    const {widget, updateWidget, drawingTemplates, moveY} = this.props;
    const type = this.props.drawingItemId;
    const rect = scaleCanvas.getBoundingClientRect();
    let x = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    const y = (e.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
    x = this.getCandleCenterX(x);
    const quotes = viewModel.quotes;
    const eventData = getDatePriceData(chartView, [x, y], quotes, moveY);
    const ts1 = eventData[0].timestamp;
    const price1 = eventData[1];
    let line = undefined;
    switch (type){
      case 'horizontalline':
        line = new cc.HorizontalLine({x, y}, scaleCanvas);
        break;
      case 'horizontalray':
        line = new cc.HorizontalRay({x, y}, scaleCanvas);
        break;
      case 'verticalline':
        line = new cc.VerticalLine({x, y}, scaleCanvas);
        break;
      case 'text':
        line = new cc.TextDrawing({x, y}, scaleCanvas);
        break;
      default:
        line = new cc.HorizontalLine({x, y}, scaleCanvas);
        break;
    }
    line.isDrawing = false;
    line.isSelected = true;

    layer.appendChildren(line);
    stage.off('mousedown', this.highlightOrStartDrawLine);
    this.props.handleDrawingCheck(true);
    this.setState({
      drawingFinished: true
    });
    //Save New Drawing
    let settings = undefined;
    const savedTemps = drawingTemplates[type];
    if (savedTemps && savedTemps['AutoSave']) {
      settings = savedTemps['AutoSave'];
    } else {
      settings = lineSettings[type];
    }
    const newDrawingTool = {
      id: line.id,
      type,
      points: 1,
      ts1,
      price1,
      settings,
      exchange: widget.exchange,
      market: widget.name
    };
    const drawingTools = widget.drawingTools ? [...widget.drawingTools, newDrawingTool] : [newDrawingTool];
    updateWidget({
      ...widget,
      drawingTools
    });
    // stage.on('mousedown', this.highlightOrStartDrawLine);
  };
  //-------two point drawing----------
  twoPointStartDrawLine = (e) => {
    const {stage, layer, scaleCanvas, viewModel, chartView} = this.state;
    const {moveY, drawingTemplates} = this.props;
    const cc = window.cc;
    const type = this.props.drawingItemId;
    const rect = scaleCanvas.getBoundingClientRect();
    let x = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    const y = (e.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
    x = this.getCandleCenterX(x);

    const quotes = viewModel.quotes;
    const eventData = getDatePriceData(chartView, [x, y], quotes, moveY);
    const ts1 = eventData[0].timestamp;
    const price1 = eventData[1];

    stage.contentWidth = chartView.geometry.boxPrice.content[2];
    stage.moveY = moveY;
    stage.stickLength = chartView.stickLength;
    const priceBoxContent = chartView.geometry.boxPrice.content;
    const width = priceBoxContent[2];
    const height = priceBoxContent[3];
    let line = undefined;
    switch (type){
      case 'arrowline':
        line = new cc.Arrow({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'extended':
        line = new cc.ExtendedLine({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'trendline':
        line = new cc.TrendLine({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'trendangle':
        line = new cc.TrendAngle({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'ray':
        line = new cc.Ray({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'gannbox':
        line = new cc.GannBox({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas);
        break;
      case 'gannfan':
        line = new cc.GannFan({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'fibretracement':
        line = new cc.FibonacciRetracement({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'fibtimezone':
        line = new cc.FibonacciTimeZone({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas, width, height);
        break;
      case 'fibcycles':
        line = new cc.FibonacciCircles({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas);
        break;
      case 'fibspeedresistancearcs':
        line = new cc.FibonacciSpeedResistanceArcs({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas);
        break;
      case 'brush':
        line = new cc.Brush({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas);
        brushLines = [];
        brushStartX = x;
        break;
      case 'rectangle':
        line = new cc.Rect({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas);
        break;
      default:
        line = new cc.Arrow({ x1: x, y1: y, x2: x, y2: y}, scaleCanvas);
        break;
    }
    const savedTemps = drawingTemplates[type];
    if (savedTemps && savedTemps['AutoSave']) {
      this.applyDrawingSetting(line, savedTemps['AutoSave']);
    } else {
      this.applyDrawingSetting(line, lineSettings[type]);
    }

    line.isDrawing = true;
    line.isSelected = true;
    layer.appendChildren(line);
    stage.off('mousedown', this.highlightOrStartDrawLine);
    window.addEventListener('mousemove', this.onTwoPointDrawingMouseMove, true);
    // window.addEventListener('blur', function(){ onTwoPointDrawingMouseUp(null);}, true);
    if (type == 'brush'){
      window.addEventListener('mouseup', this.onTwoPointDrawingMouseUp, true);
    } else {
      window.addEventListener('mousedown', this.onTwoPointDrawingMouseUp, true);
    }
    this.setState({
      line,
      ts1,
      price1
    });
  };
  onTwoPointDrawingMouseMove = (e) => {
    const {stage, line, scaleCanvas, chartView, viewModel} = this.state;
    const {drawingItemId, moveY} = this.props;
    e.preventDefault();
    e.stopImmediatePropagation();
    const rect = scaleCanvas.getBoundingClientRect();
    let x = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    const y = (e.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
    if (drawingItemId != 'brush'){
      x = this.getCandleCenterX(x);
    }
    const quotes = viewModel.quotes;
    const eventData = getDatePriceData(chartView, [x, y], quotes, moveY);
    const ts2 = eventData[0].timestamp;
    const price2 = eventData[1];
    if (drawingItemId == 'brush'){
      line.push({x, y});
      brushLines.push([x - brushStartX, price2]);
    } else {
      line.x2 = x;
      line.y2 = y;
    }
    this.setState({
      line,
      ts2,
      price2
    });
    stage.update({x: e.clientX, y: e.clientY});
  };
  onTwoPointDrawingMouseUp = (e) => {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    const {stage, ts1, ts2, price1, price2, line, chartView} = this.state;
    const {widget, drawingItemId, updateWidget, drawingTemplates} = this.props;
    const cc = window.cc;
    const lines = cc.from(stage).selectAll((l) => l.isDrawing === true);
    if (lines.size()) {
      lines.forEach((l) => l.isDrawing = false);
    }
    window.removeEventListener('mousemove', this.onTwoPointDrawingMouseMove, true);
    if (drawingItemId == 'brush'){
      window.removeEventListener('mouseup', this.onTwoPointDrawingMouseUp, true);
    } else {
      window.removeEventListener('mousedown', this.onTwoPointDrawingMouseUp, true);
    }
    this.props.handleDrawingCheck(true);
    this.setState({
      drawingFinished: true
    });
    //Save New Drawing
    const savedTemps = drawingTemplates[drawingItemId];
    let settings = undefined;
    if (savedTemps && savedTemps['AutoSave']) {
      settings = savedTemps['AutoSave'];
    } else {
      settings = lineSettings[drawingItemId];
    }
    const newDrawingTool = {
      id: line.id,
      type: drawingItemId,
      points: 2,
      ts1,
      ts2,
      price1,
      price2,
      settings,
      exchange: widget.exchange,
      market: widget.name
    };
    if (drawingItemId == 'brush'){
      newDrawingTool['lines'] = brushLines;
      newDrawingTool['sticklength'] = chartView.stickLength;
    }
    const drawingTools = widget.drawingTools ? [...widget.drawingTools, newDrawingTool] : [newDrawingTool];
    updateWidget({
      ...widget,
      drawingTools
    });
    // stage.on('mousedown', this.highlightOrStartDrawLine);
  };
  //-------three point drawing----------
  threePointStartDrawLine = (e) => {
    const {stage, layer, scaleCanvas, viewModel, chartView} = this.state;
    const {moveY, drawingTemplates} = this.props;
    const cc = window.cc;
    const type = this.props.drawingItemId;
    const rect = scaleCanvas.getBoundingClientRect();
    let x = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    const y = (e.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
    x = this.getCandleCenterX(x);

    const quotes = viewModel.quotes;
    const eventData = getDatePriceData(chartView, [x, y], quotes, moveY);
    const ts1 = eventData[0].timestamp;
    const price1 = eventData[1];
    stage.contentWidth = chartView.geometry.boxPrice.content[2];
    stage.moveY = moveY;
    stage.stickLength = chartView.stickLength;
    const priceBoxContent = chartView.geometry.boxPrice.content;
    const width = priceBoxContent[2];
    const height = priceBoxContent[3];
    let line = undefined;
    switch (type){
      case 'parallelchannel':
        line = new cc.ParallelChannel({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'pitchfork':
        line = new cc.PitchFork({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'schiffpitchfork':
        line = new cc.SchiffPitchFork({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'modifiedschiffpitchfork':
        line = new cc.ModifiedSchiffPitchFork({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'insidepitchfork':
        line = new cc.InsidePitchFork({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'pitchfan':
        line = new cc.PitchFan({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'trendbasedfibextension':
        line = new cc.TrendBasedFibonacciExtension({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'fibspeedresistancefan':
        line = new cc.FibonacciSpeedResistanceFan({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'trendbasedfibtime':
        line = new cc.TrendBasedFibonacciTimeZone({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'fibwedge':
        line = new cc.FibonacciWedge({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas);
        break;
      case 'fibchannel':
        line = new cc.FibonacciChannel({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas, width, height);
        break;
      case 'ellipse':
        line = new cc.Ellipse({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas);
        break;
      case 'triangle':
        line = new cc.Triangle({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas);
        break;
      default:
        line = new cc.Triangle({ x1: x, y1: y, x2: x, y2: y, x3: x, y3: y }, scaleCanvas);
        break;
    }
    const savedTemps = drawingTemplates[type];
    if (savedTemps && savedTemps['AutoSave']) {
      this.applyDrawingSetting(line, savedTemps['AutoSave']);
    } else {
      this.applyDrawingSetting(line, lineSettings[type]);
    }
    line.isDrawing = true;
    line.isSelected = true;
    this.setState({
      line,
      ts1,
      price1
    });

    layer.appendChildren(line);
    stage.off('mousedown', this.highlightOrStartDrawLine);
    window.addEventListener('mousemove', this.onThreeDrawingMouseMoveP2, true);
    window.addEventListener('mousedown', this.handleP3, true);
    // window.addEventListener('blur', function(){ onDrawingMouseUp(null);}, true);
  };
  onThreeDrawingMouseMoveP2 = (e) => {
    const {stage, line, scaleCanvas, viewModel, chartView} = this.state;
    const {moveY} = this.props;
    e.preventDefault();
    e.stopImmediatePropagation();
    const rect = scaleCanvas.getBoundingClientRect();
    let x = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    const y = (e.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
    x = this.getCandleCenterX(x);
    const quotes = viewModel.quotes;
    const eventData = getDatePriceData(chartView, [x, y], quotes, moveY);
    const ts2 = eventData[0].timestamp;
    const price2 = eventData[1];
    this.setState({
      ts2,
      price2
    });
    line.x2 = x;
    line.y2 = y;
    line.x3 = x;
    line.y3 = y;
    stage.update({x: e.clientX, y: e.clientY});
  };
  handleP3 = (e) => {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    window.removeEventListener('mousemove', this.onThreeDrawingMouseMoveP2, true);
    window.addEventListener('mousemove', this.onThreeDrawingMouseMoveP3, true);
    window.removeEventListener('mousedown', this.handleP3, true);
    window.addEventListener('mousedown', this.onThreeDrawingMouseUp, true);
  }
  onThreeDrawingMouseMoveP3 = (e) => {
    const {stage, line, scaleCanvas, viewModel, chartView} = this.state;
    const {drawingItemId, moveY} = this.props;
    e.preventDefault();
    e.stopImmediatePropagation();
    const rect = scaleCanvas.getBoundingClientRect();
    let x = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    let y = (e.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
    if (drawingItemId != 'ellipse'){
      x = this.getCandleCenterX(x);
    }
    line.x3 = x;
    line.y3 = y;
    if (drawingItemId == 'ellipse') {
      x = line.lollipops[2].cx;
      y = line.lollipops[2].cy;
      line._x3 = x;
      line._y3 = y;
    }
    const quotes = viewModel.quotes;
    const eventData = getDatePriceData(chartView, [x, y], quotes, moveY);
    const ts3 = eventData[0].timestamp;
    const price3 = eventData[1];
    if (drawingItemId == 'ellipse') {
      this.setState({
        ts3: line.currentR2 / line.currentR1,
        price3: null
      });
    } else {
      this.setState({
        ts3,
        price3
      });
    }
    stage.update({x: e.clientX, y: e.clientY});
  };
  onThreeDrawingMouseUp = (e) => {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    const {stage, ts1, ts2, price1, price2, ts3, price3, line} = this.state;
    const {updateWidget, drawingItemId, widget, drawingTemplates} = this.props;
    const cc = window.cc;
    const lines = cc.from(stage).selectAll((l) => l.isDrawing === true);
    if (lines.size()) {
      lines.forEach((l) => l.isDrawing = false);
    }
    window.removeEventListener('mousemove', this.onThreeDrawingMouseMoveP3, true);
    window.removeEventListener('mousedown', this.onThreeDrawingMouseUp, true);
    this.props.handleDrawingCheck(true);
    this.setState({
      drawingFinished: true
    });
    //Save New Drawing
    const savedTemps = drawingTemplates[drawingItemId];
    let settings = undefined;
    if (savedTemps && savedTemps['AutoSave']) {
      settings = savedTemps['AutoSave'];
    } else {
      settings = lineSettings[drawingItemId];
    }
    const newDrawingTool = {
      id: line.id,
      type: drawingItemId,
      points: 3,
      ts1,
      ts2,
      ts3,
      price1,
      price2,
      price3,
      settings,
      exchange: widget.exchange,
      market: widget.name
    };
    const drawingTools = widget.drawingTools ? [...widget.drawingTools, newDrawingTool] : [newDrawingTool];
    updateWidget({
      ...widget,
      drawingTools
    });
    // stage.on('mousedown', this.highlightOrStartDrawLine);
  };
  cursorMoveHandler = (event) => {
    let {scaleCanvas, chartView, dragStartY, viewModel, previousRange, data, drawList} = this.state;
    const priceBoxPadding = chartView.geometry.boxPrice.padding;
    const {setCursor, chartId} = this.props;
    const rect = scaleCanvas.getBoundingClientRect();
    const mouseX = (event.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    const mouseY = (event.clientY - rect.top) / (rect.bottom - rect.top) * scaleCanvas.height;
    const cursor = [mouseX, mouseY];
    //todo: handle orders hover
    setCursor(cursor, chartId);
    if (mouseX > priceBoxPadding[0] && mouseX < priceBoxPadding[0] + priceBoxPadding[2] &&
      mouseY > priceBoxPadding[1] && mouseY < priceBoxPadding[1] + priceBoxPadding[3]){
      setCursor(cursor, chartId);
    } else if (mouseX > priceBoxPadding[0] && mouseX < scaleCanvas.width &&
      mouseY > priceBoxPadding[1] && mouseY < priceBoxPadding[1] + priceBoxPadding[3]) {
      setCursor([-100, -100 * 2], chartId);
    } else {
      setCursor([-100 * 2, -100 * 2], chartId);
    }
    if (mouseX > priceBoxPadding[0] + priceBoxPadding[2] && dragStartY && dragStartY > 0){
      const delta = dragStartY - event.clientY;
      const pixelperunit = priceBoxPadding[3] / (previousRange[1] - previousRange[0]);
      const multiply = delta > 0 ? 1 : -1;
      const moveunit = Math.abs(delta) / pixelperunit;
      const scaleMax = previousRange[1] + moveunit * multiply;
      const scaleMin = previousRange[0] - moveunit * multiply;
      viewModel = updateViewModel(chartId, drawList, data, chartView, scaleMin, scaleMax, this.props.capacity);
      this.setState({
        viewModel,
        scaleMax,
        scaleMin
      });
    }
  }
  mouseDown = (e) => {
    const {scaleCanvas, chartView, viewModel} = this.state;
    const rect = scaleCanvas.getBoundingClientRect();
    const mouseX = (e.clientX - rect.left) / (rect.right - rect.left) * scaleCanvas.width;
    const priceBoxPadding = chartView.geometry.boxPrice.padding;
    if (mouseX > priceBoxPadding[0] + priceBoxPadding[2]){
      this.setState({
        dragStartY: e.clientY,
        previousRange: [viewModel.quotes.min, viewModel.quotes.max]
      });
    }
  }
  moveUp = (e) => {
    this.setState({
      dragStartY: undefined,
      previousRange: undefined,
    });
  }

  render() {
    const {chartId, onMouseDown, onMouseMove, onMouseUp, onWheel, updateWidget, widget, handleToggleModal, moveY,
      cursorData, indicators, separated, orders, chartOffset} = this.props;
    indicators.sort((a, b)=>{
      return a.id == 'vol' ? -1 : b.id == 'vol' ? 1 : 0;
    });
    const {chartView, viewModel, drawList, cursorPriceTime, layer} = this.state;
    if (this.state.selectedDrawing != null && widget.drawingTools) {

      if (this.state.selectedDrawing) {
        const selectedCanvasDrawing = layer.children.find(drawing => drawing.id === this.state.selectedDrawing.id);
        if (selectedCanvasDrawing) {
          selectedCanvasDrawing.isSelected = true;
        }
      }
    }

    if (chartView && viewModel){
      chartView.ctx.clearRect(
          0,
          0,
          chartView.width * 2,
          chartView.height * 2
      );
      scaleGrid(chartView, viewModel.quotes, viewModel.priceLines, viewModel.timeLines, moveY);
      drawAxes(chartView, viewModel.quotes, viewModel.priceLines, viewModel.timeLines, moveY);
      if (chartId.slice(0, 9) == 'mainchart'){
        price(chartView, viewModel.quotes, moveY, chartOffset);
        if (orders) {
          displayOrderPrices(chartView, viewModel.quotes, orders);
        }
      }
    }
    return (
      <div
        id={chartId}
        className={`${styles.Chart} chart`}
      >
        <canvas
          style={{width: '100%', height: '100%'}}
          className={styles.ChartCanvas}
        />
        <canvas
          style={{width: '100%', height: '100%'}}
          className={styles.ChartCanvasScale}
          onClick={(e)=>{
          }}
          onMouseMove={(e)=>{
            this.cursorMoveHandler(e);
            if (this.state.drawingFinished){
              onMouseMove(e);
            }
          }}
          onMouseDown={(e)=>{
            if (this.state.drawingFinished){
              this.mouseDown(e);
              onMouseDown(e);
            }
          }}
          onMouseUp={(e)=>{
            if (this.state.drawingFinished){
              this.moveUp(e);
              onMouseUp(e);
            }
          }}
          onWheel={(e)=>{
            if (this.state.drawingFinished){
              onWheel(e);
            }
          }}
        />
        {
          chartId.slice(0, 9) == 'mainchart' ?
            <OHLCTooltip
              cursorData={cursorPriceTime}
              view={chartView}
            /> : null
        }
        {
          chartId.slice(0, 9) == 'mainchart' ?
            <Watermark
              widget={widget}
            /> : null
        }
        {
          chartId.slice(0, 9) == 'mainchart' ?
            <DrawingToolContextMenu
              drawingToolContextMenuPos={this.state.drawingToolContextMenuPos}
              drawingToolContextMenuItemId={this.state.drawingToolContextMenuItemId}
              drawingToolContextMenuOpen={this.state.drawingToolContextMenuOpen}
              handleToggleModal={handleToggleModal}
              updateWidget={updateWidget}
              widget={widget}
            /> : null
        }
        {
          indicators.filter((item) => (separated === item.separated)).map((indicator, idx)=>{
            let quotes = viewModel.quotes;
            if (chartId.slice(0, 9) == 'mainchart') {
              if (indicator.id == 'vol') {
                quotes = viewModel.volquotes;
              }
            }
            return (
              <Indicator
                key={idx}
                chartView={chartView}
                cursorData={cursorData}
                moveY={moveY}
                indicator={indicator}
                indicatorSeqIdx={idx}
                drawList={drawList[indicator.name + indicator.idx]}
                handleToggleModal={this.props.handleToggleModal}
                quotes={quotes}
                widget={widget}
                chartOffset={chartOffset}
              />);
          })
        }
      </div>
    );
  }
}

Chart.propTypes = {
  chartId: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  cursorData: PropTypes.array,
  drawingItemId: PropTypes.string,
  drawingPoints: PropTypes.number,
  drawingTemplates: PropTypes.object,
  width: PropTypes.number,
  handleDrawingCheck: PropTypes.func,
  handleToggleChartMenu: PropTypes.func,
  handleToggleModal: PropTypes.func,
  height: PropTypes.number,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  chartOffset: PropTypes.number,
  minOffset: PropTypes.number,
  moveY: PropTypes.number,
  onMouseDown: PropTypes.func,
  dragStartX: PropTypes.func,
  orders: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseMove: PropTypes.func,
  onWheel: PropTypes.func,
  separated: PropTypes.bool.isRequired,
  setCursor: PropTypes.func,
  updateWidget: PropTypes.func,
  widget: PropTypes.object,
  zoom: PropTypes.number,
  cancelOrderAction: PropTypes.func,
  indicators: PropTypes.array,
  capacity: PropTypes.number
};

Chart.defaultProps = {
  chartId: '0',
  chartOffset: 0,
  drawingItemId: 'cross',
  drawingPoints: 0,
  moveY: 0,
  config: {},
  handleDrawingCheck: () => {},
  height: 200,
  widget: {},
  zoom: 8,
  capacity: 0,
};

export default Chart;
