import {
  GET_ACCOUNTS, GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_ERROR,
  GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR,
  ADD_ACCOUNT, ADD_ACCOUNT_SUCCESS, ADD_ACCOUNT_ERROR,
  SAVE_ACCOUNT, SAVE_ACCOUNT_SUCCESS, SAVE_ACCOUNT_ERROR,
  DELETE_ACCOUNT, DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_ERROR,
} from "../const";

const initialState = {
  accounts: {
    loading: false,
    data: [],
    error: null,
  },
  account: {
    loading: false,
    data: {},
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
          error: null,
        },
      };
    case GET_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    case GET_ACCOUNT:
      return {
        ...state,
        account: {
          loading: true,
          data: {},
          error: null,
        },
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: {
          loading: false,
          data: action.accounts,
          error: null,
        },
      };
    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        account: {
          loading: false,
          data: {},
          error: action.error,
        },
      };
    case ADD_ACCOUNT:
    case SAVE_ACCOUNT:
    case DELETE_ACCOUNT:
      return {
        ...state,
        account: {
          loading: true,
          data: {},
          error: null,
        },
      };
    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          data: [...state.accounts.data, action.account],
        },
      };
    case SAVE_ACCOUNT_SUCCESS:
      return {...state};
      // ...state,
      // accounts: {
      //   ...state.accounts,
      //   data: state.accounts.data.map((account) => {
      //     if (account.id === action.account._id) {
      //       return action.account.data;
      //     } else {
      //       return account;
      //     }
      //   }),
      // },
      // };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          data: state.accounts.data.filter((account) => account._id !== action.account),
        },
      };
    case ADD_ACCOUNT_ERROR:
    case SAVE_ACCOUNT_ERROR:
    case DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        account: {
          ...state.account,
          error: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
