import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Button.scss";
const Button = (props) => (
  <button
    className={classNames(styles.Button, props.className)}
    onClick={props.onClick}
    type={props.type}
  >
    {props.label}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  label: "",
  onClick: () => false,
  title: undefined,
  type: "button",
};

export default Button;
