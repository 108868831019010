import {connect} from "react-redux";
import WidgetSettingsModal from "../components/WidgetSettingsModal";
import {toggleModal, updateWidget} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      const indicatorTemplates = state.user.indicatorTemplates;
      return {
        isOpen: state.markets.modals.widgetSettings,
        widget: activeWidget,
        indicatorTemplates,
      };
    }, {
      toggleModal,
      updateWidget,
    },
)(WidgetSettingsModal);
