
export default [
  {
    title: "Pointers",
  }, {
    id: "cross",
    label: "Cross",
    points: 0,
    svg: <img alt="Crosshair" src="/img/icons/cross.svg" />
  }, {
    id: "dot",
    label: "Dot",
    points: 0,
    svg: <img alt="Dot" src="/img/icons/dot.svg" />
  }, {
    id: "arrow",
    label: "Cursor",
    points: 0,
    svg: <img alt="Cursor" src="/img/icons/cursor.svg" />
  },
];
