import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputBlock from "./InputBlock";
import ChannelsBlock from "./ChannelsBlock";
import Messages from "./Messages";
import ChannelTitle from "./ChannelTitle";
import styles from "./Messenger.scss";

class Messenger extends Component {
  constructor() {
    super();

    this.state = {
      channelOpened: false,
    };
  }

  toggleChatOpened = () => {
    this.setState({
      channelOpened: !this.state.channelOpened,
    });
  };

  render() {
    const {expanded, channel, channelObject, hasNewItems} = this.props;

    return (
      <div className={styles.Messenger}>
        <ChannelsBlock
          {...this.props}
          toggleChatOpened={this.toggleChatOpened}
          channelOpened={expanded || this.state.channelOpened}
        />
        {channel && (
          <div
            className={classNames(styles.Content, {
              [styles.Hidden]: !expanded && this.state.channelOpened,
            })}
          >
            <ChannelTitle
              channel={channelObject}
              toggleChatOpened={this.toggleChatOpened}
            />
            <Messages
              {...this.props}
              channelHasNewItems={!!hasNewItems.find((item) => item.channel === channel)}
            />
            <InputBlock
              {...this.props}
              editingMessage={null}
            />

          </div>
        )}

      </div>
    );
  }
}

Messenger.propTypes = {
  channel: PropTypes.string,
  channelObject: PropTypes.object,
  expanded: PropTypes.bool,
  getChannelsAction: PropTypes.func.isRequired,
  getMessagesAction: PropTypes.func.isRequired,
  getUsersAction: PropTypes.func.isRequired,
  hasNewItems: PropTypes.array,
};

Messenger.defaultProps = {
  channel: undefined,
  channelObject: {},
  expanded: false,
  hasNewItems: [],
};

export default Messenger;
