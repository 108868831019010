import {Row, Col, TabContent, TabPane, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink} from "reactstrap";
import React, {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {curry} from "lodash";
import {isModalOpen} from "../../selectors/markets";
import {toggleModal as toggleModalAction} from "../../actions/MarketsActions";
import styles from './WidgetFilter.module.scss';
import {getActiveWidget} from "../../selectors/wigdet";
import {getWidgetSettings} from "../../selectors/wigdetScanner";
import {doUpdateState} from "../../actions/UserAction";
import Select from 'react-select';
import ColorPicker from "../ColorPicker/ColorPicker";
import classes from './WidgetFilter.module.scss';
import WidgetFilterAddButton from "./WidgetFilterAddButton";
import WidgetFilterDeleteButton from "./WidgetFilterDeleteButton";
import WidgetFilterEditButton from "./WidgetFilterEditButton";
import FontAwesome from "../FontAwesome";

export default () => {
  const activeWidget = useSelector(getActiveWidget);
  const isOpen = useSelector(curry(isModalOpen)('widgetFilterModal'));
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(toggleModalAction("widgetFilterModal"));
  const settings = useSelector(state => getWidgetSettings(activeWidget.i, state));
  const [settingsPatterns, setSettingsPatterns] = useState(settings);
  const handleChangeSettings = name => data => setSettingsPatterns({
    ...settingsPatterns,
    [name]: data
  });
  const handleSaveChanges=()=>{
    dispatch(updateSettings(activeWidget.i, settingsPatterns));
    dispatch(doUpdateState());
    dispatch(toggleModalAction("widgetFilterModal"));
  };
  const OPERATORS=[
    {value: "less than", value: "<"},
    {label: "more than", value: ">"},
    {label: "equal", value: "="},
  ];
  const SECTORS=[
    {value: "Energy", value: "energy"},
    {label: "Education", value: "education"},
    {label: "Hospitality", value: "hospitality"},
  ];
  return (<Modal
    className={styles.WidgetFilterModal}
    autoFocus={false}
    isOpen={isOpen}
    toggle={() => toggleModal("widgetFilterModal")}
  >
    <ModalHeader toggle={() => toggleModal("widgetFilterModal")}>
      Filters <span>& Settings</span>
    </ModalHeader>

    <ModalBody>
      <Fragment>
        <div className={styles.tabHeader}>
          <span className={styles.tabHeadline}><FontAwesome name="filter" className={"iconHighlight"}/> Filter types</span>
          {/*<div className={styles.tabParagraph}>Total script(s) to be added:</div>*/}
          <div className={styles.tabParagraph}>Build and edit different filters below:</div>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.container}>


            <Row style={{width: "100%"}}>
              <div className={styles.patternScript}>
                <Col
                  xs={11}
                  className={styles.accordion}
                >
                  <input type="checkbox" id="1" />
                  <label htmlFor="1">
                    <div className={styles.titleAccordion}>
                      Volume
                    </div>
                    <div className={styles.plusIcon}>
                      <FontAwesome
                        name="chevron-down"
                        prefix="fas"
                        style={{opacity: .5}}
                      />
                    </div>
                  </label>
                  <div className={styles.content}>
                    <div className={styles.paragraph}>Market Cap to Volume ratio is
                      <span
                        className={styles.greyLightInput}
                        style={{marginLeft: 5}}
                      >
                        <Select
                          options={OPERATORS}
                          className="selectClearohcl"
                          classNamePrefix="react-select"
                          //menuIsOpen
                          defaultValue={{label: <span style={{marginLeft: 5}}>less than</span>, value: "<"}}
                          //onChange={point=>handleChangePattern(index, 'point')(point.name)}
                        />
                      </span>
                      <div
                        className={styles.greyLightInput}
                        style={{marginLeft: 5}}
                      >
                        <input
                          className={styles.greyLight}
                          value="2%"
                        />
                      </div>
                    </div>
                    <div className={styles.paragraph}>Volume (Intraday) is
                      <span
                        className={styles.greyLightInput}
                        style={{marginLeft: 5}}
                      >
                        <Select
                          options={OPERATORS}
                          className="selectClearohcl"
                          classNamePrefix="react-select"
                          //menuIsOpen
                          defaultValue={{label: <span style={{marginLeft: 5}}>less than</span>, value: "<"}}
                        //onChange={point=>handleChangePattern(index, 'point')(point.name)}
                        />
                      </span>
                      <div
                        className={styles.greyLightInput}
                        style={{marginLeft: 5}}
                      >
                        <input
                          className={styles.greyLight}
                          value="2%"
                        />
                      </div>
                    </div>

                  </div>
                </Col>
              </div>
            </Row>

            <Row style={{width: "100%"}}>
              <div className={styles.patternScript}>
                <Col
                  xs={11}
                  className={styles.accordion}
                >
                  <input type="checkbox" id="3" />
                  <label htmlFor="3">
                    <div className={styles.titleAccordion}>
                      Sectors
                    </div>
                    <div className={styles.plusIcon}>
                      <FontAwesome
                        name="chevron-down"
                        prefix="fas"
                        style={{opacity: .5}}
                      />
                    </div>
                  </label>
                  <div className={styles.content}>
                    <div className={styles.paragraph}>Sector is
                      <span
                        className={styles.greyLightInput}
                        style={{marginLeft: 5}}
                      >
                        <Select
                          options={SECTORS}
                          className="selectClearSectors"
                          classNamePrefix="react-select"
                          //isSearchable
                          //isMulti
                          //menuIsOpen
                          //placeholder="Add category..."
                          defaultValue={{label: <span style={{marginLeft: 5}}>Energy</span>, value: "<"}}
                        //onChange={point=>handleChangePattern(index, 'point')(point.name)}
                        />
                      </span>
                    </div>
                  </div>
                </Col>

              </div>
            </Row>
            <Row style={{width: "100%"}}>
              <div className={styles.patternScript}>
                <Col
                  xs={11}
                  className={styles.accordion}
                >
                  <input type="checkbox" id="4" />
                  <label htmlFor="4">
                    <div className={styles.titleAccordion}>
                      Change <span>(%)</span>
                    </div>


                    <div className={styles.plusIcon}>
                      <FontAwesome
                        name="chevron-down"
                        prefix="fas"
                        style={{opacity: .5}}
                      />
                    </div>
                  </label>
                  <div className={styles.content}>
                    <div className={styles.paragraph}>Change (%) daily is
                      <span
                        className={styles.greyLightInput}
                        style={{marginLeft: 5}}
                      >
                        <Select
                          options={OPERATORS}
                          className="selectClearohcl"
                          classNamePrefix="react-select"
                          //menuIsOpen
                          defaultValue={{label: <span style={{marginLeft: 5}}>less than</span>, value: "<"}}
                          //onChange={point=>handleChangePattern(index, 'point')(point.name)}
                        />
                      </span>
                      <div
                        className={styles.greyLightInput}
                        style={{marginLeft: 5}}
                      >
                        <input
                          className={styles.greyLight}
                          value="2%"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </div>

        </div>
      </Fragment>
    </ModalBody>
    <div className={styles.bottomButtons}>
      <button
        label="Reset"
        className={styles.ResetButton}
      >
        Reset
      </button>
      <button
        className={styles.SaveButton}
        type="button"
        onClick={handleSaveChanges}
      >
        Save
      </button>
      <button
        className={styles.CancelButton}
        type="button"
        onClick={() => toggleModal("widgetFilterModal")}
      >
        Cancel
      </button>
    </div>
  </Modal>);
};
