import FontAwesome from "../../components/FontAwesome";
import styles from "./WidgetScanner.module.scss";
import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip';

const WidgetScannerEditButton = ({item, handleChangeValue}) =>{
  {/*if (item.isEdit){
    return (<button
      onClick={() => handleChangeValue({
        ...item,
        isEdit: false
      })}>*/}
        <button>
    <a data-tip data-for='tooltipEditFinished'>
            <FontAwesome
        name="check"
        prefix="fa"
        className={styles.editIcon}
      />
          </a>
    <ReactTooltip
            id='tooltipEditFinished'
            offset="{'top': -10, 'left': 10}"
            arrowColor='transparent'
            className={styles.buttonTooltip}
            effect='solid'
    >
            <span>Finished</span>
          </ReactTooltip>
  </button>;
  //}
  return (<button
    onClick={() => handleChangeValue({
      ...item,
      isEdit: true
    })}>
    <a data-tip data-for='tooltipEdit'>
      <FontAwesome
        name="pen"
        prefix="fa"
        className={styles.editIcon}
      />
    </a>
    <ReactTooltip
      id='tooltipEdit'
      offset="{'top': 20, 'left': -78}"
      arrowColor='transparent'
      className={styles.buttonTooltip}
      effect='solid'
    >
      <span>Edit title</span>
    </ReactTooltip>
  </button>);
};

WidgetScannerEditButton.propTypes = {
  item: PropTypes.object.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
};
export default WidgetScannerEditButton;
