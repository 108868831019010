import React, {Component} from "react";
import PropTypes from "prop-types";
import {PieChart, Pie, Label} from "recharts";
import styles from "./TotalChart.scss";
import FloatValue from "../../../components/FloatValue";

const CHART_WIDTH = 250;

class TotalChart extends Component {
  constructor() {
    super();

    this.chartWrapper = null;
  }

  render() {
    //debugger;
    const {data, totalBTC, totalUSDT} = this.props;
    let size = 0;

    if (this.chartWrapper) {
      size = this.chartWrapper.clientWidth > CHART_WIDTH ? CHART_WIDTH : this.chartWrapper.clientWidth;
    }
    return (
      <div className={styles.Wrapper}>
        <div className={styles.Value}>
          <div className={styles.ValueTotal}>Total Balance</div>
          <div className={styles.box}>
            <img alt="BTC" className={styles.menuIcon} src={`/img/icons/BTC.svg`}/>&nbsp; <FloatValue value={totalBTC}/>
          </div>
          <div className={styles.box}>
            <img alt="USDT" className={styles.menuIcon} src={`/img/icons/USDT.svg`}/>&nbsp; <FloatValue value={totalUSDT}/>
          </div>
        </div>
        <div className={styles.Chart} ref={(el) => this.chartWrapper = el}>
          {size > 0 && (
            <PieChart
              width={size}
              height={size}
            >
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={70}
              >
                <Label
                  value={data.length || 0}
                  position="centerBottom"
                  fontSize='27px'
                  fill="#ffffff"
                />
                <Label
                  value="EXCHANGES"
                  position="centerTop"
                  className={styles.labelBottom}
                  fill="rgba(255, 255, 255, 0.75)"
                  fontSize='12px'
                />
              </Pie>
            </PieChart>
          )}
        </div>
      </div>
    );
  }
}

TotalChart.propTypes = {
  data: PropTypes.array,
  totalBTC: PropTypes.number,
  totalUSDT: PropTypes.number,
};

TotalChart.defaultProps = {
  data: [],
  totalBTC: 0,
  totalUSDT: 0,
};

export default TotalChart;
