import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {sortBy} from "lodash";
import FontAwesome from "../../../components/FontAwesome";
import PerfectScrollbar from "react-perfect-scrollbar";
import {ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle} from "reactstrap";
import {Helmet} from "react-helmet";

import Button from "../../../components/Button";
import AccountModal from "./AccountModal";
import Collapse from "../../../components/Collapse";
import Table from "../../../components/Table";
import styles from "./AccountsPage.scss";

class AccountsPage extends Component {
  constructor(props) {
    super(props);

    this.interval = null;
    this.state = {
      account: {},
      modalOpen: false,
      sortingKey: null,
      sortingType: null,
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    this.props.init();
    this.interval = setInterval(this.props.init, 10 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleToggle = (data) => {
    this.setState({
      account: {},
      modalOpen: !this.state.modalOpen,
    });

    if (data) {
      if (data._id) {
        this.props.updateAccount(data._id, data);
      } else {
        this.props.addAccount(data);
      }
    }
  };

  handleToggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  drawActions = () => {
    const {accounts, user: {isPaid}} = this.props;
    return (
      <div className="buttons">
        <ButtonDropdown
          isOpen={this.state.dropdownOpen}
          toggle={() => this.handleToggleDropdown()}
        >
          {/* eslint-enable */}
          <DropdownToggle
            type="button"
            onClick={() => this.handleToggleDropdown()}
          >
            <FontAwesome
              name='bars'
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-right">
            <DropdownItem
              disabled={!isPaid && accounts.length >= 1}
              onClick={() => (isPaid || (!isPaid && accounts.length < 1)) && this.handleToggle()}
            >
              <FontAwesome name="plus" prefix="far"/> Add New Account
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  };

  onSortClick = (key, type) => this.setState({sortingKey: key, sortingType: type});

  sortData = (items) => {
    const {sortingKey, sortingType} = this.state;
    const sortedData = sortingKey ? sortBy(items, (item) => item[sortingKey]) : items;

    return sortingType === "desc" ? sortedData.reverse() : sortedData;
  };

  drawContent = () => (
    <Table
      fields={[
        {key: "number", label: "#", disableSort: true},
        {key: "exchange", label: "Exchange"},
        {key: "apikey", label: "ApiKey"},
        {key: "secret", label: "Secret"},
        {key: "actions", label: "Edit", disableSort: true},
        {key: "delete", label: "Delete", disableSort: true},
      ]}
      sort={this.onSortClick}
    >
      {this.sortData(this.props.accounts).map((account, idx) => (
        <tr key={account._id}>
          <td>{idx + 1}</td>
          <td className={styles.exchange}>
            <Fragment>
              <img
                alt={account.exchange}
                className={styles.Icon}
                height={20}
                style={{marginRight: 5}}
                src={`/img/exchanges/${account.exchange.toLowerCase()}.png`}
              />
              {`${account.exchange.toUpperCase()}`}<span className={styles.accountName}> [{`${account.name}`}]</span>
            </Fragment>
          </td>
          <td>{account.apikey}</td>
          <td>{account.secret}</td>
          <td>
            <Button
              className={styles.ActionButton}
              label={"Edit"}
              onClick={() => {
                this.handleToggle();
                this.setState({account});
              }}
            />
          </td>
          <td>
            <Button
              className={styles.ActionButton}
              label={"Delete"}
              onClick={() => this.props.removeAccount(account._id, account)}
            />
          </td>
        </tr>
      ))}
    </Table>
  );

  render() {
    const {account, modalOpen} = this.state;
    return (
      <PerfectScrollbar options={{suppressScrollX: true}}>
        <Helmet>
          <title>Accounts</title>
        </Helmet>
        <div className={styles.AccountsPage}>
          <Collapse
            label="Accounts"
            actions={this.drawActions()}
            expanded
          >
            {this.drawContent()}
          </Collapse>
          <AccountModal
            {...this.props}
            account={account}
            open={modalOpen}
            onHandleModalToggle={this.handleToggle}
          />
        </div>
      </PerfectScrollbar>
    );
  }
}

AccountsPage.propTypes = {
  accounts: PropTypes.array,
  addAccount: PropTypes.func,
  init: PropTypes.func,
  removeAccount: PropTypes.func,
  updateAccount: PropTypes.func,
  user: PropTypes.object,
};

AccountsPage.defaultProps = {
  accounts: [],
  addAccount: () => false,
  init: () => false,
  removeAccount: () => false,
  updateAccount: () => false,
  user: {},
};

export default AccountsPage;
