import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Collapse from "../../../../components/Collapse";

import styles from "../SettingsPage.scss";

class SecurityPage extends Component {
  constructor() {
    super();

    this.state = {
      password: "",
      confirmPassword: "",
      token: "",
    };
  }

  componentDidMount() {
    this.props.getTmpSecret();
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleSavePassword = () => {
    const {password, confirmPassword} = this.state;
    if (password === confirmPassword) {
      this.props.editUserAction({
        password: password,
      });
    }
  };

  drawPasswordSettings = () => {
    return (
      <Fragment>
        <div className={styles.InputGroup}>
          <label>New password</label>
          <input
            type="password"
            onChange={(event) => this.handleChange("password", event.target.value)}
            value={this.state.password}
          />
        </div>
        <div className={styles.InputGroup}>
          <label>Confirm new password</label>
          <input
            type="password"
            onChange={(event) => this.handleChange("confirmPassword", event.target.value)}
            value={this.state.confirmPassword}
          />
        </div>
        <button
          className={styles.SaveBtn}
          onClick={this.handleSavePassword}
        >
          Save
        </button>
      </Fragment>
    );
  };

  handleSaveSecret = () => {
    const {editUserAction, tmpSecretKey} = this.props;
    editUserAction({
      secret: {
        token: this.state.token,
        secret: tmpSecretKey,
      },
    });
  };

  drawTFASettings = () => {
    const {tmpSecretImg, tmpSecretKey} = this.props;

    return (
      <Fragment>
        <div className={styles.InputGroup}>
          <label>Token</label>
          <input
            onChange={(event) => this.handleChange("token", event.target.value)}
            type="string"
            value={this.state.token}
          />
          <p className="tokenSecret">2FA secret is: {tmpSecretKey}</p>
          <img src={tmpSecretImg} alt={tmpSecretKey}/>
        </div>
        <button
          className={styles.SaveBtn}
          onClick={this.handleSaveSecret}
        >
          Save
        </button>
      </Fragment>
    );
  };

  render() {
    return (
      <div className={styles.Wrapper}>
        <Collapse
          label="Password"
          description="Change account password"
        >
          {this.drawPasswordSettings()}
        </Collapse>
        <Collapse
          label="2FA"
          description="Edit Two-Factor Authentication"
        >
          {this.drawTFASettings()}
        </Collapse>
      </div>
    );
  }
}

SecurityPage.propTypes = {
  editUserAction: PropTypes.func,
  getTmpSecret: PropTypes.func,
  tmpSecretImg: PropTypes.string,
  tmpSecretKey: PropTypes.string,
};

SecurityPage.defaultProps = {
  editUserAction: () => false,
  getTmpSecret: () => false,
  tmpSecretImg: undefined,
  tmpSecretKey: undefined,
};

export default SecurityPage;
