import {api} from "../utils/apiUtils";

const getUserNameResult = () => api.get("user/me");
const getUserWorksheetsResult = () => api.get("user/getWorksheets");
const doLoginResult = (params) => api.post("user/login", params);
const doGoogleLoginResult = () => api.get("user/googleLogin");
const doSignupResult = (params) => api.post("user/register", params);
const getTmpSecretResult = () => api.get("user/getTmpSecret");
const doCheckTokenResult = (params) => api.post("user/checkTmpSecret", params);
const doUpdateStateResult = (params) => api.post("user/update-state", params);
const doUpdateSettingsResult = (params) => api.post("user/update-settings", {
  settings: params,
});
const updateDrawingTemplatesResult = (params) => api.post("user/updateDrawingsTemplate", params);
const updateIndicatorTemplatesResult = (params) => api.post("user/updateIndicatorTemplate", params);
const getUserDrawingTemplatesResult = () => api.get("user/getDrawingTemplates");
const getUserIndicatorTemplatesResult = () => api.get("user/getIndicatorTemplates");
const doLogoutResult = () => api.get("user/logout");
const editUser = (data) => api.put("user/profile", data);

export {
  getUserNameResult,
  getUserWorksheetsResult,
  doLoginResult,
  doGoogleLoginResult,
  doSignupResult,
  getTmpSecretResult,
  doCheckTokenResult,
  doUpdateStateResult,
  doLogoutResult,
  doUpdateSettingsResult,
  updateDrawingTemplatesResult,
  updateIndicatorTemplatesResult,
  getUserDrawingTemplatesResult,
  getUserIndicatorTemplatesResult,
  editUser,
};
