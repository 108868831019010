import {connect} from "react-redux";
import MarketsPage from "../components/MarketsPage";
import {
  addWidget, getUserWorksheets, addWorksheet, changeWorksheet, removeWorksheet, updateWidget, getMarketSummaries,
  getMarketDom, toggleModal, openTradeWidget,
} from "../../../actions/MarketsActions";
import {fetchOrdersList, fetchOrderHistory, clearTempOrders} from "../../../actions/OrdersActions";
import {doUpdateState, getUserDrawingTemplates, getUserIndicatorTemplates} from "../../../actions/UserAction";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;

      return {
        activeWidget: activeWidget,
        worksheets: state.markets.worksheets,
        currentWorksheet: currentWorksheet,
        user: state.user.user.data,
      };
    }, {
      addWidget,
      openTradeWidget,
      getUserWorksheets,
      getUserDrawingTemplates,
      getUserIndicatorTemplates,
      getMarketDom,
      getMarketSummaries,
      addWorksheet,
      changeWorksheet,
      removeWorksheet,
      doUpdateState,
      fetchOrderHistory,
      fetchOrdersList,
      updateWidget,
      toggleModal,
      clearTempOrders,
    },
)(MarketsPage);
