import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "../../../components/FontAwesome";
import styles from "./ChannelTitle.scss";
import ReactTooltip from 'react-tooltip';

const ChannelTitle = ({channel, user, toggleChatOpened}) => (
  <div className={styles.Wrapper}>
    <button
      className={styles.titleButton}
      onClick={toggleChatOpened}
      type="button"
    >
      {channel.type === "public" && (
        <div>
          <div className={styles.titlehashtag}>
            <FontAwesome prefix="fas" name="hashtag" className={styles.titlehash}/>
            <div className={styles.messagesMenu}>
              <button className={styles.active}>
                Chat
              </button>
              <button>
                Links
              </button>
              <button>
                Files
              </button>
              <button>
                Users
              </button>
              <div className={styles.searchIcon}>
                <FontAwesome
                  name="search"
                  prefix="far"
                />
                <input
                  className={styles.searchChat}
                  placeholder="Search..."/>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.title1st}> {channel.title}</div>
            <div className={styles.title2nd}>Public group &nbsp;&nbsp; / &nbsp;&nbsp;
              <a data-tip data-for='usersTooltip'>
                <FontAwesome className={styles.membersSVG} prefix="fas" name="user"/>
                <span className={styles.userLength}>{channel.members.length}</span>
              </a>
              <ReactTooltip
                id='usersTooltip'
                offset="{'top': -7}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>{channel.title} has {channel.members.length} users</span>
              </ReactTooltip>
            </div>
          </div>
        </div>
      )}
      {channel.type === "team" && (
        <div>
          <div className={styles.titlehashtag}>
            <FontAwesome prefix="fas" name="hashtag" className={styles.titlehash}/>
            <div className={styles.messagesMenu}>
              <button className={styles.active}>
                Chat
              </button>
              <button>
                Links
              </button>
              <button>
                Files
              </button>
              <button>
                Users
              </button>
              <div className={styles.searchIcon}>
                <FontAwesome
                  name="search"
                  prefix="far"
                />
                <input
                  className={styles.searchChat}
                  placeholder="Search..."/>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.title1st}> {channel.title}</div>
            <div className={styles.title2nd}>Private group &nbsp; / &nbsp;&nbsp;
              <a data-tip data-for='usersTooltip'>
                <FontAwesome className={styles.membersSVG} prefix="fas" name="user"/>
                <span className={styles.userLength}>{channel.members.length}</span>
              </a>
              <ReactTooltip
                id='usersTooltip'
                offset="{'top': -7}"
                arrowColor='transparent'
                className={styles.buttonTooltip}
                effect='solid'
              >
                <span>{channel.title} has {channel.members.length} users</span>
              </ReactTooltip>
              {/*<div className={styles.volume}><FontAwesome prefix="fas" name="volume"/></div>
              <div className={styles.video}><FontAwesome prefix="fas" name="video"/></div>
              <div className={styles.audio}><FontAwesome prefix="fas" name="phone"/></div>*/}
            </div>
          </div>
        </div>
      )}
      {channel.type === "direct" && (
        <div>
          <div className={styles.titlehashtag}>
            <FontAwesome prefix="fas" name="hashtag" className={styles.titlehash}/>
            <div className={styles.messagesMenu}>
              <button className={styles.active}>
                Chat
              </button>
              <button>
                Links
              </button>
              <button>
                Files
              </button>
              <button>
                Users
              </button>
              <div className={styles.searchIcon}>
                <FontAwesome
                  name="search"
                  prefix="far"
                />
                <input
                  className={styles.searchChat}
                  placeholder="Search..."/>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.title1st}> {channel.title}</div>
            <div className={styles.title2nd}>Direct Message
              {/*<div className={styles.volume}><FontAwesome prefix="fas" name="volume"/></div>
              <div className={styles.audio}><FontAwesome prefix="fas" name="video"/></div>
              <div className={styles.audio}><FontAwesome prefix="fas" name="phone"/></div>*/}
            </div>
          </div>
        </div>
      )}

    </button>
  </div>
);

ChannelTitle.propTypes = {
  channel: PropTypes.object,
  toggleChatOpened: PropTypes.func,
  user: PropTypes.object,
};

ChannelTitle.defaultProps = {
  channel: {},
  toggleChatOpened: () => false,
  user: {},
};

export default ChannelTitle;
