/* eslint no-unused-vars: 0 */
const {runCode} = require('./parser');

const mergeData = (indicatorData, data) => {
  return data.map((item, index) => {
    const indicator = {};
    Object.keys(indicatorData).forEach((key) => {
      if (indicatorData[key]) {
        if (Array.isArray(indicatorData[key]) && indicatorData[key].length > 0){
          const modifier = data.length - indicatorData[key].length;
          if (index >= modifier) {
            indicator[key] = indicatorData[key][index - modifier];
          }
        } else {
          indicator[key] = indicatorData[key];
        }
        if (index == data.length-1) {
          indicator[key+"_label"]=true;
        }
      }
    });

    return {
      ...item,
      ...indicator,
    };
  });
};

export const calculateData = (plotData, data, indicators) => {
  const indicatorData = {};
  const indicatorDraws = {};
  indicators.forEach((indicator) => {
    if (indicator.editable){
      const result = runCode(indicator, data, plotData);
      indicatorDraws[indicator.name+indicator.idx] = result.draw;
      const plotSeries = result.plotSeries;
      const swingSeries = result.swingSeries;
      const plotColorSeries = result.plotColorSeries;
      const fillUpperSeries = result.fillUpperSeries;
      const fillLowerSeries = result.fillLowerSeries;
      for ( const key in plotSeries){
        indicatorData['plot_' + indicator.name + indicator.idx + key] = plotSeries[key];
        if (plotColorSeries.length > 0){
          indicatorData['plotColor_' + indicator.name + indicator.idx + key] = plotColorSeries[key];
        }
      }
      for (const key in swingSeries) {
        indicatorData['plot_' + indicator.name + indicator.idx + key] = swingSeries[key];
      }
      for ( const i in fillUpperSeries){
        indicatorData['fill_upper_' + indicator.name + indicator.idx + i] = fillUpperSeries[i];
        indicatorData['fill_lower_' + indicator.name + indicator.idx + i] = fillLowerSeries[i];
      }
    }
  });
  const mergedData = mergeData(indicatorData, data);
  return {mergedData, indicatorDraws};
};
