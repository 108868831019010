import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Modal, ModalBody, ModalHeader, FormGroup, Label, Input, Col} from "reactstrap";
import InputColor from "react-input-color";
import Select from "../../../../components/Select";

import "../ChartSettingsModal.scss";
import styles from "./DomChartSettingsModal.scss";

const chartSettings = [
  {id: "orientation", label: "Axis Orientation", type: "select", value: "right", values: ["left", "right"]},
  {id: "buyFill", label: "Bid Area Fill", type: "color", value: "#5c8971"},
  {id: "sellFill", label: "Ask Area Fill", type: "color", value: "#b50611"},
  {id: "buyStroke", label: "Bid Stroke", type: "color", value: "#19b762"},
  {id: "sellStroke", label: "Ask Stroke", type: "color", value: "#ff0010"},
];

class DomChartSettingsModal extends Component {
  constructor() {
    super();

    this.state = {
      orientation: chartSettings[0].value,
      buyFill: chartSettings[1].value,
      buyOpacity: 0.8,
      sellFill: chartSettings[2].value,
      sellOpacity: 0.8,
    };
  }

  componentDidUpdate(prevProps) {
    const {isOpen, widget} = this.props;
    if (!prevProps.isOpen && isOpen) {
      this.setState({
        ...this.state,
        ...widget.options,
      });
    }
  }

  handleSaveChartSettings = () => {
    const {toggleModal, updateWidget, widget} = this.props;
    updateWidget({
      ...widget,
      options: this.state,
    });
    toggleModal("domChartSettings");
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  drawInput = (option) => {
    switch (option.type) {
      case "color":
        return (
          <FormGroup row key={option.id}>
            <Label sm={6}>{option.label}</Label>
            <Col className="colorInput" sm={6}>
              <InputColor
                alpha={this.state[option.id.replace("Fill", "Opacity")] * 100}
                value={this.state[option.id]}
                defaultValue={option.value}
                onChange={(color) => {
                  const fill = `#${color.hex}`;
                  const opacity = color.a / 100;
                  this.handleChange(option.id, fill);
                  this.handleChange(option.id.replace("Fill", "Opacity"), opacity);
                }}
              /> {this.state[option.id] || option.value} ({this.state[option.id.replace("Fill", "Opacity")]})
            </Col>
          </FormGroup>
        );
      case "select":
        return (
          <FormGroup row key={option.id}>
            <Label sm={6}>{option.label}</Label>
            <Col sm={6}>
              <Select
                value={this.state[option.id] || option.value}
                onChange={(event) => this.handleChange(option.id, event.target.value)}
              >
                {option.values.map((value) => (
                  <option key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Col>
          </FormGroup>
        );
      default:
        return (
          <FormGroup row key={option.id}>
            <Label sm={6}>{option.label}</Label>
            <Col sm={6}>
              <Input
                type={option.type}
                value={this.state[option.id] || option.value}
                onChange={(event) => this.handleChange(option.id, event.target.value)}
              />
            </Col>
          </FormGroup>
        );
    }
  };

  render() {
    const {isOpen, toggleModal} = this.props;

    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={() => toggleModal("domChartSettings")}
        className={classNames("chart-settings-modal", styles.Modal)}
      >
        <ModalHeader toggle={() => toggleModal("domChartSettings")}>
          Settings
        </ModalHeader>
        <ModalBody>
          <div className="activeTitle">
            DOM CHART
          </div>
          <div className="wrapper">
            {chartSettings.map((item) => this.drawInput(item))}
          </div>
          <div className={styles.Buttons}>
            <button
              className="SaveButton"
              onClick={this.handleSaveChartSettings}
              type="button"
            >
              Save
            </button>
            <button
              className="CancelButton"
              onClick={() => toggleModal("domChartSettings")}
              type="button"
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

DomChartSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
};

DomChartSettingsModal.defaultProps = {
  isOpen: false,
  toggleModal: () => false,
  widget: {},
};

export default DomChartSettingsModal;
