import WidgetToolbar from "../../pages/Markets/containers/WidgetToolbarContainer";
import {getWidgetById} from "../../selectors/wigdet";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {curry} from "lodash";
import {widgetMap} from "./widgetMap";
import styles from "./Widget.module.scss";
import {setWidgetActive} from "../../actions/MarketsActions";
const Widget = ({id}) => {
  const widget = useSelector(curry(getWidgetById)(id));
  const Component= widgetMap[widget.type];
  const dispatch=useDispatch();
  return (<div className="widgetWrapper" onClick={()=>dispatch(setWidgetActive(id))}>
    <WidgetToolbar widget={widget}/>
    <div className={styles.WidgetContent}>
      <Component id={id}/>
    </div>
  </div>);
};
Widget.propTypes = {
  id: PropTypes.string.isRequired
};
export default Widget;