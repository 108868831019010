import {connect} from "react-redux";
import DrawingToolSettingsModal from "../components/DrawingToolSettingsModal";
import {toggleModal, updateWidget} from "../../../actions/MarketsActions";
import { updateDrawingTemplate } from "../../../actions/UserAction";
export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const drawingTemplates = state.user.drawingTemplates;
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;

      return {
        isOpen: state.markets.modals.drawingSettings,
        drawingToolId: state.markets.modalData,
        widget: activeWidget,
        drawingTemplates
      };
    }, {
      toggleModal,
      updateWidget,
      updateDrawingTemplate,
    },
)(DrawingToolSettingsModal);
