export default {
  light: {
    colorBackground: '#fff',
    colorBear: '#ef5350',
    colorBull: '#26a69a',
    colorBearBorder: '#ccc',
    colorBullBorder: '#ccc',
    colorGrid: 'rgba(255,255,255,0.3)',
    colorBorder: '#e8e8e8',
    colorScale: '#fff',
    colorCrosshair: '#ccc',
    colorLine: '#666',
    activeOrder: {
      colorBuy: '#3c9265',
      colorSell: '#db5940',
      colorBuyRGBA: {r: 60, g: 146, b: 101, a: 1},
      colorSellRGBA: {r: 219, g: 89, b: 64, a: 1},
    }
  },
  dark: {
    colorBackground: '#000',
    colorBear: '#ef5350',
    colorBull: '#26a69a',
    colorBearBorder: '#dd5f56',
    colorBullBorder: '#51a39a',
    colorGrid: 'rgba(0,0,0,0.3)',
    colorBorder: '#333',
    colorScale: '#aaa',
    colorCrosshair: '#666',
    colorLine: '#aaa',
    activeOrder: {
      colorBuy: '#3c9265',
      colorSell: '#db5940',
      colorBuyRGBA: {r: 60, g: 146, b: 101, a: 1},
      colorSellRGBA: {r: 86, g: 35, b: 25, a: 1},
    }
  },
};
