import {connect} from "react-redux";
import DomChartSettingsModal from "../components/DomChartWidget/DomChartSettingsModal";
import {toggleModal, updateWidget} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const widget = currentWorksheet && state.markets.modalData ?
      currentWorksheet.widgets.find((item) => item.i === state.markets.modalData.id) : null;

      return {
        isOpen: state.markets.modals.domChartSettings,
        widget: widget,
      };
    }, {
      toggleModal,
      updateWidget,
    },
)(DomChartSettingsModal);
