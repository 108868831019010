import {connect} from "react-redux";
import ExchangesModal from "../components/ExchangesModal";
import {toggleModal, updateWidget, getExchangesListAction} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const widget = currentWorksheet && state.markets.modalData && state.markets.modalData.widgetId ?
      currentWorksheet.widgets.find((item) => item.i === state.markets.modalData.widgetId) : null;

      return {
        widget: widget,
        isOpen: state.markets.modals.exchanges,
        exchanges: state.markets.exchanges,
      };
    }, {
      toggleModal,
      updateWidget,
      getExchangesListAction,
    },
)(ExchangesModal);
