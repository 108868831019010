import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import ReactGridLayout from "react-grid-layout";
import {Rnd} from "react-rnd";
import classNames from "classnames";
import BottomTabs from "./BottomTabs";
import TreemapWidgetContainer from "../containers/TreemapWidgetContainer";
import BubbleWidgetContainer from "../containers/BubbleWidgetContainer";
import DeFiScannerWidgetContainer from "../containers/DeFiScannerWidgetContainer";
import MarketsWidgetContainer from "../containers/MarketsWidgetContainer";
import HistoryWidgetContainer from "../containers/HistoryWidgetContainer";
import TradeWidgetContainer from "../containers/TradeWidgetContainer";
import DomWidgetContainer from "../containers/DomWidgetContainer";
import DomChartWidgetContainer from "../containers/DomChartWidgetContainer";
import ChartWidgetContainer from "../containers/ChartWidgetContainer";
import PriceWidgetContainer from "../containers/PriceWidgetContainer";
import FeedWidgetContainer from "../containers/FeedWidgetContainer";
import SentimentWidgetContainer from "../containers/SentimentWidgetContainer";
import SelectWidget from "./SelectWidget";

import {COLS, ROWS} from "../../../constants/MarketsConstants";

import "./Worksheet.scss";
import Widget from "../../../components/Widget/Widget";

const HEADERS_OVERAL_HEIGHT = 104;

class Worksheet extends PureComponent {
  constructor(props) {
    super(props);
    this.state={
      resizingWidgetId: ''
    };
    this.documentHeight = document.body.offsetHeight - HEADERS_OVERAL_HEIGHT;
    this.timeout = null;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps) {
      return false;
    }

    const {worksheets} = this.props;
    const sameWorksheets = prevProps.worksheets.length === 0 ||
      worksheets.length === 0 ||
      prevProps.worksheets.length === worksheets.length;

    if (!sameWorksheets) {
      return this.updateState();
    }

    let isSame = true;

    prevProps.worksheets.forEach((prevWorksheet) => {
      const currentWorksheet = worksheets.find((w) => w.id === prevWorksheet.id);
      if (!currentWorksheet) {
        return false;
      }
      if (currentWorksheet.widgets.length !== prevWorksheet.widgets.length) {
        isSame = false;
      } else {
        prevWorksheet.widgets.forEach((prevWidget) => {
          const currentWidget = currentWorksheet.widgets.find((w) => w.i === prevWidget.i);
          if (!currentWidget) {
            return false;
          }
          if (
            prevWidget.exchange !== currentWidget.exchange ||
            prevWidget.name !== currentWidget.name ||
            prevWidget.type !== currentWidget.type ||
            prevWidget.period !== currentWidget.period ||
            prevWidget.x !== currentWidget.x ||
            prevWidget.y !== currentWidget.y ||
            prevWidget.w !== currentWidget.w ||
            prevWidget.h !== currentWidget.h
          ) {
            isSame = false;
          }
        });
      }
    });

    if (!isSame) {
      this.updateState();
    }
  }

  updateState = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.props.doUpdateState(), 100);
  };

  handleDragResizeStop = (widgets, prev, value) => {
    const {currentWorksheet, updateWidget} = this.props;
    const widget = currentWorksheet.widgets.find((widget) => widget.i === value.i);
    if (widget) {
      updateWidget({
        ...widget,
        x: value.x,
        y: value.y,
        w: value.w,
        h: value.h,
      });
      this.setState({
        resizingWidgetId: ''
      });
    }
  };
  handleResize = (widgets, prev, value) => {
    const {currentWorksheet} = this.props;
    const widget = currentWorksheet.widgets.find((widget) => widget.i === value.i);
    if (widget) {
      this.setState({
        resizingWidgetId: value.i
      });
    }
  };
  drawWidget = (widget, idx) => {
    switch (widget.type) {
      case "scanner":
        return (<Widget id={widget.i}/>);
      case "markets":
        return (
          <MarketsWidgetContainer
            id={widget.i}
          />
        );
      case "treemap":
        return (
          <TreemapWidgetContainer
            id={widget.i}
            heightUnit={(this.documentHeight + 22) / COLS}
          />
        );
      case "bubble":
        return (
          <BubbleWidgetContainer
            id={widget.i}
            heightUnit={(this.documentHeight + 22) / COLS}
          />
        );
      case "defiscanner":
        return (
          <DeFiScannerWidgetContainer
            id={widget.i}
            heightUnit={(this.documentHeight + 22) / COLS}
          />
        );
      case "chart":
        return (
          <ChartWidgetContainer
            id={widget.i}
            height={(this.documentHeight + 22) / COLS}
            width={this.props.width}
            index={idx}
            resizingWidgetId={this.state.resizingWidgetId}
          />
        );
      case "trade":
        return (
          <TradeWidgetContainer
            id={widget.i}
          />
        );
      case "history":
        return (
          <HistoryWidgetContainer
            id={widget.i}
          />
        );
      case "dom":
        return (
          <DomWidgetContainer
            id={widget.i}
          />
        );
      case "domChart":
        return (
          <DomChartWidgetContainer
            id={widget.i}
          />
        );
      case "price":
        return (
          <PriceWidgetContainer
            id={widget.i}
            height={(this.documentHeight + 20) / COLS}
          />
        );
      case "feed":
        return (
          <FeedWidgetContainer
            id={widget.i}
          />
        );
      case "sentiment":
        return (
          <SentimentWidgetContainer
            id={widget.i}
          />
        );
      default:
        return (
          <SelectWidget
            widget={widget}
          />
        );
    }
  };

  render() {
    const {currentWorksheet, width, updateWidget} = this.props;
    const widgets = currentWorksheet ? currentWorksheet.widgets : [];
    const heightUnit = this.documentHeight / COLS;// - 1;
    const widthUnit = width / ROWS;
    return (
      <div className="widgetsWrapper">
        <ReactGridLayout
          className="layout"
          layout={widgets}
          cols={ROWS}
          margin={[3, 3]}
          rowHeight={heightUnit}
          draggableHandle=".widgetWrapper"
          draggableCancel=".disableDrag"
          width={width}
          onDragStop={this.handleDragResizeStop}
          onResizeStop={this.handleDragResizeStop}
          onResize={this.handleResize}
          useCSSTransforms={false}
          verticalCompact
        >
          {widgets && widgets.filter((widget) => !widget?.unpinned).map((widget, idx) => (
            <div
              key={widget.i}
              className={classNames({"Expanded": widget.expanded})}
            >
              {this.drawWidget(widget, idx)}
            </div>
          ))}
        </ReactGridLayout>
        {widgets && widgets.filter((widget) => widget?.unpinned).map((widget, idx) => (
          <Rnd
            className="floatWidget"
            dragHandleClassName="app-chart-toolbar"
            key={widget.i}
            position={{
              x: widget.fX || widget.x * widthUnit,
              y: widget.fY || widget.y * heightUnit,
            }}
            size={{
              //height: 2000,
              width: widget.fW || widget.w * widthUnit,
            }}
            onDragStop={(e, {lastX, lastY}) => {
              updateWidget({
                ...widget,
                fX: lastX,
                fY: lastY,
              });
            }}
            onResizeStop={(e, dir, refToElement, {width, height}) => {
              updateWidget({
                ...widget,
                fH: (widget.fH || widget.h * heightUnit) + height,
                fW: (widget.fW || widget.w * widthUnit) + width,
              });
            }}
          >
            {this.drawWidget(widget, idx)}
          </Rnd>
        ))}
        <BottomTabs {...this.props} />
      </div>
    );
  }
}

Worksheet.propTypes = {
  chartData: PropTypes.array,
  currentWorksheet: PropTypes.object,
  doUpdateState: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  width: PropTypes.number,
  worksheets: PropTypes.array,
};

Worksheet.defaultProps = {
  chartData: [],
  currentWorksheet: {},
  doUpdateState: () => false,
  width: 500,
  worksheets: [],
};

export default Worksheet;
