import React, {PureComponent, Fragment} from "react";
import PropTypes from "prop-types";
import {sortBy} from "lodash";
import moment from "moment";
import FontAwesome from "../../../components/FontAwesome";

import OrderType from "../../../components/OrderType";
import MarketName from "../../../components/MarketName";
import FloatValue from "../../../components/FloatValue";
import Button from "../../../components/Button";
import Collapse from "../../../components/Collapse";
import Table from "../../../components/Table";
import Loader from "../../../components/Loader";

import styles from "./OrdersPage.scss";

const fields = [
  {key: "opened", label: "Opened Date"},
  {key: "market", label: "Market"},
  {key: "orderSide", label: "Side"},
  {key: "orderType", label: "Type"},
  {key: "price", label: "Price"},
  {key: "quantity", label: "Quantity"},
  {key: "filled", label: "Filled"},
  {key: "status", label: "Status"},
  {key: "age", label: "Age"},
  {key: "cancel", label: "Cancel", disableSort: true},
];

class ActiveOrderItem extends PureComponent {
  constructor() {
    super();

    this.state = {
      activeOrderSortingKey: null,
      activeOrderSortingType: null,
      filter: "",
    };
  }

  onActiveSortClick = (key, type) => this.setState({activeOrderSortingKey: key, activeOrderSortingType: type});

  sortActiveOrderData = (items) => {
    const {activeOrderSortingKey, activeOrderSortingType} = this.state;
    const sortedData = activeOrderSortingKey ? sortBy(items, (item) => {
      switch (activeOrderSortingKey) {
        case "opened":
        case "age":
          return item.timestamp;
        case "market":
          return item.symbol;
        case "orderSide":
          return item.type;
        case "price":
          return item.price;
        case "quantity":
          return item.amount;
        case "filled":
          return item.filled;
        default:
          return item[activeOrderSortingKey];
      }
    }) : items;

    return activeOrderSortingType === "desc" ? sortedData.reverse() : sortedData;
  };

  drawToolBar = (account) => {
    return (
      <Fragment>
        <img
          alt=""
          height={22}
          src={`/img/exchanges/${account.exchange.toLowerCase()}.png`}
        /> {`${account.name}, ${account.exchange.toUpperCase()}`}
      </Fragment>
    );
  };

  drawActions = (account) => {
    const {onHandleToggleModal} = this.props;
    return (
      <Fragment>
        <div className={styles.FilterWrapper}>
          <input
            className={styles.Filter}
            placeholder="Search..."
            onChange={(event) => this.setState({filter: event.target.value})}
          />
          <FontAwesome
            className={styles.Icon}
            name='search'
          />
        </div>
        <button
          className={styles.Settings}
          type="button"
          onClick={() => onHandleToggleModal(account.name)}
        >
          <FontAwesome
            name='cog'
          />
        </button>
      </Fragment>
    );
  };

  drawFieldContent = (field, item, account) => {
    const {cancelOrderAction} = this.props;

    switch (field.key) {
      case "opened":
        return moment(item.timestamp).format("D MMM YYYY, HH:mm");
      case "market":
        return (
          <Fragment>
            <img
              alt=""
              className={styles.Icon}
              height={22}
              src={`/img/symbols/${item.symbol.split("/")[0].toUpperCase()}.png?`+
                `exchange=${account.exchange}`
              }
            /> <MarketName name={item.symbol} reversed/>
          </Fragment>
        );
      case "orderSide":
        return <OrderType type={item.side}/>;
      case "orderType":
        return "Limit";
      case "price":
        return <FloatValue value={item.price}/>;
      case "quantity":
        return <FloatValue value={item.amount}/>;
      case "filled":
        return <FloatValue value={item.filled}/>;
      case "age":
        return moment(item.timestamp).fromNow();
      case "status":
        return "Pending";
      case "cancel":
        return (
          <Button
            className={styles.ActionButton}
            label={"Cancel"}
            onClick={() => cancelOrderAction(account.name, item)}
          />
        );
      default:
        return null;
    }
  };

  drawOrderContent = (account) => {
    const {loading} = this.props;
    const list = account.list || [];
    const filteredList = this.sortActiveOrderData(list)
        .filter((item) => item.symbol.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0);

    return (
      <Table
        fields={fields}
        sort={this.onActiveSortClick}
      >
        {loading && (
          <tr>
            <td>
              <Loader/>
            </td>
          </tr>
        )}
        {loading === false && filteredList.length === 0 && (
          <tr>
            <td colSpan={fields.length}>
              There are no orders
            </td>
          </tr>
        )}
        {loading === false && filteredList.length > 0 && filteredList.map((item) => (
          <tr key={item.OrderUuid}>
            {fields.map((field) => (
              <td key={field.key} className={field.key}>
                {this.drawFieldContent(field, item, account)}
              </td>
            ))}
          </tr>
        ))}
      </Table>
    );
  };

  render() {
    const {account} = this.props;
    return (
      <Collapse
        label={this.drawToolBar(account)}
        description={`Open Orders (${account.list.length})`}
        actions={this.drawActions(account)}
      >
        {this.drawOrderContent(account)}
      </Collapse>
    );
  }
}

ActiveOrderItem.propTypes = {
  account: PropTypes.object,
  cancelOrderAction: PropTypes.func,
  loading: PropTypes.bool,
  onHandleToggleModal: PropTypes.func,
};

ActiveOrderItem.defaultProps = {
  account: {},
  cancelOrderAction: () => false,
  loading: false,
  onHandleToggleModal: () => false,
};

export default ActiveOrderItem;
