// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2jXrv3sKO-Hlww8hd2xWtY{width:100px}", "",{"version":3,"sources":["webpack://src/pages/Markets/components/DeFiScannerWidget/pages/tokenlistingtab.scss"],"names":[],"mappings":"AACE,yBACE,WAAA","sourcesContent":[":local {\n  .image {\n    width: 100px\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "_2jXrv3sKO-Hlww8hd2xWtY"
};
export default ___CSS_LOADER_EXPORT___;
