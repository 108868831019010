const sourceCodes = require('./IndicatorSourceCode');

const types = [
  {name: "INDICATORS", value: "indicator"},
  {name: "ALL", value: "all"},
];

const singlePointTrendlines = ["trendline_trhr", "trendline_trhl", "trendline_trvl"];

const trendLineDefaultAppearance = {
  stroke: "#ffffff",
  strokeOpacity: 1,
  strokeWidth: 1,
  strokeDasharray: "Solid",
  edgeStrokeWidth: 1,
  edgeFill: "#FFFFFF",
  edgeStroke: "#000000",
  r: 6,
};

const fibonacciDefaultAppearance = {
  stroke: "#ffffff",
  strokeWidth: 1,
  strokeOpacity: 1,
  fontSize: 11,
  fontFill: "#ffffff",
  edgeStroke: "#000000",
  edgeFill: "#FFFFFF",
  nsEdgeFill: "#000000",
  edgeStrokeWidth: 1,
  r: 5,
};

const indicators = [
  {
    id: "supdemzone",
    name: "SupDemZone",
    fullName: "Supply & Demand Zones",
    category: "Adaptive",
    long: "The supply and demand zone indicator draws the supply and demand zones of a currency pair on the chart.",
    type: "indicator",
    separated: false,
    options: {
      code: sourceCodes.SupDemZone
    },
    active: true,
    editable: true,
    ignoreY: false,
  },
  {
    id: "rsi",
    name: "RSI",
    fullName: "Relative Strength Index",
    category: "Oscillators",
    long: "The Relative Strength Index (RSI) was published by J. Welles Wilder. The current price is normalized as a percentage between 0 and 100. The name of this oscillator is misleading because it does not compare the instrument relative to another instrument or set of instruments, but rather represents the current price relative to other recent pieces within the selected lookback window length.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.RelativeStrengthIndex
    },
  },
  {
    id: "mfi",
    name: "MFI",
    fullName: "Money Flow Index",
    category: "Volume",
    long: "The Money Flow Index (MFI) was developed by Gene Quong and Avrum Soudack. It uses both price and volume to measure buying and selling pressure.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.MoneyFlowIndex
    },
  },
  {
    id: "atr",
    name: "ATR",
    fullName: "Average True Range",
    category: "Volatility",
    long: "The Average True Range (ATR) study measures the size of the period’s range, and takes into account any gap from the close of the previous period.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.AverageTrueRange
    },
  },
  {
    id: "roc",
    name: "ROC",
    fullName: "Rate Of Change",
    category: "Oscillators",
    long: "The rate of change (ROC) is the speed at which a variable changes over a specific period of time. ROC is often used when speaking about momentum, and it can generally be expressed as a ratio between a change in one variable relative to a corresponding change in another; graphically, the rate of change is represented by the slope of a line.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.RateOfChange
    },
  },
  {
    id: "obv",
    name: "OBV",
    fullName: "On Balance Volume",
    category: "Volume",
    long: "On Balance Volume (OBV) maintains a cumulative running total of the amount of volume occurring on up periods compared to down periods.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.OnBalanceVolume
    },
  },
  {
    id: "cci",
    name: "CCI",
    fullName: "Commodity Channel Index",
    category: "Oscillators",
    long: "The Commodity Channel Index (CCI) compares the current mean price with the average mean price over a typical window of 20 periods.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.CommodityChannelIndex
    },
  },
  {
    id: "trix",
    name: "TRIX",
    fullName: "Triple Exponential Moving Average Oscillator",
    category: "Oscillators",
    long: "The Triple Exponential Moving Average Oscillator (TRIX) by Jack Hutson is a momentum indicator that oscillates around zero. It displays the percentage rate of change between two triple smoothed exponential moving averages.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.TRIX
    },
  },
  {
    id: "adx",
    name: "ADX",
    fullName: "Average Directional Index",
    category: "Oscillators",
    long: "The Average Directional Movement Index (ADX) is designed to quantify trend strength by measuring the amount of price movement in a single direction.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.AverageDirectionalIndex
    },
  },
  {
    id: "tr",
    name: "TR",
    fullName: "True Range",
    category: "Volatility",
    long: "The True Range Indicator plots values of TrueRange function.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.TrueRange
    },
  },
  {
    id: "ao",
    name: "AO",
    fullName: "Awesome Oscillator",
    category: "Oscillators",
    long: "The Awesome Oscillator (AO) is a popular indicator of market momentum developed by the American financial analyst Bill Williams. It is a boundless indicator anchored around a zero line and displayed as a histogram of the average of two simple moving averages (SMAs), one covering recent momentum and the other a longer period in the market. ",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.AwesomeOscillator
    },
  },
  {
    id: "ns",
    name: "NS",
    fullName: "News Sentiment",
    category: "Trend",
    long: "Simple news sentiment indicator",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.NewsSentiment
    },
  },
  {
    id: "macd",
    name: "MACD",
    fullName: "Moving Average Convergence Divergence",
    category: "Trend",
    long: "The Moving Average Convergence Divergence (MACD) was developed by Gerald Appel, and is based on the differences between two moving averages of different lengths, a Fast and a Slow moving average. A second line, called the Signa” line is plotted as a moving average of the MACD. A third line, called the MACD Histogram is optionally plotted as a histogram of the difference between the MACD and the Signal Line.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.MovingAverageConvergenceDivergence
    },
  },
  {
    id: "vwap",
    name: "VWAP",
    fullName: "Volume Weighted Average Price",
    category: "Oscillators",
    long: "The Volume Weighted Average Price is similar to a moving average, except volume is included to weight the average price over a one day period. VWAP resets daily and can be calculated based on exchange session, primary session and custom defined sessions. You can also apply standard deviation bands above and below the VWAP.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.VolumeWeightedAveragePrice
    },
  },
  {
    id: "keltnerchannels",
    name: "KC",
    fullName: "Keltner Channels",
    category: "Volume",
    long: "The Keltner Channel was introduced in 1960 by Chester W. Keltner in his book How To Make Money in Commodities, and is also explained by Perry Kaufman's book The New Commodity Trading Systems and Methods. Keltner Channels plots three lines, consisting of a simple moving average (typically of the average price) with upper and lower bands plotted above and below this moving average. The width of the bands is based on a user defined factor applied to the Average True Range, with this result added to and subtracted from the middle moving average line.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.KeltnerChannels
    },
  },
  {
    id: "tripleema",
    name: "TEMA",
    fullName: "Triple Exponential Moving Average",
    category: "Trend",
    long: "The Triple Exponential Moving Average (TEMA) by Patrick Mulloy offers a moving average with less lag then traditional exponential moving average.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.TripleEMA
    },
  },
  {
    id: "bb",
    name: "BB",
    fullName: "Bollinger Bands",
    category: "Volatility",
    long: "The Bollinger Band (BBANDS) study created by John Bollinger plots upper and lower envelope bands around the price of the instrument. The width of the bands is based on the standard deviation of the closing prices from a moving average of price.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.BollingerBands
    },
  },
  {
    id: "fishnetavg",
    name: "FNAVG",
    fullName: "Fishnet Average",
    category: "Trend",
    long: "The Fishnet Average (FNAVG) is calculated using multiple moving averages.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.FishnetAverage
    },
  },
  {
    id: "sma",
    name: "SMA",
    fullName: "Simple Moving Average",
    category: "Trend",
    long: "The Simple Moving Average (SMA) is calculated by adding the price of an instrument over a number of time periods and then dividing the sum by the number of time periods. The SMA is basically the average price of the given time period, with equal weighting given to the price of each period.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.SimpleMovingAverage
    },
  },
  {
    id: "ema",
    name: "EMA",
    fullName: "Exponential Moving Average",
    category: "Trend",
    long: "The Exponential Moving Average (EMA) represents an average of prices, but places more weight on recent prices. The weighting applied to the most recent price depends on the selected period of the moving average. The shorter the period for the EMA, the more weight that will be applied to the most recent price.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.ExponentialMovingAverage
    },
  },
  {
    id: "wma",
    name: "WMA",
    fullName: "Weighted Moving Average",
    category: "Trend",
    long: "The Weighted Moving Average (WMA) places more emphasis on recent prices than on older prices. Each period’s data is multiplied by a weight, with the weighting determined by the number of periods selected.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.WeightedMovingAverage
    },
  },
  {
    id: "StochasticRSI",
    name: "SRSI",
    fullName: "Stochastic RSI",
    category: "Oscillators",
    long: "The Stochastic RSI (StochRSI) is an indicator used in technical analysis that ranges between zero and one",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.StochasticRSI
    },
  },
  {
    id: "vol",
    name: "VOL",
    fullName: "Volume",
    category: "Volume",
    long: "The number of contracts traded during a given period of time.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.Volume
    },
  },
  {
    id: "psar",
    name: "PSAR",
    fullName: "Parabolic SAR",
    category: "Trend",
    long: "The Parabolic Stop and Reverse (SAR) calculates trailing stop points to use with long and short positions. The SAR was published by J. Welles Wilder as part of a complete trend following system. The dotted lines above the price designate trailing stops for short positions; those below the price are sell stops for long positions.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.ParabolicSAR
    },
  },
  {
    id: "forceindex",
    name: "ForceIndex",
    fullName: "Force Index",
    category: "Volume",
    type: "indicator",
    long: "The force index is a technical indicator that measures the amount of power used to move the price of an asset.",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.ForceIndex
    },
  },
  {
    id: "williamR",
    name: "WPR",
    fullName: "Williams Percent Range",
    category: "Oscillators",
    long: "The %R indicator is similar to a stochastic oscillator, as it normalizes price as a percentage between 0 and 100. It is basically an inverted version of the ‘Raw %K’ value of a Fast Stochastic.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.WilliamsR
    },
  },
  {
    id: "ChandelierExit",
    name: "CE",
    fullName: "Chandelier Exit",
    category: "Trend",
    long: "The Chandelier Exit indicator is a trend following indicator which is based on the concept of the ATR as a means to identify trends and trend reversals.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.ChandelierExit
    },
  },
  {
    id: "kst",
    name: "KST",
    fullName: "Know Sure Thing",
    category: "Oscillators",
    long: "The Know Sure Thing (KST) is a momentum oscillator developed by Martin Pring to make rate-of-change readings easier for traders to interpret.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.KnowSureThing
    },
  },
  {
    id: "adl",
    name: "ADL",
    fullName: "Accumulation Distribution Line",
    long: "The Accumulation/Distribution (AD) study attempts to quantify the amount of volume flowing into or out of an instrument by identifying the position of the close of the period in relation to that period’s high/low range. The volume for the period is then allocated accordingly to a running continuous total.",
    category: "Volume",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.AccumulationDistributionLine
    },
  },
  {
    id: "wema",
    name: "WEMA",
    fullName: "Wilders Smoothing",
    category: "Oscillators",
    long: "he Wilder's Smoothing study is similar to the Exponential Moving Average with the difference that Wilder's Smoothing uses a smoothing factor of 1/length which makes it respond more slowly to price changes compared to other moving averages.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.WildersSmoothing
    },
  },
  {
    id: "kd",
    name: "SO",
    fullName: "Stochastic",
    category: "Oscillators",
    long: "The Stochastic (Stoch) normalizes price as a percentage between 0 and 100. Normally two lines are plotted, the %K line and a moving average of the %K which is called %D. A slow stochastic can be created by initially smoothing the %K line with a moving average before it is displayed. The length of this smoothing is set in the Slow K Period. Without the initial smoothing ( i.e., setting the Slow K Period to a value of 1 ) the %K becomes the ‘Raw %K’ value, and is also known as a fast stochastic.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.StochasticOscillator
    },
  },
  {
    id: "ichimouk",
    name: "Ichimoku",
    fullName: "Ichimoku Cloud",
    category: "Adaptive",
    long: "The Ichimoku Cloud is a collection of technical indicators that show support and resistance levels, as well as momentum and trend direction. It does this by taking multiple averages and plotting them on a chart. It also uses these figures to compute a “cloud” that attempts to forecast where the price may find support or resistance in the future.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.IchimokuCloud
    },
  },
  {
    id: "ppo",
    name: "PVO",
    fullName: "Percentage Price Oscillator",
    category: "Volume",
    long: "The Percentage Volume Oscillator is a simple oscillator view of two converging/diverging exponential moving averages of Volume.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.PercentagePriceOscillator
    },
  },
  {
    id: "cvi",
    name: "CVI",
    fullName: "Chaikins Volatility Index",
    category: "Volume",
    long: "The Chaikin Volatility Indicator is the difference between two moving averages of a volume weighted accumulation-distribution line. By comparing the spread between a security's high and low prices, it quantifies volatility as a widening of the range between the high and the low price.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.ChaikinsVolatilityIndex
    },
  },
  {
    id: "apo",
    name: "APO",
    fullName: "Absolute Price Oscillator",
    category: "Oscillators",
    long: "The Absolute Price Oscillator (APO) is based on the absolute differences between two moving averages of different lengths, a ‘Fast’ and a ‘Slow’ moving average.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.AbsolutePriceOscillator
    },
  },
  {
    id: "kvo",
    name: "KVO",
    fullName: "Klinger Volume Oscillator",
    category: "Volume",
    long: "The Klinger Volume Oscillator was developed by Stephen J. Klinger. It is a conformation indicator. It uses high, low, close and volume to create a volume force. This volume force (VF) is then turned into an oscillator by taking a fast EMA of VF and subtracting a slow EMA of VF. A signal line (KOS), which is an EMA of the Klinger Oscillator (KO), is plotted to trigger trading signals.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.KlingerVolumeOscillator
    },
  },
  {
    id: "dema",
    name: "DEMA",
    fullName: "Double Exponential Moving Average",
    category: "Oscillators",
    long: "The Double Exponential Moving Average (DEMA) by Patrick Mulloy attempts to offer a smoothed average with less lag than a straight exponential moving average. The calculation is more complex than just a moving average of a moving average as shown in the formula below.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.DoubleEMA
    },
  },
  {
    id: "Oscillators",
    name: "MOM",
    fullName: "Momentum",
    category: "Oscillators",
    long: "The Momentum (MOM) indicator compares the current price with the previous price from a selected number of periods ago. This indicator is similar to the “Rate of Change” indicator, but the MOM does not normalize the price, so different instruments can have different indicator values based on their point values.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.Momentum
    },
  },
  {
    id: "eom",
    name: "EOM",
    fullName: "Ease of Movement",
    category: "Oscillators",
    long: "The Ease of Movement indicator is a technical study that attempts to quantify a mix of momentum and volume information into one value. The intent is to use this value to discern whether prices are able to rise, or fall, with little resistance in the directional movement. Theoretically, if prices move easily, they will continue to do so for a period of time that can be traded effectively.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.EaseOfMovement
    },
  },
  {
    id: "cmo",
    name: "CMO",
    fullName: "Chande Momentum Oscillator",
    category: "Oscillators",
    long: "The Chande Momentum Oscillator (CMO) developed by Tushar Chande attempts to capture the momentum of the instrument. The indicator oscillates between -100 and 100 with overbought level of 50 and oversold level of -50.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.ChandeMomentumOscillator
    },
  },
  {
    id: "aroonosc",
    name: "AroonOsc",
    fullName: "Aroon Oscillator",
    category: "Oscillators",
    long: "The Aroon Oscillator (ARO) developed by Tushar Chande is calculated by subtracting AroonDown from AroonUp.The Aroon Oscillator ranges from -100 to 100.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.AroonOscillator
    },
  },
  {
    id: "tma",
    name: "TRIMA",
    fullName: "Triangular Moving Average",
    category: "Trend",
    long: "The Triangular Moving Average (TRIMA) represents an average of prices, but places weight on the middle prices of the time period. The calculations double-smooth the data using a window width that is one-half the length of the series.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.TriangularMovingAverage
    },
  },
  {
    id: "qstick",
    name: "QSI",
    fullName: "Qstick",
    category: "Oscillators",
    long: "The Qstick indicator is a technical analysis indicator developed by Tushar Chande to numerically identify trends on a price chart. It is calculated by taking an 'n' period moving average of the difference between the open and closing prices. A Qstick value greater than zero means that the majority of the last 'n' days have been up, indicating that buying pressure has been increasing.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.Qstick
    },
  },
  {
    id: "marketfi",
    name: "MFI",
    fullName: "Market Facilitation Index",
    category: "Volatility",
    long: "The market facilitation index (MFI) is an indicator that measures the strength or weakness behind movements of the price of an asset.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.MarketFacilitationIndex
    },
  },
  {
    id: "medprice",
    name: "MP",
    fullName: "Median Price",
    category: "Trend",
    long: "Median Price (MP) is the average price of ever period and then sum of all the average period price and dividing the result by the total number of periods.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.MedianPrice
    },
  },
  {
    id: "uo",
    name: "UO",
    fullName: "Ultimate Oscillator",
    category: "Oscillators",
    long: "The Ultimate Oscillator is a technical indicator that was developed by Larry Williams in 1976 to measure the price momentum of an asset across multiple timeframes. By using the weighted average of three different timeframes the indicator has less volatility and fewer trade signals compared to other oscillators that rely on a single timeframe. Buy and sell signals are generated following divergences. ",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.UltimateOscillator
    },
  },
  {
    id: "zlema",
    name: "ZLEMA",
    fullName: "Zero Lag EMA",
    category: "Trend",
    long: "The Zero Lag EMA is a Trend indicator, and the primary aim is to eliminate the inherent lag associated with all trend-following indicators, which average a price over time.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.ZeroLagEMA
    },
  },
  {
    id: "bop",
    name: "BOP",
    fullName: "Balance Of Power",
    category: "Oscillators",
    long: "Balance of Power is a momentum oscillator that depicts the strength of buying and selling pressure.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.BalanceOfPower
    },
  },
  {
    id: "dpo",
    name: "DPO",
    fullName: "Detrended Price Oscillator",
    category: "Oscillators",
    long: "A detrended price oscillator, used in technical analysis, strips out price trends in an effort to estimate the length of price cycles from peak to peak or trough to trough.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.DetrendedPriceOscillator
    },
  },
  {
    id: "rocr",
    name: "ROCR",
    fullName: "Rate Of Change Rate",
    category: "Oscillators",
    long: "The Rate of Change (ROC) indicator compares the current price with the previous price from a selected number of periods ago. The current price is divided by the previous price and expressed as a percentage. This indicator is also commonly known as a momentum indicator.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.RateOfChangeRate
    },
  },
  {
    id: "mass",
    name: "Mass Index",
    fullName: "Mass Index",
    category: "Oscillators",
    long: "Mass index is a form of technical analysis that examines the range between high and low stock prices over a period of time. Mass index, developed by Donald Dorsey in the early 1990s, suggests that a reversal of the current trend will likely take place when the range widens beyond a certain point and then contracts.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.MassIndex
    },
  },
  {
    id: "adxr",
    name: "ADXR",
    fullName: "Average Directional Movement Rating",
    category: "Trend",
    long: "The Average Directional Movement Rating measures the strength of a price movement in positive and negative directions, as well as the overall strength of the trend. The Average Directional Movement Rating quantifies the change of momentum in the ADX (Average Directional Index).",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.AverageDirectionalMovementRating
    },
  },
  {
    id: "hma",
    name: "HMA",
    fullName: "Hull Moving Average",
    category: "Trend",
    long: "The Hull Moving Average (HMA) attempts to minimize the lag of a traditional moving average while retaining the smoothness of the moving average line. Developed by Alan Hull in 2005, this indicator makes use of weighted moving averages to prioritize more recent values and greatly reduce lag. The resulting average is more responsive and well-suited for identifying entry points.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.HullMovingAverage
    },
  },
  {
    id: "vhf",
    name: "VHF",
    fullName: "Vertical Horizontal Filter",
    category: "Oscillators",
    long: "Vertical Horizontal Filter (VHF) was created by Adam White to identify trending and ranging markets. VHF measures the level of trend activity, similar to ADX in the Directional Movement System. Trend indicators can then be employed in trending markets and momentum indicators in ranging markets.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.VerticalHorizontalFilter
    },
  },
  {
    id: "vosc",
    name: "VO",
    fullName: "Volume Oscillator",
    category: "Oscillators",
    long: "The Volume Oscillator is used to reveal the difference between two VMA (volume moving averages) with the objective of determining whether the overall volume trend is increasing or decreasing, as well as evaluating the magnitude of the volume surges.",
    type: "indicator",
    separated: true,
    active: true,
    editable: true,
    ignoreY: false,
    options: {
      code: sourceCodes.VolumeOscillator
    },
  },
  {
    id: "floorpivot",
    name: "FTP",
    fullName: "Floor Pivots",
    category: "Adaptive",
    long: "The FTP is a calculated price point that is widely regarded as a significant level of support and/or resistance or a reference midpoint for calculating other support and resistance levels.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: true,
    options: {
      code: sourceCodes.FloorPivotPoints
    },
  },
  {
    id: "woodies",
    name: "WoodiesPivots",
    fullName: "Woodies Pivots",
    category: "Adaptive",
    long: "The Woodie’s pivot point is the level at which the market direction changes for the day. Woodie’s Pivot Point is used to determine trading levels at which trend is inclined to change direction and head to possible support and resistance. ",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: true,
    options: {
      code: sourceCodes.WoodiesPivotPoints
    },
  },
  {
    id: "camarilla",
    name: "CamarillaPivots",
    fullName: "Camarilla Pivots",
    category: "Adaptive",
    long: "Camarilla pivot point provides accurate and automated levels of support and resistance levels. The Camarilla points contain one central pivot point along with 4 levels of support and 4 levels of resistance.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: true,
    options: {
      code: sourceCodes.CamarillaPivotPoints
    },
  },
  {
    id: "fibopivot",
    name: "FibonacciPivots",
    fullName: "Fibonacci Pivots",
    category: "Adaptive",
    long: "Pivot point studies highlight prices considered to be a likely turning point when looking at values from a previous period, whether it be daily, weekly, quarterly or annual. Each pivot point study has its own characteristics on how these points are calculated.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: true,
    options: {
      code: sourceCodes.FibonacciPivotPoints
    },
  },
  {
    id: "Swing",
    name: "SHL",
    fullName: "Swing High/Low",
    category: "Adaptive",
    long: "The Swing High/Low indicator plots swing highs and swing lows of price action as it moves up and down.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: true,
    options: {
      code: sourceCodes.SwingHighLow
    },
  },
  {
    id: "Range",
    name: "RHL",
    fullName: "Range High/Low",
    category: "Adaptive",
    long: "The Range High/Low indicator plots highs and swing lows of price action as it moves up and down.",
    type: "indicator",
    separated: false,
    active: true,
    editable: true,
    ignoreY: true,
    options: {
      code: sourceCodes.RangeHighLow
    },
  },
  // {
  //   id: "wa/d",
  //   name: "WA/D",
  //   fullName: "William A/D",
  //   type: "indicator",
  //   separated: true,
  //   active: true,
  //   editable: true,
  //   options: {
  //     code: sourceCodes.WilliamAccumulationDistribution
  //   },
  // },
  // {
  //   id: "fisher",
  //   name: "Fisher",
  //   fullName: "Fisher Transform",
  //   type: "indicator",
  //   separated: true,
  //   active: true,
  //   editable: true,
  //   options: {
  //     code: sourceCodes.FisherTransform
  //   },
  // },
  {
    id: "volumeProfile",
    name: "VPI",
    fullName: "Volume Profile",
    category: "Volume",
    long: "Volume Profile Indicator is a technical chart that shows the total number of transactions at each price point. Briefly, we can say that VPI is the total traded volume of security at a certain price point over a specified time period. It makes use of historical data and previous traded volume.",
    type: "indicator",
    separated: false,
    options: {
      code: sourceCodes.VolumeProfile
    },
    active: true,
    editable: true,
    ignoreY: false,
  },
];

export {
  types,
  trendLineDefaultAppearance,
  fibonacciDefaultAppearance,
  indicators,
  singlePointTrendlines,
};
