// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5Lx5UTzm5FgLygi6x5H3W{display:inline-block;vertical-align:baseline;height:8px;width:8px;border-radius:5px;left:19px;top:4px}._5Lx5UTzm5FgLygi6x5H3W._1VnGDKYnhmCfIqnok4c_BI{background:#24a800}._5Lx5UTzm5FgLygi6x5H3W.qghEOjXTmlg3l-RFiYSLK{background:#555}", "",{"version":3,"sources":["webpack://src/pages/Chat/components/UserStatus.scss"],"names":[],"mappings":"AAGE,wBACE,oBAAA,CACA,uBAAA,CACA,UAAA,CACA,SAAA,CACA,iBAAA,CAEA,SAAA,CACA,OAAA,CAEA,gDACE,kBAAA,CAGF,8CACE,eAAA","sourcesContent":["@import \"../../../styles/constants.scss\";\n\n:local {\n  .Status {\n    display: inline-block;\n    vertical-align: baseline;\n    height: 8px;\n    width: 8px;\n    border-radius: 5px;\n    //position: absolute;\n    left: 19px;\n    top: 4px;\n\n    &.Online {\n      background: $green;\n    }\n\n    &.Offline {\n      background: #555;\n      //border: 1px solid #888;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Status": "_5Lx5UTzm5FgLygi6x5H3W",
	"Online": "_1VnGDKYnhmCfIqnok4c_BI",
	"Offline": "qghEOjXTmlg3l-RFiYSLK"
};
export default ___CSS_LOADER_EXPORT___;
