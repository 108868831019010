export const CoinTypes = [
  {
    label: "Binance",
    options: [
      {
        ticker: "BTC",
        long: "Bitcoin",
        category: "Store of Value",
        value: "temp",
      },
      {
        ticker: "LTC",
        long: "Litecoin",
        category: "Medium of Exchange",
        value: "temp"
      },
      {
        ticker: "BTT",
        long: "Blocktrade Token",
        category: "",
        value: "temp"
      },
      {
        ticker: "DUSK",
        long: "Dusk",
        category: "Smart Contracts",
        value: "temp"
      },
    ]
  },
  {
    label: "Poloniex",
    options: [
      {
        ticker: "BTC",
        long: "Bitcoin",
        category: "Store of Value",
        value: "temp",
      },
      {
        ticker: "LTC",
        long: "Litecoin",
        category: "Medium of Exchange",
        value: "temp"
      },
      {
        ticker: "BTT",
        long: "Blocktrade Token",
        category: "",
        value: "temp"
      },
      {
        ticker: "DUSK",
        long: "Dusk",
        category: "Smart Contracts",
        value: "temp"
      },
    ]
  },
];
