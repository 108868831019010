exports.hfillDef = {
  style: {
    strokeBorder: true,
    strokeWidth: 2,
    strokeColor: 'rgba(255, 192, 203, 1)',
    strokeType: "ShortDash",
    fillColor: 'rgba(255, 192, 203, 0.1)',
  }
};
exports.hlineDef = {
  style: {
    strokeWidth: 2,
    strokeColor: 'rgba(255, 192, 203, 1)',
    strokeType: "ShortDash",
  }
};
exports.fillDef = {
  style: {
    fillBetweenColor: 'rgba(0, 255, 0, 0.1)',
    fillCrossColor: 'rgba(255, 0, 0, 0.1)',
    offset: 0,
  }
};
exports.plotDef = {
  style: {
    strokeLabel: 'Label',
    strokeType: "Solid",
    strokeWidth: 2,
    strokeColor: 'rgba(255, 0, 0, 1)',
    fillColor: 'rgba(255, 255, 0, 0.2)',
    offset: 0,
  }
};
exports.areaDef = {
  style: {
    baseLine: 0,
    strokeColor: "rgba(56,193,114,1)",
    strokeWidth: 2,
    fillColor: "rgba(162,245,191,1)",
  }
};
exports.vp = {
  style: {
    upVolColor: "rgba(107,165,131,0.5)",
    dnVolColor: "rgba(255,0,0,0.5)",
    totalVolColor: "rgba(255,0,255,0.5)",
    vaUpVolColor: "rgba(73,145,149,0.5)",
    vaDnVolColor: "rgba(129,49,152,0.5)",
    vaTotalVolColor: "rgba(255,100,100,0.5)",
    showPOC: true,
    pocColor: "rgba(255,0,0,1)",
    vaVolPct: 70,
    width: 30,
    placement: 'Right',
    placementSelect: ['Right', 'Left'],
    volType: 'Up & Down Volume',
    volTypeSelect: ['Up & Down Volume', 'Total Volume']
  }
};

exports.supdemzone = {
  style: {
    demandColor: 'rgba(127, 255, 0, 1)',
    supplyColor: 'rgba(255, 99, 71, 1)',
    activeZoneLineOpacity: 0.3,
    activeZoneAreaOpacity: 0.15,
    brokenZoneLineOpacity: 0.1,
    brokenZoneAreaOpacity: 0.05,
    lineWidth: 1,
    hideActiveZones: false,
    hideBrokenZones: true,
    extendZones: false
  }
};
