import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Badge} from "reactstrap";

import FontAwesome from "../../../components/FontAwesome";
import styles from "./ChannelItem.scss";

class ChannelItem extends Component {
  drawUnreadBadge = (count) => {
    return (
      <Badge className={styles.Badge} color="danger">
        {count}
      </Badge>
    );
  };

  render() {
    const {item, channel, setCurrentChannel, checkIfHasNewItems} = this.props;
    const hasNewItems = checkIfHasNewItems(item._id);
    return (
      <div>
        <li
          className={classNames(styles.ChannelItem, {
            [styles.Active]: channel && channel === item._id,
            [styles.Unread]: hasNewItems,
          })}
          onClick={() => setCurrentChannel(item._id)}
        >
          <FontAwesome prefix="far" name="hashtag"/> <span>{item.title}</span>
          {hasNewItems && this.drawUnreadBadge(hasNewItems.count)}
        </li>
      </div>
    );
  }
}

ChannelItem.propTypes = {
  channel: PropTypes.any,
  checkIfHasNewItems: PropTypes.func,
  item: PropTypes.object,
  setCurrentChannel: PropTypes.func,
};

ChannelItem.defaultProps = {
  channel: undefined,
  checkIfHasNewItems: () => false,
  item: undefined,
  setCurrentChannel: () => false,
};

export default ChannelItem;
