import FontAwesome from "../../../../components/FontAwesome";
import PropTypes from "prop-types";
import styles from "./WorksheetSettingsModal.scss";
import ReactTooltip from 'react-tooltip';

const WorksheetAddButton = ({handleAddWorksheet}) => {
  return (<button
    onClick={() => handleAddWorksheet()}
  >
    <a data-tip data-for='addButton'>
      <FontAwesome
        name="plus-circle"
        prefix="fas"
        className={styles.addIcon}
      />
    </a>
    <ReactTooltip
      id='addButton'
      offset="{'top': 20, 'left': -575}"
      arrowColor='transparent'
      className={styles.buttonTooltip}
      effect='solid'
    >
      <span>Add new worksheet</span>
    </ReactTooltip>
  </button>);
};

WorksheetAddButton.propTypes = {
  handleAddWorksheet: PropTypes.func.isRequired,
};
export default WorksheetAddButton;
