/* eslint react/prefer-stateless-function: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ChatView from "react-chatview";
import MessageItem from "./MessageItem";

import styles from "./Messages.scss";

class Messages extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {channelHasNewItems, getMessagesAction, messages} = this.props;
    const notSame = prevProps.messages[0] && messages[0] && prevProps.messages[0].channel !== messages[0].channel;

    if ((!prevProps.messages[0] && messages[0]) || notSame) {
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 0);
    }
    if (!prevProps.channelHasNewItems && channelHasNewItems) {
      getMessagesAction();
    }
  }

    checkIsUnique = (item, index) => {
      if (index === 0) {
        return true;
      }
      const {messages} = this.props;

      const diff = moment(item.createdDate).diff(messages[index - 1].createdDate, "minutes");
      return diff >= 1 || item.user.id !== messages[index - 1].user.id;
    };

    handleGetNewMessages = () => {
      const {getMessagesAction, messages} = this.props;
      debugger;
      if (messages.length > 0) {
        const date = messages[0].createdDate;
        getMessagesAction(date);
      }
    };

    render() {
      const {messages, channelObject, editingMessage, user} = this.props;
      const days = [];
      for (const message of messages) {
        const day = moment(message.createdDate).format('MMMM Do');
        let dayIndex = days.findIndex(({day: currentDate}) => currentDate === day);
        if (dayIndex === -1) {
          days.push({day, children: []});
          dayIndex = days.length - 1;
        }
        days[dayIndex].children.push(message);
      }
      return (
        <div className={styles.Wrapper}>
          {!this.state.loading && (
            <ChatView
              className={styles.Messages}
              flipped
              reversed
              scrollLoadThreshold={20}
              onInfiniteLoad={this.handleGetNewMessages}
            >
              <div className={styles.welcomeMessage}>This is the beginning of your messages with {channelObject.title}</div>
              {days.map(({day, children}) => (
                <div>
                  {console.log(day, children)}
                  <div className={styles.dateSeperator}>{day}</div>
                  {children.map((message, index) => (
                    <MessageItem
                      {...this.props}
                      editingMessage={editingMessage && editingMessage._id === message._id ? editingMessage : null}
                      key={message._id}
                      isAuthor={user.id === message.user.id}
                      isUnique={this.checkIsUnique(message, index)}
                      message={message}
                    />
                  ))}
                </div>
              ))}

            </ChatView>
          )}
        </div>
      );
    }
}

Messages.propTypes = {
  channel: PropTypes.string,
  channelHasNewItems: PropTypes.bool,
  deleteMessageAction: PropTypes.func,
  editingMessage: PropTypes.object,
  fetchUrlAction: PropTypes.func,
  getMessagesAction: PropTypes.func,
  messages: PropTypes.array,
  setMessageForEditing: PropTypes.func,
  urlsData: PropTypes.array,
  user: PropTypes.object,
  channelObject: PropTypes.object,
};

Messages.defaultProps = {
  channel: undefined,
  channelHasNewItems: false,
  deleteMessageAction: () => false,
  editingMessage: undefined,
  fetchUrlAction: () => false,
  getMessagesAction: () => false,
  messages: [],
  setMessageForEditing: () => false,
  urlsData: [],
  channelObject: {},
  user: undefined,
};

export default Messages;
