import { v4  } from 'uuid';
import {merge} from "lodash/object";

export default ({ts1, price1, base, quote, settings})=>  merge({
  id: v4(),
  type: 'horizontalray',
  points: 1,
  ts1,
  price1,
  settings: {},
  exchange: 'binance',
  market: `${base}-${quote}`,

}, settings ?? {});
