import {connect} from "react-redux";
import MarketsModal from "../components/MarketsModal";
import {toggleModal, getMarketsListAction, updateWidget, getExchangesListAction} from "../../../actions/MarketsActions";

export default connect(
    (state) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      // console.log(activeWidget);
      return {
        isOpen: state.markets.modals.markets,
        markets: state.markets.markets,
        marketsLoading: state.markets.marketsLoading,
        exchanges: state.markets.exchanges,
        widget: activeWidget,
      };
    }, {
      toggleModal,
      getExchangesListAction,
      getMarketsListAction,
      updateWidget,
    },
)(MarketsModal);
