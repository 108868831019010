import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import styles from "./OrderModal.scss";
import Switch from 'rc-switch';

function onChange(value, event) {
  // eslint-disable-next-line no-console
  console.log(`switch checked: ${value}`, event);
}
class OrderModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renewDays: 25,
      autoEnabled: true,
      disabled: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { account, isOpen } = this.props;
    if (!prevProps.isOpen && isOpen && account) {
      this.setState({
        renewDays: account.redoday,
        autoEnabled: account.isredo,
      });
    }
  }

  toggle = (Switch) => {
    const { disabled } = this.state;
    this.setState({
      disabled: !disabled,
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleCheckboxChange = () => {
    this.setState({
      autoEnabled: !this.state.autoEnabled,
    });
  };

  handleSaveSettings = () => {
    const { account, setRedoAction, onToggleModal } = this.props;
    setRedoAction(account.name, this.state.autoEnabled, this.state.renewDays);
    onToggleModal();
  };

  render() {
    const { account, isOpen, onToggleModal } = this.props;
    const { disabled } = this.state;
    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={onToggleModal}
        className={classNames(styles.Modal, "order-modal", styles.moveable)}
        size="lg"
      >
        <ModalHeader toggle={onToggleModal}>
          Order Settings
        </ModalHeader>
        <ModalBody>
          <div className={styles.Wrapper}>
            <label style={{color: '#fff'}}>Auto-Renew</label>
            <div>
              <p
                className={styles.inlineparagraph}
              >This setting allows all open orders to automatically renew at a specific interval for account:
              </p>
              <Switch
                onChange={onChange}
                disabled={disabled}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
              {account ?
                <React.Fragment>
                  <p>
                    <img
                      alt=""
                      height={18}
                      src={`/img/exchanges/${account.exchange.toLowerCase()}.png`}
                    />
                    {` ${account.name}, ${account.exchange.toUpperCase()}`}
                  </p>
                </React.Fragment> :
              ""}
              <div className={styles.TextTitle}>
                <label>Renewal Interval</label>
              </div>
              <Input
                className={styles.label}
                disabled={!this.state.autoEnabled}
                type="number"
                style={{ width: '100px' }}
                value={this.state.renewDays}
                onChange={(event) => this.handleChange("renewDays", event.target.value)}
              /> <span className={styles.days}>Days</span>
            </div>
          </div>
        </ModalBody>
        <div className={styles.Buttons}>
          <button
            className={styles.SaveButton}
            onClick={this.handleSaveSettings}
            type="button"
          >
            Save
          </button>
          <button
            className={styles.CancelButton}
            onClick={onToggleModal}
            type="button"
          >
            Cancel
          </button>
        </div>
      </Modal>
    );
  }
}

OrderModal.propTypes = {
  account: PropTypes.object,
  isOpen: PropTypes.bool,
  onToggleModal: PropTypes.func,
  setRedoAction: PropTypes.func,
};

OrderModal.defaultProps = {
  account: null,
  isOpen: false,
  onToggleModal: () => false,
  setRedoAction: () => false,
};

export default OrderModal;
