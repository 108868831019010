import {connect} from "react-redux";
import BalancePage from "../components/BalancePage";

import {init, destroy} from "../../../actions/BalanceActions";
import {doUpdateSettings} from "../../../actions/UserAction";
import {getMarketsInfoAction} from "../../../actions/MarketsActions";

export default connect((state, props) => ({
  accounts: state.user.user.data ? state.user.user.data.accounts : [],
  balance: state.balance.balance.data.map((account) => ({
    ...account,
    balance: account.balance.map((item) => {
      let longName;
      if (Array.isArray(state.markets.marketsInfo[item.symbol])) {
        const exchangeSpecificName = account.exchange &&
          state.markets.marketsInfo[item.symbol].find((nameObj) =>
            nameObj.exchange.toLowerCase().includes(account.exchange.toLowerCase()));
        if (exchangeSpecificName) {
          longName = exchangeSpecificName.name;
        } else {
          longName = state.markets.marketsInfo[item.symbol]
              .find((nameObj) => nameObj.exchange === "").name;
        }
      } else {
        longName = state.markets.marketsInfo[item.symbol];
      }

      return {
        ...item,
        name: longName || item.symbol
      };
    }),
  })),
  markets: state.markets.markets,
  user: state.user.user.data,
}), {
  init,
  destroy,
  doUpdateSettings,
  getMarketsInfoAction,
})(BalancePage);
