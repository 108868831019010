import {connect} from "react-redux";
import FeedWidget from "../components/FeedWidget/FeedWidget";

import {getFeedAction, checkNewFeeds, addFilter} from "../../../actions/FeedActions";
import {toggleModal} from "../../../actions/MarketsActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const widget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null;

      return {
        feed: state.feed.feed.items,
        filters: state.feed.filters,
        lastCount: state.feed.lastCount,
        widget: widget,
      };
    }, {
      addFilter,
      getFeedAction,
      checkNewFeeds,
      toggleModal,
    },
)(FeedWidget);
