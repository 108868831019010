/* eslint react/jsx-handler-names: 0 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Select from "react-select";
import classNames from "classnames";
import styles from "./ChatModal.scss";

const initialState = {
  title: undefined,
  member: "",
  members: [],
  //hideMembers: true,
  type: "direct",
  modalType: undefined,
  alreadyExists: false,
  alreadyJoined: false
};

class PeopleModal extends Component {
  constructor() {
    super();

    this.state = initialState;
  }

  componentDidMount() {
    this.props.getPublicChannelsAction();
  }

  componentDidUpdate(prevProps) {
    const {data, isOpen, users} = this.props;
    if (!prevProps.isOpen && isOpen) {
      if (data && data._id) {
        const memberList = data.members.map((item) => item.id);
        const members = users.filter((item) => memberList.indexOf(item._id) >= 0).map((item) => ({
          label: item.username,
          value: item._id,
          avatar: item.avatar,
        }));
        this.setState({
          members: members,
          type: data.type,
        });
      } else {
        this.setState({

        });
      }
    }
  }

  handleChange = (field, value) => {
    const data = {
      [field]: value,
    };
    this.setState(data);
  };

  handleDeleteChannel = () => {
    const {deleteChannelAction, data} = this.props;
    deleteChannelAction(data._id);
    this.closeModal();
  };

  handleSaveChannel = () => {
    const {data, createChannelAction, updateChannelAction} = this.props;
    const {type, member, members} = this.state;
    debugger;
    if (data && !data._id) {
      if (member && member.value) {
        createChannelAction({
          members: [member.value],
          type: type,
        });
        this.closeModal();
      } else if (data && data._id) {
        updateChannelAction(data._id, {
          members: members.map((item) => item.value),
          type: type,
        });
        this.closeModal();
      }
    }
  };

  closeModal = () => {
    this.setState(initialState);
    this.props.toggleModal("people");
  };

  render() {
    const {isOpen, users} = this.props;
    const members = users.map((item) => ({
      value: item._id,
      label: item.username,
      avatar: item.avatar,
    }));
    return (
      <Modal
        autoFocus={false}
        isOpen={isOpen}
        toggle={this.closeModal}
        className={classNames(styles.ChatModal, styles.moveable)}
        size="md"
      >
        <ModalHeader
          className={styles.Header}
          toggle={this.closeModal}
        >
          Messages <span>& Friends</span>
        </ModalHeader>
        <ModalBody>

          <div className={styles.Wrapper}>
            <span className={styles.title}>Add People</span>
            <div className="chatmodal">
              <Select
                multi={false}
                className="OrderTypes"
                classNamePrefix="react-select"
                name="members"
                placeholder="Search..."
                value={this.state.member}
                optionClassName={styles.Option}
                onChange={(value) => this.handleChange("member", value)}
                options={members}
              //menuIsOpen
              />
            </div>
          </div>

          <button
            className={styles.SaveButton}
            onClick={this.handleSaveChannel}
            type="button"
          >
            Add
          </button>
          <button
            className={styles.CancelButton}
            onClick={this.closeModal}
            type="button"
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    );
  }
}

PeopleModal.propTypes = {
  createChannelAction: PropTypes.func,
  data: PropTypes.object,
  deleteChannelAction: PropTypes.func,
  getPublicChannelsAction: PropTypes.func,
  isOpen: PropTypes.bool,
  joinChannelAction: PropTypes.func,
  publicChannels: PropTypes.array,
  channels: PropTypes.array,
  toggleModal: PropTypes.func,
  updateChannelAction: PropTypes.func,
  users: PropTypes.array,
};

PeopleModal.defaultProps = {
  createChannelAction: () => false,
  data: undefined,
  deleteChannelAction: () => false,
  getPublicChannelsAction: () => false,
  isOpen: false,
  joinChannelAction: () => false,
  publicChannels: [],
  channels: [],
  toggleModal: () => false,
  updateChannelAction: () => false,
  users: [],
};

export default PeopleModal;
