export default [
  'Bit',
  'Bitcoin',
  'BitMoney',
  'Bitcoin Cash',
  'BitRewards',
  'BitCash',
  'BitDAO',
  'Bitcoin SV',
  'First Bitcoin',
  'BitMoney',
  'Bitcoin Gold',
  'Wrapped Bitcoin',
  'BitTorrent',
  'BitMart Token',
  'Bittrue Coin',
  'Valobit',
  'BitKan',
  'ProBit Token',
  'Bitcoin 2',
  '0xBitcoin',
];
[
  {
    svg: <img alt="Bitcoin" src="/img/symbols/BTC.png" />,
    ticker: 'BTC',
    longname: 'Bitcoin',
  },
  {
    svg: <img alt="Bitcoin" src="/img/symbols/BTT.png" />,
    ticker: 'BTT',
    longname: 'BitTorrent',
  },
];
