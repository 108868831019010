import LimitOrder from './OrderTypes/LimitOrder.js';
import MarketOrder from './OrderTypes/MarketOrder.js';
export const OrderTypes = [
  {
    label: "Order Types",
    options: [
      { label: <span><b>LMT</b> Limit</span>, value: "LMT", order: <LimitOrder/> },
      { label: <span><b>MKT</b> Market</span>, value: "MKT", order: <MarketOrder/> },
    ]
  },
];
