// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2i7PeLDd0t4ILtk20Ukc7j{height:calc(100% - 24px);width:100%;display:flex;margin:0;overflow:hidden}._2i7PeLDd0t4ILtk20Ukc7j ._1PAbFrOIFdjKr7uA8TXUD9{min-width:300px;margin-right:0;width:100%;height:100%;display:flex;flex-direction:column;transition:width .3s,margin .3s;-webkit-transition:width .3s,margin .3s}._2i7PeLDd0t4ILtk20Ukc7j ._1PAbFrOIFdjKr7uA8TXUD9._5o2VWmD0yRVfEFnXFSdnQ{margin-right:-300px}", "",{"version":3,"sources":["webpack://src/pages/Chat/components/Messenger.scss"],"names":[],"mappings":"AAGE,yBACE,wBAAA,CACA,UAAA,CACA,YAAA,CACA,QAAA,CACA,eAAA,CAEA,kDACE,eAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,+BAAA,CACA,uCAAA,CAEA,yEACE,mBAAA","sourcesContent":["@import \"../../../styles/constants.scss\";\n\n:local {\n  .Messenger {\n    height: calc(100% - 24px);\n    width: 100%;\n    display: flex;\n    margin: 0;\n    overflow: hidden;\n\n    .Content {\n      min-width: 300px;\n      margin-right: 0;\n      width: 100%;\n      height: 100%;\n      display: flex;\n      flex-direction: column;\n      transition: width 0.3s, margin 0.3s;\n      -webkit-transition: width 0.3s, margin 0.3s;\n\n      &.Hidden {\n        margin-right: -300px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Messenger": "_2i7PeLDd0t4ILtk20Ukc7j",
	"Content": "_1PAbFrOIFdjKr7uA8TXUD9",
	"Hidden": "_5o2VWmD0yRVfEFnXFSdnQ"
};
export default ___CSS_LOADER_EXPORT___;
