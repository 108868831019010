export const CATEGORY = [
  {
    label: "Technology",
    options: [
      {
        label: "Blockchain",
        value: "blockchain",
        image: <img alt="blockchain" src="/img/icons/blockchain.svg" />,
      },
      {
        label: "Cryptocurrency",
        value: "Cryptocurrency",
        image: <img alt="blockchain" src="/img/icons/crypto.svg" />,
      },
      {
        label: "Metaverse",
        value: "metaverse",
        image: <img alt="blockchain" src="/img/icons/globe.svg" />,
      },
      {
        label: "NFT",
        value: "NFT",
        image: <img alt="blockchain" src="/img/icons/nft.svg" />,
      },
      {
        label: "Web3",
        value: "Web3",
        image: <img alt="blockchain" src="/img/icons/web3.svg" />,
      },
    ]
  },
  {
    label: "Law",
    options: [
      {
        label: "Law",
        value: "Law",
        image: <img alt="blockchain" src="/img/icons/gavel.svg" />,
      },
      {
        label: "Regulation",
        value: "Regulation",
        image: <img alt="blockchain" src="/img/icons/regulation.svg" />,
      },
    ]
  },
  {
    label: "Media",
    options: [
      {
        label: "Market Updates",
        value: "Market Updates",
        image: <img alt="blockchain" src="/img/icons/marketupdate.svg" />,
      },
      {
        label: "Press Release",
        value: "Press Release",
        image: <img alt="blockchain" src="/img/icons/pressrelease.svg" />,
      },
    ]
  },
  {
    label: "Analysis",
    options: [
      {
        label: "Technical Analysis",
        value: "Technical Analysis",
        image: <img alt="blockchain" src="/img/icons/technicalanalysis.svg" />,
      },
      {
        label: "Analysis",
        value: "Analysis",
        image: <img alt="blockchain" src="/img/icons/analysis.svg" />,
      },
    ]
  },
  {
    label: "Economics",
    options: [
      {
        label: "Economics",
        value: "Economics",
        image: <img alt="blockchain" src="/img/icons/economics.svg" />,
      },
    ]
  },
];
