/**
 * Chart box model geometry
 */


export function makeBox(viewportWidth, viewportHeight, boxConfig) {
  const marginT = boxConfig.margin[0] * 2;
  const marginR = boxConfig.margin[1] * 2;
  const marginB = boxConfig.margin[2] * 2;
  const marginL = boxConfig.margin[3] * 2;

  const boxTop = marginT + viewportHeight * boxConfig.top * 2;
  const boxLeft = marginL;
  const boxWidth = viewportWidth * 2 - marginL - marginR;
  const boxHeight = viewportHeight * boxConfig.height * 2 - marginT - marginB;

  const padding = boxConfig.padding * 2;

  const box = {};

  box.padding = [
    Math.round(boxLeft),
    Math.round(boxTop),
    Math.round(boxWidth),
    Math.round(boxHeight),
  ];

  box.content = [
    Math.round(boxLeft + padding),
    Math.round(boxTop + padding),
    Math.round(boxWidth - padding * 2),
    Math.round(boxHeight - padding * 2)
  ];

  return box;
}

export function initGeometry(geometryConfig, width, height) {
  const geometry = {
    boxPrice: null,
    boxVolume: null,
  };
  geometry.boxPrice = makeBox(width, height, geometryConfig.boxPrice);

  if (geometryConfig.boxVolume) {
    geometry.boxVolume = makeBox(width, height, geometryConfig.boxVolume);
  } else {
    geometry.boxVolume = null;
  }
  return geometry;
}
