import {connect} from "react-redux";
import {toggleModal} from "../../../actions/MarketsActions";
import ChannelModal from "../components/ChannelModal";
import {editUserAction} from "../../../actions/UserAction";
import {
  createChannelAction, deleteChannelAction, updateChannelAction, getPublicChannelsAction, joinChannelAction,
  getRootChannelsAction,
} from "../../../actions/ChatActions";

export default connect(
    (state) => {
      const users = state.chat.users;
      const user = state.user.user.data;

      return {
        rootChannels: state.chat.rootChannels,
        isOpen: state.markets.modals.channel,
        data: state.markets.modalData,
        user: user,
        publicChannels: state.chat.publicChannels,
        channels: state.chat.channels,
        users: users.filter((item) => item._id !== user.id),
      };
    }, {
      toggleModal,
      createChannelAction,
      joinChannelAction,
      getPublicChannelsAction,
      getRootChannelsAction,
      deleteChannelAction,
      updateChannelAction,
      editUserAction
    },
)(ChannelModal);
