import { relativeFontSize, formatPrice } from '../chartTools';
import { toScreen } from '../coordinates';

export function updateOrderLines(view, layer, scaleCanvas, quotes, orders) {
  const boxContent = view.geometry.boxPrice.content;
  const cc = window.cc;
  const xStart = boxContent[0];

  for (let i = 0; i < orders.length; ++i) {
    const backgroundColorRGBA = orders[i].side === "buy" ?
    view.style.activeOrder.colorBuyRGBA : view.style.activeOrder.colorSellRGBA;
    //{r: 255, g: 255, b: 255, a: 1};{r: 25, g: 26, b: 31, a: 1};
    const strokeColor = orders[i].side === "buy" ?
    view.style.activeOrder.colorBuy : view.style.activeOrder.colorSell;
    const fontColorRGBA = {r: 255, g: 255, b: 255, a: 1};
    //orders[i].side === "buy" ? view.style.activeOrder.colorBuyRGBA : view.style.activeOrder.colorSellRGBA;
    const price = orders[i].yValue;
    const yPos = toScreen(price, boxContent[3], quotes.min, quotes.max) + boxContent[1];
    if (yPos + 15 > boxContent[3] || yPos < 15) {
      continue;
    }
    const xEnd = xStart + 75 + Math.min(orders[i].text.length, 22) * 10;

    const removeOrder = new cc.TextDrawing({x: xEnd - 30, y: yPos - 10}, scaleCanvas);
    removeOrder.id = `order-${orders[i].id}-remove`;
    removeOrder.setFontColor = fontColorRGBA;
    removeOrder.setFontSize = 26;
    removeOrder.setText = "✕";
    removeOrder.setFontBold = false;
    removeOrder.isDrawing = true;
    layer.appendChildren(removeOrder);

    const text = new cc.TextDrawing({x: xStart + 40, y: yPos - 8}, scaleCanvas);
    text.id = `order-${orders[i].id}-text`;
    text.setFontColor = fontColorRGBA;
    text.setFontSize = 20;
    text.setText = orders[i].text.substring(0, 22);
    text.setFontBold = false;
    text.isDrawing = true;
    layer.appendChildren(text);

    const textRect = new cc.Rect({
      x1: xStart + 30,
      y1: yPos - 16,
      x2: xEnd,
      y2: yPos + 16
    }, scaleCanvas);

    textRect.id = `order-${orders[i].id}-textRect`;
    textRect.setFillColor = backgroundColorRGBA;
    textRect.setStrokeColor = strokeColor;
    textRect.setStrokeWidth = 4;
    textRect.isDrawing = true;
    layer.appendChildren(textRect);

    const line = new cc.HorizontalLine({
      x: xStart,
      y: yPos
    }, scaleCanvas, boxContent[2], boxContent[3]);

    line.id = `order-${orders[i].id}-line`;
    line.isDrawing = true;
    line.setStrokeColor = strokeColor;
    line.setLineStyle = "Solid";
    //const settings = item.settings;
    //this.applyDrawingSetting(line, settings)
    layer.appendChildren(line);
    layer.appendChildren(removeOrder);
  }
  return layer;
}

export function displayOrderPrices(view, quotes, orders) {
  const boxContent = view.geometry.boxPrice.content;
  const ctx = view.ctx;

  for (let i = 0; i < orders.length; ++i) {
    const color = orders[i].side === "buy" ? view.style.activeOrder.colorBuy : view.style.activeOrder.colorSell;
    const price = orders[i].yValue;
    const yPos = toScreen(price, boxContent[3], quotes.min, quotes.max) + boxContent[1];
    if (yPos + 15 > boxContent[3] || yPos < 15) {
      continue;
    }
    // draw price text background rect
    const fontSize = relativeFontSize(view.width, view.fontSize);
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.fillRect(
        view.geometry.boxPrice.padding[0] + view.geometry.boxPrice.padding[2],
        yPos - fontSize / 2 - 3.2 * 2,
        view.config.geometry.boxPrice.margin[1] * 2,
        fontSize + 6 * 2);
    //ctx.stroke();
    //draw price text
    ctx.beginPath();

    ctx.font = `${fontSize}px "Arial"`;
    ctx.fillStyle = "#fff";
    ctx.fillText(
        formatPrice(price),
        view.geometry.boxPrice.padding[0] + view.geometry.boxPrice.padding[2] + view.config.padding * 2,
        yPos + fontSize / 3,
    );
  }
}
