import React, {Component} from "react";
import PropTypes from "prop-types";
import {Form, Container, Col, Row, FormGroup, Input, InputGroup, Alert} from "reactstrap";
import FontAwesome from "../../../components/FontAwesome";
import Button from "../../../components/Button";

import styles from "./SignupPage.scss";
import {Link} from "react-router-dom";

const fields = [
  {name: "username", type: "text", title: "Username", placeholder: "Username", icon: 'user'},
  {name: "email", type: "email", title: "Email", placeholder: "Email", icon: 'envelope'},
  {name: "password", type: "password", title: "Password", placeholder: "Password", icon: 'lock'},
  {name: "token", type: "text", title: "2FA token", placeholder: "2FA Token", icon: 'key'},
];

class SignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      username: "",
      password: "",
      token: "",
      error: false
    };
  }

  componentDidMount() {
    this.props.getTmpSecret();
  }

  handleSignup = async (event) => {
    event.preventDefault();
    try {
      await this.props.doSignup({
        email: this.state.email,
        username: this.state.username,
        password: this.state.password,
        secret: this.props.tmpSecretKey,
      });
      this.props.history.push('/login');
    } catch (e) {}
  };

  checkToken = () => this.props.doCheckToken({
    token: this.state.token,
  });

  onHandleChange = (field, value) => {
    this.setState({
      [field]: value,
    });

    if (field === "token" && value.length === 6) {
      setTimeout(() => this.checkToken(), 200);
    }
  };

  render() {
    return (
      <div className="signup-wrapper">
        <Container>
          <Row>
            <Col className="text-left">
              <h2>Hello There !</h2>
              {/*<h6>Welcome to the Beta launch.
                To keep you secure please create your account with email, password and 2FA</h6>*/}
              <h6>
                To keep you secure please create your account with email, password and 2FA.
              </h6>
            </Col>
          </Row>
          <Form onSubmit={this.handleSignup}>
            <Row className="pt-3">
              <Col xs="8" sm="8">
                {this.state.error&&(<Alert color="warning">
                  <p>
                    {this.state.error}
                  </p>
                </Alert>)}
                {fields.map((field) => (
                  <FormGroup key={field.name}>
                    <div className="inputWrapper">
                      {field.name === "token" && this.props.tokenValid && (
                        <div className="successIcon">
                          <FontAwesome
                            name="check"
                          />
                        </div>
                      )}
                      <InputGroup
                        key={field.name}
                      >
                        <div className="input-group-addon" addonType="prepend">
                          <FontAwesome
                            name={field.icon}
                            prefix="fas"
                          />
                      </div>
                        <Input
                          type={field.type}
                          name={field.name}
                          onChange={(event) => this.onHandleChange(field.name, event.target.value)}
                          onBlur={() => field.name === "token" && this.checkToken()}
                          placeholder={field.placeholder}
                          value={this.state[field.name]}
                          required
                        />
                      </InputGroup>
                    </div>
                    {field.name === "token" && (
                      <p className="tokenSecret">2FA secret is: {this.props.tmpSecretKey}</p>
                    )}
                    {field.name === "token" && (
                      <img src={this.props.tmpSecretImg} alt={this.props.tmpSecretKey}/>
                    )}
                  </FormGroup>
                ))}
                <Button
                  className="SaveButton"
                  onClick={this.handleLogin}
                  type="submit"
                  label="Signup"
                />
                <row className={styles.checkrow}>
                  Already have an account?<Link to="/login"> Login!</Link>
                </row>
              </Col>
            </Row>
          </Form>
          <span>By signing up, I agree to aurlix&apos;s <a href={`https://${window.location.host}/tos`}>
            Terms of Service</a>
          </span>
        </Container>
      </div>
    );
  }
}

SignupPage.propTypes = {
  doCheckToken: PropTypes.func.isRequired,
  doSignup: PropTypes.func.isRequired,
  getTmpSecret: PropTypes.func,
  tmpSecretImg: PropTypes.string,
  tmpSecretKey: PropTypes.string,
  tokenValid: PropTypes.bool,
};

SignupPage.defaultProps = {
  getTmpSecret: () => false,
  tmpSecretImg: undefined,
  tmpSecretKey: undefined,
  tokenValid: false,
};

export default SignupPage;
