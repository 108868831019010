import React, {Fragment, Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Collapse from "../../../../components/Collapse";

import styles from "../SettingsPage.scss";
import FontAwesome from "../../../../components/FontAwesome";

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.user.avatar,
      userName: props.user.username,
      fullName: props.user.fullName,
      email: props.user.email,
    };
  }

  handleSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
        }),
      false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleSaveAccountSettings = () => {
    this.props.editUserAction({
      avatar: this.state.src,
      username: this.state.userName,
      fullName: this.state.fullName,
    });
  };

  handleSaveEmail = () => {
    this.props.editUserAction({
      email: this.state.email,
    });
  };

  drawProfileSettings = () => {
    const {userName, fullName} = this.state;
    return (
      <Fragment>
        <div className={styles.Container}>
          <div className={classNames(styles.Col, styles.Left)}>
            <div className={styles.InputGroup}>
              <label>Username</label>
              <input
                value={userName}
                onChange={(event) => this.handleChange("userName", event.target.value)}
              />
            </div>
            <div className={styles.InputGroup}>
              <label>Full name</label>
              <input
                value={fullName}
                onChange={(event) => this.handleChange("fullName", event.target.value)}
              />
            </div>
          </div>
          <div className={styles.Col}>
            <div className={styles.InputGroup}>
              <label>Profile photo</label>
              <label
                className={styles.FileButton}
                htmlFor="avatar-input"
              >
                {this.state.src ? (
                  <img src={this.state.src} alt=""/>
                ) : (
                  <div className={styles.DefaultAvatar}>
                    {userName ? userName[0].toUpperCase() : ""}
                  </div>
                )}
                <div className={styles.Change}>
                  <FontAwesome
                    wrapperClassName={styles.Icon}
                    prefix="fas"
                    name="camera"
                  />
                  Change Profile Photo
                </div>
              </label>
              <input
                id="avatar-input"
                type="file"
                onChange={this.handleSelectFile}
              />
            </div>
          </div>
        </div>
        <button
          className={styles.SaveBtn}
          onClick={this.handleSaveAccountSettings}
        >
          Save
        </button>
      </Fragment>
    );
  };

  drawEmailSettings = () => {
    const {email} = this.state;
    return (
      <Fragment>
        <div className={styles.InputGroup}>
          <label>Email</label>
          <input
            value={email}
            onChange={(event) => this.handleChange("email", event.target.value)}
          />
        </div>
        <button
          className={styles.SaveBtn}
          onClick={this.handleSaveEmail}
        >
          Save
        </button>
      </Fragment>
    );
  };

  render() {
    const {user} = this.props;

    return user && (
      <div className={styles.Wrapper}>
        {/* <Collapse
          label="Profile"
          description="Edit user profile settings"
        >
          {this.drawProfileSettings()}
        </Collapse> */}
        <Collapse
          label="Email"
          description="Change email address"
        >
          {this.drawEmailSettings()}
        </Collapse>
      </div>
    );
  }
}

AccountPage.propTypes = {
  editUserAction: PropTypes.func,
  user: PropTypes.object,
};

AccountPage.defaultProps = {
  editUserAction: () => false,
  user: undefined,
};

export default AccountPage;
