import Table from "../Table/Table";
import React,  {Component, Fragment, useEffect, useMemo, useState} from "react";
import WidgetSearch from "../WidgetSearch/WidgetSearch";
import styles from './WidgetScanner.module.scss';
import WidgetCoinFilter from "../WidgetCoinFilter/WidgetCoinFilter";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {getWidgetById, getWidgetExchange} from "../../selectors/wigdet";
import curry from "lodash/curry";
import {fetchMarketExchanges, pauseScan, startScan, stopScan} from "../../actions/WidgetScanner";
import FontAwesome from "../../components/FontAwesome";
import {
  getProgressIndex, getWidgetFilter,
  getWidgetMarkets,
  getWidgetPatterns,
  isWidgetLoading
} from "../../selectors/wigdetScanner";
import {updateFirstChartWidget, updateWidget} from "../../actions/MarketsActions";
import classNames from "classnames";
import ScannerTypes from "./constants";

const WidgetScanner = ({id}) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchedInput, setSearchedInput] = useState("");
  const exchange = useSelector(curry(getWidgetExchange)(id));
  const patterns = useSelector(curry(getWidgetPatterns)(id));
  const progress = useSelector(curry(getProgressIndex)(id));
  const widget = useSelector(curry(getWidgetById)(id));
  const markets = useSelector(curry(getWidgetMarkets)(id));
  const filter = useSelector(curry(getWidgetFilter)(id));
  const isLoading = useSelector(curry(isWidgetLoading)(id));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMarketExchanges(id))
        .then(() => setIsLoaded(false));
  }, [exchange]);

  useEffect(() => {
    return () => dispatch(pauseScan(id));
  }, []);

  const handleUpdateWidget = (data) => {
    dispatch(updateWidget({
      ...widget,
      ...data
    }));
  };
  const handleStartScan = () => dispatch(startScan(id));
  const handlePauseScan = () => dispatch(pauseScan(id));
  const handleStopScan = () => dispatch(stopScan(id));
  const handleClickPattern = ((item, i) => () => {
    const Scanner=ScannerTypes.find(scanner=>scanner.options.find(({id})=>id===item.scannerId)).options.find(({id})=>id===item.scannerId);
    setSelectedItem(i);
    dispatch(updateFirstChartWidget(
        `${item.base}-${item.quote}`,
        exchange,
        {
          marketId: item.id,
          drawingTools: Scanner.drawingTools.map(fn=>fn(item))
        }),
    )
    ;
  });
  const searchedPatterns = useMemo(() => {
    if (!searchedInput) return patterns;
    return patterns.filter(({base}) => {
      return base.includes(searchedInput.toUpperCase());
    });
  }, [searchedInput, patterns]);
  const FIELDS = [
    {name: "TICKER", key: "TICKER", width: "60px"},
    {name: "TOKEN/PAIR", key: "TOKEN", width: "205px"},
    {name: "SENTIMENT", key: "SENTIMENT", width: "80px"},
    {name: "INTERVAL", key: "INTERVAL", width: "80px"},
    {name: "TYPE", key: "TYPE", width: "150px"},
    {name: "RESULT", key: "RESULT", width: "100px"},
    {name: "AGE", key: "AGE", width: "120px"},
    {name: "STATUS", key: "STATUS"},
  ];
  return (
    <div className="marketsWidget disableDrag">
      <Fragment>
        <WidgetSearch onChange={(data) => setSearchedInput(data)}/>
        <WidgetCoinFilter base={filter} onChange={(filter) => handleUpdateWidget({filter})}/>
        <div className={styles.WidgetScannerList}>
          <table>
            <thead>
              <tr>
                {FIELDS.map((field) => (
                  <th
                    key={field.name}
                    className={field.key}
                    style={{width: field.width}}
                    //onClick={() => this.handleSort(field.name)}
                  >
                    {` ${field.name}`}
                    <span className="fa-layers">
                      <FontAwesome
                        name="chevron-up"
                        dataFaTransform="up-6"
                      />
                      <FontAwesome
                        name="chevron-down"
                        dataFaTransform="down-6"
                      />
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={styles.tbodyScanner}>

              {searchedPatterns.map((data, i) =>
                (<tr
                  key={i}
                  className={classNames({
                    [styles.activeRow]: i===selectedItem
                  })}
                  onClick={handleClickPattern(data, i)} >
                  <td style={{width: 50}}>
                    <div className={styles.ticker}>
                      {data.quote}
                    </div>
                  </td>
                  <td style={{width: 215}}>
                    <span>
                      <div>
                        <span className={styles.longname}>
                          Longname <span className={styles.base}> / {data.base}</span>
                        </span>
                        <div className={styles.description}>
                          Store of Value
                        </div>
                      </div>
                    </span>
                  </td>
                  <td style={{width: 80}} className={styles.tdScanner}>
                    {data.sentiment}
                  </td>
                  <td style={{width: 80}} className={styles.tdScanner}>
                    {data.interval}
                  </td>
                  <td style={{width: 150}} className={styles.tdScanner}>
                    {data.type}
                  </td>
                  <td style={{width: 100}} className={styles.tdScanner}>
                    {data.result}
                  </td>
                  <td style={{width: 120}} className={styles.tdScanner}>
                    {data.ageStart} - {data.ageEnd} bars ago
                  </td>
                  <td className={styles.tdScanner}>
                    Completed
                  </td>
                </tr>))}
            </tbody>
          </table>
        </div>
        <div className={styles.WidgetScannerStatus}>
          <div> {<span>
            Scan Progress {progress} / {markets.length} Found: {patterns.length}
          </span>}</div>
          {<button
            className={styles.cancelButton}
            onClick={handleStopScan}
            type="button"
          >
            Clear
          </button>}
          {isLoading ? <button
            className={styles.Button + " " + styles.ButtonStop}
            type="button"
            onClick={handlePauseScan}
          >
            {"Stop"}
          </button> :
                    (!isLoaded && <button
                      className={styles.Button}
                      type="button"
                      onClick={handleStartScan}
                      disabled={isLoading}
                    >
                      {"Scan"}
                    </button>)}
        </div>
      </Fragment>
    </div>

  );

};
WidgetScanner.propTypes = {
  id: PropTypes.string
};
export default WidgetScanner;
