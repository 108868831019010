import {connect} from "react-redux";
import OrdersPage from "../components/OrdersPage";
import {
  fetchOrdersList,
  cancelOrderAction,
  renewOrderAction,
  setRedoAction,
} from "../../../actions/OrdersActions";
import {fetchAccounts} from "../../../actions/AccountsActions";

export default connect((state) => ({
  accounts: state.accounts.accounts.data,
  orders: state.orders.orders.data,
  ordersLoading: state.orders.orders.loading,
}), {
  fetchOrdersList,
  cancelOrderAction,
  renewOrderAction,
  setRedoAction,
  fetchAccounts,
})(OrdersPage);
