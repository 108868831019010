// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ZcD7p4j70trwl813IUy45{height:calc(100% - 40px);margin-top:3px}", "",{"version":3,"sources":["webpack://src/components/Widget/Widget.module.scss"],"names":[],"mappings":"AAAA,yBACE,wBAAA,CACA,cAAA","sourcesContent":[".WidgetContent{\n  height: calc(100% - 40px);\n  margin-top: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WidgetContent": "_1ZcD7p4j70trwl813IUy45"
};
export default ___CSS_LOADER_EXPORT___;
