import FontAwesome from "../FontAwesome";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {toggleModal as toggleModalAction} from "../../actions/MarketsActions";
import {useDispatch, useSelector} from "react-redux";
import styles from './WidgetCoinFilter.module.scss';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import getSymbolFromCurrency from "currency-symbol-map";
import classNames from "classnames";
const stableCoins=[
  "USDT",
  "TUSD",
  "USDC",
  "USDP",
  "BIDR",
  "DAI",
  "VAI",
];

const currencies =[
  "AUD",
  "BIDR",
  "EUR",
  "GBP",
  "IDRT",
  "NGN",
  "RUB",
  "TRY",
  "UAH",
  "USD"
];
export const excludeFromAltsList=[
  "BTC",
  "USDT",
  "USDC",
  "BIDR",
  "AUD",
  "EUR",
  "GBP",
  "USD",
];
export const filterCoinFn=(base)=>(item)=>{
  if (base){
    if (base==="ALTS"){
      return !excludeFromAltsList.includes(item.quote);
    } else {
      return item.quote===base;
    }
  } else {
    return true;
  }
};
const WidgetCoinFilter = ({onChange, base: defaultBase}) => {
  const toggleModal = () => dispatch(toggleModalAction("widgetFilterModal"));
  const handleToggleFavourites = () => null;
  const dispatch = useDispatch();
  const [base, setBase] = useState(defaultBase);
  useEffect(()=> {
    onChange&&onChange(base);
  }, [base]);


  return (<div className={styles.favourites}>
    <button
      className={styles.gold}
      onClick={handleToggleFavourites}
    >
      <FontAwesome
        prefix="fas"
        name="star"
      /> Favourites&nbsp;<span className={styles.tickerplus}>+125</span>
    </button>
    {/*<button
      className={classNames({
        [styles.white]: true,
        [styles.selected]: base==="BTC"
      })}
      onClick={() => setBase("BTC")}>
      <FontAwesome
        prefix="fab"
        name="btc"
      /> BTC</button>*/}
    <UncontrolledDropdown className={styles.fiat}>

      <DropdownToggle
        className={classNames({
          [styles.white]: true,
          [styles.selected]: !base
        })}
      >
        <FontAwesome
          prefix="fab"
          name="btc"
        /> Tickers&nbsp;<span className={styles.tickerplus}>+1</span>
      </DropdownToggle>
      <DropdownMenu className={styles.fiatItem}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.fiatTitleDiv}>
              <DropdownItem className={styles.fiatTitle}>
                Crypto pairs
              </DropdownItem>
            </div>
            <DropdownItem
              className={styles.dropdownItem}
              onClick={() => setBase("BTC")}
            >
              <input
                type="checkbox"
                className="checkbox"
                //checked
                onChange={()=>null}
              /> <img alt="BTC" className={styles.menuIcon} src={`/img/icons/BTC.svg`}/> BTC
            </DropdownItem>
            <DropdownItem
              className={styles.dropdownItem}
              onClick={() => setBase("ETH")}
            >
              <input
                type="checkbox"
                className="checkbox"
                //checked
                onChange={()=>null}
              /> ETH
            </DropdownItem>
            <DropdownItem
              className={styles.dropdownItem}
              onClick={() => setBase("XRP")}
            >
              <input
                type="checkbox"
                className="checkbox"
                //checked
                onChange={()=>null}
              /> XRP
            </DropdownItem>
            <div className={styles.fiatTitleDiv}>
              <DropdownItem className={styles.fiatTitle}>
                Stable  pairs
              </DropdownItem>
            </div>
            {stableCoins.map(coin=>(
              <DropdownItem
                key={coin}
                className={classNames({
                  [styles.dropdownItem]: true,
                  [styles.selected]: base===coin
                })}
                onClick={() => setBase(coin)}>
                <input
                  type="checkbox"
                  className="checkbox"
                  //checked
                  onChange={()=>null}
                /> <img alt={coin} className={styles.menuIcon} src={`/img/icons/${coin}.svg`}/> {coin}
              </DropdownItem>
            ))}
          </div>
          <div className={styles.column}>
            <div className={styles.fiatTitleDiv}>
              <DropdownItem className={styles.fiatTitle}>
                Currency pairs
              </DropdownItem>
            </div>
            {currencies.map(coin=>(
              <DropdownItem
                key={coin}
                className={classNames({
                  [styles.dropdownItem]: true,
                  [styles.selected]: base===coin
                })}
                onClick={() => setBase(coin)}>
                <input
                  type="checkbox"
                  className="checkbox"
                  //checked
                  onChange={()=>null}
                /> {getSymbolFromCurrency(coin)} {coin}
              </DropdownItem>
            ))}
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
    {/*<button
      className={classNames({
        [styles.white]: true,
        [styles.selected]: base==="ALTS"
      })}
      onClick={() => setBase("ALTS")}>ALTS</button>*/}
    {/*<UncontrolledDropdown className={styles.fiat}>

      <DropdownToggle
        className={classNames({
          [styles.white]: true,
          [styles.selected]: [...stableCoins, ...currencies].includes(base)
        })}
      >
        FIAT
      </DropdownToggle>
      <DropdownMenu className={styles.fiatItem}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.fiatTitleDiv}>
              <DropdownItem className={styles.fiatTitle}>
                Stable Coins <div className={styles.fiatTitleDivFa}><FontAwesome
                  prefix="far"
                  name="chevron-down"
                  className={styles.chevronDown}
                /></div>
              </DropdownItem>
            </div>
            {stableCoins.map(coin=>(
              <DropdownItem
                key={coin}
                className={classNames({
                  [styles.dropdownItem]: true,
                  [styles.selected]: base===coin
                })}
                onClick={() => setBase(coin)}>
                <img alt={coin} className={styles.menuIcon} src={`/img/icons/${coin}.svg`}/> {coin}
              </DropdownItem>
            ))}
          </div>
          <div className={styles.column}>
            <div className={styles.fiatTitleDiv}>
              <DropdownItem className={styles.fiatTitle}>
                Currencies <div className={styles.fiatTitleDivFa}><FontAwesome
                  prefix="far"
                  name="chevron-down"
                  className={styles.chevronDown}
                /></div>
              </DropdownItem>
            </div>
            {currencies.map(coin=>(
              <DropdownItem
                key={coin}
                className={classNames({
                  [styles.dropdownItem]: true,
                  [styles.selected]: base===coin
                })}
                onClick={() => setBase(coin)}>
                {getSymbolFromCurrency(coin)} {coin}
              </DropdownItem>
            ))}
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>*/}
    {/*<button
      className={classNames({
        [styles.white]: true,
        [styles.selected]: !base
      })}
      onClick={()=>setBase("")}>ALL</button>*/}
    <button
      onClick={() => toggleModal("widgetFilterModal")}
      className={classNames({
        [styles.white]: true,
        [styles.selected]: !base
      })}
    ><FontAwesome
      prefix="fas"
      name="filter"
      /> Filters&nbsp;<span className={styles.tickerplus}>+1</span></button>
  </div>);
};
WidgetCoinFilter.propTypes={
  toggleModal: PropTypes.func,
  onChange: PropTypes.func
};
export default WidgetCoinFilter;
