import { showAlert } from "../actions/CommonActions";

import {
  GET_ACCOUNTS, GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_ERROR,
  ADD_ACCOUNT, ADD_ACCOUNT_ERROR,
  SAVE_ACCOUNT, SAVE_ACCOUNT_SUCCESS, SAVE_ACCOUNT_ERROR,
  DELETE_ACCOUNT, DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_ERROR,
  ALERT_TYPES,
} from "../const";

import {
  getAccountsList,
  createAccount,
  deleteAccount,
  saveAccount,
} from "../api/AccountsApi";

const init = () => (dispatch) => {
  dispatch(fetchAccounts());
};

const fetchAccounts = () => async (dispatch, getState) => {
  const state = getState();

  if (state.accounts.accounts.data.length === 0) {
    dispatch({
      type: GET_ACCOUNTS,
    });
  }

  try {
    const accounts = await getAccountsList();

    dispatch({
      type: GET_ACCOUNTS_SUCCESS,
      accounts: accounts.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ACCOUNTS_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const addAccount = (account) => async (dispatch) => {
  dispatch({
    type: ADD_ACCOUNT,
  });

  try {
    const result = await createAccount(account);
    result && dispatch(fetchAccounts());
    dispatch(showAlert(ALERT_TYPES.SUCCESS, {
      title: `${account.exchange} Account Added`,
      message: `The account '${account.name}' has been sucessfully added`,
      icon: `$/img/exchanges/${account.exchange.toLowerCase()}.png`,
    }));
  } catch (error) {
    dispatch({
      type: ADD_ACCOUNT_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const removeAccount = (id, account) => async (dispatch) => {
  dispatch({
    type: DELETE_ACCOUNT,
  });

  try {
    await deleteAccount(id);

    dispatch({
      type: DELETE_ACCOUNT_SUCCESS,
      account: id,
    });
    dispatch(showAlert(ALERT_TYPES.SUCCESS, {
      title: `${account.exchange} Account Removed`,
      message: `The account '${account.name}' has been sucessfully removed`,
      icon: `/img/exchanges/${account.exchange.toLowerCase()}.png`,
    }));
  } catch (error) {
    dispatch({
      type: DELETE_ACCOUNT_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const updateAccount = (id, account) => async (dispatch) => {
  //console.debug("save", account);
  //if (account.secret.indexOf("*") >= 0) {
  //  dispatch({
  //    type: SAVE_ACCOUNT_ERROR,
  //    error: {error: "Please input secret"},
  //  });
  //} else {
  dispatch({
    type: SAVE_ACCOUNT,
  });

  try {
    const result = await saveAccount(id, account);

    dispatch({
      type: SAVE_ACCOUNT_SUCCESS,
      account: result,
    });
    dispatch(showAlert(ALERT_TYPES.SUCCESS, {
      title: `${account.exchange} Account Updated`,
      message: `The account '${account.name}' has been sucessfully updated`,
      icon: `/img/exchanges/${account.exchange.toLowerCase()}.png`,
    }));
    dispatch(fetchAccounts());
  } catch (error) {
    dispatch({
      type: SAVE_ACCOUNT_ERROR,
      error: error.message,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
  //}
};

export {
  init,
  fetchAccounts,
  addAccount,
  removeAccount,
  updateAccount,
};
