import moment from "moment";
import {
  getUserNameResult,
  doLoginResult,
  doGoogleLoginResult,
  doSignupResult,
  getTmpSecretResult,
  doCheckTokenResult,
  doUpdateStateResult,
  doUpdateSettingsResult,
  updateDrawingTemplatesResult,
  updateIndicatorTemplatesResult,
  getUserDrawingTemplatesResult,
  getUserIndicatorTemplatesResult,
  doLogoutResult,
  editUser,
} from "../api/UserApi";
import { getHostName } from "../utils/apiUtils";

import { showAlert } from "../actions/CommonActions";

import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  TMP_SECRET,
  TMP_SECRET_SUCCESS,
  TMP_SECRET_ERROR,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  ALERT_TYPES,
  SETTINGS_UPDATED,
  UPDATE_DRAWING,
  UPDATE_INDICATOR_TEMPLATE
} from "../const";

const getUserName = () => async (dispatch) => {
  dispatch({
    type: GET_USER,
  });

  try {
    const response = await getUserNameResult();
    const { subscriptions, isAdmin } = response.data;
    const activeSubscriptions = subscriptions.filter((item) => {
      if (parseInt(item.status) > 0) {
        const period = item.type.split(" ");
        const expireDate = moment(item.creationData).add(period[0], period[1]);
        const diff = moment().diff(expireDate, "seconds");
        return diff <= 0;
      } else {
        return false;
      }
    });

    dispatch({
      type: GET_USER_SUCCESS,
      result: {
        ...response.data,
        isPaid: isAdmin || activeSubscriptions.length > 0,
      },
    });
    return response;

  } catch (error) {
    dispatch({
      type: GET_USER_ERROR,
      error,
    });
    throw error;
  }
};

const doLogin = (params) => async (dispatch) => {
  dispatch({
    type: LOGIN,
  });

  try {
    const response = await doLoginResult(params);
    dispatch({
      type: LOGIN_SUCCESS,
      result: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      error,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, {
      title: `Authentication Error`,
      message: error.message,
      icon: {
        name: 'exclamation-triangle',
        prefix: 'fal',
      },
    }));
  }
};

const doLogout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  try {
    const response = await doLogoutResult();
    dispatch({
      type: LOGOUT_SUCCESS,
      result: response,
    });
    window.location.href = getHostName();
  } catch (error) {
    dispatch({
      type: LOGOUT_ERROR,
      error,
    });
  }
};

const doGoogleLogin = () => async (dispatch) => {
  dispatch({
    type: LOGIN,
  });

  try {
    const response = await doGoogleLoginResult();
    dispatch({
      type: LOGIN_SUCCESS,
      result: response,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      error,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const doSignup = (params) => async (dispatch) => {
  dispatch({
    type: SIGNUP,
  });

  try {
    const response = await doSignupResult(params);
    dispatch({
      type: SIGNUP_SUCCESS,
      result: response,
    });
  } catch (error) {
    dispatch({
      type: SIGNUP_ERROR,
      error,
    });
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
    throw new Error(error);
  }
};

const getTmpSecret = () => async (dispatch) => {
  dispatch({
    type: TMP_SECRET,
  });

  try {
    const response = await getTmpSecretResult();
    dispatch({
      type: TMP_SECRET_SUCCESS,
      tmpSecretImg: response.data.qrcode,
      tmpSecretKey: response.data.secret,
    });
  } catch (error) {
    dispatch({
      type: TMP_SECRET_ERROR,
      error,
    });
  }
};

const doCheckToken = (params) => async (dispatch) => {
  dispatch({
    type: CHECK_TOKEN,
  });

  try {
    const response = await doCheckTokenResult(params);
    dispatch({
      type: CHECK_TOKEN_SUCCESS,
      valid: response,
    });
  } catch (error) {
    dispatch({
      type: CHECK_TOKEN_ERROR,
      error,
    });
  }
};


const doUpdateState = (defaultWorksheets) => async (dispatch, getState) => {
  const state = getState();
  const worksheets = defaultWorksheets??state.markets.worksheets.map((worksheet) => ({
    ...worksheet,
    widgets: worksheet.widgets.map((widget) => {
      const newWidget=Object.fromEntries(Object.entries(widget).filter(([key])=>key[0]!=='_'));
      return {
        ...newWidget,
        data: [],
        loading: false,
      };
    }),
  }));
  try {
    await doUpdateStateResult({ worksheets });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};
const getUserDrawingTemplates = () => async (dispatch) => {
  try {
    const result = await getUserDrawingTemplatesResult();
    const templates = result.data ? result.data : {};
    dispatch({
      type: UPDATE_DRAWING,
      templates,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};
const updateDrawingTemplate = (templates) => async (dispatch) => {
  try {
    await updateDrawingTemplatesResult({ templates });
    dispatch({
      type: UPDATE_DRAWING,
      templates,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};
const getUserIndicatorTemplates = () => async (dispatch) => {
  try {
    const result = await getUserIndicatorTemplatesResult();
    const templates = result.data ? result.data : {};
    dispatch({
      type: UPDATE_INDICATOR_TEMPLATE,
      templates,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};
const updateIndicatorTemplate = (templates) => async (dispatch) => {
  try {
    await updateIndicatorTemplatesResult({ templates });
    dispatch({
      type: UPDATE_INDICATOR_TEMPLATE,
      templates,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const doUpdateSettings = (settings) => async (dispatch) => {
  try {
    await doUpdateSettingsResult(settings);
    dispatch({
      type: SETTINGS_UPDATED,
      settings: settings,
    });
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

const editUserAction = (data) => async (dispatch) => {
  try {
    await editUser(data);
    dispatch(showAlert(ALERT_TYPES.SUCCESS, "Profile updated"));
    getUserName()(dispatch);
  } catch (error) {
    dispatch(showAlert(ALERT_TYPES.ERROR, error.message));
  }
};

export {
  getUserName,
  doLogin,
  doGoogleLogin,
  doLogout,
  doSignup,
  getTmpSecret,
  doCheckToken,
  doUpdateState,
  doUpdateSettings,
  updateDrawingTemplate,
  updateIndicatorTemplate,
  getUserDrawingTemplates,
  getUserIndicatorTemplates,
  editUserAction,
};
