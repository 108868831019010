import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  DropdownToggle, ButtonDropdown, DropdownMenu, DropdownItem
} from "reactstrap";
import Slider from 'rc-slider';
import FontAwesome from "../../../../../components/FontAwesome";
import FloatValue from "../../../../../components/FloatValue";
import '../rc-slider.scss';
import styles from "../OrderSettings.scss";
class LimitOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //accountInput: null,
      value: 0,
      dropdownOpen: false,
      //selectedOptions: [],
      //account: props.accounts && props.accounts[0] ? props.accounts[0].name : "",
      //accountExchange: props.accounts && props.accounts[0] && props.accounts[0].exchange,
      //actionType: actionTypes[0].value,
      //orderType: orderTypes[0].value,
      //orderDuration: orderDurations[0].value,
      //dropdownOpen: false,
      //accountsDropdownOpen: false,
      //...initialTempOrder,
    };
  }
  setValue = (index) => {
    this.setState({
      value: index,
    });
  };
  onSliderChange = (value) => {
    this.setState({ value });
  };
  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  render() {
    const {dropdownOpen} = this.state;
    const {marketSummary} = this.props;
    const marks = {
      0: <div className={styles.dots}>0%</div>,
      100: <div className={styles.dots} style={{marginRight: '20px' }}>100%</div>,
    };
    return (
      <div>
        <div className={styles.orderTypesExpanded}>
          <div className={styles.greyDarkHeader}>Quantity</div>
          <div>
            <input
              type="text"
              placeholder="0"
              className={styles.outlineHeader}
              style={{width: 120, marginRight: 10}}
              //className={styles.input}
              //onBlur={(event) => this.handleValueBlur("quantity", event.target.value)}
              //onChange={(event) => this.handleChange("quantity", event.target.value)}
              //value={parseFloat(quantity)}
            />
          </div>

          <div className={styles.greyDarkHeader}>{/*<img
            alt=""
            className={styles.Icon}
            height={14}
            src={`/img/icons/${currency.toUpperCase()}.svg?`+
              `exchange=${account.exchange}`
            }
          />*/} {/*{currency}*/} Price</div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
            <div className={styles.outlineHeaderChevron}>
              <DropdownToggle>
                <FontAwesome
                  //className="InlineInputPriceCaret"
                  prefix="far"
                  name="angle-down"
                />
              </DropdownToggle>
            </div>
            <div>
              <input
                style={{width: 120}}
                type="text"
                placeholder="0"
                value={marketSummary.last}
                className={styles.outlineHeaderPrice}
              />
            </div>
            <DropdownMenu>
              <DropdownItem
              />
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <div className={styles.orderTypesExpanded}>
          <div className={styles.greyDarkHeader}>{/*<img
        alt=""
        className={styles.Icon}
        height={14}
        src={`/img/icons/${currency.toUpperCase()}.svg?`+
          `exchange=${account.exchange}`
        }
      />*/} {/*{currency}*/} Total</div>
          <div>
            <input
              type="text"
              placeholder="0"
              className={styles.outlineHeader}
              style={{width: 145}}
            //onBlur={(event) => this.handleValueBlur("quantity", event.target.value)}
            //onChange={(event) => this.handleChange("quantity", event.target.value)}
            //value={parseFloat(quantity)}
            />
          </div>
        </div>
        <div className={styles.slider}>
          <Slider
            marks={marks}
            value={this.state.value}
            min={this.state.min}
            max={this.state.max}
            step={this.state.step}
            onChange={this.onSliderChange}
            dotStyle={{
              borderColor: '#666',
              height: 7,
              width: 7,
              backgroundColor: '#666',
            }}
            activeDotStyle={{
              borderColor: '#07e',
              height: 7,
              width: 7,
              backgroundColor: '#07e',
            }}
            trackStyle={{
              backgroundColor: '#07e',
              height: 2,
              marginTop: 2,
            }}
            handleStyle={{
              borderColor: '#07e',
              height: 10,
              width: 10,
              marginTop: -2,
              backgroundColor: '#07e',
            }}
            railStyle={{
              backgroundColor: '#666',
              height: 1,
              marginTop: 2,
            }}
          />
          <div className={styles.InlineInput1}>
            <input
              type="number"
              value={this.state.value}
              className={styles.outlineHeader}
            />
          </div>
        </div>
      </div>
    );
  }
}
LimitOrder.propTypes = {
  marketSummary: PropTypes.object,
  accountBalance: PropTypes.object,
  accounts: PropTypes.array,
  addTempOrder: PropTypes.func,
  toggleModal: PropTypes.func,
  createOrderAction: PropTypes.func,
  fetchAccountBalance: PropTypes.func,
  orders: PropTypes.array,
  removeTempOrder: PropTypes.func,
  tempOrder: PropTypes.object,
  updateTempOrder: PropTypes.func,
  widget: PropTypes.object,
};

LimitOrder.defaultProps = {
  accountBalance: {},
  accounts: [],
  toggleModal: () => false,
  addTempOrder: () => false,
  createOrderAction: () => false,
  fetchAccountBalance: () => false,
  marketSummary: {},
  orders: [],
  removeTempOrder: () => false,
  tempOrder: {},
  updateTempOrder: () => false,
  widget: {},

};

export default LimitOrder;
