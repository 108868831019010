import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Toolbar from "./Toolbar";

import styles from "./Chat.scss";
import Messenger from "./Messenger";
import ChannelModal from "../containers/ChannelModalContainer";
import ProfileSettingsModal from "../containers/ProfileSettingsModalContainer";
import PeopleModal from "../containers/PeopleModalContainer";
import TeamsModal from "../containers/TeamsModalContainer";

class Chat extends Component {
  constructor() {
    super();

    this.state = {
      opened: false,
      expanded: false,
    };
  }

  componentDidMount() {
    if (Notification) {
      if (Notification.permission !== "granted" && Notification.permission !== "denied") {
        Notification.requestPermission();
      }
    }
    this.props.getUsersAction();
    this.props.getChannelsAction();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.userStatusChanged && this.props.userStatusChanged) {
      this.props.getUsersAction();
      this.props.getChannelsAction();
    }
  }

  handleOpenChat = () => {
    this.setState({
      opened: !this.state.opened,
      expanded: this.state.opened ? false : this.state.expanded,
    });
  };

  handleExpandChat = (event) => {
    event.stopPropagation();
    this.setState({
      expanded: !this.state.expanded,
      opened: !this.state.expanded,
    });
  };

  render() {
    const {opened, expanded} = this.state;
    return (
      <div
        className={classNames(styles.Wrapper, {
          [styles.Opened]: opened,
          [styles.Expanded]: expanded,
        })}
      >
        <Toolbar
          {...this.props}
          expanded={expanded}
          onHandleExpandChat={this.handleExpandChat}
          onHandleOpenChat={this.handleOpenChat}
          opened={opened}
        />
        {(opened || expanded) && (
          <Messenger
            {...this.props}
            expanded={expanded}
          />
        )}
        <ChannelModal/>
        <PeopleModal/>
        <TeamsModal/>
        <ProfileSettingsModal/>
      </div>
    );
  }
}

Chat.propTypes = {
  channels: PropTypes.array,
  getChannelsAction: PropTypes.func.isRequired,
  getUsersAction: PropTypes.func.isRequired,
  userStatusChanged: PropTypes.bool,
};

Chat.defaultProps = {
  channels: [],
  userStatusChanged: false,
};

export default Chat;
