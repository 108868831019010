import {connect} from "react-redux";
import {getImageAction} from "../../../actions/ImageActions";
import ImagePage from "../components/ImagePage";

export default connect((state) => ({
  imagePath: state.image.imagePath,
  loading: state.image.loading,
}), {
  getImageAction,
},
)(ImagePage);
