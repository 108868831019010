import {connect} from "react-redux";
import DomChartWidget from "../components/DomChartWidget/DomChartWidget";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;

      return {
        activeWidget: activeWidget,
        marketOrderbook: state.markets.marketOrderbook,
        widget: currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null,
      };
    },
)(DomChartWidget);
