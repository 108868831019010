import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import styles from './OHLCTooltip.scss';

class OHLCTooltip extends PureComponent {
  constructor() {
    super();

    this.state = {
    };
  }
  render() {
    const {cursorData, view} = this.props;
    const boxContent = view && view.geometry.boxPrice.content[2] || 0;
    const open = parseFloat(cursorData && cursorData[0].open || 0).toFixed(8);
    const close = parseFloat(cursorData && cursorData[0].close || 0).toFixed(8);
    const high = parseFloat(cursorData && cursorData[0].high || 0).toFixed(8);
    const low = parseFloat(cursorData && cursorData[0].low || 0).toFixed(8);
    let priceColor = undefined;
    if (parseFloat(open) > parseFloat(close)) {
      priceColor = view && view.style.colorBear || 'red';
    } else {
      priceColor = view && view.style.colorBull || 'green';
    }
    const len = open.length * 7;
    return (
      <div
        className={styles.test}
        style={{
          width: boxContent / 2,
        }}
      >
        <svg
          width="100%"
          height="12"
        >
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x="0"
            fill='DARKGRAY'
            textLength="12"
          >
            O
          </text>
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x="11"
            fill={priceColor}
            textLength={len}
          >
            {open}
          </text>
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x={15 + len}
            fill='DARKGRAY'
            textLength="12"
          >
            H
          </text>
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x={26 + len}
            fill={priceColor}
            textLength={len}
          >
            {high}
          </text>
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x={30 + 2 * len}
            fill='DARKGRAY'
            textLength="12"
          >
            L
          </text>
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x={39 + 2 * len}
            fill={priceColor}
            textLength={len}
          >
            {low}
          </text>
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x={43 + 3 * len}
            fill='DARKGRAY'
            textLength="12"
          >
            C
          </text>
          <text
            fontSize="12px"
            alignmentBaseline="hanging"
            dominantBaseline="hanging"
            style={{userSelect: "none"}}
            x={55 + 3 * len}
            fill={priceColor}
            textLength={len}
          >
            {close}
          </text>
        </svg>
      </div>
    );
  }
}

OHLCTooltip.propTypes = {
  cursorData: PropTypes.array,
  view: PropTypes.object
};

OHLCTooltip.defaultProps = {
};

export default OHLCTooltip;
