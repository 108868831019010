import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle,
  DropdownMenu, DropdownItem,
} from "reactstrap";
import FontAwesome from "./FontAwesome";
import Logo from "../assets/aurlix.svg";
import styles from "./Header.scss";
import Submenu from "./Submenu";

const subPages = [
  {name: "Workspaces", href: "/markets/workspaces"},
  {name: "Balances", href: "/markets/balances"},
  {name: "Orders", href: "/markets/orders"},
  {name: "History", href: "/markets/history"},
  {name: "Accounts", href: "/markets/accounts"},
];
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const {doLogout, isLogged} = this.props;
    return (
      <div className={styles.Header}>


        <Navbar color="dark" light expand="md">
          <NavbarBrand href="/markets" className="mr-auto">
            <img
              src={Logo}
              alt="aurlix"
              height={35}
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.handleToggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {isLogged && <Submenu
              items={subPages}
            />}
            <FontAwesome
              className={styles.Dropdown}
              name="plus"
              prefix="far"
            />
            <FontAwesome
              className={styles.Dropdown}
              style={{marginLeft: 15}}
              name="cog"
              prefix="fas"
            />
            <Nav className="app-header ml-auto" navbar>
              {isLogged && (
                <NavItem>
                  <NavLink href="#">
                    <FontAwesome
                      name="bell"
                      prefix="fas"
                    />
                  </NavLink>
                </NavItem>
              )}
              {isLogged && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className={styles.Dropdown}>
                    <FontAwesome
                      name="user-cog"
                      prefix="fas"
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className={styles.Dropdown} tag={Link} to="/settings/account">
                      <FontAwesome
                        name="user-cog"
                        prefix="fas"
                      />{" "}Settings
                    </DropdownItem>
                    <DropdownItem className={styles.Dropdown} tag={Link} to="/settings/subscription">
                      <FontAwesome
                        name="usd-square"
                        prefix="fas"
                      />{" "}Subscription
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {isLogged && (
                <NavItem>
                  <NavLink onClick={doLogout}>
                    <FontAwesome
                      name="sign-out-alt"
                      prefix="fas"
                    />
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

Header.propTypes = {
  doLogout: PropTypes.func,
  isLogged: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

Header.defaultProps = {
  doLogout: () => false,
  isLogged: false,
};

export default Header;
