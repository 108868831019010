export default {
  settings: {
    plots: {
      lineColor: {
        type: "color",
        hex: "#24a800"
      }
    }
  }
};
