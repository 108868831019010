import React, {Component} from "react";
import PropTypes from "prop-types";
import Block from "./Block";
import FontAwesome from "./FontAwesome";

import styles from "./Collapse.scss";

class Collapse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: !props.expanded,
    };
  }

  drawToolBar = () => {
    const {description, label, actions} = this.props;
    const {collapsed} = this.state;

    return (
      <div className={styles.Toolbar}>
        <button
          className={styles.CollapseButton}
          onClick={() => this.setState({collapsed: !collapsed})}
        >
          <FontAwesome
            wrapperClassName={styles.CollapseIcon}
            name={collapsed ? "angle-right" : "angle-down"}
            prefix="fas"
          />
          {label && (
            <div className={styles.Title}>
              {label}
            </div>
          )}
          {description && (
            <div className={styles.Description}>
              {description}
            </div>
          )}
        </button>
        {actions && (
          <div className={styles.Actions}>
            {actions}
          </div>
        )}
      </div>
    );
  };

  render() {
    const {children, col} = this.props;
    const {collapsed} = this.state;

    return (
      <Block
        toolbar={this.drawToolBar()}
        content={children}
        showContent={!collapsed}
        col={col}
      />
    );
  }
}

Collapse.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  col: PropTypes.number,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  expanded: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};

Collapse.defaultProps = {
  actions: null,
  children: null,
  col: 10,
  description: undefined,
  expanded: false,
  label: undefined,
};

export default Collapse;
