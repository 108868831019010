import {connect} from "react-redux";
import DomWidget from "../components/DomWidget/DomWidget";
import {cancelOrderAction} from "../../../actions/OrdersActions";
import {openTradeWidget, getMarketDom, subscribeMarketDomUpdate, subscribeMarketDomSnapshot,
  unsubscribePreMarketDom} from "../../../actions/MarketsActions";

export default connect(
    (state, props) => {
      const currentWorksheet = state.markets.worksheets.find((item) => item.id === state.markets.currentWorksheet);
      const activeWidget = currentWorksheet ? currentWorksheet.widgets.find((item) => item.active) : null;
      const hasTradeWidget = currentWorksheet ?
      currentWorksheet.widgets.filter((widget) => widget.type === "trade").length > 0 : false;

      const mappedOrders = [];

      if (activeWidget) {
        const splittedSymbol = activeWidget.name.split("-");
        const symbol = `${splittedSymbol[1]}/${splittedSymbol[0]}`;

        state.orders.orders.data
            .filter((account) => account.exchange.toLowerCase() === activeWidget.exchange.toLowerCase())
            .forEach((account) => {
              account.list.filter((order) => order.symbol === symbol).forEach((order) => {
                mappedOrders.push({
                  ...order,
                  accountName: account.name,
                });
              });
            });
      }

      const orderBook = {
        buy: [],
        sell: [],
      };

      if (state.markets.marketOrderbook.bids) {
        orderBook.buy = state.markets.marketOrderbook.bids.map((item) => ({
          Rate: item[0],
          Quantity: item[1],
          type: "buy",
          buyOrders: mappedOrders.filter((order) => order.side === "buy" && order.price === item[0]).length,
          sellOrders: 0,
        }));
      }

      if (state.markets.marketOrderbook.asks) {
        orderBook.sell = state.markets.marketOrderbook.asks.map((item) => ({
          Rate: item[0],
          Quantity: item[1],
          buyOrders: 0,
          type: "sell",
          sellOrders: mappedOrders.filter((order) => order.side === "sell" && order.price === item[0]).length,
        })).reverse();
      }

      return {
        activeWidget: activeWidget,
        hasTradeWidget: hasTradeWidget,
        marketOrderbook: orderBook,
        marketOrderbookUpdate: state.markets.marketOrderbookUpdate,
        orders: mappedOrders,
        widget: currentWorksheet ? currentWorksheet.widgets.find((item) => item.i === props.id) : null,
      };
    }, {
      cancelOrderAction,
      openTradeWidget,
      getMarketDom,
      subscribeMarketDomUpdate,
      subscribeMarketDomSnapshot,
      unsubscribePreMarketDom,
    }
)(DomWidget);
