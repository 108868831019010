import React from "react";
import PropTypes from "prop-types";

import styles from "./MarketsWrapper.scss";


const MarketsWrapper = ({children}) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Content}>
        {children}
      </div>
    </div>
  );
};

MarketsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MarketsWrapper;
