import { initViewModel } from './viewModel';
import { initGeometry } from './geometry';
import {
  initEventListeners,
  addEventListener,
  removeEventListener,
  triggerEvent,
} from './events';
import crosshair from './elements/crosshair';
import defaultConfig from './view/defaultConfig';
import themes from './view/themes';
import {relativeFontSize} from './chartTools';

export function chartDefaultConfig(baseCanvas, data, width, zoom, chartOffset) {

  const config = {...defaultConfig};
  config.geometry = {};
  config.geometry.boxPrice = {
    ...defaultConfig.geometry.boxPrice,
    height: 1.0,                        //100%
    margin: calculateMargin(baseCanvas, data, width, zoom, chartOffset, config.fontSize)
  };
  // config.geometry.boxVolume = {...defaultConfig.geometry.boxVolume };

  return config;
}
function calculateMargin(base, quotesData, width, zoom, offset, fontSize){
  const maxStr = calculateMaxPriceString(quotesData, width, zoom, offset);
  const relativeSize = relativeFontSize(width, fontSize);
  const ctx = base.getContext('2d');
  ctx.font = `${relativeSize}px "Arial"`;
  const priceWidth = 1.2 * ctx.measureText(maxStr).width / 2;
  const margin = [0, priceWidth, 0, 0];
  return margin;
}
function calculateMaxPriceString(quotesData, width, zoom, offset){
  const stickLength = zoom * 2;
  const capacity = Math.floor(width / stickLength);
  const plotData = quotesData.slice(
      -capacity + offset,
      Math.min(quotesData.length, quotesData.length + offset)
  );
  let max = 0.0;
  for (const quote of plotData) {
    if (quote.high > max) max = quote.high;
  }
  const maxStr = parseFloat(max).toFixed(8);
  return maxStr;
}

export function chartThemes() {
  return themes;
}

export function chartInit(
    canvasLayers,
    {
      width,
      height,
      zoom,
      offset,
      config,
      theme,
    }
) {
  const chartView = {};
  const maxDimension = 8192;
  if (width > maxDimension || height > maxDimension) {
    throw new Error(`Maximum chart dimensions exceeded: [${width}x${height}]`);
  }

  const min = 2;
  const max = 30;
  if (zoom < min) zoom = min;
  if (zoom > max) zoom = max;

  canvasLayers.base.width = canvasLayers.base.clientWidth * 2;
  canvasLayers.base.height = canvasLayers.base.clientHeight * 2;
  canvasLayers.scale.width = canvasLayers.scale.clientWidth * 2;
  canvasLayers.scale.height = canvasLayers.scale.clientHeight * 2;
  chartView.ctx = canvasLayers.base.getContext("2d");
  chartView.crosshairCtx = canvasLayers.scale.getContext("2d");
  chartView.width = width;
  chartView.height = height;
  chartView.style = theme;
  chartView.config = config;
  chartView.geometry = initGeometry(config.geometry, chartView.width, chartView.height);
  chartView.chartType = config.chartType;
  chartView.stickLength = zoom * 2;
  chartView.stickMargin = chartView.config.stickMargin * 2;
  chartView.offset = offset;
  chartView.fontSize = chartView.config.fontSize * 2;
  chartView.locale = config.locale;
  chartView.cursor = [0, 0];
  initEventListeners();
  return chartView;
}
export function chartUpdateDimension(canvasLayers, width, height, chartView){
  const maxDimension = 8192;
  if (width > maxDimension || height > maxDimension) {
    throw new Error("Maximum chart dimensions exceeded: [".concat(width, "x").concat(height, "]"));
  }
  canvasLayers.base.width = width*2;
  canvasLayers.base.height = height*2;
  canvasLayers.scale.width = width*2;
  canvasLayers.scale.height = height*2;
  chartView.width = width;
  chartView.height = height;
  chartView.geometry = initGeometry(chartView.config.geometry, chartView.width, chartView.height);
  return chartView;
}
export function updateViewModel(chartId, drawList, quotes, chartView, quoteMin, quoteMax, _capacity) {
  if (!chartView.ctx) return;
  // init current view model
  const width = chartView.geometry.boxPrice.content[2];
  const capacity = _capacity || Math.floor(width / chartView.stickLength);// + 4;
  // draw all the elements
  const viewModel = initViewModel(chartId, drawList, capacity, Math.round(chartView.offset), quotes, quoteMin, quoteMax);
  return viewModel;
}

export function chartDrawCrosshair(ts, chartView, viewModel, cursorPriceTime, moveY, stage) {
  if (!chartView.crosshairCtx) return;

  if (chartView.chartId.slice(0, 9) == 'mainchart'){
    if (stage){
      try {
        stage?.update({x: undefined, y: undefined});
      } catch (e) {
        // empty
      }
    }
  } else {
    // clear drawing
    chartView.crosshairCtx.clearRect(
        0,
        0,
        chartView.width * 2,
        chartView.height * 2
    );
  }
  const cursorData = crosshair(chartView, viewModel.quotes, cursorPriceTime, chartView.cursor, moveY);
  if (cursorPriceTime && (cursorPriceTime[0] !== cursorData[0] || cursorPriceTime[1] !== cursorData[1])) {
    triggerEvent('moveCursor', cursorData);
  }
  return cursorData;
}

export function chartAddEventListener(eventName, listener) {
  addEventListener(eventName, listener);
}

export function chartRemoveEventListener(eventName, listener) {
  removeEventListener(eventName, listener);
}
