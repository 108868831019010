import {
  SHOW_ALERT,
  HIDE_ALERT,
} from "../const";

const initialState = {
  alert: {
    type: null,
    show: false,
    message: null,
    timeout: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        alert: {
          type: action.alertType,
          show: true,
          message: action.message,
          timeout: action.timeout,
        },
      };
    case HIDE_ALERT:
      return {
        ...state,
        alert: {
          type: null,
          show: false,
          message: null,
          timeout: null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
