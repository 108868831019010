import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, InputGroupAddon} from "reactstrap";
import FontAwesome from "../../../components/FontAwesome";
import Button from "../../../components/Button";
import {ButtonDropdown, DropdownMenu, DropdownItem} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import styles from "./AccountModal.scss";

import FlagIconFactory from "react-flag-icon-css";
const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const fields = [
  {name: "exchange", title: "Exchange", type: "select", placeholder: "Select exchange"},
  {name: "name", title: "Account Nickname", type: "text", placeholder: "Create a name...", icon: 'user'},
  {name: "apikey", title: "Api Key", type: "text", placeholder: "Your api key...", icon: 'lock'},
  {name: "secret", title: "Api Secret", type: "text", placeholder: "Your api secret...", icon: 'key'},
];

const initialState = {
  _id: null,
  exchange: null,
  exchangeId: "",
  exchangeInput: "",
  name: "",
  apikey: "",
  secret: "",
  exchangesDropdownOpen: false,
  isEdit: false,
};

class AccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    const {account, open, getExchangesListAction} = this.props;
    if (prevProps.open && !open) {
      this.setState(initialState);
    }
    if (!prevProps.open && open) {
      this.setState(account);
      account && account.exchange && this.setState({
        exchangeId: account.exchange,
        exchangeInput: account.exchange,
        isEdit: true,
      });
      getExchangesListAction();
    }
  }

  replaceCodes = (code) => {
    return code.replace("uk", "gb");
  };

  handleInputChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  render() {
    const {onHandleModalToggle, open, exchanges} = this.props;
    return (
      <Modal
        autoFocus={false}
        className="AccountModal"
        isOpen={open}
        toggle={() => onHandleModalToggle()}
        size="lg"
      >
        <ModalHeader
          toggle={() => onHandleModalToggle()}
        >
          Add account
        </ModalHeader>
        <ModalBody>
          <form>
            {fields.map((field) => field.type === "select" ? (
              <div className="form-group" key={field.id}>
                <label>{field.title}</label>
                <ButtonDropdown
                  className="ExchangesDropdown"
                  disabled={this.state.isEdit}
                  isOpen={this.state.exchangesDropdownOpen}
                  toggle={() => this.setState({exchangesDropdownOpen: !this.state.exchangesDropdownOpen})}
                >
                  <InputGroupAddon
                    disabled={this.state.isEdit}
                    className="input-group-addon"
                    addonType="prepend"
                    style={{marginTop: -1}}
                    onClick={() => this.setState({exchangesDropdownOpen: !this.state.exchangesDropdownOpen})}
                  >
                    {this.state[field.name+"Id"] ?
                      <img
                        alt=""
                        height={18}
                        src={`/img/exchanges/${this.state[field.name+"Id"].toLowerCase()}.png`}
                      />:
                      <FontAwesome
                        name={"btc"}
                        prefix="fab"
                      />
                    }
                  </InputGroupAddon>
                  <span className={styles.caretdropdown}>
                    <FontAwesome
                      name={"chevron-down"}
                      prefix="fas"
                      onClick={() => this.setState({exchangesDropdownOpen: !this.state.exchangesDropdownOpen})}
                    />
                  </span>
                  <input
                    disabled={this.state.isEdit}
                    type={field.type}
                    className="form-control"
                    placeholder={field.placeholder}
                    onChange={(event) => this.handleInputChange(field.name+"Input", event.target.value)}
                    onClick={() => this.setState({
                      exchangesDropdownOpen: !this.state.exchangesDropdownOpen,
                      [field.name]: null,
                      [field.name+"Input"]: "",
                      [field.name+"Id"]: ""
                    })}
                    value={this.state[field.name+"Input"]}
                  />
                  <DropdownMenu className="DropdownMenu">
                    <PerfectScrollbar
                      options={{suppressScrollX: true}}
                      className="ExchangeScroll"
                    >
                      {exchanges
                          .filter((item) =>
                            item.name.toLowerCase().includes(this.state.exchangeInput.toLowerCase()) ||
                        item.id.toLowerCase().includes(this.state.exchangeInput.toLowerCase())
                          )
                          .map((item) => (
                            <DropdownItem
                              key={item.id}
                              onClick={() => {
                                this.handleInputChange(field.name, item.name);
                                this.handleInputChange(field.name+"Id", item.id);
                                this.handleInputChange(field.name+"Input", item.name);
                                this.setState({exchangesDropdownOpen: false});
                              }}
                            >
                              <span className="ExchangeName">
                                <img
                                  alt=""
                                  className="Icon"
                                  height={18}
                                  src={`/img/exchanges/${item.id.toLowerCase()}.png`}
                                />{item.name}
                              </span>
                              <span className="Description">Exchange / CryptoCurrency</span>
                              <span style={{float: 'right', marginRight: '15px'}} className={"Icon"}>
                                {item.countries && <FlagIcon code={this.replaceCodes(item.countries[0].toLowerCase())}/>}
                              </span>
                              <span style={{float: 'right', marginRight: '5px'}}>
                                {item.countries ? item.countries[0] : "N/A"}
                              </span>
                            </DropdownItem>
                          ))}
                    </PerfectScrollbar>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            ) : (
              <div className="form-group" key={field.name}>
                <label>{field.title}</label>
                <InputGroupAddon
                  className="input-group-addon"
                  addonType="prepend"
                >
                  <FontAwesome
                    name={field.icon}
                    prefix="fas"
                  />
                </InputGroupAddon>
                <input
                  readOnly={field.name !== 'name' && this.state.isEdit}
                  disabled={field.name !== 'name' && this.state.isEdit}
                  type={field.type}
                  className="form-control"
                  onChange={(event) => this.handleInputChange(field.name, event.target.value)}
                  placeholder={field.placeholder}
                  value={this.state[field.name]}
                  style={{ cursor: 'text' }}
                />
              </div>
            ))}
          </form>
          <Button
            className="SaveButton"
            label={this.state._id ? "Save" : "Add"}
            onClick={() => onHandleModalToggle(this.state)}
          />
          {" "}
          <Button
            label="Cancel"
            className="CancelButton"
            onClick={() => onHandleModalToggle()}
          />
        </ModalBody>
      </Modal>
    );
  }
}

AccountModal.propTypes = {
  account: PropTypes.object,
  exchanges: PropTypes.array,
  getExchangesListAction: PropTypes.func,
  onHandleModalToggle: PropTypes.func,
  open: PropTypes.bool,
};

AccountModal.defaultProps = {
  account: {},
  exchanges: [],
  getExchangesListAction: () => false,
  onHandleModalToggle: () => false,
  open: false,
};

export default AccountModal;
