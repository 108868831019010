import { toScreen } from '../coordinates';

export default function price(view, quotes, moveY, offset) {
  // const box = view.geometry.boxPrice.padding;
  const boxContent = view.geometry.boxPrice.content;
  const ctx = view.ctx;
  // view.ctx.strokeRect(...boxContent);
  const quotesLength = quotes.data.length;
  const left = boxContent[2] % view.stickLength;
  for (let i = 0; i < quotesLength; ++i) {
    if (view.chartType === 'candlestick') {
      priceCandlestick(ctx, quotes, i, boxContent, left, view.stickLength,
          view.stickMargin, moveY, view.style, quotesLength, offset);
    } else {
      priceLine(ctx, quotes, i, boxContent, view.stickLength, view.style);
    }
  }
}

function priceLine(ctx, quotes, i, boxContent, stickLength, style) {
  const xStart = boxContent[0] + i * stickLength;
  const xEnd = boxContent[0] + (i + 1) * stickLength;
  const q = quotes.data;
  const c = q[i].close;
  const prevC = i === 0 ? q[i].open : q[i - 1].close;

  ctx.strokeStyle = style.colorLine;
  ctx.beginPath();
  ctx.moveTo(
      xStart,
      toScreen(prevC, boxContent[3], quotes.min, quotes.max) + boxContent[1]
  );
  ctx.lineTo(
      xEnd,
      toScreen(c, boxContent[3], quotes.min, quotes.max) + boxContent[1]
  );
  ctx.stroke();
}

function priceCandlestick(ctx, quotes, i, boxContent, left, stickLength, stickMargin, moveY, style, quotesLength, offset) {
  const xStart = boxContent[2] - ((offset > 0 ? offset : 0) * stickLength +
  (quotesLength - (i - 3)) * stickLength) + stickMargin - moveY * 2;
  let xEnd = boxContent[2] - ((offset > 0 ? offset : 0) * stickLength +
  (quotesLength - (i - 2)) * stickLength) - stickMargin - moveY * 2;
  if (xEnd > boxContent[2]){
    xEnd = boxContent[2];
  }
  if (xEnd < xStart) {
    xEnd = xStart;
  }
  const width = stickLength - 2 * stickMargin;

  const q = quotes.data;

  const o = q[i].open;
  const h = q[i].high;
  const l = q[i].low;
  const c = q[i].close;

  let borderColor; let fillColor;
  if (o <= c) {
    borderColor = style.colorBullBorder;
    fillColor = style.colorBull;
  } else {
    borderColor = style.colorBearBorder;
    fillColor = style.colorBear;
  }

  candlestick(
      ctx,
      toScreen(o, boxContent[3], quotes.min, quotes.max) + boxContent[1],
      toScreen(h, boxContent[3], quotes.min, quotes.max) + boxContent[1],
      toScreen(l, boxContent[3], quotes.min, quotes.max) + boxContent[1],
      toScreen(c, boxContent[3], quotes.min, quotes.max) + boxContent[1],
      boxContent,
      xStart,
      xEnd,
      width,
      fillColor,
      borderColor,
  );
}

export function candlestick(ctx, o, h, l, c, boxContent, xStart, xEnd, width, fillColor, borderColor) {
  // let width = xEnd - xStart;
  // if (width % 2) {
  //   width += 1;
  // }

  const boxStick = [
    xStart,
    o,
    xEnd - xStart,
    c - o
  ];

  ctx.strokeStyle = borderColor;
  ctx.fillStyle = fillColor;

  if (boxStick[0] + width / 2 < boxContent[2]){
    ctx.beginPath();
    ctx.moveTo(
        boxStick[0] + width / 2,
        h
    );
    ctx.lineTo(
        boxStick[0] + width / 2,
        l
    );
    ctx.stroke();
  }


  if (xStart < boxContent[2] && width >= 2) {
    ctx.fillRect(...boxStick);
    ctx.strokeRect(...boxStick);
  }

}
