/* eslint react/no-array-index-key: 0 */
import { atr } from "technicalindicators";

class SupDemZone {

  constructor(input) {
    this.interval = null;
    this.state = {
      TickSize: 1 / Math.pow(10, 8),
      BarsArray: [],
      Highs: [],
      Lows: [],
      Opens: [],
      Closes: [],
      barIndex: 0,
      Zones: [],
      atrPeriod: 10,
      lookBackPeriod: 50,
      forwardPeriod: 50,
    };

    const {plotData = [], fullData = []} = input;

    const {barIndex, atrPeriod, BarsArray, Highs, Lows, Opens, Closes,
      Zones, lookBackPeriod, forwardPeriod} = this.state;

    if (!(plotData && fullData && plotData.length > 0 && fullData.length > 0)) return;
    let start = -1;
    let end = -1;
    for (let i = fullData.length - 1; i >= 0; i--) {
      if (fullData[i].timestamp === plotData[0].timestamp) {
        start = i;
      }
      if (fullData[i].timestamp === plotData[plotData.length - 1].timestamp) {
        end = i;
      }
      if (start > 0 && end > 0) break;
    }
    // let start = fullData.findIndex((ele) => ele.timestamp == plotData[0].timestamp);
    // let end = fullData.findIndex((ele) => ele.timestamp == plotData[plotData.length - 1].timestamp);
    start = start - lookBackPeriod > 0 ? start - lookBackPeriod : 0;
    end = (end > 0 && end + forwardPeriod > 0) ? end + forwardPeriod : fullData.length - 1;
    const data = fullData.slice(start, end);
    Zones.length = 0;
    Highs.length = 0;
    Lows.length = 0;
    Opens.length = 0;
    Closes.length = 0;
    BarsArray.length = 0;
    const dailyHighs = [];
    const dailyLows = [];
    const dailyOpens = [];
    const dailyCloses = [];
    for ( const i in data){
      const ele = data[i];
      dailyHighs.push(ele.high);
      dailyLows.push(ele.low);
      dailyCloses.push(ele.close);
      dailyOpens.push(ele.open);
    }
    Highs.push(dailyHighs);
    Lows.push(dailyLows);
    Opens.push(dailyOpens);
    Closes.push(dailyCloses);
    BarsArray.push(data);
    let currHiBar = 0;
    let currLoBar = 0;
    let currHiVal = 0;
    let currLoVal = 0;
    let prevHiVal = 0;
    let prevLoVal = 0;
    for (let i = 40; i < Highs[barIndex].length; i++){
      const subHighs = Highs[barIndex].slice(20, i).reverse();
      const subLows = Lows[barIndex].slice(20, i).reverse();
      const subBarsArray = BarsArray[barIndex].slice(0, i);
      if (this.isDnSwing(i, 3)){
        currHiBar = 3;
        currHiVal = subHighs[3];
      }
      if (this.isUpSwing(i, 3)){
        currLoBar = 3;
        currLoVal = subLows[3];
      }
      const atrResult = this.ATR(subBarsArray, atrPeriod).slice(20, i);
      const atrResultReversed = atrResult.reverse();
      const atr = this.RoundToTickSize(atrResultReversed[0] * 1.25);
      this.checkSupply(i, currHiBar, currHiVal, currLoVal, atr, prevHiVal, plotData);
      this.checkDemand(i, currHiBar, currHiVal, currLoVal, currLoBar, atr, prevLoVal, plotData);
      this.updateZones(i, plotData);
      prevHiVal = currHiVal;
      prevLoVal = currLoVal;
    }
  }

  RoundToTickSize = (value) => {
    const {TickSize} = this.state;
    const newValue = value - (value % TickSize);
    return newValue;
  }
  isUpSwing = (seeker, index)=>{
    const {Lows: whole_lows, barIndex} = this.state;
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    if (
      Lows[index] <= Lows[index-1] &&
      Lows[index] <= Lows[index-2] &&
      Lows[index] <= Lows[index-3] &&
      Lows[index] <= Lows[index+1] &&
      Lows[index] <= Lows[index+2] &&
      Lows[index] <= Lows[index+3] &&
      (Lows[index] < Lows[index-1] ||
        Lows[index] < Lows[index-2] ||
        Lows[index] < Lows[index-3]) &&
      (Lows[index] < Lows[index+1] ||
        Lows[index] < Lows[index+2] ||
        Lows[index] < Lows[index+3])
    ) {
      return true;
    }

    return false;
  }

  isDnSwing = (seeker, index)=>{
    const {Highs: whole_highs, barIndex} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    if (
      Highs[index] >= Highs[index-1] &&
      Highs[index] >= Highs[index-2] &&
      Highs[index] >= Highs[index-3] &&
      Highs[index] >= Highs[index+1] &&
      Highs[index] >= Highs[index+2] &&
      Highs[index] >= Highs[index+3] &&
      (Highs[index] > Highs[index-1] ||
        Highs[index] > Highs[index-2] ||
        Highs[index] > Highs[index-3]) &&
      (Highs[index] > Highs[index+1] ||
        Highs[index] > Highs[index+2] ||
        Highs[index] > Highs[index+3])
    ) {
      return true;
    }

    return false;
  }
  checkSupply = (seeker, currHiBar, currHiVal, currLoVal, atr, prevHiVal, plotData) =>{
    const {barIndex, TickSize, Zones,
      Highs: whole_highs, Opens: whole_opens, Closes: whole_closes,
      Lows: whole_lows, BarsArray: whole_barsArray} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    const BarsArray = whole_barsArray[barIndex].slice(20, seeker).reverse();
    if (currHiVal != prevHiVal){
      if (this.MAX(Highs, currHiBar)[0] <= currHiVal){
        if (!this.activeSupplyZoneExists(currHiVal) && this.isValidSupplyZone(currHiVal, currLoVal)){
          const zr = Highs[currHiBar] - Math.min(Opens[currHiBar], Closes[currHiBar]);
          let zl = (zr > atr) ? Math.max(Opens[currHiBar], Closes[currHiBar]) :
                                Math.min(Opens[currHiBar], Closes[currHiBar]);
          const zh = currHiVal;
          const zb = BarsArray[currHiBar];
          // let timestamp = bar.timestamp - bar.timestamp % 86400000;
          // let zb = plotData.find((d)=>d.timestamp == timestamp);
          const zt = "s";
          const zc = "r";
          const za = true;
          zl = (zh - zl < TickSize) ? (zh-TickSize) : zl;
          Zones.push({zl, zh, zb, zt, zc, za});
        }
      }
    }

    const con = this.isDnContinuation(seeker);
    if (con != -1){
      const currHiCon = this.MAX(Highs, con)[0];
      const currLoCon = this.MIN(Lows, con)[1];
      if (currHiCon - currLoCon <= atr){
        if (!this.activeSupplyZoneExists(currHiCon) && this.isValidSupplyZone(currHiCon, currLoCon)){
          let zl = currLoCon;
          const zh = currHiCon;
          const zb = BarsArray[con];
          // let timestamp = bar.timestamp - bar.timestamp % 86400000;
          // let zb = plotData.find((d)=>d.timestamp == timestamp);
          const zt = "s";
          const zc = "c";
          const za = true;

          zl = (zh - zl < TickSize) ? (zh - TickSize) : zl;

          Zones.push({zl, zh, zb, zt, zc, za});
        }
      }
    }
  }
  MAX = (highs, index) => {
    const max = [];
    const len = highs.length;
    for (let j = 0; j < len; j++){
      const ary = [];
      for (let k = j; k < j+index; k++){
        ary.push(k < len ? highs[k] : highs[len-1]);
      }
      max.push(Math.max(...ary));
    }
    return max;
  }
  MIN = (lows, index) => {
    const min = [];
    const len = lows.length;
    for (let j = 0; j < len; j++){
      const ary = [];
      for (let k = j; k < j+index; k++){
        ary.push(k < len ? lows[k] : lows[len-1]);
      }
      min.push(Math.min(...ary));
    }
    return min;
  }
  activeSupplyZoneExists = (hi) => {
    let exists = false;
    const Zones = this.state.Zones;

    for (const i in Zones){
      if (Zones[i].za == true && Zones[i].zt == "s"){
        if (Zones[i].zh == hi){
          exists = true;
          break;
        }
      }
    }
    return exists;
  }

  isValidSupplyZone = (hi, lo) => {
    let valid = true;
    const Zones = this.state.Zones;
    for (const i in Zones){
      if (Zones[i].za == true && Zones[i].zt == "s"){
        if ((hi <= Zones[i].zh && hi >= Zones[i].zl) || (lo <= Zones[i].zh && lo >= Zones[i].zl)){
          valid = false;
          break;
        }
      }
    }
    return valid;
  }

  isDnContinuation = (seeker) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens} = this.state;
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    let val = true;
    let bar = -1;
    for (let i = 10; i >=2; i--){
      if (this.isDnMove(seeker, i)){
        val = true;
        for (let j = i; j >=1; j--){
          if (!this.isInsideDnBar(seeker, j, i)){
            val = false;
            break;
          }
        }
        if (val){
          val = false;
          for (let j = i; j>= 1; j--){
            if (Closes[j] >= Opens[j]){
              val = true;
              break;
            }
          }
        }
        if (val){
          if (this.isInsideDnBreakoutBar(seeker, 0, i)){
            bar = i;
            break;
          }
        }
      }
    }
    return bar;
  }

  isDnMove = (seeker, index) => {
    const {barIndex, BarsArray: whole_barsArray, Closes: whole_closes} = this.state;
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    const BarsArray = whole_barsArray[barIndex].slice(20, seeker);
    const keltnerLower = this.KeltnerChannel(BarsArray, 1.0, 10).Lower.reverse();
    if (
      Closes[index] < keltnerLower[index] ||
      Closes[index+1] < keltnerLower[index + 1] ||
      Closes[index+2] < keltnerLower[index + 2]
    ){
      if (
        this.isDnBar(seeker, index) &&
        this.isDnBar(seeker, index+1) &&
        this.isDnBar(seeker, index+2)
      ){
        return true;
      }
      if (
        this.isStrongDnBar(seeker, index)
      ){
        return true;
      }
    }

    return false;
  }

  isDnBar = (seeker, index) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens, Highs: whole_highs, Lows: whole_lows} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    if (
      Closes[index] < Opens[index] &&
      Closes[index] < Closes[index+1] &&
      Highs[index] < Highs[index+1] &&
      Lows[index] < Lows[index+1]
    ){
      return true;
    }
    return false;
  }

  isStrongDnBar = (seeker, index) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens, Highs: whole_highs,
      Lows: whole_lows, BarsArray: whole_barsArray, atrPeriod} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    const BarsArray = whole_barsArray[barIndex].slice(0, seeker);
    const atrAry = this.ATR(BarsArray, atrPeriod).slice(20, seeker).reverse();
    if (
      Closes[index] < Opens[index] &&
      Closes[index] < Closes[index+1] &&
      Highs[index] < Highs[index+1] &&
      Lows[index] < Lows[index+1] &&
      Lows[index] < this.MIN(Lows, 3)[index+1] &&
      Highs[index] - Lows[index] > atrAry[1]
    ) {
      return true;
    }

    if (
      Closes[index] < Opens[index] &&
      Closes[index] < Closes[index+1] &&
      Closes[index] < this.MIN(Lows, 3)[index+1] &&
      Highs[index] - Lows[index] > atrAry[1] * 2
    ) {
      return true;
    }

    return false;
  }

  isInsideDnBar = (seeker, indexOne, indexTwo) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens, Highs: whole_highs, Lows: whole_lows} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    if (
      Highs[indexOne] <= Highs[indexTwo] &&
      Math.min(Opens[indexOne], Closes[indexOne]) >= Lows[indexTwo]
    ){
      return true;
    }
    return false;
  }

  isInsideDnBreakoutBar = (seeker, indexOne, indexTwo) => {
    const {barIndex, Closes: whole_closes, Highs: whole_highs, Lows: whole_lows} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    if (
      Highs[indexOne] <= Highs[indexTwo] &&
      Closes[indexOne] <= this.MIN(Lows, indexTwo - indexOne)[1] &&
      Lows[indexOne] < this.MIN(Lows, indexTwo-indexOne)[1]
    ){
      return true;
    }
    return false;
  }

  checkDemand=(seeker, currHiBar, currHiVal, currLoVal, currLoBar, atr, prevLoVal, plotData)=>{
    // Regular
    const {barIndex, TickSize, Zones,
      Closes: whole_closes, Opens: whole_opens, Highs: whole_highs,
      Lows: whole_lows, BarsArray: whole_barsArray} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    const BarsArray = whole_barsArray[barIndex].slice(20, seeker).reverse();
    if (currLoVal != prevLoVal){
      if (this.MIN(Lows, currLoBar)[0] >= currLoVal){
        if (!this.activeDemandZoneExists(currLoVal) && this.isValidDemandZone(currHiVal, currLoVal)){
          const zr = Math.max(Opens[currLoBar], Closes[currLoBar]) - Lows[currHiBar];
          const zl = currLoVal;
          let zh = (zr > atr) ? Math.min(Opens[currLoBar], Closes[currLoBar]) :
                                Math.max(Opens[currLoBar], Closes[currLoBar]);
          const zb = BarsArray[currLoBar];
          // let timestamp = bar.timestamp - bar.timestamp % 86400000;
          // let zb = plotData.find((d)=>d.timestamp == timestamp);
          const zt = "d";
          const zc = "r";
          const za = true;

          zh = (zh - zl < TickSize) ? (zl + TickSize) : zh;

          Zones.push({zl, zh, zb, zt, zc, za});
        }
      }
    }

    // Continuation

    const con = this.isUpContinuation(seeker);
    if (con != -1){
      const currHiCon = this.MAX(Highs, con)[1];
      const currLoCon = this.MIN(Lows, con)[0];

      if (currHiCon - currLoCon <= atr){
        if (!this.activeDemandZoneExists(currLoCon) && this.isValidDemandZone(currHiCon, currLoCon)){
          const zl = currLoCon;
          let zh = currHiCon;
          const zb = BarsArray[con];
          // let timestamp = bar.timestamp - bar.timestamp % 86400000;
          // let zb = plotData.find((d)=>d.timestamp == timestamp);
          const zt = "d";
          const zc = "c";
          const za = true;

          zh = (zh - zl < TickSize) ? (zl + TickSize) : zh;

          Zones.push({zl, zh, zb, zt, zc, za});
        }
      }
    }
  }
  activeDemandZoneExists = (lo) => {
    const {Zones} = this.state;
    let exists = false;
    for (let i = 0; i < Zones.length; i++){
      if (Zones[i].za == true && Zones[i].zt == "d"){
        if (Zones[i].zl == lo){
          exists = true;
          break;
        }
      }
    }
    return exists;
  }
  isValidDemandZone = (hi, lo) => {
    const {Zones} = this.state;
    let valid = true;
    for (let i = 0; i < Zones.length; i++){
      if (Zones[i].za == true && Zones[i].zt == "d"){
        if (
          (lo >= Zones[i].zl && lo <= Zones[i].zh) ||
          (hi >= Zones[i].zl && hi <= Zones[i].zh)
        ){
          valid = false;
          break;
        }
      }
    }
    return valid;
  }

  isUpContinuation = (seeker) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens} = this.state;
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    let val = true;
    let bar = -1;
    for (let i = 10; i>=2; i--){
      if (this.isUpMove(seeker, i)){
        val = true;
        for (let j = i-1; j>=1; j--){
          if (!this.isInsideUpBar(seeker, j, i)){
            val = false;
            break;
          }
        }
        if (val){
          val = false;
          for (let j = i; j >= 1; j--){
            if (Closes[j] <= Opens[j]){
              val = true;
              break;
            }
          }
        }
        if (val){
          if (this.isInsideUpBreakoutBar(seeker, 0, i)){
            bar = i;
            break;
          }
        }
      }
    }
    return bar;
  }

  isUpMove = (seeker, index) => {
    const {barIndex, BarsArray: whole_barsArray, Closes: whole_closes} = this.state;
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    const BarsArray = whole_barsArray[barIndex].slice(20, seeker);
    const keltnerUpper = this.KeltnerChannel(BarsArray, 1.0, 10).Upper.reverse();
    if (
      Closes[index] > keltnerUpper[index] ||
      Closes[index+1] > keltnerUpper[index + 1] ||
      Closes[index+2] > keltnerUpper[index + 2]
    ){
      if (
        this.isUpBar(seeker, index) &&
        this.isUpBar(seeker, index+1) &&
        this.isUpBar(seeker, index+2)
      ){
        return true;
      }
      if (
        this.isStrongUpBar(seeker, index)
      ){
        return true;
      }
    }

    return false;
  }

  isUpBar = (seeker, index) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens, Highs: whole_highs, Lows: whole_lows} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    if (
      Closes[index] > Opens[index] &&
      Closes[index] > Closes[index+1] &&
      Highs[index] > Highs[index+1] &&
      Lows[index] > Lows[index+1]
    ){
      return true;
    }
    return false;
  }

  isStrongUpBar = (seeker, index) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens, Highs: whole_highs, Lows: whole_lows,
      BarsArray: whole_barsArray, atrPeriod} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    const BarsArray = whole_barsArray[barIndex].slice(0, seeker);
    const atrAry = this.ATR(BarsArray, atrPeriod).slice(20, seeker).reverse();
    if (
      Closes[index] > Opens[index] &&
      Closes[index] > Closes[index+1] &&
      Highs[index] > Highs[index+1] &&
      Lows[index] > Lows[index+1] &&
      Highs[index] > this.MAX(Highs, 3)[index+1] &&
      Highs[index] - Lows[index] > atrAry[1]
    ) {
      return true;
    }

    if (
      Closes[index] > Opens[index] &&
      Closes[index] > Closes[index+1] &&
      Closes[index] > this.MAX(Highs, 3)[index+1] &&
      Highs[index] - Lows[index] > atrAry[1] * 2
    ) {
      return true;
    }

    return false;
  }

  isInsideUpBar = (seeker, indexOne, indexTwo) => {
    const {barIndex, Closes: whole_closes, Opens: whole_opens, Highs: whole_highs, Lows: whole_lows} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Opens = whole_opens[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    if (
      Lows[indexOne] >= Lows[indexTwo] &&
      Highs[indexOne] <= Highs[indexTwo] &&
      Math.min(Opens[indexOne], Closes[indexOne]) <= Highs[indexTwo]
    ){
      return true;
    }
    return false;
  }

  isInsideUpBreakoutBar = (seeker, indexOne, indexTwo) => {
    const {barIndex, Closes: whole_closes, Highs: whole_highs, Lows: whole_lows} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const Closes = whole_closes[barIndex].slice(20, seeker).reverse();
    if (
      Lows[indexOne] >= Lows[indexTwo] &&
      Closes[indexOne] >= this.MAX(Highs, indexTwo - indexOne)[1] &&
      Highs[indexOne] > this.MAX(Highs, indexTwo-indexOne)[1]
    ){
      return true;
    }
    return false;
  }

  updateZones = (seeker, plotData) => {
    const {Zones, Highs: whole_highs, barIndex, Lows: whole_lows, BarsArray: whole_barsArray} = this.state;
    const Highs = whole_highs[barIndex].slice(20, seeker).reverse();
    const Lows = whole_lows[barIndex].slice(20, seeker).reverse();
    const BarsArray = whole_barsArray[barIndex].slice(20, seeker).reverse();
    for (let i = 0; i < Zones.length; i++){
      if (Zones[i].za == true){
        if (Zones[i].zt == "s"){
          if (Highs[0] > Zones[i].zh){
            // let bar = BarsArray[0];
            // let timestamp = bar.timestamp - bar.timestamp % 86400000;
            // Zones[i].ze = plotData.find((d)=>d.timestamp == timestamp);
            Zones[i].ze = BarsArray[0];
            Zones[i].za = false;
          }
        }
        if (Zones[i].zt == "d"){
          if (Lows[0] < Zones[i].zl){
            // let bar = BarsArray[0];
            // let timestamp = bar.timestamp - bar.timestamp % 86400000;
            // Zones[i].ze = plotData.find((d)=>d.timestamp == timestamp);
            Zones[i].ze = BarsArray[0];
            Zones[i].za = false;
          }
        }
      }
    }
  }

  ATR = (ary, length) => {
    const atrResult = atr({
      high: ary.map((item) => item.high),
      low: ary.map((item) => item.low),
      close: ary.map((item) => item.close),
      period: length
    });
    return atrResult;
  }
  KeltnerChannel=(ary, multiplier, length)=>{

    const kelter = {
      Middle: [],
      Upper: [],
      Lower: []
    };

    const atrData = atr({
      high: ary.map((item) => item.high),
      low: ary.map((item) => item.low),
      close: ary.map((item) => item.close),
      period: length,
    });

    for (let i = length - 1; i < ary.length; i++ ){
      let smaTypicalSum = 0;
      for (let j = i - length + 1; j <= i; j++){
        const ele = ary[j];
        smaTypicalSum += (ele.high + ele.low + ele.close) / 3;
      }
      const kelter_Middle = smaTypicalSum / length;
      kelter.Middle[i] = kelter_Middle;
      kelter.Upper[i] = i >= length ? kelter_Middle + multiplier * atrData[i - length] : undefined;
      kelter.Lower[i] = i >= length ? kelter_Middle - multiplier * atrData[i - length] : undefined;
    }

    return kelter;
  }
}

export function supdemzone(data) {
  const supDemZone = new SupDemZone(data);
  const result = supDemZone.state.Zones;
  return result;
}

export default SupDemZone;
