import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import numeral from "numeral";

import Loader from "../../../../components/Loader";
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import FloatValue from "../../../../components/FloatValue";

import styles from "./PriceWidget.scss";

class PriceWidget extends PureComponent {
  constructor() {
    super();
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentDidMount() {
    const {getPriceData, widget} = this.props;
    //subscribe on price data
    if (widget.name) {
      getPriceData(widget.i);
    }
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentDidUpdate(prevProps, prevState) {
    const { widget, getPriceData, unsubscribePriceData } = this.props;
    if (prevProps.widget.name !== widget.name || prevProps.widget.exchange !== widget.exchange) {
      unsubscribePriceData(prevProps.widget.exchange, prevProps.widget.name);
      getPriceData(widget.i);
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup() {
    const {unsubscribePriceData, widget} = this.props;
    unsubscribePriceData(widget.exchange, widget.name);
  }

  interval = null;

  drawInfoData = () => {
    const {widget, price} = this.props;
    const dataType = widget.options && widget.options.dataType || "highLow";

    switch (dataType) {
      case "bidAsk":
        return (
          <div className={styles.Info}>
            <div className={styles.InfoItem}>
              Bid: <FloatValue value={price.bid}/>
            </div>
            <div className={styles.InfoItem}>
              Ask: <FloatValue value={price.ask}/>
            </div>
          </div>
        );
      case "highLow":
      default:
        return (
          <div className={styles.Info}>
            <div className={styles.InfoItem}>
              High: <FloatValue value={price.high}/>
            </div>
            <div className={styles.InfoItem}>
              Low: <FloatValue value={price.low}/>
            </div>
          </div>
        );
    }
  };

  drawData = () => {
    const {price} = this.props;
    const base = price.symbol.split("/")[1];
    const changeValue = price.change / price.last;
    return (
      <div
        className={styles.PriceWidget}
      >
        <div className={styles.Wrapper}>
          <div className={styles.AssetName}>
            <span className={styles.iconBase}><img alt={base} src={`/img/icons/${base}.svg`} /></span>
            <FloatValue
              className={styles.Price}
              value={price.last}
            />
            <div>
              <img className={styles.cardSVG} alt="Price Chart" src={`/img/icons/priceticker.svg`} />
            </div>
            <div style={{ marginTop: "10px"}} className={styles.HighLowBidAsk}>
              High: <FloatValue className={styles.HighLowBidAskValue} value={price.high}/>
            </div>
            <div className={styles.HighLowBidAsk}>
              Low: <FloatValue className={styles.HighLowBidAskValue} value={price.low}/>
            </div>
            <div className={styles.HighLowBidAsk}>
              Bid: <FloatValue className={styles.HighLowBidAskValue} value={price.bid}/>
            </div>
            <div className={styles.HighLowBidAsk}>
              Ask: <FloatValue className={styles.HighLowBidAskValue} value={price.ask}/>
            </div>
          </div>

          <div className={styles.InfoWrapper}>
            <div className={styles.Percent}>24 HR Change</div>
            <div className={styles.priceChange}>
              <FloatValue
                className={styles.Price}
                value={price.change}
              />

            </div>

            <div>
              <span
                className={classNames(styles.Change, {
                  up: price.change > 0,
                  down: price.change < 0,
                  green: price.change > 0,
                  red: price.change < 0,
                })}
              />
              <span
                className={classNames(styles.ChangeValue, {
                  green: price.change > 0,
                  red: price.change < 0,
                })}
              >
                {numeral((changeValue).toFixed(8)).format("0.00%")}
              </span>
            </div>

          </div>
        </div>
      </div>
    );
  };
  currency() {
    const {price} = this.props;
    const market = price.symbol.split("/")[0];
    if (market === "USDT"){
      <img alt="USDT" className="menuIcon" src="/img/icons/USDT.svg" />;
    }
  }

  render() {
    const {price, widget, setWidgetActive} = this.props;
    return (
      <div
        className={classNames("widgetWrapper", {"Active": widget.active})}
        onClick={() => !widget.active && setWidgetActive(widget.i)}
        role="button"
        tabIndex="0"
      >
        <WidgetToolbar
          {...this.props}
        />
        <div className="marketsWidget disableDrag">
          {price ? this.drawData() : (
            <Loader/>
          )}
        </div>
      </div>
    );
  }
}

PriceWidget.propTypes = {
  getPriceData: PropTypes.func,
  unsubscribePriceData: PropTypes.func,
  height: PropTypes.number,
  price: PropTypes.object,
  setWidgetActive: PropTypes.func,
  widget: PropTypes.object,
};

PriceWidget.defaultProps = {
  getPriceData: () => false,
  unsubscribePriceData: () => false,
  height: 100,
  price: undefined,
  setWidgetActive: () => false,
  widget: undefined,
};

export default PriceWidget;
