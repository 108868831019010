
export default [
  {
    title: "Fibonacci Tools",
  }, {
    id: "fibretracement",
    label: "Fibonacci Retracement",
    points: 2,
    svg: <img alt="Fibonacci Retracement" src="/img/icons/fibretracement.svg" />
  }, {
    id: "trendbasedfibextension",
    label: "Fibonacci Extension",
    points: 3,
    svg: <img alt="Fibonacci Extension" src="/img/icons/fibext.svg" />
  }, {
    id: "fibspeedresistancefan",
    label: "Fibonacci Fan",
    points: 3,
    svg: <img alt="Fibonacci Fan" src="/img/icons/fibfan.svg" />
  }, {
    id: "fibtimezone",
    label: "Fibonacci Time Zone",
    points: 2,
    svg: <img alt="Fibonacci Time Zone" src="/img/icons/fibtime.svg" />
  }, {
    id: "trendbasedfibtime",
    label: "Fibonacci Time Extension",
    points: 3,
    svg: <img alt="Fibonacci Time Extension" src="/img/icons/fibtimext.svg" />
  }, {
    id: "fibcycles",
    label: "Fibonacci Ellipse",
    points: 2,
    svg: <img alt="Fibonacci Ellipse" src="/img/icons/fibcircle.svg" />
  }, {
    id: "fibspeedresistancearcs",
    label: "Fibonacci Arc",
    points: 2,
    svg: <img alt="Fibonacci Arc" src="/img/icons/fibarc.svg" />
  }, {
    id: "fibwedge",
    label: "Fibonacci Wedge",
    points: 3,
    svg: <img alt="Fibonacci Wedge" src="/img/icons/fibwedge.svg" />
  }, {
    id: "fibchannel",
    label: "Fibonacci Channel",
    points: 3,
    svg: <img alt="Fibonacci Channel" src="/img/icons/fibchannel.svg" />
  },
  {
    title: "Gann Tools",
  }, {
    id: "dynamicsq9",
    label: "Gann Dynamic Square of 9",
    points: 2,
    svg: <img alt="Dynamic Square of 9" src="/img/icons/gannlines.svg" />
  }, {
    id: "gannbox",
    label: "Gann Grid",
    points: 2,
    svg: <img alt="Gann Grid" src="/img/icons/gannbox.svg" />
  }, {
    id: "gannfan",
    label: "Gann Fan",
    points: 2,
    svg: <img alt="Gann Fan" src="/img/icons/gannfan.svg" />
  },
  {
    title: "Pitchfork Tools",
  }, {
    id: "pitchfork",
    label: "Pitchfork",
    points: 3,
    svg: <img alt="Pitchfork" src="/img/icons/pitchfork.svg" />
  }, {
    id: "schiffpitchfork",
    label: "Schiff Pitchfork",
    points: 3,
    svg: <img alt="Schiff Pitchfork" src="/img/icons/schiffpitchfork.svg" />
  }, {
    id: "modifiedschiffpitchfork",
    label: "Modified Schiff Pitchfork",
    points: 3,
    svg: <img alt="Modified Schiff Pitchfork" src="/img/icons/schiffpitchfork.svg" />
  }, {
    id: "insidepitchfork",
    label: "Inside Pitchfork",
    points: 3,
    svg: <img alt="Modified Schiff Pitchfork" src="/img/icons/insidepitchfork.svg" />
  }, {
    id: "pitchfan",
    label: "Pitchfan",
    points: 3,
    svg: <img alt="Pitchfan" src="/img/icons/pitchfan.svg" />
  },
];
