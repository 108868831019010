// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3cD6UghpjYtKunpJlsGprQ{width:100%;font-size:11px;text-overflow:ellipsis}._3cD6UghpjYtKunpJlsGprQ tr,._3cD6UghpjYtKunpJlsGprQ th{white-space:nowrap;padding:0 5px 0 5px;font-family:\"Noto Sans\",serif !important}._3cD6UghpjYtKunpJlsGprQ tr:hover{background-color:#3b3e48}._3cD6UghpjYtKunpJlsGprQ tr{border-bottom:1px solid rgba(255,255,255,.075)}._3cD6UghpjYtKunpJlsGprQ th{color:#aaa;font-weight:bold;text-transform:uppercase}", "",{"version":3,"sources":["webpack://src/components/Table/Table.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,cAAA,CACA,sBAAA,CAEA,wDACE,kBAAA,CACA,mBAAA,CACA,wCAAA,CAEF,kCACE,wBAAA,CAEF,4BACE,8CAAA,CAEF,4BACE,UAAA,CACA,gBAAA,CACA,wBAAA","sourcesContent":[".Table {\n  width: 100%;\n  font-size: 11px;\n  text-overflow: ellipsis;\n\n  tr, th {\n    white-space: nowrap;\n    padding: 0 5px 0 5px;\n    font-family: 'Noto Sans', serif !important;\n  }\n  tr:hover{\n    background-color: #3b3e48\n  }\n  tr{\n    border-bottom: 1px solid rgba(255, 255, 255, 0.075);\n  }\n  th {\n    color: #aaa;\n    font-weight: bold;\n    text-transform: uppercase;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Table": "_3cD6UghpjYtKunpJlsGprQ"
};
export default ___CSS_LOADER_EXPORT___;
