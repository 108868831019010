import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as d3 from "d3";
import numeral from "numeral";
import ReactTooltip from 'react-tooltip';
import WidgetToolbar from "../../containers/WidgetToolbarContainer";
import {SYMBOLS_PATH} from "../../../../constants/locationPathnames";
import styles from "./TreemapWidget.scss";
import { COLS } from "../../../../constants/MarketsConstants";
import Loader from "../../../../components/Loader";

const format = "0,0.[00]";
const formatDollar = "$0,0.[00]";
const percentFormat = "0,0.[00]";

class TreemapWidget extends Component {
  constructor() {
    super();

    this.interval = null;
    this.wrapper = null;
    this.zero = "#bcb2b1";
    this.negativeColor = d3.scale.linear().range(["#aa2121", "#ed7171"]);
    this.positiveColor = d3.scale.linear().range(["#7ec17e", "#215e2c"]);
    this.state = {
      quoteAsset: null,
      height: 0,
      width: 0,
      node: null,
    };
  }

  componentDidMount() {
    this.updateInterval();
  }

  componentDidUpdate(prevProps) {
    const {widget} = this.props;
    if (prevProps.widget && widget && prevProps.widget.exchange !== widget.exchange) {
      this.updateInterval();
    } else if (
      prevProps.widget.h !== widget.h ||
      prevProps.widget.w !== widget.w ||
      prevProps.widget.expanded !== widget.expanded ||
      prevProps.widget.fW !== widget.fW ||
      prevProps.widget.fH !== widget.fH ||
      prevProps.widget.unpinned !== widget.unpinned) {
      this.handleResize();
    } else {
      if (widget.data && widget.data.length > 0 && !this.state.quoteAsset) {
        let quoteAsset = "USD"; //USDT, USDC;
        if (widget.data.findIndex((item) => item.symbol && item.symbol.includes("/") &&
                          (item.symbol.split("/")[0].toUpperCase() === quoteAsset ||
                          item.symbol.split("/")[1].toUpperCase() === quoteAsset)) < 0) {
          quoteAsset = "USDT";
        }
        this.setState({quoteAsset});
      }
      this.update();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateInterval = () => {
    const {getMarketSummaries, widget} = this.props;
    clearInterval(this.interval);
    getMarketSummaries(widget.i);
    this.interval = setInterval(() => {
      getMarketSummaries(widget.i);
    }, 5000);
  };

  handleResize = () => {
    const height = this.wrapper && this.wrapper.clientHeight || 0;
    const width = this.wrapper && this.wrapper.clientWidth || 0;
    this.setState({
      height, width,
    });
  };

  initChart = (el) => {
    this.wrapper = el;
    this.handleResize();
  };

  mapData = () => {
    const {widget: {exchange, data}} = this.props;
    let filteredData = data.filter((item) => item.symbol && item.quoteVolume && item.symbol.includes("/") &&
                      (item.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ||
                      item.symbol.split("/")[1].toUpperCase() === this.state.quoteAsset));

    filteredData = filteredData.sort((a, b) =>
      (b.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ? b.baseVolume : b.quoteVolume) -
      (a.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ? a.baseVolume : a.quoteVolume)
    ).slice(0, 150); //max nodes = 150;

    return {
      name: exchange || "crypto",
      children: filteredData.map(d => {
        return {
          name: d.symbol,
          volume: d.symbol.split("/")[0].toUpperCase() === this.state.quoteAsset ? +d.baseVolume : +d.quoteVolume,
          change: +d.change,
          price: +d.close,
        };
      })
    };
  };

  getColor = (item) => {
    if (item.change && item.change !== 0) {
      return item.change > 0 ? this.positiveColor(item.change) : this.negativeColor(item.change);
    }
    return this.zero;
  };

  getStyle = (attr, d, type = "name") => {
    switch (attr) {
        case "font-weight":
            return type === "name" ? 900 : 300;

        case "font-size":
            const nameParts = d.name.split('/');
            const maxFontSize = Math.min(d.dy, d.dx) * 0.14;
            const totalTextWidth = d3.max(nameParts, namePart => namePart.length * maxFontSize * 0.6);
            const desiredFontSize = totalTextWidth > d.dx ? maxFontSize * (d.dx / totalTextWidth) : maxFontSize;
            return desiredFontSize;
    }
};


  getAttr = (attr, d, type) => {
  }

  handleMouseOver = (d) => {
    this.setState({
      market: d.name.split("/")[0],
      name: d.name.split("/")[0],
      change: d.change,
      price: d.price,
    });
  };

  update = () => {
    const { widget: { data } } = this.props;
    const { height, width, node } = this.state;
    const w = width;
    const h = height;
    const root = this.mapData();
    let svg;

    const negativeValues = data.filter((item) => item.change < 0);
    const positiveValues = data.filter((item) => item.change > 0);
    this.negativeColor.domain([
        d3.min(negativeValues, (item) => item.change),
        d3.max(negativeValues, (item) => item.change),
    ]);
    this.positiveColor.domain([
        d3.min(positiveValues, (item) => item.change),
        d3.max(positiveValues, (item) => item.change),
    ]);

    const treemap = d3.layout.treemap()
        .sort((a, b) => a.value - b.value)
        .size([w, h])
        .sticky(true)
        .value((d) => d.volume);

    if (d3.select(this.wrapper).select("svg").select("g.paper").empty()) {
        svg = d3.select(this.wrapper)
            .select("svg")
            .append("g")
            .attr("class", "paper")
            .attr("transform", "translate(.5,.5)");
    } else {
        svg = d3.select(this.wrapper)
            .select("svg")
            .select("g.paper")
            .attr("transform", "translate(.5,.5)");
    }

    const nodes = treemap.nodes(node || root).filter((d) => !d.children);

    const cellNodes = svg.selectAll("g.cell").data(nodes);
    cellNodes.exit().remove();

    const cell = cellNodes.enter()
      .append("g")
      .attr("class", "cell")
      .attr("transform", (d) => "translate(" + d.x + "," + d.y + ")")
      .on("mouseover", this.handleMouseOver)
      .on("mouseleave", this.handleMouseLeave);

    cell.append("rect")
      .attr("class", "rect")
      .attr("width", (d) => d.dx)
      .attr("height", (d) => d.dy)
      .style("fill", this.getColor);

    cell.append("text")
      .attr("class", "name")
      .attr("x", (d) => d.dx / 2)
      .attr("y", (d) => d.dy / 2) // Adjust the vertical position for spacing
      .attr("text-anchor", "middle")
      .style("font-size", (d) => `${this.getStyle("font-size", d, "name")}px`)
      .style("font-weight", (d) => this.getStyle("font-weight", d, "name"))
      .text((d) => d.name);

    cell.append("text")
      .attr("class", "price")
      .attr("x", (d) => d.dx / 2)
      .attr("y", (d) => d.dy / 2 + 10) // Adjust the vertical position for spacing
      .attr("text-anchor", "middle")
      .style("font-size", (d) => `${this.getStyle("font-size", d, "price")}px`)
      .text((d) => numeral(d.price).format(formatDollar));

    cell.append("text")
      .attr("class", "change")
      .attr("x", (d) => d.dx / 2)
      .attr("y", (d) => d.dy / 2 + 26) // Adjust the vertical position for spacing
      .attr("text-anchor", "middle")
      .style("font-size", (d) => `${this.getStyle("font-size", d, "change")}px`)
      .text((d) => d.change > 0 ? `+${numeral(d.change).format(percentFormat)}%` : `${numeral(d.change).format(percentFormat)}%`);



      const cellUpdate = svg.selectAll("g.cell").transition()
      .attr("class", "cell")
      .attr("transform", (d) => "translate(" + d.x + "," + d.y + ")");

    cellUpdate.select("rect.rect")
      .attr("width", (d) => d.dx)
      .attr("height", (d) => d.dy)
      .style("fill", this.getColor);

      cellUpdate.select("text.name")
      .attr("x", (d) => d.dx / 2)
      .attr("y", (d) => d.dy * 0.42) // Adjust the factor as needed
      .style("font-size", (d) => {
          const maxFontSize = Math.min(d.dy, d.dx) * 1.14; // Max font size based on rectangle size

          // Calculate available width and height for text
          const horizontalMargin = 10; // Adjust this value as needed
          const verticalMargin = 3; // Adjust this value as needed
          const availableWidth = d.dx - 2 * horizontalMargin; // Subtract margins
          const availableHeight = d.dy - 10 * verticalMargin; // Subtract margins

          // Create a temporary text element for calculating text width
          const tempText = svg.append("text")
              .style("font-size", `${maxFontSize}px`) // Set the max font size initially
              .text(d.name);

          const nameTextWidth = tempText.node().getComputedTextLength();
          tempText.remove(); // Remove the temporary text element

          // Calculate desired font size based on both width and height constraints
          const desiredFontSizeWidth = Math.min(maxFontSize, availableWidth / nameTextWidth * maxFontSize);
          const desiredFontSizeHeight = Math.min(maxFontSize, availableHeight);

          return `${Math.min(desiredFontSizeWidth, desiredFontSizeHeight)}px`; // Set the calculated font size
      })
      .style("font-weight", (d) => this.getStyle("font-weight", d, "name"))
      .text((d) => d.name);


      cellUpdate.select("text.price")
    .attr("x", (d) => d.dx / 2)
    .attr("y", (d) => d.dy * 0.6) // Adjust the factor as needed
    .style("font-size", (d) => {
        const maxFontSize = Math.min(d.dy, d.dx) * 0.22; // Max font size based on rectangle size

        // Calculate available width for text
        const horizontalMargin = 8; // Adjust this value as needed
        const availableWidth = d.dx - 5 * horizontalMargin; // Subtract margins

        // Create a temporary text element for calculating text width
        const tempText = svg.append("text")
            .style("font-size", `${maxFontSize}px`) // Set the max font size initially
            .text(numeral(d.price).format(formatDollar)); // Use the formatted price text

        const priceTextWidth = tempText.node().getComputedTextLength();
        tempText.remove(); // Remove the temporary text element

        // Calculate desired font size based on width constraint
        const desiredFontSize = Math.min(maxFontSize, availableWidth / priceTextWidth * maxFontSize);

        // Set a minimum font size to ensure readability
        const minFontSize = 3; // Adjust this value as needed
        return `${Math.max(minFontSize, desiredFontSize)}px`; // Set the calculated font size, ensuring it's at least the minimum
    })
    .text((d) => numeral(d.price).format(formatDollar));


  cellUpdate.select("text.change")
      .attr("x", (d) => d.dx / 2)
      .attr("y", (d) => d.dy * 0.76) // Adjust the factor as needed
      .style("font-size", (d) => {
          const maxFontSize = Math.min(d.dy, d.dx) * .75; // Max font size based on rectangle size

          // Calculate available width for text
          const horizontalMargin = 18; // Adjust this value as needed
          const availableWidth = d.dx - 5 * horizontalMargin; // Subtract margins

          // Create a temporary text element for calculating text width
          const tempText = svg.append("text")
              .style("font-size", `${maxFontSize}px`) // Set the max font size initially
              .text(d.change > 0 ? `+${numeral(d.change).format(percentFormat)}%` : `${numeral(d.change).format(percentFormat)}%`);

          const changeTextWidth = tempText.node().getComputedTextLength();
          tempText.remove(); // Remove the temporary text element

          // Calculate desired font size based on width constraint
          const desiredFontSize = Math.min(maxFontSize, availableWidth / changeTextWidth * maxFontSize);

          return `${desiredFontSize}px`; // Set the calculated font size
      })
      .text((d) => d.change > 0 ? `+${numeral(d.change).format(percentFormat)}%` : `${numeral(d.change).format(percentFormat)}%`);

};


  render() {
    const {height, market, width, name, change, price} = this.state;
    const {widget} = this.props;

    return (
      <div
        className="widgetWrapper"
        style={widget.expanded ? {height: `${this.props.heightUnit * COLS - 5}px`} : {}}>
        <WidgetToolbar {...this.props}/>
        {widget.loading || widget.data.length === 0 ? (
          <Loader/>

          ) : (
            <div
              data-tip
              data-for='global'
              className={classNames("disableDrag", styles.Wrapper)}
              ref={this.initChart}
            >
              <div>
                <ReactTooltip
                  id='global'
                  aria-haspopup='true'
                  place='top'
                  className={styles.buttonTooltip}
                >
                  <div className={styles.tooltiptext}>
                    <img
                      alt=""
                      height={18}
                      src={`${SYMBOLS_PATH}${name}.png`}
                    /> {name}
                  </div>
                  <div className={styles.tooltiptext}>Price: ${numeral(price).format(format)}</div>
                  <div className={styles.tooltiptext}>
                    24h Change: <b>{change > 0 ? `+${numeral(change).format(format)}%` : `
                      ${numeral(change).format(format)}%`}</b>
                  </div>
                </ReactTooltip>

              </div>
              <svg
                className="treemap-chart"
                height={height}
                width={width}
              />
            </div>
        )}
      </div>
    );
  }
}

TreemapWidget.propTypes = {
  activeWidget: PropTypes.object,
  filter: PropTypes.string,
  getMarketSummaries: PropTypes.func,
  updateActiveWidget: PropTypes.func,
  updateWidget: PropTypes.func.isRequired,
  widget: PropTypes.object,
  heightUnit: PropTypes.number
};

TreemapWidget.defaultProps = {
  activeWidget: undefined,
  filter: "",
  getMarketSummaries: () => false,
  updateActiveWidget: () => false,
  widget: {},
  heightUnit: 250
};

export default TreemapWidget;
