import {utcYear, utcMonth, utcWeek, utcDay, utcHour, utcMinute} from "./time";
const durationSecond = 1000;
const durationMinute = durationSecond * 60;
const durationHour = durationMinute * 60;
const durationDay = durationHour * 24;
const durationWeek = durationDay * 7;
const durationMonth = durationDay * 30;
const durationYear = durationDay * 365;
const e10 = Math.sqrt(50);
const e5 = Math.sqrt(10);
const e2 = Math.sqrt(2);
function bisector(compare) {
  if (compare.length === 1) compare = ascendingComparator(compare);
  return {
    left: function(a, x, lo, hi) {
      if (lo == null) lo = 0;
      if (hi == null) hi = a.length;
      while (lo < hi) {
        const mid = lo + hi >>> 1;
        if (compare(a[mid], x) < 0) lo = mid + 1;
        else hi = mid;
      }
      return lo;
    },
    right: function(a, x, lo, hi) {
      if (lo == null) lo = 0;
      if (hi == null) hi = a.length;
      while (lo < hi) {
        const mid = lo + hi >>> 1;
        if (compare(a[mid], x) > 0) hi = mid;
        else lo = mid + 1;
      }
      return lo;
    }
  };
}
function ascending(a, b) {
  return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
}
function ascendingComparator(f) {
  return function(d, x) {
    return ascending(f(d), x);
  };
}
function tickStep(start, stop, count) {
  const step0 = Math.abs(stop - start) / Math.max(0, count);
  let step1 = Math.pow(10, Math.floor(Math.log(step0) / Math.LN10));
  const error = step0 / step1;
  if (error >= e10) step1 *= 10;
  else if (error >= e5) step1 *= 5;
  else if (error >= e2) step1 *= 2;
  return stop < start ? -step1 : step1;
}
const tickIntervals = [
  [utcMinute,  1,      durationMinute], //60000
  [utcMinute,  5,  5 * durationMinute], //300000
  [utcMinute, 15, 15 * durationMinute], //900000
  [utcMinute, 30, 30 * durationMinute], //1800000
  [utcHour,  1,      durationHour], //3600000
  [utcHour,  3,  3 * durationHour], //10800000
  [utcHour,  6,  6 * durationHour], //21600000
  [utcHour, 12, 12 * durationHour], //43200000
  [utcDay,  1,      durationDay], //86400000
  [utcDay,  2,  2 * durationDay], //172800000
  [utcWeek,  1,      durationWeek], //604800000
  [utcMonth,  1,      durationMonth], //2592000000
  [utcMonth,  3,  3 * durationMonth], //7776000000
  [utcYear,  1,      durationYear]  //31536000000
];
function tickInterval(interval, start, stop, step){
  if (interval == null) interval = 10;

  // If a desired tick count is specified, pick a reasonable tick interval
  // based on the extent of the domain and a rough estimate of tick size.
  // Otherwise, assume interval is already a time interval and use it.
  if (typeof interval === "number") {
    const target = Math.abs(stop - start) / interval;
    var i = bisector(function(i) {
      return i[2];
    }).right(tickIntervals, target);
    if (i === tickIntervals.length) {
      step = tickStep(start / durationYear, stop / durationYear, interval);
      interval = utcYear;
    } else if (i) {
      i = tickIntervals[target / tickIntervals[i - 1][2] < tickIntervals[i][2] / target ? i - 1 : i];
      step = i[1];
      interval = i[0];
    }
  }
  return step == null ? interval : interval.every(step, i[2]);
}

export default (interval, start, stop, step) => {
  let t0 = start;
  let t1 = stop;
  const r = t1 < t0;
  let t;
  if (r) t = t0, t0 = t1, t1 = t;
  const {interval: newInterval, duration} = tickInterval(interval, t0, t1, step);
  t = newInterval;
  t = t ? t.localRange(t0, t1 + 1, null, duration) : []; // inclusive stop
  return r ? t.reverse() : t;
};
